import styled from '@emotion/styled/macro';

export const Mask = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: ${props => (props.show ? 'block' : 'none')};
`;

export const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    display: block;
    width: 40%;
    max-height: 95%;
    box-sizing: border-box;
    padding: ${props => props.theme.spacing.medium};
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
    max-width: 970px;
    background-image: linear-gradient(
        to bottom,
        ${props => props.theme.colours.white},
        #edf2f5
    );
    z-index: 101;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: 90%;
    }
`;

export const LeagueModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    display: block;
    width: 40%;
    max-width: 500px;
    max-height: 95%;
    box-sizing: border-box;
    padding: ${props => props.theme.spacing.medium};
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    z-index: 101;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: 90%;
    }
`;

export const Close = styled.a`
    margin: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colours.textDark};
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${props => props.theme.fontSize.small};
    z-index: 1;
    font-family: ${props => props.theme.fonts.skycons};
    text-transform: none;
    font-style: normal;
    width: 1em;
    height: 1em;
    font-weight: normal;
    :before {
        content: attr(data-char);
    }
`;

export const TitleContainer = styled.div`
    text-align: center;
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const Title = styled.h3`
    font-family: ${props => props.theme.fonts.skySportsD};
    margin-bottom: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.xlarge};
    color: ${props => props.theme.colours.bluePrimary};
    font-weight: 900;
`;

export const LeagueTitle = styled.h3`
    font-family: ${props => props.theme.fonts.skySportsD};
    margin-bottom: 15px;
    font-size: ${props => props.theme.fontSize.xlarge};
    font-weight: 800;
    color: ${props => props.theme.colours.colorOnPrimary};
    text-transform: uppercase;
`;

export const TitleParagraph = styled.p`
    font-family: ${props => props.theme.fonts.skySportsD};
    margin-bottom: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.medium};
`;

export const LeagueTitleParagraph = styled.p`
    font-family: ${props => props.theme.fonts.regular};
    margin-bottom: 15px;
    font-size: 18px;
`;

export const ErrorParagraph = styled.p`
    font-family: ${props => props.theme.fonts.regular};
    margin-bottom: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.medium};
    text-align: center;
`;

export const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
`;

export const Action = styled.button`
    flex: 1 1 auto;
    margin: 0 5px;

    color: ${props => props.theme.colours.white};
    font-size: 18px;
    background-color: ${props => props.theme.colours.redDark};
    font-weight: 900;
    font-family: ${props => props.theme.fonts.bold};
    text-align: center;
    border-radius: 60px;
    padding: 9px;
    height: 48px;
    text-transform: uppercase;
    font-weight: 800;

    :hover {
        background-color: #e1e1e1;
        cursor: pointer;
    }
`;

export const DangerAction = styled(Action)`
    color: #fff;
    background: #df0000;
    border: 1px solid #df0000;

    :hover {
        background-color: #ad0202;
    }
`;

export const ConfirmAction = styled(Action)`
    color: ${props => props.theme.colours.colorOnPrimary};
    background: ${props =>
        props.disabled
            ? props.theme.colours.disabledButton
            : props.theme.colours.greenPrimary};
    border: 1px solid
        ${props =>
            props.disabled
                ? props.theme.colours.disabledButtonBorder
                : props.theme.colours.greenPrimaryBorder};

    :hover {
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
        background: ${props =>
            props.disabled
                ? props.theme.colours.disabledButton
                : props.theme.colours.greenPrimaryBorder};
    }
`;

export const TransparentButton = styled.button`
    font-size: 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
`;
export const DialogBody = styled.div`
    position: absolute;
    top: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
`;
export const ControlButton = styled.div`
    button {
        width: calc(100% - 60px);
        height: 40px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        border-radius: 20px;
        font-weight: 900;
    }
    .success {
        background-color: #11ed7b;
        color: #00288a;
    }
    .primary {
        background-color: #00288a;
        color: #ffffff;
    }
`;
export const BottomText = styled.div`
    text-align: center;
    text-decoration: underline;
    color: #576672;
`;
export const ContainerBody = styled.div`
    min-height: 300px;
`;
