import Events from './events';

export default class InterfaceEvent extends Events {
    constructor(
        elementText = 'null',
        action = 'null',
        module = 'null',
        eventContext = 'null'
    ) {
        super(elementText, module, action);
        this.event_context = eventContext;
    }
}
