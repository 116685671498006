import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectActiveRoundScoreChallenges } from '../../../../../../store/modules/round/selectors';
import Teams from '../../../../../../components/Teams';
import * as Styled from './styles';

const SubmittedPredictions = ({ predictions, fixtures }) => (
    <>
        {fixtures.length && (
            <Styled.SubmittedPredictions>
                <Styled.Title>
                    <Styled.Tick /> Predictions Submitted
                </Styled.Title>
                <Styled.Matches>
                    {fixtures.map((fixture, index) => {
                        const homeScore =
                            predictions[fixture.match.id].scoreHome;
                        const awayScore =
                            predictions[fixture.match.id].scoreAway;

                        const formatFixture = f => ({
                            ...f,
                            img: f.badgeUri
                        });

                        return (
                            <Styled.MatchContainer key={fixture.match.id}>
                                <Styled.Match>
                                    <Teams
                                        matchIndex={index}
                                        borderRadius={{
                                            topLeft: '45px',
                                            topRight: '45px',
                                            bottomRight: '45px',
                                            bottomLeft: '45px'
                                        }}
                                        position="center"
                                        homeTeam={formatFixture(
                                            fixture.match.homeTeam
                                        )}
                                        awayTeam={formatFixture(
                                            fixture.match.awayTeam
                                        )}
                                        padding="s"
                                    />
                                    <Styled.Scores
                                        data-test-id={`submitted-predictions-match-score-${index +
                                            1}`}
                                    >
                                        {homeScore} - {awayScore}
                                    </Styled.Scores>
                                </Styled.Match>
                            </Styled.MatchContainer>
                        );
                    })}
                </Styled.Matches>
            </Styled.SubmittedPredictions>
        )}
    </>
);

SubmittedPredictions.propTypes = {
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                })
            })
        })
    ).isRequired,
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired
};

const mapStateToProps = state => ({
    predictions: state.play.predictions,
    fixtures: selectActiveRoundScoreChallenges(state)
});

export default connect(mapStateToProps)(SubmittedPredictions);
