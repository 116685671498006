export const INITIALISE_AUTH = 'auth/INITIALISE_AUTH';
export const AUTH_INITIALISED = 'auth/AUTH_INITIALISED';
export const CHECK_AUTH = 'auth/CHECK_AUTH';
export const CHECKED_AUTH = 'auth/CHECKED_AUTH';
export const REQUEST_LOGIN = 'auth/REQUEST_LOGIN';
export const LOGOUT = 'auth/LOGOUT';
export const REQUEST_OAUTH_TOKENS = 'auth/REQUEST_OAUTH_TOKENS';
export const RECEIVE_OAUTH_TOKENS = 'auth/RECEIVE_OAUTH_TOKENS';
export const RECEIVE_SSO_TOKEN = 'auth/RECEIVE_SSO_TOKEN';
export const CHECK_OAUTH_TOKENS = 'auth/CHECK_OAUTH_TOKENS';
export const CHECK_SSO_TOKEN = 'auth/CHECK_SSO_TOKEN';
export const REQUEST_JWT = 'auth/REQUEST_JWT';
export const RECEIVE_JWT = 'auth/RECEIVE_JWT';
export const UPDATE_ID_TOKEN = 'auth/UPDATE_ID_TOKEN';
export const REQUEST_ANDROID_OAUTH_TOKENS = 'auth/REQUEST_ANDROID_OAUTH_TOKENS';
