/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled/macro';

export const Svg = styled.svg`
    width: ${props => props.width};
`;

export const Path = styled.path`
    fill: ${props => {
        const baseColour = props.followed
            ? props.theme.colours.greenPrimary
            : props.theme.colours[props.fill];

        return props.mouseOver ? props.theme.colours.brightGrey : baseColour;
    }};
`;
