import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './AccaStyles';

const { Provider, Consumer } = createContext({});

export const AccaTab = ({ id, testId, isDisabled, to, onClick, children }) => (
    <Consumer>
        {active => (
            <Styled.Tab active={active === id} data-test-id={testId}>
                {isDisabled && <Styled.Disabled>{children}</Styled.Disabled>}
                {!isDisabled && to && (
                    <Styled.Link to={to} onClick={onClick || (() => {})}>
                        {children}
                    </Styled.Link>
                )}
                {!isDisabled && !to && onClick && (
                    <Styled.Button type="button" onClick={onClick}>
                        {children}
                    </Styled.Button>
                )}
            </Styled.Tab>
        )}
    </Consumer>
);

AccaTab.propTypes = {
    children: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    testId: PropTypes.string,
    isDisabled: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func
};

AccaTab.defaultProps = {
    testId: null,
    isDisabled: false,
    to: null,
    onClick: null
};

const AccaTabs = ({ active, children }) => (
    <Provider value={active}>
        <Styled.Container>{children}</Styled.Container>
    </Provider>
);

AccaTabs.propTypes = {
    active: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element
    ]).isRequired
};

AccaTabs.defaultProps = {
    active: null
};

export default AccaTabs;
