import { put, takeLeading, call, select, take } from 'redux-saga/effects';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';
import * as types from './types';
import statsApi from '../../../api/stats';
import { selectToken } from '../auth/selectors';
import { selectCustomerId } from '../user/selectors';
import { getItem, setItem } from '../../../helpers/localStorage';

const getCachedStats = customerId => {
    try {
        const storedStr = getItem(`user-stats-self-${customerId}`);

        if (!storedStr) {
            return null;
        }

        const stored = JSON.parse(storedStr);
        if (
            !stored.lastModified ||
            stored.lastModified <= Date.now() - 60 * 10 * 1000
        ) {
            return null;
        }

        return stored.stats;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error loading cached stats', e);
        return null;
    }
};

const saveCachedStats = (customerId, stats) => {
    setItem(
        `user-stats-self-${customerId}`,
        JSON.stringify({
            lastModified: Date.now(),
            stats
        })
    );
};

// Adapted from https://stackoverflow.com/a/52044336/16879838
// Makes the saga wait (in a non-blocking fashion) for the customerId to exist, as this saga can fire before the userInfo has been fetched
function* waitForCustomerId() {
    if (yield select(selectCustomerId)) {
        return;
    }

    while (true) {
        yield take('*');
        if (yield select(selectCustomerId)) {
            return;
        }
    }
}

export function* getStatsForSelf(action) {
    yield call(waitForCustomerId);
    const customerId = yield select(selectCustomerId);

    if (!action.force) {
        const cachedStats = getCachedStats(customerId);

        if (cachedStats) {
            yield put(actions.receiveStatsForSelf(cachedStats));
            return;
        }
    }

    const token = yield select(selectToken);
    try {
        const data = yield call(statsApi.getStatsForSelf, token);
        saveCachedStats(customerId, data);
        yield put(actions.receiveStatsForSelf(data));
    } catch (err) {
        yield put(addErrorMessage('There was a problem getting your stats'));
    }
}

export function* updateStatsEnteredRound(action) {
    yield call(waitForCustomerId);
    const customerId = yield select(selectCustomerId);

    const stats = getCachedStats(customerId);

    if (!stats || !stats.entries || stats.entries.length < 1) {
        return;
    }

    const roundIndex = stats.entries.findIndex(
        ({ roundId }) => `${roundId}` === `${action.roundId}`
    );

    if (roundIndex < 0) {
        return;
    }

    const newStats = {
        ...stats,
        entries: [...stats.entries]
    };

    newStats.entries[roundIndex] = {
        ...newStats.entries[roundIndex],
        didEnter: true
    };

    saveCachedStats(customerId, newStats);
    yield put(actions.receiveStatsForSelf(newStats));
}

export function* getSeasonStatsForSelf() {
    yield call(waitForCustomerId);

    const token = yield select(selectToken);
    try {
        const data = yield call(statsApi.getSeasonStatsForSelf, token);
        yield put(actions.receiveSeasonStatsForSelf(data));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem getting your season stats')
        );
    }
}

export function* getSeasonHeadToHeadStats() {
    yield call(waitForCustomerId);

    const token = yield select(selectToken);
    try {
        const data = yield call(statsApi.getSeasonHeadToHeadStats, token);
        yield put(actions.receiveSeasonHeadToHeadStats(data));
    } catch (err) {
        yield put(
            addErrorMessage(
                'There was a problem getting your season head to head stats'
            )
        );
    }
}

export function* watchGetStatsForSelf() {
    yield takeLeading(types.REQUEST_STATS_FOR_SELF, getStatsForSelf);
}

export function* watchUpdateStatsEnteredRound() {
    yield takeLeading(
        types.UPDATE_STATS_ENTERED_ROUND,
        updateStatsEnteredRound
    );
}

export function* watchGetSeasonStatsForSelf() {
    yield takeLeading(
        types.REQUEST_SEASON_STATS_FOR_SELF,
        getSeasonStatsForSelf
    );
}

export function* watchGetSeasonHeadToHeadStats() {
    yield takeLeading(
        types.REQUEST_SEASON_HEAD_TO_HEAD_STATS,
        getSeasonHeadToHeadStats
    );
}

export const sagas = [
    watchGetStatsForSelf,
    watchUpdateStatsEnteredRound,
    watchGetSeasonStatsForSelf,
    watchGetSeasonHeadToHeadStats
];
