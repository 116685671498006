import React from 'react';
import PropTypes from 'prop-types';
import { PageContainer } from '../../../components/Common/Page/styles';
import { PageTitle } from '../../../components/Common/PageTitle/styles';
import NextRound from '../components/NextRound';
import PersonalSeasonStats from '../components/PersonalSeasonStats';
import RoundsList from '../components/RoundsList';
import * as Styled from './styles';

const Loading = () => (
    <>
        <Styled.TopSection>
            <Styled.NextRoundLoading />
            <Styled.PersonalSeasonStatsLoading />
        </Styled.TopSection>
        <Styled.RoundsListLoading />
    </>
);

const NextRoundAndStats = ({
    rounds,
    currentRound,
    totalRoundsEntered,
    averagePoints,
    isLoading
}) => (
    <PageContainer>
        <PageTitle>Results and Schedule</PageTitle>
        {isLoading ? (
            <Loading />
        ) : (
            <>
                <Styled.TopSection>
                    {currentRound && (
                        <NextRound
                            closeDate={currentRound.closeDate}
                            roundId={currentRound.id}
                            roundName={currentRound.name}
                            didEnter={currentRound.entered}
                        />
                    )}
                    <PersonalSeasonStats
                        totalRoundsEntered={totalRoundsEntered}
                        averagePoints={averagePoints}
                    />
                </Styled.TopSection>
                <RoundsList rounds={rounds} />
            </>
        )}
    </PageContainer>
);

const roundPropTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    closeDate: PropTypes.instanceOf(Date).isRequired,
    entered: PropTypes.bool.isRequired,
    score: PropTypes.number
};

NextRoundAndStats.propTypes = {
    rounds: PropTypes.arrayOf(PropTypes.shape(roundPropTypes)),
    currentRound: PropTypes.shape(roundPropTypes),
    totalRoundsEntered: PropTypes.number,
    averagePoints: PropTypes.number,
    isLoading: PropTypes.bool
};

NextRoundAndStats.defaultProps = {
    rounds: [],
    currentRound: null,
    totalRoundsEntered: 0,
    averagePoints: 0,
    isLoading: false
};

export default NextRoundAndStats;
