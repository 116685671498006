import * as types from './types';

export const requestReferees = () => ({
    type: types.REQUEST_REFEREES
});

export const receiveReferees = referees => ({
    type: types.RECEIVE_REFEREES,
    payload: referees
});

export const requestSetReferrer = userId => ({
    type: types.SET_REFERRER,
    userId
});

export const postSetReferrer = () => ({
    type: types.POST_SET_REFERRER
});
