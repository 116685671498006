import React from 'react';
import {
    OrderedList,
    UnOrderedList
} from '../../components/Common/Page/styles';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';

const PreviousPrivacy = () => (
    <Styled.PrivacyPageContainer>
        <PageTitle>Previous Privacy Policy</PageTitle>
        <Styled.Header id="privacy-policy-header" nopad>
            Privacy Policy
        </Styled.Header>
        <p>
            This privacy notice applies to the use of personal information and
            other information by each of Bonne Terre Limited and Hestview
            Limited, whose trading names include Sky Betting &amp; Gaming, Sky
            Bet, Sky Vegas, Sky Casino, Sky Bingo, Sky Poker, Sky Games, Sky
            Sports Super 6, Sky Sports Fantasy Football and Sportinglife
            (collectively,&nbsp;&apos;Sky Betting &amp; Gaming&apos;) and are a
            part of the Sky Betting &amp; Gaming group of companies (&apos;Sky
            B&amp;G Group&apos;).
        </p>
        <p>
            Any member of the Sky B&amp;G Group may use and share, within that
            group and with its business partners and/or advisors, the
            information you provide and other information it holds about you for
            the purposes set out below.
        </p>

        <Styled.SubSectionHeader>
            Information we may hold
        </Styled.SubSectionHeader>
        <UnOrderedList>
            <li>Information you provided to us, including on our websites</li>
            <li>
                Information about our content, products and services you&apos;ve
                interacted with or played including without limitation by way of
                making deposits, bets or wagers in relation to any of Sky
                Betting &amp; Gaming&apos;s services.
            </li>
            <li>
                Information provided by other companies who&apos;ve obtained
                your permission to share information about you.
            </li>
            <li>
                Information, uploads and comments you make about the services
                you use and our related products including any Sky Poker
                programmes, including how you access, view, share, contribute to
                and communicate with them.
            </li>
            <li>
                Information we collect from devices you use to receive Sky
                Betting &amp; Gaming content, products and services and about
                your use of Sky Betting &amp; Gaming and/or selected third party
                content, products and services. For more information about
                cookies and how to manage them please see the Cookies Notice
                below.
            </li>
            <li>
                IP address (this is a number that identifies a specific network
                device on the internet and is required for your device to
                communicate with websites) and location data.
            </li>
            <li>
                Technical information from the devices you use to receive Sky
                Betting &amp; Gaming content, products and services, for example
                the collection of diagnostic and traffic information.
            </li>
        </UnOrderedList>

        <Styled.SubSectionHeader>
            How we may use your information
        </Styled.SubSectionHeader>
        <p>
            In addition to using your information to provide you with content,
            products, services, tailored and personalised recommendations and
            general account management, we may also use your information in the
            following ways:
        </p>
        <OrderedList>
            <li>
                To monitor, improve and protect our content, products and
                services, work with our agents and business partners to improve
                the products and services we offer, and develop new content,
                products and services.
            </li>
            <li>
                We may share information with credit reference, fraud prevention
                agencies and other companies for use in credit decisions, for
                fraud detection, prevention and debt recovery purposes.
            </li>
            <li>
                We may transfer your information to our data processors outside
                the European Economic Area, but will do so with appropriate
                measures and controls in place to protect that information in
                accordance with applicable data protection laws and regulations.
            </li>
            <li>
                If false or inaccurate information is provided and fraud is
                identified, the details will be passed to fraud prevention
                agencies. Law enforcement agencies may access and use this
                information. We and other organisations may also access and use
                this information to prevent fraud and money laundering, for
                example when: checking details on applications for credit and
                credit related or other facilities; managing credit and credit
                related accounts and facilities; recovering debt; checking
                details on proposals and claims for all types of insurance; or
                checking details of job applications and employees. We and other
                organisations may access and use from other countries the
                information recorded by fraud prevention agencies. Please
                contact us using information on the Help &amp; Support pages
                on&nbsp;
                <a
                    rel="noopener noreferrer"
                    href="https://m.skybet.com/"
                    target="_blank"
                >
                    www.skybet.com
                </a>
                &nbsp;for more information.
            </li>
            <li>For market research.</li>
            <li>
                To enable us to comply with any legal or regulatory
                requirements; to protect or enforce our rights or the rights of
                any third party; in the detection and prevention of fraud and
                other crimes; and for the purpose of safeguarding national
                security.
            </li>
            <li>
                We may disclose your information to any successors of our
                business for them to use for the purposes set out in this
                Privacy Notice.
            </li>
            <li>
                If you log into one of our products through Facebook, Facebook
                provides us with some of your Facebook user details. We may use
                and share these details with our partners, each of whom may use
                the details to provide you with a more personalised experience
                on Facebook. This means that we and they may, for example,
                tailor the advertisements you see on Facebook so that you see
                advertisements for similar apps to Apps provided by us.
            </li>

            <p>Unless you&apos;ve asked us not to:</p>

            <li>
                To send you periodic communications and other information about
                your chosen content, products and services.
            </li>
            <li>
                To send you direct marketing from us or our trusted business
                partners (specifically including Sky UK Ltd). This may include
                communications by post, telephone or email and SMS, about us and
                our business partners&apos; content, products and services,
                events and special offers, including, where applicable, for a
                reasonable time after you have ceased to be a customer of ours.
            </li>
            <li>
                To provide you with tailored advertising. This means that we
                have your agreement to use the information we hold about you,
                for example, to make some of the adverts you see more relevant
                to you.
            </li>
        </OrderedList>

        <Styled.SubSectionHeader>
            Your Contact Preferences
        </Styled.SubSectionHeader>
        <Styled.SubSectionHeader>Marketing</Styled.SubSectionHeader>

        <p>
            You can choose not to receive direct marketing. You can adjust your
            preferences in the following ways:
        </p>
        <UnOrderedList>
            <li>
                via your Sky Betting &amp; Gaming, Sky Games, Super 6 or Fantasy
                Football account, as applicable.
            </li>
            <li>
                clicking on the &quot;unsubscribe&quot; link in email marketing
                information from us in relation to future email marketing.
            </li>
            <li>
                by replying &quot;STOP&quot; to 57785 in any SMS message in
                relation to future SMS messages.
            </li>
            <li>Online behavioural advertising</li>
            <li>
                If you don&apos;t want any information processed through the use
                of cookies, please see the Cookies Notice below.
            </li>
        </UnOrderedList>

        <Styled.SubSectionHeader>Contacting Sky Games</Styled.SubSectionHeader>

        <UnOrderedList>
            <li>
                Any queries or comments about this Privacy Notice or for
                requests of copies of the information we hold about you should
                be sent to&nbsp;
                <a href="mailto:support@skybet.com">support@skybet.com</a>
                &nbsp;with the subject line &quot;Data Protection Query&quot;.
            </li>
            <li>
                Please ensure you notify us of any updates, amendments and
                corrections to your information by contacting us using
                information on the Help &amp; Support pages on&nbsp;
                <a
                    rel="noopener noreferrer"
                    href="https://m.skybet.com/"
                    target="_blank"
                >
                    www.skybet.com
                </a>
                &nbsp;or by phoning us on&nbsp;0330 024 4777 (or +44 (0) 1133
                8876449 if calling from overseas) or writing to Sky Betting
                &amp; Gaming, 2 Wellington Place, Leeds, West Yorkshire, LS1
                4AP.
            </li>
        </UnOrderedList>

        <p>
            We may make changes to this Privacy Notice at any time, at our sole
            discretion. Such changes will take effect from the date specified by
            us on our websites, whether or not we have notified you specifically
            of such changes. It is important, therefore, that You log in to the
            websites from time to time to check to see whether there is a
            notification of change. You agree to be solely responsible for
            becoming informed of such changes. If You continue to use the Sky
            Betting &amp; Gaming websites or services after the effective date
            of certain changes (regardless of the way we have notified such
            changes), You agree to be bound by those changes whether or not You
            have had actual notice of, or have read, the relevant changes. If
            You do not agree to be bound by relevant changes, You should not
            continue to use our websites or services any further.
        </p>

        <div id="cookie-policy" />
        <Styled.SectionHeader id="cookie-policy-header">
            Cookies Policy
        </Styled.SectionHeader>
        <p>
            This cookies policy (the &quot;Policy&quot;) applies to the websites
            operated by each of Bonne Terre Limited and Hestview Limited, whose
            trading names include Sky Betting &amp; Gaming, Sky Bet, Sky Vegas,
            Sky Casino, Sky Bingo, Sky Poker, Fantasy Six-A-Side, Sky Sports
            Super 6, Sky Sports Fantasy Football and Sportinglife (collectively,
            &quot;Sky Betting &amp; Gaming&quot; or &quot;SB&amp;G&quot;) and
            are a part of the Sky Betting &amp; Gaming group of companies
            (&apos;Sky B&amp;G Group&apos;).
        </p>
        <p>
            When you create or log into an online account via the Sky Betting
            &amp; Gaming websites, you agree to our use of cookies as set out in
            this Policy. Otherwise, by continuing to scroll, click, navigate or
            use the Sky Betting &amp; Gaming websites, content, products or
            services you agree to the use of cookies as described in this
            Policy.
        </p>
        <p>
            You should be aware that when you access or use our content,
            products and services, we may collect information from your devices
            by using &apos;cookies&apos;. For further details about the types of
            cookies we use, please see the section &quot;Cookies on the Sky
            Betting &amp; Gaming websites&quot; below.
        </p>
        <p>
            If you would like to learn more about your cookie settings, how to
            manage these cookies and/or how to opt-out from the cookies being
            set, please see the section &quot;Controlling My Cookies&quot;
            below.
        </p>

        <Styled.SubSectionHeader>
            What are cookies and how do they work?
        </Styled.SubSectionHeader>
        <p>
            Cookies are small bits of text and numbers that are downloaded onto
            the devices you use to receive Sky Games content, products and
            services and access online information. Your browser makes these
            cookies available every time you visit the website again, so it can
            recognise you and your device and can then tailor what you see.
        </p>

        <Styled.SubSectionHeader>
            What do we use cookies for?
        </Styled.SubSectionHeader>
        <p>
            Cookies play an important part in the day-to-day functioning of
            websites and other online content. Cookies help your device or
            browser to access information (for example, the selections you have
            placed in your bet slip), identify you as a user (for example, so we
            can keep you logged into your account when you navigate around the
            websites) or use certain features (for example, you want to reset
            your PIN or User ID) when you use the Sky Betting and Gaming
            websites. We also sometimes use other cookies to help prevent fraud
            (for example, by detecting multiple fraudulent transactions from the
            same computer) or to enhance your experience (for example, by
            showing more relevant price boosts based on your previous activity).
            We do not use cookies for any reason that does not:
        </p>
        <UnOrderedList>
            <li>
                Make adverts and other content more effective and relevant to
                you.
            </li>
            <li>
                Ensure we can pay our affiliate partners correctly for the users
                they refer to us.
            </li>
            <li>
                Improve your Sky Betting and Gaming experience by providing
                anonymised analytics.
            </li>
            <li>
                Deliver features and content (for example we might want to see
                how many customers visited the homepage yesterday compared to
                last week).
            </li>
            <li>
                Detect and defend against fraud and other security risks to
                protect our customers, partners and Sky Betting and Gaming
                itself.
            </li>
            <li>
                Meet our legal, compliance and regulatory duties. SB&amp;G are
                regulated by both the Alderney Gambling Control Commission and
                United Kingdom Gambling Commission.
            </li>
            <li>
                Continually improve our products and services (for example, by
                testing different product experiences with different users to
                see which the best is).
            </li>
        </UnOrderedList>
        <p>
            As outlined in the summary above, there are many different types of,
            and uses for, cookies. However, they can be grouped in the following
            four main groups: (1) strictly necessary, (2) functionality, (3)
            targeting and (4) analytical. Further detail about how and why Sky
            Betting and Gaming uses each of this type of cookie can be found in
            the detail below:
        </p>

        <Styled.SubSectionHeader>
            Cookies on Sky Games websites
        </Styled.SubSectionHeader>
        <p>Our websites use the following cookies:</p>

        <Styled.SubSectionSubHeader>Type of cookie:</Styled.SubSectionSubHeader>
        <p>
            Strictly necessary - some are session and persistent cookies. Some
            of these cookies are set by third parties such as OpenBet, Omniture,
            RaceCaller and GFM.
        </p>
        <Styled.SubSectionSubHeader>
            What do they do?
        </Styled.SubSectionSubHeader>
        <p>
            These are cookies which are needed to provide the content, product
            or service you have asked for. These cookies are essential to help
            your device download or stream information, so that you can move
            around our websites and use their features. Without these cookies,
            we can&apos;t provide the content, products or services you&apos;ve
            asked for.
        </p>
        <Styled.SubSectionSubHeader>
            How do we use this type of cookie?
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Here are a few examples of the ways in which we use strictly
                necessary cookies:
            </span>
        </p>
        <UnOrderedList>
            <li>
                positioning information on your smartphone screen, tablet device
                or other screen so that you can see and use our websites;
            </li>
            <li>
                keeping you logged in during your visit so that you don&apos;t
                have to log in on every one of our websites each time you visit;
            </li>
            <li>
                enabling you to stream content, without having to repeatedly
                adjust your volume and viewing settings; and
            </li>
            <li>
                allowing you to navigate through our websites smoothly by
                identifying your device or browser and subsequently showing the
                most appropriate format of website.
            </li>
        </UnOrderedList>
        <Styled.SubSectionSubHeader>
            Do these cookies collect my personal data? Does the information get
            passed onto third parties?
        </Styled.SubSectionSubHeader>
        <p>
            Yes, some of these cookies do collect your personal data and some of
            your personal data may be passed onto third parties such as
            RaceCaller and GFM.
        </p>
        <Styled.SubSectionSubHeader>
            Links to more information:
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Page 8 – 9 of this guide, provided by the ICO, details the types
                of cookies in more detail:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf"
                target="_blank"
            >
                https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf
            </a>
        </p>

        <Styled.SubSectionSubHeader>Type of cookie:</Styled.SubSectionSubHeader>
        <p>
            Functionality. Some of these cookies are set by third parties such
            as Maxymiser, OpenBet, and GetClicky.
        </p>
        <Styled.SubSectionSubHeader>
            What do they do?
        </Styled.SubSectionSubHeader>
        <p>
            These cookies recognise you when you return to our website and allow
            us to remember the choices you make. They also help us to provide
            improved features.
        </p>
        <Styled.SubSectionSubHeader>
            How do we use this type of cookie?
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Here are a few examples of some of the ways in which we use
                functionality cookies:
            </span>
        </p>
        <UnOrderedList>
            <li>
                remembering your preferences and settings, including language,
                location and marketing preferences (such as choosing whether you
                wish to receive marketing information);
            </li>
            <li>remembering the selections in your betslip;</li>
            <li>
                remembering if you&apos;ve used a specific application or
                website before;
            </li>
            <li>
                showing you information that&apos;s relevant to the content,
                products or services that you receive;
            </li>
            <li>
                giving you access to content provided by social-media websites,
                such as Twitter; and
            </li>
            <li>
                showing &apos;related article&apos; links that are relevant to
                the information you&apos;re looking at.
            </li>
        </UnOrderedList>
        <Styled.SubSectionSubHeader>
            Do these cookies collect my personal data? Does the information get
            passed onto third parties?
        </Styled.SubSectionSubHeader>
        <p>
            Yes, some of these cookies do collect your personal data. Some of
            these cookies don&apos;t collect personal data, but we do sometimes
            pass on this anonymised data to third parties such as Maxymiser.
        </p>
        <Styled.SubSectionSubHeader>
            Links to more information:
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Page 8 – 9 of this guide, provided by the ICO, details the types
                of cookies in more detail:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf"
                target="_blank"
            >
                https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf
            </a>
        </p>

        <Styled.SubSectionSubHeader>Type of cookie:</Styled.SubSectionSubHeader>
        <p>
            Targeting. Some of these cookies are set by third parties such as
            TeaLeaf, Revenue Science, Google and Oddschecker.
        </p>
        <Styled.SubSectionSubHeader>
            What do they do?
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                We sell space on some of our websites to advertisers. The
                resulting adverts often contain cookies which are stored onto
                your device as you visit different websites. The advertiser uses
                the browsing information collected from these cookies to:
            </span>
        </p>
        <UnOrderedList>
            <li>
                restrict the number of times you are shown a particular
                advertisement. This is sometimes called &apos;frequency
                capping&apos;; and
            </li>
            <li>
                show other advertisements that are relevant to you, while
                you&apos;re accessing our information.
            </li>
        </UnOrderedList>
        <span>
            Your browsing activity may be grouped with information about what is
            being accessed by other users within similar interest and
            demographic groups. The result is then used to show you
            advertisements based on those interests. This is often called online
            behavioral advertising (OBA).
        </span>
        <Styled.SubSectionSubHeader>
            How do we use this type of cookie?
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Here are a few examples of some of the ways in which we use
                targeting cookies:
            </span>
        </p>
        <UnOrderedList>
            <li>to remember websites you have visited;</li>
            <li>to remember links you have followed; and</li>
            <li>
                within advertisements for our own Sky Betting &amp; Gaming
                products.
            </li>
        </UnOrderedList>
        <p>
            We use a cookie placed by a company called Lotame to collect
            non-personal data that is used for OBA purposes. To opt-out of the
            collection and use of data by Lotame for OBA on your browser
            (including a mobile browser if third-party cookies are enabled in
            your browser), you can click&nbsp;here&nbsp;and follow the
            instructions provided. Your opt-out choice is applied only to the
            browser from which you make the choice. Note that our opt-out is
            cookie based, and so if you delete your cookies, you may no longer
            be opted out and you may need to opt-out again We use targeting
            cookies to make our website and advertising displayed on it more
            relevant to your interests.
        </p>
        <Styled.SubSectionSubHeader>
            Do these cookies collect my personal data? Does the information get
            passed onto third parties?
        </Styled.SubSectionSubHeader>
        <p>Yes, some of these cookies do collect your personal data.</p>
        <Styled.SubSectionSubHeader>
            Links to more information:
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Page 8 – 9 of this guide, provided by the ICO, details the types
                of cookies in more detail:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf"
                target="_blank"
            >
                https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf
            </a>
        </p>
        <Styled.SubSectionSubHeader>Type of cookie:</Styled.SubSectionSubHeader>
        <p>
            Analytical. Some of these cookies are set by third parties such as
            Omniture, and Google.
        </p>
        <Styled.SubSectionSubHeader>
            What do they do?
        </Styled.SubSectionSubHeader>
        <p>
            Analytical cookies gather information about which of our websites,
            information and links are popular and which ones don&apos;t get used
            as much. Our applications, web locations, websites and
            communications that you get from us also contain small invisible
            images known as &apos;web beacons&apos; or &apos;pixels&apos;. These
            ‘pixels&apos; simply let us know that an advert or affiliate partner
            has successfully connected a customer to SB&amp;G. For example, when
            a new customer successfully registers on a SB&amp;G website after
            clicking an advert, a &quot;pixel&quot; will let us know that the
            advert worked or that we need to pay an affiliate partner for
            sending a new customer to us. These pixels cannot identify an
            individual user.
        </p>
        <p>
            Your information is collected and grouped together with information
            collected from everyone else&apos;s devices. We can then see overall
            patterns of usage, rather than any one person&apos;s activity.
        </p>
        <Styled.SubSectionSubHeader>
            How do we use this type of cookie?
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Here are a few examples of some of the ways in which we use
                Analytical cookies:
            </span>
        </p>
        <UnOrderedList>
            <li>
                to identify trends about how people navigate (find their way
                through) our information and when and where &apos;error
                messages&apos; may originate;
            </li>
            <li>to help us keep our information relevant and up to date;</li>
            <li>
                to count the number of times a page or email has been viewed and
                allow us to measure the effectiveness of the communication; and
            </li>
            <li>
                to improve how our applications, and websites and their pages
                work.
            </li>
        </UnOrderedList>
        <Styled.SubSectionSubHeader>
            Do these cookies collect my personal data? Does the information get
            passed onto third parties?
        </Styled.SubSectionSubHeader>
        <p>
            Yes, some of these cookies collect some personal data. We pass on
            both anonymised and personal information collected onto third
            parties such as Omniture and Google
        </p>
        <Styled.SubSectionSubHeader>
            Links to more information:
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                Page 8 – 9 of this guide, provided by the ICO, details the types
                of cookies in more detail:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf"
                target="_blank"
            >
                https://www.cookielaw.org/media/1096/icc_uk_cookiesguide_revnov.pdf
            </a>
        </p>

        <Styled.SubSectionSubHeader>Type of cookie:</Styled.SubSectionSubHeader>
        <p>iesnare.</p>
        <Styled.SubSectionSubHeader>
            What do they do?
        </Styled.SubSectionSubHeader>
        <p>We use the iesnare cookie to prevent fraud and protect customers.</p>
        <Styled.SubSectionSubHeader>
            How do we use this type of cookie?
        </Styled.SubSectionSubHeader>
        <p>
            <span>We use the iesnare cookie:</span>
        </p>
        <UnOrderedList>
            <li>to prevent money laundering;</li>
            <li>to verify customer identity and financial details; and</li>
            <li>to prevent fraudulent transactions.</li>
        </UnOrderedList>
        <Styled.SubSectionSubHeader>
            Do these cookies collect my personal data? Does the information get
            passed onto third parties?
        </Styled.SubSectionSubHeader>
        <p>
            The iesnare cookie collects your IP address along with certain
            device information. We pass information captured by the iesnare
            cookie (for example your device&apos;s IP address), onto Iovation (a
            third party organisation) as part of our efforts to ensure that
            customers are genuine, have not registered more than once or are not
            fraudulently trying to access accounts that do not belong to them.
        </p>

        <Styled.SubSectionHeader>Cookies explained</Styled.SubSectionHeader>
        <p>
            Neither we, nor the companies who show advertisements on our
            websites, sell personal data collected from cookies to any other
            organisations. Where we transfer any of your data to third parties,
            we do so in accordance with our Privacy Policy.
        </p>
        <p>
            It is easy for you to choose not to receive behavioural advertising
            and manage your cookie preferences. Please see the &quot;Controlling
            my Cookies&quot; section below for further details.
        </p>

        <Styled.SubSectionHeader>
            Controlling my Cookies
        </Styled.SubSectionHeader>

        <Styled.SubSectionHeader>
            How can I see and manage my cookies in my browser?
        </Styled.SubSectionHeader>
        <p>
            Virtually all modern browsers allow you to see what cookies you
            have, and to clear them individually or in their entirety. To find
            out how to do this, visit{' '}
            <a
                rel="noopener noreferrer"
                href="https://www.aboutcookies.org"
                target="_blank"
            >
                aboutcookies.org
            </a>
            , which contains comprehensive information about how to manage your
            cookie preferences on a wide variety of desktop browsers.
        </p>

        <Styled.SubSectionHeader>
            How can I choose not to receive Online Behavioural Advertising and
            other tracking cookies?
        </Styled.SubSectionHeader>
        <p>
            In addition to the controls available on your computer and through
            the Sky Betting &amp; Gaming websites, there are other ways of
            choosing not to receive Online Behavioural Advertising and other
            tracking cookies.
        </p>
        <p>
            Please note that most of these choices work by setting a cookie that
            remembers your choice of opting-out. If you clear all of your
            cookies, you will also clear these opt-out cookies. In this instance
            you would need to opt-out from Online Behavioural Advertising again.
        </p>
        <p>
            The following organisations provide more information about Online
            Behavioural Advertising:
        </p>
        <UnOrderedList>
            <li>
                <a
                    rel="noopener noreferrer"
                    href="http://www.youronlinechoices.com/uk/"
                    target="_blank"
                >
                    Your Online Choices
                </a>
                <span>
                    {' '}
                    - This allows you to choose not to receive behavioural
                    advertising from the majority of the advertising networks.
                </span>
            </li>
            <li>
                <a
                    rel="noopener noreferrer"
                    href="https://www.networkadvertising.org/"
                    target="_blank"
                >
                    Network Advertising Initiative (NAI)
                </a>
                <span>
                    {' '}
                    – Please see the &quot;opt-out&quot; page. This allows you
                    to opt-out of behavioural advertising from each of the ad
                    networks represented by the NAI.
                </span>
            </li>
            <li>
                <a
                    rel="noopener noreferrer"
                    href="http://www.edaa.eu/"
                    target="_blank"
                >
                    European Digital Advertising Alliance
                </a>
                <span>
                    {' '}
                    – Please see the Online Behavioural Advertising choices
                    page.
                </span>
            </li>
        </UnOrderedList>

        <Styled.SubSectionHeader>
            Can I disable cookies altogether rather than just deleting them?
        </Styled.SubSectionHeader>
        <p>
            Yes. If you would rather not let websites set any cookies at all on
            your browser or device you can follow the steps outlined below.
            Please note that this will affect the normal function of Sky Betting
            and Gaming websites and other websites that you might access.
        </p>
        <Styled.SubSectionSubHeader>
            Internet Explorer
        </Styled.SubSectionSubHeader>
        <p>
            <span>
                To prevent new cookies from being installed and delete existing
                cookies:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies"
                target="_blank"
            >
                http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies
            </a>
        </p>
        <Styled.SubSectionSubHeader>Firefox</Styled.SubSectionSubHeader>
        <p>
            <span>To prevent new cookies from being installed: </span>
            <a
                rel="noopener noreferrer"
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
            >
                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
        </p>
        <p>
            <span>To delete existing cookies: </span>
            <a
                rel="noopener noreferrer"
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                target="_blank"
            >
                https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </a>
        </p>
        <Styled.SubSectionSubHeader>Google Chrome</Styled.SubSectionSubHeader>
        <p>
            <span>
                To prevent new cookies from being installed and delete existing
                cookies:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="https://support.google.com/chrome/answer/95647?hl=en"
                target="_blank"
            >
                https://support.google.com/chrome/answer/95647?hl=en
            </a>
        </p>
        <Styled.SubSectionSubHeader>Safari</Styled.SubSectionSubHeader>
        <p>
            <span>
                To prevent new cookies from being installed and delete existing
                cookies:{' '}
            </span>
            <a
                rel="noopener noreferrer"
                href="http://help.apple.com/safari/mac/8.0/#/sfri11471"
                target="_blank"
            >
                http://help.apple.com/safari/mac/8.0/#/sfri11471
            </a>
        </p>
        <Styled.SubSectionHeader>
            How can I get more information?
        </Styled.SubSectionHeader>
        <p>
            Any queries or comments about this Policy should be sent to{' '}
            <a href="mailto:support@skybet.com?subject=Cookie%20Policy%20Query">
                support@skybet.com
            </a>{' '}
            with the subject line &quot;Cookie Policy Query&quot;.
        </p>
        <p>This Cookies Policy was last updated on 23 May 2016.</p>
    </Styled.PrivacyPageContainer>
);

export default PreviousPrivacy;
