import * as types from './types';

export const showModal = (modalType, modalProps, canDismiss) => ({
    type: types.SHOW,
    modalType,
    modalProps,
    canDismiss
});

export const dismissModal = () => ({
    type: types.DISMISS
});
