import styled from '@emotion/styled/macro';

export const ServiceMessage = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 30px 25px 60px;
    margin: 0 0 10px;
    box-shadow: 0 4px 9px 0 rgba(115, 115, 115, 0.5);
    background-color: #ffffff;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: 20px 30px;
    }
`;

export const InfoIconParent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const InfoIconRight = styled.div`
    margin-right: 20px;
    margin-left: 10px;
`;

export const CancelIconRight = styled.div`
    margin-left: auto;
    align-self: flex-start;
`;

export const InformationIcon = styled.div`
    width: 30px;
    min-width: 30px;
    height: 30px;
    object-fit: contain;
    background: #0d2051;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
`;

export const CloseButton = styled.button`
    background: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;

    transition: transform 0.2s ease-in-out;

    &:focus {
        outline: none;
    }

    &:hover {
        transform: scale(1.1);
    }
`;

export const Update = styled.div`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.xlarge};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0d2051;
`;

export const FixtureTextParent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        display: unset;
    }
`;
// TODO: conditionally remove 70% for mobile devices
export const FixtureTextLeft = styled.div`
    max-width: 640px;
    width: 70%;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        width: 90%;
        margin: 20px 0 20px 0;
    }
`;

export const FixtureTextRight = styled.div``;

export const FixtureText = styled.div`
    font-family: ${props => props.theme.fonts.normal};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0a0a0a;
    margin-left: 40px;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.medium};
        margin-left: 0;
    }
`;

export const SeeTCs = styled.div`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #27478f;
    a:link {
        color: #27478f;
    }
    a:visited {
        color: #27478f;
    }
    a:hover {
        color: #27478f;
    }
    a:active {
        color: #27478f;
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;
