/* eslint-disable indent */
import styled from '@emotion/styled/macro';

export const Container = styled.div`
    margin: 0 ${props => props.theme.spacing.normal};
    background: ${props => props.theme.colours.borderLightGrey};
    text-align: center;    
    ${props => props.maintainRadius && 'border-radius: 4px;'}

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        border-radius: 4px;
    }
`;

export const Header = styled.div`
    padding: ${props =>
        props.size === 'l'
            ? props.theme.spacing.large
            : props.theme.spacing.normal} 12px;
    background: ${props => props.theme.colours.lightGrey};
    justify-content: center;
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.header.textColor};
    
    ${props => props.maintainRadius && 'border-radius: 4px 4px 0 0;'}

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        border-radius: 4px 4px 0 0;
    }

    font-size: ${props =>
        props.size === 'l'
            ? props.theme.fontSize.large
            : props.theme.fontSize.medium};
`;
