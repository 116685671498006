import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const AmpersandIcon = ({ largeText }) => (
    <Styled.AmpersandContainer largeText={largeText}>
        <Styled.Character>&#38;</Styled.Character>
    </Styled.AmpersandContainer>
);

AmpersandIcon.propTypes = {
    largeText: PropTypes.bool
};

AmpersandIcon.defaultProps = {
    largeText: false
};

export default AmpersandIcon;
