import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import AppLink from '../../../Common/AppLink';
import Button from '../../../Common/Button/index';

const load = keyframes`
    from {
        left: -100px;
    }
    to {
        left: 100%;
    }
`;

export const InfoContainer = styled.div`
    display: flex;

    width: ${props => (props.variant === 'default' ? null : '40%')};
    height: auto;

    user-select: none;

    background: ${props => props.theme.hero[props.variant].colours.background};

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: 100%;
        flex: 1 1 auto;
        order: 2;

        border-bottom-right-radius: 0px;
        border-top-left-radius: 0;
    }
`;

export const InfoInner = styled.div`
    position: relative;
    overflow: hidden;

    flex-grow: 1;

    ${props =>
        props.variant === 'extra' &&
        `
        padding: 15px 38px 15px 40px;
        margin-right: 2px;
    `}

    color: ${props => props.theme.hero[props.variant].colours.font};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 14px;

    * {
        z-index: 1;
    }

    ${props =>
        props.variant === 'extra' &&
        `
        &:after {
            content: '';
            position: absolute;

            top: -50%;
            left: -100px;
            width: 200%;
            height: 100%;
            transform: rotate(-10deg);
            opacity: ${props.variant === 'default' ? '0.6' : '0.04'};
            background: white;

            @media (max-width: ${props.theme.hero.widths.mobileMax}) {
                top: -50%;
                transform: rotate(-10deg);
            }
        }
    `}

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        margin-right: 0;
        ${props =>
            props.variant === 'extra' &&
            `
            margin-top: 2px;

            padding: 10px;
        `}

    }
`;

export const Logo = styled.img`
    max-width: 200px;
    height: auto;

    pointer-events: none;

    @media (max-width: ${props => props.theme.widths.containerMaxWidth}) {
        width: 162px;
    }

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        height: 15%;
        width: auto;
    }
`;

export const PrizeContainer = styled.div`
    pointer-events: ${props => (props.variant === 'extra' ? 'none' : null)};
    width: 100%;

    ${props =>
        props.variant === 'default' &&
        `
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            width: 50%;
            height: 24%;
            align-self: center;
            position: absolute;
            bottom: 24px;
        }
        div > a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: ${props.theme.fonts.skySportsD};
            font-size: ${props.theme.fontSize.xxlarge};
            padding-top: ${props.theme.spacing.xlarge};
            padding-bottom: ${props.theme.spacing.xlarge};

        }
    `}

    @media (max-width: ${props => props.theme.hero.widths.tabletMax}) {
        width: ${props => (props.variant === 'extra' ? '70%' : '100%')};

        display: flex;
        justify-content: center;
        align-items: center;

        ${props =>
            props.variant === 'default' &&
            `
            div {
                display: flex;
                width: max-content;
                height: 100%;
                height: 24%;
                align-self: center;
                position: absolute;
                bottom: 18px;
            }
            div > a {
                display: flex;
                align-items: center;
                font-size: ${props.theme.fontSize.large};
            }
        `}
    }

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: ${props => (props.variant === 'extra' ? '70%' : '100%')};

        display: flex;
        justify-content: center;
        align-items: center;

        ${props =>
            props.variant === 'default' &&
            `
            div {
                display: flex;
                width: max-content;
                align-self: center;
                position: absolute;
                bottom: 12px;
            }
            div > a {
                display: flex;
                height: 26px;
                align-items: center;
                font-size: ${props.theme.fontSize.medium};
            }
        `}
    }

    ${props =>
        props.variant === 'default' &&
        `
        @media (max-width: ${props.theme.hero.widths.smallMobile}) {
            div {
                display: flex;
                width: max-content;
                align-self: center;
                position: absolute;
                bottom: 12px;
            }
            div > a {
                display: flex;
                height: 22px;
                align-items: center;
                font-size: ${props.theme.fontSize.small};
            }
        }
    `}
`;

export const PrizeImage = styled.img`
    width: 100%;
`;

export const InfoFooter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const HeroButtonWrapper = styled.div`
    background-color: ${props => props.theme.colours.colorOnPrimary};
    width: 100%;
    padding-top: ${props => props.theme.spacing.medium};
    padding-bottom: ${props => props.theme.spacing.medium};
`;

export const HeroButton = styled(Button)`
    width: max-content;
    ${props =>
        props.variant === 'default' &&
        `
            align-self: center;
            font-family: ${props.theme.fonts.skySportsD};
        }
    `}
`;

export const HeroAppButton = styled(AppLink)`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: 4px;
    color: ${props => props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours.greenDark};
    }
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing[props.sidePadding]};
`;

export const Info = styled.span`
    margin-top: 10px;

    align-self: left;

    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.medium};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        text-align: center;
    }

    @media (max-width: ${props => props.theme.hero.widths.smallMobileMax}) {
        font-size: ${props => props.theme.fontSize.xsmall};
    }
`;

export const LoadingInner = styled.div`
    display: flex;
    flex-direction: column;

    flex-grow: 1;

    margin: 15px 40px;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        margin: 15px;
    }
`;

const baseLoadingComponent = styled.div`
    position: relative;
    overflow: hidden;

    background: ${props => props.theme.hero.loading.colours.component};

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: 0;
        height: 100%;
        width: 100px;
        background: linear-gradient(
            to right,
            transparent 0%,
            ${props => props.theme.hero.loading.colours.background} 50%,
            transparent 100%
        );
        animation: ${load} 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
`;

export const LoadingLogo = styled(baseLoadingComponent)`
    height: 40px;
    width: 200px;
    margin-top: 20px;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: 162px;
        margin: 0 auto 5px;
    }
`;

export const LoadingPrize = styled.div`
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;
`;

export const LoadingPrizePrimary = styled(baseLoadingComponent)`
    height: 40px;
    width: 100%;

    margin-bottom: 10px;
`;

export const LoadingPrizeSecondary = styled(baseLoadingComponent)`
    height: 30px;
    width: 60%;
`;

export const LoadingButton = styled(baseLoadingComponent)`
    height: 50px;
    width: 100%;

    margin-top: 10px;
`;
