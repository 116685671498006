import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import MatchHeader from '../../../../components/NonLiveMatchHeader';
import MatchPrediction from './MatchPrediction';
import ConnectedMatchFooter from './MatchFooter';
import {
    getFormattedDate,
    getFormattedTime
} from '../../../../helpers/dateFormatters';

const Match = ({
    competitionName,
    kickOffDateTime,
    homeTeam,
    homeScore,
    awayTeam,
    awayScore,
    status,
    matchId,
    showStats,
    toggleStats,
    showPundits,
    togglePundits,
    showPopular,
    togglePopular,
    showBets,
    toggleBets,
    isMobile,
    index,
    setNavigationEvent,
    setInterfaceEvent
}) => {
    const [matchNode, setNode] = useState(0);

    const setScrollPosition = node => {
        if (node) {
            setNode(node);
        }
    };

    useEffect(() => {
        if ((showStats || showPundits || showPopular || showBets) && isMobile) {
            matchNode.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [showStats, showPundits, showPopular, showBets, isMobile, matchNode]);

    const formattedDate = getFormattedDate(kickOffDateTime);
    const formattedTime = getFormattedTime(kickOffDateTime)
        .split(':00')
        .join('');

    return (
        <Styled.MatchContainer data-test-id={`match-container-${index + 1}`}>
            <Styled.ScrollAnchor ref={setScrollPosition} />
            <MatchHeader
                competitionName={competitionName}
                kickOffDate={formattedDate}
                kickOffTime={formattedTime}
                matchIndex={index}
            />
            <MatchPrediction
                homeTeam={homeTeam}
                homeScore={homeScore}
                awayTeam={awayTeam}
                awayScore={awayScore}
                status={status}
                matchId={matchId}
            />
            <ConnectedMatchFooter
                toggleStats={toggleStats}
                showStats={showStats}
                togglePundits={togglePundits}
                showPundits={showPundits}
                togglePopular={togglePopular}
                showPopular={showPopular}
                toggleBets={toggleBets}
                showBets={showBets}
                matchId={matchId}
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                setNavigationEvent={setNavigationEvent}
                setInterfaceEvent={setInterfaceEvent}
            />
        </Styled.MatchContainer>
    );
};

Match.defaultProps = {
    homeScore: 0,
    awayScore: 0,
    setNavigationEvent: () => {},
    setInterfaceEvent: () => {}
};

Match.propTypes = {
    competitionName: PropTypes.string.isRequired,
    kickOffDateTime: PropTypes.string.isRequired,
    homeTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    homeScore: PropTypes.number,
    awayTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    awayScore: PropTypes.number,
    status: PropTypes.string.isRequired,
    matchId: PropTypes.number.isRequired,
    showStats: PropTypes.bool.isRequired,
    toggleStats: PropTypes.func.isRequired,
    showPundits: PropTypes.bool.isRequired,
    togglePundits: PropTypes.func.isRequired,
    showPopular: PropTypes.bool.isRequired,
    togglePopular: PropTypes.func.isRequired,
    showBets: PropTypes.bool.isRequired,
    toggleBets: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    setNavigationEvent: PropTypes.any,
    setInterfaceEvent: PropTypes.any
};

export default Match;
