import React from 'react';
import PropTypes from 'prop-types';
import RouteChange from './index';
import { sendPageView } from '../../helpers/GoogleAnalytics/gaHelper';

const Wrapper = ({ Component, title, ...remainingProps }) => (
    <>
        <RouteChange title={title} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...remainingProps} />
    </>
);

Wrapper.propTypes = {
    Component: PropTypes.elementType.isRequired,
    title: PropTypes.string
};

Wrapper.defaultProps = {
    title: null
};

const withRouteChange = title => Component => props => {
    sendPageView(title);
    return (
        <Wrapper Component={Component} title={title} {...props} /> // eslint-disable-line react/jsx-props-no-spreading
    );
};

export default withRouteChange;
