import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import store from './store/configureStore';
import OAuthRouteAppWrapper from './OAuthRouteAppWrapper';
import { addAuthInterceptor } from './api/axios';
import {
    detectDevice,
    getIdToken,
    isUsingIOSV3OrHigher
} from './helpers/appBridge';
import { getAuthService } from './auth/auth';
import metricApi from './api/metrics';
import { isFeatureOn } from './store/modules/content/selectors';
import { requestFeatureSwitches } from './store/modules/content/actions';
import { ENABLE_CLIENT_METRICS } from './features';
import { updateIdToken } from './store/modules/auth/actions';
import { getUserSessionId } from './helpers/sessionStorage';

// Do this pretty early as it's quite important
store.dispatch(requestFeatureSwitches());
const userSessionId = getUserSessionId();

const reactRoot = document.getElementById('react-root');

window.appBridge = {
    fire: (event, data) => {
        if (isFeatureOn(store.getState(), ENABLE_CLIENT_METRICS, true)) {
            metricApi.countMetric('early_appbridge', {
                event,
                data,
                userSessionId,
                userAgent: navigator.userAgent
            });
        }
    }
};

const errors = [];

window.onerror = (message, source, lineno, _, error) => {
    if (error.name === 'UserFriendlyError') {
        return;
    }
    const externalScript = message === 'Script error.' && source === '';

    if (
        isFeatureOn(store.getState(), ENABLE_CLIENT_METRICS, true) &&
        !externalScript
    ) {
        if (!errors.includes(message)) {
            errors.push(message);
            metricApi.countMetric('error', {
                message,
                source,
                lineno,
                userSessionId,
                userAgent: navigator.userAgent
            });
        }
    }
};

smoothscroll.polyfill();

if (reactRoot) {
    addAuthInterceptor(store);
    detectDevice(store);
    getAuthService().setDispatch(store.dispatch);

    if (isUsingIOSV3OrHigher()) {
        let idToken = getIdToken();

        if (!idToken) {
            idToken = sessionStorage.getItem('oauth_idToken');
        }

        if (idToken) {
            store.dispatch(updateIdToken(idToken));
        }
    }

    ReactDOM.render(
        <Provider store={store}>
            <OAuthRouteAppWrapper />
        </Provider>,
        reactRoot
    );
}
