import React from 'react';
import * as Styled from '../styles';

const Fixture = () => (
    <>
        <li>
            Super 6 Fixture: postponed, abandoned or incomplete &#40;Jackpot
            Competition Prizes of &#163;100,000 or more&#41;
            <Styled.OrderedList>
                <li>
                    If the Jackpot Prize is specified as totalling &#163;100,000
                    or more then the following provisions apply:
                </li>
                <li>
                    If only five Super 6 fixtures are completed during any
                    round, the &#163;100,000 Jackpot Competition Prize available
                    for that round will be reduced to &#163;20,000 and the Top
                    Scorer Prize will remain unchanged.
                </li>
                <li>
                    If only four Super 6 fixtures are completed during any
                    round, the Jackpot Competition Prize available for that
                    round will be reduced to &#163;5,000 and there will be no
                    Top Scorer Prize.
                </li>
                <li>
                    If three or fewer Super 6 fixtures are completed during any
                    round, the Jackpot Competition Prize and Top Scorer Prize
                    will be void for that round.
                </li>
            </Styled.OrderedList>
        </li>
        <li>
            Super 6 Fixture: postponed, abandoned or incomplete &#40;Jackpot
            Competition Prizes of &#163;50,000&#41;
            <Styled.OrderedList>
                <li>
                    If the Jackpot Competition Prize is specified as totalling
                    &#163;50,000 then the following rules will apply:
                </li>
                <li>
                    If only five Super 6 fixtures are completed during any
                    round, the &#163;50,000 Jackpot Competition Prize available
                    for that round will be reduced to &#163;5,000 and the Top
                    Scorer Prize will remain unchanged.
                </li>
                <li>
                    If only four Super 6 fixtures are completed during any
                    round, the Jackpot Competition Prize available for that
                    round will be reduced to &#163;1,000 and there will be no
                    Top Scorer Prize.
                </li>
                <li>
                    If three or fewer Super 6 fixtures are completed during any
                    round, the Jackpot Competition Prize and Top Scorer Prize
                    will be void for that round.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Fixture;
