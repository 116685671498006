import styled from '@emotion/styled/macro';
import AppLinkButton from '../Common/AppLink/styles';

export const OddsContainer = styled.div`
    height: 56px;
    display: grid;
    ${props =>
        !!props.isResulted &&
        `
            grid-template-columns: 0.9fr 1.1fr;
        `}
`;

export const LiveOddsLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        margin-top: ${props => props.theme.spacing.small};
        width: clamp(45px, 2vw, 70px);
    }
`;

export const LiveOddsTitle = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xxsmall}, 3vw, ${props.theme.fontSize.normal})`};
    font-weight: bold;
    color: ${props => props.theme.scores?.text};
    margin: 0;
    line-height: ${props => props.theme.lineHeight.normal};
    margin-right: ${props => props.theme.spacing.small};
`;

export const OutcomeList = styled.div`
    display: flex;
    padding-right: ${props => props.theme.spacing.large};
    justify-content: space-between;
    align-self: center;
`;

export const Outcome = styled.div`
    a,
    ${AppLinkButton} {
        text-decoration: none;
        font-family: ${props => props.theme.fonts.regular};
    }
`;

export const OutcomeBorder = styled.div`
    display: flex;
    border: 1px solid ${props => props.theme.scores?.border};
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: clamp(2px, 0.4vw, 5px);
    padding-right: clamp(2px, 0.4vw, 5px);
    background-color: ${props => props.theme.scores?.mainBgColour};
    justify-content: center;
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xxxsmall}, 3vw, ${props.theme.fontSize.normal})`};
    font-weight: 600;
`;

export const OutcomeName = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    color: ${props => props.theme.colours.black};
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const OutcomeLink = styled.div`
    line-height: ${props => props.theme.lineHeight.normal};
    font-weight: 700;
    color: #d92231;
`;
