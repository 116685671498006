import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

export default (
    state = {
        list: []
    },
    action
) => {
    switch (action.type) {
        case types.ADD_MESSAGE:
            if (
                !state.list.find(
                    item => item.message === action.payload.message
                )
            ) {
                return newIfChanged(state, {
                    ...state,
                    list: [
                        ...state.list,
                        {
                            ...action.payload,
                            id: Date.now()
                        }
                    ]
                });
            }
            return state;
        case types.DISMISS_MESSAGE:
            return newIfChanged(state, {
                ...state,
                list: state.list.filter(message => message.id !== action.id)
            });
        case types.ROUTE_CHANGE:
            /**
             * We decrement the number of remaining hops before dismiss
             * We dismiss any messages with only a single hop remaining
             */
            return newIfChanged(state, {
                ...state,
                list: state.list
                    .filter(message => message.hops > 1)
                    .map(message => ({
                        ...message,
                        hops: message.hops - 1
                    }))
            });
        default:
            return state;
    }
};
