import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Image from '../Common/Image';

export const FooterContainer = styled.div`
    background: ${props => props.theme.colours.lightGrey};
    color: ${props => props.theme.colours.textGrey};
    font-family: ${props => props.theme.fonts.regular};
    border-top: 1px solid ${props => props.theme.colours.borderGrey};
    padding: 20px 10px 30px;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const FooterRow = styled.div`
    margin-top: 10px;
    white-space: nowrap;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        display: flex;
    }
`;

export const Footer = styled.div`
    max-width: ${props => props.theme.widths.containerMaxWidth};
    margin: 0 auto;
    padding: 0;
`;

export const FooterItem = styled.span`
    display: block;
    margin-right: 10px;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        display: inline-block;
    }
`;

export const FooterLink = styled.a`
    color: inherit;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        line-height: 24px;
    }
`;

export const FooterButton = styled.button`
    background: transparent;
    border: none;
    color: inherit;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    padding: 0;
    margin: 0;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        line-height: 24px;
    }
    :hover {
        cursor: pointer;
    }
`;

export const FooterInternalLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        line-height: 24px;
    }
`;

export const SkyImage = styled(Image)`
    height: 15px;
    margin-right: 10px;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 1.2px;
`;

export const CookieButton = styled.button`
    background-color: ${props =>
        `${props.theme.colours.greenPrimary} !important`};
    border: none !important;
    color: ${props => `${props.theme.colours.colorOnPrimary} !important`};
    font-family: ${props => props.theme.fonts.medium};
`;
