import styled from '@emotion/styled/macro';

export const Alert = styled.div`
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #dfdfdf;
    background: #ffffff;
    border-radius: 4px;
`;

export const AlertWarning = styled(Alert)`
    border-color: #918a3f;
    color: #918a3f;
    background-color: #faf8eb;
`;
