export const User = '\uF006';
export const LeftChevron = '\uF024';
export const RightChevron = '\uF025';
export const DownChevron = '\uF022';
export const UpChevron = '\uF023';
export const Tick = '\uF114';
export const CircleTick = '\uF119';
export const CircleCross = '\uF118';
export const Cross = '\uF115';
export const StarFull = '\uF0D0';
export const StarOutline = '\uF0D1';
export const Search = '\uF026';
export const Trophy = '\uF0D4';
export const Delete = '\uF118';
export const PoundShield = '\uF30D';
export const Triangle = '\uF044';
