import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Profile from '../Profile';
import PredictionList from '../Prediction/list';
import { Icon } from '../../Common/Page/styles';
import { DownChevron, UpChevron } from '../../../styles/icons';

const PunditPredictions = ({ pundit, scoreChallenges }) => {
    const [hideContent, setHideContent] = useState(true);

    useEffect(() => {
        if (
            pundit.predictions &&
            pundit.predictions.scores &&
            pundit.predictions.scores.length > 0
        ) {
            setHideContent(true);
        }
    }, [pundit]);

    const hasPredictions = !!(
        pundit.predictions &&
        pundit.predictions.scores &&
        pundit.predictions.scores.length
    );

    return (
        <Styled.PredictionsCard
            data-test-id={`pundit-predictions-${pundit.id}`}
        >
            <Styled.PunditContainer>
                <Styled.HeadingContainer>
                    <Profile
                        name={pundit.name}
                        description={pundit.description}
                        avatarSrc={pundit.imageUri}
                    />

                    <Styled.Collapse
                        data-test-id={`pundit-drop-down-${pundit.id}`}
                        onClick={() => setHideContent(!hideContent)}
                    >
                        <Icon
                            data-char={hideContent ? DownChevron : UpChevron}
                        />
                    </Styled.Collapse>
                </Styled.HeadingContainer>
                <Styled.PunditCommentContainer hideContent={hideContent}>
                    <Styled.PunditComment data-test-id="pundit-comment">
                        {hasPredictions
                            ? `${pundit.comment ? `"${pundit.comment}"` : ''}`
                            : 'No predictions yet. Check back later.'}
                    </Styled.PunditComment>
                </Styled.PunditCommentContainer>
            </Styled.PunditContainer>
            <Styled.PredictionsContainer hideContent={hideContent}>
                <PredictionList
                    predictions={pundit.predictions}
                    scoreChallenges={scoreChallenges}
                    showBorderTop={
                        !hasPredictions || (hasPredictions && !!pundit.comment)
                    }
                />
            </Styled.PredictionsContainer>
        </Styled.PredictionsCard>
    );
};

PunditPredictions.propTypes = {
    pundit: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        imageUri: PropTypes.string.isRequired,
        comment: PropTypes.string,
        predictions: PropTypes.shape({
            scores: PropTypes.arrayOf(
                PropTypes.shape({
                    challengeId: PropTypes.number.isRequired,
                    scoreHome: PropTypes.number.isRequired,
                    scoreAway: PropTypes.number.isRequired
                })
            )
        }),
        type: PropTypes.string,
        typeImageUri: PropTypes.string,
        typeLink: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    scoreChallenges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    )
};

PunditPredictions.defaultProps = {
    scoreChallenges: []
};

export default PunditPredictions;
