import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export const dayMap = {
    '0': { full: 'Sunday', short: 'Sun' },
    '1': { full: 'Monday', short: 'Mon' },
    '2': { full: 'Tuesday', short: 'Tue' },
    '3': { full: 'Wednesday', short: 'Wed' },
    '4': { full: 'Thursday', short: 'Thu' },
    '5': { full: 'Friday', short: 'Fri' },
    '6': { full: 'Saturday', short: 'Sat' }
};

const monthMap = {
    '0': { full: 'January', short: 'Jan' },
    '1': { full: 'February', short: 'Feb' },
    '2': { full: 'March', short: 'Mar' },
    '3': { full: 'April', short: 'Apr' },
    '4': { full: 'May', short: 'May' },
    '5': { full: 'June', short: 'Jun' },
    '6': { full: 'July', short: 'Jul' },
    '7': { full: 'August', short: 'Aug' },
    '8': { full: 'September', short: 'Sep' },
    '9': { full: 'October', short: 'Oct' },
    '10': { full: 'November', short: 'Nov' },
    '11': { full: 'December', short: 'Dec' }
};

export const getShortDay = index => dayMap[index].short;
export const getShortMonth = index => monthMap[index].short;

export const getFormattedDateTime = dateTime =>
    dayjs(dateTime).format('ddd Do MMM YYYY, h:mma');

export const getFormattedLongDateTime = (dateTime, timeLast = false) => {
    if (timeLast) {
        return dayjs(dateTime).format('dddd Do MMMM YYYY, h:mma');
    }
    return dayjs(dateTime).format('h:mma, dddd Do MMMM YYYY');
};

export const getFormattedDate = dateTime =>
    dayjs(dateTime).format('ddd Do MMM YYYY');

export const getFormattedTime = dateTime => dayjs(dateTime).format('h:mma');

export const getFormattedShortDate = dateTime =>
    dayjs(dateTime).format('DD/MM/YY');

export const getFormattedShortDayTime = dateTime =>
    dayjs(dateTime).format('h:mma dddd');

export const parseCustomFormat = (dateTime, inputFormat) =>
    dayjs(dateTime, inputFormat);

export const convertCustomFormat = (
    dateTime,
    targetFormat,
    inputFormat = null
) => {
    const dateTimeToUse = inputFormat
        ? parseCustomFormat(dateTime, inputFormat)
        : dayjs(dateTime);

    return dateTimeToUse.format(targetFormat);
};
