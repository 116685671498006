import * as types from './types';

export const requestBanner = () => ({
    type: types.REQUEST_BANNER
});

export const receiveBanner = banner => ({
    type: types.RECEIVE_BANNER,
    data: banner
});

export const requestBannerId = () => ({
    type: types.REQUEST_BANNER_ID
});

export const receiveBannerId = banner => ({
    type: types.RECEIVE_BANNER_ID,
    data: banner
});

export const setBannerClose = (banner, customerId) => ({
    type: types.SET_BANNER_CLOSE,
    banner,
    customerId
});
