import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { requestUsersActiveRoundPredictions } from '../../store/modules/user/actions';
import {
    selectActiveRoundScoreChallenges,
    selectActiveRoundId
} from '../../store/modules/round/selectors';
import {
    selectHasPredicted,
    selectLoadingActiveRoundPredictions
} from '../../store/modules/user/selectors';
import { requestActiveRound } from '../../store/modules/round/actions';
import { selectIsLoggedIn } from '../../store/modules/auth/selectors';
import ConnectedPlay from './Play';
import ConnectedPlayed from './components/Played';
import LoadingSkeleton from './LoadingSkeleton';

const PredictedStatusWrapper = ({
    loadActiveRound,
    loadUserPredictions,
    hasPredicted,
    roundId,
    isLoggedIn,
    loadingPredictions
}) => {
    const { pathname } = useLocation();

    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    useEffect(() => {
        loadUserPredictions();
    }, [roundId, isLoggedIn]);

    if (loadingPredictions) {
        return <LoadingSkeleton />;
    }

    if (hasPredicted) {
        if (pathname !== '/played') {
            return <Redirect to="/played" />;
        }

        return <ConnectedPlayed />;
    }

    if (pathname !== '/play') {
        return <Redirect to="/play" />;
    }

    return <ConnectedPlay />;
};

PredictedStatusWrapper.defaultProps = {
    isLoggedIn: null,
    roundId: null,
    hasPredicted: null
};

PredictedStatusWrapper.propTypes = {
    isLoggedIn: PropTypes.bool,
    hasPredicted: PropTypes.bool,
    loadUserPredictions: PropTypes.func.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    roundId: PropTypes.number,
    loadingPredictions: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    roundId: selectActiveRoundId(state),
    activeRoundScoreChallenges: selectActiveRoundScoreChallenges(state),
    hasPredicted: selectHasPredicted(state),
    loadingPredictions: selectLoadingActiveRoundPredictions(state)
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound()),
    loadUserPredictions: () => dispatch(requestUsersActiveRoundPredictions())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PredictedStatusWrapper);
