import styled from '@emotion/styled/macro';

export const SubmittedPredictions = styled.div`
    background-color: white;
    border-radius: 20px;
    padding: 10px;
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${props => props.theme.spacing.xlarge};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-size: clamp(
        ${props => props.theme.fontSize.normal},
        3vw,
        ${props => props.theme.fontSize.medium}
    );
    font-family: ${props => props.theme.fonts.regular};
`;

export const Tick = styled.span`
    background: url('/content/img/icons/Tick_Outlined_Blue.svg');
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: ${props => props.theme.spacing.normal};
`;

export const Matches = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`;

export const MatchContainer = styled.div`
    flex: 1 0 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;
`;

export const Match = styled.div`
    width: fit-content;
    align-self: center;
`;

export const TeamContainer = styled.div``;

export const Scores = styled.div`
    text-align: center;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: clamp(
        ${props => props.theme.fontSize.normal},
        3vw,
        ${props => props.theme.fontSize.large}
    );
    font-weight: 400;
    color: ${props => props.theme.colours.colorOnPrimary};
    padding: 5px;
`;
