import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestMatchBets } from '../../../../../../store/modules/round/actions';
import { recordBetClick } from '../../../../../../store/modules/signal/actions';
import * as Styled from './styles';
import Spinner from '../../../../../../components/Common/Spinner';
import { makeBetsForMatchSelector } from '../../../../../../store/modules/round/selectors';
import { BET, buildTransferLink } from '../../../../../../helpers/transfer';
import {
    dcmpPrefixMap,
    affIdMap,
    buildDcmp,
    generateBetslipLink
} from '../../../../../../helpers/skyBetLinkHelper';
import AppLink from '../../../../../../components/Common/AppLink';
import { useCookieConsentContext } from '../../../../../../hooks/useCookieConsents';
import buildCookieUrl from '../../../../../../helpers/buildCookieUrl';
import FailureMessage from '../FailureMessage';
import { OAUTH_URI } from '../../../../../../config';
import eventContext from '../../../../../../helpers/GoogleAnalytics/event_context';
import elementText from '../../../../../../helpers/GoogleAnalytics/element_text';

export const MatchBets = ({
    matchId,
    eventId,
    getBets,
    rawBets,
    betsForPrediction,
    fullyRegistered,
    recordEnhancedBetClick,
    setNavigationEvent
}) => {
    useEffect(() => {
        getBets(matchId);
    }, [matchId, getBets]);

    if (!rawBets) {
        return <FailureMessage />;
    }

    if (rawBets.loading) {
        return (
            <Styled.BetsContainer>
                <Spinner colour="textTitle" />
            </Styled.BetsContainer>
        );
    }

    if (!betsForPrediction) {
        return (
            <Styled.BetsContainer>
                <Styled.NoBets>
                    No markets available for this event
                </Styled.NoBets>
            </Styled.BetsContainer>
        );
    }

    const { cookieConsentQuery } = useCookieConsentContext();
    return (
        <Styled.BetsContainer>
            <Styled.OutcomesContainer>
                <Styled.BetIconContainer>
                    <Styled.BetIcon
                        src="/img/products/sky-bet.png"
                        iconmaxwidth="50px"
                    />
                </Styled.BetIconContainer>
                {betsForPrediction.map((market, index) => (
                    <Styled.OutcomeContainer
                        key={market.name}
                        className="enhanced-bet--market"
                    >
                        <div>{market.name}</div>
                        <Styled.Description>
                            {market.outcome.desc}
                        </Styled.Description>
                        <div>
                            <Styled.OddsLink
                                onClick={() => {
                                    setNavigationEvent(
                                        elementText.navigation.inPlayOdds,
                                        index + 1,
                                        buildCookieUrl(
                                            OAUTH_URI,
                                            generateBetslipLink({
                                                affId: affIdMap.standard,
                                                dcmp: buildDcmp({
                                                    prefix:
                                                        dcmpPrefixMap.enhancedBet,
                                                    isPartReg: !fullyRegistered,
                                                    market: market.name
                                                }),
                                                selections: [market.outcome]
                                            }),
                                            cookieConsentQuery
                                        ),
                                        eventContext.skyBetReferral
                                    );
                                    recordEnhancedBetClick();
                                }}
                            >
                                <AppLink
                                    app="skybet"
                                    link={buildCookieUrl(
                                        OAUTH_URI,
                                        generateBetslipLink({
                                            affId: affIdMap.standard,
                                            dcmp: buildDcmp({
                                                prefix:
                                                    dcmpPrefixMap.enhancedBet,
                                                isPartReg: !fullyRegistered,
                                                market: market.name
                                            }),
                                            selections: [market.outcome]
                                        }),
                                        cookieConsentQuery
                                    )}
                                    testId="match-bet-market-skybet-link"
                                >
                                    {market.outcome.fraction}
                                </AppLink>
                            </Styled.OddsLink>
                        </div>
                    </Styled.OutcomeContainer>
                ))}
                <Styled.MoreInfo
                    onClick={() => {
                        setNavigationEvent(
                            elementText.navigation.moreMarkets,
                            betsForPrediction.length + 1,
                            buildCookieUrl(
                                OAUTH_URI,
                                buildTransferLink(
                                    BET,
                                    `/go/event/${eventId}?redirectPath=%2Fgo%2Fevent%2F${eventId}&AFF_ID=${
                                        affIdMap.standard
                                    }&dcmp=${buildDcmp({
                                        prefix: dcmpPrefixMap.moreMarkets,
                                        isPartReg: !fullyRegistered
                                    })}`
                                ),
                                cookieConsentQuery
                            ),
                            eventContext.skyBetReferral
                        );
                        recordEnhancedBetClick();
                    }}
                >
                    <AppLink
                        app="skybet"
                        link={buildCookieUrl(
                            OAUTH_URI,
                            buildTransferLink(
                                BET,
                                `/go/event/${eventId}?redirectPath=%2Fgo%2Fevent%2F${eventId}&AFF_ID=${
                                    affIdMap.standard
                                }&dcmp=${buildDcmp({
                                    prefix: dcmpPrefixMap.moreMarkets,
                                    isPartReg: !fullyRegistered
                                })}`
                            ),
                            cookieConsentQuery
                        )}
                        testId="match-bet-bottom-skybet-link"
                    >
                        For more markets visit <u>Sky Bet</u>
                    </AppLink>
                </Styled.MoreInfo>
            </Styled.OutcomesContainer>
        </Styled.BetsContainer>
    );
};

MatchBets.defaultProps = {
    rawBets: null,
    betsForPrediction: null,
    eventId: null,
    setNavigationEvent: () => {}
};

MatchBets.propTypes = {
    rawBets: PropTypes.shape({
        loading: PropTypes.bool,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                imageUri: PropTypes.string,
                scoreHome: PropTypes.number,
                scoreAway: PropTypes.number
            })
        )
    }),
    getBets: PropTypes.func.isRequired,
    matchId: PropTypes.number.isRequired,
    betsForPrediction: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            outcome: PropTypes.shape({
                desc: PropTypes.string,
                fraction: PropTypes.string
            })
        })
    ),
    eventId: PropTypes.number,
    fullyRegistered: PropTypes.bool.isRequired,
    recordEnhancedBetClick: PropTypes.func.isRequired,
    setNavigationEvent: PropTypes.any
};

const makeMapStateToProps = () => {
    const selectBetsForMatch = makeBetsForMatchSelector();
    return (state, props) => ({
        rawBets: state.round.bets[props.matchId],
        betsForPrediction: selectBetsForMatch(state, props),
        fullyRegistered: state.user.info.fullyRegistered
    });
};

const mapDispatchToProps = dispatch => ({
    getBets: matchId => dispatch(requestMatchBets(matchId)),
    recordEnhancedBetClick: () => dispatch(recordBetClick('enhanced'))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(MatchBets);
