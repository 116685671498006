import { parse } from 'querystring';
import oauthApi from '../api/oauth';
import { OAUTH_URI, OAUTH_CALLBACK_URI } from '../config';
import { setupOauthAppBridge } from '../helpers/appBridge';
import { setItem, getItem, removeItem } from '../helpers/sessionStorage';
import buildCookieUrl from '../helpers/buildCookieUrl';

const SESSION_ITEM_ACCESS_TOKEN = 'oauth_accessToken';
const SESSION_ITEM_ID_TOKEN = 'oauth_idToken';
const SESSION_ITEM_REFRESH_TOKEN = 'oauth_refreshToken';
const SESSION_ITEM_CODE_VERIFIER = 'oauth_codeVerifier';
const SESSION_ITEM_STATE = 'oauth_state';
const SESSION_ITEM_REDIRECT = 'oauth_postLoginRedirectPath';
const SESSION_ITEM_SILENT_AUTH_ATTEMPTED = 'oauth_silentAttempted';
const SESSION_ITEM_CHECK_AUTH_DISABLED = 'oauth_checkAuthDisabled';

let dispatch;
export const setDispatch = dispatchFunction => {
    dispatch = dispatchFunction;
};

const getRandomNumber = () => Math.floor(Math.random() * 1000000000);
const getRedirectUri = (challenge, state, silent = false) =>
    `${OAUTH_URI}/openid/v1/authorize?response_type=code&client_id=super6web&redirect_uri=${encodeURIComponent(
        OAUTH_CALLBACK_URI
    )}&scope=openid&code_challenge=${challenge}&code_challenge_method=S256&state=${state}${
        silent ? '&prompt=none' : ''
    }`;

export const storeTokensInBrowser = (token, idToken, refreshToken) => {
    setItem(SESSION_ITEM_ACCESS_TOKEN, token);
    setItem(SESSION_ITEM_ID_TOKEN, idToken);
    setItem(SESSION_ITEM_REFRESH_TOKEN, refreshToken);
};

export const getTokensFromBrowser = () => {
    const token = getItem(SESSION_ITEM_ACCESS_TOKEN);
    const idToken = getItem(SESSION_ITEM_ID_TOKEN);
    const refreshToken = getItem(SESSION_ITEM_REFRESH_TOKEN);
    if (token && idToken && refreshToken) {
        return { token, idToken, refreshToken };
    }
    return null;
};

export const getStateFromBrowser = () => getItem(SESSION_ITEM_STATE);

export const removeTokensFromBrowser = () => {
    removeItem(SESSION_ITEM_ACCESS_TOKEN);
    removeItem(SESSION_ITEM_ID_TOKEN);
    removeItem(SESSION_ITEM_REFRESH_TOKEN);
    removeItem(SESSION_ITEM_CODE_VERIFIER);
    removeItem(SESSION_ITEM_STATE);
    removeItem(SESSION_ITEM_REDIRECT);
};

const getConsentGroups = () =>
    window.TrackingConsent?.oneTrustSdkHasLoaded
        ? window.TrackingConsent.getConsentGroups().join(',')
        : '';

export const redirectToOauth = async (verifier, challenge, silent = false) => {
    setItem(SESSION_ITEM_CODE_VERIFIER, verifier);

    const state = getRandomNumber();
    setItem(SESSION_ITEM_STATE, String(state));
    if (window.location.pathname.startsWith('/oauth/skybet')) {
        setItem(SESSION_ITEM_REDIRECT, '/');
    } else {
        const { redirectTo } = parse(window.location.search.replace('?', ''));
        if (redirectTo && redirectTo.match('/[^/]+')) {
            setItem(SESSION_ITEM_REDIRECT, redirectTo);
        } else {
            setItem(
                SESSION_ITEM_REDIRECT,
                window.location.pathname + window.location.search
            );
        }
    }
    if (window.Android) {
        // send the user to the last page before we login to user
        // as temporary solution for the empty pages
        window.history.back();
        window.Android.login();
    } else {
        window.location.replace(
            buildCookieUrl(
                OAUTH_URI,
                getRedirectUri(challenge, state, silent),
                getConsentGroups()
            )
        );
    }
};

export const redirectToAccount = () => {
    const redirectBaseUrl = `${OAUTH_URI}/account?client_id=super6web&redirect_uri=${encodeURIComponent(
        window.location.href
    )}`;
    window.location.replace(
        buildCookieUrl(OAUTH_URI, redirectBaseUrl, getConsentGroups())
    );
};

export const init = async () => {
    setupOauthAppBridge(dispatch);
    return Promise.resolve();
};

const initiateOauthRedirect = async (silent = false) => {
    const { verifier, challenge } = await oauthApi.getVerifierCodes();
    return redirectToOauth(verifier, challenge, silent);
};

export const redirectToLogin = async () => initiateOauthRedirect();

export const silentAuth = () => {
    const hasAttempted = getItem(SESSION_ITEM_SILENT_AUTH_ATTEMPTED);
    const isDisabled = getItem(SESSION_ITEM_CHECK_AUTH_DISABLED);
    const shouldAttempt = window.location.pathname !== '/';
    if (hasAttempted || isDisabled || !shouldAttempt) {
        return Promise.resolve();
    }
    setItem(SESSION_ITEM_SILENT_AUTH_ATTEMPTED, '1');
    return initiateOauthRedirect(true);
};

export const stopCheckAuth = () =>
    setItem(SESSION_ITEM_CHECK_AUTH_DISABLED, '1');

export const shouldCheckAuth = () => {
    const isDisabled = getItem(SESSION_ITEM_CHECK_AUTH_DISABLED);

    return !isDisabled;
};

export const getSsoTransferToken = callback => {
    callback(null, null);
};
