import styled from '@emotion/styled/macro';

export const Trophy = styled.div`
    height: 56px;
    width: 56px;
    border-radius: 50%;

    margin-right: ${props => props.theme.spacing.large};
    background-color: ${props => props.theme.colours.backgroundGrey};

    i {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        font-size: ${props => props.theme.iconSize.xlarge};
    }
`;

export const SummaryInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 70px 1fr;
    padding: 0;
    gap: ${props => props.theme.spacing.medium};
`;

export const SummaryInfo = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;

    h3 {
        font-size: ${props => props.theme.fontSize.medium};
        font-family: ${props => props.theme.fonts.medium};
        color: ${props => props.theme.colours.black};
        margin-bottom: 0;
        line-height: ${props => props.theme.lineHeight.normal};
        padding-bottom: 2px;
    }

    p {
        color: ${props => props.theme.colours.black};
        font-size: ${props => props.theme.fontSize.normal};
        line-height: ${props => props.theme.lineHeight.normal};

        strong {
            font-weight: normal;
            font-family: ${props => props.theme.fonts.medium};
        }
    }
`;
