/* eslint-disable import/prefer-default-export */
export const headToHeadRules = {
    steps: [
        {
            title: 'STEP 1',
            text:
                'Enter your six predictions, completely free, as you normally would.'
        },
        {
            title: 'STEP 2',
            text:
                'After entering your predictions, you will also be automatically entered into the Head to Head Game. Here you will play against one of our expert pundits, giving you another chance to win prizes with no additional effort!'
        },
        {
            title: 'STEP 3',
            text:
                "Come back in play and after the round to see if you've scored more points than the pundit! If you do beat them, you're in a draw to win prizes. Look out for your name on the Prizes tab in Head to Head."
        }
    ]
};
