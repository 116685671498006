import styled from '@emotion/styled/macro';

export const Avatar = styled.div`
    display: block;
    width: 68px;
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    margin: 0 auto;
`;
