import { put, takeLeading, call, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as modalActions from '../modal/actions';
import * as types from './types';
import offerApi from '../../../api/offer';
import { selectToken } from '../auth/selectors';

export function* getGlobalBanner() {
    try {
        const token = yield select(selectToken);
        const data = yield call(offerApi.getGlobalBanner, token);
        yield put(actions.receiveGlobalBanner(data));
    } catch (err) {
        // We can ignore this error
    }
}

export function* getPostPredictionBanner() {
    try {
        const token = yield select(selectToken);
        const data = yield call(offerApi.getPostPredictionOffer, token);
        if (data) {
            yield put(
                modalActions.showModal('POST_PREDICTION_BANNER', data, true)
            );
        }
    } catch (err) {
        // We can ignore the error here, as the user doesn't need to know we
        // tried and failed to show them an advert
    }
}

export function* getBetslipOffer() {
    try {
        const token = yield select(selectToken);
        const data = yield call(offerApi.getBetslipOffer, token);
        if (data) {
            yield put(actions.receiveBetslipOffer(data));
        }
    } catch (err) {
        // We can ignore the error here, as the user doesn't need to know we
        // tried and failed to show them an advert
    }
}

export function* watchGetGlobalBanner() {
    yield takeLeading(types.REQUEST_GLOBAL_BANNER, getGlobalBanner);
}

export function* watchRequestPostPredictionBanner() {
    yield takeLeading(
        types.REQUEST_POST_PREDICTION_BANNER,
        getPostPredictionBanner
    );
}

export function* watchRequestBetslipOffer() {
    yield takeLeading(types.REQUEST_BETSLIP_OFFER, getBetslipOffer);
}

export const sagas = [
    watchGetGlobalBanner,
    watchRequestPostPredictionBanner,
    watchRequestBetslipOffer
];
