import React from 'react';
import { ContentPageContainer } from '../../../components/Common/Page/styles';
import * as Styled from './styles';
import PageTitle from '../../../components/Common/PageTitle';
import config from '../../../config';

const teams = [
    // Premier League
    { team: 'Arsenal', pin: 'ARS-S6' },
    { team: 'Aston Villa', pin: 'AVA-S6' },
    { team: 'Bournemouth', pin: 'BOU-S6' },
    { team: 'Brentford', pin: 'BRE-S6' },
    { team: 'Brighton and Hove Albion', pin: 'BHA-S6' },
    { team: 'Chelsea', pin: 'CHE-S6' },
    { team: 'Crystal Palace', pin: 'CRY-S6' },
    { team: 'Everton', pin: 'EVE-S6' },
    { team: 'Fulham', pin: 'FUL-S6' },
    { team: 'Ipswich Town', pin: 'IPS-S6' },
    { team: 'Leicester City', pin: 'LEI-S6' },
    { team: 'Liverpool', pin: 'LIV-S6' },
    { team: 'Manchester City', pin: 'MCI-S6' },
    { team: 'Manchester United', pin: 'MUN-S6' },
    { team: 'Newcastle United', pin: 'NEW-S6' },
    { team: 'Nottingham Forest', pin: 'NTG-S6' },
    { team: 'Southampton', pin: 'SOU-S6' },
    { team: 'Tottenham Hotspur', pin: 'TOT-S6' },
    { team: 'West Ham United', pin: 'WHU-S6' },
    { team: 'Wolverhampton Wanderers', pin: 'WLV-S6' },
    // Championship
    { team: 'Blackburn Rovers', pin: 'BBR-S6' },
    { team: 'Bristol City', pin: 'BSC-S6' },
    { team: 'Burnley', pin: 'BUR-S6' },
    { team: 'Cardiff City', pin: 'CDF-S6' },
    { team: 'Coventry City', pin: 'CVC-S6' },
    { team: 'Derby County', pin: 'DRB-S6' },
    { team: 'Hull City', pin: 'HUL-S6' },
    { team: 'Leeds United', pin: 'LEE-S6' },
    { team: 'Luton Town', pin: 'LUT-S6' },
    { team: 'Middlesbrough', pin: 'MID-S6' },
    { team: 'Millwall', pin: 'MLW-S6' },
    { team: 'Norwich City', pin: 'NOR-S6' },
    { team: 'Oxford United', pin: 'OXF-S6' },
    { team: 'Plymouth Argyle', pin: 'PLA-S6' },
    { team: 'Portsmouth ', pin: 'PTM-S6' },
    { team: 'Preston North End', pin: 'PNE-S6' },
    { team: 'Queens Park Rangers', pin: 'QPR-S6' },
    { team: 'Sheffield United', pin: 'SHU-S6' },
    { team: 'Sheffield Wednesday', pin: 'SHF-S6' },
    { team: 'Stoke City ', pin: 'STK-S6' },
    { team: 'Sunderland', pin: 'SUN-S6' },
    { team: 'Swansea City', pin: 'SWA-S6' },
    { team: 'Watford', pin: 'WAT-S6' },
    { team: 'West Bromwich Albion', pin: 'WBA-S6' },
    // League One
    { team: 'Barnsley', pin: 'BRS-S6' },
    { team: 'Birmingham City', pin: 'BRC-S6' },
    { team: 'Blackpool', pin: 'BLK-S6' },
    { team: 'Bolton Wanderers', pin: 'BOL-S6' },
    { team: 'Bristol Rovers', pin: 'BRR-S6' },
    { team: 'Burton Albion ', pin: 'BRA-S6' },
    { team: 'Cambridge United', pin: 'CMU-S6' },
    { team: 'Charlton Athletic', pin: 'CHA-S6' },
    { team: 'Crawley Town', pin: 'CRT-S6' },
    { team: 'Exeter City', pin: 'EXE-S6' },
    { team: 'Huddersfield Town', pin: 'HDD-S6' },
    { team: 'Leyton Orient', pin: 'LEY-S6' },
    { team: 'Lincoln City', pin: 'LNC-S6' },
    { team: 'Mansfield Town', pin: 'MSF-S6' },
    { team: 'Northampton Town', pin: 'NHT-S6' },
    { team: 'Peterborough United', pin: 'PTU-S6' },
    { team: 'Reading', pin: 'RDG-S6' },
    { team: 'Rotherham United', pin: 'RTU-S6' },
    { team: 'Shrewsbury Town', pin: 'SHT-S6' },
    { team: 'Stevenage ', pin: 'STE-S6' },
    { team: 'Stockport County', pin: 'STO-S6' },
    { team: 'Wigan Athletic', pin: 'WIG-S6' },
    { team: 'Wrexham', pin: 'WXM-S6' },
    { team: 'Wycombe Wanderers ', pin: 'WYC-S6' },
    // League Two
    { team: 'Accrington Stanley', pin: 'ACC-S6' },
    { team: 'AFC Wimbledon', pin: 'AWM-S6' },
    { team: 'Barrow', pin: 'BRW-S6' },
    { team: 'Bradford City', pin: 'BDC-S6' },
    { team: 'Bromley', pin: 'BRO-S6' },
    { team: 'Carlisle United', pin: 'CRU-S6' },
    { team: 'Cheltenham Town', pin: 'CTT-S6' },
    { team: 'Chesterfield', pin: 'CHD-S6' },
    { team: 'Colchester United', pin: 'CLU-S6' },
    { team: 'Crewe Alexandra', pin: 'CAX-S6' },
    { team: 'Doncaster Rovers', pin: 'DNR-S6' },
    { team: 'Fleetwood Town', pin: 'FLT-S6' },
    { team: 'Gillingham', pin: 'GLG-S6' },
    { team: 'Grimsby Town', pin: 'GRI-S6' },
    { team: 'Harrogate Town', pin: 'HAT-S6' },
    { team: 'Milton Keynes Dons', pin: 'MKD-S6' },
    { team: 'Morecambe', pin: 'MRC-S6' },
    { team: 'Newport County AFC', pin: 'NPT-S6' },
    { team: 'Notts County', pin: 'NTC-S6' },
    { team: 'Port Vale', pin: 'PTV-S6' },
    { team: 'Salford City', pin: 'SAL-S6' },
    { team: 'Swindon Town', pin: 'SWI-S6' },
    { team: 'Tranmere Rovers', pin: 'TRR-S6' },
    { team: 'Walsall', pin: 'WSL-S6' }
];

const Terms = () => (
    <ContentPageContainer>
        <PageTitle>Official Club Leagues</PageTitle>
        <Styled.LeaguesList>
            {teams.map(({ team, pin }) => (
                <Styled.League key={pin} data-test-id="club-league">
                    <Styled.BadgeContainer>
                        <Styled.Badge
                            src={`${config.getS3BucketPath()}/league/${pin}.png`}
                            alt={team}
                        />
                    </Styled.BadgeContainer>
                    <Styled.TeamPinContainer>
                        <Styled.Team>{team}</Styled.Team>
                        <Styled.Pin>Pin: {pin}</Styled.Pin>
                        <Styled.JoinMobile
                            link={`/league/create-join?code=${pin}`}
                            slim
                        >
                            Join League
                        </Styled.JoinMobile>
                    </Styled.TeamPinContainer>
                    <Styled.JoinDesktop
                        link={`/league/create-join?code=${pin}`}
                    >
                        Join League
                    </Styled.JoinDesktop>
                </Styled.League>
            ))}
        </Styled.LeaguesList>
    </ContentPageContainer>
);

export default Terms;
