import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    referees: [],
    loading: false,
    referralSet: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REQUEST_REFEREES:
            return newIfChanged(state, {
                ...state,
                loading: true
            });
        case types.RECEIVE_REFEREES:
            return newIfChanged(state, {
                ...state,
                loading: false,
                referees: action.payload
            });
        case types.POST_SET_REFERRER:
            return newIfChanged(state, {
                ...state,
                referralSet: true
            });
        default:
            return state;
    }
};
