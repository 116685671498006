import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    selectStatsForSelf,
    selectLoadingStatsForSelf
} from '../../store/modules/stats/selectors';
import { requestStatsForSelf } from '../../store/modules/stats/actions';
import View from './view';

const mapEntryToRound = ({ roundId, roundDate, didEnter, totalPoints }) => ({
    id: `${roundId}`,
    name: `Round ${roundId}`,
    closeDate: new Date(roundDate),
    entered: didEnter,
    score: totalPoints
});

const getCurrentRound = rounds =>
    rounds.length > 0 && rounds[0].closeDate > new Date()
        ? rounds[0]
        : undefined;

const getTotalRoundsEntered = rounds =>
    rounds.filter(({ entered }) => entered).length;

export const ScheduleComponent = ({ getStatsForSelf, stats, isLoading }) => {
    useEffect(() => {
        getStatsForSelf(true);
    }, [getStatsForSelf]);

    if (!stats.entries || isLoading) {
        return <View isLoading={isLoading} />;
    }

    const rounds = [...stats.entries].reverse().map(mapEntryToRound); // .reverse() mutates the original array, we do not want that so we make a new array first!

    return (
        <View
            rounds={rounds}
            currentRound={getCurrentRound(rounds)}
            averagePoints={stats.averagePoints}
            totalRoundsEntered={getTotalRoundsEntered(rounds)}
        />
    );
};

ScheduleComponent.defaultProps = {
    isLoading: true,
    stats: {}
};

ScheduleComponent.propTypes = {
    isLoading: PropTypes.bool,
    stats: PropTypes.shape({
        entries: PropTypes.arrayOf(
            PropTypes.shape({
                roundId: PropTypes.number.isRequired,
                roundDate: PropTypes.string.isRequired,
                didEnter: PropTypes.bool.isRequired,
                totalPoints: PropTypes.number
            })
        ),
        averagePoints: PropTypes.number
    }),
    getStatsForSelf: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: selectLoadingStatsForSelf(state),
    stats: selectStatsForSelf(state)
});

const mapDispatchToProps = dispatch => ({
    getStatsForSelf: force => dispatch(requestStatsForSelf(force))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleComponent);
