import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { Icon } from '../Common/Page/styles';
import { Cross } from '../../styles/icons';

/**
 * Given this cookie a name that is specific to the version of the cookie notice.
 * If the cookie notice content changes, you can update this cookie name to
 * reflect the latest version of the content, then users will be shown the
 * updated cookie notice and have to dismiss it again.
 */

const ServiceMessage = ({ onClickCancel, messageTitle, messageText }) => (
    <Styled.ServiceMessage data-test-id="service-banner-container">
        <Styled.InfoIconParent>
            <Styled.InformationIcon>
                <span>i</span>
            </Styled.InformationIcon>
            <Styled.InfoIconRight>
                <Styled.Update>{messageTitle}</Styled.Update>
            </Styled.InfoIconRight>
            <Styled.CancelIconRight>
                <Styled.CloseButton
                    onClick={onClickCancel}
                    aria-label="close service banner"
                    data-test-id="service-banner-close"
                >
                    <Icon data-char={Cross} />
                </Styled.CloseButton>
            </Styled.CancelIconRight>
        </Styled.InfoIconParent>
        <Styled.FixtureTextParent>
            <Styled.FixtureTextLeft>
                <Styled.FixtureText>{messageText}</Styled.FixtureText>
            </Styled.FixtureTextLeft>
            <Styled.SeeTCs>
                <a href="https://super6.skysports.com/terms">See T&C’s</a>
            </Styled.SeeTCs>
        </Styled.FixtureTextParent>
    </Styled.ServiceMessage>
);

ServiceMessage.propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    messageTitle: PropTypes.string.isRequired,
    messageText: PropTypes.string.isRequired
};

export default ServiceMessage;
