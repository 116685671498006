import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Avatar = ({ src, alt }) => (
    <Styled.Avatar>
        <Styled.Image src={src} alt={alt} draggable={false} />
    </Styled.Avatar>
);

Avatar.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

Avatar.defaultProps = {
    alt: ''
};

export default Avatar;
