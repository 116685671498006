const getPredictionsFromPayload = payload => {
    const defaultPredictionsObject = {
        hasPredicted: payload.hasPredicted,
        hasEnteredHeadToHead: payload.hasEnteredHeadToHead,
        resultPoints: payload.results,
        scorePoints: payload.scores,
        totalPoints: payload.total
    };

    if (!payload.predictions) {
        return {
            ...defaultPredictionsObject,
            matches: {},
            goldenGoal: null
        };
    }

    return payload.predictions.scores.reduce(
        (predictionObj, challenge) => ({
            ...predictionObj,
            matches: {
                ...predictionObj.matches,
                [challenge.matchId]: challenge
            }
        }),
        {
            ...defaultPredictionsObject,
            goldenGoal: payload.predictions.goldenGoal.time
        }
    );
};

export default getPredictionsFromPayload;
