import standardiseToLowerCase from '../standardiseToLowerCase';

const openOrNotPlayed = ['open', 'abandoned', 'postponed'];
const noPointsText = ({ hasPredicted, roundStatus }) => {
    if (typeof roundStatus !== 'string') {
        throw new Error('Invalid type provided for roundStatus');
    }

    const roundIsOpenOrNotPlayed = openOrNotPlayed.includes(() =>
        standardiseToLowerCase(roundStatus)
    );

    if (roundIsOpenOrNotPlayed) {
        return '-';
    }

    return hasPredicted ? '0' : '-';
};

export default noPointsText;
