import axios from 'axios';
import { API_ROOT } from '../config';

const metricsApi = {
    countMetric: async (key, labels) => {
        const requestBody = { key };
        if (labels) {
            requestBody.labels = labels;
        }
        return axios.post(`${API_ROOT()}/client-metrics/count`, requestBody);
    },
    lcpMetric: async (page, value, device) =>
        axios.post(`${API_ROOT()}/client-metrics/lcp`, { page, value, device })
};

export default metricsApi;
