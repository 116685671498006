import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const MyPositionTable = styled.div`
    margin: ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.card.borderRadius};
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.medium};
`;

export const MyPositionTitle = styled.h3`
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
    font-family: ${props => props.theme.fonts.bold};
    line-height: ${props => props.theme.lineHeight.normal};
    padding-bottom: ${props => props.theme.spacing.large};
    color: ${props =>
        props.justMe ? props.theme.colours.black : props.theme.colours.black};
`;

export const ResultTable = styled.table`
    overflow-x: hidden;
    width: 100%;
    background-color: ${props => props.theme.colours.borderGrey};
    border-collapse: collapse;
    font-size: ${props => props.theme.fontSize.medium};
    border-radius: ${props => props.theme.card.borderRadius};

    i {
        font-size: ${props => props.theme.iconSize.large};
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        i {
            font-size: ${props => props.theme.iconSize.large};
        }

        font-size: ${props => props.theme.fontSize.normal};
    }
`;

export const ResultRow = styled.tr`
    border-radius: ${props => props.theme.card.borderRadius};
    background-color: ${props => props.theme.colours.bluePrimary};
    font-family: ${props => props.theme.fonts.medium};
    text-transform: uppercase;
    line-height: 32px;
`;

export const ScoreColumn = styled.td`
    height: 38px;
    vertical-align: middle !important;
    width: 32px;
    text-align: ${props => (props.isName ? 'left' : 'center')};
    background-color: ${props =>
        props.outsideOfRow && props.theme.colours.borderGrey};
    border-top-left-radius: ${props =>
        props.startOfRow && props.theme.card.borderRadius};
    border-bottom-left-radius: ${props =>
        props.startOfRow && props.theme.card.borderRadius};

    ${props =>
        props.hideOnSmall
            ? `
        @media (max-width: ${props.theme.widths.mobileMax}) {
            display: none;
        }
    `
            : ''}

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        text-align: ${props => (props.isName ? 'left' : 'center')};
        width: ${props => !props.isName && '30px'};
    }
`;

export const PointsColumn = styled(ScoreColumn)`
    border-top-right-radius: ${props => props.theme.card.borderRadius};
    border-bottom-right-radius: ${props => props.theme.card.borderRadius};
`;

export const MyPositionLink = styled(Link)`
    display: block;
    width: 100%;
    color: ${props => props.theme.colours.white};
    line-height: ${props => props.theme.lineHeight.normal};
    text-decoration: none;
    box-sizing: border-box;
`;
