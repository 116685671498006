import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectedMatchBets from './MatchTabs/Bets/MatchBets';
import ConnectedMatchStats from './MatchTabs/Stats';
import ConnectedMatchPundits from './MatchTabs/Pundits';
import ConnectedMatchPopular from './MatchTabs/Popular';
import * as Styled from './styles';
import { teamProp } from './MatchTabs/Stats/propTypes';
import actions from '../../../../helpers/GoogleAnalytics/actions';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

// eslint-disable-next-line import/no-unused-modules
export const MatchFooter = ({
    canBet,
    showStats,
    toggleStats,
    showPundits,
    togglePundits,
    showPopular,
    togglePopular,
    showBets,
    toggleBets,
    matchId,
    homeTeam,
    awayTeam,
    setNavigationEvent,
    setInterfaceEvent
}) => {
    const tabs = [
        {
            displayName: 'Stats',
            name: 'stats',
            icon: '/img/play/stats-icon.svg',
            iconMaxWidth: '14px',
            alt: 'Stats Icon',
            component: (
                <ConnectedMatchStats
                    matchId={matchId}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                />
            ),
            toggle: toggleStats,
            show: showStats
        },
        {
            displayName: 'Pundits',
            name: 'pundits',
            icon: '/img/play/pundits-icon.svg',
            iconMaxWidth: '18px',
            alt: 'Pundits Icon',
            component: <ConnectedMatchPundits matchId={matchId} />,
            toggle: togglePundits,
            show: showPundits
        },
        {
            displayName: 'Popular',
            name: 'popular',
            icon: '/img/play/popular-icon.svg?test=123',
            iconMaxWidth: '14px',
            alt: 'Popular Icon',
            component: (
                <ConnectedMatchPopular
                    matchId={matchId}
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                />
            ),
            toggle: togglePopular,
            show: showPopular
        },
        {
            displayName: 'Bets',
            name: 'bets',
            icon: '/img/products/sky-bet.png',
            iconMaxWidth: '42px',
            alt: 'Sky Bet Logo',
            display: canBet,
            component: (
                <ConnectedMatchBets
                    matchId={matchId}
                    setNavigationEvent={setNavigationEvent}
                />
            ),
            toggle: toggleBets,
            show: showBets
        }
    ];

    return (
        <Styled.MatchFooter>
            <Styled.TabHeader>
                {tabs.map(
                    tab =>
                        tab.display !== false && (
                            <Styled.TabHeading
                                key={tab.name}
                                onClick={() => {
                                    tab.toggle();
                                    setInterfaceEvent(
                                        elementText.interface[tab.name],
                                        actions.clicked
                                    );
                                }}
                                isSelected={tab.show}
                            >
                                <Styled.TabHeadingIcon
                                    src={tab.icon}
                                    alt={tab.alt}
                                    iconmaxwidth={tab.iconMaxWidth}
                                />
                                <Styled.TabTitle>
                                    {tab.displayName}
                                </Styled.TabTitle>
                            </Styled.TabHeading>
                        )
                )}
            </Styled.TabHeader>
            {tabs.find(tab => tab.show === true) && (
                <Styled.TabBody>
                    {tabs.find(tab => tab.show === true).component}
                </Styled.TabBody>
            )}
        </Styled.MatchFooter>
    );
};

MatchFooter.propTypes = {
    canBet: PropTypes.bool.isRequired,
    showStats: PropTypes.bool.isRequired,
    toggleStats: PropTypes.func.isRequired,
    showPundits: PropTypes.bool.isRequired,
    togglePundits: PropTypes.func.isRequired,
    showPopular: PropTypes.bool.isRequired,
    togglePopular: PropTypes.func.isRequired,
    showBets: PropTypes.bool.isRequired,
    toggleBets: PropTypes.func.isRequired,
    matchId: PropTypes.number.isRequired,
    homeTeam: teamProp.isRequired,
    awayTeam: teamProp.isRequired,
    setNavigationEvent: PropTypes.any,
    setInterfaceEvent: PropTypes.any
};

MatchFooter.defaultProps = {
    setNavigationEvent: () => {},
    setInterfaceEvent: () => {}
};

const mapStateToProps = state => ({
    canBet: !!state.user.info.canBet
});

export default connect(mapStateToProps)(MatchFooter);
