import styled from '@emotion/styled/macro';
import { keyframes, css } from '@emotion/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Needed for react-responsive-carousel https://github.com/leandrowd/react-responsive-carousel#usage

export const CarouselPage = styled.div`
    display: flex;

    width: 100%;

    & > * {
        margin: ${props => props.multi && props.theme.spacing.medium};
    }

    user-select: none;

    padding-bottom: ${props => (props.multi ? '20px' : 'auto')};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        margin-bottom: 20px;
    }
`;

export const DummyHeroTile = styled.div`
    height: 1px;
    width: 100%;
`;

export const RoundsCarousel = styled(Carousel)`
    &,
    & > .carousel,
    & > .carousel > .slider,
    & > .carousel > .slider-wrapper {
        overflow: hidden;
    }
    margin-top: ${props => props.theme.spacing.medium};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        margin-top: 20px;
    }
`;

export const Pip = styled.li`
    height: 16px;
    width: 16px;
    background: ${props => (props.highlighted ? '#04a04a' : '#eaeaea')};
    border-radius: 50%;
    display: inline-block;
    cursor: ${props => (props.highlighted ? 'default' : 'pointer')};
    margin: -5px 3px;
`;

export const Arrow = styled.li`
    display: inline-block;
    margin: 0 50px;
`;

export const ArrowBody = styled.div`
    font-family: ${props => props.theme.fonts.skycons};
    font-size: ${props => props.theme.iconSize.normal};
    color: white;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    width: 31px;
    background: ${props => (props.hasNext ? '#132773' : '#c0c0c0')};
    border-radius: 50%;
    cursor: ${props => (props.hasNext ? 'pointer' : 'default')};
`;

const loadingKeyframes = keyframes`
    from {
        left: -300px;
    }
    to {
        left: 100%;
    }
`;

const animation = css`
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: 0;
        height: 100%;
        width: 300px;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 33%,
            rgba(255, 255, 255, 0.4) 67%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: ${loadingKeyframes} 1.5s linear infinite;
    }
`;

export const LoadingPlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-bottom: 20px;
    margin-top: ${props => props.theme.spacing.medium};

    max-width: 500px;
`;

export const LoadingPlaceholderCard = styled.div`
    width: 100%;
    height: ${props => (props.isMobile ? '440px' : '600px')};

    border-radius: 5px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;
    max-height: 100%;

    ${animation}
`;

export const LoadingPlaceholderButton = styled.div`
    width: 100%;
    height: 50px;
    margin-top: ${props => props.theme.spacing.large};

    border-radius: 5px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;

    ${animation}
`;

export const LoadingPlaceholderText = styled.div`
    width: 50%;
    height: 12px;
    margin-top: ${props => props.theme.spacing.large};

    border-radius: 5px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;

    ${animation}
`;

export const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    & > * {
        margin: ${props => props.theme.spacing.medium};
    }
    margin-bottom: 20px;
    padding-bottom: 20px;
`;
