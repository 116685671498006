import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postPredictions } from '../../store/modules/play/actions';
import Button from '../../components/Common/Button';
import * as Styled from './styles';
import { triggerCountMetric } from '../../store/modules/metrics/actions';
import ConnectedRoundFixtures from './components/RoundFixtures/RoundFixtures';
import ConnectedGoldenGoalPrediction from './components/GoldenGoal/GoldenGoalPrediction';
import ConnectedDeadlineTitle from '../../components/DeadlineTitle';
import ConnectedHeadToHeadBanner from '../../components/HeadToHeadBanner';
import {
    sendNavigationEvent,
    sendInterfaceEvent
} from '../../helpers/GoogleAnalytics/gaHelper';
import elementText from '../../helpers/GoogleAnalytics/element_text';
import modules from '../../helpers/GoogleAnalytics/modules';
import eventContext from '../../helpers/GoogleAnalytics/event_context';

const Play = ({
    submitPredictions,
    isSubmitting,
    triggerPlayPageMetric,
    goldenGoalTime
}) => {
    const disabled = isSubmitting;
    const [errorState, setErrorState] = useState(false);

    const checkInputValue = () => {
        if (goldenGoalTime) {
            if (disabled) {
                return;
            }
            setErrorState(false);
            submitPredictions();
        } else {
            setErrorState(true);
        }
    };

    useEffect(() => {
        triggerPlayPageMetric();
    }, [triggerPlayPageMetric]);

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.playFlow.enterPredictions,
            1,
            text,
            position,
            destinationUrl,
            context
        );
    };
    const setInterfaceEvent = (text, action) => {
        sendInterfaceEvent(text, action, modules.playFlow.enterPredictions);
    };

    return (
        <Styled.PageContainer>
            <Styled.DeadlineTitle>
                <ConnectedDeadlineTitle />
            </Styled.DeadlineTitle>
            <Styled.MainContent>
                <Styled.RoundFixtures>
                    <ConnectedRoundFixtures
                        setNavigationEvent={setNavigationEvent}
                        setInterfaceEvent={setInterfaceEvent}
                    />
                </Styled.RoundFixtures>
                <Styled.ColumnContainer>
                    <ConnectedGoldenGoalPrediction errorState={errorState} />
                    <Styled.ButtonWrapper>
                        <Button
                            onClick={() => {
                                sendNavigationEvent(
                                    modules.playFlow.submitPredictions,
                                    3,
                                    elementText.navigation.submitPredictions,
                                    1,
                                    'https://super6.skysports.com/played?submitted=1',
                                    eventContext.entryFirst
                                );
                                checkInputValue();
                            }}
                            disabled={disabled}
                            fontSize="large"
                            id="js-fixtures-submit-entry"
                            data-test-id="predictions-submit-button"
                        >
                            SUBMIT PREDICTIONS
                        </Button>
                    </Styled.ButtonWrapper>
                    <ConnectedHeadToHeadBanner />
                </Styled.ColumnContainer>
            </Styled.MainContent>
        </Styled.PageContainer>
    );
};

Play.propTypes = {
    submitPredictions: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    triggerPlayPageMetric: PropTypes.func.isRequired,
    goldenGoalTime: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    isSubmitting: state.play.isSubmitting,
    goldenGoalTime: state.play.goldenGoal.time
});

const mapDispatchToProps = dispatch => ({
    submitPredictions: () => dispatch(postPredictions()),
    triggerPlayPageMetric: () =>
        dispatch(triggerCountMetric('play_flow_started'))
});

export default connect(mapStateToProps, mapDispatchToProps)(Play);
