import { getLCP } from 'web-vitals';
import { isFeatureOn } from '../store/modules/content/selectors';
import store from '../store/configureStore';
import { ENABLE_LCP_METRICS } from '../features';
import { LCP_METRICS_PERCENTAGE } from '../config';

// eslint-disable-next-line import/prefer-default-export
export const trackLCP = async callback => {
    getLCP(({ value }) => {
        if (
            isFeatureOn(store.getState(), ENABLE_LCP_METRICS, false) &&
            Math.random() <= LCP_METRICS_PERCENTAGE
        ) {
            try {
                callback(value);
            } catch (e) {
                // no op
            }
        }
    });
};
