import * as types from './types';

export const recordBetClick = betType => ({
    type: types.SIGNAL_RECORD_BET_CLICK,
    betType
});

export const recordEnterRound = roundNumber => ({
    type: types.SIGNAL_RECORD_ENTER_ROUND,
    roundNumber
});

export const recordLogin = (data, isSso) => ({
    type: types.SIGNAL_RECORD_LOGIN,
    data,
    isSso
});

export const recordPageLoad = data => ({
    type: types.SIGNAL_RECORD_PAGE_LOAD,
    data
});
