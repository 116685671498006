import React from 'react';
import PropTypes from 'prop-types';
import HeroTile from '../../../../components/HeroTile';
import HeroImage from './HeroImage';
import InfoContainer from './InfoContainer';
import GenericSuper6Hero from '../../../../components/Super6Hero';

const Super6Hero = ({ activeRound, isSeasonClosed }) => {
    if (!activeRound) {
        return (
            <HeroTile
                isLoading
                link=""
                prizeImagePaths={null}
                logoPath="/content/img/logos/super6-extra.png"
                heroImagePaths={null}
            />
        );
    }

    return (
        <GenericSuper6Hero
            isSeasonClosed={isSeasonClosed}
            info={<InfoContainer />}
            hero={<HeroImage />}
        />
    );
};

Super6Hero.propTypes = {
    activeRound: PropTypes.shape({}),
    isSeasonClosed: PropTypes.bool
};

Super6Hero.defaultProps = {
    activeRound: null,
    isSeasonClosed: false
};

export default Super6Hero;
