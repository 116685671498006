import styled from '@emotion/styled/macro';

export const Pundits = styled.div`
    justify-content: center;
    width: 100%;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.spacing.xxlarge};
`;

export const HeaderText = styled.h2`
    font-weight: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.large};
    text-align: center;
    letter-spacing: 0.08em;
    color: ${props => props.theme.colours.black};
`;

export const PunditName = styled.p`
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    letter-spacing: 0.08em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-weight: 400;
    padding: 0 5px;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        display: inline;
        padding: 0;
    }
`;

export const PunditPrediction = styled.p`
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    text-align: center;
    letter-spacing: 0.08em;
    color: ${props => props.theme.colours.colorOnPrimary};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.skySportsD};
`;

export const PunditsContainer = styled.div`
    display: flex;
    text-align: center;
    flex-flow: row wrap;
    overflow: hidden;
    justify-content: space-between;
`;

export const PunditAvatar = styled.img`
    border: ${props => `1px solid ${props.theme.colours.brightGray}`};
    border-radius: 50%;
    width: ${props => props.theme.iconSize.xlarge};
    height: ${props => props.theme.iconSize.xlarge};
    object-fit: cover;
`;

export const Pundit = styled.div`
    border-left: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    margin-top: ${props => props.theme.spacing.normal};
    margin-bottom: ${props => props.theme.spacing.normal};
    margin-left: ${props => `-${props.theme.spacing.xsmall}`};
`;

export const PunditContainer = styled.div`
    flex: ${props => `1 0 ${props.numOfPundits === 4 ? 49 : 33}%`};
    border-bottom: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    margin-bottom: ${props => `-${props.theme.spacing.xsmall}`};
`;

export const NoPundits = styled.div`
    padding: ${props =>
        `${props.theme.spacing.large} ${props.theme.spacing.xlarge}`};
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
`;
