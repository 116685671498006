import styled from '@emotion/styled/macro';

export const AccountLink = styled.a`
    color: ${props => props.theme.colours.header.navLink};
    display: flex;
    height: 44px;
    align-items: center;
    font-size: ${props => props.theme.fontSize.medium};
    text-decoration: none;

    span {
        display: none;

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            display: block;
        }
    }

    &::before {
        font-family: skycons;
        content: '\f006';
        font-size: ${props => props.theme.fontSize.large};
        margin-right: 0;

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            margin-right: 10px;
        }
    }
`;

export default AccountLink;
