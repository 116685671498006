import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import { getMarketConfig } from '../../../../../helpers/accumulator';
import AccaRow from './AccaRow';

const Selections = ({
    selections,
    onChangeSelection,
    market,
    fixtures,
    marketOutcomes
}) => (
    <>
        <Styled.PredictionsHeader>
            <Styled.TeamColumnHeader />
            <Styled.OutcomeColumnsHeader>
                {getMarketConfig(market).outcomes.map(outcome => (
                    <Styled.OutcomeColumnHeader key={outcome}>
                        {outcome}
                    </Styled.OutcomeColumnHeader>
                ))}
            </Styled.OutcomeColumnsHeader>
        </Styled.PredictionsHeader>
        {fixtures.map(({ id, match }) => (
            <AccaRow
                key={id}
                marketOutcomes={marketOutcomes}
                marketConfig={getMarketConfig(market)}
                match={match}
                selections={selections}
                onChangeSelection={onChangeSelection(match.id)}
            />
        ))}
    </>
);

Selections.propTypes = {
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired,
            selection: PropTypes.string.isRequired
        })
    ).isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    marketOutcomes: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired
        })
    ).isRequired,
    market: PropTypes.string.isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                id: PropTypes.number,
                homeTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ).isRequired
};

export default Selections;
