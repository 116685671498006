import styled from '@emotion/styled/macro';

export const PointsResultCard = styled.div`
    justify-content: center;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.radius.normal};
    width: 100%;
`;

export const PointsResultContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    padding-bottom: ${props =>
        `clamp(${props.theme.spacing.large}, 4vw, ${props.theme.spacing.xxlarge})`};
    padding-left: ${props =>
        `clamp(${props.theme.spacing.large}, 2vw, ${props.theme.spacing.xxlarge})`};
    padding-right: ${props =>
        `clamp(${props.theme.spacing.large}, 2vw, ${props.theme.spacing.xxlarge})`};
    align-items: center;
    justify-content: stretch;
    grid-row-gap: ${props => props.theme.spacing.small};
`;

export const TotalPointsText = styled.p`
    line-height: ${props => props.theme.lineHeight.large};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.bold};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.small}, 2.5vw, ${props.theme.fontSize.large2})`};
`;

export const Points = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props =>
        `clamp(${props.theme.fontSize.large}, 7vw, ${props.theme.fontSize.huge})`};
    height: ${props =>
        `clamp(${props.theme.fontSize.large}, 7vw, ${props.theme.fontSize.huge})`};
    line-height: ${props =>
        `clamp(${props.theme.fontSize.large}, 7vw, ${props.theme.fontSize.huge})`};
    border-radius: 50%;
    font-family: ${props => props.theme.fonts.bold};
    background-color: ${props =>
        props.total
            ? props.theme.colours.greenPrimary
            : props.theme.colours.colorOnPrimary};
    color: ${props =>
        props.total
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 3.5vw, ${props.theme.fontSize.xlarge})`};
    text-align: center;
`;

export const BodyText = styled.p`
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 3.5vw, ${props.theme.fontSize.large2})`};
    line-height: ${props => props.theme.lineHeight.large};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props =>
        props.bold ? props.theme.fonts.bold : props.theme.fonts.normal};
`;

export const TotalPointsSection = styled.div`
    margin-top: ${props => props.theme.spacing.small};
    margin-bottom: ${props => props.theme.spacing.small};
    border-top: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    border-bottom: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
`;

export const FlexItem = styled.div`
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
`;

export const CardHeader = styled.div`
    justify-content: center;
    justify-items: center;
    justify-self: center;
    display: flex;
    padding-top: ${props =>
        `clamp(${props.theme.fontSize.small}, 2vw, ${props.theme.fontSize.xlarge2})`};
    padding-bottom: ${props =>
        `clamp(${props.theme.fontSize.small}, 2vw, ${props.theme.fontSize.xlarge2})`};
`;
