import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import * as CommonStyled from '../common';
import { requestMatchStats } from '../../../../../../store/modules/round/actions';
import Spinner from '../../../../../../components/Common/Spinner';
import { teamProp } from '../Stats/propTypes';
import FailureMessage from '../FailureMessage';
import getChancePercentages from '../../../../../../helpers/getChancePercentages';

const Popular = ({
    getStats,
    stats,
    matchId,
    homeTeam,
    awayTeam,
    isLoading
}) => {
    useEffect(() => {
        if (!stats) {
            getStats(matchId);
        }
    }, [matchId, getStats, stats]);

    if (isLoading) {
        return <Spinner colour="textTitle" />;
    }
    if (!(stats?.home?.chance || stats?.away?.chance) && !isLoading) {
        return (
            <FailureMessage data-test-id="match-stats-popular-predictions-failure" />
        );
    }

    const chancePercentages = getChancePercentages(
        stats.home.chance,
        stats.draw.chance,
        stats.away.chance
    );

    return (
        <Styled.Popular data-test-id="match-stats-popular-predictions">
            <CommonStyled.HeaderText>Popular</CommonStyled.HeaderText>
            <Styled.PopularTeamPercentageContainer>
                <Styled.PopularContainerLeft>
                    <Styled.PopularTeamBadge
                        src={homeTeam.badgeUri}
                        alt={homeTeam.name}
                    />
                </Styled.PopularContainerLeft>
                <Styled.PopularBar>
                    <Styled.PopularBarPercentageMarker
                        chance={
                            chancePercentages.homeChance || stats.home.chance
                        }
                    />
                </Styled.PopularBar>
                <Styled.PopularContainerRight>
                    <CommonStyled.BodyText data-test-id="home-percentage">{`${chancePercentages.homeChance ||
                        stats.home.chance}%`}</CommonStyled.BodyText>
                </Styled.PopularContainerRight>
                <Styled.PopularContainerLeft>
                    <CommonStyled.BodyText>Draw</CommonStyled.BodyText>
                </Styled.PopularContainerLeft>
                <Styled.PopularBar>
                    <Styled.PopularBarPercentageMarker
                        chance={
                            chancePercentages.drawChance || stats.draw.chance
                        }
                    />
                </Styled.PopularBar>
                <Styled.PopularContainerRight>
                    <CommonStyled.BodyText data-test-id="draw-percentage">{`${chancePercentages.drawChance ||
                        stats.draw.chance}%`}</CommonStyled.BodyText>
                </Styled.PopularContainerRight>
                <Styled.PopularContainerLeft>
                    <Styled.PopularTeamBadge
                        src={awayTeam.badgeUri}
                        alt={awayTeam.name}
                    />
                </Styled.PopularContainerLeft>
                <Styled.PopularBar>
                    <Styled.PopularBarPercentageMarker
                        chance={
                            chancePercentages.awayChance || stats.away.chance
                        }
                    />
                </Styled.PopularBar>
                <Styled.PopularContainerRight>
                    <CommonStyled.BodyText data-test-id="away-percentage">{`${chancePercentages.awayChance ||
                        stats.away.chance}%`}</CommonStyled.BodyText>
                </Styled.PopularContainerRight>
            </Styled.PopularTeamPercentageContainer>
        </Styled.Popular>
    );
};

Popular.propTypes = {
    getStats: PropTypes.func.isRequired,
    matchId: PropTypes.number.isRequired,
    homeTeam: teamProp.isRequired,
    awayTeam: teamProp.isRequired,
    stats: PropTypes.shape({
        home: PropTypes.shape({
            name: PropTypes.string,
            chance: PropTypes.number
        }),
        away: PropTypes.shape({
            name: PropTypes.string,
            chance: PropTypes.number
        }),
        draw: PropTypes.shape({ chance: PropTypes.number })
    }),
    isLoading: PropTypes.bool
};

Popular.defaultProps = {
    stats: null,
    isLoading: true
};

const mapStateToProps = (state, props) => ({
    stats:
        state.round.stats[props.matchId] &&
        state.round.stats[props.matchId].data,
    isLoading:
        state.round.stats[props.matchId] &&
        state.round.stats[props.matchId].loading
});

const mapDispatchToProps = dispatch => ({
    getStats: matchId => dispatch(requestMatchStats(matchId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Popular);
