/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled/macro';
import Image from '../../Common/Image';

export const WinnerIconImage = styled(Image)`
    width: 25px;
    height: 25px;
    object-fit: cover;
    object-position: 0 0;
`;
