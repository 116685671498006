import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';

export const spin = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
`;

export const SpinnerIcon = styled.div`
    color: ${props => props.theme.colours[props.colour || 'white']};
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: ${props => props.theme.spacing.normal};
    font-size: ${props => props.theme.spinner.spinnerSize};
    border: 1px solid;
    border-color: transparent currentColor currentColor;
    vertical-align: middle;
    overflow: hidden;
    text-indent: 100%;
    border-radius: 100%;
    -webkit-animation: ${spin} 0.8s linear infinite;
    opacity: 1;
    visibility: visible;
    transition: opacity ${props => props.theme.spinner.spinnerAnimationSpeed}
            ease,
        visibility ${props => props.theme.spinner.spinnerAnimationSpeed} ease;
`;
