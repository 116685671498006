import styled from '@emotion/styled/macro';

import { TeamContainer, TeamImage } from '../../Team/styles';
import { ScoreContainer, Score, Tick } from '../../Score/styles';

export const MatchPrediction = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${ScoreContainer} {
        height: 40px;
        width: 112px;
    }

    ${Score} {
        height: 40px;
        width: 40px;
        line-height: 40px;

        font-size: ${props => props.theme.iconSize.large};
    }

    ${Tick} {
        height: 20px;
        width: 20px;
        ::before {
            line-height: 20px;
        }
    }
`;

export const PredictionList = styled.div`
    display: flex;
    flex-wrap: wrap;

    max-width: 500px;

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        margin-left: -15px;
        margin-right: -15px;
    }

    border-top-style: ${props => props.showBorderTop && `solid;`};

    border-top-width: ${props =>
        props.showBorderTop && props.theme.spacing.xsmall};

    border-top-color: ${props =>
        props.showBorderTop && props.theme.colours.brightGray};

    & > ${MatchPrediction} {
        flex-basis: 33%;
        padding-bottom: 19px;
        box-sizing: border-box;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
            border-bottom: solid ${props => props.theme.spacing.xsmall}
                ${props => props.theme.colours.brightGray};
        }

        border-right: solid ${props => props.theme.spacing.xsmall}
            ${props => props.theme.colours.brightGray};

        &:div {
            background-color: ${props =>
                props.theme.scores?.score.bgColour} !important;
            color: ${props => props.theme.scores?.score.text} !important;
        }

        &:only-child {
            border: none;
        }

        &:nth-of-type(3),
        &:nth-of-type(6) {
            border-right: none;
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
            border-right: ${props => props.theme.spacing.xsmall} solid;
            border-image: linear-gradient(
                    to bottom,
                    ${props => props.theme.colours.brightGray} 50%,
                    ${props => props.theme.colours.white} 50%
                )
                2;
        }

        padding-top: ${props => props.theme.spacing.vlarge};

        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            flex-basis: 50%;

            &:only-child {
                border: none;
            }

            &:nth-of-type(3) {
                border-right: solid ${props => props.theme.spacing.xsmall}
                    ${props => props.theme.colours.brightGray};
            }

            &:nth-of-type(5) {
                border-right: ${props => props.theme.spacing.xsmall} solid;
                border-image: linear-gradient(
                        to bottom,
                        ${props => props.theme.colours.brightGray} 50%,
                        ${props => props.theme.colours.white} 50%
                    )
                    2;
            }

            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                padding-bottom: 19px;
            }

            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(6) {
                border-right: none;
            }

            &:nth-of-type(4) {
                border-bottom: solid ${props => props.theme.spacing.xsmall}
                    ${props => props.theme.colours.brightGray};
                border-image: none;
            }
        }
    }
`;

export const BadgeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 134px;

    ${TeamImage} {
        max-width: 60px;
        margin-bottom: 20px;
    }

    ${TeamContainer} {
        width: auto;
        margin: 0;
    }
`;
