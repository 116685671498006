import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import ImageElement from '../../components/Common/Image';

export const Image = styled(ImageElement)`
    max-width: 90%;
`;

export const Container = styled.div`
    text-align: center;
    padding: ${props => props.theme.spacing.normal};

    p {
        font-size: ${props => props.theme.fontSize.medium};
        margin-bottom: ${props => props.theme.spacing.normal};
    }
`;

export const Button = styled.button`
    display: block;
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.normal};
    box-sizing: border-box;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colours.linkBlueBorder};
    padding: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    border-radius: 4px;
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.linkBlue};

    &:hover {
        background-color: ${props => props.theme.colours.linkBlueHover};
        border-color: ${props => props.theme.colours.linkBlueHoverBorder};
    }
`;

export const InternalLink = styled(Link)`
    display: block;
    text-decoration: none;
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.normal};
    box-sizing: border-box;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colours.linkBlueBorder};
    padding: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    border-radius: 4px;
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.linkBlue};

    &:hover {
        background-color: ${props => props.theme.colours.linkBlueHover};
        border-color: ${props => props.theme.colours.linkBlueHoverBorder};
    }
`;

export const PrimaryButton = styled(Button)`
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.greenPrimary};
    border-color: ${props => props.theme.colours.greenPrimaryBorder};
    font-family: ${props => props.theme.fonts.medium};

    &:hover {
        background-color: ${props => props.theme.colours.greenDark};
        border-color: ${props => props.theme.colours.greenDarkBorder};
    }
`;

export const PrimaryLink = styled(InternalLink)`
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.greenPrimary};
    border-color: ${props => props.theme.colours.greenPrimaryBorder};
    font-family: ${props => props.theme.fonts.medium};

    &:hover {
        background-color: ${props => props.theme.colours.greenDark};
        border-color: ${props => props.theme.colours.greenDarkBorder};
    }
`;
