import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import { Icon } from '../../../../components/Common/Page/styles';
import * as icons from '../../../../styles/icons';
import { selectResults } from '../../../../store/modules/league/selectors';
import { selectLatestNonOpenOrFutureRoundId } from '../../../../store/modules/round/selectors';
import { showModal as showModalAction } from '../../../../store/modules/modal/actions';
import {
    requestLeagueLeaderboard,
    requestLeagueResults
} from '../../../../store/modules/league/actions';
import {
    requestFollowees,
    createFollowee,
    deleteFollowee
} from '../../../../store/modules/follow/actions';
import { requestRounds } from '../../../../store/modules/round/actions';
import LeaderboardTable from '../../../../components/LeaderboardTable';
import { USE_LEADERBOARD_API } from '../../../../config';

export const ResultTable = ({
    currentPage,
    rounds,
    slug,
    league,
    period,
    periodId,
    lastCompleteRoundId,
    userId,
    results,
    getResults,
    followees,
    getFollowees,
    removeMember,
    getRounds,
    onFollow,
    onUnfollow,
    getLeaderboard
}) => {
    useEffect(() => {
        if (!rounds || rounds.length < 1) {
            getRounds();
        }
    }, [getRounds, rounds]);

    useEffect(() => {
        // Load the followees if we're logged in and don't already have them loaded
        if (userId && !followees) {
            getFollowees();
        }
    }, [userId, followees, getFollowees]);

    useEffect(() => {
        if (!results) {
            if (period === 'season' && USE_LEADERBOARD_API) {
                getLeaderboard(league.slug, 1);

                return;
            }

            getResults(slug, period, periodId, 1);
        }
    }, [results, slug, period, periodId, getResults, getLeaderboard]);

    if (!results) {
        return null;
    }

    let relevantRoundId = period === 'round' ? periodId : lastCompleteRoundId;
    relevantRoundId = relevantRoundId === null ? 0 : relevantRoundId;

    const relevantRound =
        rounds && rounds.find(round => round.id === relevantRoundId);
    let baseLink = null;
    baseLink =
        relevantRound && relevantRound.status === 'inplay'
            ? `/inplay`
            : `/results/round/${relevantRoundId}`;

    const rows = results.map(result => {
        const isMe = result.userId === userId;
        const link =
            result.userId === userId
                ? baseLink
                : `${baseLink}/user/${result.userId}`;
        const mapped = {
            ...result,
            link,
            isMe,
            isMasked: false,
            name: `${result.firstName} ${result.lastName}`,
            isChairperson: isMe ? league.isChairperson : false,
            isFollowing: followees && followees.includes(result.userId),
            canRemove: league.isChairperson,
            onFollow: () => {
                onFollow(result.userId);
            },
            onUnfollow: () => {
                onUnfollow(result.userId);
            },
            removeUser: () => {
                removeMember(
                    slug,
                    result.userId,
                    `${result.firstName} ${result.lastName}`
                );
            }
        };

        if (period === 'season' && USE_LEADERBOARD_API) {
            mapped.change = parseInt(result.positionChange, 10);

            if (mapped.change === 0) {
                return mapped;
            }
            if (mapped.change > 99 || mapped.change < -99) {
                mapped.formattedChange = '99+';
            } else {
                mapped.formattedChange = `${mapped.change}`.replace('-', '');
            }
        }

        return mapped;
    });

    return (
        <>
            <LeaderboardTable isSeason={period === 'season'} rows={rows} />
            {league.memberCount > results.length && (
                <Styled.LoadMoreContainer>
                    <Styled.LoadMoreButton
                        onClick={() => {
                            if (period === 'season' && USE_LEADERBOARD_API) {
                                getLeaderboard(league.slug, currentPage + 1);

                                return;
                            }

                            getResults(slug, period, periodId, currentPage + 1);
                        }}
                    >
                        Show More
                        <Icon data-char={icons.DownChevron} />
                    </Styled.LoadMoreButton>
                </Styled.LoadMoreContainer>
            )}
        </>
    );
};

const mapStateToProps = (state, props) => ({
    rounds: state.round.rounds,
    userId: state.user.info.userId,
    followees: state.follow.followees,
    currentPage: state.league.results ? state.league.results.page : 1,
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state),
    results: selectResults(state, props.slug, props.period, props.periodId)
});

const mapDispatchToProps = dispatch => ({
    removeMember: (slug, userId, name) =>
        dispatch(
            showModalAction(
                'LEAGUE_REMOVE_MEMBER',
                { slug, userId, name },
                false
            )
        ),
    getResults: (slug, period, periodId, page) =>
        dispatch(requestLeagueResults(slug, period, periodId, page)),
    getLeaderboard: (slug, page) =>
        dispatch(requestLeagueLeaderboard(slug, page)),
    getFollowees: () => dispatch(requestFollowees()),
    getRounds: () => dispatch(requestRounds()),
    onFollow: userId => {
        dispatch(createFollowee(userId));
    },
    onUnfollow: userId => {
        dispatch(deleteFollowee(userId));
    }
});

ResultTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    getLeaderboard: PropTypes.func.isRequired,
    rounds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired
        }).isRequired
    ),
    slug: PropTypes.string.isRequired,
    league: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        memberCount: PropTypes.number,
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        isChairperson: PropTypes.bool
    }).isRequired,
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    lastCompleteRoundId: PropTypes.number,
    userId: PropTypes.number,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.number,
            position: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            isWinner: PropTypes.bool,
            goldenGoal: PropTypes.number,
            correctResults: PropTypes.number,
            correctScores: PropTypes.number,
            points: PropTypes.number
        })
    ),
    getResults: PropTypes.func.isRequired,
    followees: PropTypes.arrayOf(PropTypes.number),
    getFollowees: PropTypes.func.isRequired,
    removeMember: PropTypes.func.isRequired,
    getRounds: PropTypes.func.isRequired,
    onFollow: PropTypes.func.isRequired,
    onUnfollow: PropTypes.func.isRequired
};

ResultTable.defaultProps = {
    periodId: null,
    userId: null,
    lastCompleteRoundId: null,
    results: null,
    followees: null,
    rounds: null
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultTable);
