import React from 'react';
import MessageBlock from '../../../components/MessageBlock';

const NoHeadToHead = () => (
    <MessageBlock type="info">
        <h3>Try again later</h3>
        <p>We can&apos;t get the Head to Head results right now.</p>
    </MessageBlock>
);

export default NoHeadToHead;
