import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { NavLinkWrapper, NavLink } from '../styles';
import AppLinkButton from '../../../../Common/AppLink/styles';

export const MoreContainer = styled.div`
    position: relative;
`;

export const MoreLinks = styled.div`
    display: ${props => (props.hide ? 'none' : 'flex')};
    flex-direction: column;
    position: absolute;
    background: ${props => props.theme.colours.moreNav.background};
    margin-top: 17px;
    width: 167px;
    z-index: 2;

    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        background: ${props => props.theme.colours.lightGrey};
        width: 100%;
        margin-top: 0;
    }
`;

export const MoreNavLinkWrapper = styled(NavLinkWrapper)`
    padding: 17px 14px;

    &:first-of-type {
        padding-left: 14px;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        border-bottom: 1px solid ${props => props.theme.colours.borderGrey};

        &:first-of-type {
            padding-left: 32px;
        }
    }
`;

export const BaseNavLinkWrapper = NavLinkWrapper;

export const MoreItemDropdown = styled.a`
    color: ${props => props.theme.colours.header.navLink};
    font-size: ${props => props.theme.fontSize.large};
    text-decoration: none;
    border-bottom: ${props =>
        props.activeTab
            ? `1px solid ${props.theme.colours.header.navLink}`
            : 'none'};

    margin-left: 14px;
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        border-style: solid;
        border-width: 5px;
        position: absolute;
        right: -15px;
        border-color: ${props => props.theme.colours.header.navLink} transparent
            transparent transparent;
        top: 65%;
        transform: translateY(-50%);
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 20px;
        margin-left: 0;

        &::after {
            display: none;
        }

        &::before {
            content: '';
            border-style: solid;
            border-width: 6px;
            position: absolute;
            left: -20px;
            border-color: ${props => props.theme.colours.header.navLink}
                transparent transparent transparent;
            top: 60%;
            transform: translateY(-50%);
        }
    }
`;

export const MoreItem = styled(NavLink)`
    margin-left: 14px;
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        border-style: solid;
        border-width: 5px;
        position: absolute;
        right: -15px;
        border-color: ${props => props.theme.colours.header.navLink} transparent
            transparent transparent;
        top: 65%;
        transform: translateY(-50%);
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        font-size: 20px;
        margin-left: 0;

        &::after {
            display: none;
        }

        &::before {
            content: '';
            border-style: solid;
            border-width: 6px;
            position: absolute;
            left: -20px;
            border-color: #fff transparent transparent transparent;
            top: 60%;
            transform: translateY(-50%);
        }
    }
`;

export const MoreLink = styled.div`
    a,
    ${AppLinkButton} {
        display: block;
        color: ${props => props.theme.colours.header.navLink};
        font-size: ${props => props.theme.fontSize.large};
        text-decoration: none;

        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            color: ${props => props.theme.colours.textPrimary};
        }
    }

    ${AppLinkButton} {
        height: 18px;

        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            height: 32px;
        }
    }
`;

export const MoreLocalLink = styled(Link)`
    display: block;
    color: ${props => props.theme.colours.header.navLink};
    font-size: ${props => props.theme.fontSize.large};
    text-decoration: none;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        color: ${props => props.theme.colours.textPrimary};
        padding: 0;
    }
`;
