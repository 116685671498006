import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './mastheadStyles';

const MastheadFooter = ({ hasWon, label, quantity, value }) => {
    if (!quantity || !value) {
        return null;
    }

    return (
        <Styled.Footer hasWon={hasWon}>
            {label}
            <Styled.FooterPrize hasWon={hasWon}>
                {quantity > 1 ? `1 of ${quantity} × ${value}!` : `${value}!`}
            </Styled.FooterPrize>
        </Styled.Footer>
    );
};

MastheadFooter.defaultProps = {
    hasWon: false,
    label: 'Prizes this round'
};

MastheadFooter.propTypes = {
    hasWon: PropTypes.bool,
    label: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired
};

export default MastheadFooter;
