import { put, takeLeading, call } from 'redux-saga/effects';

import landingPageApi from '../../../api/landingPage';
import * as types from './types';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';

export function* getPodcast() {
    try {
        const data = yield call(landingPageApi.getLandingPage, 'podcast');

        yield put(actions.setPodcast(data));
    } catch (err) {
        yield put(actions.setPodcast(null));
        yield put(actions.getPodcastFailed());
        yield put(addErrorMessage('There was a problem loading the page'));
    }
}

export function* watchGetPodcast() {
    yield takeLeading(types.REQUEST_PODCAST, getPodcast);
}

export const sagas = [watchGetPodcast];
