import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

export default (
    state = {
        initialised: false,
        hasCheckedAuth: false,
        isLoggedIn: false,
        idToken: null,
        jwt: null
    },
    action
) => {
    switch (action.type) {
        case types.AUTH_INITIALISED:
            return newIfChanged(state, {
                ...state,
                initialised: true
            });
        case types.LOGOUT: {
            if (window.Android && action.shouldSoftLogout) {
                window.location.href = '/';
                window.Android.softLogout();
            }
            return newIfChanged(state, {
                ...state,
                idToken: null,
                jwt: null,
                isLoggedIn: false
            });
        }
        case types.CHECKED_AUTH:
            return newIfChanged(state, {
                ...state,
                hasCheckedAuth: true
            });
        case types.RECEIVE_SSO_TOKEN:
            return newIfChanged(state, {
                ...state,
                idToken: action.idToken,
                isLoggedIn: true
            });
        case types.RECEIVE_OAUTH_TOKENS:
            return newIfChanged(state, {
                ...state,
                idToken: action.idToken,
                isLoggedIn: true
            });
        case types.RECEIVE_JWT:
            return newIfChanged(state, {
                ...state,
                jwt: action.jwt
            });
        case types.UPDATE_ID_TOKEN:
            return newIfChanged(state, {
                ...state,
                idToken: action.idToken,
                isLoggedIn: true,
                hasCheckedAuth: true
            });
        default:
            return state;
    }
};
