import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { initAuth as initAuthAction } from './store/modules/auth/actions';
import withRouteChange from './components/RouteChange/Wrapper';
import withAuth from './hocs/withAuth';
import HomeView from './views/Home';
import LeaderboardView from './views/Leaderboard';
import LeaguesView from './views/League';
import LeagueLeaderboardView from './views/League/LeagueLeaderboard/LeaderboardFoundWrapper';
import CreateLeagueView from './views/League/CreateLeague';
import EditLeagueView from './views/League/EditLeague';
import RestartLeagueView from './views/League/RestartLeague';
import CreateJoinLeagueView from './views/League/CreateJoin';
import OfficialLeaguesView from './views/League/OfficialLeagues';
import TransferView from './views/Transfer';
import PlayView from './views/Play/RoundStatusWrapper';
import LiveScoringView from './views/LiveScoring/LiveScoringWrapper';
import ScheduleView from './views/Schedule';
import ResultsView from './views/Results';
import FaqView from './views/Faq';
import TermsView from './views/Terms';
import PrivacyView from './views/Privacy';
import PreviousPrivacyView from './views/Privacy/PreviousPrivacy';
import CookiesPolicy from './views/CookiesPolicy';
import PunditsView from './views/Pundits';
import LandingPageView from './views/LandingPage';
import PerformanceHubView from './views/PerformanceHub';
import NotFound from './views/Error/NotFound';
import HeadToHead from './views/HeadToHead/HeadToHeadWrapper';
import { recordPageLoad } from './store/modules/signal/actions';
import { selectCustomerId } from './store/modules/user/selectors';
import { ENABLE_MY_PERFORMANCE_PAGE, ENABLE_HEAD_TO_HEAD } from './features';
import { isFeatureOn } from './store/modules/content/selectors';

function usePageViews(rpl, customerId) {
    const location = useLocation();

    useEffect(() => {
        const check = [
            '/leaderboard/round',
            '/headtohead/results',
            '/refer-a-friend'
        ];

        if (check.indexOf(location.pathname) === -1) {
            rpl(location.pathname);
        }
    }, [location, rpl, customerId]);
}

export const App = ({
    initAuth,
    recordNewPageLoad,
    customerId,
    performanceHubEnabled,
    headToHeadEnabled
}) => {
    useEffect(() => {
        initAuth();
    }, [initAuth]);

    usePageViews(recordNewPageLoad, customerId);

    return (
        <Switch>
            <Route
                exact
                path="/"
                component={withRouteChange('Home')(HomeView)}
            />
            <Route
                exact
                path="/play"
                component={withAuth(
                    withRouteChange('Enter your predictions')(PlayView)
                )}
            />
            <Route
                exact
                path="/played"
                component={withAuth(
                    withRouteChange('Edit your predictions')(PlayView)
                )}
            />
            <Route
                exact
                path="/inplay"
                component={withAuth(
                    withRouteChange('In Play')(LiveScoringView)
                )}
            />
            <Route
                exact
                path="/inplay/user/:userId"
                component={withAuth(
                    withRouteChange('In Play')(LiveScoringView)
                )}
            />
            <Route
                exact
                path="/schedule"
                component={withAuth(
                    withRouteChange('Results and Schedule')(ScheduleView)
                )}
            />
            <Route
                exact
                path={['/results/:period/:periodId']}
                component={withAuth(
                    withRouteChange('Previous Results')(ResultsView)
                )}
            />
            <Route
                exact
                path={['/results/:period/:periodId/user/:userId']}
                component={withAuth(
                    withRouteChange('Previous Results')(ResultsView)
                )}
            />
            <Route
                path={['/leaderboard/:period?/:periodId?']}
                component={withRouteChange('Leaderboard')(LeaderboardView)}
            />
            <Route
                exact
                path="/league"
                component={withAuth(withRouteChange('Leagues')(LeaguesView))}
            />
            <Route
                exact
                path="/league/create"
                component={withAuth(
                    withRouteChange('Create a League')(CreateLeagueView)
                )}
            />
            <Route
                exact
                path="/league/create-join"
                component={withAuth(
                    withRouteChange('Create or Join a League')(
                        CreateJoinLeagueView
                    )
                )}
            />
            <Route
                exact
                path="/league/:slug/edit"
                component={withAuth(
                    withRouteChange('Edit League Details')(EditLeagueView)
                )}
            />
            <Route
                exact
                path="/league/:slug/restart"
                component={withAuth(
                    withRouteChange('Restart League')(RestartLeagueView)
                )}
            />
            <Route
                exact
                path="/league/:slug/:period?/:periodId?"
                component={withAuth(
                    withRouteChange('League')(LeagueLeaderboardView)
                )}
            />
            <Route
                exact
                path="/club-leagues"
                component={withRouteChange('Official Club Leagues')(
                    OfficialLeaguesView
                )}
            />
            {headToHeadEnabled && (
                <Route
                    path="/headtohead/:section/:roundId?"
                    component={withAuth(
                        withRouteChange('Head to Head')(HeadToHead)
                    )}
                />
            )}
            <Route
                exact
                path="/faq"
                component={withRouteChange('FAQs')(FaqView)}
            />
            <Route
                exact
                path="/terms"
                component={withRouteChange('Terms and Conditions')(TermsView)}
            />
            <Route
                exact
                path="/privacy"
                component={withRouteChange('Privacy Policy')(PrivacyView)}
            />
            <Route
                exact
                path="/privacy-previous"
                component={withRouteChange('Previous Privacy Policy')(
                    PreviousPrivacyView
                )}
            />
            <Route
                exact
                path="/cookies-policy"
                component={withRouteChange('Cookies Policy')(CookiesPolicy)}
            />
            <Route
                exact
                path="/pundits"
                component={withRouteChange('Pundit Predictions')(PunditsView)}
            />
            <Route path="/transfer/:app" component={TransferView} />
            <Route
                path="/landing/:slug"
                component={withRouteChange('Landing Page')(LandingPageView)}
            />
            {performanceHubEnabled && (
                <Route
                    path="/myperformancehub"
                    component={withAuth(
                        withRouteChange('My Performance Hub')(
                            PerformanceHubView
                        )
                    )}
                />
            )}
            <Route
                component={withRouteChange('Error 404 | Page Not Found')(
                    NotFound
                )}
            />
        </Switch>
    );
};

const mapStateToProps = state => ({
    customerId: selectCustomerId(state),
    performanceHubEnabled: isFeatureOn(
        state,
        ENABLE_MY_PERFORMANCE_PAGE,
        false
    ),
    headToHeadEnabled: isFeatureOn(state, ENABLE_HEAD_TO_HEAD, true)
});

const mapDispatchToProps = dispatch => ({
    initAuth: () => dispatch(initAuthAction()),
    recordNewPageLoad: page => dispatch(recordPageLoad(page))
});

App.defaultProps = {
    customerId: null,
    performanceHubEnabled: false,
    headToHeadEnabled: true
};

App.propTypes = {
    customerId: PropTypes.string,
    initAuth: PropTypes.func.isRequired,
    recordNewPageLoad: PropTypes.func.isRequired,
    performanceHubEnabled: PropTypes.bool,
    headToHeadEnabled: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
