import * as types from './types';

const defaultState = {
    activeRound: null,
    isLoading: false,
    hasLoaded: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REQUEST_SUPER6EXTRA_ACTIVE_ROUND:
            return {
                ...state,
                isLoading: true,
                hasLoaded: false
            };
        case types.RECEIVE_SUPER6EXTRA_ACTIVE_ROUND:
            return {
                ...state,
                activeRound: action.payload,
                isLoading: false,
                hasLoaded: true
            };
        default:
            return state;
    }
};
