import styled from '@emotion/styled/macro';
import { InnerContainerBorder } from './LastRoundFixtureGroup/styles';

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.blueZodiac};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.spacing.xlarge};
    text-align: center;
    margin-bottom: 10px;
`;

export const LastRoundGroupContainer = styled.div`
    width: min-content;
    padding: 18px 0px;
    @media (min-width: 341px) {
        padding: 18px 8px;
    }
    border-radius: 26px;
    border: solid 1px ${props => props.theme.lastRound.borderGrey};
    background: ${props => props.theme.gradients.greyToWhite};
`;

export const LastRoundGroupRow = styled.div`
    display: flex;
    width: min-content;

    > div:nth-of-type(1) {
        ${InnerContainerBorder} {
            border-right: 1px solid ${props => props.theme.colours.borderGrey};
            max-height: 81%;
            bottom: 0;
            left: 0;
        }
    }
    > div:nth-of-type(3) {
        ${InnerContainerBorder} {
            border-left: 1px solid ${props => props.theme.colours.borderGrey};
            max-height: 81%;
            bottom: 0;
            right: 0;
        }
`;

export const LastRoundGroupBottomRow = styled.div`
    display: flex;
    width: min-content;
    border-top: 1px solid ${props => props.theme.colours.borderGrey};

    > div:nth-of-type(1) {
        ${InnerContainerBorder} {
            border-right: 1px solid ${props => props.theme.colours.borderGrey};
            max-height: 95%;
            top: 0;
            left: 0;
        }
    }
    > div:nth-of-type(3) {
        ${InnerContainerBorder} {
            border-left: 1px solid ${props => props.theme.colours.borderGrey};
            max-height: 95%;
            top: 0;
            right: 0;
        }
`;

export const LastRoundGroupFooterRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 10px;
`;

export const Pill = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    min-width: 107px;
    height: 26px;
    border-radius: 15.5px;
    margin: 0 10px 0 0;
    font-family: ${props => props.theme.fonts.medium};
`;

export const Points = styled(Pill)`
    color: ${props => props.theme.colours.colorOnPrimary};
    background: ${props => props.theme.colours.greenPrimary};
`;

export const H2H = styled(Pill)`
    color: ${props => props.theme.colours.blueZodiac};
    background: ${props => props.theme.colours.yellow};
`;
