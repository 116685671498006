import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const Enter = () => (
    <>
        <li>
            How to Enter
            <Styled.OrderedList>
                <li>
                    The Competition will run until terminated at the sole
                    discretion of the Promoter. The Promoter also reserves the
                    right to suspend or modify the Competition at any time.
                </li>
                <li>Entry to the Competition is free.</li>
                <li>
                    In order to enter the Competition, you must:
                    <Styled.OrderedList>
                        <li>
                            register at{' '}
                            <Link to="/">https://super6.skysports.com/</Link>
                            &nbsp; &#40;the &quot;<strong>Website</strong>
                            &quot;&#41; or its related mobile apps &#40;the
                            &quot;
                            <strong>Mobile Apps</strong>&quot;&#41; to open an
                            account; and
                        </li>
                        <li>
                            predict the scores for all six Super 6 fixtures
                            identified for the relevant round; and
                        </li>
                        <li>
                            make a prediction of the time of the earliest goal
                            to be scored in all matches in a round &#40;the
                            &quot;
                            <strong>Golden Goal Prediction</strong>
                            &quot;&#41;.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    You are required to register to open an account on the
                    Website or the Mobile Apps in your own name.
                </li>
                <li>
                    Any entrant found to be registered in the name of any other
                    person will be disqualified.
                </li>
                <li>
                    Only one account is permitted to be registered per entrant.
                </li>
                <li>
                    By registering for an Account, you authorise the Promoter to
                    conduct any identification, credit or other verification
                    checks that it may require &#40;either for the
                    Promoter&apos;s own purposes or as required by applicable
                    law or a regulatory body&#41;, including &#40;but not
                    limited to&#41; checking any or all of the details you
                    provide when registering or changing your Account details.
                    In particular, the Promoter may verify that you are 18
                    &#40;eighteen&#41; years of age or older and that you are
                    resident in the country in which you say you are resident.
                    If the Promoter discovers that you are under 18 years of age
                    following verification checks or that you have not met any
                    other verification criteria, your account shall be closed
                    and all entries will be disqualified from participating in
                    the Competition and/or receiving any Prizes &#40;as defined
                    below&#41;.
                </li>
                <li>
                    The Promoter may supply the information that you have
                    provided to authorised credit reference agencies, who may
                    check such information against any databases &#40;public or
                    private&#41; to which they have access and may keep a record
                    of that check. The checks will be for the purposes of
                    confirming your identification and for fraud detection and
                    prevention. The Promoter will process the personal
                    information you provide in accordance with its Privacy and
                    Cookie Policy at{' '}
                    <Link to="/privacy">
                        https://super6.skysports.com/privacy
                    </Link>
                    .
                </li>
                <li>
                    If the Promoter has reasonable grounds to believe that
                    multiple accounts have been opened by, or are under the
                    control of the same person, all such accounts shall be
                    closed and all entries by such person and/or accounts held
                    by such person will be disqualified from participating in
                    the Competition and/or receiving any Prizes &#40;as defined
                    below&#41;.
                </li>
                <li>Only one entry per round is permitted per entrant.</li>
                <li>
                    All entries must be received by the Promoter by no later
                    than the time advertised on the points of entry of the
                    Competition &#40;&quot;Closing Time&quot;&#41;. All entries
                    received after the Closing Time will be automatically
                    disqualified.
                </li>
                <li>
                    Once an entry has been submitted, it can be edited before
                    the Closing Time via the Website or Mobile Apps.
                </li>
                <li>
                    You cannot change your predictions after the Closing Time.
                </li>
                <li>
                    The Promoter does not accept any responsibility for network,
                    computer hardware or software failures of any kind, which
                    may restrict or delay the sending or receipt of your entry.
                    Under no circumstances are you permitted to submit your
                    entry via email. Any entries submitted by email will be
                    automatically disqualified.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Enter;
