import styled from '@emotion/styled/macro';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    min-height: 250px;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        flex-wrap: wrap;
        max-width: 500px;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    flex-grow: 1;
    background: ${props => props.theme.hero.default.colours.background};

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        flex: 0 0 100%;
        order: ${props => (props.isSeasonClosed ? 1 : 2)};
        height: auto;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-left-radius: ${props =>
            props.isSeasonClosed ? 0 : '5px'};
        border-bottom-right-radius: ${props =>
            props.isSeasonClosed ? 0 : '5px'};
    }
`;

export const HeroImageContainer = styled.div`
    width: 60%;
    flex-grow: 1;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        flex: 0 0 100%;
        order: ${props => (props.isSeasonClosed ? 2 : 1)};
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: ${props => (props.isSeasonClosed ? 0 : '5px')};
        border-top-right-radius: ${props => (props.isSeasonClosed ? 0 : '5px')};
    }
`;

export const HeroImage = styled.picture`
    display: block;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const LoadingImage = styled.div`
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.hero.loading.colours.imageBackground};
    height: 100%;
    width: 100%;

    &:before {
        font-size: 100px;
        font-family: ${props => props.theme.fonts.skycons};
        content: '\f0f2';
        color: ${props => props.theme.hero.loading.colours.imageIcon};
    }
`;

export const InfoOuter = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;

    flex-grow: 1;

    * {
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;

        top: -50%;
        left: -100px;
        width: 200%;
        height: 100%;
        transform: rotate(-10deg);
        opacity: 0.6;
        background: white;

        @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
            top: -50%;
            transform: rotate(-10deg);
        }
    }
`;
