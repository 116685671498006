import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import AppLink from '../../Common/AppLink';
import { useCookieConsentContext } from '../../../hooks/useCookieConsents';
import buildCookieUrl from '../../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../../config';
import actions from '../../../helpers/GoogleAnalytics/actions';
import elementText from '../../../helpers/GoogleAnalytics/element_text';

const GenericOffer = ({ offer, setBannerEvent }) => {
    if (offer) {
        const { linkUri, desktopImageset, mobileImageset, app } = offer;

        const { cookieConsentQuery } = useCookieConsentContext();

        return (
            <Styled.OfferLink
                onClick={() =>
                    setBannerEvent(
                        elementText.banner.betHere,
                        actions.clicked,
                        1,
                        offer.linkUri
                    )
                }
            >
                <AppLink
                    app={app === 'none' ? '' : app}
                    link={buildCookieUrl(
                        OAUTH_URI,
                        linkUri,
                        cookieConsentQuery
                    )}
                    ariaHidden="true"
                    tabIndex="-1"
                >
                    <Styled.OfferAssetPicture>
                        <source
                            media="(max-width: 1023px)"
                            srcSet={`
                                ${mobileImageset['3x']} 3x,
                                ${mobileImageset['2x']} 2x,
                                ${mobileImageset['1x']} 1x
                            `}
                        />
                        <source
                            srcSet={`
                                ${desktopImageset['3x']} 3x,
                                ${desktopImageset['2x']} 2x,
                                ${desktopImageset['1x']} 1x
                            `}
                        />
                        <Styled.OfferAssetImage
                            src={mobileImageset['3x']}
                            alt="Additional product advertisement"
                        />
                    </Styled.OfferAssetPicture>
                </AppLink>
            </Styled.OfferLink>
        );
    }

    return null;
};

GenericOffer.propTypes = {
    offer: PropTypes.shape({
        linkUri: PropTypes.string,
        desktopImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        mobileImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        app: PropTypes.string
    }),
    setBannerEvent: PropTypes.any
};
GenericOffer.defaultProps = {
    offer: null,
    setBannerEvent: () => {}
};

export default GenericOffer;
