import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import * as CommonStyled from '../../styles';
import leagueProp from '../../propTypes';
import LeagueItem from '../LeagueItem';

const LeagueList = ({ intro, list, isPrevious, isInPlay }) => (
    <>
        <CommonStyled.SubHeader>{intro}</CommonStyled.SubHeader>
        <Styled.SummaryList>
            {list.map(league => (
                <Styled.SummaryListItem
                    key={league.slug}
                    data-test-id="league-card"
                >
                    <LeagueItem
                        name={league.name}
                        slug={league.slug}
                        memberCount={league.memberCount}
                        isPrevious={isPrevious}
                        isInPlay={isInPlay}
                        deleteLeague={false}
                        icon={league.iconUrl}
                    />
                </Styled.SummaryListItem>
            ))}
        </Styled.SummaryList>
    </>
);

LeagueList.propTypes = {
    intro: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(leagueProp).isRequired,
    isInPlay: PropTypes.bool.isRequired,
    isPrevious: PropTypes.bool
};

LeagueList.defaultProps = {
    isPrevious: false
};

export default LeagueList;
