import * as types from './types';

export const receiveDefaultRound = round => ({
    type: types.RECEIVE_DEFAULT_ROUND,
    payload: round
});

export const updatePrediction = (matchId, homeAway, score) => ({
    type: types.UPDATE_PREDICTION,
    matchId,
    scoreToUpdate: homeAway === 'home' ? 'scoreHome' : 'scoreAway',
    score
});

export const updateGoldenGoal = value => ({
    type: types.UPDATE_GOLDEN_GOAL,
    value: value || ''
});

export const postPredictions = () => ({
    type: types.POST_PREDICTIONS
});

export const submissionInFlight = () => ({
    type: types.SUBMISSION_STATUS,
    inFlight: true
});

export const submissionFinished = () => ({
    type: types.SUBMISSION_STATUS,
    inFlight: false
});

export const predictionsPostSuccess = roundId => ({
    type: types.POST_PREDICTIONS_SUCCESS,
    roundId
});
