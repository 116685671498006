import React from 'react';
import PropTypes from 'prop-types';
import AccaTabs, {
    AccaTab
} from '../../../../../components/Common/Tabs/AccaTabs';
import {
    bothTeamsToScore,
    fullTimeResult,
    underOverGoals
} from '../../../../../helpers/accumulator';
import * as Styled from '../styles';

const MarketTabs = ({ marketOutcomes, market, setMarket }) => (
    <Styled.TabContainer>
        <AccaTabs active={market}>
            <AccaTab
                id={fullTimeResult}
                isDisabled={
                    !marketOutcomes[fullTimeResult] ||
                    marketOutcomes[fullTimeResult].length < 1
                }
                onClick={() => setMarket(fullTimeResult)}
            >
                Full Time Result
            </AccaTab>
            <AccaTab
                id={underOverGoals}
                isDisabled={
                    !marketOutcomes[underOverGoals] ||
                    marketOutcomes[underOverGoals].length < 1
                }
                onClick={() => setMarket(underOverGoals)}
            >
                Under/Over 2.5 Goals
            </AccaTab>
            <AccaTab
                id={bothTeamsToScore}
                isDisabled={
                    !marketOutcomes[bothTeamsToScore] ||
                    marketOutcomes[bothTeamsToScore].length < 1
                }
                onClick={() => setMarket(bothTeamsToScore)}
            >
                Both Teams To Score
            </AccaTab>
        </AccaTabs>
    </Styled.TabContainer>
);

MarketTabs.propTypes = {
    market: PropTypes.string.isRequired,
    setMarket: PropTypes.func.isRequired,
    marketOutcomes: PropTypes.shape({
        [fullTimeResult]: PropTypes.array,
        [bothTeamsToScore]: PropTypes.array,
        [underOverGoals]: PropTypes.array
    }).isRequired
};

export default MarketTabs;
