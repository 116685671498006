import styled from '@emotion/styled/macro';
import Image from '../../Common/Image';
import AppLinkButton from '../../Common/AppLink/styles';

export const OfferAssetPicture = styled.picture`
    height: 100%;
    max-height: 100px;
    width: 100%;
`;

export const OfferAssetImage = styled(Image)`
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const OfferLink = styled.div`
    a,
    ${AppLinkButton} {
        display: block;
        width: 100%;
    }
`;
