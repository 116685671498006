/* eslint-disable import/no-unused-modules */
import { isOauthEnabled } from '../auth/auth';
import { OAUTH_URI } from '../config';

export const BET = 'bet';
export const MBET = 'mbet';
export const VEGAS = 'vegas';
export const MVEGAS = 'mvegas';
export const SKYSPORTS = 'skysports';
export const FANTASYFOOTBALL = 'fantasyfootball';
export const ITV7 = 'itv7';
export const POKER = 'poker';
export const MPOKER = 'mpoker';
export const BINGO = 'bingo';
export const CASINO = 'casino';
export const SPORTINGLIFE = 'sportinglife';
export const EXTRA = 'extra';

const ssoMap = {
    [BET]: 'https://www.skybet.com',
    [MBET]: 'https://m.skybet.com',
    [VEGAS]: 'https://www.skyvegas.com',
    [MVEGAS]: 'https://m.skyvegas.com',
    [SKYSPORTS]: 'https://www.skysports.com',
    [FANTASYFOOTBALL]: 'https://fantasyfootball.skysports.com',
    [ITV7]: 'https://itv7.itv.com',
    [POKER]: 'https://www.skypoker.com',
    [MPOKER]: 'https://m.skypoker.com',
    [BINGO]: 'https://www.skybingo.com',
    [CASINO]: 'https://www.skycasino.com',
    [SPORTINGLIFE]: 'https://www.sportinglife.com',
    [EXTRA]: 'https://super6extra.skysports.com'
};

const oauthMap = {
    [BET]: '/skybet',
    [MBET]: '/skybet',
    [VEGAS]: '/skyvegas',
    [MVEGAS]: '/skyvegas',
    [SKYSPORTS]: '/skysports',
    [FANTASYFOOTBALL]: '/fantasyfootball',
    [ITV7]: '/itv7',
    [POKER]: '/skypoker',
    [MPOKER]: '/skypoker',
    [BINGO]: '/skybingo',
    [CASINO]: '/skycasino',
    [SPORTINGLIFE]: '/sportinglife',
    [EXTRA]: '/s6extra'
};

export const buildTransferLink = (app, path) => {
    const map = isOauthEnabled() ? oauthMap : ssoMap;

    if (!map[app]) {
        return null;
    }

    const prefix = isOauthEnabled() ? OAUTH_URI : '';

    return `${prefix}${map[app]}${path || ''}`;
};
