import styled from '@emotion/styled/macro';

export const Container = styled.div`
    position: relative;
    display: flex;

    width: ${props => (props.variant === 'default' ? null : '100%')};
    margin-bottom: ${props => (props.variant === 'default' ? null : '20px')};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        max-width: ${props => (props.variant === 'default' ? null : '500px')};
        max-height: 905px;
    }
`;

export const ContainerExpander = styled.div`
    position: relative;

    width: 100%;
`;

// Used to maintain the same aspect ratio for tablet devices
export const ContainerInner = styled.div`
    ${props =>
        props.variant === 'extra' &&
        `
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    `}

    display: flex;
    flex-direction: ${props =>
        props.variant === 'default' ? 'column-reverse' : null};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        flex-direction: column;
        height: auto;
    }
`;

export const TeamsRow = styled.div`
    display: flex;
    flex-direction: row;
`;
