import * as types from './types';

export const requestYoutubeId = () => ({
    type: types.REQUEST_YOUTUBE_ID
});

export const requestFeatureSwitches = () => ({
    type: types.REQUEST_FEATURE_SWITCHES
});

export const receiveFeatureSwitches = data => ({
    type: types.RECEIVE_FEATURE_SWITCHES,
    data
});

export const receiveYoutubeId = youtubeId => ({
    type: types.RECEIVE_YOUTUBE_ID,
    payload: youtubeId
});

export const updateBackButton = location => ({
    type: types.UPDATE_BACK_BUTTON,
    location
});
