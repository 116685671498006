import axios from 'axios';
import { SUPER6_EXTRA_API_ROOT } from '../config';

const super6ExtraApi = {
    getActiveRound: async () => {
        const { data, status } = await axios.get(
            `${SUPER6_EXTRA_API_ROOT}/round/active/external`
        );

        if (status !== 200) {
            throw new Error('Could not load active round');
        }
        return data;
    }
};

export default super6ExtraApi;
