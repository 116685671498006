import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { IconRow, ShareContainer } from '../../components/BasicShare/styles';

export const MatchContainer = styled.div`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    margin-bottom: ${props => props.theme.spacing.normal};
    background-color: ${props => props.theme.colours.white};
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        :nth-of-type(odd) {
            margin-right: ${props => props.theme.spacing.medium};
        }
        :nth-of-type(even) {
            margin-left: ${props => props.theme.spacing.medium};
        }
        margin-bottom: ${props => props.theme.spacing.medium};
        width: 40%;
    }
`;

export const ScrollAnchor = styled.div`
    position: absolute;
    top: -90px;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

export const MatchTitleBar = styled.div`
    background-color: ${props => props.theme.colours.backgroundGrey};
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: ${props => props.theme.fontSize.small};
`;

export const MatchTitle = styled.div`
    font-family: ${props => props.theme.fonts.medium};
`;

export const MatchDate = styled.div`
    text-align: right;
`;

export const Match = styled.div`
    display: flex;

    margin-top: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.normal};
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0;
    }
`;

export const PredictionContainer = styled.div`
    flex: 40% 1 1;
    display: flex;
    flex-direction: row;
    div {
        flex: 33% 1 1;
        text-align: center;
    }
`;

export const Prediction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const chevron = styled.button`
    width: 50px;
    height: 40px;
    font-family: 'skycons';
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &::before {
        font-size: ${props => props.theme.fontSize.large};
        font-family: skycons;
        content: '\f023';
    }
`;

export const Increase = styled(chevron)`
    &::before {
        content: '\f023';
    }
`;

export const Decrease = styled(chevron)`
    &::before {
        content: '\f022';
    }
`;

export const Score = styled.input`
    width: 100%;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    font-size: ${props => props.theme.fontSize.xlarge};
    text-align: center;
    height: 42px;
    width: 41px;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const Vs = styled.p`
    display: flex;
    align-items: center;
    align-content: center;
    font-size: ${props => props.theme.fontSize.xlarge};
    font-family: ${props => props.theme.fonts.regular};
`;

export const GoldenGoalContainer = styled.div`
    color: ${props => props.theme.colours.textGrey};
    border-bottom: ${props =>
        props.underlined
            ? `1px solid ${props.theme.colours.borderGrey}`
            : 'none'};
    margin: ${props => props.theme.spacing.large} 0;
    padding-bottom: ${props => props.theme.spacing.xlarge};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin-left: ${props => props.theme.spacing.small};
        margin-right: ${props => props.theme.spacing.small};
    }
`;

export const GoldenGoalTitle = styled.p`
    text-align: center;
    font-size: ${props => props.theme.fontSize.xlarge};
    font-family: ${props => props.theme.fonts.medium};
`;

export const GoldenGoalInput = styled.div`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    margin: ${props => props.theme.fontSize.medium} 0;
    padding: 2px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;

    input {
        border-radius: 4px;
        text-align: center;
        font-size: ${props => props.theme.fontSize.medium};
        font-family: ${props => props.theme.fonts.medium};
        line-height: 30px;
        border-width: 0;
        box-sizing: border-box;
        width: 100%;
        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`;

export const GoldenGoalText = styled.p`
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1.42857;
    a {
        text-decoration: none;
        color: inherit;
    }
`;

export const H2HContainer = styled.div`
    position: relative;
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1.42857;
    color: ${props => props.theme.colours.textGrey};
    border-bottom: ${props =>
        props.underlined
            ? `1px solid ${props.theme.colours.borderGrey}`
            : 'none'};
    margin-bottom: ${props => props.theme.spacing.large};
    padding-bottom: ${props => props.theme.spacing.xlarge};
    display: flex;
    justify-content: center;
    > div {
        width: ${props => (props.fullWidth ? '100%' : '317px')};
        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            width: 100%;
        }
    }
`;

export const H2HInput = styled.input`
    display: none;
`;

export const H2HLabel = styled.label`
    position: relative;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.backgroundRafBlue};
    border-radius: 0.5em;
    margin: 31px 0 26px 0;
    display: block;
    line-height: 44px;
    padding-left: 10px;
    width: 93%;
    cursor: pointer;
`;

export const H2HCircle = styled.div`
    position: absolute;
    height: 75px;
    width: 75px;
    border-radius: 75px;
    border: 3px solid ${props => props.theme.colours.backgroundRafBlue};
    right: -5px;
    top: -20px;
    background: ${props => props.theme.colours.white};
    box-shadow: 0 2px 4px 0 ${props => props.theme.colours.textDark};
    text-align: center;

    img {
        position: absolute;
        left: 23px;
        top: 22px;
    }

    div {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        font-family: ${props => props.theme.fonts.medium};
        color: ${props => props.theme.colours.backgroundRafBlue};
        text-align: center;
        line-height: 15px;
        font-size: ${props => props.theme.fontSize.small};
    }
`;

export const ShareBar = styled.div`
    background-color: ${props => props.theme.colours.white};
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.normal};
    margin: ${props => props.theme.spacing.normal} 0;
    font-family: ${props => props.theme.fonts.medium};
    line-height: 26px;

    ${ShareContainer} {
        width: 130px;

        ${IconRow} {
            padding: 0;
        }
    }
`;

export const EditPredictions = styled(Link)`
    text-decoration: none;
    color: ${props => props.theme.colours.black};
    &::before {
        font-family: skycons;
        content: '\f025';
        padding-right: 5px;
    }
`;

export const MatchBottomContainer = styled.div`
    background-color: ${props => props.theme.colours.backgroundGrey};
    text-align: center;
    flex-grow: 1;
    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        flex-grow: 0;
    }
`;

export const MatchBottomContents = styled.div`
    padding: ${props => `0 ${props.theme.spacing.xlarge} ${props.theme.spacing.large}
        ${props.theme.spacing.xlarge}`};
    flex-grow: 1;
    color: ${props => props.theme.colours.textDark};
    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => `0 ${props.theme.spacing.normal}
        ${props.theme.spacing.normal}`};
    }
`;

export const MatchBottomControls = styled.div`
    display: flex;
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.blueDark};
    > div {
        flex: 33% 1 1;
        min-height: 56px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 12px 0 12px;

        @media (max-width: ${props => props.theme.widths.desktopMin}) {
            min-height: 46px;
        }
    }
`;

const MatchContainerControls = styled.div`
    cursor: pointer;
    &::before {
        font-family: 'skycons';
        font-weight: normal;
        font-size: ${props => props.theme.fontSize.large};
        padding-bottom: 3px;
        position: relative;
    }
`;

export const StatsController = styled(MatchContainerControls)`
    opacity: ${props => (props.greyed ? 0.5 : 1)};
    &::before {
        content: '\\F303';
    }
`;

export const PunditsController = styled(MatchContainerControls)`
    opacity: ${props => (props.greyed ? 0.5 : 1)};

    img {
        width: 21px;
        margin: 5px 0;
    }
`;

export const BetsController = styled.div`
    opacity: ${props => (props.greyed ? 0.5 : 1)};
    :hover {
        cursor: pointer;
    }
    img {
        margin: 5px 0;
        width: 40px;
        height: 12px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${props => props.theme.spacing.xlarge};
    padding-top: ${props =>
        props.padTop ? props.theme.spacing.medium : '0px'};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const BtnRow = styled(Row)`
    flex-direction: row;
    padding-bottom: 0;
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-wrap: nowrap;
    }
`;

export const RowTop = styled(Row)`
    padding-top: ${props => props.theme.spacing.large};
`;

export const MiddleColumns = styled.div`
    padding: 0 ${props => props.theme.spacing.normal};
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 70%;
    }
`;

export const FlexColumn = styled.div`
    flex: 1;

    &:nth-of-type(2) {
        margin-left: ${props => props.theme.spacing.medium};
    }
`;

export const FullWidthColumn = styled.div`
    padding: 0 ${props => props.theme.spacing.normal};
    width: 100%;
    box-sizing: border-box;
`;

export const EqualWidthColumn = styled.div`
    flex: 1 1 auto;
    padding: 0 ${props => props.theme.spacing.normal};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        :nth-of-type(odd) {
            margin-right: ${props => props.theme.spacing.medium};
            padding-left: 0;
        }
        :nth-of-type(even) {
            margin-left: ${props => props.theme.spacing.medium};
            padding-right: 0;
        }
        width: 40%;
    }
`;

export const ExtraTimeHeroContainer = styled.div`
    justify-content: center;
    display: flex;
    width: auto;
    padding: 0 8px;
`;

export const MainContent = styled.div`
    margin: 0 ${props => props.theme.spacing.medium};
`;

export const PageContainer = styled.div`
    background-color: ${props =>
        props.theme.componentColours?.pageContainer?.background};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 auto;
        padding: ${props => props.theme.spacing.large} 0;
        max-width: ${props => props.theme.widths.containerMaxWidth};
    }
`;

export const DeadlineTitle = styled.div`
    margin: ${props => `0 0 ${props.theme.spacing.large} 0`};
`;

export const RoundFixtures = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const ColumnContainer = styled.div`
    max-width: 100%;
    margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
    margin-bottom: ${props => props.theme.spacing.normal};
`;

export const ButtonWrapperPlayed = styled.div`
    margin-bottom: ${props => props.theme.spacing.large};
`;

export const PageTitle = styled.div`
    text-align: center;
    background: ${props => props.theme.colours.bgGrey};
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 700;
    color: ${props => props.theme.colours.colorOnPrimary};
    padding: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.medium};
`;

export const SkeletonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;
