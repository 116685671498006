import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

export default (
    state = {
        app: null,
        version: null
    },
    action
) => {
    switch (action.type) {
        case types.SET_VERSION:
            return newIfChanged(state, {
                ...state,
                app: action.app,
                version: action.version
            });
        default:
            return state;
    }
};
