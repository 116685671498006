import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'querystring';
import { setCookie, readCookie, deleteCookie } from '../../helpers/cookie';
import { requestSetReferrer } from '../../store/modules/raf/actions';
import { selectIsLoggedIn } from '../../store/modules/auth/selectors';

const COOKIE_NAME = 'ref';

const ReferralHandler = ({ isLoggedIn, referralSet, setReferrer }) => {
    const queryString = useLocation().search;
    const { referrer } = parse(queryString.replace('?', ''));

    // If we're not already logged in, haven't already set a referral,
    // and we have a referrer query: Set a cookie
    useEffect(() => {
        if (!isLoggedIn && !referralSet && referrer) {
            const parsedReferrer = parseInt(referrer, 10);
            if (!parsedReferrer.isNaN) {
                setCookie(COOKIE_NAME, parsedReferrer, 12);
            }
        }
    }, [referralSet, isLoggedIn, referrer]);

    // If we haven't already set a referral, and we're now logged in, check
    // if we have a referral cookie, and set the referral
    useEffect(() => {
        if (!referralSet && isLoggedIn) {
            const refCookie = readCookie(COOKIE_NAME);
            if (refCookie) {
                setReferrer(refCookie);
            }
        }
    }, [isLoggedIn, referralSet, setReferrer]);

    // If we've now set the referral, we can delete the cookie
    useEffect(() => {
        if (referralSet) {
            deleteCookie(COOKIE_NAME);
        }
    }, [referralSet]);

    return null;
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    referralSet: state.raf.referralSet
});

const mapDispatchToProps = dispatch => ({
    setReferrer: userId => dispatch(requestSetReferrer(userId))
});

ReferralHandler.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    referralSet: PropTypes.bool.isRequired,
    setReferrer: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralHandler);
