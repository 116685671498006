import { select, put, takeLeading, call } from 'redux-saga/effects';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';
import * as types from './types';
import * as selectors from './selectors';
import { selectIsLoggedIn, selectToken } from '../auth/selectors';
import { selectActiveRoundId } from '../round/selectors';
import userApi from '../../../api/user';
import { getAuthService } from '../../../auth/auth';
import * as signalActions from '../signal/actions';

function* getUserInfo(action) {
    try {
        const userId = yield select(selectors.selectUserId);
        if (!userId) {
            const idToken = yield select(selectToken);
            const data = yield call(userApi.getUserInfo, idToken);
            yield put(actions.receiveUserInfo(data));
            if (action.firstLogin) {
                yield put(signalActions.recordLogin(data.user));
            }
        }
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving user info'));
    }
}

function* getUsersActiveRoundPredictions() {
    try {
        const roundId = yield select(selectActiveRoundId);
        const idToken = yield select(selectToken);
        if (roundId !== null) {
            const isLoggedIn = yield select(selectIsLoggedIn);

            if (isLoggedIn) {
                const data = yield call(
                    userApi.getUsersActiveRoundPredictions,
                    idToken
                );
                yield put(actions.receiveUsersActiveRoundPredictions(data));
            }
        }
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the predictions')
        );
    }
}

function* redirectToAccount() {
    const authService = yield call(getAuthService);
    yield call(authService.redirectToAccount);
}

export function* watchGetUsersActiveRoundPredictions() {
    yield takeLeading(
        types.REQUEST_ACTIVE_ROUND_PREDICTIONS,
        getUsersActiveRoundPredictions
    );
}

export function* watchAccountLink() {
    yield takeLeading(types.GO_TO_ACCOUNT, redirectToAccount);
}

export function* watchRequestUserInfo() {
    yield takeLeading(types.REQUEST_USER_INFO, getUserInfo);
}

export const sagas = [
    watchGetUsersActiveRoundPredictions,
    watchAccountLink,
    watchRequestUserInfo
];
