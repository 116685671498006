import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const ChallengePrediction = ({
    label,
    homePrediction,
    awayPrediction,
    points
}) => {
    const notPredicted = !homePrediction && !awayPrediction;
    const homeScoreString = homePrediction ?? '-';
    const awayScoreString = awayPrediction ?? '-';

    return (
        <Styled.PredictionRow>
            <Styled.PredictionLabel>{label}</Styled.PredictionLabel>
            <Styled.PredictionCenter>
                <Styled.PredictionScoreContainer data-test-id="challenge-predictions-scores">
                    <Styled.PredictionScore
                        notPredicted={notPredicted}
                        data-test-id="challenge-predictions-home-score"
                    >
                        {homeScoreString}
                    </Styled.PredictionScore>
                    <Styled.PredictionVs notPredicted={notPredicted}>
                        v
                    </Styled.PredictionVs>
                    <Styled.PredictionScore
                        notPredicted={notPredicted}
                        data-test-id="challenge-predictions-away-score"
                    >
                        {awayScoreString}
                    </Styled.PredictionScore>
                </Styled.PredictionScoreContainer>
            </Styled.PredictionCenter>
            <Styled.PredictionPointsContainer data-test-id="challenge-predictions-points">
                Points{' '}
                <Styled.PredictionPoints points={points}>
                    {points}
                </Styled.PredictionPoints>
            </Styled.PredictionPointsContainer>
        </Styled.PredictionRow>
    );
};

ChallengePrediction.propTypes = {
    label: PropTypes.string.isRequired,
    homePrediction: PropTypes.number,
    awayPrediction: PropTypes.number,
    points: PropTypes.number
};

ChallengePrediction.defaultProps = {
    homePrediction: null,
    awayPrediction: null,
    points: null
};

export default ChallengePrediction;
