import React from 'react';
import PropTypes from 'prop-types';
import { matchOutcomeProp } from './propTypes';
import * as Styled from './styles';
import { convertCustomFormat } from '../../../../../../helpers/dateFormatters';

const MatchHistoryItem = ({ match, isHome, greyBackground }) => (
    <Styled.MatchHistoryItem greyBackground={greyBackground}>
        <Styled.MatchHistoryBadge
            src={match[isHome ? 'away' : 'home'].badgeUri}
            alt={match[isHome ? 'away' : 'home'].name}
            width="29px"
        />
        <Styled.MatchHistoryDate>
            {convertCustomFormat(match.matchDate, 'DD/MM', 'Do MMMM YYYY')}
        </Styled.MatchHistoryDate>
        <Styled.MatchHistoryOutcome outcome={match.outcome}>
            {match.outcome}
        </Styled.MatchHistoryOutcome>
        <Styled.MatchHistoryHomeAway>
            {isHome ? 'H' : 'A'}
        </Styled.MatchHistoryHomeAway>
        <Styled.MatchHistoryScore>
            {match.home.score}-{match.away.score}
        </Styled.MatchHistoryScore>
    </Styled.MatchHistoryItem>
);

MatchHistoryItem.propTypes = {
    match: matchOutcomeProp.isRequired,
    isHome: PropTypes.bool.isRequired,
    greyBackground: PropTypes.bool
};

MatchHistoryItem.defaultProps = {
    greyBackground: false
};

export default MatchHistoryItem;
