import styled from '@emotion/styled/macro';
import { Link as RouterLink } from 'react-router-dom';
import { css } from '@emotion/core';

export const Container = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 48px;
    list-style: none;
    background: ${props =>
        props.bgGrey
            ? props.theme.colours.lightGrey
            : props.theme.colours.white};
    justify-content: ${props => props.theme.tabs.justify};
    border-radius: inherit;
`;

export const Tab = styled.li`
    flex: 0 0 auto;
    white-space: nowrap;
    border-bottom: 3px solid
        ${props =>
            props.active ? props.theme.colours.bluePrimary : 'transparent'};

    & button,
    a {
        font-weight: ${props =>
            props.active
                ? props.theme.tabs.fontWeights.active
                : props.theme.tabs.fontWeights.default};
        color: ${props =>
            props.active
                ? props.theme.tabs.color
                : props.theme.colours.mineShaft};

        font-family: ${props =>
            props.active
                ? props.theme.fonts.skySportsDBold
                : props.theme.fonts.skySportsD};
    }
`;

const buttonStyles = props => css`
    display: block;
    border: none;
    background: transparent;
    font-size: ${props.theme.fontSize.medium};
    text-transform: ${props.theme.tabs.textTransform};
    text-decoration: none;
    color: ${props.theme.tabs.color};
    line-height: 25px;
    padding: ${props.theme.spacing.medium};
    margin: 0;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const Link = styled(RouterLink)`
    ${buttonStyles}
`;

export const Button = styled.button`
    ${buttonStyles}
`;

export const Disabled = styled(Button)`
    color: ${props => props.theme.colours.disabledButton};

    &:hover {
        cursor: default;
    }
`;
