import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const Publicity = () => (
    <>
        <li>
            Publicity and Personal Information
            <Styled.OrderedList>
                <li>
                    The Promoter will use each winner&apos;s and each
                    entrant&apos;s personal details for the purpose of
                    administering this Competition.
                </li>
                <li>
                    By opting in to receive offers and communications you agree
                    that the Promoter and Sky Betting &amp; Gaming and each of
                    their group companies, businesses and business partners may
                    use the details you give us to contact you about products
                    and services that may be of interest to you. By opting in to
                    allow us to use your customer profile, including but not
                    limited to your age, location and gender, your browsing,
                    betting and gaming history and patterns, your social media
                    usage and how you interact with us.
                </li>
                <li>
                    You agree that the Promoter and Sky Betting &amp; Gaming and
                    each of their group companies, businesses and business
                    partners may use the details you give us to contact you and
                    provide targeted marketing tailored to the products and
                    services that may be of interest to you.
                </li>
                <li>
                    You can change your direct marketing preferences by
                    contacting us by email at super6@skybet.com or by altering
                    your marketing preferences within the My Account section of
                    the Super 6 website. You can also click on the
                    &quot;unsubscribe&quot; link in direct marketing emails from
                    us to be removed from receiving future email marketing.
                </li>
                <li>
                    For further information on how we use your information,
                    please see our Privacy and Cookie Policy available{' '}
                    <Link to="/privacy">here</Link>.
                </li>
                <li>
                    Entry and participation in the Competition constitutes
                    permission for the Promoter and/or its associated,
                    affiliated and subsidiary companies to use the usernames,
                    predictions, name, city/county and user photographs of
                    participants for publishing purposes, including but not
                    limited to, leader boards and other display within the Super
                    6 Website or related applications.
                </li>
                <li>
                    With the jackpot winner&apos;s consent, the winner&apos;s
                    details will be included in such publicity as the Promotor
                    reasonably requires.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Publicity;
