/* eslint-disable import/no-unused-modules */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Team from '../Team';
import PredictionAndScore from './PredictionAndScore';
import * as Styled from './styles';
import ConnectedLiveOdds from '../LiveOdds';
import MatchPoints from './MatchPoints/MatchPoints';
import MatchStatus from '../MatchStatus';
import NonLiveMatchHeader from '../NonLiveMatchHeader';
import Image from '../Common/Image';
import TextInBox from '../Common/TextInBox';

export const MatchWithScores = ({
    liveOdds,
    homeTeam,
    predictedHomeScore,
    homeScore,
    awayTeam,
    predictedAwayScore,
    awayScore,
    isCorrectScore,
    isCorrectResult,
    points,
    isLive,
    isPreLive,
    isAbandoned,
    index,
    canBet,
    competitionUri,
    isResulted,
    matchStatusText,
    competitionName,
    kickOffDate,
    kickOffTime,
    isResultsPage,
    userId
}) => (
    <Styled.MatchContainer
        live={isLive}
        className="match-live"
        data-test-id={`match-container-with-scores-${index + 1}`}
    >
        {isPreLive ? (
            <Styled.NonLiveMatchHeader data-test-id="non-live-match-header">
                <NonLiveMatchHeader
                    competitionName={competitionName}
                    kickOffDate={kickOffDate}
                    kickOffTime={kickOffTime}
                    matchIndex={index}
                />
            </Styled.NonLiveMatchHeader>
        ) : (
            <Styled.MatchHeader data-test-id="after-kick-off-match-header">
                <Styled.GridItem position="start">
                    {competitionUri ? (
                        <Image
                            src={competitionUri}
                            alt={competitionName}
                            width="90px"
                            data-test-id="match-competition-logo"
                        />
                    ) : (
                        <Styled.MatchCompetitionName
                            competitionName={competitionName}
                        >
                            {competitionName}
                        </Styled.MatchCompetitionName>
                    )}
                </Styled.GridItem>
                <Styled.GridItem position="center">
                    {isResultsPage && isCorrectScore ? (
                        <TextInBox text="Correct Score" variant="green" />
                    ) : (
                        <MatchStatus
                            textInRed={matchStatusText.red}
                            textInBlue={matchStatusText.blue}
                        />
                    )}
                </Styled.GridItem>
                <Styled.GridItem position="end">
                    <MatchPoints points={points} />
                </Styled.GridItem>
            </Styled.MatchHeader>
        )}

        <Styled.Match>
            <Team team={homeTeam} size="l" mobWidth="25%" />
            <PredictionAndScore
                predictedHomeScore={predictedHomeScore}
                homeScore={homeScore}
                predictedAwayScore={predictedAwayScore}
                awayScore={awayScore}
                isCorrectResult={!!isCorrectResult}
                isCorrectScore={!!isCorrectScore}
                isLive={isLive}
                isPreLive={isPreLive}
                isAbandoned={isAbandoned}
                userId={userId}
            />
            <Team team={awayTeam} size="l" mobWidth="25%" />
        </Styled.Match>
        {canBet && liveOdds && isLive && !isResulted && (
            <Styled.MatchFooter>
                <ConnectedLiveOdds
                    outcomes={[
                        liveOdds.home
                            ? { ...liveOdds.home, desc: 'Home' }
                            : null,
                        liveOdds.draw
                            ? { ...liveOdds.draw, desc: 'Draw' }
                            : null,
                        liveOdds.away
                            ? { ...liveOdds.away, desc: 'Away' }
                            : null
                    ]}
                    isResulted={isResulted}
                />
            </Styled.MatchFooter>
        )}
    </Styled.MatchContainer>
);

MatchWithScores.defaultProps = {
    predictedHomeScore: null,
    predictedAwayScore: null,
    homeScore: null,
    awayScore: null,
    isCorrectScore: false,
    isCorrectResult: false,
    points: 0,
    isLive: false,
    isPreLive: false,
    isAbandoned: false,
    liveOdds: null,
    competitionUri: null,
    isResultsPage: false,
    userId: null
};

MatchWithScores.propTypes = {
    kickOffDate: PropTypes.string.isRequired,
    kickOffTime: PropTypes.string.isRequired,
    homeTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    predictedHomeScore: PropTypes.number,
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awayTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    predictedAwayScore: PropTypes.number,
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isCorrectScore: PropTypes.bool,
    isCorrectResult: PropTypes.bool,
    points: PropTypes.number,
    isLive: PropTypes.bool,
    isPreLive: PropTypes.bool,
    isAbandoned: PropTypes.bool,
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    }),
    index: PropTypes.number.isRequired,
    canBet: PropTypes.bool.isRequired,
    competitionUri: PropTypes.string,
    isResulted: PropTypes.bool.isRequired,
    matchStatusText: PropTypes.shape({
        red: PropTypes.string,
        blue: PropTypes.string
    }).isRequired,
    competitionName: PropTypes.string.isRequired,
    isResultsPage: PropTypes.bool,
    userId: PropTypes.string
};

const mapStateToProps = state => ({
    canBet: !!state.user.info.canBet
});

export default connect(mapStateToProps)(MatchWithScores);
