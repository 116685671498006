import {
    fullTimeResult,
    underOverGoals,
    bothTeamsToScore
} from './accumulator';
import { BET, buildTransferLink } from './transfer';

export const dcmpPrefixMap = {
    betButton: 's6ih-betbutton',
    moreMarkets: 's6ih-more-markets',
    enhancedBet: 's6ih-eb',
    liveOdds: 's6ih-live-odds'
};

export const affIdMap = {
    standard: 10033
};

const generateSelectionsParam = outcomes =>
    encodeURIComponent(
        outcomes.map(({ id, fraction }) => `${id}|${fraction}`).join(',')
    );

const getAccaMarketShorthand = market => {
    switch (market) {
        case fullTimeResult:
            return 'mr';
        case bothTeamsToScore:
            return 'bt';
        case underOverGoals:
            return 'uo';
        default:
            return '';
    }
};

const getMarketShorthand = market => {
    const standard = market
        .toLowerCase()
        .trim()
        .replace(/ /g, '-');
    switch (standard) {
        case 'full-time-result':
            return 'ft-result';
        case '1st-goalscorer':
            return 'first-scorer';
        case 'time-of-1st-goal':
            return 'time-of-first-goal';
        default:
            return standard;
    }
};

export const buildAccaDcmp = ({
    prefix,
    isPartReg,
    market,
    selections = [],
    trialNumber = null
}) => {
    const partRegValue = isPartReg ? 'pb' : 'f';
    const trialNumberValue =
        trialNumber && (trialNumber >= 0 || trialNumber <= 99)
            ? trialNumber
            : -1;
    const selectionCount = selections.length;
    const marketValue = getAccaMarketShorthand(market);
    return `${prefix}-entryconf-${partRegValue}-${selectionCount}-${marketValue}-tn${trialNumberValue}-`;
};

export const buildDcmp = ({ prefix, isPartReg, market }) => {
    const partRegValue = isPartReg ? 'pb' : 'f';
    let marketValue = '';
    if (market) {
        marketValue = `${getMarketShorthand(market)}-`;
    }
    return `${prefix}-${marketValue}${partRegValue}`;
};

export const generateBetslipLink = ({
    affId,
    dcmp,
    promo = null,
    selections = []
}) => {
    const query = [
        `redirectPath=${encodeURIComponent('/go/class/5/bet')}`,
        `AFF_ID=${affId}`,
        `dcmp=${dcmp}`
    ];

    if (selections.length > 0) {
        query.push(`sels=${generateSelectionsParam(selections)}`);
    }

    if (promo) {
        query.push(`sba_promo=${promo}`);
    }

    return buildTransferLink(BET, `/go/class/5/bet?${query.join('&')}`);
};
