import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import ConnectedReferralHandler from '../../components/ReferralHandler/ReferralHandler';
import ConnectedModal from '../../components/Modals';
import Footer from '../../components/Footer';
import ConnectedXSell from '../../components/XSell';
import ConnectedMessages from '../../components/Messages';
import { isUsingIOSV3OrHigher } from '../../helpers/appBridge';

const Layout = ({ children }) => {
    const shouldHideHeaderAndFooter = isUsingIOSV3OrHigher();

    return (
        <>
            <div className="grow-container">
                <ConnectedMessages />
                <ConnectedModal />
                {!shouldHideHeaderAndFooter && (
                    <>
                        <ConnectedXSell />
                        <Header />
                    </>
                )}
                <ConnectedReferralHandler />
                <div
                    className="App"
                    data-has-header={!shouldHideHeaderAndFooter}
                >
                    {children}
                </div>
            </div>
            {!shouldHideHeaderAndFooter && (
                <>
                    <Footer />
                </>
            )}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.element.isRequired
};

export default Layout;
