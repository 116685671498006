import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import StreaksBadge from '../../components/StreaksBadge';
import { selectIsLoggedIn } from '../../store/modules/auth/selectors';
import {
    selectCurrentStreakForSelf,
    selectLoadingStatsForSelf
} from '../../store/modules/stats/selectors';
import { requestStatsForSelf } from '../../store/modules/stats/actions';
import { isFeatureOn } from '../../store/modules/content/selectors';
import {
    ENABLE_MY_PERFORMANCE_PAGE,
    ENABLE_STREAKS_BANNER,
    ENABLE_STREAKS_ALERT
} from '../../features';
import StreakAlertStorage from '../../components/StreaksAlert/storage';
import { StreaksContainer } from './styles';
import { StreaksAnimationWrapper } from '../../components/StreaksBadge/styles';
import elementText from '../../helpers/GoogleAnalytics/element_text';

const ConnectedStreaksBadge = ({
    isLoggedIn,
    currentStreak,
    getStatsForSelf,
    isLoading,
    enabled,
    alertEnabled,
    streaksTextLeftBold,
    streaksTextRight,
    setNavigationEvent
}) => {
    const { pathname } = useLocation();
    const paths = ['/play', '/league', '/myperformancehub'];

    useEffect(() => {
        if (enabled && isLoggedIn) {
            getStatsForSelf(true);
        }
    }, [isLoggedIn, getStatsForSelf, enabled]);

    if (
        !enabled ||
        !isLoggedIn ||
        isLoading ||
        typeof currentStreak === 'undefined'
    ) {
        return null;
    }

    return (
        <>
            <StreaksContainer
                to="/myperformancehub"
                data-test-id="nav-streaks-container"
                aria-label="My Performance Hub"
                onClick={() =>
                    setNavigationEvent(
                        elementText.navigation.myPerformanceHub,
                        3,
                        'https://super6.skysports.com/myperformancehub'
                    )
                }
            >
                <StreaksAnimationWrapper>
                    <StreaksBadge currentStreak={currentStreak} />
                </StreaksAnimationWrapper>
            </StreaksContainer>
            {alertEnabled && paths.includes(pathname) === false ? (
                <StreakAlertStorage
                    streaksTextLeftBold={streaksTextLeftBold}
                    streaksTextRight={streaksTextRight}
                    linkTo="/myperformancehub"
                />
            ) : null}
        </>
    );
};

ConnectedStreaksBadge.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    getStatsForSelf: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    alertEnabled: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentStreak: PropTypes.number,
    streaksTextLeftBold: PropTypes.string,
    streaksTextRight: PropTypes.string,
    setNavigationEvent: PropTypes.any
};

ConnectedStreaksBadge.defaultProps = {
    currentStreak: undefined,
    streaksTextLeftBold: 'New!',
    streaksTextRight: 'Discover Streaks!',
    setNavigationEvent: () => {}
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    currentStreak: selectCurrentStreakForSelf(state),
    isLoading: selectLoadingStatsForSelf(state),
    enabled:
        isFeatureOn(state, ENABLE_STREAKS_BANNER, false) &&
        isFeatureOn(state, ENABLE_MY_PERFORMANCE_PAGE, false),
    alertEnabled:
        isFeatureOn(state, ENABLE_STREAKS_BANNER, false) &&
        isFeatureOn(state, ENABLE_MY_PERFORMANCE_PAGE, false) &&
        isFeatureOn(state, ENABLE_STREAKS_ALERT, false)
});

const mapDispatchToProps = dispatch => ({
    getStatsForSelf: force => dispatch(requestStatsForSelf(force))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedStreaksBadge);
