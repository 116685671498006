import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Modal from '../styles';
import { submitFeedback } from '../../../store/modules/feedback/actions';
import Link from '../../Common/Link';
import { Hr } from '../../Common/Hr/styles';
import * as Styled from './styles';
import useHandleValue from '../../../hooks/useHandleValue';
import Button from '../../Common/Button';
import { sendPageView } from '../../../helpers/GoogleAnalytics/gaHelper';

export const FeedbackForm = ({ submit }) => {
    const [about, handleAboutChange] = useHandleValue();
    const [comments, handleCommentsChange] = useHandleValue();
    const [recommendation, handleRecommendationChange] = useHandleValue();

    const submitFeedbackForm = e => {
        e.preventDefault();
        if (comments.length > 1000 || !(about && recommendation)) {
            return false;
        }
        const feedback = {
            about,
            comments,
            recommendation
        };
        submit(feedback);
        return true;
    };

    sendPageView('Feedback');

    return (
        <div>
            <Modal.TitleContainer>
                <Modal.Title>Super 6 Feedback</Modal.Title>
                <Modal.TitleParagraph>
                    {`We're always looking to improve Super 6 for our users and we'd
really like to know what you think of the game and how we can improve.`}
                </Modal.TitleParagraph>
                <Modal.TitleParagraph>
                    If you need customer support, then please contact us using
                    one of the following methods.
                </Modal.TitleParagraph>
                <Modal.TitleParagraph>
                    {`Before you contact us for help, please take a look around the site including the `}
                    <Link href="/faq">FAQ&apos;s section</Link>.
                </Modal.TitleParagraph>
                <Button
                    icon="/content/img/logos/X.png"
                    textColour="black"
                    link="https://x.com/super6"
                    isSocialButton
                >
                    X (Formerly Twitter)
                </Button>
                <Button
                    icon="/content/img/logos/facebook-02.png"
                    textColour="black"
                    link="https://www.facebook.com/SUPER6"
                    isSocialButton
                >
                    Facebook
                </Button>
                <Button
                    icon="/content/img/logos/Instagram.png"
                    textColour="black"
                    link="https://www.instagram.com/super6"
                    isSocialButton
                >
                    Instagram
                </Button>
                <Button
                    icon="/content/img/logos/Mail.png"
                    textColour="black"
                    link="mailto:super6@skybet.com?subject=Super%206%20customer%20support"
                    isSocialButton
                >
                    Super6@skybet.com
                </Button>
            </Modal.TitleContainer>
            <Hr />
            <form onSubmit={submitFeedbackForm} id="feedback-form">
                <Styled.FormGroup>
                    <Styled.Label htmlFor="about">
                        What would you like to tell us about today?
                        <Styled.Select
                            value={about}
                            onChange={handleAboutChange}
                            id="about"
                            required
                        >
                            <option value="" disabled>
                                --- Please select ---
                            </option>
                            <option value="Something I like">
                                Something I like
                            </option>
                            <option value="Something I think could be better">
                                Something I think could be better
                            </option>
                            <option value="I have a technical issue">
                                I have a technical issue
                            </option>
                            <option value="Something else">
                                Something else
                            </option>
                        </Styled.Select>
                    </Styled.Label>
                </Styled.FormGroup>
                <Styled.FormGroup>
                    <Styled.Label htmlFor="comments">
                        Please give us your feedback:
                        <Styled.Textarea
                            value={comments}
                            onChange={handleCommentsChange}
                            id="comments"
                            maxLength="1000"
                        />
                    </Styled.Label>
                </Styled.FormGroup>
                <Styled.FormGroup>
                    <Styled.Label htmlFor="recommendation">
                        On a scale of 0-10, how would you rate Super 6 and how
                        likely are you to recommend us to a friend?
                        <Styled.Select
                            value={recommendation}
                            onChange={handleRecommendationChange}
                            id="recommendation"
                            required
                        >
                            <option value="" disabled>
                                --- Please select ---
                            </option>
                            <option value="10">10 - Extremely likely</option>
                            <option value="9">9</option>
                            <option value="8">8</option>
                            <option value="7">7</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                            <option value="0">0 - Not at all likely</option>
                        </Styled.Select>
                    </Styled.Label>
                </Styled.FormGroup>
                <Hr />
                <Styled.SubmitButton type="submit" form="feedback-form">
                    Submit feedback
                </Styled.SubmitButton>
            </form>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    submit: feedback => {
        dispatch(submitFeedback(feedback));
    }
});

FeedbackForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(FeedbackForm);
