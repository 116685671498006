import * as types from './types';

export const requestPundits = () => ({
    type: types.REQUEST_PUNDITS
});

export const receivePundits = pundits => ({
    type: types.RECEIVE_PUNDITS,
    payload: pundits
});

export const requestLeagues = () => ({
    type: types.REQUEST_LEAGUES
});

export const requestLeague = slug => ({
    type: types.REQUEST_LEAGUE,
    slug
});

export const receiveLeagues = leagues => ({
    type: types.RECEIVE_LEAGUES,
    payload: leagues
});

export const receiveLeague = league => ({
    type: types.RECEIVE_LEAGUE,
    payload: league
});

export const leagueNotFound = slug => ({
    type: types.LEAGUE_NOT_FOUND,
    slug
});

export const createLeague = (name, pundits) => ({
    type: types.REQUEST_CREATE_LEAGUE,
    name,
    pundits
});

export const joinLeague = slug => ({
    type: types.REQUEST_JOIN_LEAGUE,
    slug
});

export const postLeagueSuccess = data => ({
    type: types.POST_LEAGUE_SUCCESS,
    payload: data
});

export const postLeagueJoinSuccess = slug => ({
    type: types.POST_LEAGUE_JOIN_SUCCESS,
    slug
});

export const requestMyScore = (slug, period, periodId) => ({
    type: types.REQUEST_MY_SCORE,
    slug,
    period,
    periodId
});

export const receiveMyScore = (slug, period, periodId, payload) => ({
    type: types.RECEIVE_MY_SCORE,
    slug,
    period,
    periodId,
    payload
});

export const requestLeagueResults = (slug, period, periodId, page = 1) => ({
    type: types.REQUEST_LEAGUE_RESULTS,
    slug,
    period,
    periodId,
    page
});

export const receiveLeagueResults = (
    slug,
    period,
    periodId,
    page,
    payload
) => ({
    type: types.RECEIVE_LEAGUE_RESULTS,
    slug,
    period,
    periodId,
    page,
    payload
});

export const deleteLeague = slug => ({
    type: types.REQUEST_DELETE_LEAGUE,
    slug
});

export const leagueDeleteSuccess = slug => ({
    type: types.DELETE_LEAGUE_SUCCESS,
    slug
});

export const leaveLeague = slug => ({
    type: types.REQUEST_LEAVE_LEAGUE,
    slug
});

export const removeMember = (slug, userId, name) => ({
    type: types.REQUEST_REMOVE_MEMBER,
    slug,
    userId,
    name
});

export const removeMemberSuccess = (slug, userId) => ({
    type: types.REMOVE_MEMBER_SUCCESS,
    slug,
    userId
});

export const updateLeague = (slug, name, pundits) => ({
    type: types.REQUEST_UPDATE_LEAGUE,
    slug,
    name,
    pundits
});

export const clearLeagueState = () => ({
    type: types.CLEAR_LEAGUE_STATE
});

export const restartLeague = (slug, name, pundits) => ({
    type: types.REQUEST_RESTART_LEAGUE,
    slug,
    name,
    pundits
});

export const restartLeagueSuccess = (slug, payload) => ({
    type: types.RESTART_LEAGUE_SUCCESS,
    slug,
    payload
});

export const requestLeaguePundits = slug => ({
    type: types.REQUEST_LEAGUE_PUNDITS,
    slug
});

export const receiveLeaguePundits = (slug, payload) => ({
    type: types.RECEIVE_LEAGUE_PUNDITS,
    slug,
    payload
});

export const requestLeagueLeaderboard = (slug, page = 1) => ({
    type: types.REQUEST_LEAGUE_LEADERBOARD,
    slug,
    page
});
