import styled from '@emotion/styled/macro';

export const AmpersandContainer = styled.div`
    // Positioning elements
    position: relative;
    margin: 0 auto -15px auto;
    z-index: 1;

    // Styling ampersand
    width: 25px;
    height: 25px;
    display: flex;
    background: ${props => props.theme.colours.white};
    border: ${props => props.theme.colours.bluePrimary} solid 2px;
    border-radius: 50%;
    color: ${props => props.theme.colours.bluePrimary};
    font-family: ${props => props.theme.fonts.bold};
    font-size: ${props => props.theme.fontSize.normal};

    @media (min-width: ${props => props.theme.widths.largeMobile}) {
        margin: 0 auto -19px auto;
        border: ${props => props.theme.colours.bluePrimary} solid 3px;
        width: 30px;
        height: 30px;
        font-size: ${props => props.theme.fontSize.large};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        margin: 0 auto -19px auto;
        border: ${props => props.theme.colours.bluePrimary} solid 3px;
        width: 30px;
        height: 30px;
        font-size: ${props => props.theme.fontSize.large};
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: ${props => props.largeText && '0 auto -28px auto'};
        border: ${props =>
            props.largeText && `${props.theme.colours.bluePrimary} solid 4px`};
        width: ${props => props.largeText && '45px'};
        height: ${props => props.largeText && '45px'};
        font-size: ${props => props.largeText && props.theme.fontSize.xxlarge};
    }
`;

export const Character = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &::before {
        content: '${props => props.icon}';
        color: ${props => props.theme.colours.bluePrimary};

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            display: flex;
            font-size: ${props => props.theme.fontSize.huge};
            align-items: center;
            margin-top: -6px;
        }

    }
`;
