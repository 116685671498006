import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Icon } from '../../../../components/Common/Page/styles';
import { GreenTick, PinkCross } from '../../../../components/Common/Icons';
import * as Styled from './style';

const getRoundLink = (id, closeDate) => {
    if (closeDate > new Date()) {
        return `/play`;
    }
    return `/results/round/${id}`;
};

const getDateText = date => dayjs(date).format('ddd Do MMM');

const RoundRow = ({ round: { id, name, entered, score, closeDate } }) => (
    <Styled.Row
        to={getRoundLink(id, closeDate)}
        id="link-active-round-enter"
        data-test-id="round-schedule-row"
    >
        <Styled.Col>{`${name} (${getDateText(closeDate)})`}</Styled.Col>
        <Styled.Col>{entered ? <GreenTick /> : <PinkCross />}</Styled.Col>
        {/* Specifically using == here as we want to allow coalescing undefined and null... */}
        <Styled.Col>{score == null ? '-' : score}</Styled.Col>
        <Styled.Col data-test-id={`round-schedule-row-${id}-link`}>
            <Icon data-char={'\uF025'} />
        </Styled.Col>
    </Styled.Row>
);

const roundPropTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    closeDate: PropTypes.instanceOf(Date).isRequired,
    entered: PropTypes.bool.isRequired,
    score: PropTypes.number
};

RoundRow.propTypes = {
    round: PropTypes.shape(roundPropTypes).isRequired
};

// This component makes the assumption that it will only ever be given open or past rounds
const RoundsList = ({ rounds }) => (
    <Styled.Container>
        <Styled.Headers>
            <Styled.Header>Round</Styled.Header>
            <Styled.Header>Entered</Styled.Header>
            <Styled.Header>Score</Styled.Header>
            <Styled.Header>View Round</Styled.Header>
        </Styled.Headers>
        <Styled.Rows>
            {rounds.map(round => (
                <RoundRow round={round} key={round.id} />
            ))}
        </Styled.Rows>
    </Styled.Container>
);

RoundsList.propTypes = {
    rounds: PropTypes.arrayOf(PropTypes.shape(roundPropTypes)).isRequired
};

export default RoundsList;
