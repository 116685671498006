import React from 'react';
import PropTypes from 'prop-types';
import Prediction from './index';
import * as Styled from './styles';

const Predictions = ({ scoreChallenges, predictions, showBorderTop }) => {
    const findScore = challenge => {
        const prediction =
            predictions &&
            predictions.scores.find(
                challengePrediction =>
                    challengePrediction.challengeId === challenge.id
            );

        return (
            prediction || {
                scoreHome: null,
                scoreAway: null,
                isCorrectResult: false,
                isCorrectScore: false,
                points: 0
            }
        );
    };

    return (
        <Styled.PredictionList showBorderTop={showBorderTop}>
            {scoreChallenges.map(challenge => {
                const score = findScore(challenge);
                return (
                    <Prediction
                        key={challenge.id}
                        score={score}
                        challenge={challenge}
                    />
                );
            })}
        </Styled.PredictionList>
    );
};

Predictions.propTypes = {
    scoreChallenges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ),
    predictions: PropTypes.shape({
        scores: PropTypes.arrayOf(
            PropTypes.shape({
                challengeId: PropTypes.number.isRequired,
                scoreHome: PropTypes.number.isRequired,
                scoreAway: PropTypes.number.isRequired
            })
        )
    }),
    showBorderTop: PropTypes.bool
};

Predictions.defaultProps = {
    predictions: null,
    scoreChallenges: [],
    showBorderTop: true
};

export default Predictions;
