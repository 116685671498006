import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { isUsingIOSV3OrHigher } from '../../../helpers/appBridge';

const PageTitle = ({
    children,
    preventCollapse,
    alwaysShowOnApp,
    leftButton
}) => {
    if (!alwaysShowOnApp && isUsingIOSV3OrHigher()) {
        return null;
    }

    if (leftButton) {
        return (
            <Styled.PageTitleWithBackButton
                preventCollapse={preventCollapse}
                data-test-id="page-title"
            >
                <Styled.LeftButtonWrapper data-test-id="page-title-back-button">
                    {leftButton}
                </Styled.LeftButtonWrapper>
                <Styled.TitleWrapper data-test-id="page-title-text">
                    {children}
                </Styled.TitleWrapper>
            </Styled.PageTitleWithBackButton>
        );
    }

    return (
        <Styled.PageTitle
            preventCollapse={preventCollapse}
            data-test-id="page-title"
        >
            {children}
        </Styled.PageTitle>
    );
};

PageTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        )
    ]).isRequired,
    preventCollapse: PropTypes.bool,
    alwaysShowOnApp: PropTypes.bool,
    leftButton: PropTypes.node
};

PageTitle.defaultProps = {
    preventCollapse: false,
    alwaysShowOnApp: false.valueOf(),
    leftButton: null
};

export default PageTitle;
