import styled from '@emotion/styled/macro';

export const LoadMoreContainer = styled.div`
    display: block;
    width: 100%;
    border-radius: 30px;
`;

export const LoadMoreButton = styled.button`
    border: none;
    display: block;
    width: 100%;
    outline: none;
    border-radius: 30px;
    height: 50px;
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.textPrimary};
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};

    i {
        margin: ${props => props.theme.spacing.medium};
        font-size: ${props => props.theme.fontSize.medium};
    }
`;
