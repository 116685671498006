import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const variantToFill = {
    default: 'lightGrey',
    primary: 'colorOnPrimary',
    secondary: 'greenPrimary'
};

const PlusIcon = ({ width, variant, followed, mouseOver }) => (
    <Styled.Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70.14 77.45"
        width={width}
    >
        <g>
            <Styled.Path
                fill={variantToFill[variant]}
                followed={followed}
                mouseOver={mouseOver}
                d="M41.96 39.22c-.28-.95.08-8.27.63-15.34.54-7.07 1.26-13.88 1.26-13.88L44 7.57h.07l.17-3.85c0-.06-.27-1.9-1.93-2.5-2.1-.4-2.95-1.77-8.36-.97-.1.02-2.13.75-2.02 2.26 0 0-.19.98-.14 2.76-.07.78-.13 1.77-.12 2.97 0 3.17-1.43 11.12-1.14 15.98.14 2.43-.19 8.96-.51 15.07-.33 6.11-.66 11.81-.82 12.52-.16.71-.02 3.56.15 6.22.19 2.67.41 5.15.41 5.15s1.04 6.92 1.66 10.99c0 2 1.91 2.29 2.44 2.58.23.4 3.33 1.11 3.69.37.09.12.47-1.09.85-2.43.44-3.81 1.79-11.42 1.79-11.42l1.09-11.13c.53-5.71 1.03-11.71.67-12.92Z"
            />
            <Styled.Path
                fill={variantToFill[variant]}
                followed={followed}
                mouseOver={mouseOver}
                d="M69.77 38.74c-.01-2.13 1.18-3.14-.65-8.4-.03-.09-1.14-2.01-2.62-1.71 0 0-1.43-.09-3.86.42-.54.07-1.16.17-1.85.31-2.73.58-9.86.36-14.02 1.17-2.08.42-7.82.8-13.19.87-5.38.09-10.39-.14-11.02-.27-.63-.13-3.11.04-5.44.24-2.33.2-4.5.38-4.5.38s-1.51.22-3.47.51c-1.96.26-4.38.6-6.18.78-1.76-.11-2.05 1.8-2.32 2.32-.36.21-1.06 3.3-.39 3.7-.11.08.98.53 2.2.99 1.73.34 4.33.89 6.51 1.37l3.93.83s4.99.65 10.27 1.02c5.28.39 10.86.51 11.93.05.84-.35 7.58-.52 14.11-.88 6.53-.34 12.84-.84 12.84-.84 1.73-.17 3.45-.38 5.17-.58l.44-.04.08-.02.29-.03c.05 0 .07-.02.1-.13.56-.27 1.45-.86 1.63-2.05Z"
            />
        </g>
    </Styled.Svg>
);

PlusIcon.propTypes = {
    width: PropTypes.string,
    followed: PropTypes.bool,
    mouseOver: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'primary'])
};

PlusIcon.defaultProps = {
    width: '25px',
    followed: false,
    mouseOver: false,
    variant: 'default'
};

export default PlusIcon;
