import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import NotFound from '../Error/NotFound';
import { buildTransferLink } from '../../helpers/transfer';

const Transfer = () => {
    const { app } = useRouteMatch().params;
    const location = useLocation();
    const trimFromPath = `/transfer/${app}`.length;

    const url = buildTransferLink(
        app,
        `${location.pathname.substr(trimFromPath)}${location.search}${
            location.hash
        }`
    );

    if (!url) {
        return <NotFound />;
    }

    window.location.href = url;
    return null;
};

export default Transfer;
