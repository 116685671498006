import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import Outcome from './Outcome';

const AccaRow = ({
    match,
    marketOutcomes,
    marketConfig,
    selections,
    onChangeSelection
}) => {
    const matchId = parseInt(match.id, 10);
    const outcomes = marketOutcomes.find(
        marketOutcome => marketOutcome.match === matchId
    );
    if (!outcomes) {
        return null;
    }

    return (
        <Styled.MatchRow className="acca-row">
            <Styled.TeamColumn>
                <span>
                    <img
                        alt={match.homeTeam.name}
                        src={match.homeTeam.badgeUri}
                    />{' '}
                    {match.homeTeam.name}
                </span>
                <span>
                    <img
                        alt={match.awayTeam.name}
                        src={match.awayTeam.badgeUri}
                    />{' '}
                    {match.awayTeam.name}
                </span>
            </Styled.TeamColumn>
            <Styled.OutcomeColumns>
                {marketConfig.outcomes.map(outcomeName => (
                    <Outcome
                        key={`${match.id}-${outcomeName}`}
                        matchId={matchId}
                        outcomeName={outcomeName}
                        outcome={outcomes[outcomeName.toLowerCase()]}
                        selections={selections}
                        onChangeSelection={onChangeSelection}
                    />
                ))}
            </Styled.OutcomeColumns>
        </Styled.MatchRow>
    );
};

AccaRow.propTypes = {
    marketOutcomes: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired
        })
    ).isRequired,
    match: PropTypes.shape({
        id: PropTypes.number,
        homeTeam: PropTypes.shape({
            name: PropTypes.string.isRequired,
            badgeUri: PropTypes.string.isRequired
        }),
        awayTeam: PropTypes.shape({
            name: PropTypes.string.isRequired,
            badgeUri: PropTypes.string.isRequired
        })
    }).isRequired,
    marketConfig: PropTypes.shape({
        outcomes: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired,
            selection: PropTypes.string.isRequired
        })
    ).isRequired,
    onChangeSelection: PropTypes.func.isRequired
};

export default AccaRow;
