import { select, put, takeLeading, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import { selectToken } from '../auth/selectors';
import rafApi from '../../../api/raf';

function* setReferrer(action) {
    try {
        const { userId } = action;
        const token = yield select(selectToken);
        if (token) {
            yield call(rafApi.setReferrer, userId, token);
            yield put(actions.postSetReferrer());
        }
    } catch (err) {
        // We can probably silently fail here, it will try again next time they
        // log in
    }
}

export function* watchSetReferrer() {
    yield takeLeading(types.SET_REFERRER, setReferrer);
}

export const sagas = [watchSetReferrer];
