import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import {
    selectHasCheckedAuth,
    selectIsInitialised,
    selectIsLoggedIn
} from '../store/modules/auth/selectors';
import { checkAuth as checkAuthAction } from '../store/modules/auth/actions';
import { isUsingIOSV3OrHigher } from '../helpers/appBridge';

const withAuth = Component => props => {
    /* eslint-disable react/prop-types */
    const {
        checkAuth,
        hasCheckedAuth,
        isAuthInitialised,
        isLoggedIn,
        ...rest
    } = props;

    useEffect(() => {
        if (isAuthInitialised && hasCheckedAuth && !isUsingIOSV3OrHigher()) {
            checkAuth();
        }
    }, [checkAuth, hasCheckedAuth, isAuthInitialised]);

    if (!isAuthInitialised || !hasCheckedAuth) {
        return null;
    }

    if (!isLoggedIn) {
        if (!window.Android) {
            return (
                <Redirect
                    to={`/?redirectTo=${encodeURIComponent(
                        window.location.pathname + window.location.search
                    )}`}
                />
            );
        }
    }

    return <Component {...rest} />; // eslint-disable-line react/jsx-props-no-spreading
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    isAuthInitialised: selectIsInitialised(state),
    hasCheckedAuth: selectHasCheckedAuth(state)
});

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuthAction(true))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withAuth);
