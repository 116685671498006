import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';
import CommonButton from '../../../../components/Common/Button';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
        
    to {
        opacity: 0.8;
    }
`;

export const Container = styled.div`
    max-width: 318px;
    height: 300px;
    border-radius: 14px;
    border: 1px solid ${props => props.theme.colours.gallery};
    background: ${props => props.theme.colours.white};

    position: relative;
    margin-top: 40px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Header = styled.div`
    width: 100%;
    background: center/contain no-repeat
        url('/content/img/performance-hub/streakbox_BG.png');
    height: 85px;
`;

export const StreakBadgeContainer = styled.div`
    width: 45px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 34px;
    user-select: none;
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.skySportsD};
    color: ${props => props.theme.colours.blueZodiac};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.spacing.xlarge};
    margin-top: -10px;
`;

export const Message = styled.div`
    text-align: center;
    color: ${props => props.theme.colours.black};
    font-family: ${props => props.theme.fonts.light};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.fontSize.xlarge};
    padding-left: 32px;
    padding-right: 32px;
`;

export const Button = styled(CommonButton)`
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    background-color: ${props => props.theme.performanceHub.button.primary};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.medium};
`;

export const SubText = styled.div`
    text-align: center;
    color: ${props => props.theme.colours.black};
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.fontSize.large2};
    margin-top: ${props => props.theme.spacing.medium};
`;

export const EntryContainer = styled.div`
    padding-left: 38px;
    padding-right: 38px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    div {
        width: 100%;
        button {
            height: 50px;
        }
    }
`;

export const BoldText = styled.span`
    font-family: ${props => props.theme.fonts.medium};
`;

export const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 75px 15px 15px;

    border-radius: 14px;

    width: 100%;
    height: 100%;

    background: #f4f4f4;

    opacity: 0.9;

    -webkit-animation: ${fadeIn} 0.2s linear;

    display: ${props => (props.isOpen ? 'flex' : 'none')};
`;

export const InfoText = styled.span`
    display: inline-block;
    line-height: 1.5;
    text-align: center;

    font-family: ${props => props.theme.fonts.medium};
`;

export const OverlayButton = styled.button`
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;

    height: 20px;
    width: 20px;

    border-radius: 50%;

    position: absolute;
    top: 12px;
    right: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 0.2s ease-in-out;

    background: ${props => props.theme.colours[props.colour || 'white']};
    font-family: ${props => props.theme.fonts.medium};

    &:focus {
        outline: none;
    }

    &:hover {
        transform: scale(1.1);
    }
`;

export const Close = styled.span`
    font-size: 16px;
`;

export const Open = styled.span`
    font-size: 12px;
`;

export const OverlayImage = styled.img``;
