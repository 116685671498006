import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
        
    to {
        opacity: 0.8;
    }
`;

export const Container = styled.div`
    max-width: 318px;
    border-radius: 26px;
    border: solid 1px ${props => props.theme.lastRound.borderGrey};
    background: ${props => props.theme.gradients.greyToWhite};

    position: relative;
    padding: 20px 20px 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.blueZodiac};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.spacing.xlarge};
`;

export const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;

    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;

    padding: 10px 15px 15px;

    border-radius: 26px;

    width: 100%;
    height: 100%;

    background: #f4f4f4;

    opacity: 0.9;

    -webkit-animation: ${fadeIn} 0.2s linear;
`;

export const KeyTitle = styled.span`
    display: block;
    margin-top: 55px;
    line-height: 1.5;
    text-align: center;

    font-family: ${props => props.theme.fonts.medium};
    font-size: 16px;
`;

export const KeyText = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

export const KeyTextItem = styled.span`
    margin: 5px 0;
    line-height: 1.5;
    text-align: left;

    font-family: ${props => props.theme.fonts.medium};
    font-size: 14px;
`;

export const OverlayButton = styled.button`
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;

    height: 20px;
    width: 20px;

    border-radius: 50%;

    position: absolute;
    top: 12px;
    right: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 0.2s ease-in-out;

    background: ${props => props.theme.colours[props.colour || 'white']};
    font-family: ${props => props.theme.fonts.medium};

    &:focus {
        outline: none;
    }

    &:hover {
        transform: scale(1.1);
    }
`;

export const Close = styled.span`
    font-size: 16px;
`;

export const Open = styled.span`
    font-size: 12px;
`;

export const OverlayImage = styled.img``;

export const EnteredLabel = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${props => props.theme.colours.blueZodiac};
    border: 4px solid ${props => props.theme.colours.greenPrimary};
    border-radius: 10px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 8px;
`;

export const NotEnteredLabel = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${props => props.theme.colours.blueZodiac};
    border: 4px solid ${props => props.theme.colours.redMedium};
    border-radius: 10px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 8px;
`;
