import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const Home = styled.div``;

export const PageContainer = styled.div`
    background-color: ${props =>
        props.theme.componentColours?.pageContainer?.background};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 auto;
        padding: ${props => props.theme.spacing.large} 0;
        max-width: ${props => props.theme.widths.containerMaxWidth};
    }
`;

export const MainContent = styled.div`
    margin: 0 ${props => props.theme.spacing.medium};
`;

export const ColumnContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const Column = styled.div`
    flex: 1 1 50%;
    padding: ${props => props.theme.spacing.normal};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        :first-of-type {
            padding-left: 0;
        }
        :last-of-type {
            padding-right: 0;
        }
    }

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        flex: 1 1 100%;
    }

    box-sizing: border-box;
    display: flex;
`;
export const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledLink = styled(Link)`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.xsmall};
    color: ${props => props.theme.colours.bluePrimary};
    text-decoration: initial;
    padding: ${props => props.theme.spacing.normal};
    font-weight: 700;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props => props.theme.fontSize.xlarge};
    }
`;
