import styled from '@emotion/styled/macro';

export const PageContainer = styled.div`
    padding-left: ${props => props.padding || 0};
    padding-right: ${props => props.padding || 0};
    margin-bottom: ${props => props.theme.spacing.normal};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 auto;
        padding: ${props => props.theme.spacing.large} 0;
        max-width: ${props => props.theme.widths.containerMaxWidth};
    }
`;

export const ContentPageContainer = styled(PageContainer)`
    font-size: 16px;
    color: #333;
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const PageItem = styled.div`
    margin: ${props => `0 ${props.theme.spacing.normal}
        ${props.theme.spacing.normal}
        ${props.theme.spacing.normal}`};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 0 ${props => props.theme.spacing.large} 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:empty {
        margin: 0;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const OrderedList = styled.ol`
    display: block;
    list-style-type: ${props =>
        props.type === 'i' ? 'lower-roman' : 'decimal'};
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
    margin-top: 0;
    margin-bottom: 10px;

    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }
`;

export const UnOrderedList = styled.ul`
    display: block;
    list-style-type: initial;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin-top: 0;
    margin-bottom: 10px;

    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }
`;

export const Icon = styled.i`
    font-family: 'skycons';
    text-transform: none;
    font-size: 16px;
    font-style: normal;
    width: 1.2em;
    height: 1.2em;
    font-weight: normal;

    &:before {
        content: attr(data-char);
    }
`;

export const PageTitle = styled.div`
    padding: ${props => props.theme.spacing.large} 0;
    text-align: center;
    background: ${props => props.theme.colours.bgGrey};
    margin-bottom: ${props => props.theme.spacing.large};
`;

export const PageTitleText = styled.h2`
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: 700;
    color: ${props => props.theme.colours.colorOnPrimary};
`;
