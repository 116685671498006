import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import history from './routing/history';
import ConnectedApp from './App';
import OauthCallbackView from './views/Oauth';
import Layout from './views/Layout';
import LogoutView from './views/Logout';
import { checkWindowSize } from './store/modules/user/actions';
import GlobalStyles from './styles';
import standard from './styles/themes/standard';
import { CookieConsentProvider } from './hooks/useCookieConsents';
import { requestAndroidOauthTokens } from './store/modules/auth/actions';

const AUTHORISED_EVENT = 'authorised';

/**
 * This component wraps the main App component to add the oauth callback route
 * before authentication is loaded/checked
 */
const OAuthRouteAppWrapper = ({
    checkNewWindowWidth,
    getOauthTokenFromNativeAndroid
}) => {
    useEffect(() => {
        const handleResize = () => checkNewWindowWidth(window.innerWidth);
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [checkNewWindowWidth]);

    useLayoutEffect(() => {
        if (window.Android && !window.authorised) {
            window.authorised = new Event(AUTHORISED_EVENT);
            window.addEventListener(
                AUTHORISED_EVENT,
                getOauthTokenFromNativeAndroid,
                false
            );
        }
        return () => {
            window.removeEventListener(
                AUTHORISED_EVENT,
                getOauthTokenFromNativeAndroid
            );
        };
    }, []);

    return (
        <ThemeProvider theme={standard}>
            <GlobalStyles />
            <CookieConsentProvider>
                <Router history={history}>
                    <Layout>
                        {/*
                        This route is here to handle cases where urls have more than 1 leading /, 
                        for example https://super6.skysports.com//league. 
                        Without this, you'd get a 404. 
                        This caused an issue with silent auth as detailed in https://iw-sbg.atlassian.net/browse/S6-3237 
                    */}
                        <Route
                            path="*"
                            render={renderProps => {
                                // Match the first 2 or more concurrent / chars at the start of the path, and if there is a match, redirect it with a single / char. Otherwise render the app as normal.
                                const {
                                    pathname,
                                    search,
                                    hash
                                } = renderProps.location;
                                const regex = /\/\/+/g;
                                if (pathname.match(regex)) {
                                    const cleanUrl = `${pathname.replace(
                                        regex,
                                        '/'
                                    )}${search}${hash}`;
                                    return <Redirect to={cleanUrl} />;
                                }
                                return (
                                    <Switch>
                                        <Route
                                            path="/oauth/skybet"
                                            component={OauthCallbackView}
                                        />
                                        <Route
                                            exact
                                            path="/oauth/logout"
                                            component={LogoutView}
                                        />
                                        <Route component={ConnectedApp} />
                                    </Switch>
                                );
                            }}
                        />
                    </Layout>
                </Router>
            </CookieConsentProvider>
        </ThemeProvider>
    );
};

const mapDispatchToProps = dispatch => ({
    checkNewWindowWidth: width => dispatch(checkWindowSize(width)),
    getOauthTokenFromNativeAndroid: () => dispatch(requestAndroidOauthTokens())
});

OAuthRouteAppWrapper.propTypes = {
    checkNewWindowWidth: PropTypes.func.isRequired,
    getOauthTokenFromNativeAndroid: PropTypes.func.isRequired
};

OAuthRouteAppWrapper.defaultProps = {};

export default connect(null, mapDispatchToProps)(OAuthRouteAppWrapper);
