import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getItem, setItem } from '../../helpers/localStorage';
import StreaksAlert from '.';

const StreakAlertName = 'dismissStreakAlertStorage2021';
const StreakAlertCount = 'streakAlertLoadedCount';

const StreakAlertStorage = ({
    streaksTextLeftBold,
    streaksTextRight,
    linkTo
}) => {
    const [dismissed, setDismissed] = useState(getItem(StreakAlertName));

    if (dismissed) {
        return null;
    }

    const loadedTimes = parseInt(getItem(StreakAlertCount) || 0, 10);

    const dismiss = () => {
        setItem(StreakAlertName, true);
        setDismissed(true);
    };

    if (loadedTimes >= 3) {
        dismiss();
    }

    setItem(StreakAlertCount, loadedTimes + 1);

    return (
        <StreaksAlert
            onClickCancel={dismiss}
            streaksTextLeftBold={streaksTextLeftBold}
            streaksTextRight={streaksTextRight}
            linkTo={linkTo}
        />
    );
};

StreakAlertStorage.propTypes = {
    streaksTextLeftBold: PropTypes.string.isRequired,
    streaksTextRight: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired
};

export default StreakAlertStorage;
