/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Alert from '../../Common/Alert';
import Position from '../Position';
import Spinner from '../../Common/Spinner';
import { selectLatestNonOpenOrFutureRoundId } from '../../../store/modules/round/selectors';
import { requestRounds } from '../../../store/modules/round/actions';
import getPositionChange from '../../../helpers/getPositionChange';
import getFormattedPositionChange from '../../../helpers/getFormattedPositionChange';

export const MyPositionRowComponent = ({
    loadRounds,
    lastCompleteRoundId,
    myScore,
    justMe,
    isManaged,
    isChairperson,
    testId
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);
    const link = `/results/round/${lastCompleteRoundId}`;

    const positionChange = getPositionChange(myScore);

    const formattedPositionChange = getFormattedPositionChange(positionChange);

    let fullName;
    if (myScore) {
        fullName = myScore.name || `${myScore.firstName} ${myScore.lastName}`;
    }

    return (
        <>
            <Styled.MyPositionTable justMe={justMe} data-test-id={testId}>
                <Styled.ResultTable border="0">
                    <tbody>
                        <Styled.ResultRow>
                            <Styled.ScoreColumn startOfRow>
                                {myScore && (
                                    <Styled.MyPositionLink to={link}>
                                        <Position position={myScore.position} />
                                    </Styled.MyPositionLink>
                                )}
                                {!myScore && <Spinner colour="black" />}
                            </Styled.ScoreColumn>
                            {formattedPositionChange && (
                                <Styled.ScoreColumn>
                                    {myScore && (
                                        <Styled.MyPositionLink to={link}>
                                            {formattedPositionChange}
                                        </Styled.MyPositionLink>
                                    )}
                                    {!myScore && <Spinner colour="black" />}
                                </Styled.ScoreColumn>
                            )}
                            <Styled.ScoreColumn isName>
                                {myScore && (
                                    <Styled.MyPositionLink to={link}>
                                        {fullName}
                                    </Styled.MyPositionLink>
                                )}
                                {!myScore && <Spinner colour="black" />}
                            </Styled.ScoreColumn>
                            <Styled.ScoreColumn hideOnSmall>
                                {myScore && (
                                    <Styled.MyPositionLink to={link}>
                                        {myScore.correctResults}
                                    </Styled.MyPositionLink>
                                )}
                                {!myScore && <Spinner colour="black" />}
                            </Styled.ScoreColumn>
                            <Styled.ScoreColumn hideOnSmall>
                                {myScore && (
                                    <Styled.MyPositionLink to={link}>
                                        {myScore.correctScores}
                                    </Styled.MyPositionLink>
                                )}
                                {!myScore && <Spinner colour="black" />}
                            </Styled.ScoreColumn>
                            {isChairperson && (
                                <Styled.ScoreColumn>
                                    {myScore && (
                                        <Styled.MyPositionLink
                                            to={link}
                                            data-test-id="my-position-points-column"
                                        >
                                            {myScore.points || '0'}
                                        </Styled.MyPositionLink>
                                    )}
                                    {!myScore && <Spinner colour="black" />}
                                </Styled.ScoreColumn>
                            )}
                            {!isChairperson && (
                                <Styled.PointsColumn>
                                    {myScore && (
                                        <Styled.MyPositionLink
                                            to={link}
                                            data-test-id="my-position-points-column"
                                        >
                                            {myScore.points || '0'}
                                        </Styled.MyPositionLink>
                                    )}
                                    {!myScore && <Spinner colour="black" />}
                                </Styled.PointsColumn>
                            )}
                            {isChairperson && <Styled.PointsColumn />}
                        </Styled.ResultRow>
                    </tbody>
                </Styled.ResultTable>
            </Styled.MyPositionTable>
            {!isManaged && justMe && (
                <>
                    <Alert type="Warning">
                        It&apos;s only you! Share your league PIN with friends
                        so that they can join.
                    </Alert>
                </>
            )}
        </>
    );
};

MyPositionRowComponent.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    myScore: PropTypes.shape({
        userId: PropTypes.number,
        position: PropTypes.number,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isWinner: PropTypes.bool,
        goldenGoal: PropTypes.number,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        points: PropTypes.number,
        positionChange: PropTypes.string,
        positionLastRound: PropTypes.number
    }),
    lastCompleteRoundId: PropTypes.number,
    justMe: PropTypes.bool,
    isManaged: PropTypes.bool,
    isChairperson: PropTypes.bool,
    testId: PropTypes.string
};

MyPositionRowComponent.defaultProps = {
    myScore: null,
    lastCompleteRoundId: 0,
    testId: 'league',
    justMe: false,
    isManaged: false,
    isChairperson: false
};

const mapStateToProps = state => ({
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyPositionRowComponent);
