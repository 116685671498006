import React from 'react';
import PropTypes from 'prop-types';
import Team from '../../Team';
import * as Styled from './styles';

const LastRoundFixtures = ({ homeTeam, awayTeam }) => (
    <Styled.MatchContainer>
        <Styled.Match>
            <Team team={homeTeam} />
            <Team team={awayTeam} />
        </Styled.Match>
    </Styled.MatchContainer>
);

LastRoundFixtures.propTypes = {
    homeTeam: PropTypes.shape({
        badgeUri: PropTypes.string
    }).isRequired,
    awayTeam: PropTypes.shape({
        badgeUri: PropTypes.string
    }).isRequired
};

export default LastRoundFixtures;
