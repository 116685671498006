import axios from 'axios';
import { API_ROOT } from '../config';
import { getUserSessionId } from '../helpers/sessionStorage';

const statsApi = {
    getStatsForSelf: async token => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/stats/user/self`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    Accept: 'application/json'
                }
            }
        );
        if (status !== 200) {
            throw new Error('Unable to get self stats');
        }

        return data;
    },
    getSeasonStatsForSelf: async token => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/season/stats/user/self`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    Accept: 'application/json'
                }
            }
        );
        if (status !== 200) {
            throw new Error('Unable to get season stats');
        }

        return data;
    },
    getSeasonHeadToHeadStats: async token => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/stats/head-to-head`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    Accept: 'application/json'
                }
            }
        );
        if (status !== 200) {
            throw new Error('Unable to get season head to head stats');
        }

        return data;
    }
};

export default statsApi;
