import styled from '@emotion/styled/macro';

export const FormGroup = styled.div`
    margin-bottom: ${props => props.theme.spacing.large};
    color: ${props => props.theme.colours.modalGrey};
`;

export const Label = styled.label`
    display: block;
    font-family: ${props => props.theme.fonts.medium};
    line-height: ${props => props.theme.lineHeight.normal};
    font-size: ${props => props.theme.fontSize.medium};
`;

export const Select = styled.select`
    box-sizing: border-box;
    margin-top: 5px;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    color: #555;
    background-color: ${props => props.theme.colours.white};
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const Textarea = styled.textarea`
    height: auto;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
    margin-top: 5px;
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    color: #555;
    background-color: ${props => props.theme.colours.white};
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export const SubmitButton = styled.button`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: 4px;
    color: ${props => props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: ${props => (props.enabled ? 'pointer' : 'default')};
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours.greenDark};
        color: white;
    }
    font-size: ${props => props.theme.fontSize.normal};
    padding: ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.xxlarge};
`;

export default FormGroup;
