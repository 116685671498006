import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../mastheadStyles';
import MastheadTitle from '../MastheadTitle';

const PrizesMastheadContent = ({
    isComplete,
    hasWon,
    beatPunditPercentage,
    opponentFirstName,
    opponentSurname,
    opponentPoints
}) => {
    if (isComplete) {
        if (!hasWon) {
            return (
                <Styled.Content>
                    <MastheadTitle
                        text={`You're not a prize winner this time`}
                        align="left"
                        isInplay={false}
                    />
                    <Styled.SubTitle>
                        This round
                        <Styled.BeatPunditPercentage>
                            {beatPunditPercentage}
                        </Styled.BeatPunditPercentage>
                        % of people beat {opponentFirstName} to get into the
                        prize draw
                    </Styled.SubTitle>
                    <Styled.SubTitle>
                        {opponentFirstName} {opponentSurname}
                        <Styled.OpponentPoints
                            data-test-id="h2h-prizes-opponent-points"
                            textColour="white"
                        >
                            {opponentPoints || '-'}
                        </Styled.OpponentPoints>
                        Points
                    </Styled.SubTitle>
                </Styled.Content>
            );
        }

        return (
            <Styled.Content>
                <MastheadTitle align="left" hasWon />
                <Styled.SubTitle textColour="white">
                    This round
                    <Styled.BeatPunditPercentage textColour="white">
                        {beatPunditPercentage}
                    </Styled.BeatPunditPercentage>
                    % of people beat {opponentFirstName} to get into the prize
                    draw
                </Styled.SubTitle>
                <Styled.SubTitle textColour="white">
                    {opponentFirstName} {opponentSurname}
                    <Styled.OpponentPoints
                        data-test-id="h2h-prizes-opponent-points"
                        bgColour="white"
                    >
                        {opponentPoints}
                    </Styled.OpponentPoints>
                    Points
                </Styled.SubTitle>
            </Styled.Content>
        );
    }

    return (
        <Styled.Content>
            <MastheadTitle
                text="Please check back later for this round's winners"
                align="left"
                isInplay={false}
            />
            <Styled.SubTitle>
                {opponentFirstName} {opponentSurname}
                <Styled.OpponentPoints
                    data-test-id="h2h-prizes-opponent-points"
                    textColour="white"
                >
                    {opponentPoints || '-'}
                </Styled.OpponentPoints>
                Points
            </Styled.SubTitle>
        </Styled.Content>
    );
};

PrizesMastheadContent.defaultProps = {
    beatPunditPercentage: 0,
    opponentPoints: 0
};

PrizesMastheadContent.propTypes = {
    isComplete: PropTypes.bool.isRequired,
    hasWon: PropTypes.bool.isRequired,
    beatPunditPercentage: PropTypes.number,
    opponentFirstName: PropTypes.string.isRequired,
    opponentSurname: PropTypes.string.isRequired,
    opponentPoints: PropTypes.number
};

export default PrizesMastheadContent;
