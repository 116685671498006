import styled from '@emotion/styled/macro';

export const Container = styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: ${props => props.theme.spacing.vlarge};
    background-color: ${props =>
        props.bgBlue
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    border-radius: ${props => props.theme.radius.normal};
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: ${props => props.theme.spacing.vlarge} 0 0
        ${props => props.theme.spacing.vlarge};
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.lineHeight.small2};
    text-align: ${props => props.align};
    text-transform: uppercase;
    color: ${props =>
        props.hasWon
            ? props.theme.colours.white
            : props.theme.colours.colorOnPrimary};
    margin-bottom: ${props => props.theme.spacing.vlarge};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.xlarge};
        margin-bottom: 30px;
    }
`;

export const TitleSuccess = styled.div`
    color: ${props => props.theme.colours.greenPrimary};
    display: block;
`;

export const SubTitle = styled.div`
    line-height: ${props => props.theme.lineHeight.small2};
    color: ${props =>
        props.textColour
            ? props.textColour
            : props.theme.colours.colorOnPrimary};
    margin-top: ${props => props.theme.spacing.xlarge2};

    &:last-of-type {
        margin-top: ${props => props.theme.spacing.vlarge};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large2};
    }
`;

export const Content = styled.div`
    width: 100%;
    margin: ${props => props.theme.spacing.medium};
    margin-left: 0;

    @media (min-width: ${props => props.theme.widths.largeMobile}) {
        margin-right: ${props => props.theme.spacing.large};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        margin-top: ${props => props.theme.spacing.large};
        margin-right: ${props => props.theme.spacing.xlarge};
    }
`;

export const Image = styled.div`
    display: flex;
    align-items: flex-end;
    background-image: url(${props => props.opponentImage});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    flex: 0 0 42%;

    @media (min-width: ${props => props.theme.widths.smallMobile}) {
        margin-left: ${props => props.theme.spacing.small};
        flex: 0 0 40%;
        background-position: top;
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        flex: 0 0 30%;
        min-height: 230px;
    }
`;

export const SummaryRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    font-family: ${props =>
        props.bold ? props.theme.fonts.medium : props.theme.fonts.regular};
    margin-bottom: ${props => props.theme.spacing.medium};
`;

export const SummaryRowDivider = styled.hr`
    height: 2px;
    border-width: 0;
    background-color: ${props => props.theme.colours.brightGray};
    margin-bottom: 10px;
`;

export const SummaryPointsLabel = styled.div`
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.medium};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large2};
    }
`;

export const SummaryPointsBox = styled.div`
    text-align: center;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    font-size: ${props => props.theme.fontSize.normal};
    font-weight: 700;
    font-family: ${props => props.theme.fonts.skySportsD};
    padding: 3px;
    box-sizing: border-box;

    color: ${props =>
        props.success
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    background-color: ${props =>
        props.success
            ? props.theme.colours.greenPrimary
            : props.theme.colours.blueDark};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        width: 35px;
        height: 35px;
        padding: ${props => props.theme.spacing.normal};
        font-size: ${props => props.theme.fontSize.large};
    }
`;

export const SummaryTitle = styled.div`
    font-size: ${props => props.theme.fontSize.normal};
    text-align: center;

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large2};
    }
`;

export const BannerContainer = styled.div`
    margin: 0 auto ${props => props.theme.spacing.vlarge};
`;

export const SummaryContainer = styled.div`
    color: ${props => props.theme.colours.colorOnPrimary};

    a {
        font-family: ${props => props.theme.fonts.medium};
        text-decoration: underline;
        margin-bottom: ${props => props.theme.spacing.medium};
        font-size: ${props => props.theme.fontSize.large};
        color: inherit;
    }
`;

export const BeatPunditPercentage = styled.span`
    color: ${props =>
        props.textColour
            ? props.textColour
            : props.theme.colours.colorOnPrimary};
    font-weight: 900;
    padding-left: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.medium};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.xlarge};
    }
`;

export const OpponentPoints = styled.span`
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    padding: ${props => props.theme.spacing.small};
    margin: 0 ${props => props.theme.spacing.small} 0
        ${props => props.theme.spacing.vlarge};
    text-align: center;
    color: ${props =>
        props.textColour
            ? props.textColour
            : props.theme.colours.colorOnPrimary};
    background-color: ${props =>
        props.bgColour ? props.bgColour : props.theme.colours.colorOnPrimary};
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.normal};
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.skySportsD};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large};
        height: 22px;
        width: 22px;
        padding: ${props => props.theme.spacing.small};
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props =>
        props.hasWon
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    background-color: ${props =>
        props.hasWon
            ? props.theme.colours.greenPrimary
            : props.theme.colours.colorOnPrimary};
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.xsmall};
    padding: ${props => props.theme.spacing.normal}
        ${props => props.theme.spacing.large};
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.skySportsD};

    @media (min-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.normal};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large2};
    }
`;

export const FooterPrize = styled.div`
    color: ${props =>
        props.hasWon
            ? props.theme.colours.white
            : props.theme.colours.colorOnPrimary};
    background-color: ${props =>
        props.hasWon
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.greenPrimary};
    font-weight: 900;
    padding: ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.medium};
    margin-left: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.xsmall};
    text-align: center;

    @media (min-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.small};
        margin-left: ${props => props.theme.spacing.normal};
    }
    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large2};
    }
`;
