import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { sendNavigationEvent } from '../../../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../../../helpers/GoogleAnalytics/modules';

const renderPredictionClosedDate = date =>
    date ? <Styled.Info>{date}</Styled.Info> : null;

const InfoContainer = ({
    isLoading,
    logoPath,
    prizeImagePaths,
    link,
    variant,
    predictionClosedDate,
    buttonText
}) => {
    if (isLoading) {
        return (
            <Styled.InfoContainer variant="loading">
                <Styled.LoadingInner>
                    <Styled.LoadingLogo />
                    <Styled.LoadingPrize>
                        <Styled.LoadingPrizePrimary />
                        <Styled.LoadingPrizeSecondary />
                    </Styled.LoadingPrize>
                    <Styled.LoadingButton />
                </Styled.LoadingInner>
            </Styled.InfoContainer>
        );
    }

    const { desktop } = prizeImagePaths;

    return (
        <Styled.InfoContainer variant={variant}>
            <Styled.InfoInner variant={variant}>
                {variant === 'extra' && <Styled.Logo src={logoPath} alt="" />}
                <Styled.PrizeContainer variant={variant}>
                    <Styled.PrizeImage src={desktop} alt="" />
                </Styled.PrizeContainer>
                {variant === 'default' && (
                    <Styled.HeroButtonWrapper>
                        <Styled.HeroButton
                            variant={variant}
                            data-target-id={`s6-${variant}-cta-play-btn`}
                            link={link}
                            disabled={link === null}
                            internal
                            sendNavEvent={() =>
                                sendNavigationEvent(
                                    modules.homepage.playForFree,
                                    1,
                                    buttonText,
                                    1,
                                    `https://super6.skysports.com${link}`
                                )
                            }
                        >
                            {buttonText}
                        </Styled.HeroButton>
                    </Styled.HeroButtonWrapper>
                )}
                <Styled.InfoFooter>
                    {variant === 'extra' && (
                        <Styled.HeroAppButton
                            app="super6extra"
                            link={link}
                            target="_blank"
                            rel="noreferrer noopener"
                            targetId={`s6-${variant}-cta-play-btn`}
                            testId={`s6-${variant}-cta-play-btn`}
                        >
                            {buttonText}
                        </Styled.HeroAppButton>
                    )}
                    {variant === 'extra' &&
                        renderPredictionClosedDate(predictionClosedDate)}
                </Styled.InfoFooter>
            </Styled.InfoInner>
        </Styled.InfoContainer>
    );
};

InfoContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    prizeImagePaths: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired
    }),
    logoPath: PropTypes.string.isRequired,
    link: PropTypes.string,
    variant: PropTypes.string,
    predictionClosedDate: PropTypes.string,
    buttonText: PropTypes.string
};

InfoContainer.defaultProps = {
    variant: 'default',
    predictionClosedDate: '',
    prizeImagePaths: {
        desktop: null,
        mobile: null
    },
    buttonText: 'Play For Free',
    link: null
};

export default InfoContainer;
