import styled from '@emotion/styled/macro';

// eslint-disable-next-line import/prefer-default-export
export const OfferAsset = styled.div`
    border-radius: 4px;
    width: 100%;
    * {
        border-radius: 4px;
    }
`;
