import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Profile = ({
    onDismiss,
    onAccountClick,
    onLogOutClick,
    onForgotClick
}) => (
    <div>
        <Styled.ContainerBody>
            <Styled.TransparentButton
                data-test-id="close-modal"
                onClick={onDismiss}
            >
                &#x2715;
            </Styled.TransparentButton>
            <Styled.DialogBody>
                <Styled.ControlButton>
                    <button
                        type="button"
                        onClick={onAccountClick}
                        className="primary"
                    >
                        MY ACCOUNT
                    </button>
                </Styled.ControlButton>
                <Styled.ControlButton>
                    <button
                        type="button"
                        onClick={onLogOutClick}
                        className="success"
                    >
                        LOG OUT
                    </button>
                </Styled.ControlButton>
                <div>
                    <Styled.BottomText onClick={onForgotClick}>
                        Forget my account from this device
                    </Styled.BottomText>
                </div>
            </Styled.DialogBody>
        </Styled.ContainerBody>
    </div>
);

Profile.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    onAccountClick: PropTypes.func.isRequired,
    onLogOutClick: PropTypes.func.isRequired,
    onForgotClick: PropTypes.func.isRequired
};

Profile.defaultProps = {};

export default Profile;
