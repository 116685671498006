import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';
import Component from './component/index';

export default function index({ link }) {
    return link ? (
        <Styled.WinnerNoticeLink to={link} data-test-id="winner-notice-link">
            <Component />
        </Styled.WinnerNoticeLink>
    ) : (
        <Component />
    );
}

index.prototype = {
    link: PropTypes.string
};
index.defaultProps = { link: null };
