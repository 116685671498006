import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import * as Modal from '../../Modals/styles';
import { Hr } from '../../Common/Hr/styles';
import { BetButtonWrapper } from '../../Common/Button/styles';
import AppLink from '../../Common/AppLink';
import {
    BET,
    FANTASYFOOTBALL,
    VEGAS,
    CASINO,
    ITV7,
    buildTransferLink
} from '../../../helpers/transfer';
import buildCookieUrl from '../../../helpers/buildCookieUrl';
import { useCookieConsentContext } from '../../../hooks/useCookieConsents';
import { OAUTH_URI } from '../../../config';
import { sendBannerEvent } from '../../../helpers/GoogleAnalytics/gaHelper';
import actions from '../../../helpers/GoogleAnalytics/actions';
import modules from '../../../helpers/GoogleAnalytics/modules';
import elementText from '../../../helpers/GoogleAnalytics/element_text';

const mobileApps = {
    skybet: BET,
    skyvegas: VEGAS,
    SkyFF: FANTASYFOOTBALL,
    SkyCasino: CASINO,
    Pick7: ITV7
};

export const buildTransferLinkUri = (mobileApp, linkUri) => {
    const transferApp = mobileApps[mobileApp];

    if (!transferApp) {
        return linkUri;
    }

    let path = linkUri;

    if (linkUri.startsWith('http')) {
        const url = new URL(linkUri);
        path = `${url.pathname}${url.search}${url.hash}`;
    }

    return buildTransferLink(transferApp, path);
};

const PostPredictionOffer = ({
    callToAction,
    title,
    description,
    linkUri,
    linkTermsUri,
    desktopImageset,
    mobileImageset,
    mobileApp
}) => {
    const mobile3x = mobileImageset['3x'];
    const mobile2x = mobileImageset['2x'];
    const mobile1x = mobileImageset['1x'];

    const desktop3x = desktopImageset['3x'];
    const desktop2x = desktopImageset['2x'];
    const desktop1x = desktopImageset['1x'];

    const transferLinkUri = buildTransferLinkUri(mobileApp, linkUri);

    const { cookieConsentQuery } = useCookieConsentContext();

    const setBannerEvent = (text, action, position, url) => {
        sendBannerEvent(
            text,
            modules.playedFlow.splashOffer,
            action,
            position,
            url
        );
    };

    return (
        <div>
            <Modal.TitleContainer>
                <Modal.Title>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                </Modal.Title>
                <Modal.TitleParagraph>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: description }} />
                </Modal.TitleParagraph>
            </Modal.TitleContainer>
            <Styled.ImageLink
                onClick={() =>
                    setBannerEvent(
                        elementText.banner.betHere,
                        actions.clicked,
                        1,
                        transferLinkUri
                    )
                }
            >
                <AppLink
                    app={mobileApp === 'none' ? '' : mobileApp}
                    link={transferLinkUri}
                >
                    {mobile2x && desktop2x && (
                        <Styled.Picture>
                            <source
                                media="(max-width: 1023px)"
                                srcSet={`
                            ${mobile3x} 3x,
                            ${mobile2x} 2x,
                            ${mobile1x} 1x
                        `}
                            />
                            <source
                                srcSet={`
                            ${desktop3x} 3x,
                            ${desktop2x} 2x,
                            ${desktop1x} 1x
                        `}
                            />

                            <img src={desktop2x} alt="Masthead" />
                        </Styled.Picture>
                    )}
                </AppLink>
            </Styled.ImageLink>
            <Hr />
            <Styled.ButtonContainer>
                <BetButtonWrapper
                    onClick={() =>
                        setBannerEvent(
                            elementText.banner.betHere,
                            actions.clicked,
                            2,
                            transferLinkUri
                        )
                    }
                >
                    <AppLink
                        app={mobileApp === 'none' ? '' : mobileApp}
                        link={buildCookieUrl(
                            OAUTH_URI,
                            transferLinkUri,
                            cookieConsentQuery
                        )}
                    >
                        {callToAction}
                    </AppLink>
                </BetButtonWrapper>
            </Styled.ButtonContainer>
            <Styled.TermsLink
                rel="noopener noreferrer"
                target="_blank"
                href={buildCookieUrl(
                    OAUTH_URI,
                    linkTermsUri,
                    cookieConsentQuery
                )}
            >
                18+. Terms and Conditions apply.
            </Styled.TermsLink>
        </div>
    );
};

PostPredictionOffer.propTypes = {
    callToAction: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    linkUri: PropTypes.string.isRequired,
    linkTermsUri: PropTypes.string.isRequired,
    desktopImageset: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    mobileImageset: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    mobileApp: PropTypes.string.isRequired
};

export default PostPredictionOffer;
