import * as types from './types';
import * as followTypes from '../follow/types';
import { ACTIVE_ROUND_STATE_CHANGE, ACTIVE_ROUND_CHANGE } from '../round/types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    referreesLeaderboard: { season: {}, month: {}, round: {} },
    selfLeaderboard: [],
    search: {},
    leaderboard: {
        global: [],
        pundits: [],
        following: []
    }
};

const formatLeaderboardResult = ({ payload, period, periodId }) => ({
    period,
    periodId,
    results: payload.map(row => {
        const mappedPayload = {
            userId: row.userId,
            name: row.isMasked ? null : `${row.firstName} ${row.lastName}`,
            position: row.position || 0,
            isWinner: row.isWinner || false,
            isMasked: row.isMasked || false,
            correctResults: row.correctResults || 0,
            correctScores: row.correctScores || 0,
            points: row.points || 0
        };

        if (period === 'season') {
            mappedPayload.positionChange = row.positionChange;
        }

        return mappedPayload;
    })
});

export default (state = defaultState, action) => {
    switch (action.type) {
        case ACTIVE_ROUND_STATE_CHANGE:
        case ACTIVE_ROUND_CHANGE:
            return newIfChanged(state, { ...defaultState });
        case types.RECEIVE_LEADERBOARD_FOR_REFERREES:
            return newIfChanged(state, {
                ...state,
                referreesLeaderboard: {
                    ...state.referreesLeaderboard,
                    [action.period]: {
                        ...state.referreesLeaderboard[action.period],
                        [action.payload.periodId || 'default']: action.payload
                            .leaderboard
                    }
                }
            });
        case types.RECEIVE_LEADERBOARD_FOR_SELF:
            return newIfChanged(state, {
                ...state,
                selfLeaderboard: [
                    ...state.selfLeaderboard,
                    {
                        userId: action.payload.userId,
                        name: action.payload.isMasked
                            ? null
                            : `${action.payload.firstName} ${action.payload.lastName}`,
                        position: action.payload.position || 0,
                        isWinner: action.payload.isWinner || false,
                        isMasked: action.payload.isMasked || false,
                        correctResults: action.payload.correctResults || 0,
                        correctScores: action.payload.correctScores || 0,
                        points: action.payload.points || 0,
                        period: action.period,
                        periodId: action.periodId,
                        positionChange:
                            action.payload.positionChange || undefined
                    }
                ]
            });
        case types.RECEIVE_SEARCH_LEADERBOARD: {
            const newSearch = {};
            newSearch[action.search] = state.search[action.search] || [];

            newSearch[action.search] = [
                ...newSearch[action.search],
                formatLeaderboardResult(action)
            ];

            return newIfChanged(state, {
                ...state,
                search: newSearch
            });
        }
        case types.RECEIVE_GLOBAL_LEADERBOARD:
            return newIfChanged(state, {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    global: [
                        ...state.leaderboard.global,
                        formatLeaderboardResult(action)
                    ]
                }
            });
        case types.RECEIVE_FOLLOWEE_LEADERBOARD:
            return newIfChanged(state, {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    following: [
                        ...state.leaderboard.following,
                        formatLeaderboardResult(action)
                    ]
                }
            });
        case types.RECEIVE_PUNDIT_LEADERBOARD:
            return newIfChanged(state, {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    pundits: [
                        ...state.leaderboard.pundits,
                        formatLeaderboardResult(action)
                    ]
                }
            });
        case followTypes.DELETE_FOLLOWEE: {
            let followeeList = state.leaderboard.following;

            followeeList = followeeList.map(dataSet => ({
                ...dataSet,
                results: dataSet.results.filter(
                    result => result.userId !== action.userId
                )
            }));
            return newIfChanged(state, {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    following: followeeList
                }
            });
        }
        case followTypes.REFRESH_FOLLOWEES:
            return newIfChanged(state, {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    following: []
                }
            });
        default:
            return state;
    }
};
