import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestRoundResults } from '../../../../store/modules/results/actions';
import { getRoundResults as selectRoundResults } from '../../../../store/modules/results/selectors';
import isLiveMatch from '../../../../helpers/isLiveMatch';
import isResultedMatch from '../../../../helpers/isResultedMatch';
import ResultMatch from '../ResultMatch';
import * as Styled from '../../styles';
import {
    requestRounds,
    requestLiveOdds
} from '../../../../store/modules/round/actions';

const RoundFixtures = ({
    loadRounds,
    getLiveOdds,
    canBet,
    roundId,
    results,
    getRoundResults,
    userPredictions,
    punditPredictions,
    headToHead,
    liveOdds
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    useEffect(() => {
        if (!results) {
            getRoundResults(roundId);
        }
    }, [roundId, results, getRoundResults]);

    useEffect(() => {
        getLiveOdds();
    }, [liveOdds]);

    if (!results) {
        return null;
    }

    const challenges = results.scoreChallenges.map(scoreChallenge => {
        const userPrediction =
            userPredictions && userPredictions.matches[scoreChallenge.match.id];
        const punditPrediction =
            punditPredictions &&
            punditPredictions.matches[scoreChallenge.match.id];

        const effectiveUserScore = userPrediction ? userPrediction.points : 0;
        const effectivePunditScore = punditPrediction
            ? punditPrediction.points
            : 0;
        const liveOddsForMatch = liveOdds && liveOdds[scoreChallenge.match.id];
        const matchStatus = scoreChallenge.match.status;

        return {
            id: scoreChallenge.match.id,
            userPoints: userPrediction ? userPrediction.points : null,
            opponentPoints: punditPrediction ? punditPrediction.points : null,
            hasMatchStarted: scoreChallenge.match.shortStatus !== '',
            userIsWinning: effectiveUserScore > effectivePunditScore,
            userIsLosing: effectiveUserScore < effectivePunditScore,
            homeTeam: {
                logo: scoreChallenge.match.homeTeam.badgeUri,
                name: scoreChallenge.match.homeTeam.name,
                score: scoreChallenge.match.homeTeam.score,
                userPrediction: userPrediction
                    ? userPrediction.scoreHome
                    : null,
                opponentPrediction: punditPrediction
                    ? punditPrediction.scoreHome
                    : null
            },
            awayTeam: {
                logo: scoreChallenge.match.awayTeam.badgeUri,
                name: scoreChallenge.match.awayTeam.name,
                score: scoreChallenge.match.awayTeam.score,
                userPrediction: userPrediction
                    ? userPrediction.scoreAway
                    : null,
                opponentPrediction: punditPrediction
                    ? punditPrediction.scoreAway
                    : null
            },
            liveOdds: liveOddsForMatch,
            matchStatus
        };
    });

    return (
        <Styled.RoundFixturesContainer data-test-id="round-fixtures-list">
            {challenges.map(challenge => (
                <ResultMatch
                    key={challenge.id}
                    challenge={challenge}
                    headToHead={headToHead}
                    liveOdds={challenge.liveOdds}
                    isLive={isLiveMatch(challenge.matchStatus)}
                    isResulted={isResultedMatch(challenge.matchStatus)}
                    canBet={canBet}
                />
            ))}
        </Styled.RoundFixturesContainer>
    );
};

RoundFixtures.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    getLiveOdds: PropTypes.func.isRequired,
    canBet: PropTypes.bool.isRequired,
    roundId: PropTypes.number.isRequired,
    results: PropTypes.shape({
        scoreChallenges: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                match: PropTypes.shape({
                    shortStatus: PropTypes.string,
                    homeTeam: PropTypes.shape({
                        badgeUri: PropTypes.string,
                        name: PropTypes.string,
                        score: PropTypes.oneOfType([
                            PropTypes.number,
                            PropTypes.string
                        ])
                    }),
                    awayTeam: PropTypes.shape({
                        badgeUri: PropTypes.string,
                        name: PropTypes.string,
                        score: PropTypes.oneOfType([
                            PropTypes.number,
                            PropTypes.string
                        ])
                    })
                })
            })
        )
    }),
    getRoundResults: PropTypes.func.isRequired,
    userPredictions: PropTypes.shape({
        hasPredicted: PropTypes.bool,
        hasEnteredHeadToHead: PropTypes.bool,
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        matches: PropTypes.object
    }),
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        }),
        matches: PropTypes.object
    }),
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }).isRequired,
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    })
};

RoundFixtures.defaultProps = {
    results: null,
    userPredictions: null,
    punditPredictions: null,
    liveOdds: null
};

const mapStateToProps = (state, props) => ({
    canBet: !!state.user.info.canBet,
    results: selectRoundResults(state, props.roundId),
    liveOdds: state.round.liveOdds
});

const mapDispatchToProps = dispatch => ({
    getRoundResults: roundId => dispatch(requestRoundResults(roundId)),
    loadRounds: () => dispatch(requestRounds()),
    getLiveOdds: () => dispatch(requestLiveOdds())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundFixtures);
