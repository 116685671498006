import styled from '@emotion/styled/macro';

export const DeadlineTitle = styled.div`
    padding: ${props => props.theme.spacing.large} 0;
    text-align: center;
    background: ${props => props.theme.colours.bgGrey};
`;

export const DeadlineTitleText = styled.h2`
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: 700;
    color: ${props => props.theme.colours.colorOnPrimary};
`;
