function getResultOutcomeForScore(outcomes, homeScore, awayScore) {
    if (homeScore === awayScore) {
        return outcomes.draw;
    }
    if (homeScore > awayScore) {
        return outcomes.home;
    }
    return outcomes.away;
}

function getCorrectScoreOutcomeForScore(outcomes, homeScore, awayScore) {
    const scoreString = `${homeScore}-${awayScore}`;
    return outcomes[scoreString];
}

function getScorerOutcomeForScore(outcomes, homeScore, awayScore) {
    if (homeScore === 0 && awayScore === 0) {
        return outcomes.none;
    }
    if (homeScore === awayScore) {
        const homeOdds = parseFloat(outcomes.home.decimal);
        const awayOdds = parseFloat(outcomes.away.decimal);

        return homeOdds <= awayOdds ? outcomes.home : outcomes.away;
    }

    if (homeScore > awayScore) {
        return outcomes.home;
    }

    return outcomes.away;
}

function getTimeOfFirstGoalOutcomeForScore(outcomes, homeScore, awayScore) {
    if (homeScore === 0 && awayScore === 0) {
        return outcomes['no goal'];
    }
    return outcomes[Object.keys(outcomes)[0]];
}

const methodsForMarket = {
    'Full Time Result': getResultOutcomeForScore,
    'Correct Score': getCorrectScoreOutcomeForScore,
    '1st Goalscorer': getScorerOutcomeForScore,
    'Time Of First Goal': getTimeOfFirstGoalOutcomeForScore
};

export default (marketName, outcomes, homeScore, awayScore) =>
    methodsForMarket[marketName]
        ? methodsForMarket[marketName](outcomes, homeScore, awayScore)
        : outcomes[Object.keys(outcomes)[0]];
