import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';
import Team from '../../Team';
import Score from '../../Score';

const Prediction = ({ challenge, score }) => (
    <Styled.MatchPrediction
        key={challenge.id}
        data-test-id={`match-prediction-${challenge.id}`}
    >
        <Styled.BadgeContainer>
            <Team
                team={challenge.match.homeTeam}
                size="l"
                mobWidth="25%"
                hideName
            />
            <Team
                team={challenge.match.awayTeam}
                size="l"
                mobWidth="25%"
                hideName
            />
        </Styled.BadgeContainer>
        <Score
            homeScore={score.scoreHome}
            awayScore={score.scoreAway}
            correctResult={score.isCorrectResult}
            correctScore={score.isCorrectScore}
        />
    </Styled.MatchPrediction>
);

Prediction.propTypes = {
    challenge: PropTypes.shape({
        id: PropTypes.number,
        match: PropTypes.shape({
            homeTeam: PropTypes.shape({
                badgeUri: PropTypes.string,
                name: PropTypes.string
            }),
            awayTeam: PropTypes.shape({
                badgeUri: PropTypes.string,
                name: PropTypes.string
            })
        })
    }).isRequired,
    score: PropTypes.shape({
        scoreHome: PropTypes.number,
        scoreAway: PropTypes.number,
        isCorrectResult: PropTypes.bool,
        isCorrectScore: PropTypes.bool
    }).isRequired
};

export default Prediction;
