import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

import Avatar from '../Avatar';

const Profile = ({ name, description, avatarSrc }) => (
    <Styled.Profile>
        <Avatar src={avatarSrc} alt={name} />
        <Styled.Header>
            <Styled.Name data-test-id="pundit-name">{name}</Styled.Name>
            <Styled.Description data-test-id="pundit-description">
                {description}
            </Styled.Description>
        </Styled.Header>
    </Styled.Profile>
);

Profile.propTypes = {
    name: PropTypes.string.isRequired,
    avatarSrc: PropTypes.string.isRequired,
    description: PropTypes.string
};

Profile.defaultProps = {
    description: ''
};

export default Profile;
