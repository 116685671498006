import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getPreviousLeagues,
    selectLeaguePundits
} from '../../../store/modules/league/selectors';
import {
    requestLeaguePundits,
    requestLeagues,
    restartLeague as restartLeagueAction
} from '../../../store/modules/league/actions';
import ConnectedLeagueForm from '../components/LeagueForm';

const RestartLeague = ({
    match,
    previousLeagues,
    getLeagues,
    restartLeague,
    leaguePundits,
    getLeaguePundits
}) => {
    const { slug } = match.params;

    useEffect(() => {
        if (!previousLeagues) {
            getLeagues();
        }
    }, [previousLeagues, getLeagues]);

    useEffect(() => {
        if (!leaguePundits) {
            getLeaguePundits(slug);
        }
    }, [slug, leaguePundits, getLeaguePundits]);

    if (!previousLeagues) {
        return null;
    }

    const league = previousLeagues.find(
        previousLeague => previousLeague.slug === slug
    );

    if (!league) {
        return <Redirect to="/league" />;
    }

    if (!leaguePundits) {
        return null;
    }

    return (
        <ConnectedLeagueForm
            pageTitle="Restart League"
            submitLabel="Restart"
            subtitle="Create a new league"
            leagueNameText="Enter a name for your league"
            defaultLeagueName={league.name}
            defaultPundits={leaguePundits.map(pundit => pundit.userId)}
            onSubmit={restartLeague(slug)}
        />
    );
};

RestartLeague.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            slug: PropTypes.string,
            period: PropTypes.string,
            periodId: PropTypes.string
        })
    }).isRequired,
    previousLeagues: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string
        })
    ),
    getLeagues: PropTypes.func.isRequired,
    restartLeague: PropTypes.func.isRequired,
    leaguePundits: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            userId: PropTypes.number.isRequired
        })
    ),
    getLeaguePundits: PropTypes.func.isRequired
};

RestartLeague.defaultProps = {
    previousLeagues: null,
    leaguePundits: null
};

const mapStateToProps = (state, props) => ({
    previousLeagues: getPreviousLeagues(state),
    leaguePundits: selectLeaguePundits(state, props.match.params.slug)
});

const mapDispatchToProps = dispatch => ({
    getLeagues: () => dispatch(requestLeagues()),
    getLeaguePundits: slug => dispatch(requestLeaguePundits(slug)),
    restartLeague: slug => (name, pundits) =>
        dispatch(restartLeagueAction(slug, name, pundits))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RestartLeague));
