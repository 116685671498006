import axios from 'axios';
import { API_ROOT } from '../config';
import { getUserSessionId } from '../helpers/sessionStorage';

const offerApi = {
    getGlobalBanner: async token => {
        const headers = token
            ? { Authorization: token, 'X-Session-Id': getUserSessionId() }
            : {};
        const {
            data,
            status
        } = await axios.get(`${API_ROOT()}/offer/global-banner`, { headers });

        if (status !== 200) {
            throw new Error('Unable to get offer');
        }
        return data;
    },
    getPostPredictionOffer: async token => {
        const headers = {
            Authorization: token,
            'X-Session-Id': getUserSessionId()
        };
        const {
            data,
            status
        } = await axios.get(`${API_ROOT()}/offer/post-prediction`, { headers });

        if (status !== 200) {
            return null;
        }
        return data;
    },
    getBetslipOffer: async token => {
        const headers = {
            Authorization: token,
            'X-Session-Id': getUserSessionId()
        };
        const { data, status } = await axios.get(
            `${API_ROOT()}/offer/betslip`,
            {
                headers
            }
        );

        if (status !== 200) {
            return null;
        }
        return data;
    }
};

export default offerApi;
