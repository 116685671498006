import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';

export const Svg = styled.svg`
    height: inherit;
`;

export const Text = styled.text`
    fill: ${props => props.theme.colours.textPrimary};
    font-size: ${`${(173.47 / 172.17) * 75}px`};
    font-family: ${props => props.theme.fonts.bold};
`;

export const StreaksAnimationWrapper = styled.div`
    animation: 1s ${keyframes`
        from {
            transform: scale3d(1, 1, 1);
        }
        25% {
            transform: scale3d(1.2, 1.2, 1.2);
        }
        50% {
            transform: scale3d(1, 1, 1)
        }
        75% {
            transform: scale3d(1.2, 1.2, 1.2)
        }
        to {
            transform: scale3d(1, 1, 1)
        }
    `};
    height: 27px;
    width: 42px;
`;
