import styled from '@emotion/styled/macro';

export const Link = styled.a`
    color: ${props => props.theme.colours.linkBlue};
    text-decorate: none;
    :hover,
    :focus {
        color: ${props => props.theme.colours.linkBlueHover};
    }
`;

export default Link;
