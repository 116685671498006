import styled from '@emotion/styled/macro';

export const ScoreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Score = styled.div`
    background-color: ${props => props.theme.scores?.noPoints.bgColour};
    color: ${props => props.theme.scores?.noPoints.text};
    font-size: ${props => props.theme.fontSize.xlarge};
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 37px;
    width: 37px;
    line-height: 37px;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;

    ${props =>
        (props.correctResult || props.correctScore) &&
        `
            background-color: ${props.theme.scores?.points.bgColour};
            color: ${props.theme.scores?.points.text};
        `}

    ${props =>
        props.isScore &&
        `
            background-color: ${props.theme.scores?.score.bgColour};
            color: ${props.theme.scores?.score.text};
        `}

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        height: 43px;
        width: 43px;
        line-height: 43px;
    }
`;

export const Vs = styled.p`
    display: flex;
    align-items: center;
    align-content: center;
    color: ${props => props.theme.scores?.text};
    font-size: ${props => props.theme.fontSize.large};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
`;

export const Tick = styled.div`
    background: url('/content/img/icons/solid-green-tick.svg');
    border-radius: 50%;
    width: 25px;
    height: 25px;
`;
