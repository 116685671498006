import { createSelector } from 'reselect';

export const selectPundits = state => state.league.pundits;
export const getActiveLeagues = state => state.league.active;
export const getPreviousLeagues = state => state.league.previous;
export const selectLeague = (state, slug) =>
    state.league.league && state.league.league.slug === slug
        ? state.league.league
        : null;
const getMyScores = state => state.league.myScores;
const getResults = state => state.league.results;
const getSlug = (state, slug) => slug;
const getPeriod = (state, slug, period) => period;
const getPeriodId = (state, slug, period, periodId) => periodId;
const getLeaguePundits = state => state.league.leaguePundits;

export const selectMyScore = createSelector(
    [getMyScores, getSlug, getPeriod, getPeriodId],
    (scores, slug, period, periodId) =>
        scores.find(score => {
            if (score.slug !== slug) {
                return false;
            }
            if (score.period !== period) {
                return false;
            }
            return score.period === 'season' || score.periodId === periodId;
        })
);

export const selectResults = createSelector(
    [getResults, getSlug, getPeriod, getPeriodId],
    (results, slug, period, periodId) => {
        if (
            !results ||
            results.slug !== slug ||
            results.period !== period ||
            results.periodId !== periodId
        ) {
            return null;
        }
        return results.results;
    }
);

export const selectLeaguePundits = createSelector(
    [getLeaguePundits, getSlug],
    (pundits, slug) => {
        if (!pundits || pundits.slug !== slug) {
            return null;
        }

        return pundits.pundits;
    }
);
