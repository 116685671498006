import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Link = ({ href, children }) => (
    <Styled.Link href={href}>{children}</Styled.Link>
);

Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
};

export default Link;
