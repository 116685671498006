const sendSignalReport = (reportTitle, data) => {
    if (window && window.Taggert !== undefined) {
        window.Taggert.report(reportTitle, data);
    }
};

export const recordPageLoad = (customerId, title) => {
    sendSignalReport('pageLoad', {
        customerId,
        title
    });
};

export const recordLogin = customerId => {
    sendSignalReport('login', { customerId });
};

export const recordEnterRound = (round, customerId) => {
    sendSignalReport('enterRound', { round, customerId });
};

export const recordBetClick = (betType, customerId) => {
    sendSignalReport('betClick', {
        betType,
        customerId,
        page: window.location.pathname
    });
};
