import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    BarController,
    Legend,
    Tooltip
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import * as Styled from './styles';
import theme from '../../../../styles/themes/standard';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    BarController,
    Legend,
    Tooltip
);

ChartJS.defaults.color = theme.colours.blueZodiac;
ChartJS.defaults.borderColor = theme.colours.blueZodiac;
ChartJS.defaults.font.family = '"Sky Medium",Arial,sans-serif';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = 'bold';
ChartJS.defaults.font.style = 'italic';

ChartJS.defaults.animation.duration = 1;

ChartJS.defaults.datasets.line.borderWidth = 1;
ChartJS.defaults.datasets.line.pointStyle = 'circle';
ChartJS.defaults.datasets.line.pointRadius = 6;
ChartJS.defaults.datasets.line.pointBackgroundColor = theme.colours.blueZodiac;
ChartJS.defaults.datasets.line.pointBorderWidth = 4;
ChartJS.defaults.datasets.line.hoverRadius = 10;
ChartJS.defaults.datasets.line.hoverBackgroundColor = 'white';
ChartJS.defaults.datasets.line.hoverBorderWidth = 4;

ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.scale.title.display = true;
ChartJS.defaults.scale.ticks.padding = 8;

ChartJS.defaults.plugins.legend.display = false;

ChartJS.defaults.plugins.tooltip.backgroundColor = theme.colours.greenPrimary;
ChartJS.defaults.plugins.tooltip.displayColors = false;
ChartJS.defaults.plugins.tooltip.caretSize = 0;
ChartJS.defaults.plugins.tooltip.caretPadding = 15;
ChartJS.defaults.plugins.tooltip.cornerRadius = 0;

const Graph = ({ entryData }) => {
    const [isOpen, setIsOpen] = useState(false);

    if (entryData.length < 1) {
        return null;
    }

    const onClick = () => {
        setIsOpen(prevProp => !prevProp);
    };

    const setPointBorderColour = ctx => {
        const value = ctx.parsed.y;
        return value === 0 && entryData[ctx.dataIndex].didEnter === false
            ? theme.colours.redMedium
            : theme.colours.greenPrimary;
    };

    // set any nulls to 0, so they will display on the graph
    const numbers = entryData.map(entry => ({
        ...entry,
        totalPoints: entry.totalPoints == null ? 0 : entry.totalPoints
    }));

    // add an empty bar graph as a hack to force spacing from the Y axis
    const data = {
        datasets: [
            {
                data: numbers,
                parsing: {
                    xAxisKey: 'roundId',
                    yAxisKey: 'totalPoints'
                }
            },
            {
                type: 'bar',
                data: []
            }
        ]
    };

    const options = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => '',
                    label: dataPoint => `${dataPoint.parsed.y} POINTS`
                },
                bodyFont: { size: 9 },
                padding: { x: 10, y: 3 }
            }
        },
        elements: {
            point: {
                borderColor: setPointBorderColour
            }
        },
        scales: {
            x: {
                type: 'linear',
                title: { text: 'Round' },
                min: numbers[0].roundId,
                ticks: {
                    stepSize: 1
                }
            },
            y: {
                title: { text: 'Points' },
                suggestedMin: 0,
                suggestedMax: 30,
                ticks: {
                    stepSize: 5
                }
            }
        }
    };

    return (
        <Styled.Container>
            <Styled.OverlayContainer aria-hidden={!isOpen} isOpen={isOpen}>
                <Styled.KeyTitle>Key</Styled.KeyTitle>
                <Styled.KeyText>
                    <Styled.KeyTextItem>
                        <Styled.EnteredLabel>&nbsp;</Styled.EnteredLabel> Played
                        round
                    </Styled.KeyTextItem>
                    <Styled.KeyTextItem>
                        <Styled.NotEnteredLabel /> Did not play round
                    </Styled.KeyTextItem>
                </Styled.KeyText>
            </Styled.OverlayContainer>
            <Styled.OverlayButton onClick={onClick}>
                {isOpen ? (
                    <Styled.OverlayImage
                        aria-label="close"
                        src="/content/img/icons/close.svg"
                        height="20"
                        width="20"
                    />
                ) : (
                    <Styled.OverlayImage
                        aria-label="open"
                        src="/content/img/icons/info.svg"
                        height="20"
                        width="20"
                    />
                )}
            </Styled.OverlayButton>
            <Styled.Title>Last 5 Rounds Performance</Styled.Title>
            <Chart
                className="chromatic-ignore"
                type="line"
                options={options}
                data={data}
                width={286}
                height={314}
            />
        </Styled.Container>
    );
};

Graph.propTypes = {
    entryData: PropTypes.arrayOf(
        PropTypes.shape({
            roundId: PropTypes.number.isRequired,
            totalPoints: PropTypes.number,
            didEnter: PropTypes.bool.isRequired
        })
    ).isRequired
};

export default Graph;
