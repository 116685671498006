import styled from '@emotion/styled/macro';
import { ContentPageContainer } from '../../components/Common/Page/styles';
import StyledPageTitle from '../../components/Common/PageTitle/styles';

export const PrivacyPageContainer = styled(ContentPageContainer)`
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        padding-left: ${props => props.theme.spacing.normal};
        padding-right: ${props => props.theme.spacing.normal};
    }

    ${StyledPageTitle} {
        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            margin-left: -${props => props.theme.spacing.normal};
            margin-right: -${props => props.theme.spacing.normal};
        }
    }

    p {
        line-height: ${props => props.theme.lineHeight.normal};
        margin: 0 0 ${props => props.theme.spacing.medium};
    }
`;

export const Header = styled.h3`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.huge};
    padding-top: ${props => (props.nopad ? '0' : props.theme.spacing.medium)};
    margin-top: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.medium};
    font-weight: 500;
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const SectionHeader = styled.h4`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.xxlarge};
    padding-top: ${props => props.theme.spacing.medium};
    margin: ${props => props.theme.spacing.medium} 0;
    font-weight: 500;
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const SubSectionHeader = styled(SectionHeader)`
    font-size: ${props => props.theme.fontSize.xlarge};
`;

export const SubSectionSubHeader = styled(SectionHeader)`
    font-size: ${props => props.theme.fontSize.large};
`;

export const SubSectionSubSubHeader = styled(SectionHeader)`
    font-size: ${props => props.theme.fontSize.medium};
`;

export const ImportantKey = styled.span`
    color: red;
`;

export const Important = styled.p`
    padding-left: 20px;

    :before {
        color: red;
        content: '!';
        position: relative;
        float: left;
        font-size: ${props => props.theme.fontSize.xxlarge};
        margin-left: -20px;
    }
`;

export const ImportantList = styled.ul`
    padding-left: 20px;
    margin: 0;

    :before {
        color: red;
        content: '!';
        position: relative;
        float: left;
        font-size: ${props => props.theme.fontSize.xxlarge};
        margin-left: -20px;
    }
`;
