import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isDesktop } from 'react-device-detect';
import * as Styled from './styles';
import PlusIcon from '../../Common/Icons/Plus';

const FollowButton = ({
    userId,
    isFollowing,
    onUnfollow,
    onFollow,
    isMasked
}) => {
    const [mouseOver, setMouseOver] = useState(false);

    const onMouseToggle = (enter = false) => () =>
        isDesktop && setMouseOver(enter);

    if (isFollowing) {
        return (
            <Styled.FollowButton
                onClick={() => onUnfollow(userId)}
                title="Unfollow"
                isMasked={isMasked}
                onMouseEnter={onMouseToggle(true)}
                onMouseLeave={onMouseToggle(false)}
                data-test-id="unfollow-button"
            >
                <PlusIcon followed mouseOver={mouseOver} />
            </Styled.FollowButton>
        );
    }

    return (
        <Styled.FollowButton
            onClick={() => onFollow(userId)}
            title="Follow"
            isMasked={isMasked}
            onMouseEnter={onMouseToggle(true)}
            onMouseLeave={onMouseToggle(false)}
            data-test-id="follow-button"
        >
            <PlusIcon mouseOver={mouseOver} />
        </Styled.FollowButton>
    );
};

FollowButton.propTypes = {
    userId: PropTypes.number,
    isFollowing: PropTypes.bool.isRequired,
    onUnfollow: PropTypes.func.isRequired,
    onFollow: PropTypes.func.isRequired,
    isMasked: PropTypes.bool.isRequired
};

FollowButton.defaultProps = {
    userId: null
};

export default FollowButton;
