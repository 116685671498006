export const REQUEST_STATS_FOR_SELF = 'stats/REQUEST_STATS_FOR_SELF';
export const RECEIVE_STATS_FOR_SELF = 'stats/RECEIVE_STATS_FOR_SELF';
export const UPDATE_STATS_ENTERED_ROUND = 'stats/UPDATE_STATS_ENTERED_ROUND';
export const REQUEST_SEASON_STATS_FOR_SELF =
    'stats/REQUEST_SEASON_STATS_FOR_SELF';
export const RECEIVE_SEASON_STATS_FOR_SELF =
    'stats/RECEIVE_SEASON_STATS_FOR_SELF';
export const REQUEST_SEASON_HEAD_TO_HEAD_STATS =
    'stats/REQUEST_SEASON_HEAD_TO_HEAD_STATS';
export const RECEIVE_SEASON_HEAD_TO_HEAD_STATS =
    'stats/RECEIVE_SEASON_HEAD_TO_HEAD_STATS';
