import React from 'react';
import PropTypes from 'prop-types';
import HowToPlayMasthead from './components/Masthead/HowToPlayMasthead';
import { PageContainer } from '../../components/Common/Page/styles';
import * as Styled from './styles';

import HowToPlaySteps from './components/HowToPlay';
import { headToHeadRules } from './components/HowToPlay/h2hRules';

const HowToPlay = ({ headToHead: { opponent, prizes } }) => (
    <PageContainer padding="8px">
        <Styled.ReducedWidthDesktopContainer>
            <HowToPlayMasthead opponent={opponent} prizes={prizes} />
        </Styled.ReducedWidthDesktopContainer>
        <HowToPlaySteps steps={headToHeadRules.steps} />
    </PageContainer>
);

HowToPlay.propTypes = {
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }).isRequired
};

export default HowToPlay;
