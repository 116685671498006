import {
    put,
    takeEvery,
    takeLeading,
    call,
    select,
    delay
} from 'redux-saga/effects';
import * as actions from './actions';
import * as playActions from '../play/actions';
import { addErrorMessage, addSuccessMessage } from '../message/actions';
import * as types from './types';
import roundApi from '../../../api/round';
import * as roundSelectors from './selectors';
import { getFormattedLongDateTime } from '../../../helpers/dateFormatters';

export function* getActiveRound() {
    try {
        const currentActiveRound = yield select(
            roundSelectors.selectActiveRound
        );
        const data = yield call(roundApi.getActiveRound);
        if (
            currentActiveRound !== null &&
            data !== null &&
            (currentActiveRound.id !== data.id ||
                currentActiveRound.status !== data.status)
        ) {
            if (currentActiveRound.id !== data.id) {
                yield put(actions.activeRoundChange());
            } else if (currentActiveRound.status !== data.status) {
                yield put(actions.activeRoundStateChange());
            }
            let status;
            switch (data.status) {
                case 'complete':
                    status = 'is now complete';
                    break;
                case 'inplay':
                    status = 'is now in play';
                    break;
                case 'open':
                    status = 'is open for predictions';
                    break;
                case 'future':
                    status = `will open at ${getFormattedLongDateTime(
                        data.endDateTime
                    )}`;
                    break;
                default:
                    // Shouldn't ever happen
                    status = 'has been updated';
            }
            yield put(addSuccessMessage(`Round ${data.id} ${status}`));
        }
        yield put(playActions.receiveDefaultRound(data));
        yield put(actions.receiveActiveRound(data));
        yield delay(5000);
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving the round'));
    }
}

export function* getRounds() {
    try {
        const rounds = yield select(roundSelectors.getRounds);
        if (rounds && rounds.length > 0) {
            return;
        }
        yield put(actions.loadingRounds());
        const data = yield call(roundApi.getRounds);
        yield put(actions.receiveRounds(data));
        yield delay(5000);
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving the rounds'));
        yield put(actions.failedLoadingRounds());
    }
}

export function* getUpcomingRounds() {
    try {
        const upcomingRounds = yield select(
            roundSelectors.getUpcomingS6AndExtraRounds
        );
        if (upcomingRounds && upcomingRounds.length > 0) {
            return;
        }
        yield put(actions.loadingUpcomingRounds());
        const data = yield call(roundApi.getUpcomingRounds);
        yield put(actions.receiveUpcomingRounds(data));
        yield delay(5000);
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving the rounds'));
        yield put(actions.failedToLoadUpcomingRounds());
    }
}

export function* getMatchStats(action) {
    const { matchId } = action;

    try {
        const existingData = yield select(
            roundSelectors.getStatsForMatch,
            matchId
        );
        if (existingData && (existingData.data || existingData.loading)) {
            return;
        }
        yield put(actions.setMatchStatsLoading(matchId));
        const data = yield call(roundApi.getMatchStats, matchId);
        yield put(actions.receiveMatchStats(matchId, data));
    } catch (err) {
        yield put(actions.requestMatchStatsFailed(matchId));
    }
}

export function* getMatchPundits(action) {
    const { matchId } = action;

    try {
        const existingData = yield select(
            roundSelectors.getPunditsForMatch,
            matchId
        );
        if (existingData && (existingData.data || existingData.loading)) {
            return;
        }
        yield put(actions.setMatchPunditsLoading(matchId));
        const data = yield call(roundApi.getMatchPundits, matchId);
        yield put(actions.receiveMatchPundits(matchId, data));
    } catch (err) {
        yield put(actions.requestMatchPunditsFailed(matchId));
    }
}

export function* getMatchBets(action) {
    const { matchId } = action;

    try {
        const existingData = yield select(roundSelectors.getBetsForMatch, {
            matchId
        });
        if (existingData && (existingData.data || existingData.loading)) {
            return;
        }
        yield put(actions.setMatchBetsLoading(matchId));
        const data = yield call(roundApi.getMatchBets, matchId);
        yield put(actions.receiveMatchBets(matchId, data));
    } catch (err) {
        yield put(actions.requestMatchBetsFailed(matchId));
    }
}

export function* getPunditPredictions(action) {
    const { roundId } = action;

    try {
        const existingData = yield select(
            roundSelectors.selectPunditPredictions
        );
        if (
            existingData &&
            (existingData.isLoading || existingData.predictions)
        ) {
            return;
        }
        yield put(actions.setPunditPredictionsLoading(roundId));
        const data = yield call(roundApi.getPunditPredictions, roundId);
        yield put(actions.receivePunditPredictions(roundId, data));
    } catch (err) {
        yield put(actions.failedToLoadPunditPredictions(roundId));
        yield put(
            addErrorMessage('There was a problem retrieving pundit predictions')
        );
    }
}

export function* getOutcomesForMarket(action) {
    const { market } = action;

    try {
        yield put(actions.startLoadingMarket(market));
        const data = yield call(roundApi.getOutcomesForMarket, market);
        yield put(actions.receiveOutcomesForMarket(market, data));
        yield put(actions.hasLoadedMarket(market));
    } catch (err) {
        yield put(actions.receiveOutcomesForMarket(market, {}));
        yield put(actions.hasLoadedMarket(market));
    }
}

export function* getHeadToHead(action) {
    const { roundId } = action;

    if (!roundId) {
        return;
    }

    try {
        yield put(actions.loadedHeadToHead(roundId));
        const data = yield call(roundApi.getHeadToHead, roundId);
        yield put(actions.receiveHeadToHead(roundId, data));
    } catch (err) {
        yield put(
            addErrorMessage(
                'There was a problem retrieving the head to head config'
            )
        );
        yield put(actions.failedloadedHeadToHead(roundId));
    }
}

export function* getLiveOdds() {
    try {
        const data = yield call(roundApi.getLiveOdds);
        yield put(actions.receiveLiveOdds(data));
    } catch (err) {
        // We might get a 404 back from the API, in which case empty the liveOdds object to hide them on site
        yield put(actions.receiveLiveOdds(null));
    }
}

export function* watchGetActiveRound() {
    yield takeLeading(types.REQUEST_ACTIVE_ROUND, getActiveRound);
}

export function* watchGetRounds() {
    yield takeLeading(types.REQUEST_ROUNDS, getRounds);
}

export function* watchGetMatchStats() {
    yield takeEvery(types.REQUEST_MATCH_STATS, getMatchStats);
}

export function* watchGetMatchPundits() {
    yield takeEvery(types.REQUEST_MATCH_PUNDITS, getMatchPundits);
}

export function* watchGetMatchBets() {
    yield takeEvery(types.REQUEST_MATCH_BETS, getMatchBets);
}

export function* watchRequestPunditPredictions() {
    yield takeLeading(types.REQUEST_PUNDIT_PREDICTIONS, getPunditPredictions);
}

export function* watchRequestOutcomesForMarket() {
    yield takeEvery(types.REQUEST_OUTCOMES_FOR_MARKET, getOutcomesForMarket);
}

export function* watchRequestHeadToHead() {
    yield takeLeading(types.REQUEST_HEAD_TO_HEAD, getHeadToHead);
}

export function* watchRequestLiveOdds() {
    yield takeLeading(types.REQUEST_LIVE_ODDS, getLiveOdds);
}

export function* watchRequestUpcomingRounds() {
    yield takeLeading(types.REQUEST_UPCOMING_ROUNDS, getUpcomingRounds);
}

export const sagas = [
    watchGetActiveRound,
    watchGetRounds,
    watchGetMatchStats,
    watchGetMatchPundits,
    watchGetMatchBets,
    watchRequestPunditPredictions,
    watchRequestOutcomesForMarket,
    watchRequestHeadToHead,
    watchRequestLiveOdds,
    watchRequestUpcomingRounds
];
