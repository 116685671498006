import React from 'react';
import PropTypes from 'prop-types';
import StatBox from '../../../../components/StatBox';
import * as Styled from './styles';

const getStatBox = (title, value) => (
    <Styled.StatBox>
        <StatBox title={title} value={value} />
    </Styled.StatBox>
);

// const getPerctangeStr = str => (str ? `${str}%` : '-');

const StatBoxGroup = ({
    // eslint-disable-next-line
    seasonStats,
    selfStats
}) => (
    <Styled.StatBoxGroupContainer>
        <Styled.StatBoxGroupTopRow>
            {getStatBox('Current Streak', selfStats.currentStreak)}
            {getStatBox(
                'Total Streak Points',
                selfStats.currentStreakAccumulatedPoints
            )}
            {getStatBox('Average Points P/Rnd', selfStats.averagePoints)}
        </Styled.StatBoxGroupTopRow>
        <Styled.StatBoxGroupRow>
            {getStatBox('Total Rounds Entered', selfStats.roundsEntered)}
            {getStatBox('Longest Streak', selfStats.bestStreak)}
            {/* {getStatBox(
                'Leaderboard Pos Top %',
                getPerctangeStr(seasonStats.currentTopPercentage)
            )} */}
        </Styled.StatBoxGroupRow>
    </Styled.StatBoxGroupContainer>
);

StatBoxGroup.propTypes = {
    selfStats: PropTypes.shape({
        currentStreak: PropTypes.number,
        currentStreakAccumulatedPoints: PropTypes.number,
        averagePoints: PropTypes.number,
        roundsEntered: PropTypes.number,
        bestStreak: PropTypes.number
    }).isRequired,
    seasonStats: PropTypes.shape({
        currentTopPercentage: PropTypes.number
    })
};

StatBoxGroup.defaultProps = {
    seasonStats: null
};

export default StatBoxGroup;
