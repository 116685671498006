import styled from '@emotion/styled/macro';
import { Icon } from '../../../../components/Common/Page/styles';
import Image from '../../../../components/Common/Image';

export const Container = styled.div`
    flex: 1 1 auto;
    text-align: left;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    border-top-left-radius: ${props => props.theme.radius.normal};
    border-top-right-radius: ${props => props.theme.radius.normal};
    background: linear-gradient(
        to bottom,
        ${props => props.theme.colours.header.gradient.from},
        ${props => props.theme.colours.header.gradient.to}
    );
    padding: ${props => props.theme.spacing.vlarge} 0
        ${props => props.theme.spacing.large};
`;

export const Title = styled.h1`
    flex: 0 0 auto;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colours.black};
    font-family: ${props => props.theme.fonts.regular};
    line-height: 15px;
    border-top-left-radius: ${props => props.theme.radius.large};
    border-top-right-radius: ${props => props.theme.radius.large};
    letter-spacing: 0.08em;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
    }
`;

export const HeaderLogo = styled(Image)`
    flex: 0 0 auto;
    height: 16px;
    margin: 0 ${props => props.theme.spacing.small};
`;

export const Body = styled.div`
    display: block;
    background: ${props => props.theme.colours.white};
    box-sizing: border-box;
    border-bottom-left-radius: ${props => props.theme.radius.normal};
    border-bottom-right-radius: ${props => props.theme.radius.normal};

    padding: 0 ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.xlarge};
`;

export const Content = styled.div`
    display: block;
    padding: 0 ${props => props.theme.spacing.normal};
    background: ${props => props.theme.colours.white};
    box-sizing: border-box;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        padding: 0 ${props => props.theme.spacing.small};
    }
`;

export const TabContainer = styled.div`
    border-bottom: 1px solid ${props => props.theme.colours.accaBorder};
    display: flex;
    justify-content: center;

    ul {
        padding-bottom: ${props => props.theme.spacing.medium};
    }
`;

export const AccaContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    padding: ${props => props.theme.spacing.medium};
    box-sizing: border-box;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        padding: 0;
    }
`;

export const PredictionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 0 0 auto;

    width: 48%;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: 100%;
    }
`;

export const MarketsContainer = styled.div`
    display: block;
    flex: 0 0 auto;
    margin-bottom: ${props => props.theme.spacing.large};
    padding: ${props => props.theme.spacing.medium};
    width: 48%;
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: 100%;
    }
`;

export const PredictionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: ${props => props.theme.fontSize.normal};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
    }

    border: 1px solid #b3bfd3;
    background-color: #dce3ed;
`;

export const TeamColumnHeader = styled.div`
    flex: 0 0 auto;
    width: 33%;
`;

export const OutcomeColumnsHeader = styled.div`
    flex: 1 1 auto;

    display: flex;
    flex-direction: row;
`;

export const OutcomeColumnHeader = styled.div`
    flex: 1 1 auto;
`;

export const TeamColumn = styled.div`
    flex: 0 0 auto;
    background: #0d2051;
    width: 33%;
    padding: ${props => props.theme.spacing.normal};
    box-sizing: border-box;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: block;
        overflow: hidden;
        line-height: 30px;
        color: #ffffff;
        text-align: ${props => (props.right ? 'right' : 'left')};
        font-family: ${props => props.theme.fonts.medium};
        font-size: ${props => props.theme.fontSize.normal};

        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            font-size: ${props => props.theme.fontSize.small};
        }

        img {
            margin: 0 ${props => props.theme.spacing.normal};
            vertical-align: middle;
            height: 24px;
        }
    }
`;

export const MatchRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 80px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
`;

export const OutcomeColumns = styled.div`
    flex: 1 1 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: ${props => props.theme.colours.white};
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing.normal};
`;

export const OutcomeColumn = styled.div`
    flex: 1 1 0;
    padding: 0 ${props => props.theme.spacing.normal};
`;

const GenericOutcomeButton = styled.button`
    display: block;
    width: 100%;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #b3bfd3;
    boz-sizing: border-box;

    &:focus {
        outline: none;
    }
`;

export const OutcomeButton = styled(GenericOutcomeButton)`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.normal};
    background: ${props => props.theme.colours.white};
    color: #d92231;

    ${Icon} {
        display: none;
    }

    &:hover {
        cursor: pointer;
        background: #e0e8f3;
    }
`;

export const SelectedOutcomeButton = styled(GenericOutcomeButton)`
    background: #e0e8f3;
    color: #5895e2;

    ${Icon} {
        display: none;

        :first-of-type {
            display: inline;
        }
    }

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colours.white};
    }
`;

export const AccumulatorTitle = styled.p`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colours.black};
    line-height: 2;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
    }
`;

export const AccumulatorReturn = styled.div`
    margin-bottom: ${props => props.theme.spacing.xlarge};
    font-size: ${props => props.theme.fontSize.medium};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.xsmall};
    }

    input {
        width: 82px;
        height: 40px;
        padding: ${props => props.theme.spacing.normal};
        margin: 0 ${props => props.theme.spacing.normal};
        font-size: ${props => props.theme.fontSize.large};
        border-radius: 3px;
        border: solid 1px #d8d8d8;
        background-color: #fcfdfe;
        box-sizing: border-box;

        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            width: 60px;
            height: 30px;
            padding: ${props => props.theme.spacing.small};
            margin: 0 ${props => props.theme.spacing.small};
            font-size: ${props => props.theme.fontSize.small};
            border-radius: 2px;
        }
    }

    strong {
        font-weight: normal;
        font-family: ${props => props.theme.fonts.medium};
    }
`;

export const CalculationText = styled.span`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.xlarge};
    color: #d2000d;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
    }
`;

export const OfferContainer = styled.div`
    margin-top: ${props => props.theme.spacing.large};
`;

export const BetButtonContainer = styled.div`
    padding: ${props => props.theme.spacing.normal};
`;
