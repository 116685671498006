import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeChange as routeChangeAction } from '../../store/modules/message/actions';
import setTitle from '../../helpers/page';
import { dismissModal as dismissModalAction } from '../../store/modules/modal/actions';
import {
    requestFeatureSwitches,
    updateBackButton as updateBackButtonAction
} from '../../store/modules/content/actions';

const RouteChange = ({
    match,
    dismissModal,
    routeChange,
    requestFeatures,
    title,
    updateBackButton
}) => {
    const { path, url } = match;

    const paths = ['/headtohead/:section/:roundId?'];

    useEffect(() => {
        window.scrollTo(0, 0);
        if (title) {
            setTitle(title);
        }
        if (
            title === 'League' ||
            title === 'Leaderboard' ||
            title === 'Results and Schedule' ||
            paths.indexOf(path) !== -1
        ) {
            updateBackButton(url);
        } else if (title !== 'Previous Results') {
            updateBackButton(null);
        }
        return () => {
            dismissModal();
            routeChange();
            requestFeatures();
        };
    }, [path, routeChange, dismissModal, title, requestFeatures]);

    return null;
};

const mapDispatchToProps = dispatch => ({
    dismissModal: () => dispatch(dismissModalAction()),
    routeChange: () => dispatch(routeChangeAction()),
    requestFeatures: () => dispatch(requestFeatureSwitches()),
    updateBackButton: location => dispatch(updateBackButtonAction(location))
});

RouteChange.propTypes = {
    dismissModal: PropTypes.func.isRequired,
    routeChange: PropTypes.func.isRequired,
    requestFeatures: PropTypes.func.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string,
    updateBackButton: PropTypes.func.isRequired
};

RouteChange.defaultProps = {
    title: ''
};

export default connect(null, mapDispatchToProps)(withRouter(RouteChange));
