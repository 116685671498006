import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Common/Spinner';

const Defer = ({ children }) => {
    const [inView, setInView] = useState(false);
    const ref = useRef(null);

    const checkInView = () => {
        if (!ref || !ref.current) {
            return;
        }
        let el = ref.current;

        let top = el.offsetTop;
        let left = el.offsetLeft;
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            top + height <= window.pageYOffset + window.innerHeight &&
            left + width <= window.pageXOffset + window.innerWidth
        ) {
            setInView(true);
        }
    };

    useEffect(() => {
        checkInView();
        window.addEventListener('scroll', checkInView);
        window.addEventListener('resize', checkInView);
        return () => {
            window.removeEventListener('scroll', checkInView);
            window.removeEventListener('resize', checkInView);
        };
    }, []);

    if (!inView) {
        return (
            <div ref={ref}>
                <Spinner />
            </div>
        );
    }

    return children;
};

Defer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired
};

export default Defer;
