import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Spinner = ({ colour }) => (
    <Styled.SpinnerIcon colour={colour} data-test-id="spinner" />
);

Spinner.defaultProps = {
    colour: null
};

Spinner.propTypes = {
    colour: PropTypes.string
};

export default Spinner;
