import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import XSELL_LINKS from './config';
import AppLink from '../Common/AppLink';
import { useCookieConsentContext } from '../../hooks/useCookieConsents';
import buildCookieUrl from '../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../config';
import modules from '../../helpers/GoogleAnalytics/modules';
import eventContext from '../../helpers/GoogleAnalytics/event_context';
import { sendNavigationEvent } from '../../helpers/GoogleAnalytics/gaHelper';

export const XSell = ({ canBet }) => {
    const links = XSELL_LINKS.filter(
        item => (!item.isBet || canBet) && !item.hidden
    ).sort((a, b) => {
        if (!canBet) {
            return a.cantBetOrdinal - b.cantBetOrdinal;
        }
        return 0;
    });

    const getTargetId = alt =>
        `s6-xsell-${alt.toLowerCase().replace(/\s/g, '-')}-link`;

    const { cookieConsentQuery } = useCookieConsentContext();

    const setNavigationEvent = (text, position, destinationURL, context) => {
        sendNavigationEvent(
            modules.homepage.crossSell,
            'null',
            text,
            position,
            destinationURL,
            context
        );
    };

    return (
        <Styled.XSellContainer>
            <Styled.ProductList>
                {links.map((item, index) => (
                    <Styled.ProductLink
                        key={item.alt}
                        id={item.alt}
                        large={item.large ? 1 : 0}
                    >
                        <AppLink
                            app={item.name}
                            link={buildCookieUrl(
                                OAUTH_URI,
                                item.link,
                                cookieConsentQuery
                            )}
                            targetId={getTargetId(item.alt)}
                            testId={getTargetId(item.alt)}
                        >
                            <Styled.ProductImage
                                src={`/img/products/${item.image}`}
                                alt={item.alt}
                                large={item.large ? 1 : 0}
                                border={item.border ? 1 : 0}
                                onClick={() =>
                                    setNavigationEvent(
                                        item.alt,
                                        index + 1,
                                        item.link,
                                        eventContext.crossSell
                                    )
                                }
                            />
                        </AppLink>
                    </Styled.ProductLink>
                ))}
            </Styled.ProductList>
        </Styled.XSellContainer>
    );
};

XSell.propTypes = {
    canBet: PropTypes.bool
};

XSell.defaultProps = {
    canBet: true
};

const mapStateToProps = state => ({
    canBet: !!state.user.info.canBet
});

export default connect(mapStateToProps)(XSell);
