import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const PrizeWinnerItem = ({ link, text, points, isUser, isPending }) => (
    <Styled.PrizeWinnerListItem
        to={link}
        isUser={isUser}
        data-test-id="prize-winner-list-item"
    >
        {isPending ? <Styled.PendingSpace /> : <Styled.WinnerListAvatar />}
        <Styled.WinnerListText data-test-id="prize-winner-list-item-text">
            {text}
        </Styled.WinnerListText>
        <Styled.Points>Points</Styled.Points>
        <Styled.WinnerListPoints
            isUser={isUser}
            data-test-id="prize-winner-list-item-points"
        >
            <span>{points}</span>
        </Styled.WinnerListPoints>
    </Styled.PrizeWinnerListItem>
);

PrizeWinnerItem.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    points: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    isUser: PropTypes.bool,
    isPending: PropTypes.bool
};

PrizeWinnerItem.defaultProps = {
    isUser: false,
    isPending: false
};

export default PrizeWinnerItem;
