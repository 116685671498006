import styled from '@emotion/styled/macro';

export const Grid = styled.div`
    display: grid;
    gap: ${props => props.theme.spacing.vlarge};

    grid-template-columns: 1fr;

    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        grid-template-columns: ${props =>
            props.numPundits === 1 ? 'minmax(100px, 500px) 1fr' : '1fr'};
    }

    margin-bottom: ${props => props.theme.spacing.vlarge};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${props => props.theme.spacing.large};

    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        justify-content: start;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;

    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        width: inherit;
    }
`;
