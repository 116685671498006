import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import { selectIsRoundInplayById } from '../../../../store/modules/round/selectors';
import { requestRounds } from '../../../../store/modules/round/actions';
import PrizeWinnerItem from './PrizeWinnerItem';
import { reorderWinners } from '../../../../helpers/headToHead';

const PrizeWinners = ({
    loadRounds,
    headToHead,
    userId,
    roundId,
    isInPlay
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    const userLink = isInPlay ? '/inplay' : `/results/round/${roundId}`;
    const linkPrefix = isInPlay
        ? '/inplay/user/'
        : `/results/round/${roundId}/user/`;

    const winnersArr = reorderWinners([...headToHead.winners], userId);

    const defaultPrizes =
        headToHead.prizes.quantity > 10 ? 10 : headToHead.prizes.quantity;

    return (
        <Styled.PrizeWinnerListContainer data-test-id="prize-winners">
            <Styled.PrizeWinnerListTitle>
                PRIZE DRAW WINNERS
            </Styled.PrizeWinnerListTitle>
            <Styled.PrizeWinnerList>
                <Styled.PrizeWinnerListInnerContainer data-test-id="prize-winner-list">
                    {isInPlay || !winnersArr.length
                        ? [...Array(defaultPrizes)].map((_, i) => (
                              <PrizeWinnerItem
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`PrizeWinnerItem-${i}`}
                                  link={userLink}
                                  text="Pending"
                                  points="-"
                                  isPending
                              />
                          ))
                        : winnersArr.map(winner => (
                              <PrizeWinnerItem
                                  key={winner.userId}
                                  link={
                                      winner.userId === userId
                                          ? userLink
                                          : `${linkPrefix}${winner.userId}`
                                  }
                                  text={
                                      winner.userId === userId
                                          ? 'You'
                                          : `${winner.firstName} ${winner.lastName}`
                                  }
                                  points={winner.points}
                                  isUser={winner.userId === userId}
                              />
                          ))}
                </Styled.PrizeWinnerListInnerContainer>
            </Styled.PrizeWinnerList>
        </Styled.PrizeWinnerListContainer>
    );
};

PrizeWinners.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    isInPlay: PropTypes.bool.isRequired,
    roundId: PropTypes.number.isRequired,
    userId: PropTypes.number,
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool.isRequired,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }).isRequired
};
PrizeWinners.defaultProps = {
    userId: null
};
const mapStateToProps = (state, props) => ({
    isInPlay: selectIsRoundInplayById(state, props.roundId)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrizeWinners);
