import styled from '@emotion/styled/macro';

export const MatchHeader = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.skySportsPL};
    font-weight: 700;
    height: 60px;
    padding: 0 ${props => props.theme.spacing.vlarge};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        line-height: 26px;
        font-size: ${props => props.theme.fontSize.medium};
    }

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.xsmall};
        padding: 0 ${props => props.theme.spacing.medium};
    }
`;

export const MatchCompetitionName = styled.div`
    text-align: start;
    align-self: center;
    margin-right: ${props => props.theme.spacing.medium};
    margin-left: ${props => props.theme.spacing.large};
    width: 100px;
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.skySportsPL};
    ${props =>
        props.competitionName !== 'Premier League' &&
        `
            font-family: ${props.theme.fonts.skySportsF};
            font-weight: 500
        `};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
        margin-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.xsmall};
        line-height: ${props => props.theme.spacing.large};
    }
`;

export const MatchKickoffDateTime = styled.div`
    display: flex;
    flex-shrink: 0;
`;

export const MatchKickoffDate = styled.div`
    background-color: ${props =>
        props.theme.match?.colourIndex?.[props.matchIndex]?.background ??
        props.theme.colours.white};
    color: ${props =>
        props.theme.match?.colourIndex?.[props.matchIndex]?.text ??
        props.theme.colours.black};
    padding: ${props =>
        `${props.theme.spacing.medium} ${props.theme.spacing.large}`};
    margin: auto 0;
    transform: rotate(-4.36deg);
    flex-shrink: 0;
    font-size: ${props => props.theme.fontSize.xsmall};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props =>
            `${props.theme.spacing.xsmall} ${props.theme.spacing.normal}`};
    }
`;

export const MatchKickoffTime = styled.div`
    background-color: ${props => props.theme.colours.bluePrimary};
    color: ${props => props.theme.colours.white};
    padding: ${props =>
        `${props.theme.spacing.medium} ${props.theme.spacing.large}`};
    margin: auto 0 0 -7px;
    max-height: 52px;
    transform: rotate(-4.36deg);
    font-size: ${props => props.theme.fontSize.xsmall};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props =>
            `${props.theme.spacing.xsmall} ${props.theme.spacing.normal}`};
    }
`;
