import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    selectNonOpenOrFutureRoundIds,
    selectNonOpenOrFutureRoundMonths,
    selectLatestNonOpenOrFutureMonthId,
    selectLatestNonOpenOrFutureRoundId
} from '../../../store/modules/round/selectors';
import setTitle from '../../../helpers/page';
import Leaderboard from './Leaderboard';
import Spinner from '../../../components/Common/Spinner';
import { requestRounds } from '../../../store/modules/round/actions';

const LeaderboardRedirectWrapper = ({
    loadRounds,
    period,
    periodId,
    league,
    activeRounds,
    activeMonths,
    lastCompleteRoundId,
    lastCompleteMonthId,
    history
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    if (!activeRounds || !activeMonths) {
        return <Spinner />;
    }

    const periodIdInt = periodId ? parseInt(periodId, 10) : null;

    if (
        period === 'round' &&
        (!periodId || !activeRounds.includes(periodIdInt))
    ) {
        if (lastCompleteRoundId) {
            return (
                <Redirect
                    to={`/league/${league.slug}/round/${lastCompleteRoundId}`}
                />
            );
        }

        return <Redirect to={`/league/${league.slug}/season`} />;
    }

    if (
        period === 'month' &&
        (!periodId || !activeMonths.includes(periodIdInt))
    ) {
        if (lastCompleteMonthId) {
            return (
                <Redirect
                    to={`/league/${league.slug}/month/${lastCompleteMonthId}`}
                />
            );
        }

        return <Redirect to={`/league/${league.slug}/season`} />;
    }

    setTitle(league.name);

    return (
        <Leaderboard
            league={league}
            period={period}
            periodId={periodId}
            history={history}
        />
    );
};

const mapStateToProps = state => ({
    lastCompleteMonthId: selectLatestNonOpenOrFutureMonthId(state),
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state),
    activeMonths: selectNonOpenOrFutureRoundMonths(state),
    activeRounds: selectNonOpenOrFutureRoundIds(state)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

LeaderboardRedirectWrapper.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    activeRounds: PropTypes.arrayOf(PropTypes.number),
    activeMonths: PropTypes.arrayOf(PropTypes.number),
    lastCompleteRoundId: PropTypes.number,
    lastCompleteMonthId: PropTypes.number,
    league: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        isFound: PropTypes.bool.isRequired,
        name: PropTypes.string,
        memberCount: PropTypes.number,
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        isChairperson: PropTypes.bool
    }).isRequired,
    period: PropTypes.string,
    periodId: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

LeaderboardRedirectWrapper.defaultProps = {
    activeRounds: null,
    activeMonths: null,
    lastCompleteRoundId: null,
    lastCompleteMonthId: null,
    period: null,
    periodId: null
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardRedirectWrapper);
