import axios from 'axios';
import UserFriendlyError from '../errors/UserFriendlyError';
import { API_ROOT } from '../config';
import {
    ALREADY_LEAGUE_MEMBER_ERROR,
    INVALID_LEAGUE_PIN_ERROR,
    MAXIMUM_LEAGUE_LIMIT_ERROR
} from '../errors/messages';
import { getUserSessionId } from '../helpers/sessionStorage';

const commonHeaders = {
    Accept: 'application/json'
};

const leagueApi = {
    getLeagues: async token => {
        const { data, status } = await axios.get(`${API_ROOT()}/league`, {
            headers: {
                Authorization: token,
                'X-Session-Id': getUserSessionId(),
                ...commonHeaders
            }
        });

        if (status !== 200) {
            throw new Error('Unable to get leagues');
        }
        return data;
    },
    getLeague: async (slug, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.get(
            `${API_ROOT()}/league/${encodedSlug}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get league');
        }
        return data;
    },
    getLeaguePundits: async (slug, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.get(
            `${API_ROOT()}/league/${encodedSlug}/pundit`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get league pundits');
        }
        return data;
    },
    getMyScore: async (slug, period, id, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const idText = id ? `&id=${id}` : '';
        const { data, status } = await axios.get(
            `${API_ROOT()}/score/league/${encodedSlug}/user/self?period=${period}${idText}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get my score');
        }
        return data;
    },
    getResults: async (slug, period, id, page, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const idText = id ? `&id=${id}` : '';
        const { data, status } = await axios.get(
            `${API_ROOT()}/score/league/${encodedSlug}?period=${period}${idText}&page=${page}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get results');
        }
        return data;
    },
    getLeaderboard: async (slug, page, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.get(
            `${API_ROOT()}/score/league/${encodedSlug}/leaderboard?page=${page}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get results');
        }
        return data;
    },
    deleteLeague: async (slug, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { status } = await axios.delete(
            `${API_ROOT()}/league/${encodedSlug}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to delete league');
        }
    },
    leaveLeague: async (slug, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { status } = await axios.delete(
            `${API_ROOT()}/league/${encodedSlug}/member`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to leave league');
        }
    },
    removeMember: async (slug, userId, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { status } = await axios.delete(
            `${API_ROOT()}/league/${encodedSlug}/member/${userId}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to remove member');
        }
    },
    createLeague: async (name, pundits, token) => {
        const { data, status } = await axios.post(
            `${API_ROOT()}/league`,
            {
                name,
                pundits
            },
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    'Content-Type': 'application/json',
                    ...commonHeaders
                }
            }
        );

        if (status === 400) {
            throw UserFriendlyError(data.reason);
        }

        if (status !== 201) {
            throw new Error('Unable to create league');
        }
        return data;
    },
    restartLeague: async (slug, name, pundits, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.post(
            `${API_ROOT()}/league/${encodedSlug}/restart`,
            {
                name,
                pundits
            },
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    'Content-Type': 'application/json',
                    ...commonHeaders
                }
            }
        );

        if (status === 400) {
            throw UserFriendlyError(data.reason);
        }

        if (status !== 200) {
            throw new Error('Unable to restart league');
        }
        return data;
    },
    updateLeague: async (slug, name, pundits, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.put(
            `${API_ROOT()}/league/${encodedSlug}`,
            {
                name,
                pundits
            },
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    'Content-Type': 'application/json',
                    ...commonHeaders
                }
            }
        );

        if (status === 400) {
            throw UserFriendlyError(data.reason);
        }

        if (status !== 200) {
            throw new Error('Unable to update league');
        }
        return data;
    },
    joinLeague: async (slug, token) => {
        const encodedSlug = encodeURIComponent(slug);
        const { data, status } = await axios.post(
            `${API_ROOT()}/league/${encodedSlug}/member`,
            {},
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );
        if (status === 400) {
            throw UserFriendlyError(MAXIMUM_LEAGUE_LIMIT_ERROR);
        }

        if (status === 409) {
            throw UserFriendlyError(ALREADY_LEAGUE_MEMBER_ERROR);
        }

        if (status === 404) {
            throw UserFriendlyError(INVALID_LEAGUE_PIN_ERROR);
        }

        if (status !== 201) {
            throw new Error('Unable to join league');
        }
        return data;
    },
    getPundits: async () => {
        const { data, status } = await axios.get(`${API_ROOT()}/pundit`, {
            headers: commonHeaders
        });

        if (status !== 200) {
            throw new Error('Unable to get pundits');
        }
        return data;
    }
};

export default leagueApi;
