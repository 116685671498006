import * as types from './types';

export const initAuth = () => ({
    type: types.INITIALISE_AUTH
});

export const authInitialised = () => ({
    type: types.AUTH_INITIALISED
});

export const checkAuth = (forceLoggedIn = false) => ({
    type: types.CHECK_AUTH,
    forceLoggedIn
});

export const requestLogin = () => ({
    type: types.REQUEST_LOGIN
});

export const logout = (shouldRedirect = true, shouldSoftLogout = true) => ({
    type: types.LOGOUT,
    shouldRedirect,
    shouldSoftLogout
});

export const checkedAuth = () => ({
    type: types.CHECKED_AUTH
});

export const requestOauthTokens = (code, state) => ({
    type: types.REQUEST_OAUTH_TOKENS,
    code,
    state
});

export const receiveOauthTokens = idToken => ({
    type: types.RECEIVE_OAUTH_TOKENS,
    idToken
});

export const receiveSsoToken = idToken => ({
    type: types.RECEIVE_SSO_TOKEN,
    idToken
});

export const checkOauthTokens = (token, idToken) => ({
    type: types.CHECK_OAUTH_TOKENS,
    token,
    idToken
});

export const checkSsoToken = (token, shouldRedirect = false) => ({
    type: types.CHECK_SSO_TOKEN,
    token,
    shouldRedirect
});

export const requestJwt = () => ({
    type: types.REQUEST_JWT
});

export const receiveJwt = jwt => ({
    type: types.RECEIVE_JWT,
    jwt
});

export const updateIdToken = idToken => ({
    type: types.UPDATE_ID_TOKEN,
    idToken
});

export const requestAndroidOauthTokens = () => ({
    type: types.REQUEST_ANDROID_OAUTH_TOKENS
});
