import * as types from './types';

export const createFollowee = userId => ({
    type: types.CREATE_FOLLOWEE,
    userId
});

export const deleteFollowee = userId => ({
    type: types.DELETE_FOLLOWEE,
    userId
});

export const requestFollowees = () => ({
    type: types.REQUEST_FOLLOWEES
});

export const receiveFollowees = payload => ({
    type: types.RECEIVE_FOLLOWEES,
    payload
});

export const refreshFollowees = () => ({
    type: types.REFRESH_FOLLOWEES
});
