import Events from './events';

export default class BannerEvent extends Events {
    constructor(
        elementText = 'null',
        module = 'null',
        action = 'clicked - banner',
        position = 'null',
        destinationUrl = 'null'
    ) {
        super(elementText, module, action);
        this.position = position.toString();
        this.destination_url = destinationUrl.substring(0, 90); // Firebase has a limit of 100 characters per event parameter value
    }
}
