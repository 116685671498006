import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const MatchHeader = ({
    competitionName,
    kickOffDate,
    kickOffTime,
    matchIndex
}) => (
    <Styled.MatchHeader>
        <Styled.MatchCompetitionName competitionName={competitionName}>
            {competitionName}
        </Styled.MatchCompetitionName>
        <Styled.MatchKickoffDateTime>
            <Styled.MatchKickoffDate matchIndex={matchIndex}>
                {kickOffDate}
            </Styled.MatchKickoffDate>
            <Styled.MatchKickoffTime>{kickOffTime}</Styled.MatchKickoffTime>
        </Styled.MatchKickoffDateTime>
    </Styled.MatchHeader>
);

MatchHeader.propTypes = {
    competitionName: PropTypes.string.isRequired,
    kickOffDate: PropTypes.string.isRequired,
    kickOffTime: PropTypes.string.isRequired,
    matchIndex: PropTypes.number
};

MatchHeader.defaultProps = {
    matchIndex: 0
};

export default MatchHeader;
