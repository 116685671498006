import styled from '@emotion/styled/macro';

export const ShareContainer = styled.div``;

export const IconRow = styled.div`
    max-width: 662px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
`;

export const Icon = styled.a`
    width: ${props => (props.small ? '24px' : '48px')};
    height: ${props => (props.small ? '24px' : '48px')};
    display: flex;
    border-radius: 4px;
    text-decoration: none;
    background: ${props => props.theme.colours[props.colour]};
    img {
        width: ${props => (props.small ? '60%' : '40%')};
        margin: auto;
    }
    p {
        margin: auto;
        padding-bottom: ${props => props.displace}%;
    }
`;

export const Character = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &::before {
        font-family: skycons;
        font-size: 40px;
        content: '${props => props.icon}';
        cursor: pointer;
        color: ${props => props.theme.colours.bluePrimary};
        margin-top: -6px;

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            display: flex;
            font-size: 34px;
            align-items: center;
            margin-top: -6px;
        }
    }
`;

export const ButtonPadding = styled.div`
    padding: ${props => (props.small ? '0' : '15px')} 0px;
`;

export const HiddenInput = styled.input`
    position = 'fixed';
    top = 0;
    left = 0;
    height: 1px;
    width: 1px;
    border: 0px;
    padding: 0px;
    background: transparent;
    color: transparent;
    border: none;
`;

export const CopyLinkButton = styled.button`
    text-transform: uppercase;
    text-decoration: underline;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: ${props => props.theme.colours[props.colour || 'white']};
    cursor: pointer;
    font-family: 'Sky Medium';
    font-size: 16px;

    &:focus {
        outline: none;
    }
`;
