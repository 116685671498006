import styled from '@emotion/styled/macro';
import { Triangle } from '../../../../styles/icons';

export const BellContainer = styled.div`
    width: 44px;
    height: 44px;
    position: relative;
    cursor: pointer;
`;

export const BellIcon = styled.svg`
    height: 18px;
    position: absolute;
    top: 13px;
    left: 13px;

    path {
        fill: ${props => props.theme.colours.header.navLink};
    }
`;

export const NotificationDot = styled.div`
    background-color: #dd2e2e;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 6px;
    height: 6px;
    text-align: center;
`;

export const Pointer = styled.div`
    &::after {
        font-family: 'skycons';
        font-size: ${props => props.theme.iconSize.normal};
        content: '${Triangle}';
        color: #ffffff;
        width: 10px;
        display: block;
        transform: rotate(-90deg);
        position: absolute;
        left: 16px;
        top: 39px;
    }
`;

export const NotificationWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    top: ${props => (props.notificationsOpen ? '57px' : '-5000px')};
    right: 0;
    width: 100%;
    pointer-events: none; // prevent click blocking
    margin-top: -1px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    visibility: ${props => (props.notificationsOpen ? 'visible' : 'hidden')};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        top: 128px;
    }
`;

export const NotificationInner = styled.div`
    max-width: none;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        max-width: ${props => props.theme.widths.containerMaxWidth};
    }
`;

export const NotificationContainer = styled.div`
    pointer-events: all;
    z-index: 95;
    background-color: #fff;
    align-self: flex-start;
    position: relative;

    border-top: 1px solid #ffffff;
    box-shadow: 0px 8px 12px 0px rgba(218, 218, 218, 0.5);

    min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);

    width: 100%;
    height: ${props => `${props.contentHeight}px`};
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 375px;
        min-height: 480px;
        max-height: 480px;

        border: 1px solid #e4e4e4;
        border-top: 1px solid #ffffff;

        display: ${props => (props.notificationsOpen ? 'block' : 'none')};
    }
`;
