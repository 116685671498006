import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    checkedAuth,
    requestOauthTokens
} from '../../store/modules/auth/actions';
import getQueryItems from '../../helpers/getQueryItems';
import { getApp, isUsingIOSV3OrHigher } from '../../helpers/appBridge';
import { selectIsLoggedIn } from '../../store/modules/auth/selectors';

export const Callback = ({
    markCheckedAuth,
    requestTokens,
    location,
    isLoggedIn,
    history
}) => {
    /**
     * If we've hit the oauth callback page, but are using a web-wrapper app
     * then we need to redirect to "/"
     */
    const isSso = getApp() && !isUsingIOSV3OrHigher();
    const defaultRedirect = isSso ? '/' : null;

    const [redirect, setRedirect] = useState(defaultRedirect);

    useEffect(() => {
        if (isSso) {
            return;
        }
        if (isLoggedIn) {
            const redirectPath =
                sessionStorage.getItem('oauth_postLoginRedirectPath') || '/';
            setRedirect(redirectPath);
        }
    }, [isSso, isLoggedIn, history]);

    useEffect(() => {
        if (isSso || isLoggedIn) {
            return;
        }
        const {
            code,
            state,
            error_description: errorDescription
        } = getQueryItems(location.search);

        if (errorDescription) {
            markCheckedAuth();
            const redirectPath =
                sessionStorage.getItem('oauth_postLoginRedirectPath') || '/';
            setRedirect(redirectPath);
        } else {
            requestTokens(code, state);
        }
    }, [isSso, markCheckedAuth, requestTokens, location.search, isLoggedIn]);

    if (isSso) {
        window.location.href = '/';
        return null;
    }

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return null;
};

const mapDispatchToProps = dispatch => ({
    requestTokens: (code, state) => dispatch(requestOauthTokens(code, state)),
    markCheckedAuth: () => dispatch(checkedAuth())
});

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state)
});

Callback.propTypes = {
    markCheckedAuth: PropTypes.func.isRequired,
    requestTokens: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired
    }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
