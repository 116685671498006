import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { parse } from 'querystring';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { selectType } from '../../store/modules/leaderboard/selectors';
import ConnectedGlobal from './types/Global';
import ConnectedSearch from './types/Search';
import ConnectedPundits from './types/Pundits';
import ConnectedFollowing from './types/Following';
import {
    selectNonOpenOrFutureRoundIds,
    selectNonOpenOrFutureRoundMonths,
    selectLatestNonOpenOrFutureMonthId,
    selectLatestNonOpenOrFutureRoundId,
    selectHasLoadedRounds
} from '../../store/modules/round/selectors';
import { requestRounds } from '../../store/modules/round/actions';
import { getValidRedirect } from '../../helpers/periodHelper';
import ConnectedLeaderboardNavigation from './components/LeaderboardNavigation';
import * as Styled from './styles';
import MessageBlock from '../../components/MessageBlock';
import ConnectedGlobalBanner from '../../components/Offer/GlobalBanner';
import setTitle from '../../helpers/page';
import { ucFirst } from '../../helpers/stringFormatters';
import PageTitle from '../../components/Common/PageTitle';
import InfoParagraph from './components/InfoParagraph';
import { isUsingIOSV3OrHigher } from '../../helpers/appBridge';

export const Leaderboard = ({
    match,
    hasLoadedRounds,
    type,
    location,
    lastCompleteMonthId,
    lastCompleteRoundId,
    activeMonths,
    activeRounds,
    getRounds,
    history
}) => {
    const activeSearch = parse(location.search.replace('?', '')).search || '';
    const { period } = match.params;
    let { periodId } = match.params;
    const { replace } = history;
    const isUsingIOS = isUsingIOSV3OrHigher();

    useEffect(() => {
        if (period) {
            setTitle(`Leaderboard | ${ucFirst(period)}`);
        } else {
            setTitle('Leaderboard');
        }
    }, [period]);

    useEffect(() => {
        if (!lastCompleteRoundId) {
            getRounds();
        }
    }, [lastCompleteRoundId, getRounds]);

    useEffect(() => {
        const validRedirect = getValidRedirect(
            match.params.period,
            match.params.periodId,
            lastCompleteMonthId,
            lastCompleteRoundId,
            activeMonths,
            activeRounds
        );

        if (validRedirect) {
            replace(`/leaderboard/${validRedirect}${location.search || ''}`);
        }
    }, [
        replace,
        match.params.period,
        match.params.periodId,
        lastCompleteMonthId,
        lastCompleteRoundId,
        activeMonths,
        activeRounds,
        location.search
    ]);

    if (hasLoadedRounds && !lastCompleteRoundId) {
        return (
            <Styled.LeaderboardPageContainer isUsingIOS={isUsingIOS}>
                <PageTitle>Leaderboard</PageTitle>
                <MessageBlock type="info">
                    <h3>No leaderboards are available yet</h3>
                    <p>
                        The leaderboard will be available after the 1st round of
                        the season is complete. Please check back soon.
                    </p>
                </MessageBlock>
            </Styled.LeaderboardPageContainer>
        );
    }

    if (!lastCompleteRoundId) {
        return null;
    }

    if (period !== 'season' && period !== 'info') {
        if (periodId) {
            periodId = parseInt(periodId, 10);
        }

        if (
            !periodId ||
            (period === 'month' && !activeMonths.includes(periodId)) ||
            (period === 'round' && !activeRounds.includes(periodId))
        ) {
            return null;
        }
    }

    let component;
    switch (type) {
        case 'search':
            component = (
                <ConnectedSearch
                    search={activeSearch}
                    period={period}
                    periodId={periodId}
                    lastCompleteRoundId={lastCompleteRoundId}
                    lastCompleteMonthId={lastCompleteMonthId}
                />
            );
            break;
        case 'following':
            component = (
                <ConnectedFollowing
                    period={period}
                    periodId={periodId}
                    lastCompleteRoundId={lastCompleteRoundId}
                    lastCompleteMonthId={lastCompleteMonthId}
                />
            );
            break;
        case 'pundits':
            component = (
                <ConnectedPundits
                    period={period}
                    periodId={periodId}
                    lastCompleteRoundId={lastCompleteRoundId}
                    lastCompleteMonthId={lastCompleteMonthId}
                />
            );
            break;
        case 'global':
            component = (
                <ConnectedGlobal
                    period={period}
                    periodId={periodId}
                    lastCompleteRoundId={lastCompleteRoundId}
                    lastCompleteMonthId={lastCompleteMonthId}
                />
            );
            break;
        default:
            return null;
    }

    return (
        <Styled.LeaderboardPageContainer isUsingIOS={isUsingIOS}>
            <PageTitle>Leaderboard</PageTitle>
            <Styled.LeaderboardContainer>
                <ConnectedLeaderboardNavigation
                    match={match}
                    activeNavigation={period !== 'info'}
                    location={location}
                    history={history}
                    lastCompleteRoundId={lastCompleteRoundId}
                    lastCompleteMonthId={lastCompleteMonthId}
                />
                {period === 'info' && <InfoParagraph type="leaderboard" />}
                {period !== 'info' && component}
            </Styled.LeaderboardContainer>
            <ConnectedGlobalBanner />
        </Styled.LeaderboardPageContainer>
    );
};

const mapStateToProps = (state, props) => ({
    type: selectType(props.location),
    hasLoadedRounds: selectHasLoadedRounds(state),
    lastCompleteMonthId: selectLatestNonOpenOrFutureMonthId(state),
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state),
    activeMonths: selectNonOpenOrFutureRoundMonths(state),
    activeRounds: selectNonOpenOrFutureRoundIds(state)
});

const mapDispatchToProps = dispatch => ({
    getRounds: () => {
        dispatch(requestRounds());
    }
});

Leaderboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            period: PropTypes.string,
            periodId: PropTypes.string
        })
    }).isRequired,
    hasLoadedRounds: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        key: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired
    }).isRequired,
    getRounds: PropTypes.func.isRequired,
    lastCompleteMonthId: PropTypes.number,
    lastCompleteRoundId: PropTypes.number,
    activeMonths: PropTypes.arrayOf(PropTypes.number),
    activeRounds: PropTypes.arrayOf(PropTypes.number)
};

Leaderboard.defaultProps = {
    lastCompleteMonthId: null,
    lastCompleteRoundId: null,
    activeMonths: [],
    activeRounds: []
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Leaderboard));
