/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BasePeriodIdSelector from '../../components/BasePeriodIdSelector';
import {
    selectActiveRoundSeason,
    selectNonFutureRounds,
    selectNonOpenOrFutureRoundMonths
} from '../../store/modules/round/selectors';
import {
    requestActiveRound,
    requestRounds
} from '../../store/modules/round/actions';
import * as Styled from './styles';

export const PeriodIdNavigation = ({
    urlTemplate,
    activeRounds,
    activeMonths,
    period,
    periodId,
    seasonName,
    history,
    loadActiveRound,
    loadRounds,
    PeriodIdSelectorContainer,
    backButtonLink,
    bgGrey
}) => {
    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    let activePeriods = period === 'month' ? activeMonths : activeRounds;

    if (!activePeriods) {
        return null;
    }

    if (period === 'season') {
        activePeriods = null;
    }

    return (
        <PeriodIdSelectorContainer bgGrey={bgGrey}>
            {backButtonLink && (
                <Styled.ButtonWrapper to={backButtonLink}>
                    <Styled.BackButtonIcon />
                </Styled.ButtonWrapper>
            )}
            <BasePeriodIdSelector
                period={period}
                periodId={periodId}
                activeIds={activePeriods}
                template={urlTemplate}
                history={history}
                HeaderWrapper={Styled.PeriodId}
                SelectWrapper={Styled.SelectWrapper}
                seasonName={seasonName}
            />
        </PeriodIdSelectorContainer>
    );
};

PeriodIdNavigation.propTypes = {
    urlTemplate: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    activeRounds: PropTypes.arrayOf(PropTypes.number),
    activeMonths: PropTypes.arrayOf(PropTypes.number),
    seasonName: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    loadRounds: PropTypes.func.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    PeriodIdSelectorContainer: PropTypes.oneOfType([
        PropTypes.shape({
            displayName: PropTypes.oneOf(['PeriodIdSelectorContainer'])
        }),
        PropTypes.symbol
    ]),
    backButtonLink: PropTypes.string,
    bgGrey: PropTypes.bool
};

PeriodIdNavigation.defaultProps = {
    periodId: null,
    seasonName: '',
    activeRounds: null,
    activeMonths: null,
    PeriodIdSelectorContainer: Styled.PeriodIdSelectorContainer,
    backButtonLink: null,
    bgGrey: false
};

const mapStateToProps = state => ({
    seasonName: selectActiveRoundSeason(state),
    activeMonths: selectNonOpenOrFutureRoundMonths(state),
    activeRounds: selectNonFutureRounds(state).map(round => round.id)
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound()),
    loadRounds: () => dispatch(requestRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(PeriodIdNavigation);
