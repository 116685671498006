import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

export default (
    state = {
        youtubeId: null,
        features: {},
        featuresLoaded: false,
        backButtonLink: null
    },
    action
) => {
    switch (action.type) {
        case types.RECEIVE_YOUTUBE_ID:
            return newIfChanged(state, {
                ...state,
                youtubeId: action.payload
            });
        case types.RECEIVE_FEATURE_SWITCHES:
            return newIfChanged(state, {
                ...state,
                features: action.data,
                featuresLoaded: true
            });
        case types.UPDATE_BACK_BUTTON:
            return newIfChanged(state, {
                ...state,
                backButtonLink: action.location
            });
        default:
            return state;
    }
};
