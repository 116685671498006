import styled from '@emotion/styled/macro';

export const Banner = styled.a`
    border-radius: 4px;
    width: 100%;
    * {
        border-radius: 4px;
    }
`;

export const BannerImage = styled.picture`
    display: block;
    width: 100%;
    margin: 0 auto;

    source,
    img {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
`;
