import styled from '@emotion/styled/macro';
import Button from '../../../../components/Common/Button';

export const PrizePicture = styled.picture`
    width: 100%;
`;

export const PrizeImage = styled.img`
    width: 100%;
`;

export const ClosedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    min-height: 300px;
`;

export const ClosedText = styled.h1`
    font-family: ${props => props.theme.fonts.medium};
    font-size: clamp(18px, 2.5vw, ${props => props.theme.fontSize.xlarge});
    line-height: 30px;
    color: ${props => props.theme.hero.default.colours.font};
    margin-bottom: ${props => props.theme.spacing.medium};
    text-align: center;
`;

export const ClosedSubText = styled.p`
    font-family: ${props => props.theme.fonts.regular};
    font-size: clamp(16px, 2vw, ${props => props.theme.fontSize.large});
    line-height: ${props => props.theme.lineHeight.normal};
    color: ${props => props.theme.hero.default.colours.font};
    margin-bottom: ${props => props.theme.spacing.medium};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        text-align: center;
    }
`;

export const InfoButton = styled(Button)`
    display: block;
    padding: 0;
    line-height: 50px;
    text-align: center;
    height: 50px;
    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        margin-top: 10px;
    }
`;

export const InfoButtonWrapper = styled.div`
    @media (max-width: ${props => props.theme.widths.containerMaxWidth}) {
        padding: ${props => props.isSeasonClosed ?? '0 20px'};
    }

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        padding: ${props => props.isSeasonClosed ?? '0'};
    }
`;

export const PrizeContainer = styled.div`
    pointer-events: none;
    width: 100%;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: 90%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const InfoFooter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Info = styled.span`
    margin-top: 10px;

    align-self: left;

    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.medium};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        text-align: center;
    }

    @media (max-width: ${props => props.theme.hero.widths.smallMobileMax}) {
        font-size: ${props => props.theme.fontSize.xsmall};
    }
`;

export const InfoInner = styled.div`
    padding: 15px 40px;

    color: ${props => props.theme.hero.default.colours.font};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    box-sizing: border-box;
    width: 100%;

    font-size: 14px;

    @media (max-width: ${props => props.theme.widths.containerMaxWidth}) {
        padding: ${props => (props.isSeasonClosed ? '15px 20px' : '20px 10px')};
    }

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        padding: 20px 10px;
    }
`;
