import axios from 'axios';
import { API_ROOT } from '../config';

const bannerAPI = {
    getBanner: async () => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/banner/home`,
            {}
        );

        if (status !== 200) {
            throw new Error('Unable to get banner');
        }
        return data;
    }
};

export default bannerAPI;
