const readCookies = () => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieStrings = decodedCookie.split(';');

    return cookieStrings.reduce((result, cookieString) => {
        const [name, value] = cookieString.trim().split('=');
        return {
            ...result,
            [name]: value
        };
    }, {});
};

export const readCookie = name => {
    const cookies = readCookies();
    return cookies[name];
};

export const setCookie = (name, value, hoursExpiry) => {
    const date = new Date();
    date.setTime(date.getTime() + hoursExpiry * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
};

export const deleteCookie = name => {
    setCookie(name, '', -1);
};
