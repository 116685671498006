import { select, put, takeLeading, call } from 'redux-saga/effects';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';
import * as types from './types';
import { selectToken } from '../auth/selectors';
import * as leaderboardSelectors from './selectors';
import leaderboardApi from '../../../api/leaderboard';

export function* getLeaderboardForReferrees(action) {
    try {
        const { period, id } = action;
        const existingData = yield select(
            leaderboardSelectors.referees,
            period,
            id
        );

        if (existingData) {
            return;
        }

        const token = yield select(selectToken);
        const data = yield call(
            leaderboardApi.getLeaderboardForReferrees,
            period,
            id,
            token
        );
        yield put(actions.receiveLeaderboardForReferrees(period, data));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* getLeaderboardForSelf(action) {
    try {
        const { period, id } = action;

        const token = yield select(selectToken);
        const data = yield call(
            leaderboardApi.getLeaderboardForSelf,
            period,
            id,
            token
        );
        yield put(actions.receiveLeaderboardForSelf(data, period, id));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* getGlobalLeaderboard(action) {
    try {
        const { period, id } = action;

        const data = yield call(
            leaderboardApi.getGlobalLeaderboard,
            period,
            id
        );
        yield put(actions.receiveGlobalLeaderboard(data, period, id));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* getPunditLeaderboard(action) {
    try {
        const { period, id } = action;

        const data = yield call(
            leaderboardApi.getPunditLeaderboard,
            period,
            id
        );
        yield put(actions.receivePunditLeaderboard(data, period, id));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* getFolloweeLeaderboard(action) {
    try {
        const { period, id } = action;

        const token = yield select(selectToken);
        const data = yield call(
            leaderboardApi.getFolloweeLeaderboard,
            period,
            id,
            token
        );
        yield put(actions.receiveFolloweeLeaderboard(data, period, id));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* getSearchLeaderboard(action) {
    try {
        const { search, period, id } = action;

        const data = yield call(
            leaderboardApi.getSearchLeaderboard,
            search,
            period,
            id
        );
        yield put(actions.receiveSearchLeaderboard(data, search, period, id));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(addErrorMessage(err.message));
        } else {
            yield put(
                addErrorMessage(
                    'There was a problem retrieving the leaderboard'
                )
            );
        }
    }
}

export function* watchGetLeaderboardForReferrees() {
    yield takeLeading(
        types.REQUEST_LEADERBOARD_FOR_REFERREES,
        getLeaderboardForReferrees
    );
}

export function* watchGetLeaderboardForSelf() {
    yield takeLeading(
        types.REQUEST_LEADERBOARD_FOR_SELF,
        getLeaderboardForSelf
    );
}

export function* watchGetGlobalLeaderboard() {
    yield takeLeading(types.REQUEST_GLOBAL_LEADERBOARD, getGlobalLeaderboard);
}

export function* watchGetFolloweeLeaderboard() {
    yield takeLeading(
        types.REQUEST_FOLLOWEE_LEADERBOARD,
        getFolloweeLeaderboard
    );
}

export function* watchGetPunditLeaderboard() {
    yield takeLeading(types.REQUEST_PUNDIT_LEADERBOARD, getPunditLeaderboard);
}

export function* watchGetSearchLeaderboard() {
    yield takeLeading(types.REQUEST_SEARCH_LEADERBOARD, getSearchLeaderboard);
}

export const sagas = [
    watchGetLeaderboardForReferrees,
    watchGetLeaderboardForSelf,
    watchGetFolloweeLeaderboard,
    watchGetGlobalLeaderboard,
    watchGetPunditLeaderboard,
    watchGetSearchLeaderboard
];
