import React from 'react';
import * as Styled from './styles';
import Button from '../../../../components/Common/Button';
import config from '../../../../config';

const CreateLeague = () => {
    const IconImage = () => `${config.getS3BucketPath()}/league/default.png`;
    return (
        <Styled.Card>
            <Styled.DescriptionContainer>
                <Styled.ImageContainer>
                    <Styled.IconImage
                        src={IconImage()}
                        alt="Super 6 create league icon"
                        data-test-id="create-league-icon"
                    />
                </Styled.ImageContainer>

                <Styled.LabelContainer>
                    <Styled.SubTitle>Create a new league</Styled.SubTitle>
                    <Styled.Text>
                        Play against your friends in a private Super6 league
                    </Styled.Text>
                </Styled.LabelContainer>
            </Styled.DescriptionContainer>

            <Button
                internal
                link="/league/create"
                data-test-id="create-league-button"
            >
                Create a league
            </Button>
        </Styled.Card>
    );
};

export default CreateLeague;
