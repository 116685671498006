import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobileOnly, isDesktop } from 'react-device-detect';
import * as Styled from './styles';
import SHARE_LINKS from './config';
import Image from '../../../../components/Common/Image';
import Button from '../../../../components/Common/Button';
import { sendSocialShare as sendSocialShareAction } from '../../../../store/modules/appBridge/actions';
import { canShareNatively } from '../../../../helpers/appBridge';
import actions from '../../../../helpers/GoogleAnalytics/actions';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

let isMobileApp = false;

const LeagueShare = ({
    sendSocialShare,
    customMessage,
    shareEvent,
    isApp,
    setInterfaceEvent
}) => {
    const emitNativeShareEvent = e => {
        if (shareEvent) {
            shareEvent();
        }
        sendSocialShare({
            text: customMessage.shareText || '',
            URL: '',
            imageURL: '',
            origin: window.location.pathname.split('/')[1] || 'homepage'
        });

        e.preventDefault();
    };

    const sendNativeWebShareEvent = () => {
        navigator.share({
            text: customMessage.shareText || '',
            URL: '',
            imageURL: '',
            origin: window.location.pathname.split('/')[1] || 'homepage'
        });
    };

    const createLink = item =>
        `${item.link}${encodeURIComponent(customMessage.shareText)}`;

    const shouldShareNatively = canShareNatively();
    isMobileApp = isApp;

    const getNativeSharing = () => (
        <Styled.ButtonPadding small>
            <Button
                data-analytics={`"[Share]-Native${!isApp ? '-Web' : ''}"`}
                id="share-btn"
                style={{ padding: '10px' }}
                onClick={() => {
                    if (isApp) {
                        emitNativeShareEvent();
                    } else {
                        sendNativeWebShareEvent();
                    }
                    setInterfaceEvent(
                        customMessage.buttonText || 'Share now',
                        actions.clicked
                    );
                }}
            >
                <Styled.ShareButtonTextContainer>
                    {customMessage.buttonText || 'Share now'}
                </Styled.ShareButtonTextContainer>
            </Button>
        </Styled.ButtonPadding>
    );

    return (
        <Styled.ShareContainer isApp={shouldShareNatively}>
            {shouldShareNatively || isApp ? (
                getNativeSharing()
            ) : (
                <>
                    <Styled.IconRow>
                        {SHARE_LINKS.map(item => {
                            if (
                                (item.showInBrowser && isDesktop) ||
                                (item.showOnMobile && isMobileOnly)
                            ) {
                                return (
                                    <Styled.Icon
                                        onClick={() => {
                                            setInterfaceEvent(
                                                elementText.interface[
                                                    item.altText
                                                ],
                                                actions.clicked
                                            );
                                            if (shareEvent) {
                                                shareEvent();
                                            }
                                        }}
                                        key={item.link}
                                        href={createLink(item)}
                                        data-analytics={item.analytics}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-test-id={item.testId}
                                    >
                                        {item.image ? (
                                            <Image
                                                src={item.image}
                                                alt={item.altText}
                                            />
                                        ) : (
                                            <Styled.Character
                                                icon={item.character}
                                            />
                                        )}
                                    </Styled.Icon>
                                );
                            }
                            return null;
                        })}
                    </Styled.IconRow>
                </>
            )}
        </Styled.ShareContainer>
    );
};

LeagueShare.propTypes = {
    sendSocialShare: PropTypes.func.isRequired,
    customMessage: PropTypes.shape({
        shareText: PropTypes.string,
        shortShareText: PropTypes.string,
        url: PropTypes.string,
        buttonText: PropTypes.string
    }),
    shareEvent: PropTypes.func,
    isApp: PropTypes.bool,
    setInterfaceEvent: PropTypes.any
};

LeagueShare.defaultProps = {
    customMessage: {
        shareText: null,
        buttonText: null
    },
    shareEvent: null,
    isApp: isMobileApp,
    setInterfaceEvent: () => {}
};

const mapDispatchToProps = dispatch => ({
    sendSocialShare: data => dispatch(sendSocialShareAction(data))
});

export default connect(null, mapDispatchToProps)(LeagueShare);
