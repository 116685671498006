import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { requestLeague } from '../../../store/modules/league/actions';
import { selectLeague } from '../../../store/modules/league/selectors';
import setTitle from '../../../helpers/page';
import LeaderboardRedirectWrapper from './LeaderboardRedirectWrapper';
import Spinner from '../../../components/Common/Spinner';

const LeaderboardFoundWrapper = ({ match, league, getLeague, history }) => {
    const { slug, period, periodId } = match.params;

    const standardSlug = slug.toUpperCase().replace(' ', '');

    useEffect(() => {
        if (standardSlug === slug) {
            getLeague(slug);
        }
    }, [slug, standardSlug, getLeague]);

    if (slug !== standardSlug) {
        if (periodId) {
            return (
                <Redirect
                    to={`/league/${standardSlug}/${period}/${periodId}`}
                />
            );
        }
        return <Redirect to={`/league/${standardSlug}/${period}`} />;
    }
    if (league && !league.isFound) {
        return <Redirect to="/league" />;
    }

    if (!league) {
        return <Spinner />;
    }

    setTitle(league.name);

    if (!period || !['round', 'month', 'season', 'info'].includes(period)) {
        return <Redirect to={`/league/${slug}/season`} />;
    }

    return (
        <LeaderboardRedirectWrapper
            league={league}
            period={period}
            periodId={periodId}
            history={history}
        />
    );
};

const mapStateToProps = (state, props) => ({
    league: selectLeague(state, props.match.params.slug)
});

const mapDispatchToProps = dispatch => ({
    getLeague: slug => dispatch(requestLeague(slug))
});

LeaderboardFoundWrapper.propTypes = {
    league: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        isFound: PropTypes.bool.isRequired,
        name: PropTypes.string,
        memberCount: PropTypes.number,
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        isChairperson: PropTypes.bool
    }),
    getLeague: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            slug: PropTypes.string,
            period: PropTypes.string,
            periodId: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

LeaderboardFoundWrapper.defaultProps = {
    league: null
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LeaderboardFoundWrapper));
