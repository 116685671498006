import {
    buildTransferLink,
    BET,
    VEGAS,
    SKYSPORTS,
    CASINO,
    ITV7,
    POKER,
    BINGO,
    EXTRA,
    SPORTINGLIFE
} from '../../helpers/transfer';

const XSELL_LINKS = [
    {
        alt: 'Sky Bet',
        name: 'skybet',
        link: buildTransferLink(BET, '?dcmp=s6_nav&AFF_ID=1197312425'),
        analytics: '[ProductXSell]-Bet',
        image: 'sky-bet-s.png',
        isBet: true
    },
    {
        alt: 'Super6 Extra',
        name: 'super6extra',
        link: buildTransferLink(EXTRA, '?dcmp=s6_nav'),
        analytics: '[ProductXSell]-Extra',
        image: 'super6-extra.png?updated=2020-10-21',
        large: true,
        isBet: false,
        cantBetOrdinal: 1,
        hidden: true
    },
    {
        alt: 'ITV7',
        name: 'skyc4racingpick6',
        link: buildTransferLink(ITV7, '?dcmp=s6_nav&AFF_ID=1197328077'),
        analytics: '[ProductXSell]-P7',
        image: 'itv7.png?updated=2020-10-08',
        large: true,
        isBet: false,
        cantBetOrdinal: 3
    },
    {
        alt: 'Sky Vegas',
        name: 'skyvegas',
        link: buildTransferLink(VEGAS, '?dcmp=s6_nav&AFF_ID=1197328078'),
        analytics: '[ProductXSell]-Vegas',
        image: 'sky-vegas.png?updated=2020-10-08',
        border: true,
        isBet: true
    },
    {
        alt: 'Sky Sports',
        name: 'skysports',
        link: buildTransferLink(SKYSPORTS, '?dcmp=s6_nav&AFF_ID=1197328071'),
        analytics: '[ProductXSell]-Sports',
        image: 'sky-sports-m.png?updated=2020-10-08',
        isBet: false,
        cantBetOrdinal: 2
    },
    {
        alt: 'Sky Casino',
        name: 'skycasino',
        link: buildTransferLink(CASINO, '?dcmp=s6_nav&AFF_ID=1197328079'),
        analytics: '[ProductXSell]-Casino',
        image: 'sky-casino.png?updated=2020-10-08',
        border: true,
        isBet: true
    },
    {
        alt: 'Sky Poker',
        name: 'skypoker',
        link: buildTransferLink(POKER, '?dcmp=s6_nav&AFF_ID=1197328080'),
        analytics: '[ProductXSell]-Poker',
        image: 'sky-poker.png?updated=2020-10-08',
        border: true,
        isBet: true
    },
    {
        alt: 'Sky Bingo',
        name: 'skybingo',
        link: buildTransferLink(BINGO, '?dcmp=s6_nav&AFF_ID=1197328081'),
        analytics: '[ProductXSell]-Bingo',
        image: 'sky-bingo.png?updated=2020-10-08',
        border: true,
        isBet: true
    },
    {
        alt: 'Sporting Life',
        name: 'SportingLife',
        link: buildTransferLink(SPORTINGLIFE, '?dcmp=s6_nav'),
        analytics: '[ProductXSell]-SportingLife',
        image: 'sporting-life.png?updated=2021-09-13',
        large: true,
        isBet: false
    }
];

export default XSELL_LINKS;
