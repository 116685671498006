import React from 'react';
import PropTypes from 'prop-types';
import LastRoundPredictionAndScore from './LastRoundPredictionAndScore';
import * as Styled from './styles';

const PredictionAndScore = ({
    predictedHomeScore,
    homeScore,
    predictedAwayScore,
    awayScore,
    points,
    isAbandoned
}) => (
    <Styled.MatchContainer>
        <Styled.Match>
            <LastRoundPredictionAndScore
                predictedHomeScore={predictedHomeScore}
                homeScore={homeScore}
                predictedAwayScore={predictedAwayScore}
                awayScore={awayScore}
                points={points}
                isAbandoned={isAbandoned}
            />
        </Styled.Match>
    </Styled.MatchContainer>
);

PredictionAndScore.defaultProps = {
    predictedHomeScore: null,
    predictedAwayScore: null,
    homeScore: null,
    awayScore: null,
    points: null,
    isAbandoned: false
};

PredictionAndScore.propTypes = {
    predictedHomeScore: PropTypes.number,
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    predictedAwayScore: PropTypes.number,
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    points: PropTypes.number,
    isAbandoned: PropTypes.bool
};

export default PredictionAndScore;
