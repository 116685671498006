/* eslint-disable jsx-a11y/no-onchange */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Button from '../Common/Button';
import { isUsingIOSV3OrHigher } from '../../helpers/appBridge';

const SelectHeaderContainer = ({
    activeOption,
    optionsList,
    fontSize,
    backLink,
    onChange
}) => {
    const refSelect = useRef();
    const refSelectedItem = useRef();
    const [selectOpen, setSelectOpen] = useState(false);
    const isUsingIOS = isUsingIOSV3OrHigher();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (
                selectOpen &&
                refSelect.current &&
                !refSelect.current.contains(e.target)
            ) {
                setSelectOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        if (selectOpen && refSelectedItem.current) {
            refSelectedItem.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [selectOpen]);

    return (
        <Styled.TopLinkContainer data-test-id="select-header-container">
            <Styled.BackButtonContainer>
                {!isUsingIOS && (
                    <Button
                        id="select-header-back-button"
                        data-test-id="select-header-back-button"
                        back
                        link={backLink}
                        fontSize={fontSize}
                    />
                )}
            </Styled.BackButtonContainer>
            <Styled.TopLinkContainerHeading data-test-id="select-header-container-heading">
                <Styled.SelectWrapper
                    ref={refSelect}
                    onClick={() => setSelectOpen(() => !selectOpen)}
                >
                    <Styled.LongText>
                        {activeOption && activeOption.name}
                    </Styled.LongText>
                    <Styled.Select
                        value={(activeOption && activeOption.slug) || ''}
                        className="leaderboard-select-league"
                        data-test-id="leaderboard-select-league"
                    >
                        {optionsList &&
                            selectOpen &&
                            optionsList.map(option => (
                                <Styled.SelectOption
                                    key={option.slug}
                                    value={option.slug}
                                    ref={
                                        activeOption.slug === option.slug
                                            ? refSelectedItem
                                            : null
                                    }
                                    className={
                                        activeOption.slug === option.slug
                                            ? 'selected'
                                            : ''
                                    }
                                    onClick={() => {
                                        onChange(option);
                                    }}
                                >
                                    {option.name}
                                </Styled.SelectOption>
                            ))}
                    </Styled.Select>
                </Styled.SelectWrapper>
            </Styled.TopLinkContainerHeading>
        </Styled.TopLinkContainer>
    );
};

SelectHeaderContainer.propTypes = {
    fontSize: PropTypes.string,
    activeOption: PropTypes.object.isRequired,
    backLink: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    optionsList: PropTypes.arrayOf(PropTypes.object)
};

SelectHeaderContainer.defaultProps = {
    optionsList: [],
    fontSize: '20px'
};

export default SelectHeaderContainer;
