import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
    selectStatsForSelf,
    selectLoadingStatsForSelf,
    selectSeasonStatsForSelf
    // selectLoadingSeasonStatsForSelf
} from '../../store/modules/stats/selectors';
import {
    requestStatsForSelf,
    requestSeasonHeadToHeadStats
} from '../../store/modules/stats/actions';
import {
    requestRoundResults,
    requestRoundPredictions,
    requestRoundPredictionsByUser
} from '../../store/modules/results/actions';

import View from './view';
import { getFormattedShortDayTime } from '../../helpers/dateFormatters';
import {
    requestRounds,
    requestActiveRound
} from '../../store/modules/round/actions';
import {
    selectActiveRoundStatus,
    selectLatestCompletedRoundId
} from '../../store/modules/round/selectors';
import {
    getRoundPredictionsByUserWithName,
    selectLastRoundData
} from '../../store/modules/results/selectors';
import { isUsingIOSV3OrHigher } from '../../helpers/appBridge';
import Loading from './components/PerformanceHubLoading';
import { sendPlayNow } from '../../store/modules/appBridge/actions';
import { isFeatureOn } from '../../store/modules/content/selectors';
import {
    ENABLE_HEAD_TO_HEAD,
    ENABLE_MY_PERFORMANCE_PAGE_COMPONENTS
} from '../../features';
import { getH2hText, getLastFiveRounds } from './helper';

const isEmpty = obj => Object.keys(obj).length === 0;

const PUNDIT_ID = 'head-to-head-opponent';

export const PerformanceHubComponent = ({
    getStatsForSelf,
    // getSeasonStatsForSelf,
    isLoading,
    // isLoadingSeasonStats,
    selfStats,
    seasonStats,
    loadRounds,
    loadActiveRound,
    activeRoundStatus,
    sendAppMessage,
    getRoundResults,
    getRoundPredictions,
    lastRoundId,
    componentsEnabled,
    getPunditPredictions,
    getSeasonHeadToHeadStats,
    h2hEnabled
}) => {
    useEffect(() => {
        getStatsForSelf(true);
    }, [getStatsForSelf]);

    // useEffect(() => {
    //     getSeasonStatsForSelf();
    // }, [getSeasonStatsForSelf]);

    useEffect(() => {
        if (componentsEnabled) {
            loadRounds();
        }
        loadActiveRound();
    }, [loadRounds, loadActiveRound]);

    useEffect(() => {
        if (componentsEnabled && lastRoundId) {
            getRoundResults(lastRoundId);
            getRoundPredictions(lastRoundId);
            getPunditPredictions(lastRoundId);
        }
    }, [
        lastRoundId,
        getRoundResults,
        getRoundPredictions,
        getPunditPredictions
    ]);

    useEffect(() => {
        if (componentsEnabled) {
            getSeasonHeadToHeadStats();
        }
    }, [getSeasonHeadToHeadStats]);

    const lastRoundData = useSelector(state =>
        selectLastRoundData(state, lastRoundId)
    );

    const punditPredictions = useSelector(state => {
        if (!lastRoundId || !PUNDIT_ID) {
            return null;
        }

        return getRoundPredictionsByUserWithName(state, lastRoundId, PUNDIT_ID);
    });

    if (
        isLoading ||
        // isLoadingSeasonStats ||
        // isEmpty(seasonStats) ||
        isEmpty(selfStats)
    ) {
        return (
            <div>
                <Loading
                    componentsEnabled={componentsEnabled}
                    h2hEnabled={h2hEnabled}
                />
            </div>
        );
    }

    const currentRound = selfStats.entries[selfStats.entries.length - 1];
    const isLive = activeRoundStatus === 'inplay';

    let buttonText;
    let subText;
    let buttonLink;

    if (activeRoundStatus === 'inplay') {
        buttonLink = '/inplay';
        buttonText = 'View Current Round';
    } else {
        buttonLink = currentRound.didEnter ? '/played' : '/play';
        buttonText = currentRound.didEnter
            ? 'Edit Predictions'
            : 'Play For Free';
        subText = `Entries by ${getFormattedShortDayTime(
            currentRound.roundDate
        )}. T&Cs apply`;
    }

    let buttonOnClick;
    if (isUsingIOSV3OrHigher()) {
        buttonOnClick = sendAppMessage;
    }
    return (
        <div>
            <View
                seasonStats={seasonStats}
                selfStats={selfStats}
                streak={selfStats.currentStreak}
                subText={subText}
                buttonText={buttonText}
                buttonLink={buttonLink}
                hasEntered={currentRound.didEnter}
                isLive={isLive}
                brokenStreak={selfStats.brokenStreak}
                buttonOnClick={buttonOnClick}
                componentsEnabled={componentsEnabled}
                lastRoundData={lastRoundData}
                h2hText={getH2hText(
                    componentsEnabled,
                    punditPredictions,
                    lastRoundId,
                    lastRoundData
                )}
                h2hEnabled={h2hEnabled}
                entryData={getLastFiveRounds(
                    componentsEnabled,
                    selfStats.entries,
                    lastRoundId
                )}
            />
        </div>
    );
};

PerformanceHubComponent.propTypes = {
    getStatsForSelf: PropTypes.func.isRequired,
    // getSeasonStatsForSelf: PropTypes.func,
    getSeasonHeadToHeadStats: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    // isLoadingSeasonStats: PropTypes.bool.isRequired,
    seasonStats: PropTypes.shape({
        currentTopPercentage: PropTypes.number
    }),
    selfStats: PropTypes.shape({
        correctScores: PropTypes.number,
        correctResults: PropTypes.number,
        totalPoints: PropTypes.number,
        currentStreak: PropTypes.number,
        currentStreakAccumulatedPoints: PropTypes.number,
        averagePoints: PropTypes.number,
        roundsEntered: PropTypes.number,
        bestStreak: PropTypes.number,
        entries: PropTypes.array,
        willLoseStreak: PropTypes.bool,
        brokenStreak: PropTypes.bool
    }),
    loadRounds: PropTypes.func.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    activeRoundStatus: PropTypes.string,
    sendAppMessage: PropTypes.func.isRequired,
    getRoundResults: PropTypes.func.isRequired,
    getRoundPredictions: PropTypes.func.isRequired,
    lastRoundId: PropTypes.number,
    lastRoundData: PropTypes.shape({
        points: PropTypes.string,
        homeTeam: PropTypes.shape({
            badgeUri: PropTypes.string
        }),
        awayTeam: PropTypes.shape({
            badgeUri: PropTypes.string
        }),
        predictedHomeScore: PropTypes.number,
        homeScore: PropTypes.number,
        predictedAwayScore: PropTypes.number,
        awayScore: PropTypes.number,
        isAbandoned: PropTypes.bool
    }),
    componentsEnabled: PropTypes.bool,
    getPunditPredictions: PropTypes.func.isRequired,
    punditPredictions: PropTypes.shape({
        firstName: PropTypes.string,
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    headToHeadSeasonStats: PropTypes.shape({
        scores: PropTypes.number,
        results: PropTypes.number,
        points: PropTypes.number,
        userBeatOpponent: PropTypes.number,
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }),
    h2hEnabled: PropTypes.bool
};

PerformanceHubComponent.defaultProps = {
    lastRoundId: null,
    selfStats: null,
    seasonStats: null,
    activeRoundStatus: null,
    lastRoundData: null,
    componentsEnabled: false,
    punditPredictions: null,
    headToHeadSeasonStats: null,
    h2hEnabled: true
};

const mapStateToProps = state => ({
    isLoading: selectLoadingStatsForSelf(state),
    selfStats: selectStatsForSelf(state),
    // isLoadingSeasonStats: selectLoadingSeasonStatsForSelf(state),
    seasonStats: selectSeasonStatsForSelf(state),
    activeRoundStatus: selectActiveRoundStatus(state),
    lastRoundId: selectLatestCompletedRoundId(state),
    componentsEnabled: isFeatureOn(
        state,
        ENABLE_MY_PERFORMANCE_PAGE_COMPONENTS,
        false
    ),
    h2hEnabled: isFeatureOn(state, ENABLE_HEAD_TO_HEAD, true)
});

const mapDispatchToProps = dispatch => ({
    getStatsForSelf: force => dispatch(requestStatsForSelf(force)),
    // getSeasonStatsForSelf: () => dispatch(requestSeasonStatsForSelf()),
    getSeasonHeadToHeadStats: () => dispatch(requestSeasonHeadToHeadStats()),
    loadRounds: () => dispatch(requestRounds()),
    loadActiveRound: () => dispatch(requestActiveRound()),
    sendAppMessage: () => dispatch(sendPlayNow()),
    getRoundResults: roundId => {
        dispatch(requestRoundResults(roundId));
    },
    getRoundPredictions: (roundId, userId) => {
        dispatch(requestRoundPredictions(roundId, userId));
    },
    getPunditPredictions: roundId =>
        dispatch(requestRoundPredictionsByUser(roundId, PUNDIT_ID))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PerformanceHubComponent);
