import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { selectLatestNonOpenOrFutureRoundId } from '../../../store/modules/round/selectors';
import {
    requestFollowees,
    createFollowee,
    deleteFollowee
} from '../../../store/modules/follow/actions';
import { requestRounds } from '../../../store/modules/round/actions';
import LeaderboardTable from '../../../components/LeaderboardTable';

const ScoreTable = ({
    rounds,
    getRounds,
    userId,
    rows,
    lastCompleteRoundId,
    followees,
    getFollowees,
    onFollow,
    onUnfollow,
    testId
}) => {
    const { period, periodId } = useRouteMatch().params;

    useEffect(() => {
        if (!rounds || rounds.length < 1) {
            getRounds();
        }
    }, [getRounds, rounds]);

    useEffect(() => {
        // Load the followees if we're logged in and don't already have them loaded
        if (userId && !followees) {
            getFollowees();
        }
    }, [userId, followees, getFollowees]);

    // Bail if there's no rows
    if (rows.length < 1) {
        return null;
    }

    const relevantRoundId = period === 'round' ? periodId : lastCompleteRoundId;

    const relevantRound =
        rounds && rounds.find(round => round.id === Number(relevantRoundId));

    const baseLink =
        relevantRound && relevantRound.status === 'inplay'
            ? `/inplay`
            : `/results/round/${relevantRoundId}`;

    const formattedRows = rows.map((row, index) => {
        const isMe = userId !== null && row.userId === userId;
        const name = row.isMasked ? 'Winner being confirmed...' : row.name;
        const isFollowing = followees ? followees.includes(row.userId) : false;
        const mapped = {
            ...row,
            canRemove: false,
            isChairperson: false,
            isFollowing,
            isMe,
            link: `${baseLink}/user/${row.userId}`,
            name,
            isMasked: row.isMasked,
            isWinner: row.isWinner || (index === 0 && true)
        };

        if (userId !== null && !isMe) {
            mapped.onFollow = onFollow;
            mapped.onUnfollow = onUnfollow;
        }

        if (period === 'season') {
            if (!row.positionChange) {
                return mapped;
            }

            mapped.change = parseInt(row.positionChange, 10);

            if (
                !mapped.change ||
                mapped.change === 0 ||
                Number.isNaN(mapped.change)
            ) {
                return mapped;
            }
            if (mapped.change > 99 || mapped.change < -99) {
                mapped.formattedChange = '99+';
            } else {
                mapped.formattedChange = `${mapped.change}`.replace('-', '');
            }
        }

        return mapped;
    });

    return (
        <LeaderboardTable
            isSeason={period === 'season'}
            rows={formattedRows}
            testId={testId}
        />
    );
};

const mapStateToProps = state => ({
    rounds: state.round.rounds,
    userId: state.user.info.userId,
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state),
    followees: state.follow.followees || null
});

const mapDispatchToProps = dispatch => ({
    getFollowees: () => dispatch(requestFollowees()),
    getRounds: () => dispatch(requestRounds()),
    onFollow: userId => {
        dispatch(createFollowee(userId));
    },
    onUnfollow: userId => {
        dispatch(deleteFollowee(userId));
    }
});

ScoreTable.propTypes = {
    rounds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired
        }).isRequired
    ),
    userId: PropTypes.number,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.number,
            name: PropTypes.string,
            position: PropTypes.number,
            isWinner: PropTypes.bool.isRequired,
            isMasked: PropTypes.bool.isRequired,
            correctResults: PropTypes.number,
            correctScores: PropTypes.number,
            points: PropTypes.number
        })
    ).isRequired,
    lastCompleteRoundId: PropTypes.number.isRequired,
    getFollowees: PropTypes.func.isRequired,
    followees: PropTypes.arrayOf(PropTypes.number),
    getRounds: PropTypes.func.isRequired,
    onFollow: PropTypes.func.isRequired,
    onUnfollow: PropTypes.func.isRequired,
    testId: PropTypes.string
};

ScoreTable.defaultProps = {
    userId: null,
    followees: null,
    rounds: null,
    testId: null
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreTable);
