import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import TableRow from './TableRow';

const LeaderboardTable = ({ rows, testId, isSeason }) => (
    <Styled.LeaderboardTable data-test-id={testId}>
        <Styled.TableHeader>
            <tr>
                <Styled.TableHeaderColumn center width="small">
                    Pos
                </Styled.TableHeaderColumn>
                {isSeason && (
                    <Styled.TableHeaderColumn center width="small">
                        +/-
                    </Styled.TableHeaderColumn>
                )}
                <Styled.TableHeaderColumn width="expand" />
                <Styled.TableHeaderColumn
                    center
                    hideOnSmall
                    width="large-reduce"
                >
                    Results
                </Styled.TableHeaderColumn>
                <Styled.TableHeaderColumn
                    center
                    hideOnSmall
                    width="large-reduce"
                >
                    Scores
                </Styled.TableHeaderColumn>
                <Styled.TableHeaderColumn center width="large">
                    Points
                </Styled.TableHeaderColumn>
                {rows.some(row => !!row.onFollow) && (
                    <Styled.TableHeaderColumn
                        center
                        width="small-reduce"
                        canRemove={rows.some(row => row.canRemove)}
                    />
                )}
                {rows.some(row => row.canRemove) && (
                    <Styled.TableHeaderColumn
                        center
                        width="small-reduce"
                        canRemove={rows.some(row => row.canRemove)}
                    />
                )}
            </tr>
        </Styled.TableHeader>
        <tbody>
            {rows.map((row, index) => (
                <TableRow
                    key={row.userId}
                    row={row}
                    isSeason={isSeason}
                    displayChange={rows.some(r => 'change' in r)}
                    index={index}
                />
            ))}
        </tbody>
    </Styled.LeaderboardTable>
);

LeaderboardTable.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            canRemove: PropTypes.bool,
            change: PropTypes.number,
            correctResults: PropTypes.number,
            correctScores: PropTypes.number,
            isChairperson: PropTypes.bool,
            isFollowing: PropTypes.bool,
            isMasked: PropTypes.bool,
            isMe: PropTypes.bool,
            isWinner: PropTypes.bool.isRequired,
            link: PropTypes.string,
            name: PropTypes.string,
            onFollow: PropTypes.func,
            onUnfollow: PropTypes.func,
            points: PropTypes.number,
            position: PropTypes.number,
            removeUser: PropTypes.func,
            userId: PropTypes.number
        })
    ).isRequired,
    testId: PropTypes.string,
    isSeason: PropTypes.bool
};

LeaderboardTable.defaultProps = {
    testId: null,
    isSeason: false
};

export default LeaderboardTable;
