import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Styled from './styles';
import Alert from '../../components/Common/Alert';
import { PageContainer } from '../../components/Common/Page/styles';
import PageTitle from '../../components/Common/PageTitle';
import {
    requestActiveRound,
    requestPunditPredictions
} from '../../store/modules/round/actions';
import {
    selectActiveRoundId,
    selectActiveRoundScoreChallenges,
    selectPunditPredictions
} from '../../store/modules/round/selectors';
import Spinner from '../../components/Common/Spinner';
import {
    areFeaturesLoaded,
    isFeatureOn
} from '../../store/modules/content/selectors';
import { SEASON_CLOSED } from '../../features';

import PunditPredictionsList from '../../components/Pundit/PunditPredictions/list';

const Pundits = ({
    loadActiveRound,
    activeRoundId,
    pundits,
    getPredictions,
    isSeasonClosed,
    featuresLoaded,
    scoreChallenges
}) => {
    useEffect(() => {
        if (featuresLoaded && !isSeasonClosed) {
            loadActiveRound();
        }
    }, [loadActiveRound, featuresLoaded, isSeasonClosed]);

    useEffect(() => {
        if (featuresLoaded && !isSeasonClosed && activeRoundId) {
            getPredictions(activeRoundId);
        }
    }, [activeRoundId, getPredictions, featuresLoaded, isSeasonClosed]);

    if (featuresLoaded && isSeasonClosed) {
        return <Redirect to="/" />;
    }

    if (
        !featuresLoaded ||
        !pundits ||
        !pundits.predictions ||
        pundits.isLoading ||
        !activeRoundId
    ) {
        return (
            <PageContainer>
                <PageTitle>Pundits Predictions</PageTitle>
                <Styled.LoadingContainer>
                    <Spinner colour="black" />
                    <span>Loading...</span>
                </Styled.LoadingContainer>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageTitle>Pundits Predictions</PageTitle>
            {pundits.predictions.length < 1 && (
                <Styled.AlertContainer>
                    <Alert type="Warning">
                        Our pundits haven&apos;t made their predictions yet,
                        please check back later.
                    </Alert>
                </Styled.AlertContainer>
            )}
            {pundits.predictions.length >= 1 && (
                <Styled.PunditsContainer>
                    <PunditPredictionsList
                        pundits={pundits.predictions}
                        scoreChallenges={scoreChallenges}
                    />
                </Styled.PunditsContainer>
            )}
        </PageContainer>
    );
};

Pundits.propTypes = {
    loadActiveRound: PropTypes.func.isRequired,
    activeRoundId: PropTypes.number,
    pundits: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        predictions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                imageUri: PropTypes.string.isRequired,
                comment: PropTypes.string,
                predictions: PropTypes.shape({
                    score: PropTypes.arrayOf(
                        PropTypes.shape({
                            challengeId: PropTypes.number.isRequired,
                            scoreHome: PropTypes.number.isRequired,
                            scoreAway: PropTypes.number.isRequired
                        })
                    )
                }),
                type: PropTypes.string,
                typeImageUri: PropTypes.string,
                typeLink: PropTypes.string,
                description: PropTypes.string
            })
        )
    }),
    scoreChallenges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ),
    getPredictions: PropTypes.func.isRequired,
    isSeasonClosed: PropTypes.bool.isRequired,
    featuresLoaded: PropTypes.bool.isRequired
};

Pundits.defaultProps = {
    activeRoundId: null,
    pundits: null,
    scoreChallenges: []
};

const mapStateToProps = state => ({
    activeRoundId: selectActiveRoundId(state),
    pundits: selectPunditPredictions(state),
    scoreChallenges: selectActiveRoundScoreChallenges(state),
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false),
    featuresLoaded: areFeaturesLoaded(state)
});

const mapDispatchToProps = dispatch => ({
    getPredictions: roundId => dispatch(requestPunditPredictions(roundId)),
    loadActiveRound: () => dispatch(requestActiveRound())
});

export default connect(mapStateToProps, mapDispatchToProps)(Pundits);
