import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Image from '../../../Common/Image';

export const HeaderRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.spacing.medium};
    margin: 0 10px;
    position: relative;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 0 20px;
    }
`;

export const HeaderLogoContainer = styled(Link)`
    display: flex;
    width: 50%;
`;

export const HeaderLogo = styled(Image)`
    width: 110px;
    height: 98%;
    margin: auto;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: 0 auto;
        width: 190px;
    }
`;

export const BurgerNavIcon = styled.div`
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        display: none;
    }
    &::before {
        font-size: ${props => props.theme.fontSize.medium};
        font-family: skycons;
        content: '\f003';
        color: ${props => props.theme.colours.header.navLink};
        cursor: pointer;
    }
`;

export const CloseNavIcon = styled.div`
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        display: none;
    }
    &::before {
        font-size: ${props => props.theme.fontSize.medium};
        font-family: skycons;
        content: '\f115';
        color: ${props => props.theme.colours.header.navLink};
        cursor: pointer;
    }
`;

export const LeftIcons = styled.div`
    width: 25%;
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: ${props => props.theme.spacing.normal};
`;

export const RightIcons = styled.div`
    width: 25%;
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    gap: ${props => props.theme.spacing.normal};
`;
