import styled from '@emotion/styled/macro';

export const List = styled.ul`
    list-style: none;
    margin: 0;
`;

export const UnorderedList = styled.ul`
    list-style: disc;
    margin: 0;
`;

export const ListItem = styled.li`
    margin: 0 ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.medium};
    text-align: left;

    h2 {
        display: block;
        width: 100%;
        font-family: ${props => props.theme.fonts.medium};
        color: ${props => props.theme.colours.textTitle};
        font-size: ${props => props.theme.fontSize.large};
        margin: 0;
    }

    p {
        margin: 0 0 10px;
    }
`;

export const ToggleButton = styled.button`
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 10px;

    :hover {
        cursor: pointer;
    }

    :focus {
        outline: none;
    }

    i {
        float: right;
    }
`;

export const Link = styled.button`
    display: inline-block;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: inherit;
    font: inherit;

    :hover {
        cursor: pointer;
    }
`;

export const SubHeader = styled.span`
    display: block;
    font: inherit;
    font-family: ${props => props.theme.fonts.medium};
    margin: 10px 0 0;
`;

export const Content = styled.div`
    display: block;
    padding-right: 50px;
`;

export const BoldText = styled.span`
    font-family: ${props => props.theme.fonts.medium};
`;
