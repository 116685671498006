import * as types from './types';

export const addErrorMessage = (message, hops = 1) => ({
    type: types.ADD_MESSAGE,
    payload: { message, hops, type: 'error' }
});

export const addSuccessMessage = (message, hops = 1) => ({
    type: types.ADD_MESSAGE,
    payload: { message, hops, type: 'success' }
});

export const dismissMessage = id => ({
    type: types.DISMISS_MESSAGE,
    id
});

export const routeChange = () => ({
    type: types.ROUTE_CHANGE
});
