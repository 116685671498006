import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import {
    requestActiveRound,
    requestOutcomesForMarket
} from '../../../../store/modules/round/actions';
import Spinner from '../../../../components/Common/Spinner';
import Calculation from './components/Calculation';
import ConnectedSelections from './components/Selections';
import { requestBetslipOffer } from '../../../../store/modules/offer/actions';
import {
    fullTimeResult,
    bothTeamsToScore,
    underOverGoals,
    setDefaultSelections,
    onChangeSelection
} from '../../../../helpers/accumulator';
import GenericOffer from '../../../../components/Offer/GenericOffer';
import BetButton from './components/BetButton';
import MarketTabs from './components/MarketTabs';
import { selectActiveRoundValidAccaScoreChallenges } from '../../../../store/modules/round/selectors';
import { requestUsersActiveRoundPredictions } from '../../../../store/modules/user/actions';
import {
    sendBannerEvent,
    sendNavigationEvent
} from '../../../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../../../helpers/GoogleAnalytics/modules';

const desiredMarketOrder = [fullTimeResult, underOverGoals, bothTeamsToScore];

export const Acca = ({
    loadUserPredictions,
    loadActiveRound,
    offer,
    isOnDesktop,
    marketOutcomes,
    getBetslipOffer,
    getMarketOutcomes,
    predictions,
    fixtures
}) => {
    const defaultSelections = setDefaultSelections(fixtures, predictions);
    const [market, setMarket] = useState(fullTimeResult);
    const [selections, setSelections] = useState(defaultSelections);

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        // To differentiate between predictions summary and edit predictions pages as they have the same URL
        const moduleDisplayOrder = document.querySelector(
            'div[id="prediction-submitted"]'
        )
            ? 2
            : 4;
        sendNavigationEvent(
            modules.playedFlow.crossSellAccumulator,
            moduleDisplayOrder,
            text,
            position,
            destinationUrl,
            context
        );
    };

    const setBannerEvent = (text, action, position, url) => {
        sendBannerEvent(
            text,
            modules.playedFlow.crossSellAccumulator,
            action,
            position,
            url
        );
    };

    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    useEffect(() => {
        getBetslipOffer();
    }, [getBetslipOffer]);

    useEffect(() => {
        if (fixtures.length > 0 && predictions === null) {
            loadUserPredictions();
        }
    }, [loadUserPredictions, fixtures.length]);

    useEffect(() => {
        if (fixtures.length > 0) {
            getMarketOutcomes(fullTimeResult);
            getMarketOutcomes(underOverGoals);
            getMarketOutcomes(bothTeamsToScore);
        }
    }, [getMarketOutcomes, fixtures]);

    if (fixtures.length < 1 || !marketOutcomes) {
        return null;
    }

    const hasAtleastOneMarketWithData =
        (marketOutcomes[fullTimeResult] &&
            marketOutcomes[fullTimeResult].length) ||
        (marketOutcomes[underOverGoals] &&
            marketOutcomes[underOverGoals].length) ||
        (marketOutcomes[bothTeamsToScore] &&
            marketOutcomes[bothTeamsToScore].length);

    if (!hasAtleastOneMarketWithData) {
        return null;
    }

    /**
     * If we have no data for the market, automatically skip to the next one
     */
    if (marketOutcomes[market] && marketOutcomes[market].length === 0) {
        // Try selecting the next market
        setMarket(desiredMarketOrder[desiredMarketOrder.indexOf(market) + 1]);
    }

    return (
        <Styled.Container data-test-id="betting-container">
            <Styled.Header>
                <Styled.Title>Latest from </Styled.Title>
                <Styled.HeaderLogo src="/img/products/sky-bet.png?updated=2020-10-08" />
            </Styled.Header>
            <Styled.Body>
                <Styled.Content>
                    {!isOnDesktop && offer && (
                        <GenericOffer
                            offer={offer}
                            setBannerEvent={setBannerEvent}
                        />
                    )}
                    <MarketTabs
                        setMarket={setMarket}
                        market={market}
                        marketOutcomes={marketOutcomes}
                        desiredMarketOrder={desiredMarketOrder}
                    />
                    {!marketOutcomes[market] && <Spinner colour="black" />}
                    {marketOutcomes[market] && (
                        <>
                            <Styled.AccaContainer data-test-id="acca-container">
                                <Styled.PredictionsContainer>
                                    <ConnectedSelections
                                        fixtures={fixtures}
                                        marketOutcomes={marketOutcomes[market]}
                                        predictions={predictions}
                                        market={market}
                                        selections={selections[market]}
                                        onChangeSelection={onChangeSelection(
                                            market,
                                            selections,
                                            setSelections
                                        )}
                                    />
                                </Styled.PredictionsContainer>
                                <Styled.MarketsContainer data-test-id="acca-market">
                                    <Calculation
                                        market={market}
                                        marketOutcomes={marketOutcomes[market]}
                                        selections={selections[market]}
                                        setNavigationEvent={setNavigationEvent}
                                        setBannerEvent={setBannerEvent}
                                    />
                                </Styled.MarketsContainer>
                            </Styled.AccaContainer>
                            {!isOnDesktop && (
                                <Styled.BetButtonContainer>
                                    <BetButton
                                        market={market}
                                        selections={selections[market]}
                                        marketOutcomes={marketOutcomes[market]}
                                    />
                                </Styled.BetButtonContainer>
                            )}
                        </>
                    )}
                </Styled.Content>
            </Styled.Body>
        </Styled.Container>
    );
};

Acca.propTypes = {
    loadUserPredictions: PropTypes.func.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    isOnDesktop: PropTypes.bool.isRequired,
    offer: PropTypes.shape({
        linkUri: PropTypes.string,
        desktopImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        mobileImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        })
    }),
    marketOutcomes: PropTypes.shape({
        [fullTimeResult]: PropTypes.array,
        [bothTeamsToScore]: PropTypes.array,
        [underOverGoals]: PropTypes.array
    }),
    getBetslipOffer: PropTypes.func.isRequired,
    getMarketOutcomes: PropTypes.func.isRequired,
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                id: PropTypes.number,
                homeTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ).isRequired
};

Acca.defaultProps = {
    marketOutcomes: null,
    offer: null
};

const mapStateToProps = state => ({
    offer: state.offer.betslipOffer,
    isOnDesktop: state.user.isOnDesktop,
    marketOutcomes: state.round.marketOutcomes,
    fixtures: selectActiveRoundValidAccaScoreChallenges(state),
    predictions: state.play.predictions
});

const mapDispatchToProps = dispatch => ({
    getBetslipOffer: () => dispatch(requestBetslipOffer()),
    getMarketOutcomes: market => dispatch(requestOutcomesForMarket(market)),
    loadActiveRound: () => dispatch(requestActiveRound()),
    loadUserPredictions: () => dispatch(requestUsersActiveRoundPredictions())
});

export default connect(mapStateToProps, mapDispatchToProps)(Acca);
