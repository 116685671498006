import styled from '@emotion/styled/macro';

export const GreenTick = styled.div`
    background: url('/content/img/icons/green-tick.svg');
    width: ${props => props.size ?? props.theme.spacing.large};
    height: ${props => props.size ?? props.theme.spacing.large};
    background-repeat: no-repeat;
`;

export const PinkCross = styled.div`
    background: url('/content/img/icons/pink-cross.svg');
    background-repeat: no-repeat;
    width: ${props => props.theme.spacing.large};
    height: ${props => props.theme.spacing.large};
`;

/* eslint-disable import/no-unused-modules */
export const Tick = styled.span`
    &::before {
        font-size: ${props => props.theme.fontSize.xxxsmall};
        font-family: ${props => props.theme.fonts.skycons};
        content: '\f114';
        color: ${props => props.theme.colours.white};
    }
    background: ${props => props.theme.colours.greenPrimary};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Cross = styled.div`
    &::before {
        font-size: ${props => props.theme.fontSize.xxxsmall};
        font-family: ${props => props.theme.fonts.skycons};
        content: '\f115';
        color: ${props => props.theme.colours.white};
    }
    background: ${props => props.theme.colours.red};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
