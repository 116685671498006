import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const WinnerNoticeContainer = styled.div`
    display: grid;
    row-gap: ${props => props.theme.spacing.xsmall};
    border-radius: ${props => props.theme.spacing.vlarge};
    background-color: ${props => props.theme.colours.turboYellow};
`;

export const WinnerNoticeHeader = styled.h1`
    text-align: center;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colours.colorOnPrimary};
    padding-top: ${props => props.theme.spacing.large};
    padding-bottom: ${props => props.theme.spacing.medium};
`;

export const WinnerNoticeBodyText = styled.p`
    text-align: center;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colours.colorOnPrimary};
    padding-left: ${props => props.theme.spacing.xlarge2};
    padding-right: ${props => props.theme.spacing.xlarge2};
    padding-bottom: ${props => props.theme.spacing.large};
`;

export const WinnerNoticeLink = styled(Link, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    text-decoration: none;
`;
