import styled from '@emotion/styled/macro';
import AppLinkButton from '../../../../../../components/Common/AppLink/styles';
import Image from '../../../../../../components/Common/Image';

export const BetsContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    border-radius: 20px;
    text-align: left;
`;

export const NoBets = styled.div`
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing.xlarge};
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
    letter-spacing: 0.08em;
`;

export const OutcomesContainer = styled.div`
    padding:  ${props => props.theme.spacing.xlarge} ${props =>
    props.theme.spacing.xxlarge};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props => props.theme.spacing.xlarge} 0 0;
    });
`;

export const OutcomeContainer = styled.div`
    color: ${props => props.theme.colours.black};
    display: flex;
    align-items: baseline;
    margin: 0 ${props => props.theme.spacing.medium};
    padding: ${props => props.theme.spacing.medium} 0;
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};

    > div {
        padding: ${props => props.theme.spacing.medium} 0;
        max-height: 100%;
        overflow: hidden;
        :first-of-type {
            flex: 25% 1 1;
        }

        :nth-of-type(2) {
            flex: 25% 1 1;
        }

        :last-of-type {
            padding: 0;
            flex: 20% 0 1;
        }
    }
`;

export const Description = styled.div`
    color: ${props => props.theme.colours.blueDark};
    font-size: ${props => props.theme.fontSize.small};
    font-family: ${props => props.theme.fonts.medium};
    letter-spacing: 0.08em;
    text-align: center;
`;

export const OddsLink = styled.div`
    a,
    ${AppLinkButton} {
        display: block;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        border: solid 1px ${props => props.theme.colours.borderMediumGrey};
        font-family: ${props => props.theme.fonts.medium};
        padding: 2px 0;
        font-size: ${props => props.theme.fontSize.normal};
        letter-spacing: 0.08em;
        text-align: center;
        color: ${props => props.theme.colours.red};
        text-decoration: none;
        line-height: ${props => props.theme.lineHeight.normal};
    }
`;

export const MoreInfo = styled.div`
    a,
    ${AppLinkButton} {
        display: block;
        font-family: ${props => props.theme.fonts.medium};
        margin: ${props => props.theme.spacing.medium} 0;
        padding: ${props => props.theme.spacing.large}
            ${props => props.theme.spacing.medium};
        font-size: ${props => props.theme.fontSize.small};
        letter-spacing: 0.08em;
        text-decoration: none;
        color: ${props => props.theme.colours.black};

        &:hover {
            color: ${props => props.theme.colours.linkBlue};
        }
    }
    ,
    u {
        color: ${props => props.theme.colours.textPrimary};
    }
`;

export const BetIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const BetIcon = styled(Image)`
    max-width: ${props => props.iconmaxwidth};
    margin-bottom: ${props => props.theme.spacing.medium};
`;
