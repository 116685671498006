import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    requestLeaderboardForSelf,
    requestPunditLeaderboard
} from '../../../store/modules/leaderboard/actions';
import {
    selectLeaderboard,
    selectSelfLeaderboard
} from '../../../store/modules/leaderboard/selectors';
import Alert from '../../../components/Common/Alert';
import ConnectedLeaderboardTypeNavigation from '../components/LeaderboardTypeNavigation';
import ConnectedScoreTable from '../containers/ScoreTable';
import { mergeUserResults } from '../../../helpers/leaderboardHelper';

export const Pundits = ({
    period,
    periodId,
    userId,
    selfLeaderboard,
    getLeaderboardForSelf,
    leaderboard,
    getPunditsLeaderboard
}) => {
    useEffect(() => {
        if (leaderboard) {
            return;
        }

        getPunditsLeaderboard(period, periodId);
    }, [period, periodId, leaderboard, getPunditsLeaderboard]);

    useEffect(() => {
        if (!userId || selfLeaderboard) {
            return;
        }

        getLeaderboardForSelf(period, periodId);
    }, [userId, getLeaderboardForSelf, period, periodId, selfLeaderboard]);

    if (!leaderboard || leaderboard.results.length < 1) {
        return (
            <>
                <ConnectedLeaderboardTypeNavigation
                    title="Pundits"
                    prev="following"
                    next="global"
                />
                <Alert type="Warning">
                    This table is being calculated, please check back later
                </Alert>
            </>
        );
    }

    const results = leaderboard.results.slice();
    if (
        selfLeaderboard &&
        !results.find(result => result.userId === selfLeaderboard.userId)
    ) {
        mergeUserResults(results, selfLeaderboard);
    }

    const position =
        selfLeaderboard && selfLeaderboard.position > 0
            ? results.findIndex(result => result.userId === userId) + 1
            : null;

    return (
        <>
            {position}
            <ConnectedLeaderboardTypeNavigation
                title="Pundits"
                prev="following"
                next="global"
            />
            <ConnectedScoreTable
                showHeader
                rows={results}
                testId="leaderboard-table-pundits"
            />
        </>
    );
};

const mapStateToProps = (state, props) => ({
    userId: state.user.info.userId,
    selfLeaderboard: selectSelfLeaderboard(state, props.period, props.periodId),
    leaderboard: selectLeaderboard(
        state,
        props.period,
        props.periodId,
        'pundits'
    )
});

const mapDispatchToProps = dispatch => ({
    getPunditsLeaderboard: (period, id) => {
        dispatch(requestPunditLeaderboard(period, id));
    },
    getLeaderboardForSelf: (period, id) => {
        dispatch(requestLeaderboardForSelf(period, id));
    }
});

Pundits.propTypes = {
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    userId: PropTypes.number,
    selfLeaderboard: PropTypes.shape({
        userId: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.number,
        isWinner: PropTypes.bool.isRequired,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        points: PropTypes.number
    }),
    getLeaderboardForSelf: PropTypes.func.isRequired,
    leaderboard: PropTypes.shape({
        results: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                name: PropTypes.string,
                position: PropTypes.number,
                isWinner: PropTypes.bool.isRequired,
                correctResults: PropTypes.number,
                correctScores: PropTypes.number,
                points: PropTypes.number
            })
        ).isRequired
    }),
    getPunditsLeaderboard: PropTypes.func.isRequired
};

Pundits.defaultProps = {
    periodId: null,
    userId: null,
    selfLeaderboard: null,
    leaderboard: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Pundits);
