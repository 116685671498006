import styled from '@emotion/styled/macro';

export const MatchStats = styled.div`
    color: ${props => props.theme.colours.textGrey};
`;

export const StatsTitle = styled.h3`
    text-align: center;

    margin-bottom: ${props => props.theme.spacing.vlarge};

    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.colours.textGrey};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.normal};
    }
`;

export const MatchHistory = styled.div`
    display: flex;
    align-items: center;

    padding-bottom: ${props =>
        props.bottomDivider ? props.theme.spacing.small : '0'};

    margin: ${props => props.theme.spacing.small} 0
        ${props => (props.bottomDivider ? props.theme.spacing.small : '0')};

    border-bottom: ${props =>
        props.bottomDivider
            ? `3px solid ${props.theme.match?.tabsDivider}`
            : '0'};
`;

export const MatchHistoryList = styled.div`
    display: flex;
    align-items: center;

    width: 100%;

    border-left: 4px solid ${props => props.theme.match?.tabsDivider};
    padding-left: ${props => props.theme.spacing.normal};
`;

export const MatchHistoryItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 20%;
    line-height: 22px;
    padding: ${props => props.theme.spacing.normal}
        ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.xsmall};

    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.colours.textGrey};

    background: ${props =>
        props.greyBackground
            ? props.theme.colours.backgroundGrey
            : 'transparent'};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props => props.theme.spacing.small}
            ${props => props.theme.spacing.xsmall}
            ${props => props.theme.spacing.xsmall};
    }
`;

export const MatchHistoryBadge = styled.img`
    max-width: ${props => props.width};
    margin-bottom: ${props => props.theme.spacing.small};
`;

export const MatchHistoryDate = styled.div`
    font-weight: light;
    margin-bottom: ${props => props.theme.spacing.small};

    font-size: ${props => props.theme.fontSize.normal};

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.xsmall};
    }
`;

export const MatchHistoryOutcome = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${props => props.theme.fontSize.small};
    font-weight: 700;
    text-transform: uppercase;
    width: ${props => (props.outcome === 'd' ? `28px` : '30px')};
    height: ${props => (props.outcome === 'd' ? `28px` : '30px')};
    border-radius: 50%;
    margin-bottom: ${props => props.theme.spacing.small};

    border: ${props =>
        props.outcome === 'd'
            ? `1px solid ${props.theme.match?.drawingBorder}`
            : '0'};

    background: ${props => {
        const { outcome } = props;

        if (outcome === 'w') {
            return props.theme.match?.winningGreen;
        }

        if (outcome === 'l') {
            return props.theme.match?.losingRed;
        }

        return props.theme.match?.drawingGrey;
    }};

    color: ${props => {
        const { outcome } = props;

        if (outcome === 'w' || outcome === 'd') {
            return props.theme.colours.textGrey;
        }

        return props.theme.colours.white;
    }};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        width: ${props => (props.outcome === 'd' ? '23px' : '25px')};
        height: ${props => (props.outcome === 'd' ? '23px' : '25px')};

        font-size: ${props => props.theme.fontSize.small};
    }
`;

export const MatchHistoryHomeAway = styled.div`
    line-height: 22px;
    margin-bottom: ${props => props.theme.spacing.small};
`;

export const MatchHistoryScore = styled.div`
    line-height: 22px;
`;

export const MatchHistoryHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
    width: 25%;
    min-width: 50px;
    margin-right: ${props => props.theme.spacing.normal};

    color: ${props => props.theme.colours.textGrey};
`;

export const MatchHistoryHeaderTeamName = styled.p`
    font-size: ${props => props.theme.fontSize.normal};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 100%;
`;
