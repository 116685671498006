import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrizesMasthead from './components/Masthead/PrizesMasthead';
import { PageContainer } from '../../components/Common/Page/styles';
import { requestRoundPredictionsByUser } from '../../store/modules/results/actions';
import { getRoundPredictionsByUser } from '../../store/modules/results/selectors';
import { selectRoundStatusById } from '../../store/modules/round/selectors';
import * as Styled from './styles';
import PrizeWinners from './components/PrizeWinners';
import WinnerNotice from './components/WinnerNotice';

const PUNDIT_ID = 'head-to-head-opponent';

const Prizes = ({
    punditPredictions,
    getPunditPredictions,
    userId,
    headToHead,
    roundId,
    roundStatus
}) => {
    useEffect(() => {
        if (!punditPredictions && roundId) {
            getPunditPredictions(roundId);
        }
    }, [roundId, punditPredictions, getPunditPredictions]);

    const winners =
        roundStatus === 'complete' &&
        headToHead.winners &&
        headToHead.winners.find(winner => winner.userId === userId);

    const didUserWin = !!winners;

    return (
        <PageContainer padding="8px">
            <Styled.ReducedWidthDesktopContainer>
                <PrizesMasthead
                    punditPredictions={punditPredictions}
                    beatPunditPercentage={headToHead.beatPunditPercentage}
                    opponent={headToHead.opponent}
                    didUserWin={didUserWin}
                    prizes={headToHead.prizes}
                    roundId={roundId}
                    roundStatus={roundStatus}
                />
                {didUserWin && <WinnerNotice link="/terms" />}
            </Styled.ReducedWidthDesktopContainer>
            <PrizeWinners
                headToHead={headToHead}
                userId={userId}
                roundId={roundId}
            />
        </PageContainer>
    );
};

Prizes.propTypes = {
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    getPunditPredictions: PropTypes.func.isRequired,
    userId: PropTypes.number,
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }).isRequired,
    roundId: PropTypes.number.isRequired,
    roundStatus: PropTypes.string.isRequired
};

Prizes.defaultProps = {
    userId: null,
    punditPredictions: null
};

const mapStateToProps = (state, props) => ({
    userId: state.user.info.userId,
    punditPredictions: getRoundPredictionsByUser(
        state,
        props.roundId,
        PUNDIT_ID
    ),
    roundStatus: selectRoundStatusById(state, props.roundId)
});

const mapDispatchToProps = dispatch => ({
    getPunditPredictions: roundId =>
        dispatch(requestRoundPredictionsByUser(roundId, PUNDIT_ID))
});

export default connect(mapStateToProps, mapDispatchToProps)(Prizes);
