/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled/macro';

export const FollowButton = styled.button`
    background: transparent;
    border: none;

    ${props =>
        props.isMasked &&
        `
        display: none
    `}
`;
