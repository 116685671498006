import React from 'react';
import * as Styled from '../style';

export default function index() {
    return (
        <Styled.WinnerNoticeContainer>
            <Styled.WinnerNoticeHeader>
                What happens next?
            </Styled.WinnerNoticeHeader>
            <Styled.WinnerNoticeBodyText>
                We will be in touch via email after final checks are completed!
            </Styled.WinnerNoticeBodyText>
        </Styled.WinnerNoticeContainer>
    );
}
