/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../../../Modals';
import * as Styled from './styles';
import { accountRedirect } from '../../../../store/modules/user/actions';
import Spinner from '../../../Common/Spinner';
import { requestLogin, logout } from '../../../../store/modules/auth/actions';
import { selectIsLoggedIn } from '../../../../store/modules/auth/selectors';
import {
    showModal as showModalAction,
    dismissModal as dismissModalAction
} from '../../../../store/modules/modal/actions';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

export const Account = ({
    isLoggedIn,
    doLogin,
    goToAccount,
    firstName,
    logOut,
    showModal,
    dismissModal,
    setNavigationEvent
}) => {
    const closeModal = () => {
        dismissModal();
    };

    const onLogOutClick = () => {
        closeModal();
        logOut();
    };

    const onForgotClick = () => {
        closeModal();
        window.Android.hardLogout();
        logOut(false);
    };

    const onAccountClick = () => {
        closeModal();
        goToAccount();
    };

    const openModal = () => {
        showModal({
            onDismiss: closeModal,
            onAccountClick,
            onLogOutClick,
            onForgotClick
        });
    };

    const onClick = event => {
        event.preventDefault();
        setNavigationEvent(
            elementText.navigation.myAccount,
            4,
            'https://account.skybetservices.com/?client_id=super6&auth_id=super6web&open=account'
        );
        if (window.Android) {
            openModal();
        } else {
            goToAccount();
        }
    };

    const loginRegisterButtonName = 'Login / Register';

    if (!isLoggedIn) {
        return (
            <Styled.AccountLink
                aria-label="login or register"
                id="account-bar-login-btn"
                data-test-id="account-bar-login"
                href="#"
                onClick={event => {
                    event.preventDefault();
                    doLogin();
                    setNavigationEvent(
                        loginRegisterButtonName,
                        4,
                        'https://auth.skybetservices.com/login?'
                    );
                }}
            >
                <span>{loginRegisterButtonName}</span>
            </Styled.AccountLink>
        );
    }

    if (firstName === null) {
        return (
            <Styled.AccountLink
                id="account-bar-my-account-btn"
                data-test-id="account-bar-my-account"
                href="#"
                onClick={onClick}
            >
                <Spinner />
            </Styled.AccountLink>
        );
    }

    return (
        <div>
            <Styled.AccountLink
                href="#"
                id="account-bar-my-account-btn"
                data-test-id="account-bar-my-account"
                onClick={onClick}
            />
            {window.Android && (
                <Modal
                    type="PROFILE"
                    props={{
                        onDismiss: closeModal,
                        onAccountClick,
                        onLogOutClick,
                        onForgotClick
                    }}
                    dismiss={() => {}}
                />
            )}
        </div>
    );
};

Account.propTypes = {
    isLoggedIn: PropTypes.bool,
    doLogin: PropTypes.func.isRequired,
    goToAccount: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    dismissModal: PropTypes.func.isRequired,
    firstName: PropTypes.string,
    logOut: PropTypes.func.isRequired,
    setNavigationEvent: PropTypes.any
};

Account.defaultProps = {
    isLoggedIn: false,
    firstName: null,
    setNavigationEvent: () => {}
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    firstName: state.user.info ? state.user.info.firstName : null
});

const mapDispatchToProps = dispatch => ({
    showModal: props => dispatch(showModalAction('PROFILE', props, false)),
    dismissModal: () => dispatch(dismissModalAction()),
    doLogin: () => dispatch(requestLogin()),
    goToAccount: () => dispatch(accountRedirect()),
    logOut: (shouldSoftLogout = true) =>
        dispatch(logout(true, shouldSoftLogout))
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
