import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Icon, PageContainer } from '../../components/Common/Page/styles';

export const ToastContainer = styled.div`
    text-align: center;
`;

export const NavigationDirection = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: ${props => props.align || 'left'};

    ${Icon} {
        ${props => props.align || 'left'}: 0;
    }
`;

export const PeriodIdSelectorContainer = styled.div`
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const ScoreTable = styled.table`
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.normal};
    margin-top: ${props => props.theme.spacing.normal};
`;

export const ResultRow = styled.tr`
    border: inherit;
    border-bottom: 1px solid #ddd;
    font-weight: normal;
    font-size: ${props => props.theme.fontSize.medium};
    min-height: 56px;
`;

export const TableHeader = styled.thead`
    background-color: ${props => props.theme.colours.backgroundGrey};
    font-size: ${props => props.theme.fontSize.xsmall};
    color: ${props => props.theme.colours.textTitle};
`;

export const TableHeaderColumn = styled.th`
    box-sizing: border-box;
    padding: ${props => props.theme.spacing.normal};
    font-family: ${props => props.theme.fonts.medium};

    text-align: ${props => (props.center ? 'center' : 'left')};

    ${props =>
        props.showOnSmall &&
        `
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: none;
        }    
    `}

    ${props =>
        props.hideOnSmall &&
        `
        display: none;
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: table-cell;
        }    
    `}
`;

export const TableBodyColumn = styled.td`
    text-align: ${props => (props.center ? 'center' : 'left')};
    padding: ${props => (props.nopad ? '0' : props.theme.spacing.normal)};

    ${props =>
        props.showOnSmall &&
        `
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: none;
        }    
    `}

    ${props =>
        props.hideOnSmall &&
        `
        display: none;
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: table-cell;
        }    
    `}
`;

export const TableLink = styled(Link)`
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.linkBlue};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: normal;

    &:hover {
        color: ${props => props.theme.colours.linkBlueHover};
    }
`;

export const TableSubtleLink = styled(Link)`
    font-family: ${props => props.theme.fonts.regular};
    color: inherit;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.colours.linkBlueHover};
    }
`;

export const SocialContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const SharePositionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 300px;
    padding-right: ${props => props.theme.spacing.medium};

    > span {
        line-height: ${props => props.theme.lineHeight.normal};
        flex: 50% 0 0;
    }
    > div {
        flex: 50% 0 0;

        > div {
            padding: 0;
        }
    }
`;

export const Clear = styled.div`
    clear: both;
`;

export const LeaderboardTypeNavigationContainer = styled.div`
    background: linear-gradient(
        to bottom,
        ${props => props.theme.colours.header.gradient.from},
        ${props => props.theme.colours.header.gradient.to}
    );
    border: 1px solid ${props => props.theme.colours.header.gradient.to};
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
    margin: ${props => props.theme.spacing.normal} 0;
    padding: ${props => props.theme.spacing.normal};
`;

export const LeaderboardTypeHeader = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
`;

export const LeaderboardTypeNavigationButton = styled(Link)`
    display: block;
    text-decoration: none;
    color: ${props => props.theme.colours.linkBlue};
    width: 100%;

    &:hover {
        color: ${props => props.theme.colours.linkBlueHover};
    }
`;

export const SearchContainer = styled.div`
    position: relative;
    margin-bottom: ${props => props.theme.spacing.normal};
`;

export const SearchField = styled.input`
    display: block;
    height: 46px;
    padding: ${props =>
        `
        ${props.theme.spacing.normal} 
        56px
        ${props.theme.spacing.normal}
        ${props.theme.spacing.medium}
    `};
    box-sizing: border-box;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    color: ${props => props.theme.colours.textGrey};
    background-color: ${props => props.theme.colours.white};
    background-image: none;
    border: ${props =>
        props.hasActiveSearch
            ? `2px solid ${props.theme.colours.greenPrimary}`
            : `1px solid ${props.theme.colours.borderGrey}`};
    border-radius: ${props => props.theme.card.borderRadius};
    width: 100%;
    box-shadow: none;
`;

export const SearchButton = styled.button`
    padding: ${props => props.theme.spacing.medium};
    border: 0;
    background: ${props => props.theme.colours.white};
    position: absolute;
    top: 3px;
    right: 12px;

    :hover {
        cursor: pointer;
    }
`;

export const ClearButton = styled(SearchButton)`
    padding: ${props => props.theme.spacing.medium};
    border: 0;
    background: ${props => props.theme.colours.white};
    position: absolute;
    top: 3px;
    right: 41px;

    :hover {
        cursor: pointer;
    }
`;

export const FollowButton = styled.button`
    background: white;
    border: none;
    padding: 10px;

    &:focus {
        outline: none;
    }

    :hover {
        cursor: pointer;
    }
`;

export const MaskedContent = styled.span`
    font-family: ${props => props.theme.fonts.italic};
    line-height: 39px;
`;

export const LeaderboardContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.card.borderRadius};
    padding-top: ${props => `${props.theme.spacing.vlarge}`};
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.medium};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: ${props => `0 ${props.theme.spacing.medium}`};
    }
`;

export const LeaderboardPageContainer = styled(PageContainer)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
    ${props => (props.isUsingIOS ? 'padding-top: 20px' : '')}
`;

export const ParagraphContainer = styled(PageContainer)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
`;

export const LeagueInfoParagraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: ${props => props.theme.spacing.medium};
`;

export const Container = styled(PageContainer)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
    padding: ${props => props.theme.spacing.large};
`;

export const Title = styled.div`
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 800;
    text-decoration: none;
    color: ${props => props.theme.tabs.color};
    line-height: 25px;
    padding-bottom: ${props => props.theme.spacing.large};
`;

export const Footer = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.medium};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: ${props => props.theme.spacing.medium};
`;

export const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const BodyItemSmall = styled.div`
    flex: 30%;
    text-align: right;
`;

export const BodyItemBig = styled.div`
    min-height: 20px;
    flex: 70%;
`;

export const Paragraph = styled(PageContainer)`
    display: inline-block;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: ${props => `${props.theme.spacing.medium}`};
        display: inline-block;
    }
`;

export const BodyItemLeftSide = styled.span``;

export const BodyItemRightSide = styled.span`
    font-weight: 700;
    margin-left: 3px;
`;

export const TextLink = styled.a`
    margin-left: -6px;
    margin-right: -6px;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin-left: 3px;
        margin-right: 3px;
    }
`;

export const Share = styled.div`
    ${props => (props.isApp ? `flex: 100% 0 0;` : `flex: 60% 0 0;`)}
`;

export const Invite = styled.p`
    flex: 40% 0 0;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: space-between;
    justify-content: center;
`;

export const LeagueButtonWrapper = styled.div`
    flex-shrink: 0;
    width: 49%;
    &:first-child {
        margin-right: ${props => `${props.theme.spacing.small}`};
    }
    &:nth-child(2) {
        margin-left: ${props => `${props.theme.spacing.small}`};
    }
    &:only-child {
        width: 100%;
    }
`;
