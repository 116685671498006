import React, { useState } from 'react';

import * as Styled from './styles';
import ConnectedHeaderMain from './components/HeaderMain';
import ConnectedNav from './components/Nav';
import modules from '../../helpers/GoogleAnalytics/modules';
import {
    sendNavigationEvent,
    sendInterfaceEvent
} from '../../helpers/GoogleAnalytics/gaHelper';

const Header = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const openMobileNav = () => {
        document.body.style.overflowY = 'hidden';
        setMobileNavOpen(true);
    };

    const closeMobileNav = () => {
        document.body.style.overflowY = 'auto';
        setMobileNavOpen(false);
    };

    const toggleMobileNav = () => {
        if (mobileNavOpen) {
            return closeMobileNav();
        }
        return openMobileNav();
    };

    const setInterfaceEvent = (text, action) => {
        sendInterfaceEvent(text, action, modules.homepage.header);
    };

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.homepage.header,
            'null',
            text,
            position,
            destinationUrl,
            context
        );
    };

    return (
        <Styled.HeaderContainer>
            <Styled.Header>
                <ConnectedHeaderMain
                    toggleMobileNav={toggleMobileNav}
                    mobileNavOpen={mobileNavOpen}
                    setInterfaceEvent={setInterfaceEvent}
                    setNavigationEvent={setNavigationEvent}
                />
                <ConnectedNav
                    mobileNavOpen={mobileNavOpen}
                    closeMobileNav={closeMobileNav}
                    setNavigationEvent={setNavigationEvent}
                    setInterfaceEvent={setInterfaceEvent}
                />
            </Styled.Header>
        </Styled.HeaderContainer>
    );
};

export default Header;
