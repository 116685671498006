import { createSelector } from 'reselect';
import { getPredictionsForMatchIdProp } from '../play/selectors';
import marketOutcomeForScore from '../../../helpers/marketOutcomeForScore';

export const getRounds = state => state.round.rounds || null;
export const getRoundIdParam = (_, roundId) => roundId;
export const getUpcomingS6AndExtraRounds = state =>
    state.round.upcomingRounds || null;

export const selectUpcomingS6AndExtraRounds = createSelector(
    [getUpcomingS6AndExtraRounds],
    rounds => rounds
);

export const selectCurrentS6AndExtraRoundsOnly = createSelector(
    [getUpcomingS6AndExtraRounds],
    upcomingRounds => {
        if (upcomingRounds === null) {
            return null;
        }
        return upcomingRounds.filter(round => round.status !== 'future');
    }
);

export const selectOpenSuper6RoundId = createSelector(
    [getUpcomingS6AndExtraRounds],
    upcomingRounds => {
        if (upcomingRounds && upcomingRounds.length) {
            const openRound = upcomingRounds.find(
                round => round.roundType === 'super6' && round.status === 'open'
            );
            if (openRound) {
                return openRound.id;
            }
        }
        return null;
    }
);

export const selectIsLoadingUpcomingRounds = state =>
    state.round.isLoadingUpcomingRounds;

export const selectRoundStatusById = createSelector(
    [getRounds, getRoundIdParam],
    (rounds, roundId) => {
        if (!rounds) {
            return null;
        }

        const round = rounds.find(item => item.id === roundId);
        if (!round) {
            return null;
        }

        return round.status;
    }
);

export const selectIsRoundOpenById = createSelector(
    [selectRoundStatusById],
    roundStatus => roundStatus === 'open'
);

export const selectIsRoundInplayById = createSelector(
    [selectRoundStatusById],
    roundStatus => roundStatus === 'inplay'
);

export const selectNonOpenOrFutureRoundMonths = createSelector(
    [getRounds],
    rounds => {
        if (rounds === null) {
            return null;
        }
        return [
            ...new Set(
                rounds
                    .filter(round => !['open', 'future'].includes(round.status))
                    .map(round => new Date(round.endDateTime).getMonth() + 1)
            )
        ];
    }
);

export const selectNonOpenOrFutureRoundIds = createSelector(
    [getRounds],
    rounds => {
        if (rounds === null) {
            return null;
        }
        return rounds
            .filter(round => !['open', 'future'].includes(round.status))
            .map(round => round.id);
    }
);

export const selectNonFutureRounds = createSelector([getRounds], rounds => {
    if (rounds === null) {
        return [];
    }
    return rounds
        .filter(round => round.status !== 'future')
        .sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));
});

export const selectNonFutureRoundIds = createSelector(
    [selectNonFutureRounds],
    rounds => rounds.map(round => round.id)
);

const getLimit = (state, limit) => limit;

export const selectNextRounds = createSelector(
    [getRounds, getLimit],
    (rounds, limit) => {
        if (rounds === null) {
            return null;
        }
        return rounds
            .filter(round => ['open', 'future'].includes(round.status))
            .sort(
                (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)
            )
            .slice(0, limit);
    }
);

export const selectLatestCompletedRound = createSelector(
    [getRounds],
    rounds => {
        if (rounds === null) {
            return null;
        }
        return rounds
            .filter(round => round.status === 'complete')
            .sort(
                (a, b) => new Date(b.startDateTime) - new Date(a.startDateTime)
            )[0];
    }
);

export const selectLatestCompletedRoundId = createSelector(
    [selectLatestCompletedRound],
    round => (round ? round.id : null)
);

export const getStatsForMatch = (state, matchId) => state.round.stats[matchId];
export const getPunditsForMatch = (state, matchId) =>
    state.round.pundits[matchId];

export const selectActiveRound = state => state.round.activeRound;

export const selectActiveRoundId = createSelector(selectActiveRound, round =>
    round ? round.id : null
);
export const selectActiveRoundOpen = createSelector(selectActiveRound, round =>
    round ? round.status === 'open' : false
);
export const selectActiveRoundScoreChallenges = createSelector(
    selectActiveRound,
    round => (round ? round.scoreChallenges : null)
);
export const selectActiveRoundValidAccaScoreChallenges = createSelector(
    selectActiveRound,
    round => {
        if (!round || !round.scoreChallenges) {
            return [];
        }
        return round.scoreChallenges.filter(
            ({ void: isVoid, match }) => !isVoid && match.shortStatus === '' // Pre-live
        );
    }
);
export const selectActiveRoundGoldenGoalChallenge = createSelector(
    selectActiveRound,
    round => (round ? round.goldenGoalChallenge : null)
);

export const selectActiveRoundPrizeImage = createSelector(
    selectActiveRound,
    round =>
        round && round.assets && round.assets.prizeImageset
            ? round.assets.prizeImageset['2x']
            : null
);
export const selectActiveRoundStatus = createSelector(
    selectActiveRound,
    round => (round ? round.status : null)
);

export const selectIsRoundPaused = createSelector(selectActiveRound, round =>
    round ? round.isPaused : null
);

export const selectActiveRoundEndDate = createSelector(
    selectActiveRound,
    round => (round ? round.endDateTime : null)
);

export const selectActiveRoundSeason = createSelector(
    selectActiveRound,
    round => (round ? round.season : null)
);

export const getPunditPredictions = state => state.round.punditPredictions;

export const getBetsForMatch = (state, { matchId }) =>
    state.round.bets[matchId];

export const makeBetsForMatchSelector = () =>
    createSelector(
        [getPredictionsForMatchIdProp, getBetsForMatch],
        (predictions, bets) => {
            if (!bets || !predictions || !bets.data) {
                return null;
            }

            const requiredMarkets = [
                ...new Set(bets.data.map(outcome => outcome.name))
            ];

            return requiredMarkets.reduce((betsArray, marketName) => {
                const marketOutcomes = bets.data.find(
                    market => market.name === marketName
                ).outcomes;

                const outcomeToShow = marketOutcomeForScore(
                    marketName,
                    marketOutcomes,
                    predictions.scoreHome,
                    predictions.scoreAway
                );

                if (!outcomeToShow) {
                    return betsArray;
                }

                return [
                    ...betsArray,
                    {
                        name: marketName,
                        outcome: outcomeToShow
                    }
                ];
            }, []);
        }
    );

export const selectLatestNonOpenOrFutureRoundId = createSelector(
    [selectNonOpenOrFutureRoundIds],
    rounds => {
        if (!rounds) {
            return null;
        }
        return rounds[rounds.length - 1];
    }
);
export const selectLatestNonOpenOrFutureMonthId = createSelector(
    [selectNonOpenOrFutureRoundMonths],
    rounds => {
        if (!rounds) {
            return null;
        }
        return rounds[rounds.length - 1];
    }
);

export const selectHasLoadedRounds = createSelector(
    [getRounds],
    rounds => rounds != null
);
export const selectPunditPredictions = createSelector(
    [selectActiveRound, getPunditPredictions],
    (activeRound, predictions) => {
        if (
            predictions &&
            activeRound &&
            predictions.roundId === activeRound.id
        ) {
            return predictions;
        }

        return null;
    }
);

const selectHeadToHeads = state => state.round.headToHead || [];
const selectRoundIdProp = (state, roundId) => roundId;

export const selectHeadToHead = createSelector(
    [selectHeadToHeads, selectRoundIdProp],
    (headToHeads, roundId) => {
        if (!roundId) {
            return null;
        }

        const roundIdInt = parseInt(roundId, 10);

        if (!headToHeads[roundIdInt]) {
            return null;
        }

        return headToHeads[roundIdInt];
    }
);

export const selectLastCompletedHeadToHead = createSelector(
    [selectHeadToHeads, selectLatestCompletedRoundId],
    (headToHeads, lastCompletedRoundId) => {
        if (!lastCompletedRoundId) {
            return null;
        }

        const roundIdInt = parseInt(lastCompletedRoundId, 10);

        if (!headToHeads[roundIdInt]) {
            return null;
        }

        return headToHeads[roundIdInt];
    }
);

export const selectIsLoadedHeadToHead = (state, roundId) => {
    if (!roundId) {
        return false;
    }

    const roundIdInt = parseInt(roundId, 10);

    return !!state.round.loadedHeadToHead[roundIdInt];
};

export const selectAssetProp = (_, asset) => asset;
export const selectAssetSize = (_, __, size) => size;

export const selectAsset = createSelector(
    [selectActiveRound, selectAssetProp, selectAssetSize],
    (activeRound, asset, size) => activeRound && activeRound.assets[asset][size]
);

export const selectHasLoadedAcca = state => {
    const markets = Object.keys(state.round.isLoadingMarkets);
    if (markets.length < 1) {
        return true;
    }

    const loadedMarkets = Object.keys(state.round.hasLoadedMarket);

    if (loadedMarkets.length < 1) {
        return false;
    }

    return true;
};
