const colours = {
    colorPrimary: '#FFFFFF',
    colorOnPrimary: '#01298A',
    colorSecondary: '#01298A',
    colorOnSecondary: '#FFFFFF',
    colorTertiary: '#6DB85C',
    colorOnTertiary: '#FFFFFF',
    colorBackground: '#F7F6F7',
    colorBackgroundVariant: '#333333',
    colorError: '#FF0000',
    springGreen: '#07ef7b',
    electricViolet: '#8131ff',
    turboYellow: '#fee700',
    purplePizzazz: '#f700e0',
    aqua: '#00cfff',
    orangePizzazz: '#ff8c03',
    brightGray: '#EBEBEB',
    amaranth: '#e61e5c',
    silver: '#c4c4c4',
    gallery: '#EEEEEE',
    mediumGrey: '#ebebeb',
    blueHighlight: '#d7e0eb',
    darkBlueHighlight: '#dfe8f3',
    mineShaft: '#333333',
    smalt: '#002A91',
    casper: '#B4BFD3',
    doveGray: '#666666',
    guardsmanRed: '#CC0000'
};

export default {
    colours: {
        header: {
            gradient: {
                from: colours.colorPrimary,
                to: colours.colorPrimary
            },
            navLink: colours.colorSecondary,
            textColor: colours.colorSecondary
        },
        burgerNav: {
            background: colours.colorPrimary
        },
        moreNav: {
            background: colours.colorPrimary
        },
        black: colours.colorBackgroundVariant,
        black2: colours.colorBackgroundVariant,
        bluePrimary: colours.colorSecondary,
        blueDark: colours.colorSecondary,
        blueLeaderboard: colours.colorSecondary,
        greenPrimary: colours.springGreen,
        greenPrimaryBorder: colours.springGreen,
        colorOnPrimary: colours.colorOnPrimary,
        disabledButton: '#999',
        disabledButtonBorder: '#888',
        greenDark: colours.colorTertiary,
        greenDarkBorder: colours.colorTertiary,
        white: colours.colorPrimary,
        lightGrey: colours.colorBackground,
        brightGray: colours.brightGray,
        gold: '#ff9800',
        red: colours.colorError,
        redMedium: colours.colorError,
        redDark: colours.guardsmanRed,
        yellow: '#ffe600',
        turboYellow: colours.turboYellow,
        textPrimary: colours.colorOnPrimary,
        textGrey: colours.colorBackgroundVariant,
        lightTextGrey: colours.colorBackgroundVariant,
        textDark: colours.colorBackgroundVariant,
        textGold: '#70612a',
        textTitle: colours.colorOnPrimary,
        textDarkBlue: colours.colorSecondary,
        textDarkGrey: colours.colorBackgroundVariant,
        modalGrey: colours.colorBackgroundVariant,
        borderLightGrey: colours.gallery,
        borderMediumGrey: colours.casper,
        borderGrey: '#ebebeb',
        winnerGrey: colours.doveGray,
        greyHighlight: colours.colorBackground,
        darkThemeEmailBackground: colours.colorSecondary,
        borderDarkGrey: colours.colorBackground,
        bgGrey: colours.colorBackground,
        xSellBg: colours.colorBackgroundVariant,
        whatsapp: '#25d366',
        linkBlue: colours.colorSecondary,
        linkBlueBorder: colours.colorSecondary,
        linkBlueHover: '#286090',
        linkBlueHoverBorder: '#204d74',
        messenger: '#0084ff',
        facebook: '#3b5998',
        twitter: '#1da1f2',
        backgroundGrey: colours.colorBackground,
        backgroundRafBlue: colours.colorSecondary,
        skyBetBlue: colours.colorSecondary,
        accaBackground: colours.colorPrimary,
        accaBorder: colours.colorPrimary,
        separatorGrey: colours.colorBackground,
        heroTileRoundText: colours.colorSecondary,
        // Names from https://chir.ag/projects/name-that-color/
        deepKoamaru: colours.colorSecondary,
        wildSand: colours.colorBackground,
        mercury: colours.colorBackground,
        botticelli: colours.colorBackground,
        blackSqueeze: colours.colorBackground,
        blueZodiac: colours.colorSecondary,
        gallery: colours.gallery,
        alabaster: colours.colorBackground
    },
    gradients: {
        greyToWhite: `linear-gradient(to bottom, ${colours.colorBackground} 0%, ${colours.colorPrimary} 100%)`,
        loading: `linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 33%,
            rgba(255, 255, 255, 0.4) 67%,
            rgba(255, 255, 255, 0) 100%
        )`
    },
    componentColours: {
        results: {
            roundsListText: colours.colorBackgroundVariant,
            roundsListBackground: colours.colorPrimary,
            roundsListBorder: colours.mediumGrey,
            roundsListRowEvenBackground: colours.colorPrimary,
            roundsListRowOddBackground: colours.colorBackground,
            roundsListRowViewRoundEvenBackground: colours.blueHighlight,
            roundsListRowViewRoundOddBackground: colours.darkBlueHighlight,
            roundsListHeaderText: colours.colorSecondary
        },
        play: {
            background: colours.gallery
        },
        pageContainer: {
            background: colours.gallery
        },
        textInBox: {
            red: {
                background: colours.colorError,
                text: colours.colorPrimary
            },
            blue: {
                text: colours.colorOnPrimary
            },
            green: {
                background: colours.springGreen,
                text: colours.colorOnPrimary
            }
        }
    },
    headToHead: {
        greenBorder: colours.colorTertiary,
        opponentBorder: colours.colorSecondary,
        opponentPredictionBg: colours.colorSecondary,
        userWinnerBackground: colours.background,
        userWinnerPoints: colours.colorBackgroundVariant
    },
    lastRound: {
        borderGrey: colours.colorBackground
    },
    christmasCampaign: {
        upcomingBorder: colours.colorBackground,
        openBorder: colours.colorTertiary,
        winnerBorder: colours.colorTertiary,
        winnerDarker: colours.colorTertiary,
        separatorLight: colours.colorBackground,
        separatorDark: colours.colorBackgroundVariant,
        conicLight: colours.colorSecondary,
        conicDark: colours.colorSecondary,
        opponentImageSize: '150px',
        opponentSmallImageSize: '112px'
    },
    performanceHub: {
        button: {
            primary: colours.springGreen,
            primaryHover: colours.colorTertiary,
            secondary: colours.colorSecondary,
            secondaryHover: colours.colorTertiary
        }
    },
    skyBet: {
        fonts: {
            medium: '"Sky Medium", Arial, sans-serif'
        },
        colours: {
            white: '#ffffff',
            greenPrimary: '#04a04a',
            greenDark: '#006c1f'
        },
        fontSize: {
            large: '18px'
        },

        spacing: {
            large: '16px',
            xxlarge: '48px'
        },
        borderRadius: '4px'
    },
    spacing: {
        xsmall: '2px',
        small: '4px',
        normal: '8px',
        medium: '10px',
        large: '16px',
        vlarge: '20px',
        xlarge: '24px',
        xlarge2: '40px',
        xxlarge: '48px',
        huge: '80px'
    },
    radius: {
        xsmall: '5px',
        small: '10px',
        normal: '16px',
        large: '40px'
    },
    fonts: {
        regular: '"Sky Regular", Arial, sans-serif',
        medium: '"Sky Medium", Arial, sans-serif',
        italic: '"Sky Italic", Arial, sans-serif',
        skySportsBoldItalic: '"Sky Sports Bold Italic", Arial, sans-serif',
        bold: '"Sky Bold", Arial, sans-serif',
        skycons: 'skycons',
        skySportsPL: '"Sky Sports PL Medium", Arial, sans-serif',
        skySportsD: '"Sky Sports D Medium", Arial, sans-serif',
        skySportsDBold: '"Sky Sports D Bold", Arial, sans-serif',
        skySportsF: '"Sky Sports F Medium", Arial, sans-serif'
    },
    fontSize: {
        xxxsmall: '8px',
        xxsmall: '10px',
        xsmall: '12px',
        small: '13px',
        normal: '14px',
        medium: '16px',
        large: '18px',
        large2: '20px',
        xlarge: '22px',
        xlarge2: '26px',
        xxlarge: '30px',
        huge: '36px',
        vhuge: '60px',
        massive: '80px'
    },
    iconSize: {
        small: '9px',
        normal: '13px',
        large: '20px',
        xlarge: '36px',
        xxlarge: '40px',
        huge: '56px'
    },
    lineHeight: {
        small: 1,
        small2: 1.2,
        normal: 1.42857,
        medium: 1.8,
        large: 2.2
    },
    widths: {
        tabletMax: '1023px',
        desktopMin: '1024px',
        mobileMax: '576px',
        containerMaxWidth: '991px',
        smallMobile: '350px',
        largeMobile: '414px'
    },
    spinner: {
        spinnerSize: '2',
        spinnerSizeSmall: '2',
        spinnerSizeLarge: '3',
        spinnerAnimationSpeed: '0.8s',
        spinnerTransitionDelay: '0.8s'
    },
    button: {
        primary: {
            borderRadius: '60px',
            onHoverBgColour: '#06d970'
        },
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        fontWeight: '700',
        share: {
            onHoverBgColour: colours.colorPrimary
        }
    },
    card: {
        borderRadius: '20px',
        bgColour: colours.colorPrimary,
        border: 'none'
    },
    subHeader: {
        backgroundColour: 'none'
    },
    hero: {
        default: {
            colours: {
                background: colours.colorBackground,
                multiBackground: colours.colorBackground,
                font: colours.colorOnPrimary
            }
        },
        extra: {
            colours: {
                background: colours.colorSecondary,
                multiBackground: colours.colorSecondary,
                font: colours.colorOnSecondary
            }
        },
        loading: {
            colours: {
                background: colours.colorBackground,
                component: colours.colorBackground,
                imageBackground: colours.colorBackground,
                imageIcon: colours.colorBackgroundVariant
            }
        },
        widths: {
            tabletMax: '1023px',
            mobileMax: '720px',
            desktopMin: '721px',
            smallMobile: '350px',
            smallMobileMax: '319px'
        }
    },
    team: {
        imageSizes: {
            s: '50px',
            l: '83px',
            default: '65px'
        },
        teamNameColour: colours.colorOnPrimary
    },
    match: {
        tabsDivider: colours.mediumGrey,
        losingRed: colours.amaranth,
        winningGreen: colours.springGreen,
        drawingGrey: colours.mediumGrey,
        drawingBorder: colours.silver,
        colourIndex: {
            0: {
                background: colours.springGreen,
                text: colours.black
            },
            1: {
                background: colours.electricViolet,
                text: colours.colorPrimary
            },
            2: {
                background: colours.turboYellow,
                text: colours.black
            },
            3: {
                background: colours.purplePizzazz,
                text: colours.colorPrimary
            },
            4: {
                background: colours.aqua,
                text: colours.colorPrimary
            },
            5: {
                background: colours.orangePizzazz,
                text: colours.colorPrimary
            }
        }
    },
    scores: {
        points: {
            bgColour: colours.springGreen,
            text: colours.smalt
        },
        noPoints: {
            bgColour: colours.mineShaft,
            text: colours.colorPrimary
        },
        score: {
            bgColour: colours.smalt,
            text: colours.colorPrimary
        },
        text: colours.smalt,
        mainBgColour: colours.colorPrimary,
        secondaryBgColour: colours.colorBackground,
        border: colours.casper
    },
    tabs: {
        justify: 'center',
        textTransform: 'uppercase',
        color: colours.colorOnPrimary,
        font: '"Sky Sports D Medium", Arial, sans-serif',
        fontWeights: {
            active: '800',
            default: '400'
        }
    }
};
