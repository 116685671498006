import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BetButton from './BetButton';
import {
    calculateAccaDecimal,
    calculateAccaFraction,
    calculateBetReturn,
    getSelectedOutcomes
} from '../../../../../helpers/accumulator';
import * as Styled from '../styles';
import GenericOffer from '../../../../../components/Offer/GenericOffer';

const Calculation = ({
    market,
    isOnDesktop,
    offer,
    marketOutcomes,
    selections,
    setNavigationEvent,
    setBannerEvent
}) => {
    const [stake, setStake] = useState(10);
    const outcomes = getSelectedOutcomes(selections, marketOutcomes);

    if (outcomes.length === 0) {
        return null;
    }

    const decimal = calculateAccaDecimal(outcomes);
    const fraction = calculateAccaFraction(outcomes, decimal);
    const betReturn = calculateBetReturn(stake, decimal);

    const onStakeChange = e => {
        const parsed = parseFloat(e.target.value);
        if (!parsed.isNaN) {
            setStake(Math.abs(parsed));
        }
    };

    return (
        <>
            <Styled.AccumulatorTitle>
                Accumulator @{' '}
                <Styled.CalculationText id="acca-odds" data-test-id="acca-odds">
                    {fraction}
                </Styled.CalculationText>
            </Styled.AccumulatorTitle>
            <Styled.AccumulatorReturn>
                <strong>£</strong>
                <input
                    value={stake}
                    type="number"
                    min="0"
                    onChange={onStakeChange}
                />{' '}
                Returns:{' '}
                <Styled.CalculationText
                    id="acca-amount"
                    data-test-id="acca-returns"
                >
                    £{betReturn.toFixed(2)}
                </Styled.CalculationText>
            </Styled.AccumulatorReturn>
            <BetButton
                market={market}
                selections={selections}
                marketOutcomes={marketOutcomes}
                setNavigationEvent={setNavigationEvent}
            />
            {isOnDesktop && offer && (
                <Styled.OfferContainer>
                    <GenericOffer
                        offer={offer}
                        setBannerEvent={setBannerEvent}
                    />
                </Styled.OfferContainer>
            )}
        </>
    );
};

Calculation.propTypes = {
    market: PropTypes.string.isRequired,
    isOnDesktop: PropTypes.bool.isRequired,
    offer: PropTypes.shape({
        linkUri: PropTypes.string,
        desktopImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        mobileImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        })
    }),
    marketOutcomes: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired
        })
    ).isRequired,
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired,
            selection: PropTypes.string.isRequired
        })
    ).isRequired,
    setNavigationEvent: PropTypes.any,
    setBannerEvent: PropTypes.any
};

Calculation.defaultProps = {
    offer: null,
    setBannerEvent: () => {},
    setNavigationEvent: () => {}
};

const mapStateToProps = state => ({
    offer: state.offer.betslipOffer,
    isOnDesktop: state.user.isOnDesktop
});

export default connect(mapStateToProps, null)(Calculation);
