const modules = {
    homepage: {
        crossSell: 'cross sell bar',
        header: 'header',
        playForFree: 'hero card - home',
        banner: 'banner - head to head',
        TnCs: 'T&C - home',
        footer: 'footer'
    },
    playFlow: {
        enterPredictions: 'play flow - enter predictions',
        goldenGoal: 'play flow - golden goal',
        submitPredictions: 'play flow - submit predictions',
        h2hBanner: 'banner - head to head'
    },
    playedFlow: {
        viewPredictions: 'played flow - view predictions',
        splashOffer: 'banner - splash offer',
        editPredictions: 'played flow - edit predictions',
        submitPredictions: 'played flow - submit predictions',
        crossSellAccumulator: 'cross sell - accumulator'
    },
    leaderboard: {
        table: 'leaderboard - table',
        sharePosition: 'share - my position',
        banner: 'banner  - bottom'
    },
    results: {
        table: 'table  - leaderboard'
    },
    h2h: 'head to head',
    leagues: {
        createOrJoin: 'league - create or join',
        currentLeagues: 'league - current leagues',
        pastLeagues: 'league - past leagues',
        create: 'league - create a league',
        created: 'league - created',
        join: 'league - join a league',
        results: 'league - table results'
    },
    feedback: 'feedback '
};

export default modules;
