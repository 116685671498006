import styled from '@emotion/styled/macro';

export const PointsLabel = styled.div`
    width: 45px;
    height: 14px;
    background: ${props =>
        props.points === '0'
            ? `${props.theme.colours.black}`
            : `${props.theme.colours.greenPrimary}`};
    margin: 0 12px 9px 12px;
    position: relative;
    align-self: flex-end;

    :before {
        content: '';
        position: absolute;
        background: ${props =>
            props.points === '0'
                ? `${props.theme.colours.black}`
                : `${props.theme.colours.greenPrimary}`};
        top: 0;
        left: -15px;
        bottom: 0;
        width: 25px;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
        transform: skew(-20deg);
    }
`;

export const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Points = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.xxxsmall};
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    position: absolute;
    padding-right: 5px;
`;
