import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectedSearchField from '../components/SearchField';
import {
    requestFolloweeLeaderboard,
    requestLeaderboardForSelf
} from '../../../store/modules/leaderboard/actions';
import {
    selectLeaderboard,
    selectSelfLeaderboard
} from '../../../store/modules/leaderboard/selectors';
import Alert from '../../../components/Common/Alert';
import { requestFollowees } from '../../../store/modules/follow/actions';
import LeaderboardTypeNavigation from '../components/LeaderboardTypeNavigation';
import ConnectedScoreTable from '../containers/ScoreTable';
import {
    getFollowingTableWarning,
    mergeUserResults
} from '../../../helpers/leaderboardHelper';

export const Following = ({
    period,
    periodId,
    userId,
    followees,
    getFollowees,
    selfLeaderboard,
    getLeaderboardForSelf,
    leaderboard,
    getFolloweeLeaderboard
}) => {
    useEffect(() => {
        // Load the followees if we're logged in and don't already have them loaded
        if (userId && !followees) {
            getFollowees();
        }
    }, [userId, followees, getFollowees]);

    useEffect(() => {
        if (leaderboard) {
            return;
        }

        if (followees && followees.length > 0) {
            getFolloweeLeaderboard(period, periodId);
        }
    }, [followees, period, periodId, leaderboard, getFolloweeLeaderboard]);

    useEffect(() => {
        if (!userId || selfLeaderboard) {
            return;
        }

        getLeaderboardForSelf(period, periodId);
    }, [userId, getLeaderboardForSelf, period, periodId, selfLeaderboard]);

    const warning = getFollowingTableWarning(userId, followees, leaderboard);

    if (warning) {
        return (
            <>
                <LeaderboardTypeNavigation
                    title="Following"
                    prev="global"
                    next="pundits"
                />
                <Alert type="Warning">{warning}</Alert>
            </>
        );
    }

    const results = leaderboard.results.slice();

    if (selfLeaderboard) {
        mergeUserResults(results, selfLeaderboard);
    }

    const position =
        selfLeaderboard && selfLeaderboard.position > 0
            ? results.findIndex(result => result.userId === userId) + 1
            : null;

    return (
        <>
            {position}
            <LeaderboardTypeNavigation
                title={`Following ${
                    followees && followees.length > 0
                        ? `(${followees.length})`
                        : ''
                }`}
                prev="global"
                next="pundits"
            />
            <ConnectedSearchField period={period} periodId={periodId} />
            <ConnectedScoreTable
                showHeader
                rows={results}
                testId="leaderboard-table-following"
            />
        </>
    );
};

const mapStateToProps = (state, props) => ({
    followees: state.follow.followees || null,
    userId: state.user.info.userId,
    selfLeaderboard: selectSelfLeaderboard(state, props.period, props.periodId),
    leaderboard: selectLeaderboard(
        state,
        props.period,
        props.periodId,
        'following'
    )
});

const mapDispatchToProps = dispatch => ({
    getFollowees: () => {
        dispatch(requestFollowees());
    },
    getFolloweeLeaderboard: (period, id) => {
        dispatch(requestFolloweeLeaderboard(period, id));
    },
    getLeaderboardForSelf: (period, id) => {
        dispatch(requestLeaderboardForSelf(period, id));
    }
});

Following.propTypes = {
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    userId: PropTypes.number,
    followees: PropTypes.arrayOf(PropTypes.number),
    getFollowees: PropTypes.func.isRequired,
    selfLeaderboard: PropTypes.shape({
        userId: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.number,
        isWinner: PropTypes.bool.isRequired,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        points: PropTypes.number
    }),
    getLeaderboardForSelf: PropTypes.func.isRequired,
    leaderboard: PropTypes.shape({
        results: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                name: PropTypes.string,
                position: PropTypes.number,
                isWinner: PropTypes.bool.isRequired,
                correctResults: PropTypes.number,
                correctScores: PropTypes.number,
                points: PropTypes.number
            })
        ).isRequired
    }),
    getFolloweeLeaderboard: PropTypes.func.isRequired
};

Following.defaultProps = {
    userId: null,
    periodId: null,
    followees: null,
    selfLeaderboard: null,
    leaderboard: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
