import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Pundit = ({ avatarUri, name, homePrediction, awayPrediction }) => {
    const [firstName, surname] = name.split(' ');

    return (
        <Styled.Pundit>
            <center>
                <Styled.PunditAvatar
                    src={avatarUri}
                    data-test-id="pundit-avatar"
                />
            </center>
            <Styled.PunditPrediction data-test-id="pundit-prediction">
                {homePrediction}-{awayPrediction}
            </Styled.PunditPrediction>
            <center data-test-id="pundit-name">
                <Styled.PunditName>{`${firstName} `}</Styled.PunditName>
                <Styled.PunditName>{surname}</Styled.PunditName>
            </center>
        </Styled.Pundit>
    );
};

Pundit.propTypes = {
    name: PropTypes.string.isRequired,
    avatarUri: PropTypes.string.isRequired,
    homePrediction: PropTypes.number.isRequired,
    awayPrediction: PropTypes.number.isRequired
};

export default Pundit;
