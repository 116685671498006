import styled from '@emotion/styled/macro';
import Image from '../../../components/Common/Image';
import Button from '../../../components/Common/Button';

export const LeaguesList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.xlarge};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        gap: ${props => props.theme.spacing.medium};
    }
`;

export const League = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.xlarge};
    padding: ${props => props.theme.spacing.xlarge};
    background: ${props => props.theme.colours.lightGrey};
    box-sizing: border-box;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        gap: ${props => props.theme.spacing.medium};
        padding: ${props => props.theme.spacing.medium};
    }
`;

export const BadgeContainer = styled.div`
    min-width: 100px;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        min-width: 75px;
    }
`;

export const Badge = styled(Image)`
    display: block;
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        max-width: 75px;
        max-height: 75px;
    }
`;

export const TeamPinContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.small};
`;

export const Team = styled.div`
    font-size: ${props => props.theme.fontSize.xxlarge};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large};
    }
`;

export const Pin = styled.div`
    font-size: ${props => props.theme.fontSize.large};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;

export const JoinMobile = styled(Button)`
    width: 200px;
    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        display: none;
    }
`;

export const JoinDesktop = styled(Button)`
    width: 200px;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        display: none;
    }
`;
