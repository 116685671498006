import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import config from '../../../../../../config';

const IconImage = () => `${config.getS3BucketPath()}/league/default.png`;

const LeagueName = ({
    leagueName,
    setLeagueName,
    subtitle,
    leagueNameText
}) => (
    <Styled.Card>
        <Styled.DescriptionContainer>
            <Styled.ImageContainer>
                <Styled.IconImage src={IconImage()} alt="League Shield Icon" />
            </Styled.ImageContainer>
            <Styled.LabelContainer>
                <Styled.SubTitle>{subtitle}</Styled.SubTitle>
                <Styled.Label htmlFor="league-name">
                    {leagueNameText}
                </Styled.Label>
            </Styled.LabelContainer>
        </Styled.DescriptionContainer>
        <Styled.InputField
            defaultValue={leagueName}
            onChange={e => setLeagueName(e.target.value)}
            id="league-name"
            data-test-id="league-form-league-name"
        />
    </Styled.Card>
);

LeagueName.propTypes = {
    subtitle: PropTypes.string.isRequired,
    leagueNameText: PropTypes.string.isRequired,
    leagueName: PropTypes.string.isRequired,
    setLeagueName: PropTypes.func.isRequired
};

export default LeagueName;
