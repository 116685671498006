const SHARE_LINKS = [
    {
        colour: 'whatsapp',
        link: 'https://wa.me/?text=',
        useText: true,
        useShortText: false,
        image: '/img/logos/whatsapp_white.png',
        altText: 'Whatsapp',
        analytics: '[Share]-Whatsapp',
        showInBrowser: true,
        showOnMobile: false,
        showOnNative: false,
        testId: 'share-link-whatsapp',
        extraProps: {}
    },
    {
        colour: 'whatsapp',
        link: 'whatsapp://send?text=',
        useText: true,
        useShortText: false,
        image: '/img/logos/whatsapp_white.png',
        altText: 'Whatsapp',
        analytics: '[Share]-Whatsapp',
        showInBrowser: false,
        showOnMobile: true,
        showOnNative: true,
        testId: 'share-link-whatsapp',
        extraProps: {}
    },
    {
        colour: 'facebook',
        link:
            'https://www.facebook.com/dialog/share?app_id=388193917967007&href=http%3A%2F%2Fsuper6.skysports.com&quote=',
        useText: true,
        useShortText: false,
        image: '/img/logos/facebook.png',
        altText: 'Facebook',
        analytics: '[Share]-Facebook',
        showInBrowser: true,
        showOnMobile: true,
        showOnNative: true,
        testId: 'share-link-facebook',
        extraProps: {}
    },
    {
        colour: 'twitter',
        link:
            'https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Fsuper6.skysports.com&related=Super6&text=',
        useText: false,
        useShortText: true,
        useUrl: true,
        image: '/img/logos/twitter.png',
        altText: 'Twitter',
        analytics: '[Share]-Twitter',
        showInBrowser: true,
        showOnMobile: true,
        showOnNative: true,
        testId: 'share-link-twitter',
        extraProps: {}
    },
    {
        colour: 'lightGrey',
        link: 'mailto:?subject=Play%20Super%206&body=',
        useText: true,
        useShortText: false,
        image: '/img/logos/email.png',
        altText: 'Email',
        darkTheme: {
            colour: 'darkThemeEmailBackground',
            image: '/img/logos/email_darktheme.png'
        },
        displace: 12,
        analytics: '[Share]-Email',
        showInBrowser: true,
        showOnMobile: true,
        showOnNative: true,
        testId: 'share-link-email',
        extraProps: {}
    }
];

export default SHARE_LINKS;
