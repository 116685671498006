import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Banner from '../../components/Banner';
import {
    requestBanner,
    requestBannerId,
    setBannerClose
} from '../../store/modules/banner/actions';
import { isFeatureOn } from '../../store/modules/content/selectors';
import ServiceMessage from '../../components/ServiceMessage';
import {
    selectIsLoggedIn,
    selectHasCheckedAuth
} from '../../store/modules/auth/selectors';

const ConnectedBanner = ({
    getBanner,
    getBannerId,
    banner,
    loading,
    type,
    isGenericBannerEnabled,
    isLoggedIn,
    bannerId,
    checkedBannerId,
    closeBanner,
    isBannerClosed,
    customerId,
    hasCheckedAuth
}) => {
    // Use hasCheckedAuth to avoid a flash of banner during the login check if the user has already dismissed the banner
    useEffect(() => {
        if (isGenericBannerEnabled && hasCheckedAuth) {
            getBanner();
        }
    }, [getBanner, isGenericBannerEnabled, hasCheckedAuth]);

    useEffect(() => {
        if (
            isGenericBannerEnabled &&
            banner &&
            banner.bannerType === 'serviceMessage'
        ) {
            getBannerId();
        }
    }, [banner, getBannerId, isGenericBannerEnabled]);

    if (!isGenericBannerEnabled || loading || !banner) {
        return null;
    }

    if (banner && banner.bannerType === 'serviceMessage') {
        if (!checkedBannerId || isBannerClosed) {
            return null;
        }
        if (isLoggedIn) {
            if (bannerId === `${banner.id}-${customerId}`) {
                return null;
            }
            return (
                <ServiceMessage
                    onClickCancel={() => {
                        closeBanner(banner, customerId);
                    }}
                    messageTitle={banner.title}
                    messageText={banner.body}
                />
            );
        }
        return (
            <ServiceMessage
                onClickCancel={() => {
                    closeBanner(banner, '');
                }}
                messageTitle={banner.title}
                messageText={banner.body}
            />
        );
    }

    return (
        <Banner
            href={banner.link}
            srcSet={{ desktop: banner.desktopUrl, mobile: banner.mobileUrl }}
            alt=""
            type={type}
        />
    );
};

ConnectedBanner.propTypes = {
    closeBanner: PropTypes.func.isRequired,
    getBanner: PropTypes.func.isRequired,
    getBannerId: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    bannerId: PropTypes.string,
    checkedBannerId: PropTypes.bool,
    banner: PropTypes.shape({
        id: PropTypes.number,
        link: PropTypes.string,
        desktopUrl: PropTypes.string,
        mobileUrl: PropTypes.string,
        bannerType: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string
    }),
    isLoggedIn: PropTypes.bool,
    isGenericBannerEnabled: PropTypes.bool.isRequired,
    isBannerClosed: PropTypes.bool,
    customerId: PropTypes.string,
    hasCheckedAuth: PropTypes.bool
};

ConnectedBanner.defaultProps = {
    banner: null,
    bannerId: null,
    checkedBannerId: false,
    isLoggedIn: null,
    isBannerClosed: null,
    customerId: null,
    hasCheckedAuth: false
};

const mapDispatchToProps = dispatch => ({
    getBanner: () => {
        dispatch(requestBanner());
    },
    getBannerId: () => {
        dispatch(requestBannerId());
    },
    closeBanner: (banner, customerId) => {
        dispatch(setBannerClose(banner, customerId));
    }
});

const mapStateToProps = (state, props) => ({
    loading: state.banner.loading,
    banner: state.banner.banner,
    isGenericBannerEnabled: isFeatureOn(state, props.featureFlag, true),
    isLoggedIn: selectIsLoggedIn(state),
    bannerId: state.banner.storageBannerId,
    checkedBannerId: state.banner.checkedStorageBannerId,
    isBannerClosed: state.banner.isBannerClosed,
    customerId: state.user.info.customerId,
    hasCheckedAuth: selectHasCheckedAuth(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedBanner);
