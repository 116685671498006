import styled from '@emotion/styled/macro';

export const PageTitle = styled.h1`
    font-size: ${props => props.theme.fontSize.xxlarge};
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
    padding: ${props => props.theme.spacing.xlarge} 0;
    margin-bottom: ${props => props.theme.spacing.large};
    font-weight: 700;
    font-family: ${props => props.theme.fonts.skySportsD};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.skySportsD};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props =>
            props.preventCollapse
                ? `
            line-height: ${props.theme.lineHeight.medium};
            padding: ${props.theme.spacing.large};
            `
                : `
            text-align: center;
            font-size: ${props.theme.fontSize.small};
            padding: ${props.theme.spacing.large} 0;
            border-bottom: none;
            background: ${props.theme.colours.bgGrey};
        `}
    }
`;

export const PageTitleWithBackButton = styled(PageTitle)`
    display: flex;
    align-items: center;
    min-height: 32px;
`;

export const LeftButtonWrapper = styled.div`
    width: 65px;
    line-height: 32px;
`;

export const TitleWrapper = styled.div`
    width: calc(100% - 140px);
    text-align: center;
`;

export default PageTitle;
