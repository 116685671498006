import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    type: null,
    props: null,
    canDismiss: false,
    show: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SHOW:
            document.body.style.overflowY = 'hidden';
            return newIfChanged(state, {
                ...state,
                type: action.modalType,
                props: action.modalProps,
                canDismiss: action.canDismiss,
                show: true
            });
        case types.DISMISS:
            document.body.style.overflowY = 'auto';
            return newIfChanged(state, {
                ...defaultState
            });
        default:
            return state;
    }
};
