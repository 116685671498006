import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    globalBanner: null,
    betslipOffer: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.RECEIVE_GLOBAL_BANNER:
            return newIfChanged(state, {
                ...state,
                globalBanner: action.payload
            });
        case types.RECEIVE_BETSLIP_OFFER:
            return newIfChanged(state, {
                ...state,
                betslipOffer: action.payload
            });
        default:
            return state;
    }
};
