export const fillVersionParts = (first, second) => {
    const firstParts = first.split('.').map(part => parseInt(part, 10));
    const secondParts = second.split('.').map(part => parseInt(part, 10));

    if (firstParts.length !== secondParts.length) {
        for (
            let i = 0;
            i < Math.max(firstParts.length, secondParts.length);
            i += 1
        ) {
            if (firstParts[i] === undefined) {
                firstParts[i] = 0;
            }
            if (secondParts[i] === undefined) {
                secondParts[i] = 0;
            }
        }
    }

    return [firstParts, secondParts];
};

export const isGreaterThanOrEqual = (first, second) => {
    const [firstParts, secondParts] = fillVersionParts(first, second);

    for (let i = 0; i < firstParts.length; i += 1) {
        if (firstParts[i] > secondParts[i]) {
            return true;
        }

        if (firstParts[i] < secondParts[i]) {
            return false;
        }
    }

    // If we've got here, then they're equal values
    return true;
};
