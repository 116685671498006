import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectedHeadToHeadBanner from '../../components/HeadToHeadBanner';
import ConnectedRoundCarousel from './components/RoundCarousel';
import ConnectedBanner from '../../containers/GenericBanner';
import { ENABLE_HOME_BANNER } from '../../features';
import ConnectedDeadlineTitle from '../../components/DeadlineTitle';
import { trackLCP } from '../../helpers/webvitals';
import { captureLcp } from '../../store/modules/metrics/actions';
import * as Styled from './styles';

const Home = ({ trackLCPCallback }) => {
    useEffect(() => {
        trackLCP(trackLCPCallback);
    }, []);

    return (
        <Styled.PageContainer>
            <ConnectedBanner
                featureFlag={ENABLE_HOME_BANNER}
                type="home"
                alt="top home banner"
            />
            <ConnectedDeadlineTitle />
            <Styled.MainContent>
                <ConnectedRoundCarousel />
                <ConnectedHeadToHeadBanner largeText />
                <Styled.LinkContainer>
                    <Styled.StyledLink to="/terms">
                        T&amp;Cs apply.
                    </Styled.StyledLink>
                </Styled.LinkContainer>
            </Styled.MainContent>
        </Styled.PageContainer>
    );
};

Home.propTypes = {
    trackLCPCallback: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    trackLCPCallback: value => {
        dispatch(captureLcp('home', value));
    }
});

export default connect(null, mapDispatchToProps)(Home);
