import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import LastRoundScore from '../../LastRoundScore';

const LastRoundPredictionAndScore = ({
    predictedHomeScore,
    predictedAwayScore,
    homeScore,
    awayScore,
    isAbandoned
}) => {
    const getScore = score => {
        if (isAbandoned) {
            return 'P';
        }

        return score || 0;
    };

    return (
        <Styled.PredictionAndScoreContainer>
            <Styled.ScoreTitle>You Predicted</Styled.ScoreTitle>
            <LastRoundScore
                homeScore={predictedHomeScore}
                awayScore={predictedAwayScore}
                testId="last-round-prediction-row"
            />

            <Styled.ScoreTitle>Final Score</Styled.ScoreTitle>
            <LastRoundScore
                homeScore={getScore(homeScore)}
                awayScore={getScore(awayScore)}
                testId="last-round-final-score-row"
            />
        </Styled.PredictionAndScoreContainer>
    );
};

LastRoundPredictionAndScore.defaultProps = {
    predictedHomeScore: null,
    predictedAwayScore: null,
    homeScore: null,
    awayScore: null,
    isAbandoned: false
};

LastRoundPredictionAndScore.propTypes = {
    predictedHomeScore: PropTypes.number,
    predictedAwayScore: PropTypes.number,
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAbandoned: PropTypes.bool
};

export default LastRoundPredictionAndScore;
