import { select, put, takeLeading, takeEvery, call } from 'redux-saga/effects';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';
import * as types from './types';
import { selectToken } from '../auth/selectors';
import followApi from '../../../api/follow';

export function* getFollowees() {
    try {
        const token = yield select(selectToken);
        const data = yield call(followApi.getFollowees, token);
        yield put(actions.receiveFollowees(data));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the followees')
        );
    }
}

export function* createFollowee(action) {
    try {
        const token = yield select(selectToken);
        const data = yield call(followApi.createFollowee, action.userId, token);

        yield put(actions.receiveFollowees(data));
        yield put(actions.refreshFollowees());
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(addErrorMessage(err.message));
        } else {
            yield put(addErrorMessage('There was a problem creating followee'));
        }
    }
}

export function* deleteFollowee(action) {
    try {
        const token = yield select(selectToken);
        const data = yield call(followApi.deleteFollowee, action.userId, token);
        yield put(actions.receiveFollowees(data));
    } catch (err) {
        yield put(addErrorMessage('There was a problem deleting followee'));
    }
}

export function* watchGetFollowees() {
    yield takeLeading(types.REQUEST_FOLLOWEES, getFollowees);
}

export function* watchCreateFollowee() {
    yield takeEvery(types.CREATE_FOLLOWEE, createFollowee);
}

export function* watchDeleteFollowee() {
    yield takeEvery(types.DELETE_FOLLOWEE, deleteFollowee);
}

export const sagas = [
    watchGetFollowees,
    watchCreateFollowee,
    watchDeleteFollowee
];
