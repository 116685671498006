import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import LeagueItemInfo from '../LeagueItemInfo';
import { showModal as showModalAction } from '../../../../store/modules/modal/actions';

const LeagueItem = ({
    slug,
    name,
    isInPlay,
    memberCount,
    isPrevious,
    deleteLeague,
    icon
}) => {
    if (isPrevious) {
        return (
            <Styled.PreviousSummaryListItem isPrevious={isPrevious}>
                <LeagueItemInfo
                    name={name}
                    slug={slug}
                    memberCount={memberCount}
                    icon={icon}
                />
                {isPrevious && (
                    <Styled.ActionsContainer>
                        <Styled.ActionButton
                            internal
                            to={`/league/${slug}/restart`}
                            slim
                        >
                            Restart
                        </Styled.ActionButton>
                        <Styled.RedActionButton
                            onClick={() => deleteLeague(slug)}
                            slim
                        >
                            Delete
                        </Styled.RedActionButton>
                    </Styled.ActionsContainer>
                )}
            </Styled.PreviousSummaryListItem>
        );
    }
    return (
        <Styled.SummaryListLink
            to={`/league/${slug}/${isInPlay ? 'round' : 'season'}`}
        >
            <LeagueItemInfo
                name={name}
                slug={slug}
                memberCount={memberCount}
                icon={icon}
            />
        </Styled.SummaryListLink>
    );
};

const mapDispatchToProps = dispatch => ({
    deleteLeague: slug =>
        dispatch(showModalAction('LEAGUE_DELETE', { slug }, false))
});

LeagueItem.propTypes = {
    deleteLeague: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired,
    isPrevious: PropTypes.bool,
    isInPlay: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired
};

LeagueItem.defaultProps = {
    isPrevious: false
};

export default connect(null, mapDispatchToProps)(LeagueItem);
