import { put, takeLeading, call } from 'redux-saga/effects';
import super6ExtraApi from '../../../api/super6-extra';
import * as types from './types';
import * as actions from './actions';

function* getActiveRound() {
    try {
        const data = yield call(super6ExtraApi.getActiveRound);
        yield put(actions.receiveSuper6ExtraActiveRound(data));
    } catch (err) {
        yield put(actions.receiveSuper6ExtraActiveRound(null));
    }
}

export default function* watchRequestExtraActiveRound() {
    yield takeLeading(types.REQUEST_SUPER6EXTRA_ACTIVE_ROUND, getActiveRound);
}

export const sagas = [watchRequestExtraActiveRound];
