import * as types from './types';
import getPredictionsFromPayload from '../../../helpers/predictionHelper';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    rounds: {},
    predictions: {},
    loadedPredictions: {},
    predictionsOtherUser: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.RECEIVE_ROUND_RESULTS:
            return newIfChanged(state, {
                ...state,
                rounds: {
                    ...state.rounds,
                    [action.roundId]: action.payload
                }
            });

        case types.RECEIVE_ROUND_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                predictions: {
                    ...state.predictions,
                    [action.roundId]: getPredictionsFromPayload(action.payload)
                },
                loadedPredictions: {
                    ...state.loadedPredictions,
                    [action.roundId]: true
                }
            });

        case types.RECEIVE_ROUND_PREDICTIONS_BY_USER:
            return newIfChanged(state, {
                ...state,
                predictionsOtherUser: {
                    ...state.predictionsOtherUser,
                    [action.userId]: {
                        ...state.predictionsOtherUser[action.userId],
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        [action.roundId]: getPredictionsFromPayload(
                            action.payload
                        )
                    }
                }
            });

        default:
            return state;
    }
};
