export const isFeatureOn = (state, name, def = false) => {
    if (
        !state.content ||
        !state.content.features ||
        typeof state.content.features[`FEATURE_${name}`] === 'undefined'
    ) {
        return def;
    }
    return state.content.features[`FEATURE_${name}`];
};

export const areFeaturesLoaded = state => state.content.featuresLoaded;

export const selectDoesBackButtonLinkExist = state =>
    state.content.backButtonLink;
