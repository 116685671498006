import { createSelector } from 'reselect';

export const getPredictions = state => state.play.predictions;
export const getPredictionsForMatchIdProp = (state, props) =>
    state.play.predictions && state.play.predictions[props.matchId];

export const getPredictionsForMatchProp = (state, match) =>
    state.play.predictions && state.play.predictions[match.id];

export const getGoldenGoal = state => {
    if (state.play.goldenGoal.time) {
        return state.play.goldenGoal;
    }
    return {
        ...state.play.goldenGoal,
        time: null
    };
};

export const selectHomePredictionForMatch = createSelector(
    [getPredictionsForMatchProp],
    matchPredictions => {
        if (!matchPredictions) {
            return null;
        }

        return matchPredictions.scoreHome;
    }
);

export const selectAwayPredictionForMatch = createSelector(
    [getPredictionsForMatchProp],
    matchPredictions => {
        if (!matchPredictions) {
            return null;
        }

        return matchPredictions.scoreAway;
    }
);
