import React from 'react';
import * as Styled from './styles';
import Image from '../../../Common/Image';
import { Icon } from '../../../Common/Page/styles';
import * as icons from '../../../../styles/icons';
import { buildTransferLink, BET } from '../../../../helpers/transfer';
import AppLink from '../../../Common/AppLink';

const link = buildTransferLink(
    BET,
    '?dcmp=s6_fbnavoffer-tn91&AFF_ID=1197317355&open=upgradereg'
);

const termsLink = buildTransferLink(BET, '/lp/free-to-play-offer');

const PartRegAd = () => (
    <Styled.AdContainer>
        <Styled.Details>
            <AppLink app="skybet" link={link}>
                <div>
                    <div>
                        Get a free £20 bet{' '}
                        <Icon data-char={icons.PoundShield} />
                    </div>
                    <div>No deposit required.</div>
                </div>
                <div>
                    <Image
                        src="/img/products/sky-bet.png?updated=2020-10-08"
                        alt="SkyBet logo"
                        height="22px"
                    />
                </div>
            </AppLink>
        </Styled.Details>
        <Styled.TermsAndConditions
            href={termsLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            T&Cs apply.
        </Styled.TermsAndConditions>
    </Styled.AdContainer>
);

export default PartRegAd;
