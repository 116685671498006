import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Image from '../Common/Image';
import {
    affIdMap,
    buildDcmp,
    dcmpPrefixMap,
    generateBetslipLink
} from '../../helpers/skyBetLinkHelper';
import { buildTransferLink } from '../../helpers/transfer';
import AppLink from '../Common/AppLink';
import { recordBetClick } from '../../store/modules/signal/actions';
import { useCookieConsentContext } from '../../hooks/useCookieConsents';
import buildCookieUrl from '../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../config';

export const LiveOdds = ({
    fullyRegistered,
    outcomes,
    isResulted,
    recordLiveOddsClick
}) => {
    const dcmp = buildDcmp({
        prefix: dcmpPrefixMap.liveOdds,
        isPartReg: !fullyRegistered
    });

    const affId = affIdMap.standard;
    const areOutcomesValid = !outcomes.some(outcome => outcome === null);
    const shouldShowOdds = !isResulted && areOutcomesValid;
    const { cookieConsentQuery } = useCookieConsentContext();

    return (
        <Styled.OddsContainer isResulted={shouldShowOdds}>
            <Styled.LiveOddsLeft>
                <Styled.LiveOddsTitle data-test-id="live-odds-title">
                    {!shouldShowOdds
                        ? `For betting markets visit`
                        : `Latest odds from`}
                </Styled.LiveOddsTitle>
                <AppLink
                    app="skybet"
                    link={buildCookieUrl(
                        OAUTH_URI,
                        `${buildTransferLink(
                            'mbet'
                        )}?AFF_ID=${affId}&dcmp=${dcmp}`,
                        cookieConsentQuery
                    )}
                    testId="match-live-odds-skybet-link"
                >
                    <Image src="/img/products/sky-bet.png?updated=2020-10-08" />
                </AppLink>
            </Styled.LiveOddsLeft>

            {shouldShowOdds && (
                <Styled.OutcomeList data-test-id="outcome-list">
                    {outcomes.map(outcome => (
                        <Styled.Outcome
                            key={outcome.desc}
                            onClick={() => recordLiveOddsClick()}
                        >
                            <AppLink
                                app="skybet"
                                link={buildCookieUrl(
                                    OAUTH_URI,
                                    generateBetslipLink({
                                        affId,
                                        dcmp,
                                        selections: [outcome]
                                    }),
                                    cookieConsentQuery
                                )}
                                testId={`match-live-odds-${outcome.desc.toLowerCase()}`}
                            >
                                <Styled.OutcomeBorder>
                                    <Styled.OutcomeName>
                                        {outcome.desc}&nbsp;-&nbsp;
                                    </Styled.OutcomeName>
                                    <Styled.OutcomeLink>
                                        {outcome.fraction}
                                    </Styled.OutcomeLink>
                                </Styled.OutcomeBorder>
                            </AppLink>
                        </Styled.Outcome>
                    ))}
                </Styled.OutcomeList>
            )}
        </Styled.OddsContainer>
    );
};

LiveOdds.defaultProps = {
    outcomes: []
};

LiveOdds.propTypes = {
    outcomes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    ),
    fullyRegistered: PropTypes.bool.isRequired,
    isResulted: PropTypes.bool.isRequired,
    recordLiveOddsClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    fullyRegistered: !!state.user.info.fullyRegistered
});

const mapDispatchToProps = dispatch => ({
    recordLiveOddsClick: () => dispatch(recordBetClick('live'))
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveOdds);
