import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const FreeTextContainer = ({ header, text }) => {
    if (!text) {
        return null;
    }

    const renderHeader = () => (
        <>{header && <Styled.Header>{header}</Styled.Header>}</>
    );

    return (
        <>
            {renderHeader()}
            {/* eslint-disable-next-line react/no-danger */}
            <Styled.TextContainer dangerouslySetInnerHTML={{ __html: text }} />
        </>
    );
};

FreeTextContainer.propTypes = {
    header: PropTypes.string,
    text: PropTypes.string
};

FreeTextContainer.defaultProps = {
    header: null,
    text: null
};

export default FreeTextContainer;
