import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAsset } from '../../../../store/modules/round/selectors';
import { IMAGE_BASE_PATH } from '../../../../config';
import GenericHeroImage from '../../../../components/Super6Hero/HeroImage';
import * as Styled from '../../../../components/Super6Hero/styles';
import { isFeatureOn } from '../../../../store/modules/content/selectors';
import { SEASON_CLOSED } from '../../../../features';
import ConnectedAppLink from '../../../../components/Common/AppLink';
import { buildTransferLink, ITV7 } from '../../../../helpers/transfer';

const HeroImage = ({
    mobile3x,
    mobile2x,
    mobile1x,
    desktop3x,
    desktop2x,
    desktop1x,
    isSeasonClosed
}) => {
    if (isSeasonClosed) {
        return (
            <>
                <ConnectedAppLink
                    app="skyc4racingpick6"
                    link={buildTransferLink(
                        ITV7,
                        '?dcmp=s6_headline_itv7xsell'
                    )}
                >
                    <GenericHeroImage
                        mobile1x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_mobile_1x.png`}
                        mobile2x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_mobile_2x.png`}
                        mobile3x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_mobile_3x.png`}
                        desktop1x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_desktop_1x.png`}
                        desktop2x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_desktop_2x.png`}
                        desktop3x={`${IMAGE_BASE_PATH}/img/masthead/itv_super6_push_desktop_3x.png`}
                    />
                </ConnectedAppLink>
            </>
        );
    }

    if (!mobile2x || !desktop2x) {
        return <Styled.LoadingImage />;
    }

    return (
        <GenericHeroImage
            mobile1x={mobile1x}
            mobile2x={mobile2x}
            mobile3x={mobile3x}
            desktop1x={desktop1x}
            desktop2x={desktop2x}
            desktop3x={desktop3x}
        />
    );
};

const mapStateToProps = state => ({
    mobile3x: selectAsset(state, 'mastheadMobileImageset', '3x'),
    mobile2x: selectAsset(state, 'mastheadMobileImageset', '2x'),
    mobile1x: selectAsset(state, 'mastheadMobileImageset', '1x'),
    desktop3x: selectAsset(state, 'mastheadDesktopImageset', '3x'),
    desktop2x: selectAsset(state, 'mastheadDesktopImageset', '2x'),
    desktop1x: selectAsset(state, 'mastheadDesktopImageset', '1x'),
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false)
});

HeroImage.propTypes = {
    mobile3x: PropTypes.string,
    mobile2x: PropTypes.string,
    mobile1x: PropTypes.string,
    desktop3x: PropTypes.string,
    desktop2x: PropTypes.string,
    desktop1x: PropTypes.string,
    isSeasonClosed: PropTypes.bool
};

HeroImage.defaultProps = {
    mobile3x: null,
    mobile2x: null,
    mobile1x: null,
    desktop3x: null,
    desktop2x: null,
    desktop1x: null,
    isSeasonClosed: false
};

export default connect(mapStateToProps, null)(HeroImage);
