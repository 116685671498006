import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { leaveLeague as leaveLeagueAction } from '../../../../store/modules/league/actions';
import Confirmation from '../../../../components/Modals/Confirmation';

const LeaveLeagueConfirmation = ({ slug, leaveLeague, onDismiss }) => (
    <Confirmation
        title="Leave League"
        confirmLabel="Leave"
        message="Are you sure you want to"
        messageSecondLine="leave the league?"
        onConfirm={() => leaveLeague(slug)}
        onDismiss={onDismiss}
    />
);

const mapDispatchToProps = dispatch => ({
    leaveLeague: slug => dispatch(leaveLeagueAction(slug))
});

LeaveLeagueConfirmation.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    leaveLeague: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(LeaveLeagueConfirmation);
