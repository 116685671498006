import styled from '@emotion/styled/macro';
import AppLink from '../../../../../../components/Common/AppLink';
import Button from '../../../../../../components/Common/Button';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    max-width: 500px;
`;

export const TeamsRow = styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: row;

    > div > div > div {
        width: ${props => props.width ?? '50px'};
        height: ${props => props.width ?? '50px'};

        @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
            width: ${props => props.width ?? '35px'};
            height: ${props => props.width ?? '35px'};
        }

        @media (max-width: ${props => props.theme.hero.widths.smallMobile}) {
            width: ${props => props.width ?? '30px'};
            height: ${props => props.width ?? '30px'};
        }
    }

    > div > div img {
        width: ${props => props.width ?? '30px !important'};
        height: ${props => props.width ?? '30px'};

        @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
            width: ${props => props.width ?? '20px !important'};
            height: ${props => props.width ?? '20px'};
        }

        @media (max-width: ${props => props.theme.hero.widths.smallMobile}) {
            width: ${props => props.width ?? '16px !important'};
            height: ${props => props.width ?? '16px'};
        }
    }
`;

export const Card = styled.div`
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const LogoPrizeContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;

    position: relative;

    * {
        z-index: 1;
    }

    ${props =>
        props.variant === 'default' &&
        `
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            width: 50%;
            align-self: center;
            position: absolute;
            bottom: 18px;
        }
        div > a {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-family: ${props.theme.fonts.skySportsD};
            font-size: ${props.theme.fontSize.large};
        }

        @media (max-width: ${props.theme.hero.widths.tabletMax}) {
            div {
                display: flex;
                width: 50%;
                align-self: center;
                position: absolute;
                bottom: 10%;
            }
            div > a {
                display: flex;
                height: 30px;
                align-items: center;
                font-size: ${props.theme.fontSize.medium};
            }
        }

        @media (max-width: ${props.theme.hero.widths.mobileMax}) {
            div {
                display: flex;
                width: 50%
                align-self: center;
                position: absolute;
                bottom: 12px;
            }
            div > a {
                display: flex;
                height: 26px;
                align-items: center;
                font-size: ${props.theme.fontSize.small};
            }
        }

        @media (max-width: ${props.theme.hero.widths.smallMobile}) {
            div {
                display: flex;
                width: 50%;
                align-self: center;
                position: absolute;
                bottom: 12px;
            }
            div > a {
                display: flex;
                height: 22px;
                align-items: center;
                font-size: ${props.theme.fontSize.small};
            }
        }

    `}

    ${props =>
        props.variant === 'extra' &&
        `
        justify-content: space-around;
        height: 240px;
        background: ${props.theme.hero[props.variant].colours.multiBackground};
        padding: ${`${props.theme.spacing.xlarge} ${props.theme.spacing.medium}`};
        box-sizing: border-box;
        overflow: hidden;

            &:after {
                content: '';
                position: absolute;

                bottom: 110px;
                left: -60px;
                width: 1000px;
                height: 500px;
                transform: rotate(-10deg);
                opacity: ${props.variant === 'default' ? '0.7' : '0.1'};
                background: white;
            }
        }
    `}
    ${props =>
        props.variant === 'default' &&
        props.buttonText.length >= 15 &&
        `

        @media (max-width: ${props.theme.hero.widths.tabletMax}) {
            div {
                width: 60%;
            }
            div > button {
                font-size: ${props.theme.fontSize.medium};
            }
        }
    `}
`;

export const HeroImage = styled.picture`
    img {
        width: 100%;
        height: 100%;
    }
`;

export const LogoImage = styled.picture`
    img {
        height: 52px;
        object-fit: contain;
    }
`;

export const PrizeImage = styled.picture`
    img {
        max-height: ${props => (props.variant === 'extra' ? '150px' : null)};
        width: ${props => (props.variant === 'default' ? '100%' : null)};
        object-fit: contain;
    }
`;

export const HeroButtonWrapper = styled.div`
    background-color: ${props => props.theme.colours.colorOnPrimary};
    width: 100%;
    padding-bottom: ${props => props.theme.spacing.medium};
    ${props =>
        `
        & div a {
                @media (max-width: ${props.theme.hero.widths.tabletMax}) {
                width: 50%;
                font-size: ${props.theme.fontSize.medium};
                height: 30px;
            }
                @media (max-width: ${props.theme.hero.widths.mobileMax}) {
                    width: 50%;
                    font-size: ${props.theme.fontSize.small};
                    height: 26px;
                    line-height: 2;
                }
                @media (max-width: ${props.theme.hero.widths.smallMobile}) {
                    width: 50%;
                    font-size: ${props.theme.fontSize.small};
                    height: 22px;
                    line-height: 1.7;
                }
        }
    `}
`;

export const HeroButton = styled(Button)`
    margin-top: ${props => props.theme.spacing.large};
    color: ${props =>
        props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    padding: 0px;
    height: ${props => (props.variant === 'extra' ? '50px' : '40px')};
    line-height: ${props => (props.variant === 'extra' ? '50px' : null)};
`;

export const HeroAppButton = styled(AppLink)`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: 4px;
    color: ${props => props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours.greenDark};
    }
    font-size: ${props => props.theme.fontSize.large};
    margin-top: ${props => props.theme.spacing.large};

    padding: 0;
    height: 50px;
    line-height: 50px;
`;

export const RoundInfoText = styled.div`
    margin-top: ${props => props.theme.spacing.large};
    font-size: ${props => props.theme.fontSize.xsmall};
    color: ${props => props.theme.colours.heroTileRoundText};
    display: flex;
    justify-content: space-around;
`;
