import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HeroTile from '../../../../components/HeroTile';
import { buildTransferLink, EXTRA } from '../../../../helpers/transfer';

import {
    GetSuper6ExtraActiveRound,
    HasLoadedActiveRound,
    IsLoadingActiveRound
} from '../../../../store/modules/super6Extra/selectors';
import { requestSuper6ExtraActiveRound } from '../../../../store/modules/super6Extra/actions';
import { dayMap, getFormattedTime } from '../../../../helpers/dateFormatters';

const getButtonText = activeRound => {
    if (activeRound) {
        const { calcStatus, complete, status } = activeRound;

        if (calcStatus === 'Success' && complete === 'yes') {
            return 'View Results';
        }
        if (status === 'closed') {
            return 'View Live Round';
        }
    }
    return 'Play For Free';
};

const ExtraTimeHero = ({
    loadActiveRound,
    activeRound,
    hasLoaded,
    isLoading
}) => {
    useEffect(() => {
        if (!hasLoaded && !isLoading) {
            loadActiveRound();
        }
    }, [loadActiveRound, hasLoaded, isLoading]);

    if (hasLoaded && !activeRound) {
        return null;
    }

    const link = buildTransferLink(EXTRA, '?dcmp=s6_hero');

    if (isLoading || !hasLoaded) {
        return (
            <HeroTile
                isLoading
                heroImagePaths={null}
                prizeImagePaths={null}
                logoPath="/content/img/logos/super6-extra.png"
                link={link}
                variant="extra"
                predictionClosedDate={null}
            />
        );
    }
    const { images, fixtures, complete } = activeRound;

    const { punditImage, prizeImage } = images;
    const { datetime } = fixtures[0];
    let predictionClosedDate = '';
    if (complete !== 'yes') {
        predictionClosedDate = `Entries by ${getFormattedTime(datetime)} ${
            dayMap[new Date(datetime).getDay()].full
        }. T&Cs apply.`;
    }

    const buttonText = getButtonText(activeRound);

    return (
        <HeroTile
            isLoading={isLoading && !hasLoaded}
            heroImagePaths={punditImage}
            prizeImagePaths={prizeImage}
            logoPath="/content/img/logos/super6-extra.png"
            link={link}
            variant="extra"
            predictionClosedDate={predictionClosedDate}
            buttonText={buttonText}
        />
    );
};
ExtraTimeHero.defaultProps = {
    activeRound: null
};
ExtraTimeHero.propTypes = {
    activeRound: PropTypes.shape({
        calcStatus: PropTypes.string,
        complete: PropTypes.string,
        fixtures: PropTypes.arrayOf(
            PropTypes.shape({
                fixtureRef: PropTypes.string,
                datetime: PropTypes.string,
                teamA: PropTypes.shape({
                    extRef: PropTypes.string,
                    name: PropTypes.string,
                    short: PropTypes.string
                }),
                teamB: PropTypes.shape({
                    extRef: PropTypes.string,
                    name: PropTypes.string,
                    short: PropTypes.string
                })
            })
        ),
        images: PropTypes.shape({
            punditImage: PropTypes.shape({
                desktop: PropTypes.string,
                mobile: PropTypes.string
            }),
            prizeImage: PropTypes.shape({
                desktop: PropTypes.string,
                mobile: PropTypes.string
            })
        }),
        status: PropTypes.string
    }),
    loadActiveRound: PropTypes.func.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestSuper6ExtraActiveRound())
});
const mapStateToProps = state => ({
    activeRound: GetSuper6ExtraActiveRound(state),
    hasLoaded: HasLoadedActiveRound(state),
    isLoading: IsLoadingActiveRound(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtraTimeHero);
