import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Position = ({ position }) => {
    if (position === 1) {
        return <Styled.WinnerIconImage src="/img/leaderboard/crown.png" />;
    }

    return position;
};

Position.propTypes = {
    position: PropTypes.number.isRequired
};

export default Position;
