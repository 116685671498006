import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const StatBox = ({ title, value }) => (
    <Styled.OuterContainer>
        <Styled.InnerContainer>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Value>{value}</Styled.Value>
        </Styled.InnerContainer>
    </Styled.OuterContainer>
);

StatBox.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]).isRequired
};

export default StatBox;
