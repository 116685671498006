import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LandingPageApi from '../../api/landingPage';
import setTitle from '../../helpers/page';

import FreeTextContainer from '../../components/FreeTextContainer';
import Spinner from '../../components/Common/Spinner';
import PageTitle from '../../components/Common/PageTitle';
import * as Styled from './styles';
import { addErrorMessage } from '../../store/modules/message/actions';

export const LandingPage = ({ match, addMessage }) => {
    const [landingData, setLandingData] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const { slug } = match.params;

    useEffect(() => {
        const getLandingPage = async () => {
            try {
                const data = await LandingPageApi.getLandingPage(slug);
                setLandingData(data);
            } catch (e) {
                setRedirect('/');
                addMessage('Sorry, that page was not found');
            }
        };
        getLandingPage();
    }, [slug]);

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    if (!landingData) {
        return (
            <Styled.LoadingContainer>
                <Spinner />
            </Styled.LoadingContainer>
        );
    }

    const {
        name,
        headerImage,
        mainText,
        buttonCopy,
        targetUrl,
        termsText,
        extraText,
        pageAnalytics
    } = landingData;

    setTitle(name);

    const renderSecondaryButton = () => {
        const { secondaryButtonCopy, secondaryButtonUrl } = landingData;

        if (!secondaryButtonCopy || !secondaryButtonUrl) {
            return null;
        }

        return (
            <Styled.SecondaryButton link={secondaryButtonUrl}>
                {secondaryButtonCopy}
            </Styled.SecondaryButton>
        );
    };

    return (
        <Styled.LandingContainer>
            <Styled.HeaderImage src={headerImage} />
            <PageTitle preventCollapse>{name}</PageTitle>
            <FreeTextContainer text={mainText} />
            <Styled.PrimaryButton
                data-analytics={pageAnalytics}
                link={targetUrl}
            >
                {buttonCopy}
            </Styled.PrimaryButton>
            {renderSecondaryButton()}
            <FreeTextContainer text={termsText} header="Terms & Conditions" />
            <FreeTextContainer text={extraText} />
        </Styled.LandingContainer>
    );
};

const mapDispatchToProps = dispatch => ({
    addMessage: message => dispatch(addErrorMessage(message, 2))
});

LandingPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            slug: PropTypes.string.isRequired
        })
    }).isRequired,
    addMessage: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(LandingPage);
