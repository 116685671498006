import * as types from './types';

export const requestLeaderboardForReferrees = (period, id = null) => ({
    type: types.REQUEST_LEADERBOARD_FOR_REFERREES,
    period,
    id
});

export const receiveLeaderboardForReferrees = (
    period,
    referreesLeaderboard
) => ({
    type: types.RECEIVE_LEADERBOARD_FOR_REFERREES,
    period,
    payload: referreesLeaderboard
});

export const requestLeaderboardForSelf = (period, id = null) => ({
    type: types.REQUEST_LEADERBOARD_FOR_SELF,
    period,
    id
});

export const receiveLeaderboardForSelf = (leaderboard, period, periodId) => ({
    type: types.RECEIVE_LEADERBOARD_FOR_SELF,
    payload: leaderboard,
    period,
    periodId
});

export const requestGlobalLeaderboard = (period, id = null) => ({
    type: types.REQUEST_GLOBAL_LEADERBOARD,
    period,
    id
});

export const receiveGlobalLeaderboard = (leaderboard, period, periodId) => ({
    type: types.RECEIVE_GLOBAL_LEADERBOARD,
    payload: leaderboard,
    period,
    periodId
});

export const requestFolloweeLeaderboard = (period, id = null) => ({
    type: types.REQUEST_FOLLOWEE_LEADERBOARD,
    period,
    id
});

export const receiveFolloweeLeaderboard = (leaderboard, period, periodId) => ({
    type: types.RECEIVE_FOLLOWEE_LEADERBOARD,
    payload: leaderboard,
    period,
    periodId
});

export const requestPunditLeaderboard = (period, id = null) => ({
    type: types.REQUEST_PUNDIT_LEADERBOARD,
    period,
    id
});

export const receivePunditLeaderboard = (leaderboard, period, periodId) => ({
    type: types.RECEIVE_PUNDIT_LEADERBOARD,
    payload: leaderboard,
    period,
    periodId
});

export const requestSearchLeaderboard = (search, period, id = null) => ({
    type: types.REQUEST_SEARCH_LEADERBOARD,
    search,
    period,
    id
});
export const receiveSearchLeaderboard = (
    leaderboard,
    search,
    period,
    periodId
) => ({
    type: types.RECEIVE_SEARCH_LEADERBOARD,
    payload: leaderboard,
    search,
    period,
    periodId
});
