import styled from '@emotion/styled';

export const Header = styled.h2`
    color: ${props => props.theme.colours.modalGrey};
    font-size: ${props => props.theme.fontSize.xlarge};
`;

export const TextContainer = styled.div`
    color: ${props => props.theme.colours.modalGrey};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.lineHeight.normal};

    p {
        margin-bottom: 10px;
    }
`;
