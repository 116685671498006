import { v4 as uuidv4 } from 'uuid';

const SESSION_ITEM_USER_SESSION_ID = 'user_sessionId';

export const getUserSessionId = () => {
    let userSessionId = sessionStorage.getItem(SESSION_ITEM_USER_SESSION_ID);

    if (!userSessionId) {
        userSessionId = uuidv4();

        sessionStorage.setItem(SESSION_ITEM_USER_SESSION_ID, userSessionId);
    }
    return userSessionId;
};

export const getItem = key => sessionStorage.getItem(key);

export const setItem = (key, value) => {
    sessionStorage.setItem(key, value);
};

export const removeItem = key => {
    sessionStorage.removeItem(key);
};
