import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissModal } from '../../store/modules/modal/actions';
import * as Styled from './styles';

import ConnectedFeedbackForm from './FeedbackForm';
import ContactUsForm from './ContactUsForm';
import Profile from './Profile';
import DeleteLeagueConfirmation from '../../views/League/components/Confirmation/DeleteLeagueConfirmation';
import LeaveLeagueConfirmation from '../../views/League/components/Confirmation/LeaveLeagueConfirmation';
import RemoveMemberConfirmation from '../../views/League/components/Confirmation/RemoveMemberConfirmation';
import ConnectedPostPredictionOffer from '../Offer/PostPredictionOffer';

const modalTypes = {
    FEEDBACK_FORM: ConnectedFeedbackForm,
    CONTACT_US_FORM: ContactUsForm,
    LEAGUE_DELETE: DeleteLeagueConfirmation,
    LEAGUE_LEAVE: LeaveLeagueConfirmation,
    LEAGUE_REMOVE_MEMBER: RemoveMemberConfirmation,
    POST_PREDICTION_BANNER: ConnectedPostPredictionOffer,
    PROFILE: Profile
};

export const Modal = ({ type, props, canDismiss, dismiss, show }) => {
    useEffect(() => {
        const onKeyUp = ({ key }) => {
            if (canDismiss && key === 'Escape') {
                dismiss();
            }
        };

        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [dismiss, canDismiss]);

    const clickMask = e => {
        e.preventDefault();
        e.stopPropagation();
        if (canDismiss) {
            dismiss();
        }
    };

    if (!type) {
        return null;
    }

    const Component = modalTypes[type];

    const DynamicComponent = type.match(
        /^(LEAGUE_DELETE|LEAGUE_LEAVE|LEAGUE_REMOVE_MEMBER)$/
    )
        ? Styled.LeagueModal
        : Styled.Modal;

    return (
        <Styled.Mask show={show} onClick={clickMask}>
            <DynamicComponent
                data-test-id="modal"
                onClick={e => {
                    e.stopPropagation();
                    return false;
                }}
            >
                {canDismiss ? (
                    <Styled.Close
                        data-char={`\uF115`}
                        onClick={clickMask}
                        className="close-modal"
                        data-test-id="modal-close-button"
                    />
                ) : null}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...props} onDismiss={dismiss} />
            </DynamicComponent>
        </Styled.Mask>
    );
};

const mapStateToProps = state => ({
    type: state.modal.type || null,
    show: state.modal.show || false,
    props: state.modal.props || null,
    canDismiss: state.modal.canDismiss || false
});

const mapDispatchToProps = dispatch => ({
    dismiss: () => dispatch(dismissModal())
});

Modal.propTypes = {
    type: PropTypes.string,
    props: PropTypes.shape({}),
    canDismiss: PropTypes.bool,
    dismiss: PropTypes.func.isRequired,
    show: PropTypes.bool
};

Modal.defaultProps = {
    type: null,
    props: null,
    canDismiss: false,
    show: false
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
