import styled from '@emotion/styled/macro';

export const HeaderText = styled.h1`
    font-weight: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.large};
    text-align: center;
    letter-spacing: 0.08em;
    color: ${props => props.theme.colours.black};
`;

export const BodyText = styled.p`
    font-weight: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.large};
    text-align: center;
    letter-spacing: 0.08em;
    color: ${props => props.theme.colours.black};
`;
