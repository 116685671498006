import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Common/Button';

export const SummaryListLink = styled(Link)`
    display: block;
    padding: ${props => props.theme.spacing.medium};
    color: inherit;
    text-decoration: none;
    width: 100%;
`;

export const PreviousSummaryListItem = styled.div`
    display: grid;
    padding: ${props => props.theme.spacing.medium};
    grid-template-columns: ${props =>
        props.isPrevious ? `2fr 1fr` : `1fr 3fr`};
    gap: ${props => props.theme.spacing.medium};
`;

export const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
`;

export const ActionButton = styled(Button)`
    margin-bottom: 5px;
`;

export const RedActionButton = styled(ActionButton)`
    background-color: ${props => props.theme.colours.red};
    color: ${props => props.theme.colours.white};

    :hover,
    &:focus {
        background-color: ${props => props.theme.colours.redDark};
    }
`;

export const ActionButtonLink = styled(Link)`
    display: inline-block;
    width: 101px;
    margin: 0 0 ${props => props.theme.spacing.normal} 0;
    font-size: ${props => props.theme.fontSize.normal};
    background-color: ${props => props.theme.colours.linkBlue};
    border: 1px solid ${props => props.theme.colours.linkBlueBorder};
    color: ${props => props.theme.colours.white};
    padding: 4px;
    font-family: ${props => props.theme.fonts.medium};
    text-align: center;
    border-radius: 3px;
    text-decoration: none;
    box-sizing: border-box;

    :hover {
        cursor: pointer;
        background-color: ${props => props.theme.colours.linkBlueHover};
        border-color: ${props => props.theme.colours.linkBlueHoverBorder};
    }
`;

export const SecondaryActionButton = styled(ActionButton)`
    background-color: ${props => props.theme.colours.white};
    border: 1px solid ${props => props.theme.colours.borderGrey};
    color: ${props => props.theme.colours.black};

    :hover {
        border: 1px solid ${props => props.theme.colours.borderGrey};
        background-color: ${props => props.theme.colours.backgroundGrey};
    }
`;
