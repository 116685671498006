import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import AppLink from '../../../../Common/AppLink';
import { useCookieConsentContext } from '../../../../../hooks/useCookieConsents';
import buildCookieUrl from '../../../../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../../../../config';

const MoreNav = ({
    closeMobileNav,
    navItems,
    setNavigationEvent,
    startingIndex
}) => {
    const [moreOpen, setMoreOpen] = useState(false);
    const toggleMoreDropdown = () => {
        setMoreOpen(!moreOpen);
    };

    const wrapperRef = useRef(null);

    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMoreOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const { cookieConsentQuery } = useCookieConsentContext();
    return (
        <Styled.MoreContainer ref={wrapperRef}>
            <Styled.BaseNavLinkWrapper>
                <Styled.MoreItemDropdown
                    id="more-dropdown-btn"
                    data-test-id="nav-bar-more-dropdown"
                    data-target-id="s6-nav-more-dropdown-item"
                    onClick={toggleMoreDropdown}
                >
                    More
                </Styled.MoreItemDropdown>
            </Styled.BaseNavLinkWrapper>
            <Styled.MoreLinks className="nav-more" hide={!moreOpen}>
                {navItems.map((item, index) => (
                    <Styled.MoreNavLinkWrapper key={item.label}>
                        {item.external && (
                            <Styled.MoreLink
                                onClick={() =>
                                    setNavigationEvent(
                                        item.label,
                                        startingIndex + index,
                                        buildCookieUrl(
                                            OAUTH_URI,
                                            item.link,
                                            cookieConsentQuery
                                        )
                                    )
                                }
                            >
                                <AppLink
                                    testId={item.testId}
                                    targetId={item.targetId}
                                    app={item.appName || ''}
                                    link={buildCookieUrl(
                                        OAUTH_URI,
                                        item.link,
                                        cookieConsentQuery
                                    )}
                                >
                                    {item.label}
                                </AppLink>
                            </Styled.MoreLink>
                        )}
                        {!item.external && (
                            <Styled.MoreLocalLink
                                to={item.link}
                                onClick={() => {
                                    toggleMoreDropdown();
                                    closeMobileNav();
                                    setNavigationEvent(
                                        item.label,
                                        startingIndex + index,
                                        `https://super6.skysports.com${item.link}`
                                    );
                                }}
                                data-test-id={item.testId}
                                data-target-id={item.targetId}
                            >
                                {item.label}
                            </Styled.MoreLocalLink>
                        )}
                    </Styled.MoreNavLinkWrapper>
                ))}
            </Styled.MoreLinks>
        </Styled.MoreContainer>
    );
};

MoreNav.propTypes = {
    closeMobileNav: PropTypes.func.isRequired,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            show: PropTypes.bool.isRequired,
            section: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            dispatchActionMethod: PropTypes.shape({
                type: PropTypes.string
            }),
            link: PropTypes.string.isRequired,
            path: PropTypes.arrayOf(PropTypes.string),
            analytics: PropTypes.string,
            id: PropTypes.string,
            testId: PropTypes.string.isRequired,
            targetId: PropTypes.string.isRequired
        })
    ).isRequired,
    setNavigationEvent: PropTypes.any,
    startingIndex: PropTypes.number.isRequired
};

MoreNav.defaultProps = {
    setNavigationEvent: () => {}
};

export default MoreNav;
