import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { requestGlobalBanner } from '../../../store/modules/offer/actions';
import GenericOffer from '../GenericOffer';
import { selectHasCheckedAuth } from '../../../store/modules/auth/selectors';
import { sendBannerEvent } from '../../../helpers/GoogleAnalytics/gaHelper';
import elementText from '../../../helpers/GoogleAnalytics/element_text';
import modules from '../../../helpers/GoogleAnalytics/modules';
import actions from '../../../helpers/GoogleAnalytics/actions';

export const GlobalBanner = ({
    getGlobalBanner,
    globalBanner,
    hasCheckedAuth
}) => {
    useEffect(() => {
        if (hasCheckedAuth) {
            getGlobalBanner();
        }
    }, [getGlobalBanner, hasCheckedAuth]);

    if (globalBanner) {
        return (
            <Styled.OfferAsset
                data-target-id="s6-home-global-banner-link"
                onClick={() => {
                    sendBannerEvent(
                        elementText.banner.betHere,
                        modules.leaderboard.banner,
                        actions.clicked,
                        1,
                        globalBanner.linkUri
                    );
                }}
            >
                <GenericOffer offer={globalBanner} />
            </Styled.OfferAsset>
        );
    }

    return null;
};

const mapStateToProps = state => ({
    globalBanner: state.offer.globalBanner,
    hasCheckedAuth: selectHasCheckedAuth(state)
});

const mapDispatchToProps = dispatch => ({
    getGlobalBanner: () => {
        dispatch(requestGlobalBanner());
    }
});

GlobalBanner.propTypes = {
    getGlobalBanner: PropTypes.func.isRequired,
    globalBanner: PropTypes.shape({
        linkUri: PropTypes.string,
        desktopImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        mobileImageset: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }),
        app: PropTypes.string
    }),
    hasCheckedAuth: PropTypes.bool.isRequired
};
GlobalBanner.defaultProps = { globalBanner: null };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBanner);
