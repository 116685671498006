import { css } from '@emotion/core';

const base = css`
    html,
    body {
        font-family: 'Sky Regular';
        font-size: 14px;
    }

    * {
        transition: color 0.3s ease, background-color 0.3s ease;
    }

    button {
        font-family: 'Sky Regular';
        :hover {
            cursor: pointer;
        }
    }

    body {
        background: #f4f4f4;
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        -webkit-text-size-adjust: 100%;
    }

    #react-root {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .grow-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .App {
        background: #eeeeee;
        flex: 1;
        display: flex;
        flex-direction: column;

        > * {
            flex: 1 1 auto;
            width: 100%;
        }

        &[data-has-header='true'] {
            @media (max-width: 1023px) {
                margin-top: 100px;
            }
        }
    }

    strong {
        font-family: 'Sky Medium';
    }

    .sba-open body {
        @media (min-width: 1024px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
`;

export default base;
