import * as types from './types';

export const triggerCountMetric = (key, labels) => ({
    type: types.TRIGGER_COUNT_METRIC,
    key,
    labels
});

export const captureLcp = (page, value) => ({
    type: types.CAPTURE_LCP,
    page,
    value
});
