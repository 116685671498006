import React, {
    useContext,
    useEffect,
    useState,
    createContext,
    useRef
} from 'react';
import PropTypes from 'prop-types';

const EVENT_TYPE = 'TrackingConsentChange';
const INITIAL_CONSENT = [];

export const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
    const [cookieConsents, setCookieConsents] = useState(INITIAL_CONSENT);
    const [cookieConsentQuery, setCookieConsentQuery] = useState('');
    const prevCountRef = useRef(INITIAL_CONSENT);

    useEffect(() => {
        const eventListener = event => {
            const trackingConsent = event.target.TrackingConsent;
            const consentGroups = trackingConsent.getConsentGroups();
            setCookieConsents(consentGroups);
        };

        window.addEventListener(EVENT_TYPE, eventListener);
    }, []);

    useEffect(() => {
        if (
            (prevCountRef.current.length !== 0 &&
                prevCountRef.current.length !== cookieConsents.length) ||
            !prevCountRef.current.every((v, i) => v === cookieConsents[i])
        ) {
            window.location.reload();
        }

        if (cookieConsents.length) {
            setCookieConsentQuery(cookieConsents.join(','));
        }

        prevCountRef.current = cookieConsents;
    }, [cookieConsents]);

    return (
        <CookieConsentContext.Provider
            value={{
                cookieConsentQuery
            }}
        >
            {children}
        </CookieConsentContext.Provider>
    );
};

CookieConsentProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const useCookieConsentContext = () => useContext(CookieConsentContext);
