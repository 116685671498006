/* eslint-disable import/no-unused-modules */
const getFormattedPositionChange = position => {
    let positionChange = parseInt(position, 10);

    if (Number.isNaN(positionChange)) {
        return null;
    }

    positionChange *= -1;

    switch (true) {
        case Number.isNaN(positionChange):
            return null;

        case positionChange === 0:
            return '=';

        case positionChange > 99:
            return '+99';

        case positionChange < -99:
            return '-99';

        default:
            return `${positionChange > 0 ? '+' : ''}${positionChange}`;
    }
};

export { getFormattedPositionChange as default };
