/* eslint-disable import/no-unused-modules */
import createPredictionStringForFixture from '../createPredictionStringForFixture';

function createPredictionsString({ predictions, fixtures }) {
    if (!predictions) {
        throw new Error('No predictions provided');
    }

    if (!fixtures) {
        throw new Error('No fixtures provided');
    }

    return fixtures.reduce(
        (strings, fixture, index) =>
            createPredictionStringForFixture({
                strings,
                fixture,
                predictions,
                index
            }),
        { predictionsStringShort: '', predictionsStringFull: '' }
    );
}

export { createPredictionsString as default };
