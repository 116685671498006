import { showModal } from '../../../../store/modules/modal/actions';
import {
    BET,
    buildTransferLink,
    ITV7,
    SPORTINGLIFE,
    EXTRA
} from '../../../../helpers/transfer';

// eslint-disable-next-line import/prefer-default-export
export const getNavItems = ({
    showPlay,
    showLive,
    isOnDesktop,
    showExtra,
    showPerformanceHub,
    showHeadToHead
}) => [
    {
        show: showPlay,
        section: 'Super 6',
        label: 'Play',
        link: '/play',
        path: ['/play', '/played'],
        analytics: '[NavMenu]-PlaySuper6',
        id: 'play-game-nav',
        testId: 'nav-bar-play',
        targetId: 's6-nav-play-item',
        isBetContent: false
    },
    {
        show: showLive,
        section: 'Super 6',
        label: 'Live Scoring',
        link: '/inplay',
        path: ['/inplay'],
        analytics: '[NavMenu]-PlaySuper6',
        id: 'play-game-nav',
        testId: 'nav-bar-inplay',
        targetId: 's6-nav-inplay-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'Super 6',
        label: 'Leaderboard',
        link: '/leaderboard/round',
        path: ['/leaderboard'],
        analytics: '[NavMenu]-Leaderboard',
        id: 'leaderboard-nav',
        testId: 'nav-bar-leaderboard',
        targetId: 's6-nav-leaderboard-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'Super 6',
        label: 'Results',
        link: '/schedule',
        path: ['/schedule', '/results'],
        analytics: '[NavMenu]-Results',
        id: 'results-nav',
        testId: 'nav-bar-results',
        targetId: 's6-nav-results-item',
        isBetContent: false
    },
    {
        show: showHeadToHead,
        section: 'Super 6',
        label: 'Head to Head',
        link: '/headtohead/results',
        path: ['/headtohead'],
        analytics: '[NavMenu]-headToHead',
        id: 'head-to-head-nav',
        testId: 'nav-bar-head-to-head',
        targetId: 's6-nav-head-to-head-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'Super 6',
        label: 'Leagues',
        link: '/league',
        path: ['/league'],
        analytics: '[NavMenu]-Leagues',
        id: 'private-league-nav',
        testId: 'nav-bar-leagues',
        targetId: 's6-nav-leagues-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'Super 6',
        label: 'Pundits',
        link: '/pundits',
        path: ['/pundits'],
        analytics: '[NavMenu]-Pundits',
        id: 'pundits-nav',
        testId: 'nav-bar-pundits',
        targetId: 's6-nav-pundits-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'Super 6',
        label: 'Feedback',
        link: '#',
        analytics: '[NavMenu]-Feedback',
        path: ['#'],
        dispatchActionMethod: showModal('FEEDBACK_FORM', {}, true),
        id: 'feedback-nav',
        testId: 'nav-bar-feedback',
        targetId: 's6-nav-feedback-item',
        isBetContent: false
    },
    {
        show: showExtra,
        section: 'Super 6 Extra',
        label: isOnDesktop ? 'Super 6 Extra' : 'Play',
        link: buildTransferLink(EXTRA, '?dcmp=s6_nav'),
        id: 'extra-nav',
        testId: 'nav-bar-extra',
        targetId: 's6-nav-extra-item',
        external: true,
        appLink: true,
        appName: 'super6extra',
        alt: 'Super6 Extra',
        isBetContent: false
    },
    {
        show: true,
        section: 'More',
        label: 'Official Club Leagues',
        link: '/club-leagues',
        path: ['/club-leagues'],
        analytics: '[NavMenu]-ClubLeagues',
        id: 'club-leagues-nav',
        testId: 'nav-bar-official-club-leagues',
        targetId: 's6-nav-more-club-leagues-item',
        isBetContent: false
    },
    {
        show: showPerformanceHub,
        section: 'More',
        label: 'My Performance Hub',
        link: '/myperformancehub',
        path: ['/myperformancehub'],
        analytics: '[NavMenu]-MyPerformanceHub',
        id: 'my-performance-hub-nav',
        testId: 'nav-bar-my-performance-hub',
        targetId: 's6-nav-more-my-performance-hub-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'More',
        label: 'Sky Bet',
        link: buildTransferLink(BET, '?dcmp=s6_nav&AFF_ID=1197312425'),
        analytics: '[ProductXSell]-Bet',
        external: true,
        appName: 'skybet',
        testId: 'nav-bar-more-skybet',
        targetId: 's6-nav-more-item',
        isBetContent: true
    },
    {
        show: true,
        section: 'More',
        label: 'ITV7',
        link: buildTransferLink(ITV7, '?dcmp=s6_nav&AFF_ID=1197328077'),
        analytics: '[ProductXSell]-P7',
        external: true,
        appName: 'skyc4racingpick6',
        testId: 'nav-bar-more-itv7',
        targetId: 's6-nav-more-itv7-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'More',
        label: 'Sporting Life',
        link: buildTransferLink(SPORTINGLIFE, '?dcmp=s6_nav'),
        analytics: '[ProductXSell]-SL',
        external: true,
        testId: 'nav-bar-more-sportinglife',
        targetId: 's6-nav-more-sporting-life-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'More',
        label: 'FAQs',
        link: '/faq',
        analytics: '[NavMenu]-FAQ',
        testId: 'nav-bar-more-faq',
        targetId: 's6-nav-more-faq-item',
        isBetContent: false
    },
    {
        show: true,
        section: 'More',
        label: 'T&Cs',
        link: '/terms',
        analytics: '[NavMenu]-Terms',
        testId: 'nav-bar-more-tandc',
        targetId: 's6-nav-more-tandc-item',
        isBetContent: false
    }
];
