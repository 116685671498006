import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getApp,
    shouldOpenAppLinksNatively,
    isAppWrapper,
    isUsingIOSV3OrHigher
} from '../../../helpers/appBridge';
import { sendOpenAppLink } from '../../../store/modules/appBridge/actions';
import { getAuthService } from '../../../auth/auth';
import AppLinkButton from './styles';
import { selectIsLoggedIn } from '../../../store/modules/auth/selectors';

/**
 * Append SSO token and inAppWrapper query params
 */
const appendToLink = async (isLoggedIn, link) => {
    if (!isAppWrapper()) {
        return Promise.resolve(link);
    }

    const newLink = `${link}${
        link.indexOf('?') < 0 ? '?' : '&'
    }inAppWrapper=true`;

    if (!isLoggedIn) {
        return Promise.resolve(newLink);
    }
    return new Promise(resolve => {
        getAuthService().getSsoTransferToken((err, ssoTransferToken) => {
            if (err || !ssoTransferToken) {
                resolve(newLink);
            } else {
                resolve(`${newLink}&sba_transferToken=${ssoTransferToken}`);
            }
        });
    });
};

const AppLink = ({
    isLoggedIn,
    openAppLink,
    app,
    link,
    children,
    testId,
    targetId,
    className,
    ariaHidden,
    tabIndex
}) => {
    if (isUsingIOSV3OrHigher() || (shouldOpenAppLinksNatively() && !!app)) {
        return (
            <AppLinkButton
                className={className}
                type="button"
                data-test-id={testId}
                data-target-id={targetId}
                onClick={async () => {
                    const newLink = await appendToLink(isLoggedIn, link);
                    openAppLink(app, newLink.replace(/(^\w+:|^)\/\//, ''));
                }}
            >
                {children}
            </AppLinkButton>
        );
    }

    if (getApp() === 'android') {
        return (
            <AppLinkButton
                className={className}
                type="button"
                data-test-id={testId}
                data-target-id={targetId}
                onClick={async () => {
                    window.location.href = await appendToLink(isLoggedIn, link);
                }}
            >
                {children}
            </AppLinkButton>
        );
    }

    return (
        <a
            className={className}
            rel="noopener noreferrer external"
            target="_blank"
            href={link}
            data-test-id={testId}
            data-target-id={targetId}
            aria-hidden={ariaHidden}
            tabIndex={tabIndex}
        >
            {children}
        </a>
    );
};

AppLink.propTypes = {
    openAppLink: PropTypes.func.isRequired,
    app: PropTypes.string,
    link: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    isLoggedIn: PropTypes.bool,
    testId: PropTypes.string,
    targetId: PropTypes.string,
    className: PropTypes.string,
    ariaHidden: PropTypes.string,
    tabIndex: PropTypes.string
};

AppLink.defaultProps = {
    app: '',
    isLoggedIn: null,
    testId: null,
    targetId: '',
    className: '',
    ariaHidden: null,
    tabIndex: null
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state)
});

const mapDispatchToProps = dispatch => ({
    openAppLink: (app, link) => dispatch(sendOpenAppLink(app, link))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLink);
