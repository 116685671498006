import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteLeague as deleteLeagueAction } from '../../../../store/modules/league/actions';
import Confirmation from '../../../../components/Modals/Confirmation';

const DeleteLeagueConfirmation = ({ slug, deleteLeague, onDismiss }) => (
    <Confirmation
        title="Delete League"
        message="Are you sure you want to"
        messageSecondLine="delete the league?"
        confirmLabel="Delete"
        onConfirm={() => deleteLeague(slug)}
        onDismiss={onDismiss}
    />
);

const mapDispatchToProps = dispatch => ({
    deleteLeague: slug => dispatch(deleteLeagueAction(slug))
});

DeleteLeagueConfirmation.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    deleteLeague: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(DeleteLeagueConfirmation);
