import React from 'react';
import PropTypes from 'prop-types';
import Tabs, { Tab } from '../Common/Tabs/Tabs';
import * as Styled from './styles';

const LeaderboardTabs = ({
    period,
    roundTemplate,
    monthTemplate,
    seasonTemplate,
    infoTemplate,
    lastCompleteRoundId
}) => (
    <Tabs active={period}>
        <Tab
            isDisabled={!lastCompleteRoundId}
            id="round"
            to={roundTemplate}
            testId="leaderboard-tab-round"
        >
            Round
        </Tab>
        <Tab
            isDisabled={!lastCompleteRoundId}
            id="month"
            to={monthTemplate}
            testId="leaderboard-tab-month"
        >
            Month
        </Tab>
        <Tab id="season" to={seasonTemplate} testId="leaderboard-tab-season">
            Season
        </Tab>
        <Tab id="info" to={infoTemplate} testId="leaderboard-tab-info">
            <Styled.InfoIconParent>
                <Styled.InformationIcon>
                    <span>i</span>
                </Styled.InformationIcon>
            </Styled.InfoIconParent>
        </Tab>
    </Tabs>
);

LeaderboardTabs.propTypes = {
    period: PropTypes.string.isRequired,
    lastCompleteRoundId: PropTypes.number,
    roundTemplate: PropTypes.string,
    monthTemplate: PropTypes.string,
    seasonTemplate: PropTypes.string,
    infoTemplate: PropTypes.string
};

LeaderboardTabs.defaultProps = {
    lastCompleteRoundId: 0,
    roundTemplate: null,
    monthTemplate: null,
    seasonTemplate: null,
    infoTemplate: null
};

export default LeaderboardTabs;
