export const mergeUserResults = (results, userResult) => {
    results.push(userResult);

    // If the current user has a position, then we need to resort the table
    if (userResult.position && userResult.position !== 0) {
        results.sort((resultA, resultB) => resultA.position - resultB.position);
    }
};

export const getFollowingTableWarning = (userId, followees, leaderboard) => {
    if (!userId) {
        return 'You need to be logged in to view your followed users';
    }

    if (!followees || followees.length < 1) {
        return 'You are not following anyone at the moment';
    }

    if (!leaderboard || leaderboard.results.length < 1) {
        return 'This table is being calculated, please check back later';
    }

    return null;
};
