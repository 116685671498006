import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './mastheadStyles';
import MatchStatus from '../../../../components/MatchStatus';

const ResultMastheadTitle = ({ text, align, isInplay, hasWon }) => {
    if (isInplay) {
        return (
            <Styled.Title
                align={align}
                data-test-id="masthead-title-match-status"
            >
                <MatchStatus textInRed="live" textInBlue="points" />
            </Styled.Title>
        );
    }

    if (hasWon) {
        return (
            <Styled.Title hasWon={hasWon} align={align}>
                <Styled.TitleSuccess>Congratulations,</Styled.TitleSuccess>
                you&apos;ve won in the prize draw!
            </Styled.Title>
        );
    }

    return <Styled.Title align={align}>{text}</Styled.Title>;
};

ResultMastheadTitle.defaultProps = {
    text: `You've missed your chance`,
    align: 'center',
    isInplay: false,
    hasWon: false
};

ResultMastheadTitle.propTypes = {
    text: PropTypes.string,
    align: PropTypes.string,
    isInplay: PropTypes.bool,
    hasWon: PropTypes.bool
};

export default ResultMastheadTitle;
