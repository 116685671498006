import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as Styled from './styles';
import { selectActiveRound } from '../../store/modules/round/selectors';
import { convertCustomFormat } from '../../helpers/dateFormatters';

export const DeadlineTitle = ({ round }) => {
    const getTitleText = () => {
        const roundId = round?.id ?? '';

        switch (round?.status) {
            case 'open': {
                const convertedTime = convertCustomFormat(
                    round?.endDateTime,
                    'Do MMM @ h:mma'
                );

                return `ROUND ${roundId} - DEADLINE ${convertedTime}`;
            }
            case 'inplay':
                return `ROUND ${roundId} - IN PLAY`;
            default:
                return `ROUND ${roundId}`;
        }
    };

    return (
        <Styled.DeadlineTitle>
            <Styled.DeadlineTitleText>
                {getTitleText()}
            </Styled.DeadlineTitleText>
        </Styled.DeadlineTitle>
    );
};

DeadlineTitle.propTypes = {
    round: PropTypes.shape({
        id: PropTypes.number.isRequired,
        endDateTime: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
};

const mapStateToProps = state => ({
    round: selectActiveRound(state)
});

export default connect(mapStateToProps)(DeadlineTitle);
