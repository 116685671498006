import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const Winners = () => (
    <>
        <li>
            Winners and Entrants Verification
            <Styled.OrderedList>
                <li>
                    Winners &#40;as determined in accordance with the terms set
                    out in Clause 6 &#40;
                    <Styled.EmphasisedText>Prizes</Styled.EmphasisedText>
                    &#41;&#41; will be notified by email or telephone number
                    registered on their account on the day on which the relevant
                    round is completed, or on the following Business Day &#40;a
                    &quot;Business Day&quot; being a day other than a Saturday,
                    Sunday or public holiday in England when banks in London are
                    open for business&#41;.
                </li>
                <li>
                    It is each player&apos;s sole responsibility to ensure that
                    their contact details are up to date on their account.
                </li>
                <li>
                    Winners will be required to verify &#40;with evidence
                    satisfactory to the Promoter&#41; their:
                    <Styled.OrderedList>
                        <li>age;</li>
                        <li>identity;</li>
                        <li>residence; and</li>
                        <li>
                            any other verification information requested by the
                            Promoter, to the satisfaction of the Promoter before
                            they are eligible to receive their Prize.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    Any announcement made regarding winners on Sky properties
                    &#40;TV, online, or otherwise&#41; is pending verification
                    and is not final confirmation.
                </li>
                <li>
                    A full list of Prize winners by name will be available in
                    the results sections of the Super 6 Website at{' '}
                    <Link to="/">https://super6.skysports.com/</Link>.
                </li>
                <li>
                    If any winner is not able, for whatever reason:
                    <Styled.OrderedList>
                        <li>to accept a Prize;</li>
                        <li>
                            verify their registration information to the
                            satisfaction of the Promoter;
                        </li>
                        <li>
                            has been found to have breached these Terms and
                            Conditions; or
                        </li>
                        <li>
                            cannot be contacted within five Business Days, the
                            Promoter reserves the right to disqualify that
                            winner &#40;and their eligibility to receive any
                            Prizes under the Competition&#41; and award the
                            applicable Prize to another participant, if
                            applicable.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    Winners should allow up to forty&#45;five days for payment
                    and delivery of the applicable Prize.
                </li>
                <li>
                    The Promoter&apos;s decision is final and legally binding on
                    all entrants in relation to all aspects of the Competition
                    including &#40;without limitation&#41;, allocation of the
                    Prizes and no correspondence will be entered into.
                </li>
                <li>
                    Entries that do not comply in full with these Terms and
                    Conditions will be disqualified and will not be eligible to
                    receive any Prizes.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Winners;
