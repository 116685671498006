import styled from '@emotion/styled/macro';

export const StepCard = styled.div`
    border-radius: ${props => props.theme.spacing.xlarge2};
    background-color: ${props => props.theme.colours.white};
    flex-basis: 100%;
`;

export const StepTitle = styled.h1`
    font-size: ${props => props.theme.fontSize.large2};
    text-align: center;
    font-family: ${props => props.theme.fonts.skySportsD};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-weight: 700;
    padding: ${props => props.theme.spacing.xlarge};
`;

export const StepLine = styled.div`
    width: 90%;
    border-bottom: ${props => `1px solid ${props.theme.colours.brightGray}`};
    margin: 0 auto;
    padding: 0;
`;

export const StepBody = styled.div`
    justify-content: center;
    display: flex;
`;

export const BodyText = styled.p`
    text-align: left;
    padding: ${props =>
        `${props.theme.spacing.xlarge} ${props.theme.spacing.xlarge2} ${props.theme.spacing.xlarge2}`};
    font-size: ${props => props.theme.fontSize.large2};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.regular};
`;

export const StepsContainer = styled.div`
    display: flex;
    row-gap: ${props => props.theme.spacing.medium};
    flex-flow: row wrap;
`;

export const PrizeWinnerHeader = styled.h3`
    width: 320px;
    margin: 0 auto;
    padding: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.large};
    font-family: ${props => props.theme.fonts.medium};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.colours.backgroundGrey};
        margin-bottom: ${props => props.theme.spacing.large};
    }
`;

export const ReducedWidthDesktopContainer = styled.div`
    margin: ${props => props.theme.spacing.large} 0;
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 70%;
        margin: 0 auto ${props => props.theme.spacing.large} auto;
    }
`;

export const RoundFixturesContainer = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing.large};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        grid-template-columns: 1fr 1fr;
    }
`;
