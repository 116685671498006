import styled from '@emotion/styled/macro';

export const LeaderboardContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.card.borderRadius};
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.spacing.small};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: ${props => props.theme.spacing.medium};
    }
`;

export const LeaderboardContainerWrapper = styled.div`
    margin-top: ${props => props.theme.spacing.medium};
    border-radius: inherit;
`;

export const LeagueDetailsWrapper = styled.div`
    margin-bottom: ${props => props.theme.spacing.medium};
`;
