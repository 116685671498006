import * as types from './types';

export const requestGlobalBanner = () => ({
    type: types.REQUEST_GLOBAL_BANNER
});

export const receiveGlobalBanner = offer => ({
    type: types.RECEIVE_GLOBAL_BANNER,
    payload: offer
});

export const requestPostPredictionBanner = () => ({
    type: types.REQUEST_POST_PREDICTION_BANNER
});

export const requestBetslipOffer = () => ({
    type: types.REQUEST_BETSLIP_OFFER
});

export const receiveBetslipOffer = payload => ({
    type: types.RECEIVE_BETSLIP_OFFER,
    payload
});
