import styled from '@emotion/styled/macro';
import { ContentPageContainer } from '../../components/Common/Page/styles';
import StyledPageTitle from '../../components/Common/PageTitle/styles';

export const CookiePageContainer = styled(ContentPageContainer)`
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        padding-left: ${props => props.theme.spacing.normal};
        padding-right: ${props => props.theme.spacing.normal};
    }

    ${StyledPageTitle} {
        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            margin-left: -${props => props.theme.spacing.normal};
            margin-right: -${props => props.theme.spacing.normal};
        }
    }

    p {
        line-height: ${props => props.theme.lineHeight.normal};
        margin: 0 0 ${props => props.theme.spacing.medium};
    }
`;

export const SectionHeader = styled.h4`
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.xxlarge};
    padding-top: ${props => props.theme.spacing.medium};
    margin: ${props => props.theme.spacing.medium} 0;
    font-weight: 500;
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const SubSectionHeader = styled(SectionHeader)`
    font-size: ${props => props.theme.fontSize.xlarge};
`;

export const SubSectionSubHeader = styled(SectionHeader)`
    font-size: ${props => props.theme.fontSize.large};
`;
