export const REQUEST_ROUND_RESULTS = 'results/REQUEST_ROUND_RESULTS';
export const RECEIVE_ROUND_RESULTS = 'results/RECEIVE_ROUND_RESULTS';

export const REQUEST_ROUND_PREDICTIONS = 'results/REQUEST_ROUND_PREDICTIONS';
export const RECEIVE_ROUND_PREDICTIONS = 'results/RECEIVE_ROUND_PREDICTIONS';

export const REQUEST_ROUND_PREDICTIONS_BY_USER =
    'results/REQUEST_ROUND_PREDICTIONS_BY_USER';
export const RECEIVE_ROUND_PREDICTIONS_BY_USER =
    'results/RECEIVE_ROUND_PREDICTIONS_BY_USER';
