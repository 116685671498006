import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';

export const PopularBar = styled.div`
    border-radius: ${props => props.theme.radius.normal};
    border: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.borderLightGrey}`};
    background-color: ${props => props.theme.spacing.large};
    height: ${props => props.theme.spacing.medium};
    width: auto;
`;

const animation = props => keyframes`
    0% { width: 0%; }
    100% {width: ${props.chance}%}
`;

export const PopularBarPercentageMarker = styled.div`
    background-color: ${props => props.theme.colours.bluePrimary};
    height: ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.radius.normal};
    width: ${props => `${props.chance}%`};
    max-width: 100%;
    animation-name: ${animation};
    animation-iteration-count: 1;
    animation-duration: 1s;
`;

export const PopularTeamPercentageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-row-gap: ${props => props.theme.spacing.large};
    grid-column-gap: ${props => props.theme.spacing.large};
    padding: ${props => props.theme.spacing.medium};
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const PopularTeamBadge = styled.img`
    width: ${props => props.theme.iconSize.xlarge};
`;

export const PopularContainerRight = styled.div`
    text-align: left;
`;

export const PopularContainerLeft = styled.div`
    text-align: center;
`;

export const Popular = styled.div`
    justify-content: center;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.spacing.xxlarge};
    width: 100%;
`;
