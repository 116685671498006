/* eslint-disable import/no-unused-modules */
import { takeLeading, call } from 'redux-saga/effects';
import * as types from './types';
import { postMessage } from '../../../helpers/appBridge';

function* sendMessage(action) {
    try {
        const { message, data } = action;
        yield call(postMessage(message, data));
    } catch (err) {
        // No need to do anything here
    }
}

function setVersion(action) {
    const { app, version } = action;
    window.localStorage.setItem('app', app);
    window.localStorage.setItem('version', version);
}

export function* watchSendMessage() {
    yield takeLeading(types.SEND_MESSAGE, sendMessage);
}

export function* watchSetVersion() {
    yield takeLeading(types.SET_VERSION, setVersion);
}

export const sagas = [watchSendMessage, watchSetVersion];
