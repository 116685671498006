import styled from '@emotion/styled/macro';
import AppLinkButton from '../../../Common/AppLink/styles';

export const AdContainer = styled.div`
    background-color: #fcee30;
    padding: ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.large};
    font-family: ${props => props.theme.fonts.medium};
    margin: 10px 0;
`;

export const Details = styled.div`
    a,
    ${AppLinkButton} {
        text-decoration: none;
        color: ${props => props.theme.colours.black};
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        line-height: 1.15em;
        font-size: ${props => props.theme.fontSize.large};
        padding-bottom: ${props => props.theme.spacing.normal};
        margin-bottom: ${props => props.theme.spacing.normal};
    }
`;

export const TermsAndConditions = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colours.black};
    background-color: #fcee30;
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: 1.5em;
`;
