import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissMessage as dismissMessageAction } from '../../store/modules/message/actions';
import Message from './Message';
import * as Styled from './styles';

const Messages = ({ messages, dismissMessage }) => {
    useEffect(() => {
        window.setTimeout(() => {
            messages.forEach(message => {
                dismissMessage(message.id)();
            });
        }, 5000);
    }, [messages, dismissMessage]);

    if (messages.length < 1) {
        return null;
    }

    return (
        <Styled.List>
            {messages.map(message => (
                <Styled.ListItem key={message.id}>
                    <Message
                        type={message.type}
                        message={message.message}
                        onDismiss={dismissMessage(message.id)}
                    />
                </Styled.ListItem>
            ))}
        </Styled.List>
    );
};

const mapStateToProps = state => ({
    messages: state.message.list
});

const mapDispatchToProps = dispatch => ({
    dismissMessage: id => () => dispatch(dismissMessageAction(id))
});

Messages.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    ).isRequired,
    dismissMessage: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
