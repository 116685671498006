import * as types from './types';

export const requestActiveRound = () => ({
    type: types.REQUEST_ACTIVE_ROUND
});

export const receiveActiveRound = round => ({
    type: types.RECEIVE_ACTIVE_ROUND,
    payload: round
});

export const activeRoundStateChange = () => ({
    type: types.ACTIVE_ROUND_STATE_CHANGE
});

export const activeRoundChange = () => ({
    type: types.ACTIVE_ROUND_CHANGE
});

export const requestRounds = () => ({
    type: types.REQUEST_ROUNDS
});

export const loadingRounds = () => ({
    type: types.LOADING_ROUNDS
});

export const failedLoadingRounds = () => ({
    type: types.FAILED_LOADING_ROUNDS
});

export const receiveRounds = rounds => ({
    type: types.RECEIVE_ROUNDS,
    payload: rounds
});

export const requestMatchStats = matchId => ({
    type: types.REQUEST_MATCH_STATS,
    matchId
});

export const setMatchStatsLoading = matchId => ({
    type: types.MATCH_STATS_LOADING,
    matchId
});

export const requestMatchStatsFailed = matchId => ({
    type: types.REQUEST_MATCH_STATS_FAILED,
    matchId
});

export const receiveMatchStats = (matchId, payload) => ({
    type: types.RECEIVE_MATCH_STATS,
    matchId,
    payload
});

export const requestMatchPundits = matchId => ({
    type: types.REQUEST_MATCH_PUNDITS,
    matchId
});

export const setMatchPunditsLoading = matchId => ({
    type: types.MATCH_PUNDITS_LOADING,
    matchId
});

export const requestMatchPunditsFailed = matchId => ({
    type: types.REQUEST_MATCH_PUNDITS_FAILED,
    matchId
});

export const receiveMatchPundits = (matchId, payload) => ({
    type: types.RECEIVE_MATCH_PUNDITS,
    matchId,
    payload
});

export const requestMatchBets = matchId => ({
    type: types.REQUEST_MATCH_BETS,
    matchId
});

export const setMatchBetsLoading = matchId => ({
    type: types.MATCH_BETS_LOADING,
    matchId
});

export const requestMatchBetsFailed = matchId => ({
    type: types.REQUEST_MATCH_BETS_FAILED,
    matchId
});

export const receiveMatchBets = (matchId, payload) => ({
    type: types.RECEIVE_MATCH_BETS,
    matchId,
    payload
});

export const requestPunditPredictions = roundId => ({
    type: types.REQUEST_PUNDIT_PREDICTIONS,
    roundId
});

export const setPunditPredictionsLoading = roundId => ({
    type: types.LOADING_PUNDIT_PREDICTIONS,
    roundId
});

export const receivePunditPredictions = (roundId, payload) => ({
    type: types.RECEIVE_PUNDIT_PREDICTIONS,
    roundId,
    payload
});

export const failedToLoadPunditPredictions = roundId => ({
    type: types.FAILED_LOAD_PUNDIT_PREDICTIONS,
    roundId
});

export const requestOutcomesForMarket = market => ({
    type: types.REQUEST_OUTCOMES_FOR_MARKET,
    market
});

export const receiveOutcomesForMarket = (market, payload) => ({
    type: types.RECEIVE_OUTCOMES_FOR_MARKET,
    market,
    payload
});

export const startLoadingMarket = market => ({
    type: types.START_LOADING_MARKET,
    market
});

export const hasLoadedMarket = market => ({
    type: types.HAS_LOADED_MARKET,
    market
});

export const requestHeadToHead = roundId => ({
    type: types.REQUEST_HEAD_TO_HEAD,
    roundId
});

export const loadedHeadToHead = roundId => ({
    type: types.LOADING_HEAD_TO_HEAD,
    roundId
});

export const failedloadedHeadToHead = roundId => ({
    type: types.FAILED_LOADING_HEAD_TO_HEAD,
    roundId
});

export const receiveHeadToHead = (roundId, payload) => ({
    type: types.RECEIVE_HEAD_TO_HEAD,
    roundId,
    payload
});

export const receiveLiveOdds = payload => ({
    type: types.RECEIVE_LIVE_ODDS,
    payload
});

export const requestLiveOdds = () => ({
    type: types.REQUEST_LIVE_ODDS
});

export const requestUpcomingRounds = () => ({
    type: types.REQUEST_UPCOMING_ROUNDS
});

export const receiveUpcomingRounds = payload => ({
    type: types.RECEIVE_UPCOMING_ROUNDS,
    payload
});

export const failedToLoadUpcomingRounds = () => ({
    type: types.FAILED_LOADING_UPCOMING_ROUNDS
});

export const loadingUpcomingRounds = () => ({
    type: types.LOADING_UPCOMING_ROUNDS
});
