/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { logout, receiveOauthTokens } from '../store/modules/auth/actions';
import { isOauthEnabled, getAuthService } from '../auth/auth';
import oauthApi from './oauth';
import { OAUTH_URI } from '../config';
import { isUsingIOSV3OrHigher } from '../helpers/appBridge';
import { sendMessage } from '../store/modules/appBridge/actions';
import { selectHasCheckedAuth } from '../store/modules/auth/selectors';

export const getRefreshTokenIfApplicable = response => {
    const originalRequest = response.config;

    if (!isOauthEnabled() || originalRequest.hasRetried) {
        return false;
    }

    const authService = getAuthService();
    const { refreshToken } = authService.getTokensFromBrowser();

    return refreshToken;
};

const callbacks = [];
let isInflight = false;

export const processAPICallbacks = async () => {
    const authService = getAuthService();
    const {
        id_token: idToken,
        access_token: accessToken
    } = authService.getTokensFromBrowser();

    while (callbacks.length > 0) {
        const { resolve, reject, originalRequest } = callbacks.shift();

        if (originalRequest.isTokenInfoRequest) {
            const originalData = JSON.parse(originalRequest.data);
            originalData.token = accessToken;
            originalRequest.data = JSON.stringify(originalData);
        } else {
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${idToken}`
            };
        }

        axios
            .request(originalRequest)
            .then(resolve)
            .catch(reject);
    }
};

const initiateRefresh = async (refreshToken, dispatch) => {
    try {
        if (window.Android) {
            window.Android.tokenExpired();
            return;
        }

        const tokens = await oauthApi.refreshTokens(refreshToken);
        const {
            id_token: idToken,
            access_token: accessToken,
            refresh_token: newRefreshToken
        } = tokens;

        getAuthService().storeTokensInBrowser(
            accessToken,
            idToken,
            newRefreshToken
        );
        dispatch(receiveOauthTokens(idToken));
        await processAPICallbacks();
    } catch (_) {
        dispatch(logout());

        while (callbacks.length > 0) {
            const { resolve, error } = callbacks.shift();
            resolve(error.response);
        }
    }
};

/**
 * In case there are multiple requests fired asynchronously that all return a 401
 * we stack up the retry callbacks and just do a single refresh. Once the refresh
 * is complete, we resolve/reject the retry requests accordingly
 */
export const refreshAndRetry = async (error, refreshToken, dispatch) =>
    new Promise(async (resolve, reject) => {
        const originalRequest = error.config;
        originalRequest.hasRetried = true;
        callbacks.push({
            error,
            originalRequest,
            resolve,
            reject
        });
        if (!isInflight) {
            isInflight = true;
            await initiateRefresh(refreshToken, dispatch);
            isInflight = false;
        }
    });

// eslint-disable-next-line import/no-unused-modules
export const handleRefreshAndRetry = (dispatch, error) => {
    const refreshToken = getRefreshTokenIfApplicable(error);
    if (!refreshToken && !window.Android) {
        dispatch(logout());
        return Promise.resolve(error.response);
    }
    if (window.Android) {
        if (!window.location.pathname !== '/') {
            window.location.href = '/';
        } else {
            return null;
        }
    }

    return refreshAndRetry(error, refreshToken, dispatch);
};

export const addAuthInterceptor = store => {
    axios.interceptors.response.use(
        async response => {
            const { dispatch } = store;
            let res = response;
            if (
                res.config.url.indexOf(OAUTH_URI) === 0 &&
                res.config.url.indexOf('/token_info') !== -1 &&
                res.status === 200 &&
                res.data &&
                !res.data.active
            ) {
                res.config.isTokenInfoRequest = true;
                const refreshToken = getRefreshTokenIfApplicable(res);
                if (refreshToken) {
                    res = await refreshAndRetry(res, refreshToken, dispatch);
                }
            }
            return res;
        },
        error => {
            const { dispatch, getState } = store;
            // If it's not a 401 then just return early
            if (error.response.status !== 401) {
                return Promise.resolve(error.response);
            }

            if (isUsingIOSV3OrHigher()) {
                dispatch(sendMessage('requestTokenRefresh', {}));
                return Promise.resolve(error.response);
            }

            if (!selectHasCheckedAuth(getState())) {
                return Promise.resolve(error.response);
            }
            return handleRefreshAndRetry(dispatch, error);
        }
    );
};
