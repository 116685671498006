import { css } from '@emotion/core';
import { FONT_BASE_PATH } from '../config';

const Fonts = css`
    @font-face {
        font-family: 'Sky Bold';
        src: url('${FONT_BASE_PATH}/SkyText-Bold.eot');
        src: url('${FONT_BASE_PATH}/SkyText-Bold.eot?#iefix') format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SkyText-Bold.woff') format('woff'),
            url('${FONT_BASE_PATH}/SkyText-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'skycons';
        src: url('${FONT_BASE_PATH}/skycons-Regular.eot');
        src: url('${FONT_BASE_PATH}/skycons-Regular.eot?#iefix')
                format('embedded-opentype'),
            url('${FONT_BASE_PATH}/skycons-Regular.woff') format('woff'),
            url('${FONT_BASE_PATH}/skycons-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Regular';
        src: url('${FONT_BASE_PATH}/SkyText-Regular.eot');
        src: url('${FONT_BASE_PATH}/SkyText-Regular.eot?#iefix')
                format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SkyText-Regular.woff') format('woff'),
            url('${FONT_BASE_PATH}/SkyText-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Italic';
        src: url('${FONT_BASE_PATH}/SkyText-Italic.eot');
        src: url('${FONT_BASE_PATH}/SkyText-Italic.eot?#iefix') format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SkyText-Italic.woff') format('woff'),
            url('${FONT_BASE_PATH}/SkyText-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Sky Medium';
        src: url('${FONT_BASE_PATH}/SkyTextMedium-Regular.eot');
        src: url('${FONT_BASE_PATH}/SkyTextMedium-Regular.eot?#iefix')
                format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SkyTextMedium-Regular.woff') format('woff'),
            url('${FONT_BASE_PATH}/SkyTextMedium-Regular.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Sports Bold Italic';
        src: url('${FONT_BASE_PATH}/SSportsD-BoldItalic.eot');
        src: url('${FONT_BASE_PATH}/SSportsD-BoldItalic.eot?#iefix')
                format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SSportsD-BoldItalic.woff') format('woff'),
            url('${FONT_BASE_PATH}/SSportsD-BoldItalic.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Sky Sports D Bold';
        src: url('${FONT_BASE_PATH}/SSportsD-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Sports PL Medium';
        src: url('${FONT_BASE_PATH}/SSportsPL-Medium.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Sports D Medium';
        src: url('${FONT_BASE_PATH}/SSportsD-Medium.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sky Sports F Medium';
        src: url('${FONT_BASE_PATH}/SSportsF-Medium.otf') format('opentype');
        src: url('${FONT_BASE_PATH}/SSportsF-Medium.eot');
        src: url('${FONT_BASE_PATH}/SSportsF-Medium.eot?#iefix')
                format('embedded-opentype'),
            url('${FONT_BASE_PATH}/SSportsF-Medium.woff') format('woff'),
            url('${FONT_BASE_PATH}/SSportsF-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
`;

export default Fonts;
