import React from 'react';
import PropTypes from 'prop-types';
import { IMAGE_BASE_PATH } from '../../../config';

const formatSrc = src => {
    if (!src) {
        return '';
    }

    if (src.lastIndexOf('http', 0) === 0) {
        return src;
    }

    return IMAGE_BASE_PATH ? `${IMAGE_BASE_PATH}${src}` : src;
};

const Image = ({ src, alt, ...props }) => (
    <img src={formatSrc(src)} alt={alt} {...props} /> // eslint-disable-line react/jsx-props-no-spreading
);

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string
};

Image.defaultProps = {
    src: '',
    alt: ''
};

export default Image;
