import * as types from './types';

export const requestUsersActiveRoundPredictions = () => ({
    type: types.REQUEST_ACTIVE_ROUND_PREDICTIONS
});

export const receiveUsersActiveRoundPredictions = roundPredictions => ({
    type: types.RECEIVE_ACTIVE_ROUND_PREDICTIONS,
    payload: roundPredictions
});

export const requestUserInfo = firstLogin => ({
    type: types.REQUEST_USER_INFO,
    firstLogin
});

export const receiveUserInfo = userInfo => ({
    type: types.RECEIVE_USER_INFO,
    payload: userInfo
});

export const accountRedirect = () => ({
    type: types.GO_TO_ACCOUNT
});

export const checkWindowSize = width => ({
    type: types.WINDOW_RESIZE,
    width
});
