import styled from '@emotion/styled/macro';

export const JoinLeagueContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.spacing.vlarge};
`;

export const PlusIconCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: ${props => props.theme.colours.gallery};
`;

export const PlusIcon = styled.div`
    width: 35px;
    height: 35px;
    background: url('/content/img/play/plus.svg');
    background-repeat: no-repeat;
`;

export const TextGrid = styled.div`
    padding-left: ${props => props.theme.spacing.vlarge};
    padding-right: ${props => props.theme.spacing.vlarge};
    padding-top: ${props => props.theme.spacing.medium};
    padding-bottom: ${props => props.theme.spacing.medium};
    display: flex;
`;

export const TextContainer = styled.div`
    padding: ${props => props.theme.spacing.medium};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
`;

export const InputContainer = styled.div`
    display: flex;
    padding: ${props => props.theme.spacing.medium};
    column-gap: ${props => props.theme.spacing.medium};
`;

export const InputField = styled.input`
    min-width: ${props => props.theme.spacing.vlarge};
    border-radius: ${props => props.theme.button.primary.borderRadius};
    border: none;
    height: 45px;
    background-color: ${props => props.theme.colours.gallery};
    font-size: ${props => props.theme.fontSize.normal};
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    flex: 1 1 65%;
`;

export const InputButton = styled.button`
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.large};
    border: none;
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.colorOnPrimary};
    border-radius: ${props => props.theme.button.primary.borderRadius};
    background-color: ${props => props.theme.colours.greenPrimary};
    flex-grow: 1;
`;

export const HeaderText = styled.h1`
    color: ${props => props.theme.colours.colorOnPrimary};
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 700;
`;

export const BodyText = styled.p`
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 400;
`;
