import styled from '@emotion/styled/macro';

export const AlertContainer = styled.div`
    padding: ${props => props.theme.spacing.large};
`;

export const PageTitle = styled.div`
    font-size: ${props => props.theme.fontSize.xlarge};
    display: flex;
    justify-content: center;

    color: ${props => props.theme.colours.black2};

    margin-top: 14px;
    margin-bottom: 40px;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin-bottom: 20px;
    }
`;

export const PunditsContainer = styled.div`
    margin: 0;

    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: 0 10px;
    }

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        margin: 0;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${props => props.theme.fontSize.large};
`;
