import styled from '@emotion/styled/macro';

export const Container = styled.div`
    flex: 0 0 auto;
    background: ${props => props.theme.colours.backgroundGrey};
    ${props => (props.width ? `width: ${props.width};` : '')}
    ${props => (props.height ? `height: ${props.height};` : '')}
`;

export const Shimmer = styled.div`
    width: 100%;
    height: 100%;

    background-image: -moz-linear-gradient(
        0deg,
        ${props => props.theme.colours.backgroundGrey} 0%,
        ${props => props.theme.colours.backgroundGrey} 30%,
        rgba(255, 255, 255, 0.85) 50%,
        ${props => props.theme.colours.backgroundGrey} 70%,
        ${props => props.theme.colours.backgroundGrey} 100%
    );
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, ${props => props.theme.colours.backgroundGrey}),
        color-stop(30%, ${props => props.theme.colours.backgroundGrey}),
        color-stop(50%, rgba(255, 255, 255, 0.85)),
        color-stop(70%, ${props => props.theme.colours.backgroundGrey}),
        color-stop(100%, ${props => props.theme.colours.backgroundGrey})
    );
    background-image: -webkit-linear-gradient(
        0deg,
        ${props => props.theme.colours.backgroundGrey} 0%,
        ${props => props.theme.colours.backgroundGrey} 30%,
        rgba(255, 255, 255, 0.85) 50%,
        ${props => props.theme.colours.backgroundGrey} 70%,
        ${props => props.theme.colours.backgroundGrey} 100%
    );
    background-image: -o-linear-gradient(
        0deg,
        ${props => props.theme.colours.backgroundGrey} 0%,
        ${props => props.theme.colours.backgroundGrey} 30%,
        rgba(255, 255, 255, 0.85) 50%,
        ${props => props.theme.colours.backgroundGrey} 70%,
        ${props => props.theme.colours.backgroundGrey} 100%
    );
    background-image: -ms-linear-gradient(
        0deg,
        ${props => props.theme.colours.backgroundGrey} 0%,
        ${props => props.theme.colours.backgroundGrey} 30%,
        rgba(255, 255, 255, 0.85) 50%,
        ${props => props.theme.colours.backgroundGrey} 70%,
        ${props => props.theme.colours.backgroundGrey} 100%
    );
    background-image: linear-gradient(
        0deg,
        ${props => props.theme.colours.backgroundGrey} 0%,
        ${props => props.theme.colours.backgroundGrey} 30%,
        rgba(255, 255, 255, 0.85) 50%,
        ${props => props.theme.colours.backgroundGrey} 70%,
        ${props => props.theme.colours.backgroundGrey} 100%
    );
    background-repeat: repeat-x;
    background-position: 0 -1000px;
    animation: shimmerBackground 4s ease-in-out infinite;
    animation-direction: alternate;
`;
