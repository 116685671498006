import { put, takeLeading, call, delay, select } from 'redux-saga/effects';
import { addErrorMessage, addSuccessMessage } from '../message/actions';
import * as types from './types';
import feedbackApi from '../../../api/feedback';
import { dismissModal } from '../modal/actions';
import { getApp, getAppVersion } from '../../../helpers/appBridge';
import { getUserSessionId } from '../../../helpers/sessionStorage';
import { selectUserId } from '../user/selectors';

export function* submitFeedback(action) {
    try {
        const { payload } = action;
        const userId = yield select(selectUserId);
        const feedback = {
            ...payload,
            product: 'super6',
            app: getApp() || 'web',
            appVersion: getAppVersion() || 'N/A',
            userSessionId: getUserSessionId(),
            _userAgent: navigator.userAgent,
            _userId: userId
        };
        yield call(feedbackApi.submitFeedback, feedback);
        yield put(dismissModal());
        yield put(addSuccessMessage('Thank you for your feedback'));
        yield delay(5000);
    } catch (err) {
        yield put(
            addErrorMessage(
                'There was a problem submitting feedback, please try again'
            )
        );
    }
}

export function* watchSubmitFeedback() {
    yield takeLeading(types.REQUEST_SUBMIT_FEEDBACK, submitFeedback);
}

export const sagas = [watchSubmitFeedback];
