import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Score = ({
    homeScore,
    awayScore,
    correctResult,
    correctScore,
    className,
    isScore,
    testId
}) => {
    const homeScoreString = homeScore !== null ? homeScore : '-';
    const awayScoreString = awayScore !== null ? awayScore : '-';

    return (
        <Styled.ScoreContainer className={className} data-test-id={testId}>
            <Styled.Score
                correctResult={correctResult}
                isScore={isScore}
                data-test-id="score-home"
            >
                <span>{homeScoreString}</span>
            </Styled.Score>
            {!correctScore && <Styled.Vs>v</Styled.Vs>}
            {correctScore && <Styled.Tick />}

            <Styled.Score
                correctResult={correctResult}
                isScore={isScore}
                data-test-id="score-away"
            >
                <span>{awayScoreString}</span>
            </Styled.Score>
        </Styled.ScoreContainer>
    );
};

Score.defaultProps = {
    homeScore: null,
    awayScore: null,
    correctResult: false,
    correctScore: false,
    className: '',
    isScore: false,
    testId: null
};

Score.propTypes = {
    homeScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    awayScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    correctResult: PropTypes.bool,
    correctScore: PropTypes.bool,
    className: PropTypes.string,
    isScore: PropTypes.bool,
    testId: PropTypes.string
};

export default Score;
