import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FailureMessage from '../FailureMessage';
import { requestMatchStats } from '../../../../../../store/modules/round/actions';
import { teamProp, matchHistoryProp } from './propTypes';
import Spinner from '../../../../../../components/Common/Spinner';
import MatchHistory from './MatchHistory';
import * as Styled from './styles';

export const MatchStats = ({
    getStats,
    stats,
    matchId,
    isLoading,
    homeTeam,
    awayTeam
}) => {
    useEffect(() => {
        if (!stats) {
            getStats(matchId);
        }
    }, [matchId, getStats, stats]);

    if (isLoading) {
        return <Spinner colour="textTitle" />;
    }

    return (
        <Styled.MatchStats data-test-id="match-stats-previous-games">
            <Styled.StatsTitle>Last 5 Games</Styled.StatsTitle>
            {stats?.home?.matchHistory?.length &&
            stats?.away?.matchHistory?.length ? (
                <>
                    <MatchHistory
                        team={homeTeam}
                        history={stats.home.matchHistory}
                        numMatchesToShow={5}
                        bottomDivider
                    />
                    <MatchHistory
                        team={awayTeam}
                        history={stats.away.matchHistory}
                        numMatchesToShow={5}
                    />
                </>
            ) : (
                <FailureMessage />
            )}
        </Styled.MatchStats>
    );
};

MatchStats.propTypes = {
    getStats: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    matchId: PropTypes.number.isRequired,
    homeTeam: teamProp.isRequired,
    awayTeam: teamProp.isRequired,
    stats: PropTypes.shape({
        loading: PropTypes.bool,
        home: PropTypes.shape({
            name: PropTypes.string,
            matchHistory: matchHistoryProp
        }),
        away: PropTypes.shape({
            name: PropTypes.string,
            matchHistory: matchHistoryProp
        }),
        draw: PropTypes.shape({ chance: PropTypes.number })
    })
};

MatchStats.defaultProps = {
    stats: null,
    isLoading: true
};

const mapStateToProps = (state, props) => ({
    stats:
        state.round.stats[props.matchId] &&
        state.round.stats[props.matchId].data,
    isLoading:
        state.round.stats[props.matchId] &&
        state.round.stats[props.matchId].loading
});

const mapDispatchToProps = dispatch => ({
    getStats: matchId => dispatch(requestMatchStats(matchId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchStats);
