import * as types from './types';

export const sendMessage = (message, data) => ({
    type: types.SEND_MESSAGE,
    message,
    data
});

export const sendConsumerReady = () =>
    sendMessage('consumerReady', { ready: true });
export const sendIncrementFeedbackCount = () =>
    sendMessage('shouldFeedbackIncrement', {});
export const sendSocialShare = message =>
    sendMessage('socialSharing', { socialInfo: message });
export const sendOpenAppLink = (app, url) =>
    sendMessage('openAppLink', { app, url });
export const sendPlayNow = () => sendMessage('playNow', {});

export const setVersion = (app, version) => ({
    type: types.SET_VERSION,
    app,
    version
});
