import React from 'react';
import PropTypes from 'prop-types';

import MatchHistoryItem from './MatchHistoryItem';
import { teamProp, matchHistoryProp } from './propTypes';
import * as Styled from './styles';
import { parseCustomFormat } from '../../../../../../helpers/dateFormatters';

const formatHistory = (history, numMatches) =>
    history
        .slice(0, numMatches)
        .sort((a, b) =>
            parseCustomFormat(a.matchDate, 'Do MMMM YYYY').diff(
                parseCustomFormat(b.matchDate, 'Do MMMM YYYY')
            )
        );

const MatchHistory = ({ team, history, bottomDivider, numMatchesToShow }) => (
    <Styled.MatchHistory bottomDivider={bottomDivider}>
        <Styled.MatchHistoryHeader>
            <Styled.MatchHistoryBadge
                src={team.badgeUri}
                alt={team.name}
                width="35px"
            />
            <Styled.MatchHistoryHeaderTeamName>
                {team.name}
            </Styled.MatchHistoryHeaderTeamName>
        </Styled.MatchHistoryHeader>

        <Styled.MatchHistoryList>
            {formatHistory(history, numMatchesToShow).map((match, index) => {
                const isHome = match.home.teamId === team.id;
                const teamToUse = isHome ? match.away : match.home;

                return (
                    <MatchHistoryItem
                        key={match.matchDate}
                        match={match}
                        team={teamToUse}
                        isHome={isHome}
                        greyBackground={index % 2 !== 0}
                    />
                );
            })}
        </Styled.MatchHistoryList>
    </Styled.MatchHistory>
);

MatchHistory.propTypes = {
    team: teamProp.isRequired,
    history: matchHistoryProp.isRequired,
    bottomDivider: PropTypes.bool,
    numMatchesToShow: PropTypes.number
};

MatchHistory.defaultProps = {
    bottomDivider: false,
    numMatchesToShow: 5
};

export default MatchHistory;
