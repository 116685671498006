import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { PageContainer } from '../../components/Common/Page/styles';
import { requestUsersActiveRoundPredictions } from '../../store/modules/user/actions';
import * as Styled from './styles';
import Container from '../../components/Common/Container';
import RoundFixtures from './components/RoundFixtures';
import PointsResult from '../../components/PointsResult';
import { requestRoundPredictionsByUser } from '../../store/modules/results/actions';
import { predictions as predictionsProp } from '../Results/propTypes';
import ConnectedGlobalBanner from '../../components/Offer/GlobalBanner';
import ConnectedDeadlineTitle from '../../components/DeadlineTitle';
import ConnectedAcca from '../Play/components/Acca';
import {
    requestActiveRound,
    requestHeadToHead,
    requestLiveOdds
} from '../../store/modules/round/actions';
import {
    selectActiveRoundGoldenGoalChallenge,
    selectActiveRoundId,
    selectHeadToHead,
    selectIsLoadedHeadToHead
} from '../../store/modules/round/selectors';
import { isFeatureOn } from '../../store/modules/content/selectors';
import { ENABLE_HEAD_TO_HEAD } from '../../features';

export const LiveScoring = ({
    goldenGoalChallenge,
    activeRoundId,
    loadActiveRound,
    liveOdds,
    getLiveOdds,
    getPredictions,
    predictions,
    getRoundPredictionsForOtherUser,
    predictionsOtherUser,
    canBet,
    hasLoadedHeadToHead,
    getHeadToHead,
    headToHeadEnabled
}) => {
    const match = useRouteMatch();
    const { userId } = match.params;
    const otherUserView = !!userId;

    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    useEffect(() => {
        getLiveOdds();
    }, [activeRoundId, getLiveOdds]);

    useEffect(() => {
        if (otherUserView) {
            getRoundPredictionsForOtherUser(activeRoundId, userId);
        } else if (!predictions) {
            getPredictions();
        }
    }, [
        predictions,
        userId,
        getRoundPredictionsForOtherUser,
        getPredictions,
        activeRoundId,
        otherUserView
    ]);

    useEffect(() => {
        if (!headToHeadEnabled || hasLoadedHeadToHead) {
            return;
        }

        getHeadToHead(activeRoundId);
    }, [activeRoundId, getHeadToHead, hasLoadedHeadToHead, headToHeadEnabled]);

    if (!activeRoundId) {
        return null;
    }

    const getContent = () => {
        if (otherUserView && predictionsOtherUser[userId]) {
            const roundPredictions =
                predictionsOtherUser[userId][activeRoundId];
            return {
                roundPredictions,
                title: `${predictionsOtherUser[userId].firstName} ${predictionsOtherUser[userId].lastName}`,
                scorePoints: roundPredictions.scorePoints,
                resultPoints: roundPredictions.resultPoints,
                totalPoints: roundPredictions.totalPoints,
                firstName: predictionsOtherUser[userId].firstName
            };
        }
        return {
            roundPredictions: predictions || { matches: {} },
            scorePoints: (predictions && predictions.scorePoints) || {
                points: 0,
                number: 0
            },
            resultPoints: (predictions && predictions.resultPoints) || {
                points: 0,
                number: 0
            },
            totalPoints: (predictions && predictions.totalPoints) || {
                points: 0,
                number: 0
            }
        };
    };

    const {
        roundPredictions,
        scorePoints,
        resultPoints,
        firstName
    } = getContent(otherUserView);
    return (
        <PageContainer>
            <ConnectedDeadlineTitle />
            <Container>
                <Styled.PaddedContainer>
                    <Styled.PanelsContainer>
                        <Styled.Panel>
                            <PointsResult
                                correctResultsPoints={resultPoints.points ?? 0}
                                correctScoresPoints={scorePoints.points ?? 0}
                                goldenGoal={
                                    goldenGoalChallenge && {
                                        isGoldenGoalScored:
                                            goldenGoalChallenge.isGoldenGoalScored,
                                        goldenGoalPredictionMinute:
                                            roundPredictions.goldenGoal,
                                        goldenGoalScorerName:
                                            goldenGoalChallenge.scorerName,
                                        goldenGoalScorerTeam:
                                            goldenGoalChallenge.teamName,
                                        goldenGoalScoredMinute:
                                            goldenGoalChallenge.time,
                                        inPlay: true
                                    }
                                }
                            />
                        </Styled.Panel>
                    </Styled.PanelsContainer>
                    <Styled.PanelsContainer>
                        <RoundFixtures
                            predictions={roundPredictions.matches}
                            firstName={firstName}
                            liveOdds={liveOdds}
                            userId={userId}
                        />
                    </Styled.PanelsContainer>
                </Styled.PaddedContainer>
                <ConnectedGlobalBanner />
                {canBet && !otherUserView && predictions && <ConnectedAcca />}
            </Container>
        </PageContainer>
    );
};

LiveScoring.defaultProps = {
    predictions: null,
    liveOdds: null,
    activeRoundId: null,
    headToHead: null,
    headToHeadEnabled: true,
    goldenGoalChallenge: null
};

LiveScoring.propTypes = {
    loadActiveRound: PropTypes.func.isRequired,
    activeRoundId: PropTypes.number,
    predictions: predictionsProp,
    getPredictions: PropTypes.func.isRequired,
    getRoundPredictionsForOtherUser: PropTypes.func.isRequired,
    predictionsOtherUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        roundId: predictionsProp
    }).isRequired,
    getLiveOdds: PropTypes.func.isRequired,
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    }),
    canBet: PropTypes.bool.isRequired,
    hasLoadedHeadToHead: PropTypes.bool.isRequired,
    headToHead: PropTypes.shape({
        opponent: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            profileImage: PropTypes.string.isRequired
        }).isRequired
    }),
    getHeadToHead: PropTypes.func.isRequired,
    headToHeadEnabled: PropTypes.bool,
    goldenGoalChallenge: PropTypes.shape({
        isGoldenGoalScored: PropTypes.bool.isRequired,
        scorerName: PropTypes.string,
        time: PropTypes.number,
        teamName: PropTypes.string
    })
};

const mapStateToProps = state => ({
    goldenGoalChallenge: selectActiveRoundGoldenGoalChallenge(state),
    liveOdds: state.round.liveOdds,
    activeRoundId: selectActiveRoundId(state),
    predictions: state.play.fullPredictions,
    predictionsOtherUser: state.results.predictionsOtherUser,
    canBet: !!state.user.info.canBet,
    hasLoadedHeadToHead: selectIsLoadedHeadToHead(
        state,
        selectActiveRoundId(state)
    ),
    headToHead: selectHeadToHead(state, selectActiveRoundId(state)),
    headToHeadEnabled: isFeatureOn(state, ENABLE_HEAD_TO_HEAD, true)
});

const mapDispatchToProps = dispatch => ({
    getLiveOdds: () => dispatch(requestLiveOdds()),
    getPredictions: () => {
        dispatch(requestUsersActiveRoundPredictions());
    },
    getRoundPredictionsForOtherUser: (roundId, userId) => {
        dispatch(requestRoundPredictionsByUser(roundId, userId));
    },
    loadActiveRound: () => dispatch(requestActiveRound()),
    getHeadToHead: roundId => dispatch(requestHeadToHead(roundId))
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveScoring);
