import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Super6Hero from '../Super6Hero';
import * as Styled from './styles';
import ExtraTimeHero from '../ExtraTimeHero';

import { selectUpcomingS6AndExtraRounds } from '../../../../store/modules/round/selectors';
import { requestUpcomingRounds } from '../../../../store/modules/round/actions';
import { isFeatureOn } from '../../../../store/modules/content/selectors';
import { SEASON_CLOSED } from '../../../../features';

const Heroes = ({ activeRounds, loadUpcomingRounds, isSeasonClosed }) => {
    useEffect(() => {
        loadUpcomingRounds();
    }, [loadUpcomingRounds]);

    let s6HeroCounter = 0;
    let extraHeroCounter = 0;

    if (!activeRounds) {
        return (
            <Styled.HeroContainer data-test-id="superSixHeroContainer">
                <Super6Hero activeRound={activeRounds} />
            </Styled.HeroContainer>
        );
    }

    const newRounds = activeRounds.sort((a, b) => {
        if (
            (a.status === 'open' && b.status === 'open') ||
            (a.status === 'complete' && b.status === 'complete')
        ) {
            return 0;
        }
        if (b.status === 'complete' && a.status !== 'complete') {
            return -1;
        }
        return 1;
    });

    return (
        <>
            {newRounds.map(round => {
                switch (round.roundType) {
                    case 'super6':
                        s6HeroCounter += 1;
                        return (
                            <Styled.HeroContainer
                                data-test-id={`s6-default-hero-container-${s6HeroCounter}`}
                                key={round.roundType}
                            >
                                <Super6Hero
                                    activeRound={round}
                                    isSeasonClosed={isSeasonClosed}
                                />
                            </Styled.HeroContainer>
                        );
                    case 'super6extra':
                        extraHeroCounter += 1;
                        return (
                            <Styled.HeroContainer
                                data-test-id={`super6extra-hero-container-${extraHeroCounter}`}
                                key={round.roundType}
                            >
                                <ExtraTimeHero />
                            </Styled.HeroContainer>
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};

Heroes.propTypes = {
    loadUpcomingRounds: PropTypes.func.isRequired,
    activeRounds: PropTypes.arrayOf(PropTypes.shape({})),
    isSeasonClosed: PropTypes.bool
};

Heroes.defaultProps = {
    activeRounds: null,
    isSeasonClosed: false
};

const mapStateToProps = state => ({
    activeRounds: selectUpcomingS6AndExtraRounds(state),
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false)
});

const mapDispatchToProps = dispatch => ({
    loadUpcomingRounds: () => dispatch(requestUpcomingRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(Heroes);
