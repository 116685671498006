import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from './styles';
import * as GenericStyled from '../../../../components/Super6Hero/styles';
import PlayButton from './PlayButton';
import PrizeImage from './PrizeImage';
import { getFormattedLongDateTime } from '../../../../helpers/dateFormatters';
import {
    selectActiveRoundId,
    selectActiveRoundStatus
} from '../../../../store/modules/round/selectors';
import {
    ClosedContainer,
    ClosedSubText,
    ClosedText,
    InfoButton,
    InfoButtonWrapper
} from './styles';
import {
    areFeaturesLoaded,
    isFeatureOn
} from '../../../../store/modules/content/selectors';
import { SEASON_CLOSED } from '../../../../features';

const renderAdditionalText = (roundId, status, date) => {
    if (status !== 'open') {
        return <Styled.Info>Round {roundId} has kicked off</Styled.Info>;
    }

    if (!date || !status) {
        return null;
    }

    return (
        <Styled.Info>{`Entries by ${getFormattedLongDateTime(
            date
        )}. T&Cs apply.`}</Styled.Info>
    );
};

const InfoContainer = ({
    roundId,
    roundStatus,
    predictionClosedDate,
    isSeasonClosed,
    featuresLoaded
}) => {
    const closedSeason = featuresLoaded && isSeasonClosed;
    const openSeason = featuresLoaded && !isSeasonClosed;

    const renderOpenSeason = () => (
        <>
            <Styled.PrizeContainer>
                <PrizeImage />
            </Styled.PrizeContainer>
            <Styled.InfoFooter>
                <span data-test-id="masthead-game-btn-container">
                    <PlayButton />
                </span>
                {renderAdditionalText(
                    roundId,
                    roundStatus,
                    predictionClosedDate
                )}
            </Styled.InfoFooter>
        </>
    );

    const renderClosedSeason = () => (
        <>
            <ClosedContainer>
                <ClosedText>
                    ANOTHER EXCITING SUPER 6 SEASON COMES TO A CLOSE. THANK YOU
                    FOR PLAYING!
                </ClosedText>
                <ClosedSubText>
                    There are no more rounds to enter this season but you can
                    still access your leagues, results and the performance hub,
                    which will be available until the 9th of June. After this
                    date, the site will close so we can prepare for next season.
                    Follow our social channels to ensure you don&apos;t miss the
                    opening Super 6 round of the 23/24 season.
                </ClosedSubText>
                <InfoButtonWrapper>
                    <InfoButton
                        id="play-game-btn-league"
                        data-test-id="masthead-game-btn-league"
                        data-target-id="s6-masthead-cta-league-btn"
                        link="/league"
                        internal
                    >
                        View Leagues
                    </InfoButton>
                </InfoButtonWrapper>
            </ClosedContainer>
        </>
    );

    return (
        <GenericStyled.InfoOuter>
            <Styled.InfoInner isSeasonClosed={isSeasonClosed}>
                {openSeason && renderOpenSeason()}
                {closedSeason && renderClosedSeason()}
            </Styled.InfoInner>
        </GenericStyled.InfoOuter>
    );
};

InfoContainer.propTypes = {
    roundId: PropTypes.number,
    roundStatus: PropTypes.string,
    predictionClosedDate: PropTypes.string,
    isSeasonClosed: PropTypes.bool.isRequired,
    featuresLoaded: PropTypes.bool.isRequired
};

InfoContainer.defaultProps = {
    roundId: null,
    roundStatus: null,
    predictionClosedDate: null
};

const mapStateToProps = state => ({
    roundId: selectActiveRoundId(state),
    roundStatus: selectActiveRoundStatus(state),
    predictionClosedDate: state.round.activeRound
        ? state.round.activeRound.endDateTime
        : null,
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false),
    featuresLoaded: areFeaturesLoaded(state)
});

export default connect(mapStateToProps, null)(InfoContainer);
