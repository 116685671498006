import * as types from './types';

export const requestStatsForSelf = (force = false) => ({
    type: types.REQUEST_STATS_FOR_SELF,
    force
});

export const receiveStatsForSelf = stats => ({
    type: types.RECEIVE_STATS_FOR_SELF,
    payload: stats
});

export const updateStatsEnteredRound = roundId => ({
    type: types.UPDATE_STATS_ENTERED_ROUND,
    roundId
});

export const requestSeasonStatsForSelf = (force = false) => ({
    type: types.REQUEST_SEASON_STATS_FOR_SELF,
    force
});

export const receiveSeasonStatsForSelf = seasonStats => ({
    type: types.RECEIVE_SEASON_STATS_FOR_SELF,
    payload: seasonStats
});

export const requestSeasonHeadToHeadStats = (force = false) => ({
    type: types.REQUEST_SEASON_HEAD_TO_HEAD_STATS,
    force
});

export const receiveSeasonHeadToHeadStats = seasonHeadtoHeadStats => ({
    type: types.RECEIVE_SEASON_HEAD_TO_HEAD_STATS,
    payload: seasonHeadtoHeadStats
});
