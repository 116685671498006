import * as types from './types';

const defaultState = {
    isLoading: false,
    data: null,
    hasRequested: false,
    hasFailed: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REQUEST_PODCAST:
            return {
                ...state,
                isLoading: true,
                hasFailed: false
            };
        case types.RECEIVE_PODCAST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                hasRequested: true
            };
        case types.REQUEST_PODCAST_FAILED:
            return {
                ...state,
                hasFailed: true
            };
        default:
            return state;
    }
};
