import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    self: {},
    season: {},
    seasonHeadToHead: {},
    isLoadingSelf: false,
    isLoadingSeasonStats: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REQUEST_STATS_FOR_SELF:
            return newIfChanged(state, {
                ...state,
                isLoadingSelf: true
            });

        case types.RECEIVE_STATS_FOR_SELF:
            return newIfChanged(state, {
                ...state,
                self: action.payload,
                isLoadingSelf: false
            });

        case types.REQUEST_SEASON_STATS_FOR_SELF:
            return newIfChanged(state, {
                ...state,
                isLoadingSeasonStats: true
            });

        case types.RECEIVE_SEASON_STATS_FOR_SELF:
            return newIfChanged(state, {
                ...state,
                season: action.payload,
                isLoadingSeasonStats: false
            });

        case types.RECEIVE_SEASON_HEAD_TO_HEAD_STATS:
            return newIfChanged(state, {
                ...state,
                seasonHeadToHead: action.payload
            });

        default:
            return state;
    }
};
