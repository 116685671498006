import React, { createContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    MessagingBootstrap,
    AuthType
} from '@sbgmessaging/customer-ui-bootstrap';
import { requestJwt } from '../../../../store/modules/auth/actions';
import * as Styled from './styles';
import { NOTIFICATION_ENV } from '../../../../config';
import { selectIsLoggedIn } from '../../../../store/modules/auth/selectors';
import actions from '../../../../helpers/GoogleAnalytics/actions';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

const context = createContext({
    notificationsOpen: false,
    toggle: () => {}
});
const { Provider, Consumer } = context;

const BellButton = ({ setInterfaceEvent }) => (
    <Consumer>
        {({
            notificationsAvailable,
            notificationsOpen,
            toggle,
            unreadNotifications,
            bellRef
        }) => {
            if (!notificationsAvailable) {
                return null;
            }

            return (
                <Styled.BellContainer
                    onClick={() => {
                        setInterfaceEvent(
                            elementText.interface.notificationBell,
                            actions.clicked
                        );
                        toggle(prevToggle => !prevToggle);
                    }}
                    ref={bellRef}
                >
                    <Styled.BellIcon
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 2048 2048"
                    >
                        <path d="M1696.3,1386V786.1h-0.7c-13.6-300.4-224.4-549.5-506.1-620.8C1189.4,74,1115.3,0,1024,0S858.6,74,858.5,165.3c-281.6,71.3-492.4,320.3-506.1,620.8h-0.7V1386c-114.2,0-206.9,92.6-206.9,206.9c0,114.2,92.6,206.9,206.9,206.9h1344.6c114.2,0,206.9-92.6,206.9-206.9C1903.2,1478.6,1810.6,1386,1696.3,1386z" />
                        <path d="M1024,2048c114.2,0,206.9-92.6,206.9-206.9H817.1C817.1,1955.4,909.8,2048,1024,2048z" />
                    </Styled.BellIcon>
                    {unreadNotifications > 0 && <Styled.NotificationDot />}
                    {notificationsOpen && <Styled.Pointer />}
                </Styled.BellContainer>
            );
        }}
    </Consumer>
);

const NotificationsPanel = () => (
    <Consumer>
        {({ notificationsOpen, containerPlacedCallback, contentSize }) => (
            <Styled.NotificationWrapper
                className="notifications-wrapper"
                notificationsOpen={notificationsOpen}
            >
                <Styled.NotificationInner className="inner">
                    <Styled.NotificationContainer
                        ref={containerPlacedCallback}
                        id="notification-container"
                        contentHeight={contentSize}
                        notificationsOpen={notificationsOpen}
                    />
                </Styled.NotificationInner>
            </Styled.NotificationWrapper>
        )}
    </Consumer>
);

export const NotificationsContext = ({
    isLoggedIn,
    jwt,
    getJwt,
    customerId,
    children
}) => {
    const [notificationsAvailable, setNotificationsAvailable] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [contentSize, setContentSize] = useState(0);
    const target = useRef(false);
    const bellRef = useRef(false);

    useEffect(() => {
        getJwt();
    }, [getJwt, isLoggedIn]);

    function handleClickOutside(event) {
        if (
            target.current &&
            !target.current.contains(event.target) &&
            !bellRef.current.contains(event.target)
        ) {
            setNotificationsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    if (!isLoggedIn || !jwt || !customerId) {
        // ignore notification setup and return children
        return <>{children}</>;
    }
    const auth = { token: jwt, type: AuthType.super6Jwt };
    const customerIdInt = +customerId;

    const bootstrap = new MessagingBootstrap(
        customerIdInt,
        auth,
        NOTIFICATION_ENV,
        'ss-super6'
    );

    const setupListeners = () => {
        bootstrap.on('requestVisibilityChange', p =>
            setNotificationsOpen(p.visible)
        );
        bootstrap.on('error', () => setNotificationsAvailable(false));
        bootstrap.on('contentSize', p => setContentSize(p.height + 10));
        bootstrap.on('undisplayedUpdate', p =>
            setUnreadNotifications(p.undisplayed)
        );
        bootstrap.on('notificationAction', p => {
            if (p.type === 'navigate' && p.payload.url) {
                window.location.assign(p.payload.url);
            }
        });
    };

    const containerPlacedCallback = element => {
        if (!target.current) {
            bootstrap.insertNotificationContainer(element, 'super6');
            target.current = element;
            setupListeners();
            setNotificationsAvailable(true);
        }
    };

    return (
        <Provider
            value={{
                notificationsAvailable,
                notificationsOpen,
                toggle: setNotificationsOpen,
                containerPlacedCallback,
                unreadNotifications,
                contentSize,
                bellRef
            }}
        >
            {children}
        </Provider>
    );
};

NotificationsContext.propTypes = {
    isLoggedIn: PropTypes.bool,
    jwt: PropTypes.string,
    getJwt: PropTypes.func.isRequired,
    customerId: PropTypes.string,
    children: PropTypes.node.isRequired
};

NotificationsContext.defaultProps = {
    isLoggedIn: false,
    jwt: null,
    customerId: null
};

BellButton.propTypes = {
    setInterfaceEvent: PropTypes.any
};

BellButton.defaultProps = {
    setInterfaceEvent: () => {}
};

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    jwt: state.auth.jwt,
    customerId: state.user.info.customerId
});

const matchDispatchToProps = dispatch => ({
    getJwt: () => dispatch(requestJwt())
});

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(NotificationsContext);

export { BellButton, NotificationsPanel };
