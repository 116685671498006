import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createLeague as createLeagueAction } from '../../../store/modules/league/actions';
import ConnectedLeagueForm from '../components/LeagueForm';

const CreateLeague = ({ createLeague }) => (
    <ConnectedLeagueForm
        pageTitle="Create League"
        submitLabel="Create League"
        subtitle="Create a new league"
        leagueNameText="Enter a name for your league"
        onSubmit={createLeague}
    />
);

CreateLeague.propTypes = {
    createLeague: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    createLeague: (name, pundits) => dispatch(createLeagueAction(name, pundits))
});

export default connect(null, mapDispatchToProps)(CreateLeague);
