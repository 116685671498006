import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MastheadTitle from '../MastheadTitle';
import MastheadFooter from '../MastheadFooter';
import * as Styled from '../mastheadStyles';
import { requestRounds } from '../../../../../store/modules/round/actions';

const HowToPlayMasthead = ({ loadRounds, opponent, prizes }) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    return (
        <Styled.Container data-test-id="how-to-play-masthead">
            <Styled.ContentContainer>
                <Styled.Content>
                    <MastheadTitle
                        text="How to play"
                        align="left"
                        isInplay={false}
                    />
                    <Styled.SubTitle>
                        Go Head to Head against our footballing experts. Using
                        your predictions, see if you can beat the experts at
                        their own game. If you do, you&apos;ll have a chance to
                        win some prizes!
                    </Styled.SubTitle>
                </Styled.Content>
                <Styled.Image
                    opponentImage={opponent.winnerImage}
                    data-test-id="masthead-image"
                />
            </Styled.ContentContainer>
            <MastheadFooter
                label="The prize:"
                quantity={prizes.quantity}
                value={prizes.value}
            />
        </Styled.Container>
    );
};

HowToPlayMasthead.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    opponent: PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileImage: PropTypes.string,
        winnerImage: PropTypes.string,
        loserImage: PropTypes.string
    }).isRequired,
    prizes: PropTypes.shape({
        quantity: PropTypes.number,
        value: PropTypes.string
    }).isRequired
};

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

export default connect(null, mapDispatchToProps)(HowToPlayMasthead);
