export default function getQueryItems(searchString) {
    if (!searchString) {
        return {};
    }

    const splitString = searchString.substring(1).split('&');
    return splitString.reduce((buildObject, query) => {
        const keyValuePair = query.split('=');
        return {
            ...buildObject,
            [keyValuePair[0]]: keyValuePair[1]
        };
    }, {});
}
