import styled from '@emotion/styled/macro';

export const ShareContainer = styled.div`
    width: '100%';
`;

export const IconRow = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;

export const Icon = styled.a`
    width: 40px;
    height: 40px;
    display: flex;
    flex: 1;
    text-decoration: none;

    img {
        max-width: 20px;
        max-height: 20px;
        margin: auto;
        margin-right: 0;
    }
    p {
        margin: auto;
        padding-bottom: ${props => props.displace}%;
    }
    &:last-child {
        margin-right: 0;
    }
`;

export const Character = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &::before {
        font-family: skycons;
        font-size: 40px;
        content: '${props => props.icon}';
        cursor: pointer;
        color: ${props => props.theme.colours.bluePrimary};
        margin-top: -6px;

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            display: flex;
            font-size: 34px;
            align-items: center;
            margin-top: -6px;
        }
    }
`;

export const ButtonPadding = styled.div`
    padding: ${props => (props.small ? '0' : '15px')} 0px;
`;

export const ButtonContainer = styled(ButtonPadding)`
    width: 100%;
`;

export const ShareButtonTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    white-space: nowrap;
    font-family: ${props => props.theme.fonts.medium};
`;
