import React from 'react';
import * as Styled from '../styles';

const Raf = () => (
    <>
        <li>
            Refer a Friend Promotion Terms and Conditions
            <Styled.OrderedList>
                <li>
                    All other Super 6 terms and conditions apply to the Refer a
                    Friend Promotion.
                </li>
                <li>
                    All Sky Betting and Gaming General Terms and Conditions
                    apply to the Refer a Friend Promotion and can be found{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.skybet.com/s/article/Sky-Betting-and-Gaming-General-Terms-Conditions"
                    >
                        here
                    </a>
                    .
                </li>
                <li>
                    All Sky Betting &amp; Gaming Promotional Terms apply and can
                    be viewed{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.skybet.com/s/article/General-Sky-Bet-Promotion-Terms"
                    >
                        here
                    </a>
                    .
                </li>
                <li>
                    This Refer a Friend Promotion is now closed to new referrals
                    but users that have referred a friend to Super 6 before 2021
                    will still be eligible to win.
                </li>
                <li>
                    In order to enter the Refer a Friend Promotion, current
                    Super 6 customers must refer individuals who are eligible to
                    play Super 6 but have never played Super 6 before, to the
                    Super 6 Competition.
                </li>
                <li>
                    The referred individual must sign up via the website, having
                    started their session from using the refer a friend link for
                    the referrer to be eligible for the Refer a Friend
                    Promotion.
                </li>
                <li>
                    Thereafter referrals will need to enter a Super 6 round for
                    the referrer to then be eligible to win the &#163;25,000.
                </li>
                <li>
                    If a referred entrant correctly predicts the full&#45;time
                    score line of all 6 games within a specific round of Super
                    6, the referrer will win &#163;25,000.
                </li>
                <li>
                    The referrer will only receive &#163;25,000 if their
                    referral wins the Jackpot Prize, not the Top Scorer Prize or
                    any other prizes/promotions Super 6 has live at that time.
                </li>
                <li>
                    In the instance where there is a shared jackpot, the
                    referrer will still receive the full &#163;25,000.
                </li>
                <li>
                    The referrer will only receive &#163;25,000 where the
                    referred individual makes the winning prediction within 3
                    years of the referral date.
                </li>
                <li>
                    If no referral correctly meets the entry requirements then
                    no prize will be awarded.
                </li>
                <li>
                    Any Super 6 entries received after kick off during each
                    round will not be counted.
                </li>
                <li>
                    Winners will be notified within 48 hours after the
                    Promotional Period.
                </li>
                <li>
                    The prize will be paid into the winning participants account
                    within 10 days of the Promoter successfully verifying
                    &#40;with evidence satisfactory to the Promoter&#41; the
                    winner&apos;s age, identity, residence and other requested
                    verification and security information.
                </li>
                <li>
                    If the winning participants cannot be contacted within a
                    reasonable period of time, fail to upgrade their account or
                    are not able or available to accept the prize for any reason
                    which is beyond the Promoter&apos;s reasonable control, then
                    the prize will be revoked.
                </li>
                <li>
                    Participants who have been excluded from previous promotions
                    run by the Promoter &#40;including in relation to Sky Bet,
                    Sky Vegas, Sky Poker or Sky Bingo&#41; will not be permitted
                    to participate in this Refer a Friend Promotion and any
                    submissions shall be deemed void.
                </li>
                <li>The &#163;25,000 prize is paid as cash via BACS.</li>
                <li>
                    The customer cannot request any alternative to the prize and
                    unless agreed otherwise in writing by the Promoter the prize
                    is non&#45;refundable and non&#45;transferable.
                </li>
                <li>
                    The Promoter reserves the right to substitute the prize for
                    a prize of equivalent or greater monetary value if this is
                    necessary to do so for reasons beyond its control.
                </li>
                <li>
                    The Promoter reserves the right to amend these Terms and
                    Conditions at any time if reasonably necessary to do so.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Raf;
