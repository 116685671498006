import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Alert = ({ children, type }) => {
    if (type === 'Warning') {
        return (
            <Styled.AlertWarning data-test-id="alert-warning">
                {children}
            </Styled.AlertWarning>
        );
    }

    return <Styled.Alert data-test-id="alert">{children}</Styled.Alert>;
};

Alert.propTypes = {
    children: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['Warning', 'Default'])
};

Alert.defaultProps = {
    type: 'Default'
};

export default Alert;
