import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'react-router-dom';
import AppLinkButton from '../AppLink/styles';
import { Icon } from '../Page/styles';

export const StandardButton = styled.button`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: ${props =>
        props.theme?.button?.primary?.borderRadius ?? '4px'};
    color: ${props =>
        props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${props =>
            props.theme?.button?.primary?.onHoverBgColour ??
            props.theme.colours.greenDark};
    }
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing[props.sidePadding]};
    text-transform: ${props => props.theme?.button?.textTransform};
    letter-spacing: ${props => props.theme?.button?.letterSpacing};
    font-weight: ${props => props.theme?.button?.fontWeight};
`;

export const ButtonWrapper = styled.div`
    a,
    ${AppLinkButton} {
        display: inline-block;
        width: 100%;
        font-family: ${props => props.theme.fonts.medium};
        box-sizing: border-box;
        border: 0;
        text-align: center;
        border-radius: ${props =>
            props.theme?.button?.primary?.borderRadius ?? '4px'};
        color: ${props =>
            props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
        text-decoration: none;
        background-color: ${props => props.theme.colours.greenPrimary};
        &:hover,
        &:focus {
            background-color: ${props =>
                props.theme?.button?.primary?.onHovergColour ??
                props.theme.colours.greenDark};
        }
        font-size: ${props => props.theme.fontSize.large};
        padding: ${props => props.theme.spacing.large}
            ${props => props.theme.spacing.xxlarge};
        text-transform: ${props => props.theme?.button?.textTransform};
        letter-spacing: ${props => props.theme?.button?.letterSpacing};
        font-weight: ${props => props.theme?.button?.fontWeight};
    }

    ${AppLinkButton} {
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
    }
`;

export const BetButtonWrapper = styled.div`
    a,
    ${AppLinkButton} {
        display: inline-block;
        width: 100%;
        font-family: ${props => props.theme?.skyBet?.fonts?.medium};
        box-sizing: border-box;
        border: 0;
        text-align: center;
        border-radius: ${props => props.theme?.skyBet?.borderRadius};
        color: ${props => props.theme?.skyBet?.colours?.white};
        text-decoration: none;
        background-color: ${props =>
            props.theme?.skyBet?.colours?.greenPrimary};
        &:hover,
        &:focus {
            background-color: ${props =>
                props.theme?.skyBet?.colours?.greenDark};
        }
        font-size: ${props => props.theme?.skyBet?.fontSize?.large};
        padding: ${props => props.theme?.skyBet?.spacing?.large}
            ${props => props.theme?.skyBet?.spacing?.xxlarge};

        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            font-size: ${props => props.theme.fontSize.medium};
            padding: ${props => props.theme?.skyBet?.spacing?.small}
                ${props => props.theme?.skyBet?.spacing?.large};
        }
    }

    ${AppLinkButton} {
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
    }
`;

export const StandardButtonInternalLink = styled(Link)`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: ${props =>
        props.theme?.button?.primary?.borderRadius ?? '4px'};
    color: ${props =>
        props.textColour
            ? props.theme.colours[props.textColour]
            : props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${props =>
            props.theme?.button?.primary?.onHoverBgColour ??
            props.theme.colours.greenDark};
    }
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing[props.sidePadding]};
    text-transform: ${props => props.theme?.button?.textTransform};
    letter-spacing: ${props => props.theme?.button?.letterSpacing};
    font-weight: ${props => props.theme?.button?.fontWeight};
`;

export const StandardButtonLink = styled.a`
    display: inline-block;
    width: 100%;
    font-family: ${props => props.theme.fonts.medium};
    box-sizing: border-box;
    border: 0;
    text-align: center;
    border-radius: ${props =>
        props.theme?.button?.primary?.borderRadius ?? '4px'};
    color: ${props =>
        props.textColour
            ? props.theme.colours[props.textColour]
            : props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    text-decoration: none;
    background-color: ${props => props.theme.colours.greenPrimary};
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: ${props =>
            props.theme.button?.primary?.onHoverBgColour ??
            props.theme.colours.greenDark};
        color: ${props =>
            props.textColour
                ? props.theme.colours[props.textColour]
                : props.theme.colours.white};
    }
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large}
        ${props => props.theme.spacing[props.sidePadding]};
    text-transform: ${props => props.theme?.button?.textTransform};
    letter-spacing: ${props => props.theme?.button?.letterSpacing};
    font-weight: ${props => props.theme?.button?.fontWeight};
`;

export const ButtonLinkWithIcon = styled.a`
    display: flex;
    font-family: ${props => props.theme.fonts.medium};
    border: 0;
    text-align: left;
    color: ${props =>
        props.textColour
            ? props.theme.colours[props.textColour]
            : props.theme.colours?.colorOnPrimary ?? 'black'};
    font-size: ${props => props.theme.fontSize.medium};
    padding: 8px;
    text-decoration: none;
    height: 24px;
    line-height: 24px;

    img {
        height: 24px;
        width: 24px;
        padding-right: 8px;
    }

    width: 250px;
    text-transform: ${props => props.textTransform};
    letter-spacing: ${props => props.theme?.button?.letterSpacing};
    font-weight: ${props => props.theme?.button?.fontWeight};
`;

export const BackButton = styled(Link, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    display: flex;
    font-family: ${props => props.theme.fonts.regular};
    border: 0;
    text-align: center;
    color: ${props => props.theme.colours.bluePrimary};
    text-decoration: none;
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.large};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        .hideOnMobile {
            display: none;
        }
    }
`;

export const Chevron = styled(Icon)`
    margin-right: ${props => props.theme.spacing.small};
`;

export const InternalButton = styled(StandardButtonInternalLink, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    background-color: ${props => props.theme.colours[props.colour]};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours[props.hoverColour]};
    }
    font-size: ${props => props.theme.fontSize[props.fontSize]};
    padding: ${props => props.theme.spacing[props.padding]}
        ${props => props.theme.spacing[props.sidePadding]};
`;

export const Button = styled(StandardButton, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    background-color: ${props => props.theme.colours[props.colour]};
    color: ${props =>
        props.textColour
            ? props.theme.colours[props.textColour]
            : props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:hover,
    &:focus {
        background-color: ${props =>
            props.hoverColour
                ? props.theme.colours[props.hoverColour]
                : props.theme.button?.primary?.onHoverBgColour ??
                  props.theme.colours.greenDark};
    }
    font-size: ${props => props.theme.fontSize[props.fontSize]};
    padding: ${props => props.theme.spacing[props.padding]}
        ${props => props.theme.spacing[props.sidePadding]};
`;

export const DisabledButton = styled(StandardButton)`
    background-color: ${props => props.theme.colours.disabledButton};
    color: ${props => props.theme.colours.white};
    cursor: default !important;
    font-size: ${props => props.theme.fontSize[props.fontSize]};
    padding: ${props => props.theme.spacing[props.padding]}
        ${props => props.theme.spacing[props.sidePadding]};
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours.disabledButton};
    }
`;

export const ButtonLink = styled(StandardButtonLink, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    background-color: ${props => props.theme.colours[props.colour]};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:hover,
    &:focus {
        background-color: ${props => props.theme.colours[props.hoverColour]};
    }
    font-size: ${props => props.theme.fontSize[props.fontSize]};
    padding: ${props => props.theme.spacing[props.padding]}
        ${props => props.theme.spacing[props.sidePadding]};
`;

export const Subtext = styled.div`
    text-align: right;
    color: ${props => props.theme.colours.textGrey};
    padding-top: 5px;
    font-size: ${props => props.theme.fontSize.medium};
`;
