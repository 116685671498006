import styled from '@emotion/styled/macro';

export const TextContainer = styled.div`
    background-color: ${props =>
        props.theme.componentColours.textInBox[props.variant].background};
    color: ${props =>
        props.theme.componentColours.textInBox[props.variant].text};
    font-family: ${props => props.theme.fonts.bold};
    display: inline-flex;
`;

export const Text = styled.span`
    padding: ${props =>
        `${props.theme.spacing.xsmall} ${props.theme.spacing.small}`};
    height: fit-content;
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 2vw, ${props.theme.fontSize.small})`};
    text-transform: uppercase;
`;
