import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ParentStyled from '../../styles';
import * as Styled from './styles';
import PageTitle from '../../../../components/Common/PageTitle';
import { PageContainer } from '../../../../components/Common/Page/styles';
import { requestPundits } from '../../../../store/modules/league/actions';
import { selectPundits } from '../../../../store/modules/league/selectors';
import Button from '../../../../components/Common/Button';
import LeagueName from './Inputs/LeagueName';
import { sendNavigationEvent } from '../../../../helpers/GoogleAnalytics/gaHelper';
import eventContext from '../../../../helpers/GoogleAnalytics/event_context';
import modules from '../../../../helpers/GoogleAnalytics/modules';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

export const LeagueForm = ({
    pageTitle,
    subtitle,
    leagueNameText,
    onSubmit,
    submitLabel,
    defaultLeagueName,
    defaultPundits,
    pundits,
    getPundits
}) => {
    useEffect(() => {
        if (!pundits) {
            getPundits();
        }
    }, [pundits, getPundits]);

    const [selectedPundits] = useState(defaultPundits);
    const [leagueName, setLeagueName] = useState(defaultLeagueName);

    if (!pundits) {
        return null;
    }

    const valid = leagueName.length > 0;

    const onClickSubmit = () => {
        if (!valid) {
            return;
        }

        onSubmit(leagueName, selectedPundits);
    };

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.leagues.created,
            1,
            text,
            position,
            destinationUrl,
            context
        );
    };

    return (
        <PageContainer>
            <PageTitle>{pageTitle}</PageTitle>
            <ParentStyled.Page>
                <Styled.Grid numPundits={pundits.length}>
                    <LeagueName
                        subtitle={subtitle}
                        leagueNameText={leagueNameText}
                        leagueName={leagueName}
                        setLeagueName={setLeagueName}
                    />
                </Styled.Grid>

                <Styled.ButtonContainer>
                    <Styled.ButtonWrapper>
                        <Button
                            disabled={!valid}
                            onClick={() => {
                                setNavigationEvent(
                                    elementText.navigation.createLeague,
                                    1,
                                    `https://super6.skysports.com/league/slug/season`,
                                    eventContext.leagueCreated
                                );
                                onClickSubmit();
                            }}
                            id="league-submit-button"
                            data-test-id="league-form-submit-button"
                        >
                            {submitLabel}
                        </Button>
                    </Styled.ButtonWrapper>
                </Styled.ButtonContainer>
            </ParentStyled.Page>
        </PageContainer>
    );
};

LeagueForm.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    leagueNameText: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultLeagueName: PropTypes.string,
    defaultPundits: PropTypes.arrayOf(PropTypes.number),
    pundits: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            name: PropTypes.string,
            imageUri: PropTypes.string
        })
    ),
    getPundits: PropTypes.func.isRequired
};

LeagueForm.defaultProps = {
    pundits: null,
    defaultLeagueName: '',
    defaultPundits: []
};

const mapStateToProps = state => ({
    pundits: selectPundits(state)
});

const mapDispatchToProps = dispatch => ({
    getPundits: () => dispatch(requestPundits())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueForm);
