import { call, put, takeLeading } from 'redux-saga/effects';
import * as actions from './actions';
import bannerAPI from '../../../api/banner';
import * as types from './types';
import { setBannerHash, loadBannerHash } from './helpers/localStorage';

function* getBanner() {
    try {
        const data = yield call(bannerAPI.getBanner);
        yield put(actions.receiveBanner(data));
    } catch (err) {
        yield put(actions.receiveBanner(null));
    }
}

export function* watchRequestBanner() {
    yield takeLeading(types.REQUEST_BANNER, getBanner);
}

function* getLocalStorageBannerId() {
    try {
        const data = loadBannerHash();
        yield put(actions.receiveBannerId(data));
    } catch (err) {
        yield put(actions.receiveBannerId(null));
    }
}
function* setBannerClose(data) {
    try {
        const bannerWithCustomerId = `${data.banner.id}-${data.customerId}`;
        setBannerHash(bannerWithCustomerId);
        yield put(actions.receiveBannerId(bannerWithCustomerId));
    } catch (err) {
        // do not need to do anything
    }
}

export function* watchRequestLocalStorageBannerId() {
    yield takeLeading(types.REQUEST_BANNER_ID, getLocalStorageBannerId);
}

export function* watchBannerOnClose() {
    yield takeLeading(types.SET_BANNER_CLOSE, setBannerClose);
}

export const sagas = [
    watchRequestBanner,
    watchRequestLocalStorageBannerId,
    watchBannerOnClose
];
