import axios from 'axios';
import { API_ROOT } from '../config';
import UserFriendlyError from '../errors/UserFriendlyError';
import { MAXIMUM_FOLLOWEE_LIMIT_ERROR } from '../errors/messages';
import { getUserSessionId } from '../helpers/sessionStorage';

const commonHeaders = {
    Accept: 'application/json',
    'X-Session-Id': getUserSessionId()
};

const followApi = {
    getFollowees: async token => {
        const { data, status } = await axios.get(`${API_ROOT()}/follow`, {
            headers: {
                Authorization: token,
                ...commonHeaders
            }
        });

        if (status !== 200) {
            throw new Error('Unable to get followees');
        }
        return data;
    },
    createFollowee: async (followeeId, token) => {
        const { data, status } = await axios.post(
            `${API_ROOT()}/follow`,
            {
                userIds: [followeeId]
            },
            {
                headers: {
                    Authorization: token,
                    ...commonHeaders
                }
            }
        );

        if (status === 400) {
            throw UserFriendlyError(MAXIMUM_FOLLOWEE_LIMIT_ERROR);
        }
        if (status !== 201) {
            throw new Error('Unable to follow user');
        }
        return data;
    },
    deleteFollowee: async (followeeId, token) => {
        const { data, status } = await axios.delete(
            `${API_ROOT()}/follow/${followeeId}`,
            {
                headers: {
                    Authorization: token,
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to unfollow');
        }
        return data;
    }
};

export default followApi;
