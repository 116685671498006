import styled from '@emotion/styled/macro';

export const GoldenGoalContainer = styled.div`
    color: ${props => props.theme.colours.textGrey};
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.radius.normal};
    border-bottom: ${props =>
        props.underlined
            ? `1px solid ${props.theme.colours.borderGrey}`
            : 'none'};
    margin: ${props => `0 0 ${props.theme.spacing.large} 0`};
    padding: ${props => `${props.theme.spacing.large}`};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => `${props.theme.spacing.xlarge}`};
    }
`;

export const GoldenGoalTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        align-items: flex-start;
    }
`;

export const GoldenGoalTitleSection = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const GoldenGoalToggleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 28px;
    cursor: pointer;
    background: #01298a1a;
`;

export const GoldenGoalToggleSectionOpen = styled(GoldenGoalToggleSection)`
    background: ${props => props.theme.colours.colorOnPrimary};
`;

export const GoldenGoalToggleIcon = styled.div`
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: url('/content/img/icons/question-mark-outline.svg') center
        no-repeat;
`;

export const GoldenGoalToggleIconOpen = styled(GoldenGoalToggleIcon)`
    background: url('/content/img/icons/question-mark-fill.svg') center
        no-repeat;
`;

export const GoldenGoalTitleIcon = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('/content/img/icons/golden-goal.svg');
`;

export const GoldenGoalTitleText = styled.p`
    font-size: ${props => props.theme.fontSize.large2};
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colours.black};
    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;

export const GoldenGoalToggleText = styled.p`
    font-size: ${props => props.theme.fontSize.normal};
    font-family: ${props => props.theme.fonts.medium};
    padding-right: 6px;
    color: ${props => props.theme.colours.colorOnPrimary};
`;

export const GoldenGoalToggleTextOpen = styled(GoldenGoalToggleText)`
    color: ${props => props.theme.colours.white};
`;

export const GoldenGoalText = styled.p`
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.regular};
    line-height: 24px;
    padding-top: 8px;
    span {
        font-family: ${props => props.theme.fonts.medium};
    }
`;

export const GoldenGoalTextContainer = styled.div`
    overflow: hidden;
    max-height: ${({ isOpen }) => (isOpen ? '100px' : '0')};
    transition: max-height 0.4s ease-in;
`;

export const GoldenGoalInput = styled.div`
    border: 2px solid ${props => props.theme.colours.borderGrey};
    margin: 14px auto 0;
    padding: 2px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eaeaea;

    &.inputError {
        border: 2px solid #d92231;

        input:focus {
            outline: none;
        }
    }

    input {
        border-radius: 5px;
        border: 2px;
        text-align: center;
        font-size: ${props => props.theme.fontSize.medium};
        font-family: ${props => props.theme.fonts.regular};
        line-height: 18px;
        border-width: 0;
        box-sizing: border-box;
        background-color: #eaeaea;
        width: 100%;
        height: 44px;
        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @media (max-width: ${props => props.theme.widths.desktopMin}) {
            font-size: ${props => props.theme.fontSize.normal};
        }
    }
`;

export const GoldenGoalInputError = styled(GoldenGoalInput)`
    border: 2px solid #d92231;
    input:focus {
        outline: none;
    }
`;

export const GoldenGoalInputErrorSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${props => `${props.theme.spacing.normal}`};
`;

export const GoldenGoalInputErrorText = styled.p`
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.medium};
    color: #d92231;
    padding: 0;
`;

export const GoldenGoalInputErrorIcon = styled.div`
    display: flex;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: url('/content/img/icons/exclamation-red.svg') center no-repeat;
`;

export const SkeletonContainer = styled(GoldenGoalContainer)`
    padding: 0;
`;
