import React from 'react';
import Skeleton from '../../../../components/Skeleton';
import * as Styled from './styles';

export default function LoadingGoldenGoalSkeleton() {
    return (
        <Styled.SkeletonContainer data-test-id="loading-skeleton-container">
            <Skeleton height="300px" width="auto" />
        </Styled.SkeletonContainer>
    );
}
