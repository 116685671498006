/* eslint-disable import/no-unused-modules */
const getChancePercentages = (home, draw, away) => {
    if (!home || !draw || !away) {
        return {};
    }

    const percentages = { home, draw, away };

    const highest = Object.keys(percentages).reduce((a, b) =>
        percentages[a] > percentages[b] ? a : b
    );

    let homeChance;
    let drawChance;
    let awayChance;

    if (highest === 'home') {
        homeChance = 100 - draw - away;
        drawChance = draw;
        awayChance = away;
    }
    if (highest === 'draw') {
        drawChance = 100 - home - away;
        homeChance = home;
        awayChance = away;
    }
    if (highest === 'away') {
        awayChance = 100 - draw - home;
        drawChance = draw;
        homeChance = home;
    }

    return {
        homeChance,
        drawChance,
        awayChance
    };
};

export { getChancePercentages as default };
