import axios from 'axios';
import { API_ROOT, OAUTH_URI, OAUTH_CALLBACK_URI } from '../config';

const oauthApi = {
    getTokens: async code => {
        const codeVerifier = sessionStorage.getItem('oauth_codeVerifier');
        const { data, status } = await axios.post(
            `${OAUTH_URI}/openid/v1/token`,
            {
                grant_type: 'authorization_code',
                redirect_uri: OAUTH_CALLBACK_URI,
                client_id: 'super6web',
                code_verifier: codeVerifier,
                code
            }
        );
        if (status >= 400) {
            throw new Error('Unable to get oauth tokens');
        }
        return data;
    },

    refreshTokens: async refreshToken => {
        const { data, status } = await axios.post(
            `${OAUTH_URI}/openid/v1/token`,
            {
                grant_type: 'refresh_token',
                client_id: 'super6web',
                refresh_token: refreshToken
            }
        );
        if (status >= 400) {
            throw new Error('Unable to refresh tokens');
        }
        return data;
    },

    getVerifierCodes: async () => {
        const { data, status } = await axios.get(`${API_ROOT()}/auth/codes`);
        if (status >= 400) {
            throw new Error('Unable to get auth codes');
        }

        return data;
    },

    getUserLoggedIn: async token => {
        const { data } = await axios.post(`${OAUTH_URI}/openid/v1/token_info`, {
            client_id: 'super6web',
            token,
            token_type_hint: 'access_token'
        });
        return !!(data && data.active);
    }
};

export default oauthApi;
