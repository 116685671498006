import axios from 'axios';
import { API_ROOT } from '../config';

const feedbackApi = {
    submitFeedback: async feedback => {
        const requestBody = { ...feedback };
        const { data, status } = await axios.post(
            `${API_ROOT()}/feedback`,
            requestBody
        );

        if (status !== 201) {
            throw new Error('Feedback could not be submitted');
        }
        return data;
    }
};

export default feedbackApi;
