import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../mastheadStyles';
import MastheadTitle from '../MastheadTitle';
import ResultMastheadSummary from './ResultMastheadSummary';

const ResultMastheadContent = ({
    isInplay,
    isComplete,
    isWinning,
    isDrawing,
    hasPredicted,
    roundIsActive,
    opponentName,
    userPredictions,
    punditPredictions
}) => {
    const isPrelive = !isComplete && !isInplay;

    if (roundIsActive && hasPredicted) {
        if (isPrelive) {
            return (
                <Styled.Content>
                    <MastheadTitle
                        text="Check back after KO!"
                        align="center"
                        isInplay={false}
                    />
                    <ResultMastheadSummary
                        userPredictions={userPredictions}
                        punditPredictions={punditPredictions}
                        opponentName={opponentName}
                        isPrelive={isPrelive}
                    />
                </Styled.Content>
            );
        }
        if (isInplay) {
            return (
                <Styled.Content>
                    <MastheadTitle align="center" isInplay />
                    <ResultMastheadSummary
                        userPredictions={userPredictions}
                        punditPredictions={punditPredictions}
                        opponentName={opponentName}
                    />
                </Styled.Content>
            );
        }
        if (isComplete) {
            if (isWinning) {
                return (
                    <Styled.Content>
                        <MastheadTitle
                            text={`You beat ${opponentName}!`}
                            align="center"
                            isInplay={false}
                        />
                        <ResultMastheadSummary
                            userPredictions={userPredictions}
                            punditPredictions={punditPredictions}
                            opponentName={opponentName}
                        />
                    </Styled.Content>
                );
            }
            if (isDrawing) {
                return (
                    <Styled.Content>
                        <MastheadTitle
                            text={`It's a draw!`}
                            align="center"
                            isInplay={false}
                        />
                        <ResultMastheadSummary
                            userPredictions={userPredictions}
                            punditPredictions={punditPredictions}
                            opponentName={opponentName}
                        />
                    </Styled.Content>
                );
            }

            return (
                <Styled.Content>
                    <MastheadTitle
                        text={`${opponentName} wins this one!`}
                        align="center"
                        isInplay={false}
                    />
                    <ResultMastheadSummary
                        userPredictions={userPredictions}
                        punditPredictions={punditPredictions}
                        opponentName={opponentName}
                    />
                </Styled.Content>
            );
        }
    }

    if (roundIsActive && !hasPredicted && isPrelive) {
        return (
            <Styled.Content>
                <MastheadTitle
                    text={`You have to enter your predictions before you challenge ${opponentName}!`}
                    align="left"
                    isInplay={false}
                />
                <Styled.SubTitle>
                    {`Enter this weeks round to be in with a chance of beating ${opponentName}!`}
                </Styled.SubTitle>
            </Styled.Content>
        );
    }

    return (
        <Styled.Content>
            <MastheadTitle
                text={`You've missed your chance`}
                align="left"
                isInplay={false}
            />
            <Styled.SubTitle>You didn&apos;t play this round.</Styled.SubTitle>
            <Styled.SubTitle>
                {`Enter this weeks round to be in with a chance of beating ${opponentName}!`}
            </Styled.SubTitle>
        </Styled.Content>
    );
};

ResultMastheadContent.propTypes = {
    isInplay: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    isWinning: PropTypes.bool.isRequired,
    isDrawing: PropTypes.bool.isRequired,
    hasPredicted: PropTypes.bool.isRequired,
    roundIsActive: PropTypes.bool.isRequired,
    opponentName: PropTypes.string.isRequired,
    userPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }).isRequired,
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }).isRequired
};

export default ResultMastheadContent;
