import * as types from './types';
import { getMarketConfig } from '../../../helpers/accumulator';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    rounds: null,
    activeRound: null,
    activeRoundPredictionCount: 0,
    stats: {},
    pundits: {},
    bets: {},
    punditPredictions: null,
    marketOutcomes: null,
    loading: false,
    headToHead: {},
    loadedHeadToHead: {},
    liveOdds: {},
    isLoadingMarkets: {},
    hasLoadedMarket: {},
    isLoadingUpcomingRounds: false,
    upcomingRounds: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.ACTIVE_ROUND_CHANGE:
            return newIfChanged(state, {
                ...defaultState,
                activeRound: state.activeRound,
                loading: state.loading
            });
        case types.LOADING_ROUNDS:
            return newIfChanged(state, {
                ...state,
                loading: true
            });
        case types.FAILED_LOADING_ROUNDS:
            return newIfChanged(state, {
                ...state,
                loading: false
            });
        case types.LOADING_HEAD_TO_HEAD:
            return newIfChanged(state, {
                ...state,
                loadedHeadToHead: {
                    ...state.loadedHeadToHead,
                    [parseInt(action.roundId, 10)]: false
                }
            });
        case types.FAILED_LOADING_HEAD_TO_HEAD:
            return newIfChanged(state, {
                ...state,
                loadedHeadToHead: {
                    ...state.loadedHeadToHead,
                    [parseInt(action.roundId, 10)]: true
                }
            });
        case types.RECEIVE_ROUNDS:
            return newIfChanged(state, {
                ...state,
                rounds: action.payload,
                loading: false
            });

        case types.RECEIVE_ACTIVE_ROUND:
            return newIfChanged(state, {
                ...state,
                activeRound: action.payload
            });

        case types.MATCH_STATS_LOADING:
            return newIfChanged(state, {
                ...state,
                stats: {
                    ...state.stats,
                    [action.matchId]: { loading: true }
                }
            });

        case types.REQUEST_MATCH_STATS_FAILED:
            return newIfChanged(state, {
                ...state,
                stats: {
                    ...state.stats,
                    [action.matchId]: { loading: false }
                }
            });

        case types.RECEIVE_MATCH_STATS:
            return newIfChanged(state, {
                ...state,
                stats: {
                    ...state.stats,
                    [action.matchId]: { data: action.payload, loading: false }
                }
            });

        case types.MATCH_PUNDITS_LOADING:
            return newIfChanged(state, {
                ...state,
                pundits: {
                    ...state.pundits,
                    [action.matchId]: { loading: true }
                }
            });

        case types.REQUEST_MATCH_PUNDITS_FAILED:
            return newIfChanged(state, {
                ...state,
                pundits: {
                    ...state.pundits,
                    [action.matchId]: { loading: false }
                }
            });

        case types.RECEIVE_MATCH_PUNDITS:
            return newIfChanged(state, {
                ...state,
                pundits: {
                    ...state.pundits,
                    [action.matchId]: { data: action.payload, loading: false }
                }
            });

        case types.MATCH_BETS_LOADING:
            return newIfChanged(state, {
                ...state,
                bets: {
                    ...state.bets,
                    [action.matchId]: { loading: true }
                }
            });

        case types.REQUEST_MATCH_BETS_FAILED:
            return newIfChanged(state, {
                ...state,
                bets: {
                    ...state.bets,
                    [action.matchId]: { loading: false }
                }
            });

        case types.RECEIVE_MATCH_BETS:
            return newIfChanged(state, {
                ...state,
                bets: {
                    ...state.bets,
                    [action.matchId]: { data: action.payload, loading: false }
                }
            });
        case types.LOADING_PUNDIT_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                punditPredictions: {
                    isLoading: true,
                    roundId: action.roundId
                }
            });
        case types.RECEIVE_PUNDIT_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                punditPredictions: {
                    isLoading: false,
                    roundId: action.roundId,
                    predictions: action.payload
                }
            });
        case types.FAILED_LOAD_PUNDIT_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                punditPredictions: {
                    isLoading: false,
                    roundId: action.roundId,
                    predictions: null
                }
            });
        case types.RECEIVE_OUTCOMES_FOR_MARKET:
            return newIfChanged(state, {
                ...state,
                marketOutcomes: {
                    ...state.marketOutcomes,
                    [action.market]: Object.keys(action.payload).reduce(
                        (result, matchId) => {
                            if (
                                Object.keys(action.payload[matchId]).length !==
                                getMarketConfig(action.market).outcomes.length
                            ) {
                                return result;
                            }

                            result.push({
                                ...action.payload[matchId],
                                match: parseInt(matchId, 10)
                            });
                            return result;
                        },
                        []
                    )
                }
            });
        case types.RECEIVE_HEAD_TO_HEAD:
            return newIfChanged(state, {
                ...state,
                headToHead: {
                    ...state.headToHead,
                    [action.roundId]: action.payload
                },
                loadedHeadToHead: {
                    ...state.loadedHeadToHead,
                    [parseInt(action.roundId, 10)]: true
                }
            });
        case types.RECEIVE_LIVE_ODDS:
            return newIfChanged(state, {
                ...state,
                liveOdds: action.payload
            });
        case types.START_LOADING_MARKET:
            return newIfChanged(state, {
                ...state,
                isLoadingMarkets: {
                    ...state.isLoadingMarkets,
                    [action.market]: true
                }
            });
        case types.HAS_LOADED_MARKET:
            return newIfChanged(state, {
                ...state,
                isLoadingMarkets: {
                    ...state.isLoadingMarkets,
                    [action.market]: false
                },
                hasLoadedMarket: {
                    ...state.hasLoadedMarket,
                    [action.market]: true
                }
            });
        case types.LOADING_UPCOMING_ROUNDS:
            return newIfChanged(state, {
                ...state,
                isLoadingUpcomingRounds: true
            });
        case types.RECEIVE_UPCOMING_ROUNDS:
            return newIfChanged(state, {
                ...state,
                upcomingRounds: action.payload,
                isLoadingUpcomingRounds: false
            });
        case types.FAILED_LOADING_UPCOMING_ROUNDS:
            return newIfChanged(state, {
                ...state,
                isLoadingUpcomingRounds: false
            });
        default:
            return state;
    }
};
