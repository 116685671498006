import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Banner = ({ type, srcSet, href, alt }) => (
    <Styled.Banner
        as={!href ? 'div' : 'a'}
        data-target-id={`s6-${type ? `${type}-` : ''}banner-link`}
        data-test-id={`${type}-banner-link`}
        href={href}
    >
        <Styled.BannerImage>
            <source media="(max-width: 550px)" srcSet={srcSet.mobile} />
            <source srcSet={srcSet.desktop} />
            <img src={srcSet.desktop} alt={alt} draggable={false} />
        </Styled.BannerImage>
    </Styled.Banner>
);

Banner.propTypes = {
    type: PropTypes.string,
    href: PropTypes.string,
    srcSet: PropTypes.shape({
        desktop: PropTypes.string,
        mobile: PropTypes.string
    }),
    alt: PropTypes.string
};

Banner.defaultProps = {
    type: null,
    href: '',
    srcSet: {
        desktop: '',
        mobile: ''
    },
    alt: ''
};

export default Banner;
