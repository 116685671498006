import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const MatchPoints = ({ points }) => (
    <Styled.MatchPoints points={points} data-test-id="match-points">
        {points} points
    </Styled.MatchPoints>
);

MatchPoints.propTypes = {
    points: PropTypes.number.isRequired
};
export default MatchPoints;
