import styled from '@emotion/styled/macro';

export const PredictionsCard = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.card.borderRadius};
    width: 100%;
    height: 100%;
    max-width: 500px;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        padding: 18px;
    }
`;

export const PunditContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;

    position: relative;
`;

export const PredictionsContainer = styled.div`
    display: flex;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props => props.hideContent && 'display: none;'}
    }
`;

export const PunditComment = styled.span`
    margin: ${props => props.theme.spacing.vlarge}
        ${props => props.theme.spacing.small}
        ${props => props.theme.spacing.vlarge};

    color: ${props => props.theme.colours.textDark};
    line-height: 1.22;

    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.large};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin-bottom: ${props => props.theme.spacing.vlarge};
    }
`;

export const PunditCommentContainer = styled.div`
    display: flex;

    span:empty {
        margin: 0;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props => props.hideContent && 'display: none;'}
    }
`;

export const Collapse = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: none;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        i {
            font-size: ${props => props.theme.iconSize.large};
        }

        margin-left: 5px;

        ${props => !props.hideContent && 'display: block;'}
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 74px;
    border-bottom: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
`;

export const PunditsList = styled.div`
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 100%));
    justify-items: center;
    align-items: start;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
`;
