import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Styled from './styles';
import * as icons from '../../../styles/icons';

const Button = ({
    back,
    link,
    children,
    onClick,
    slim,
    disabled,
    subtext,
    internal,
    textColour,
    icon,
    backgroundColour,
    hoverColour,
    sendNavEvent,
    isSocialButton,
    ...props
}) => {
    const doOnClick = e => {
        if (onClick) {
            onClick(e);
        }
    };

    const fontSize = slim ? 'normal' : 'large';
    const padding = slim ? 'medium' : 'large';
    const sidePadding = slim ? 'large' : 'xxlarge';
    const isSocialTextTransform = isSocialButton ? 'none' : 'uppercase';

    if (back) {
        return (
            <Styled.BackButton
                to={link}
                padding={padding}
                fontSize={fontSize}
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                <Styled.Chevron data-char={icons.LeftChevron} />
                <span className="hideOnMobile">Back</span>
            </Styled.BackButton>
        );
    }

    if (disabled) {
        return (
            <div>
                <Styled.DisabledButton
                    disabled
                    padding={padding}
                    sidePadding={sidePadding}
                    fontSize={fontSize}
                    {...props} // eslint-disable-line react/jsx-props-no-spreading
                >
                    {children}
                </Styled.DisabledButton>
                {subtext && <Styled.Subtext>{subtext}</Styled.Subtext>}
            </div>
        );
    }

    if (internal) {
        return (
            <Styled.InternalButton
                to={link}
                onClick={sendNavEvent}
                padding={padding}
                sidePadding={sidePadding}
                fontSize={fontSize}
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                {children}
            </Styled.InternalButton>
        );
    }

    if (icon && link) {
        let button = (
            <Styled.ButtonLinkWithIcon
                href={link}
                padding={padding}
                sidePadding={sidePadding}
                fontSize={fontSize}
                textColour={textColour}
                textTransform={isSocialTextTransform}
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                <img src={icon} alt="icon" />
                {children}
            </Styled.ButtonLinkWithIcon>
        );
        // On Android we need to keep the links as local navigation or we highjack the app to browser
        if (window.Android) {
            button = (
                <Link to={link}>
                    <Styled.Button
                        onClick={doOnClick}
                        padding={padding}
                        sidePadding={sidePadding}
                        fontSize={fontSize}
                        textColour={textColour}
                        backgroundColour={backgroundColour}
                        hoverColour={hoverColour}
                        {...props} // eslint-disable-line react/jsx-props-no-spreading
                    >
                        <img src={icon} alt="icon" />
                        {children}
                    </Styled.Button>
                </Link>
            );
        }
        return button;
    }

    if (link) {
        let button = (
            <div>
                <Styled.ButtonLink
                    href={link}
                    onClick={doOnClick}
                    padding={padding}
                    sidePadding={sidePadding}
                    fontSize={fontSize}
                    textColour={textColour}
                    {...props} // eslint-disable-line react/jsx-props-no-spreading
                >
                    {children}
                </Styled.ButtonLink>
                {subtext && <Styled.Subtext>{subtext}</Styled.Subtext>}
            </div>
        );
        // On Android we need to keep the links as local navigation or we highjack the app to browser
        if (window.Android) {
            button = (
                <Link to={link}>
                    <div>
                        <Styled.Button
                            onClick={doOnClick}
                            padding={padding}
                            sidePadding={sidePadding}
                            fontSize={fontSize}
                            textColour={textColour}
                            backgroundColour={backgroundColour}
                            hoverColour={hoverColour}
                            {...props} // eslint-disable-line react/jsx-props-no-spreading
                        >
                            {children}
                        </Styled.Button>
                        {subtext && <Styled.Subtext>{subtext}</Styled.Subtext>}
                    </div>
                </Link>
            );
        }
        return button;
    }

    return (
        <div>
            <Styled.Button
                onClick={doOnClick}
                padding={padding}
                sidePadding={sidePadding}
                fontSize={fontSize}
                textColour={textColour}
                backgroundColour={backgroundColour}
                hoverColour={hoverColour}
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                {children}
            </Styled.Button>
            {subtext && <Styled.Subtext>{subtext}</Styled.Subtext>}
        </div>
    );
};

Button.propTypes = {
    link: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colour: PropTypes.string,
    hoverColour: PropTypes.string,
    id: PropTypes.string,
    slim: PropTypes.bool,
    disabled: PropTypes.bool,
    analytics: PropTypes.string,
    subtext: PropTypes.string,
    internal: PropTypes.bool,
    back: PropTypes.bool,
    headerTitle: PropTypes.string,
    textColour: PropTypes.string,
    icon: PropTypes.string,
    backgroundColour: PropTypes.string,
    sendNavEvent: PropTypes.func,
    isSocialButton: PropTypes.bool
};

Button.defaultProps = {
    link: null,
    children: '',
    onClick: null,
    colour: '',
    hoverColour: '',
    id: '',
    slim: false,
    disabled: false,
    analytics: null,
    subtext: null,
    internal: false,
    back: false,
    headerTitle: null,
    textColour: '',
    icon: null,
    backgroundColour: null,
    isSocialButton: false,
    sendNavEvent: () => {}
};

export default Button;
