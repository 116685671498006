import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'react-router-dom';
import AppLink from '../../../Common/AppLink';

export const NavContainer = styled.div`
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        display: ${props => (props.mobileNavOpen ? 'flex' : 'none')};
        background: ${props => props.theme.colours.burgerNav.background};
        width: 100%;
        position: fixed;
        top: 98px;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
    }

    > div {
        display: flex;

        @media (max-width: ${props => props.theme.widths.tabletMax}) {
            flex-direction: column;
            overflow-y: auto;
            width: 100%;
            height: 100%;
        }
    }
`;

export const NavLinkSection = styled.div`
    display: none;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        display: block;
        margin: 0 30px;
        font-family: ${props => props.theme.fonts.medium};
        line-height: 50px;
        font-size: 20px;
        color: ${props => props.theme.colours.header.navLink};

        ${props =>
            props.new &&
            `
                &::after {
                    display: inline;
                    position: relative;
                    content: 'new';
                    top: -9px;
                    margin-left: 5px;
                    font-size: 0.6em;
                    font-weight: bold;
                    color: #fff;
                    background: #d92231;
                    border-radius: 3px;
                    padding: 2px 4px;
                }
            `}
    }
`;

export const NavLinkWrapper = styled.div`
    padding: 0 14px;

    &:first-of-type {
        padding-left: 0;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        line-height: ${props => props.theme.lineHeight.medium};
        padding: 4px 60px;

        &:first-of-type {
            padding: 4px 32px;
        }
    }
`;

export const NavLink = styled(Link, {
    shouldForwardProp: prop => isPropValid(prop) && prop !== 'activeTab'
})`
    color: ${props => props.theme.colours.header.navLink};
    font-size: ${props => props.theme.fontSize.large};
    text-decoration: none;
    font-family: ${props =>
        props.activeTab ? props.theme.fonts.medium : props.theme.fonts.regular};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props =>
            props.navOnly
                ? `
display: none;`
                : `
        font-size: 20px;
        display: block;
        width: 100%;
        height: 100%;
        `}
    }
`;

export const NavExternalLink = styled.a`
    color: ${props => props.theme.colours.header.navLink};
    font-size: ${props => props.theme.fontSize.large};
    text-decoration: none;
    font-family: ${props => props.theme.fonts.regular};

    &:link {
        text-decoration: none;
    }

    ${props =>
        props.new &&
        `
        &::after {
            position: absolute;
            content: 'new';
            font-size: 0.6em;
            font-weight: bold;
            color: #fff;
            background: #d92231;
            border-radius: 3px;
            padding: 2px 4px;
            margin-top: -12px;
            margin-left: -16px;
        }
        `};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props =>
            props.navOnly
                ? `display: none;`
                : `
                font-size: 20px;
                display: block;
                width: 100%;
                height: 100%;`}
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        &::after {
            display: none;
        }
    }
`;

export const NavExternalAppLink = styled(AppLink)`
    color: ${props => props.theme.colours.header.navLink};
    font-size: ${props => props.theme.fontSize.large};
    text-decoration: none;
    font-family: ${props => props.theme.fonts.regular};

    ${props =>
        props.new &&
        `
        &::after {
            position: absolute;
            content: 'new';
            font-size: 0.6em;
            font-weight: bold;
            color: #fff;
            background: #d92231;
            border-radius: 3px;
            padding: 2px 4px;
            margin-top: -12px;
            margin-left: -16px;
        }
        `};

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props =>
            props.navOnly
                ? `display: none;`
                : `
            text-align: left;
            font-size: 20px;
            display: block;
            width: 100%;
            height: 100%;`}
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        &::after {
            display: none;
        }
    }
`;

export const NavButton = styled.button`
    margin: 0;
    color: ${props => props.theme.colours.header.navLink};
    text-align: left;
    font-size: ${props => props.theme.fontSize.large};
    line-height: ${props => props.theme.fontSize.large};
    text-decoration: none;
    font-family: ${props =>
        props.activeTab ? props.theme.fonts.medium : props.theme.fonts.regular};
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        ${props =>
            props.navOnly
                ? `display: none;`
                : `
        font-size: 20px;
        display: block;
        width: 100%;
        height: 100%;
        line-height: 36px;
    `}
    }
`;
