import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { IMAGE_BASE_PATH } from '../../../../config';

export const WinAvatar = styled.div`
    background-image: url(${IMAGE_BASE_PATH}/img/head-to-head/h2h-winner-avatar-white.svg);
    background-size: cover;
    transform: scale(-1, 1);
    transform-origin: center;
    display: inline-block;
`;

export const PrizeWinnerListContainer = styled.div`
    text-align: left;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.medium};
    background-color: ${props => props.theme.scores?.mainBgColour};
    border-radius: 20px;
`;

export const PrizeWinnerListTitle = styled.div`
    padding: 12px;
    background-color: ${props =>
        props.theme?.card?.bgColour ?? props.theme.colours.lightGrey};
    justify-content: center;
    font-family: ${props =>
        props.theme.fonts.skySportsD ?? props.theme.fonts.medium};
    font-weight: ${props => props.theme.fonts.skySportsD && '700'};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.large}, 2vw, ${props.theme.fontSize.xlarge})`};
    text-align: center;
    color: ${props => props.theme.colours.header.textColor};
    border-radius: ${props =>
        props.theme?.card?.borderRadius
            ? `${props.theme?.card?.borderRadius} ${props.theme?.card?.borderRadius} 0 0`
            : '3px 3px 0 0'};
`;

export const PrizeWinnerList = styled.div`
    display: flex;
    width: 90%;
    align-self: center;
`;

export const PrizeWinnerListInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;
    align-items: center;
`;

export const PrizeWinnerListItem = styled(Link, {
    shouldForwardProp: prop => isPropValid(prop) || prop === 'to'
})`
    border-top: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.borderGrey}`};
    width: 100%;
    margin: 0;
    display: inline-flex;
    align-items: center;
    height: 63px;
    float: left;
    font-size: ${props =>
        `clamp(${props.theme.fontSize.medium}, 2vw, ${props.theme.fontSize.large})`};
    text-decoration: none;
    color: ${props => props.theme.colours.colorOnPrimary};

    ${props =>
        props.isUser
            ? `
    background: ${props.theme.headToHead.userWinnerBackground};
    font-family: ${props.theme.fonts.medium};`
            : ''}
`;

export const WinnerListAvatar = styled(WinAvatar)`
    background-image: url(${IMAGE_BASE_PATH}/img/head-to-head/h2h-winner-avatar.svg);
    height: ${props => props.theme.iconSize.xlarge};
    width: ${props => props.theme.iconSize.xlarge};
    margin: ${props => `${props.theme.spacing.normal}
        ${props.theme.spacing.medium}`};
`;

export const PendingSpace = styled.div`
    width: 36px;
    margin: 8px 10px;
`;

export const WinnerListText = styled.div`
    display: inline-block;
    flex-grow: 1;
    text-align: left;
    width: 0;

    ${props =>
        props.pending &&
        `
        font-family: ${props.theme.fonts.italic}
    `}
`;

export const Points = styled.div`
    text-align: center;
    align-items: center;
    color: ${props => props.theme.colours.colorOnPrimary};
    padding: 0 ${props => props.theme.spacing.large} 0
        ${props => props.theme.spacing.large};
    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        display: none;
    }
`;

export const WinnerListPoints = styled.div`
    font-family: ${props => props.theme.fonts.bold};
    height: ${props => props.theme.iconSize.xlarge};
    width: ${props => props.theme.iconSize.xlarge};
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 50%;

    background: ${props =>
        props.isUser
            ? props.theme.match?.winningGreen
            : props.theme.colours.colorOnPrimary};

    color: ${props =>
        props.isUser
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};

    span {
        width: 100%;
    }
`;
