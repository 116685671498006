import * as types from './types';
import * as authTypes from '../auth/types';
import { RECEIVE_ROUND_PREDICTIONS } from '../results/types';
import { newIfChanged } from '../../../helpers/stateHelper';
import { POST_PREDICTIONS_SUCCESS } from '../play/types';

const defaultState = {
    info: {
        userId: null,
        customerId: null,
        canBet: true
    },
    loadingActiveRoundPredictions: false,
    hasPredictedRounds: [],
    isOnDesktop: window.innerWidth >= 1024
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case authTypes.LOGOUT:
            return newIfChanged(state, {
                ...state,
                info: {
                    userId: null,
                    customerId: null,
                    canBet: true
                },
                hasPredictedRounds: []
            });
        case types.RECEIVE_USER_INFO:
            return newIfChanged(state, {
                ...state,
                info: action.payload.user,
                isLoggedIn: true
            });
        case types.REQUEST_ACTIVE_ROUND_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                loadingActiveRoundPredictions: true
            });
        case POST_PREDICTIONS_SUCCESS:
            if (state.hasPredictedRounds.includes(action.roundId)) {
                return state;
            }
            return newIfChanged(state, {
                ...state,
                hasPredictedRounds: [
                    ...state.hasPredictedRounds,
                    action.roundId
                ]
            });
        case types.RECEIVE_ACTIVE_ROUND_PREDICTIONS:
            if (
                !action.payload.hasPredicted ||
                state.hasPredictedRounds.includes(action.payload.roundId)
            ) {
                return newIfChanged(state, {
                    ...state,
                    loadingActiveRoundPredictions: false
                });
            }
            return newIfChanged(state, {
                ...state,
                hasPredictedRounds: [
                    ...state.hasPredictedRounds,
                    action.payload.roundId
                ],
                loadingActiveRoundPredictions: false
            });
        case RECEIVE_ROUND_PREDICTIONS:
            if (
                !action.payload.hasPredicted ||
                state.hasPredictedRounds.includes(action.roundId)
            ) {
                return state;
            }
            return newIfChanged(state, {
                ...state,
                hasPredictedRounds: [
                    ...state.hasPredictedRounds,
                    action.roundId
                ]
            });
        case types.WINDOW_RESIZE:
            return newIfChanged(state, {
                ...state,
                isOnDesktop: action.width >= 1024
            });
        default:
            return state;
    }
};
