/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
    ContentPageContainer,
    Icon
} from '../../components/Common/Page/styles';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';
import * as icons from '../../styles/icons';
import { requestLogin } from '../../store/modules/auth/actions';
import Button from '../../components/Common/Button';

export const Faq = ({ doLogin }) => {
    const location = useLocation();
    let defaultExpanded = [];
    if (location.hash) {
        defaultExpanded = location.hash.replace('#', '').split(',');
    }
    const [expanded, setExpanded] = useState(defaultExpanded);

    const faqs = {
        'how-to-play': {
            title: 'How do I play?',
            content: (
                <p>
                    <Styled.SubHeader>Login or Register</Styled.SubHeader>
                    Register for a free account or log in using your existing
                    Sky Bet details.
                    <Styled.SubHeader>Predict the scores</Styled.SubHeader>
                    Get all 6 scores correct to win the jackpot!
                </p>
            )
        },
        'does-it-cost': {
            title: 'Is it free to play?',
            content: <p>YES! Super 6 is completely FREE to play!</p>
        },
        'how-to-register': {
            title: 'How do I register?',
            content: (
                <p>
                    Creating a Super 6 account is free and easy! Just click{' '}
                    <Link onClick={doLogin} to="/faq">
                        Login / Register
                    </Link>
                    , enter your details, and choose a Username and PIN to
                    register. If you already have a Sky Bet or ITV7 account, you
                    can use your existing credentials for Super 6 for a quicker
                    and easier way to play.
                </p>
            )
        },
        'how-to-play-head-to-head': {
            title: 'How do I play Head to Head?',
            content: (
                <div>
                    <p>
                        Each round you&apos;ll go head-to-head against one of
                        our pundits! Beat their score and you&apos;ll be entered
                        into a prize draw. Check back after the round to see if
                        you&apos;re a winner!
                    </p>
                </div>
            )
        },
        'can-use-skybet': {
            title: "It says I'm already registered.",
            content: (
                <p>
                    Super 6 uses the same account platform as{' '}
                    <Styled.BoldText>Sky Bet and ITV7</Styled.BoldText>. With
                    this in mind, if you are informed that you already have an
                    account with us, please log in with your existing account
                    details instead.
                </p>
            )
        },
        'multiple-entries': {
            title: 'Can I have more than one entry?',
            content: (
                <p>
                    Only one entry per round is allowed per person. If the
                    Promoter has reasonable grounds to believe that multiple
                    accounts have been opened by or are under the control of the
                    same person, all such accounts shall be closed and all
                    entries by such player will be disqualified.
                </p>
            )
        },
        prizes: {
            title: 'What can I win?',
            content: (
                <div>
                    <p>
                        <Styled.SubHeader>
                            The Super 6 Jackpot:{' '}
                        </Styled.SubHeader>
                        Predict 6 correct scores from the 6 fixtures to win the
                        Jackpot.
                    </p>
                    <p>
                        <Styled.SubHeader>Top Scorer: </Styled.SubHeader>
                        Win a guaranteed cash prize each round for finishing at
                        the top of the Super 6 leaderboard.
                    </p>
                    <p>
                        <Styled.SubHeader>Head to Head: </Styled.SubHeader>
                        Beat our pundit for a given round and you&apos;ll be
                        entered into a prize draw to win £1,000! Available every
                        round.
                    </p>
                    <p>
                        <Styled.SubHeader>The 100 Club: </Styled.SubHeader>
                        Predict a correct score that 100 players or less get
                        correct and you will become a member of our exclusive
                        100 Club. Members of the 100 Club will be rewarded with
                        exclusive Super 6 prizes and ultimate bragging rights!
                        This prize is available for every fixture in every
                        round.
                    </p>
                    <p>
                        <Styled.SubHeader>
                            Season Leaderboard:{' '}
                        </Styled.SubHeader>
                        Finish on top of the Super 6 leaderboard at the end of
                        the season and you&apos;ll take home £10,000 Cash!
                    </p>
                    <p>
                        <Styled.SubHeader>
                            Official Club Leagues:{' '}
                        </Styled.SubHeader>
                        Finish top of your{' '}
                        <Link to="/club-leagues">official club league</Link>,
                        and you&apos;ll win your club&apos;s shirt. And if you
                        win your league and your team makes the Championship,
                        League One, or League Two play-off final, you&apos;ll
                        also win tickets to the game!
                    </p>
                    <p>
                        *All prizes are subject to <Link to="/terms">T&Cs</Link>
                    </p>
                </div>
            )
        },
        'submit-deadline': {
            title: 'When do I have to submit my predictions?',
            content: (
                <p>
                    Predictions must be submitted before the first fixture of a
                    given round kicks off.
                </p>
            )
        },
        'update-selected': {
            title: 'Can I change my selections?',
            content: (
                <p>
                    Once you have submitted your predictions, you can make
                    unlimited changes until the first fixture of a given round
                    kicks off.
                </p>
            )
        },
        scoring: {
            title: 'How do I score points?',
            content: (
                <div>
                    <p>
                        You score points by predicting the correct results and
                        scores for the 6 fixtures within a round. A maximum of 5
                        points can be achieved for each fixture within a round.
                        All predictions are settled at Full-time (including
                        stoppage time). Extra time and penalty shoot-outs do not
                        count.
                    </p>
                    <p>Points are awarded for the following:</p>
                    <Styled.UnorderedList>
                        <Styled.ListItem>
                            Correct result = 2 points
                        </Styled.ListItem>
                        <Styled.ListItem>
                            Correct score = 5 points
                        </Styled.ListItem>
                    </Styled.UnorderedList>
                </div>
            )
        },
        'golden-goal': {
            title: 'What is the Golden Goal?',
            content: (
                <div>
                    <p>
                        The golden goal is a prediction for the minute that the
                        first goal will be scored across the 6 fixtures within a
                        given round and is used as a tiebreaker if two or more
                        players have the same number of points. The player who
                        has correctly predicted (or is closest to) the golden
                        goal will be placed higher when tied on points.
                    </p>
                    <p>Example:</p>
                    <Styled.UnorderedList>
                        <Styled.ListItem>
                            0 - 59 seconds = 1st minute
                        </Styled.ListItem>
                        <Styled.ListItem>
                            1:00 - 1:59 = 2nd minute point
                        </Styled.ListItem>
                        <Styled.ListItem>
                            44:00-45:00 and first-half stoppage time = 45
                            minutes
                        </Styled.ListItem>
                        <Styled.ListItem>
                            89:00-90:00 and second-half stoppage time = 90
                            minutes
                        </Styled.ListItem>
                    </Styled.UnorderedList>
                    <p>
                        If you predict the 6th minute, you will need the first
                        goal to be scored between 5m00s - 5m59s.
                    </p>
                </div>
            )
        },
        'points-weighting': {
            title: 'What if I have scored the same points as another player?',
            content: (
                <>
                    <p>
                        If you and another player have the same points,
                        we&apos;ll use tiebreakers to determine who ranks
                        higher.
                    </p>
                    <p>
                        First, we&apos;ll check who predicted the most correct
                        scores. The player with the more correct score
                        predictions will be ranked higher. If you and another
                        player have accumulated the same number of correct
                        scores, we&apos;ll use your golden goal prediction as a
                        tiebreaker. The person with the closest golden goal
                        prediction will be ranked higher.
                    </p>
                    <p>
                        The same tiebreakers apply over both monthly and season
                        rankings as well. As these rankings include multiple
                        rounds, we collate your golden goal predictions from all
                        rounds within the given period. For example, if your
                        golden goal prediction is 5 minutes outside the correct
                        time, you&apos;d receive a score of 5. Therefore, the
                        player with the lowest golden goal score over the given
                        rounds will be ranked higher.
                    </p>
                </>
            )
        },
        results: {
            title: 'How do I find out my score?',
            content: (
                <p>
                    Head to the leaderboard and use the round, month, and season
                    filters to see how you did! You can also play with friends
                    by creating or joining a league!
                </p>
            )
        },
        'change-details': {
            title: 'How do I change my details?',
            content: (
                <p>
                    Log in and go to the My Account tab. Within this section,
                    you can manage your account and update your details, contact
                    preferences, and PIN.
                </p>
            )
        },
        postponed: {
            title: 'What happens when a fixture is postponed?',
            content: (
                <p>
                    If any fixture is postponed, abandoned, or not completed
                    (i.e. less than 90 minutes played) the fixture in question
                    will be considered void. If only five fixtures are
                    completed, the jackpot will be reduced accordingly. If only
                    four fixtures are completed the jackpot will be reduced
                    further. If three or fewer fixtures are completed in the
                    round, the Jackpot Competition will be void for that given
                    round. T&C&apos;s Apply.
                </p>
            )
        },
        'terms-and-conditions': {
            title: 'Terms & Conditions',
            content: (
                <p>
                    All T&Cs can be seen <Link to="/terms">here</Link>.
                </p>
            )
        },
        contact: {
            title: 'How can I contact you?',
            content: (
                <p>
                    Contact us using any of the following methods.
                    <Button
                        icon="/content/img/logos/X.png"
                        textColour="black"
                        link="https://x.com/super6"
                        isSocialButton
                    >
                        X (Formerly Twitter)
                    </Button>
                    <Button
                        icon="/content/img/logos/facebook-02.png"
                        textColour="black"
                        link="https://www.facebook.com/SUPER6"
                        isSocialButton
                    >
                        Facebook
                    </Button>
                    <Button
                        icon="/content/img/logos/Instagram.png"
                        textColour="black"
                        link="https://www.instagram.com/super6"
                        isSocialButton
                    >
                        Instagram
                    </Button>
                    <Button
                        icon="/content/img/logos/Mail.png"
                        textColour="black"
                        link="mailto:super6@skybet.com?subject=Super%206%20customer%20support"
                        isSocialButton
                    >
                        Email: Super6@skybet.com
                    </Button>
                </p>
            )
        }
    };

    const toggle = key => () => {
        if (expanded.includes(key)) {
            const newExpanded = expanded.slice();
            newExpanded.splice(expanded.indexOf(key), 1);
            setExpanded(newExpanded);
        } else {
            setExpanded([...expanded, key]);
        }
    };

    return (
        <ContentPageContainer>
            <PageTitle>FAQs</PageTitle>
            <Styled.List id="faq-content" data-test-id="page-content-faq">
                {Object.keys(faqs).map(key => (
                    <Styled.ListItem key={key} id={key}>
                        <Styled.ToggleButton onClick={toggle(key)}>
                            {expanded.includes(key) && (
                                <Icon data-char={icons.UpChevron} />
                            )}
                            {!expanded.includes(key) && (
                                <Icon data-char={icons.DownChevron} />
                            )}
                            <h2>{faqs[key].title}</h2>
                        </Styled.ToggleButton>
                        {expanded.includes(key) && (
                            <Styled.Content>{faqs[key].content}</Styled.Content>
                        )}
                    </Styled.ListItem>
                ))}
            </Styled.List>
        </ContentPageContainer>
    );
};

Faq.propTypes = {
    doLogin: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    doLogin: () => dispatch(requestLogin())
});

export default connect(null, mapDispatchToProps)(Faq);
