import React, { useEffect, useRef, useState } from 'react';
import { parse } from 'querystring';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PageContainer } from '../../../../components/Common/Page/styles';
import ConnectedRoundFixtures from '../RoundFixtures/RoundFixtures';
import { postPredictions } from '../../../../store/modules/play/actions';
import Button from '../../../../components/Common/Button';
import ConnectedGoldenGoalPrediction from '../GoldenGoal/GoldenGoalPrediction';
import ConnectedSharePredictions from '../SharePredictions';
import * as Styled from '../../styles';
import ConnectedAcca from '../Acca';
import {
    selectActiveRoundId,
    selectHasLoadedAcca,
    selectActiveRoundScoreChallenges
} from '../../../../store/modules/round/selectors';
import { selectIsLoggedIn } from '../../../../store/modules/auth/selectors';
import ExtraTimeHero from '../../../Home/components/ExtraTimeHero';
import { isFeatureOn } from '../../../../store/modules/content/selectors';
import { ENABLE_PLAY_EXTRA_HERO_TILE } from '../../../../features';
import PredictionsSummary from './PredictionsSummary';
import ConnectedHeadToHeadBanner from '../../../../components/HeadToHeadBanner';
import {
    sendNavigationEvent,
    sendInterfaceEvent
} from '../../../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../../../helpers/GoogleAnalytics/modules';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';
import eventContext from '../../../../helpers/GoogleAnalytics/event_context';

const Played = ({
    isExtraHeroTileEnabled,
    canBet,
    isSubmitting,
    submitPredictions,
    isDesktop,
    hasLoadedAcca,
    predictions,
    fixtures,
    goldenGoalTime
}) => {
    const disabled = isSubmitting;
    const [errorState, setErrorState] = useState(false);
    const { search } = useLocation();
    const { submitted } = parse(search.replace('?', ''));
    const justSubmitted = submitted === '1';
    const hideInputs = submitted && !isDesktop;
    const shareRef = useRef(null);
    const isNativeShareAvailable = !!navigator.share;

    useEffect(() => {
        // shareRef.current can be null on newer webkit versions
        if (hasLoadedAcca && justSubmitted && shareRef && shareRef.current) {
            shareRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [justSubmitted, hasLoadedAcca]);

    const [editPredictions, setEditPredictions] = React.useState(false);

    const checkInputValue = () => {
        if (goldenGoalTime) {
            if (disabled) {
                return;
            }
            setErrorState(false);
            submitPredictions();
        } else {
            setErrorState(true);
        }
    };

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.playedFlow.editPredictions,
            1,
            text,
            position,
            destinationUrl,
            context
        );
    };
    const setInterfaceEvent = (text, action) => {
        sendInterfaceEvent(text, action, modules.playedFlow.editPredictions);
    };

    return (
        <PageContainer>
            {/* Predictions summary */}
            {!editPredictions && (
                <PredictionsSummary
                    onEditPredictions={() => setEditPredictions(true)}
                    predictions={predictions}
                    fixtures={fixtures}
                    isNativeShareAvailable={isNativeShareAvailable}
                />
            )}

            {/* Edit predictions form */}
            {editPredictions && (
                <>
                    <Styled.PageTitle>Edit Predictions</Styled.PageTitle>
                    <Styled.MainContent>
                        <Styled.RoundFixtures>
                            <ConnectedRoundFixtures
                                setNavigationEvent={setNavigationEvent}
                                setInterfaceEvent={setInterfaceEvent}
                            />
                        </Styled.RoundFixtures>
                        <Styled.ColumnContainer>
                            <ConnectedGoldenGoalPrediction
                                errorState={errorState}
                            />
                            <Styled.ButtonWrapperPlayed>
                                <Button
                                    onClick={() => {
                                        sendNavigationEvent(
                                            modules.playedFlow
                                                .submitPredictions,
                                            3,
                                            elementText.navigation
                                                .submitMyEntry,
                                            1,
                                            'https://super6.skysports.com/played?submitted=1',
                                            eventContext.entryAdditional
                                        );
                                        checkInputValue();
                                    }}
                                    disabled={disabled}
                                    fontSize="large"
                                    id="js-fixtures-submit-entry"
                                    data-test-id="predictions-submit-button"
                                >
                                    Submit my entry
                                </Button>
                            </Styled.ButtonWrapperPlayed>
                        </Styled.ColumnContainer>
                    </Styled.MainContent>
                </>
            )}

            {/* Share (desktop) */}
            {!editPredictions && !isNativeShareAvailable && (
                <Styled.RelativeContainer>
                    <Styled.ScrollAnchor ref={shareRef} />
                    <Styled.Row padTop={hideInputs}>
                        <Styled.FullWidthColumn>
                            <ConnectedSharePredictions
                                predictions={predictions}
                                fixtures={fixtures}
                            />
                        </Styled.FullWidthColumn>
                    </Styled.Row>
                </Styled.RelativeContainer>
            )}

            {/* Accumulator */}
            {canBet && (
                <Styled.RelativeContainer>
                    <Styled.Row>
                        <Styled.ScrollAnchor ref={shareRef} />
                        <Styled.FullWidthColumn>
                            <ConnectedAcca />
                        </Styled.FullWidthColumn>
                    </Styled.Row>
                </Styled.RelativeContainer>
            )}

            {/* S6 Extra hero (desktop) */}
            {isDesktop && isExtraHeroTileEnabled && (
                <Styled.Row>
                    <ExtraTimeHero />
                </Styled.Row>
            )}

            {/* S6 Extra hero (mobile) */}
            {!isDesktop && isExtraHeroTileEnabled && (
                <Styled.Row>
                    <Styled.ExtraTimeHeroContainer>
                        <ExtraTimeHero />
                    </Styled.ExtraTimeHeroContainer>
                </Styled.Row>
            )}

            <Styled.Row>
                <Styled.MiddleColumns>
                    <ConnectedHeadToHeadBanner />
                </Styled.MiddleColumns>
            </Styled.Row>
        </PageContainer>
    );
};

Played.propTypes = {
    isExtraHeroTileEnabled: PropTypes.bool.isRequired,
    canBet: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    submitPredictions: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    hasLoadedAcca: PropTypes.bool.isRequired,
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired,
    goldenGoalTime: PropTypes.number.isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ).isRequired
};

const mapStateToProps = state => ({
    isExtraHeroTileEnabled: isFeatureOn(
        state,
        ENABLE_PLAY_EXTRA_HERO_TILE,
        false
    ),
    isLoggedIn: selectIsLoggedIn(state),
    canBet: !!state.user.info.canBet,
    roundId: selectActiveRoundId(state),
    isDesktop: state.user.isOnDesktop,
    isSubmitting: state.play.isSubmitting,
    hasLoadedAcca: selectHasLoadedAcca(state),
    fixtures: selectActiveRoundScoreChallenges(state),
    predictions: state.play.predictions,
    goldenGoalTime: state.play.goldenGoal.time
});

const mapDispatchToProps = dispatch => ({
    submitPredictions: () => dispatch(postPredictions())
});

export default connect(mapStateToProps, mapDispatchToProps)(Played);
