/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getPeriodTitle } from '../../helpers/periodHelper';
import * as Styled from '../../containers/PeriodIdNavigation/styles';

const PeriodIdSelector = ({
    period,
    periodId,
    activeIds,
    template,
    history,
    SelectWrapper,
    HeaderWrapper,
    seasonName
}) => {
    const refSelect = useRef();
    const refSelectedItem = useRef();
    const [selectOpen, setSelectOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (
                selectOpen &&
                refSelect.current &&
                !refSelect.current.contains(e.target)
            ) {
                setSelectOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        if (selectOpen && refSelectedItem.current) {
            refSelectedItem.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [selectOpen]);

    const handleChange = e =>
        history.push(template.replace(':id', e.target.value));

    if (activeIds) {
        return (
            <SelectWrapper
                onClick={() => setSelectOpen(() => !selectOpen)}
                ref={refSelect}
                data-test-id="leaderboard-select-wrapper"
            >
                {getPeriodTitle(period, periodId)}
                {selectOpen && (
                    <Styled.Select
                        className={`leaderboard-select-${period}`}
                        data-test-id={`leaderboard-select-${period}`}
                    >
                        {activeIds
                            .slice()
                            .reverse()
                            .map(id => (
                                <Styled.SelectOption
                                    key={id}
                                    value={id}
                                    ref={
                                        Number(periodId) === id
                                            ? refSelectedItem
                                            : null
                                    }
                                    data-test-id={`select-option-${id}`}
                                    onClick={handleChange}
                                >
                                    <Styled.SelectOptionIcon
                                        className={
                                            Number(periodId) === id
                                                ? 'selected'
                                                : ''
                                        }
                                    />
                                    {getPeriodTitle(period, id)}
                                </Styled.SelectOption>
                            ))}
                    </Styled.Select>
                )}
            </SelectWrapper>
        );
    }
    return seasonName ? (
        <HeaderWrapper data-test-id="leaderboard-season-title">
            {getPeriodTitle(period, seasonName)}
        </HeaderWrapper>
    ) : null;
};

PeriodIdSelector.propTypes = {
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    activeIds: PropTypes.arrayOf(PropTypes.number),
    template: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    SelectWrapper: PropTypes.shape({
        displayName: PropTypes.oneOf(['SelectWrapper'])
    }).isRequired,
    HeaderWrapper: PropTypes.shape({
        displayName: PropTypes.oneOf(['PeriodId'])
    }),
    seasonName: PropTypes.string
};

PeriodIdSelector.defaultProps = {
    periodId: null,
    activeIds: null,
    template: null,
    seasonName: '',
    HeaderWrapper: null
};

export default PeriodIdSelector;
