import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { Cross } from '../../styles/icons';

const StreaksAlert = ({
    onClickCancel,
    streaksTextLeftBold,
    streaksTextRight,
    linkTo
}) => (
    <Styled.StreaksOuterContainer data-test-id="nav-streaks-alert-container">
        <Styled.StreaksPointer>
            <Styled.StreaksInnerContainer>
                <Styled.CloseButton
                    aria-label="dismiss streaks alert"
                    onClick={onClickCancel}
                >
                    <Styled.Icon data-char={Cross} />
                </Styled.CloseButton>
                <Styled.StreaksTextContainer to={linkTo}>
                    <Styled.TextLeftBold>
                        {streaksTextLeftBold}
                    </Styled.TextLeftBold>
                    {(streaksTextLeftBold && streaksTextRight && (
                        <Styled.TextRight>
                            &nbsp;{streaksTextRight}
                        </Styled.TextRight>
                    )) || (
                        <Styled.TextRight>{streaksTextRight}</Styled.TextRight>
                    )}
                </Styled.StreaksTextContainer>
            </Styled.StreaksInnerContainer>
        </Styled.StreaksPointer>
    </Styled.StreaksOuterContainer>
);

StreaksAlert.propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    streaksTextLeftBold: PropTypes.string,
    streaksTextRight: PropTypes.string,
    linkTo: PropTypes.string
};

StreaksAlert.defaultProps = {
    streaksTextLeftBold: null,
    streaksTextRight: null,
    linkTo: '#'
};

// eslint-disable-next-line import/no-unused-modules
export default StreaksAlert;
