import React from 'react';
import PropTypes from 'prop-types';
import Teams from '../../../../../../components/Teams';
import ValidateMatchIndex from '../../../../../../components/Teams/validation/validateMatchIndex';
import ValidatePosition from '../../../../../../components/Teams/validation/validatePosition';
import * as Styled from './styles';
import { sendNavigationEvent } from '../../../../../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../../../../../helpers/GoogleAnalytics/modules';

const HeroTile = ({
    heroImagePaths,
    prizeImagePaths,
    logoPath,
    link,
    variant,
    predictionClosedDate,
    buttonText,
    roundId,
    fixtures
}) => {
    const getLinkButton = () => {
        if (variant === 'extra') {
            return (
                <Styled.HeroAppButton
                    app="super6extra"
                    link={link}
                    target="_blank"
                    disabled={link === null}
                    rel="noreferrer noopener"
                    targetId={`s6-${variant}-cta-play-btn`}
                    testId={`s6-${variant}-cta-play-btn-${roundId}`}
                >
                    {buttonText}
                </Styled.HeroAppButton>
            );
        }
        return (
            <div>
                <Styled.HeroButton
                    data-target-id={`s6-${variant}-cta-play-btn-${roundId}`}
                    link={link}
                    disabled={link === null}
                    variant={variant}
                    internal
                    sendNavEvent={() =>
                        sendNavigationEvent(
                            modules.homepage.playForFree,
                            1,
                            buttonText,
                            1,
                            `https://super6.skysports.com${link}`
                        )
                    }
                >
                    {buttonText}
                </Styled.HeroButton>
            </div>
        );
    };

    const getAltText = () => {
        if (variant === 'extra') {
            return 'Play Super 6 Extra for free';
        }
        return 'Play Super 6 for free';
    };

    return (
        <Styled.Container
            data-test-id={`s6-${variant}-hero-container-${roundId}`}
        >
            <Styled.Card>
                {variant === 'default' && (
                    <Styled.TeamsRow>
                        {fixtures.slice(0, 3).map(fixture => (
                            <Teams
                                key={fixture.matchIndex}
                                matchIndex={fixture.matchIndex}
                                borderRadius={fixture.borderRadius}
                                position={fixture.position}
                                homeTeam={fixture.homeTeam}
                                awayTeam={fixture.awayTeam}
                            />
                        ))}
                    </Styled.TeamsRow>
                )}
                <Styled.HeroImage>
                    <source
                        media="(max-width: 720px)"
                        srcSet={`
                            ${heroImagePaths.mobile['3x']} 3x,
                            ${heroImagePaths.mobile['2x']} 2x,
                            ${heroImagePaths.mobile['1x']} 1x
                        `}
                    />
                    <source
                        srcSet={`
                            ${heroImagePaths.desktop['3x']} 3x,
                            ${heroImagePaths.desktop['2x']} 2x,
                            ${heroImagePaths.desktop['1x']} 1x
                        `}
                    />
                    <img
                        src={heroImagePaths.desktop['2x']}
                        alt={getAltText()}
                    />
                </Styled.HeroImage>
                <Styled.LogoPrizeContainer
                    variant={variant}
                    buttonText={buttonText}
                >
                    {variant === 'extra' && (
                        <Styled.LogoImage>
                            <img src={logoPath} alt="Logo" />
                        </Styled.LogoImage>
                    )}
                    <Styled.PrizeImage variant={variant}>
                        <source
                            media="(max-width: 720px)"
                            srcSet={`
                                ${prizeImagePaths.mobile['3x']} 3x
                                ${prizeImagePaths.mobile['2x']} 2x
                                ${prizeImagePaths.mobile['1x']} 1x
                            `}
                        />
                        <source
                            srcSet={`
                                ${prizeImagePaths.desktop['3x']} 3x
                                ${prizeImagePaths.desktop['2x']} 2x
                                ${prizeImagePaths.desktop['1x']} 1x
                            `}
                        />
                        <img src={prizeImagePaths.desktop['2x']} alt="Prize" />
                    </Styled.PrizeImage>
                </Styled.LogoPrizeContainer>
                {variant === 'default' && (
                    <Styled.HeroButtonWrapper>
                        {getLinkButton()}
                    </Styled.HeroButtonWrapper>
                )}
                {variant === 'default' && (
                    <Styled.TeamsRow>
                        {fixtures.slice(3, 6).map(fixture => (
                            <Teams
                                key={fixture.matchIndex}
                                matchIndex={fixture.matchIndex}
                                borderRadius={fixture.borderRadius}
                                position={fixture.position}
                                homeTeam={fixture.homeTeam}
                                awayTeam={fixture.awayTeam}
                            />
                        ))}
                    </Styled.TeamsRow>
                )}
            </Styled.Card>
            {variant === 'extra' && getLinkButton()}
            {variant === 'extra' && (
                <Styled.RoundInfoText>
                    {predictionClosedDate}
                </Styled.RoundInfoText>
            )}
        </Styled.Container>
    );
};

HeroTile.propTypes = {
    logoPath: PropTypes.string.isRequired,
    link: PropTypes.string,
    heroImagePaths: PropTypes.shape({
        desktop: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }).isRequired,
        mobile: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }).isRequired
    }).isRequired,
    prizeImagePaths: PropTypes.shape({
        desktop: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }).isRequired,
        mobile: PropTypes.shape({
            '1x': PropTypes.string,
            '2x': PropTypes.string,
            '3x': PropTypes.string
        }).isRequired
    }).isRequired,
    variant: PropTypes.string.isRequired,
    predictionClosedDate: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            matchIndex: ValidateMatchIndex,
            borderRadius: PropTypes.shape({
                topLeft: PropTypes.string,
                topRight: PropTypes.string,
                bottomRight: PropTypes.string,
                bottomLeft: PropTypes.string
            }),
            position: ValidatePosition,
            homeTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired,
            awayTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired
};

HeroTile.defaultProps = {
    link: null,
    roundId: 'loading'
};

export default HeroTile;
