import * as oauth from './oauth';
import * as sso from './sso';
import { getAppVersion, getApp } from '../helpers/appBridge';
import { isGreaterThanOrEqual } from '../helpers/versionHelper';

export const isOauthEnabled = () => {
    const app = getApp();
    const version = getAppVersion();

    if (!app || !version) {
        return true;
    }

    return app === 'ios' && isGreaterThanOrEqual(version, '3');
};

let authService;
export const getAuthService = () => {
    if (!authService) {
        if (isOauthEnabled()) {
            authService = oauth;
        } else {
            authService = sso;
        }
    }
    return authService;
};
