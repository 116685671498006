import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const RoundResultCard = styled.div`
    justify-content: center;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.radius.normal};
    width: 100%;
`;

export const RoundResultContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    padding-bottom: ${props =>
        `clamp(${props.theme.spacing.large}, 4vw, ${props.theme.spacing.xlarge})`};
    padding-left: ${props =>
        `clamp(${props.theme.spacing.large}, 3.5vw, ${props.theme.spacing.xxlarge})`};
    padding-right: ${props =>
        `clamp(${props.theme.spacing.large}, 3.5vw, ${props.theme.spacing.xxlarge})`};
    align-items: center;
    justify-content: stretch;
`;

export const TotalPointsText = styled.p`
    line-height: ${props => props.theme.lineHeight.large};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.bold};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.normal}, 2.5vw, ${props.theme.fontSize.large2})`};
`;

export const Points = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => `clamp(${props.theme.fontSize.large}, 7vw, 32px)`};
    height: ${props => `clamp(${props.theme.fontSize.large}, 7vw, 32px)`};
    line-height: ${props => `clamp(${props.theme.fontSize.large}, 7vw, 32px)`};
    border-radius: 50%;
    font-family: ${props => props.theme.fonts.bold};
    background-color: ${props =>
        props.total
            ? props.theme.colours.greenPrimary
            : props.theme.colours.colorOnPrimary};
    color: ${props =>
        props.total
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.medium}, 3.5vw, ${props.theme.fontSize.xlarge})`};
    text-align: center;
`;

export const TotalPointsSection = styled.div`
    margin-top: ${props => props.theme.spacing.small};
    margin-bottom: ${props => props.theme.spacing.small};
    border-top: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    border-bottom: ${props =>
        `${props.theme.spacing.xsmall} solid ${props.theme.colours.brightGray}`};
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
`;

export const FlexItem = styled.div`
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
`;

export const CardHeader = styled.div`
    justify-content: center;
    justify-items: center;
    justify-self: center;
    display: flex;
    padding-top: ${props =>
        `clamp(${props.theme.fontSize.small}, 2vw, ${props.theme.fontSize.xlarge2})`};
    padding-bottom: ${props =>
        `clamp(${props.theme.fontSize.small}, 2vw, ${props.theme.fontSize.xlarge2})`};
`;

export const HeaderText = styled.h1`
    font-size: ${props =>
        `clamp(${props.theme.fontSize.normal}, 3.5vw, ${props.theme.fontSize.large2})`};
    font-family: ${props => props.theme.fonts.skySportsD};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-weight: 700;
`;

export const BodyText = styled.div`
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 3.5vw, ${props.theme.fontSize.large2})`};
    font-family: ${props =>
        props.bold ? props.theme.fonts.bold : props.theme.fonts.normal};
    font-weight: ${props => props.semibold && '600'};
    line-height: ${props => props.theme.lineHeight.large};
    color: ${props => props.theme.colours.colorOnPrimary};
`;

export const Button = styled.div`
    background-color: ${props => props.theme.colours.greenPrimary};
    border-radius: ${props => props.theme.spacing.xlarge2};
    border-style: none;
`;

export const ButtonText = styled.h6`
    padding: ${props => props.theme.spacing.medium};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 2.5vw, ${props.theme.fontSize.large})`};
    color: ${props => props.theme.colours.colorOnPrimary};
    font-family: ${props => props.theme.fonts.bold};
    white-space: nowrap;

    @media (min-width: ${props => props.theme.widths.smallMobile}) {
        padding: ${props => props.theme.spacing.medium}
            ${props => props.theme.spacing.vlarge};
    }
`;

export const ButtonWrapper = styled(Link, {
    shouldForwardProp: prop => isPropValid(prop)
})`
    text-decoration: none;
`;
