import * as types from './types';

export const requestSuper6ExtraActiveRound = () => ({
    type: types.REQUEST_SUPER6EXTRA_ACTIVE_ROUND
});

export const receiveSuper6ExtraActiveRound = activeRound => ({
    type: types.RECEIVE_SUPER6EXTRA_ACTIVE_ROUND,
    payload: activeRound
});
