/* eslint-disable indent */
import styled from '@emotion/styled/macro';
import Image from '../Common/Image';

export const TeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    margin: ${props => props.theme.spacing.normal};
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: ${props => props.mobWidth || '30%'};
    }
`;

export const TeamName = styled.div`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.normal};
    text-transform: ${props =>
        props.uppercase === true ? 'uppercase' : 'none'};
    font-family: ${props => props.theme.fonts.skySportsPL};
    color: ${props => props.theme.team.teamNameColour};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props =>
            props.size === 'l'
                ? props.theme.fontSize.medium
                : props.theme.fontSize.small};
    }
`;

export const TeamImage = styled(Image)`
    margin: 0 auto;
    max-width: ${props => props.theme.team.imageSizes.s};
    margin-bottom: ${props => props.theme.spacing.normal};
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        max-width: ${props => props.theme.team.imageSizes.default};
    }
`;
