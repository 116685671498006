import styled from '@emotion/styled/macro';

export const PaddedContainer = styled.div`
    padding: ${props => props.theme.spacing.medium};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => props.theme.spacing.medium} 0 0;
    }
`;

export const PanelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: ${props =>
        props.padVertical ? `${props.theme.spacing.medium} 0` : 0};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-direction: row;
    }
`;

export const Panel = styled.div`
    flex: 0 0 auto;
    margin-bottom: ${props => props.theme.spacing.normal};
    box-sizing: border-box;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin-bottom: ${props => props.theme.spacing.medium};
        width: 48%;
    }
`;

export const SkeletonHeader = styled.div`
    display: block;
    width: 100%;
    text-align: center;
    padding: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colours.header.textColor};
`;

export const RoundHeaderBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BackButtonContainerWrapper = styled.div`
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: 0 -2px ${props => props.theme.spacing.medium} 0;
    }
    margin: 0 ${props => props.theme.spacing.normal}
        ${props => props.theme.spacing.medium};
`;

export const FixturesContainer = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing.medium};
    width: 100%;

    @media (min-width: ${props => props.theme.widths.containerMaxWidth}) {
        grid-template-columns: 1fr 1fr;
    }
`;
