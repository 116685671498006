import PropTypes from 'prop-types';

export const teamProp = PropTypes.shape({
    badgeUri: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
});

export const matchOutcomeProp = PropTypes.shape({
    home: PropTypes.shape({
        teamId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        badgeUri: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired
    }),
    away: PropTypes.shape({
        teamId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        badgeUri: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired
    }),
    outcome: PropTypes.oneOf(['w', 'l', 'd']).isRequired,
    matchDate: PropTypes.string.isRequired
});

export const matchHistoryProp = PropTypes.arrayOf(matchOutcomeProp);
