import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Skeleton = ({ height, width }) => (
    <Styled.Container height={height} width={width}>
        <Styled.Shimmer />
    </Styled.Container>
);

Skeleton.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
};

Skeleton.defaultProps = {
    height: null,
    width: null
};

export default Skeleton;
