import styled from '@emotion/styled/macro';

export const MatchContainer = styled.div`
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    width: 80px;
    margin: 0 0 -6px 0;
    box-sizing: border-box;
`;

export const Match = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const PredictionAndScoreContainer = styled.div`
    flex: 33% 1 1;
    font-size: ${props => props.theme.fontSize.xsmall};
`;

export const ScoreTitle = styled.div`
    font-family: ${props => props.theme.fonts.normal};
    font-size: ${props => props.theme.fontSize.xxsmall};
    color: ${props => props.theme.colours.blueDark};
    text-align: center;
    margin: 0 0 6px 0;
`;
