import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { getNavItems } from './config';
import MoreNav from './MoreNav';
import PartRegAd from '../PartRegAd';
import { requestActiveRound } from '../../../../store/modules/round/actions';
import { selectActiveRoundStatus } from '../../../../store/modules/round/selectors';
import { isFeatureOn } from '../../../../store/modules/content/selectors';
import {
    SEASON_CLOSED,
    ENABLE_NAV_PART_REG_AD,
    ENABLE_NAV_EXTRA,
    ENABLE_MY_PERFORMANCE_PAGE,
    ENABLE_HEAD_TO_HEAD
} from '../../../../features';
import NavSectionList from './NavSectionList';

const Nav = ({
    loadActiveRound,
    shouldShowPlayNow,
    mobileNavOpen,
    closeMobileNav,
    fullyRegistered,
    canBet,
    isOnDesktop,
    isSeasonClosed,
    enableNavPartRegAd,
    enableNavExtra,
    performanceHubEnabled,
    h2hEnabled,
    setNavigationEvent,
    setInterfaceEvent
}) => {
    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    const navItems = getNavItems({
        showLive: !shouldShowPlayNow && !isSeasonClosed,
        showPlay: shouldShowPlayNow && !isSeasonClosed,
        isOnDesktop,
        showExtra: enableNavExtra,
        showPerformanceHub: performanceHubEnabled,
        showHeadToHead: h2hEnabled
    })
        .filter(item => item.show)
        .filter(
            item => item.isBetContent === false || (item.isBetContent && canBet)
        );

    return (
        <Styled.NavContainer
            data-test-id="nav-container"
            mobileNavOpen={mobileNavOpen}
        >
            <div>
                {/* Main navbar links */}
                <NavSectionList
                    closeMobileNav={closeMobileNav}
                    navItems={navItems.filter(item => item.section !== 'More')}
                    setNavigationEvent={setNavigationEvent}
                    setInterfaceEvent={setInterfaceEvent}
                    startingIndex={1}
                />

                {/* Mobile specific nav items - START */}
                {!fullyRegistered &&
                    canBet &&
                    !isOnDesktop &&
                    enableNavPartRegAd && <PartRegAd />}

                {/* Mobile "More" section */}
                {!isOnDesktop && (
                    <NavSectionList
                        closeMobileNav={closeMobileNav}
                        setNavigationEvent={setNavigationEvent}
                        navItems={navItems.filter(
                            item => item.section === 'More'
                        )}
                        startingIndex={
                            navItems.filter(item => item.section !== 'More')
                                .length + 1
                        }
                    />
                )}
                {/* Mobile specific nav items - END */}

                {/* Desktop "More" section */}
                {isOnDesktop && (
                    <MoreNav
                        closeMobileNav={closeMobileNav}
                        navItems={navItems.filter(
                            item => item.section === 'More'
                        )}
                        setNavigationEvent={setNavigationEvent}
                        startingIndex={
                            navItems.filter(item => item.section !== 'More')
                                .length + 1
                        }
                    />
                )}
            </div>
        </Styled.NavContainer>
    );
};

Nav.defaultProps = {
    performanceHubEnabled: false,
    h2hEnabled: true,
    setNavigationEvent: () => {},
    setInterfaceEvent: () => {}
};

Nav.propTypes = {
    mobileNavOpen: PropTypes.bool.isRequired,
    closeMobileNav: PropTypes.func.isRequired,
    fullyRegistered: PropTypes.bool.isRequired,
    canBet: PropTypes.bool.isRequired,
    isOnDesktop: PropTypes.bool.isRequired,
    shouldShowPlayNow: PropTypes.bool.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    isSeasonClosed: PropTypes.bool.isRequired,
    enableNavPartRegAd: PropTypes.bool.isRequired,
    enableNavExtra: PropTypes.bool.isRequired,
    performanceHubEnabled: PropTypes.bool,
    h2hEnabled: PropTypes.bool,
    setNavigationEvent: PropTypes.any,
    setInterfaceEvent: PropTypes.any
};

const mapStateToProps = state => ({
    fullyRegistered: !!state.user.info.fullyRegistered,
    canBet: !!state.user.info.canBet,
    shouldShowPlayNow: [null, 'open'].includes(selectActiveRoundStatus(state)),
    isOnDesktop: state.user.isOnDesktop,
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false),
    enableNavPartRegAd: isFeatureOn(state, ENABLE_NAV_PART_REG_AD, false),
    enableNavExtra: isFeatureOn(state, ENABLE_NAV_EXTRA, false),
    performanceHubEnabled: isFeatureOn(
        state,
        ENABLE_MY_PERFORMANCE_PAGE,
        false
    ),
    h2hEnabled: isFeatureOn(state, ENABLE_HEAD_TO_HEAD, true)
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound())
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
