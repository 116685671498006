const actions = {
    banner: {
        banner: 'clicked - banner',
        image: 'clicked - banner image',
        CTA: 'clicked - banner cta',
        text: 'clicked - banner text'
    },
    clicked: 'clicked',
    interface: {
        opened: 'opened',
        closed: 'closed',
        removed: 'removed'
    }
};

export default actions;
