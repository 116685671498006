import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrizesMastheadContent from './PrizesMastheadContent';
import MastheadFooter from '../MastheadFooter';
import * as Styled from '../mastheadStyles';
import { selectRoundStatusById } from '../../../../../store/modules/round/selectors';
import { requestRounds } from '../../../../../store/modules/round/actions';

const PrizesMasthead = ({
    loadRounds,
    roundStatus,
    punditPredictions,
    beatPunditPercentage,
    opponent,
    didUserWin,
    prizes
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    if (!punditPredictions) {
        return null;
    }

    return (
        <Styled.Container bgBlue={didUserWin} data-test-id="prizes-masthead">
            <Styled.ContentContainer>
                <PrizesMastheadContent
                    isComplete={roundStatus === 'complete'}
                    hasWon={didUserWin}
                    beatPunditPercentage={beatPunditPercentage}
                    opponentFirstName={opponent.firstName}
                    opponentSurname={opponent.lastName}
                    opponentPoints={
                        punditPredictions.totalPoints
                            ? punditPredictions.totalPoints.points
                            : 0
                    }
                />
                <Styled.Image
                    opponentImage={opponent.winnerImage}
                    data-test-id="masthead-image"
                />
            </Styled.ContentContainer>
            <MastheadFooter
                hasWon={Boolean(didUserWin)}
                quantity={prizes.quantity}
                value={prizes.value}
            />
        </Styled.Container>
    );
};

PrizesMasthead.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    roundStatus: PropTypes.string.isRequired,
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    opponent: PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileImage: PropTypes.string,
        winnerImage: PropTypes.string,
        loserImage: PropTypes.string
    }).isRequired,
    beatPunditPercentage: PropTypes.number,
    didUserWin: PropTypes.bool.isRequired,
    prizes: PropTypes.shape({
        quantity: PropTypes.number,
        value: PropTypes.string
    }).isRequired
};

PrizesMasthead.defaultProps = {
    punditPredictions: null,
    beatPunditPercentage: 0
};

const mapStateToProps = (state, props) => ({
    roundStatus: selectRoundStatusById(state, props.roundId)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrizesMasthead);
