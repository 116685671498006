import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './style';
import ConnectedMatchWithScores from '../../../components/MatchWithScores';
import { selectActiveRoundScoreChallenges } from '../../../store/modules/round/selectors';
import { requestActiveRound } from '../../../store/modules/round/actions';
import {
    getFormattedDate,
    getFormattedTime
} from '../../../helpers/dateFormatters';

const RoundFixtures = ({
    loadActiveRound,
    liveOdds,
    fixtures,
    predictions,
    userId
}) => {
    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);
    if (!fixtures) {
        return null;
    }
    return (
        <>
            {fixtures.map((fixture, index) => {
                const prediction =
                    (predictions && predictions[fixture.match.id]) || {};
                const { status, kickOffDateTime } = fixture.match;
                const formattedDate = getFormattedDate(kickOffDateTime);
                const formattedTime = getFormattedTime(kickOffDateTime)
                    .split(':00')
                    .join('');

                const odds =
                    (liveOdds && liveOdds[parseInt(fixture.match.id, 10)]) ||
                    null;

                const isLive = [
                    'Kick Off',
                    'Half Time',
                    'Half Time Started',
                    'Second Half Started'
                ].includes(status);
                const isPreLive = status === 'Pre Live';
                const isAbandoned = ['Abandoned', 'Postponed'].includes(status);
                const panelTitleForStatus = {
                    'Kick Off': 'LATEST',
                    'Half Time': 'HALF TIME',
                    'Half Time Started': 'LATEST',
                    'Second Half Started': 'LATEST'
                };

                return (
                    <Styled.MatchResultCard>
                        <ConnectedMatchWithScores
                            key={fixture.id}
                            matchStatusText={{
                                red: isLive || isPreLive ? 'LIVE' : 'FULL TIME',
                                blue: panelTitleForStatus[status]
                            }}
                            competitionName={fixture.match.competitionName}
                            competitionUri={fixture.match.competitionImageUrl}
                            homeTeam={fixture.match.homeTeam}
                            awayTeam={fixture.match.awayTeam}
                            predictedHomeScore={prediction.scoreHome}
                            predictedAwayScore={prediction.scoreAway}
                            homeScore={fixture.match.homeTeam.score}
                            awayScore={fixture.match.awayTeam.score}
                            points={prediction.points}
                            isCorrectResult={prediction.isCorrectResult}
                            isCorrectScore={prediction.isCorrectScore}
                            isLive={isLive}
                            isPreLive={isPreLive}
                            isAbandoned={isAbandoned}
                            liveOdds={odds}
                            index={index}
                            isResulted={!isLive}
                            kickOffDate={formattedDate}
                            kickOffTime={formattedTime}
                            userId={userId}
                        />
                    </Styled.MatchResultCard>
                );
            })}
        </>
    );
};

RoundFixtures.defaultProps = {
    predictions: null,
    liveOdds: null,
    fixtures: null,
    userId: null
};

RoundFixtures.propTypes = {
    loadActiveRound: PropTypes.func.isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                competitionName: PropTypes.string,
                competitionImageUrl: PropTypes.string,
                kickOffDateTime: PropTypes.string,
                homeTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                })
            })
        })
    ),
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.string,
            scoreAway: PropTypes.string
        })
    }),
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    }),
    userId: PropTypes.string
};

const mapStateToProps = state => ({
    fixtures: selectActiveRoundScoreChallenges(state)
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundFixtures);
