import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResultMasthead from './components/Masthead/ResultMasthead';
import RoundFixtures from './components/RoundFixtures';
import Button from '../../components/Common/Button';
import { PageContainer } from '../../components/Common/Page/styles';
import * as Styled from './styles';
import {
    requestRoundPredictions,
    requestRoundPredictionsByUser
} from '../../store/modules/results/actions';
import {
    getRoundPredictions,
    getLoadingRoundPredictions,
    getRoundPredictionsByUser
} from '../../store/modules/results/selectors';
import { selectRoundStatusById } from '../../store/modules/round/selectors';

const PUNDIT_ID = 'head-to-head-opponent';

const ButtonVariant = ({ hasPredicted, roundStatus }) => {
    if (hasPredicted && roundStatus === 'complete') {
        return (
            <Button
                link="/headtohead/prizes"
                data-test-id="results-cta-view-prizes"
            >
                VIEW PRIZE WINNERS
            </Button>
        );
    }

    if (hasPredicted && roundStatus === 'open') {
        return (
            <Button link="/play" data-test-id="results-cta-edit-predictions">
                EDIT PREDICTIONS
            </Button>
        );
    }

    if (!hasPredicted && roundStatus === 'open') {
        return (
            <Button link="/play" data-test-id="results-cta-play">
                PLAY SUPER 6
            </Button>
        );
    }

    return null;
};

const Results = ({
    roundId,
    roundStatus,
    headToHead,
    predictions,
    getPredictions,
    punditPredictions,
    getPunditPredictions,
    arePredictionsLoading
}) => {
    useEffect(() => {
        if (!punditPredictions) {
            getPunditPredictions(roundId);
        }
    }, [roundId, punditPredictions, getPunditPredictions]);

    useEffect(() => {
        if (!predictions) {
            getPredictions();
        }
    }, [predictions, getPredictions]);

    if (!arePredictionsLoading) {
        return null;
    }

    const { hasPredicted } = predictions;

    return (
        <PageContainer padding="8px">
            <Styled.ReducedWidthDesktopContainer>
                <ResultMasthead
                    roundId={roundId}
                    punditPredictions={punditPredictions}
                    userPredictions={predictions}
                    opponent={headToHead.opponent}
                />
                <ButtonVariant
                    hasPredicted={hasPredicted}
                    roundStatus={roundStatus}
                />
            </Styled.ReducedWidthDesktopContainer>
            <RoundFixtures
                roundId={roundId}
                punditPredictions={punditPredictions}
                userPredictions={predictions}
                headToHead={headToHead}
            />
        </PageContainer>
    );
};

Results.propTypes = {
    roundId: PropTypes.number.isRequired,
    roundStatus: PropTypes.string.isRequired,
    arePredictionsLoading: PropTypes.bool.isRequired,
    predictions: PropTypes.shape({
        hasPredicted: PropTypes.bool,
        hasEnteredHeadToHead: PropTypes.bool,
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    getPredictions: PropTypes.func.isRequired,
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }).isRequired,
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    getPunditPredictions: PropTypes.func.isRequired
};

Results.defaultProps = {
    predictions: null,
    punditPredictions: null
};

ButtonVariant.propTypes = {
    hasPredicted: PropTypes.bool,
    roundStatus: PropTypes.string
};

ButtonVariant.defaultProps = {
    hasPredicted: null,
    roundStatus: ''
};

const mapStateToProps = (state, props) => ({
    arePredictionsLoading: getLoadingRoundPredictions(state, props.roundId),
    predictions: getRoundPredictions(state, props.roundId),
    punditPredictions: getRoundPredictionsByUser(
        state,
        props.roundId,
        PUNDIT_ID
    ),
    roundStatus: selectRoundStatusById(state, props.roundId)
});

const mapDispatchToProps = (dispatch, props) => ({
    getPredictions: () => dispatch(requestRoundPredictions(props.roundId)),
    getPunditPredictions: roundId =>
        dispatch(requestRoundPredictionsByUser(roundId, PUNDIT_ID))
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
