import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const TermsText = () => (
    <>
        <li>
            Terms and Conditions
            <Styled.OrderedList>
                <li>
                    These terms and conditions &#40;&quot;
                    <strong>Terms and Conditions&quot;</strong>&#41; will apply:
                    <Styled.OrderedList>
                        <li>
                            in conjunction with any specific terms and
                            conditions of any applicable promotion. Where any
                            specific terms and conditions conflict with these
                            Terms and Conditions, the specific terms of the
                            applicable promotion shall apply; and
                        </li>
                        <li>
                            if you apply to enter the Super 6 football
                            predictions game located at{' '}
                            <Link to="/">https://super6.skysports.com/</Link>
                            &nbsp; &#40;&quot;
                            <strong>Competition</strong>&quot;&#41;
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    You will be deemed to have accepted these Terms and
                    Conditions and agreed to be bound by them when entering the
                    Competition.
                </li>
                <li>
                    For the purposes of these Terms and Conditions, any
                    reference to &quot;you&quot;, &quot;entrant&#40;s&#41;&quot;
                    or &quot;player&#40;s&#41;&quot; are to persons who have
                    entered the Competition in accordance with the terms set out
                    at Clause 4 &#40;
                    <Styled.EmphasisedText>How to Enter</Styled.EmphasisedText>
                    &#41;.
                </li>
                <li>
                    Please note that Apple Inc. and Alphabet Inc. are in no way
                    linked or associated with this Competition or any other
                    competitions/content run in the Mobile Apps &#40;as defined
                    below&#41;.
                </li>
            </Styled.OrderedList>
        </li>

        <li>
            The Promoter
            <Styled.OrderedList>
                <li>
                    The Competition is co&#45;promoted by Sky UK Limited of
                    Grant Way, Isleworth, Middlesex, TW7 5QD &#40;&quot;
                    <strong>Sky</strong>&quot;&#41; and Hestview Limited
                    &#40;trading as Sky Games&#41; with a registered office
                    address of One Chamberlain Square Cs, Birmingham, United
                    Kingdom, B3 3AX. &#40;Sky and Sky Games together being, the
                    &quot;<strong>Promoter</strong>
                    &quot;&#41;.
                </li>
            </Styled.OrderedList>
        </li>

        <li>
            Eligibility
            <Styled.OrderedList>
                <li>
                    This Competition is only open to persons aged 18 years or
                    over resident in the UK, Isle of Man, Channel Islands and
                    Republic of Ireland, except for:
                    <Styled.OrderedList>
                        <li>
                            employees of the Promoter and/or each of their
                            associated companies;
                        </li>
                        <li>
                            employees of agents and/or suppliers of the Promoter
                            and/or its associated companies who are
                            professionally connected with the Competition and/or
                            its administration; or
                        </li>
                        <li>
                            members of the immediate families or households of
                            those specified in clauses 3.1.1 or 3.1.2 above.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    Where entrants do not meet the criteria set out in clause
                    3.1, their entry will not be valid and they will not be
                    eligible to win.
                </li>
                <li>
                    The Promoter may require entrants to provide proof of age,
                    residence and identity.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default TermsText;
