import styled from '@emotion/styled/macro';

export const Card = styled.div`
    box-sizing: border-box;
    background-color: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.card.borderRadius};
    padding: ${props => props.theme.spacing.vlarge}
        ${props => props.theme.spacing.vlarge}
        ${props => props.theme.spacing.xlarge}
        ${props => props.theme.spacing.vlarge};
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${props => props.theme.spacing.vlarge};
    max-width: 500px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.xlarge};
`;

export const ImageContainer = styled.div`
    display: flex;
    flex: 0 0 70px;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colours.gallery};
    border-radius: 50%;
    width: 70px;
    height: 70px;
    align-self: center;
`;

export const IconImage = styled.img`
    width: 35px;
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const SubTitle = styled.h3`
    color: ${props => props.theme.colours.colorOnPrimary};
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 700;
`;

export const Label = styled.label`
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 400;
`;

export const InputField = styled.input`
    border-radius: ${props => props.theme.button.primary.borderRadius};
    border: none;
    height: 45px;
    background-color: ${props => props.theme.colours.gallery};
    font-size: ${props => props.theme.fontSize.large};
    text-align: center;
    font-family: ${props => props.theme.fonts.medium};
    width: 100%;
`;
