import styled from '@emotion/styled/macro';

export const MatchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 113px;
    margin: 5px 0 5px 0;
`;

export const Match = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        width: 28px;
        height: 28px;
    }

    div {
        margin: 0 5px 0 5px;
    }
`;
