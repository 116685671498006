import styled from '@emotion/styled/macro';

export const List = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 380px;
    max-width: 100%;
    padding: ${props => props.theme.spacing.xxlarge}
        ${props => props.theme.spacing.large}
        ${props => props.theme.spacing.large};
    box-sizing: border-box;
    z-index: 110;
`;

export const ListItem = styled.li`
    display: block;
    list-style: none;
    margin: 0 0 ${props => props.theme.spacing.medium} 0;
    padding: 0;
`;

export const Message = styled.div`
    display: block;
    color: ${props => props.theme.colours.white};
    border-radius: 4px;
    padding: ${props => props.theme.spacing.large};
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.lineHeight.normal};
`;

export const SuccessMessage = styled(Message)`
    background: ${props => props.theme.colours.greenPrimary};
    border: 1px solid ${props => props.theme.colours.greenPrimaryBorder};
    color: ${props => props.theme.colours.colorOnPrimary};
`;

export const ErrorMessage = styled(Message)`
    background: ${props => props.theme.colours.red};
    border: 1px solid ${props => props.theme.colours.redDark};
`;

export const DismissButton = styled.button`
    display: block;
    float: right;
    margin-left: ${props => props.theme.spacing.medium};
    background: transparent;
    color: ${props =>
        props.primary
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.white};
    border: none;
    font-size: ${props => props.theme.fontSize.medium};
    padding: ${props => props.theme.spacing.medium};
    margin: -${props => props.theme.spacing.medium} -${props =>
            props.theme.spacing.medium}
        0 0;

    &:hover {
        cursor: pointer;
        color: ${props =>
            props.primary
                ? props.theme.colours.colorOnPrimary
                : props.theme.colours.lightGrey};
    }

    &:focus {
        outline: none;
    }
`;
