import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Container = ({ title, children, size, maintainRadius }) => (
    <Styled.Container maintainRadius={maintainRadius}>
        {title && (
            <Styled.Header size={size} maintainRadius={maintainRadius}>
                {title}
            </Styled.Header>
        )}
        {children}
    </Styled.Container>
);

Container.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.node
    ]).isRequired,
    maintainRadius: PropTypes.bool,
    size: PropTypes.oneOf(['l'])
};

Container.defaultProps = {
    maintainRadius: false,
    title: null,
    size: null
};

export default Container;
