import { select, put, takeLeading, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as modalActions from '../modal/actions';
import { addSuccessMessage, addErrorMessage } from '../message/actions';
import * as types from './types';
import { selectToken } from '../auth/selectors';
import leagueApi from '../../../api/league';
import history from '../../../routing/history';

export function* getLeagues() {
    try {
        const token = yield select(selectToken);
        const data = yield call(leagueApi.getLeagues, token);
        yield put(actions.receiveLeagues(data));
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving leagues'));
    }
}

export function* getLeague(action) {
    const { slug } = action;

    try {
        const token = yield select(selectToken);
        const data = yield call(leagueApi.getLeague, slug, token);
        yield put(actions.receiveLeague(data));
    } catch (err) {
        yield put(actions.leagueNotFound(slug));
        yield put(addErrorMessage('There was a problem retrieving the league'));
    }
}

export function* getPundits() {
    try {
        const data = yield call(leagueApi.getPundits);
        yield put(actions.receivePundits(data));
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving pundits'));
    }
}

export function* createLeague(action) {
    try {
        const { name, pundits } = action;
        const token = yield select(selectToken);
        const data = yield call(leagueApi.createLeague, name, pundits, token);
        yield call(history.push, `/league/${data.slug}/season`);
        yield put(actions.postLeagueSuccess(data));
        yield put(addSuccessMessage('Successfully created a new league'));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(
                addErrorMessage(
                    `There was a problem creating the league: ${err.message}`
                )
            );
        } else {
            yield put(
                addErrorMessage('There was a problem creating the league')
            );
        }
    }
}

export function* joinLeague(action) {
    try {
        const { slug } = action;
        const token = yield select(selectToken);
        yield call(leagueApi.joinLeague, slug, token);

        yield call(
            history.push,
            `/league/${slug.toUpperCase().replace(' ', '')}/season`
        );
        yield put(actions.postLeagueJoinSuccess(slug));
        yield put(addSuccessMessage(`Successfully joined league ${slug}`));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(addErrorMessage(err.message));
        } else {
            yield put(
                addErrorMessage('There was a problem joining the league')
            );
        }
    }
}

export function* getMyScore(action) {
    try {
        const { slug, period, periodId } = action;
        const token = yield select(selectToken);
        const data = yield call(
            leagueApi.getMyScore,
            slug,
            period,
            periodId,
            token
        );
        yield put(actions.receiveMyScore(slug, period, periodId, data));
    } catch (err) {
        yield put(addErrorMessage('There was a problem retrieving the score'));
    }
}

export function* getResults(action) {
    try {
        const { slug, period, periodId, page } = action;
        const token = yield select(selectToken);
        const data = yield call(
            leagueApi.getResults,
            slug,
            period,
            periodId,
            page,
            token
        );
        yield put(
            actions.receiveLeagueResults(slug, period, periodId, page, data)
        );
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the results')
        );
    }
}

export function* getLeagueLeaderboard(action) {
    try {
        const { slug, page } = action;
        const token = yield select(selectToken);
        const data = yield call(leagueApi.getLeaderboard, slug, page, token);

        yield put(
            actions.receiveLeagueResults(
                slug,
                'season',
                null,
                page,
                data.leagueTable
            )
        );
        yield put(actions.receiveMyScore(slug, 'season', null, data.user));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the leaderboard')
        );
    }
}

export function* deleteLeague(action) {
    try {
        const { slug } = action;
        const token = yield select(selectToken);
        yield call(leagueApi.deleteLeague, slug, token);
        yield put(actions.leagueDeleteSuccess(slug));
        yield put(modalActions.dismissModal());
        yield call(history.push, '/league');
        yield put(addSuccessMessage(`Successfully deleted league ${slug}`));
    } catch (err) {
        yield put(addErrorMessage('There was a problem deleting the league'));
    }
}

export function* leaveLeague(action) {
    try {
        const { slug } = action;
        const token = yield select(selectToken);
        yield call(leagueApi.leaveLeague, slug, token);
        yield put(actions.leagueDeleteSuccess(slug));
        yield put(modalActions.dismissModal());
        yield call(history.push, '/league');
        yield put(addSuccessMessage(`Successfully left league ${slug}`));
    } catch (err) {
        yield put(addErrorMessage('There was a problem leaving the league'));
    }
}

export function* removeMember(action) {
    try {
        const { slug, userId, name } = action;
        const token = yield select(selectToken);
        yield call(leagueApi.removeMember, slug, userId, token);
        yield put(actions.removeMemberSuccess(slug, userId));
        yield put(modalActions.dismissModal());
        yield put(
            addSuccessMessage(`Successfully removed ${name} from league`)
        );
    } catch (err) {
        yield put(addErrorMessage('There was a problem removing the member'));
    }
}

export function* updateLeague(action) {
    try {
        const { slug, name, pundits } = action;
        const token = yield select(selectToken);
        yield call(leagueApi.updateLeague, slug, name, pundits, token);
        yield call(history.push, `/league/${slug}/season`);
        yield put(actions.clearLeagueState());
        yield put(addSuccessMessage('Successfully updated your league'));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(
                addErrorMessage(
                    `There was a problem updating the league: ${err.message}`
                )
            );
        } else {
            yield put(
                addErrorMessage('There was a problem updating the league')
            );
        }
    }
}

export function* restartLeague(action) {
    try {
        const { slug, name, pundits } = action;
        const token = yield select(selectToken);
        const data = yield call(
            leagueApi.restartLeague,
            slug,
            name,
            pundits,
            token
        );
        yield call(history.push, `/league/${data.slug}/season`);
        yield put(actions.restartLeagueSuccess(slug, data));
        yield put(addSuccessMessage('Successfully restarted your league'));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(
                addErrorMessage(
                    `There was a problem restarting the league: ${err.message}`
                )
            );
        } else {
            yield put(
                addErrorMessage('There was a problem restarting the league')
            );
        }
    }
}

export function* getLeaguePundits(action) {
    try {
        const { slug } = action;
        const token = yield select(selectToken);
        const data = yield call(leagueApi.getLeaguePundits, slug, token);
        yield put(actions.receiveLeaguePundits(slug, data));
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem retrieving the league pundits')
        );
    }
}

export function* watchGetLeagues() {
    yield takeLeading(types.REQUEST_LEAGUES, getLeagues);
}

export function* watchGetPundits() {
    yield takeLeading(types.REQUEST_PUNDITS, getPundits);
}

export function* watchCreateLeague() {
    yield takeLeading(types.REQUEST_CREATE_LEAGUE, createLeague);
}

export function* watchJoinLeague() {
    yield takeLeading(types.REQUEST_JOIN_LEAGUE, joinLeague);
}

export function* watchGetLeague() {
    yield takeLeading(types.REQUEST_LEAGUE, getLeague);
}

export function* watchGetMyScore() {
    yield takeLeading(types.REQUEST_MY_SCORE, getMyScore);
}

export function* watchGetResults() {
    yield takeLeading(types.REQUEST_LEAGUE_RESULTS, getResults);
}

export function* watchDeleteLeague() {
    yield takeLeading(types.REQUEST_DELETE_LEAGUE, deleteLeague);
}

export function* watchLeaveLeague() {
    yield takeLeading(types.REQUEST_LEAVE_LEAGUE, leaveLeague);
}

export function* watchRemoveMember() {
    yield takeLeading(types.REQUEST_REMOVE_MEMBER, removeMember);
}

export function* watchUpdateLeague() {
    yield takeLeading(types.REQUEST_UPDATE_LEAGUE, updateLeague);
}

export function* watchRestartLeague() {
    yield takeLeading(types.REQUEST_RESTART_LEAGUE, restartLeague);
}

export function* watchRequestLeaguePundits() {
    yield takeLeading(types.REQUEST_LEAGUE_PUNDITS, getLeaguePundits);
}

export function* watchRequestLeagueLeaderboard() {
    yield takeLeading(types.REQUEST_LEAGUE_LEADERBOARD, getLeagueLeaderboard);
}

export const sagas = [
    watchGetLeagues,
    watchGetPundits,
    watchCreateLeague,
    watchJoinLeague,
    watchGetLeague,
    watchGetMyScore,
    watchGetResults,
    watchDeleteLeague,
    watchLeaveLeague,
    watchRemoveMember,
    watchUpdateLeague,
    watchRestartLeague,
    watchRequestLeaguePundits,
    watchRequestLeagueLeaderboard
];
