import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobileOnly, isDesktop } from 'react-device-detect';
import * as Styled from './styles';
import SHARE_LINKS from './config';
import Image from '../Common/Image';
import { getApp } from '../../helpers/appBridge';
import Button from '../Common/Button';
import { documentCanCopy, executeCopy } from '../../helpers/clipboardHelpers';
import { sendSocialShare as sendSocialShareAction } from '../../store/modules/appBridge/actions';

const BasicShare = ({
    sendSocialShare,
    customMessage,
    shareEvent,
    clipboardValue,
    colour,
    small,
    theme
}) => {
    const [isCopied, setIsCopied] = useState(false);
    const fieldRef = useRef(null);

    const emitNativeShareEvent = e => {
        if (shareEvent) {
            shareEvent();
        }
        sendSocialShare({
            text: customMessage.shareText || '',
            URL: '',
            imageURL: '',
            origin: window.location.pathname.split('/')[1] || 'homepage'
        });

        e.preventDefault();
    };

    function createLink(item) {
        let result = item.link;
        if (item.useText) {
            result += encodeURIComponent(customMessage.shareText);
        }

        if (item.useShortText) {
            result += encodeURIComponent(
                customMessage.shortShareText || customMessage.shareText
            );
        }

        if (item.useUrl && customMessage.url) {
            result += `&url=${encodeURIComponent(customMessage.url)}`;
        }

        return result;
    }

    const copyText = e => {
        fieldRef.current.select();
        executeCopy();
        e.target.focus();
        setIsCopied(true);
    };

    return (
        <Styled.ShareContainer>
            {getApp() ? (
                <Styled.ButtonPadding small>
                    <Button
                        slim={small}
                        data-analytics="[Share]-Native"
                        id="share-btn"
                        onClick={emitNativeShareEvent}
                    >
                        {customMessage.buttonText || 'Share now'}
                    </Button>
                </Styled.ButtonPadding>
            ) : (
                <>
                    <Styled.IconRow>
                        {SHARE_LINKS.map(item => {
                            if (
                                (item.showInBrowser && isDesktop) ||
                                (item.showOnMobile && isMobileOnly)
                            ) {
                                return (
                                    <Styled.Icon
                                        small={small}
                                        onClick={shareEvent}
                                        key={item.link}
                                        colour={
                                            theme === 'dark' &&
                                            item.darkTheme &&
                                            item.darkTheme.colour
                                                ? item.darkTheme.colour
                                                : item.colour
                                        }
                                        href={createLink(item)}
                                        data-analytics={item.analytics}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        displace={item.displace}
                                        data-test-id={item.testId}
                                        {...item.extraProps} // eslint-disable-line react/jsx-props-no-spreading
                                    >
                                        {item.image ? (
                                            <Image
                                                src={
                                                    theme === 'dark' &&
                                                    item.darkTheme &&
                                                    item.darkTheme.image
                                                        ? item.darkTheme.image
                                                        : item.image
                                                }
                                                alt={item.altText}
                                            />
                                        ) : (
                                            <Styled.Character
                                                icon={item.character}
                                            />
                                        )}
                                        <span
                                            className="react-native-share-text"
                                            hidden
                                        >
                                            {customMessage.shareText}
                                        </span>
                                    </Styled.Icon>
                                );
                            }
                            return null;
                        })}
                    </Styled.IconRow>

                    {clipboardValue && documentCanCopy() && (
                        <>
                            <Styled.HiddenInput
                                readOnly
                                ref={fieldRef}
                                value={clipboardValue}
                            />
                            <Styled.CopyLinkButton
                                onClick={copyText}
                                colour={colour}
                            >
                                {isCopied
                                    ? 'Copied!'
                                    : 'Or copy link to clipboard'}
                            </Styled.CopyLinkButton>
                        </>
                    )}
                </>
            )}
        </Styled.ShareContainer>
    );
};

BasicShare.propTypes = {
    sendSocialShare: PropTypes.func.isRequired,
    small: PropTypes.bool,
    customMessage: PropTypes.shape({
        shareText: PropTypes.string,
        shortShareText: PropTypes.string,
        url: PropTypes.string,
        buttonText: PropTypes.string
    }),
    shareEvent: PropTypes.func,
    clipboardValue: PropTypes.string,
    colour: PropTypes.string,
    theme: PropTypes.string
};

BasicShare.defaultProps = {
    small: false,
    customMessage: {
        shareText: null,
        buttonText: null
    },
    shareEvent: null,
    clipboardValue: null,
    colour: null,
    theme: 'light'
};

const mapDispatchToProps = dispatch => ({
    sendSocialShare: data => dispatch(sendSocialShareAction(data))
});

export default connect(null, mapDispatchToProps)(BasicShare);
