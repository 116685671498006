import styled from '@emotion/styled';
import Button from '../../components/Common/Button';
import { PageContainer } from '../../components/Common/Page/styles';

export const LandingContainer = styled(PageContainer)`
    box-sizing: border-box;

    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        padding: 0 ${props => props.theme.spacing.medium};
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        height: 3em;
        width: 3em;

        color: ${props => props.theme.colours.black};
    }
`;

export const HeaderImage = styled.img`
    width: 100%;
`;

export const PrimaryButton = styled(Button)`
    width: 100%;
    margin-bottom: 10px;
`;

export const SecondaryButton = styled(Button)`
    width: 100%;
    margin-bottom: 10px;
    background: none;
    color: ${props => props.theme.colours.textTitle};
    border: solid 1px ${props => props.theme.colours.textTitle};

    &:hover {
        background: ${props => props.theme.colours.borderDarkGrey};
    }
`;
