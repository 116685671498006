/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChallengePrediction from '../ChallengePrediction/index';
import Team from '../../../../components/Team';
import Score from '../../../../components/Score';
import MatchStatus from '../../../../components/MatchStatus';
import ConnectedLiveOdds from '../../../../components/LiveOdds';

import * as Styled from './styles';

const ResultMatch = ({
    challenge,
    headToHead,
    liveOdds,
    isLive,
    isResulted,
    canBet
}) => {
    const formatForTeam = team => ({
        badgeUri: challenge[`${team}`].logo,
        name: challenge[`${team}`].name
    });
    const showMatchStatus =
        !isNaN(challenge.homeTeam.score) && !isNaN(challenge.awayTeam.score);
    const isFullTime = showMatchStatus && isResulted && !isLive;
    const isLiveWithScores = showMatchStatus && !isResulted && isLive;

    return (
        <Styled.MatchContainer data-test-id="h2h-results-fixture">
            <Styled.MatchRow top>
                <Styled.GridItem>
                    <Team team={formatForTeam('homeTeam')} mobWidth="100%" />
                </Styled.GridItem>
                <Styled.GridItem width="35%">
                    <Styled.MatchScoreContainer>
                        <Styled.MatchStatusContainer data-test-id="match-status">
                            {isFullTime && <MatchStatus textInRed="FULLTIME" />}
                            {isLiveWithScores && (
                                <MatchStatus
                                    textInRed="LIVE"
                                    textInBlue="LATEST"
                                />
                            )}
                        </Styled.MatchStatusContainer>
                        <Score
                            homeScore={challenge.homeTeam.score}
                            awayScore={challenge.awayTeam.score}
                            testId="h2h-result-match-score"
                            isScore
                        />
                    </Styled.MatchScoreContainer>
                </Styled.GridItem>
                <Styled.GridItem>
                    <Team team={formatForTeam('awayTeam')} mobWidth="100%" />
                </Styled.GridItem>
            </Styled.MatchRow>
            <Styled.MatchRow bottom>
                <Styled.MatchLine />
                <ChallengePrediction
                    label="Your Prediction"
                    homePrediction={challenge.homeTeam.userPrediction}
                    awayPrediction={challenge.awayTeam.userPrediction}
                    points={challenge.userPoints}
                />
                <ChallengePrediction
                    label={`${headToHead.opponent.firstName}'s Prediction`}
                    homePrediction={challenge.homeTeam.opponentPrediction}
                    awayPrediction={challenge.awayTeam.opponentPrediction}
                    points={challenge.opponentPoints}
                />
            </Styled.MatchRow>
            {canBet && liveOdds && isLive && !isResulted && (
                <Styled.MatchFooter>
                    <ConnectedLiveOdds
                        outcomes={[
                            liveOdds.home
                                ? { ...liveOdds.home, desc: 'Home' }
                                : null,
                            liveOdds.draw
                                ? { ...liveOdds.draw, desc: 'Draw' }
                                : null,
                            liveOdds.away
                                ? { ...liveOdds.away, desc: 'Away' }
                                : null
                        ]}
                        isResulted={isResulted}
                    />
                </Styled.MatchFooter>
            )}
        </Styled.MatchContainer>
    );
};

ResultMatch.defaultProps = {
    liveOdds: null,
    isLive: false
};

ResultMatch.propTypes = {
    challenge: PropTypes.shape({
        userPoints: PropTypes.number,
        opponentPoints: PropTypes.number,
        homeTeam: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string,
            score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            userPrediction: PropTypes.number,
            opponentPrediction: PropTypes.number
        }),
        awayTeam: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string,
            score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            userPrediction: PropTypes.number,
            opponentPrediction: PropTypes.number
        })
    }).isRequired,
    headToHead: PropTypes.shape({
        opponent: PropTypes.shape({
            firstName: PropTypes.string
        })
    }).isRequired,
    isLive: PropTypes.bool,
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    }),
    canBet: PropTypes.bool.isRequired,
    isResulted: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    canBet: !!state.user.info.canBet
});

export default connect(mapStateToProps)(ResultMatch);
