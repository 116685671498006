/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Styled from './styles';
import Ampersand from './Ampersand';
import { requestHeadToHead } from '../../store/modules/round/actions';
import {
    selectActiveRoundId,
    selectHeadToHead,
    selectIsLoadedHeadToHead
} from '../../store/modules/round/selectors';
import { sendBannerEvent } from '../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../helpers/GoogleAnalytics/modules';
import actions from '../../helpers/GoogleAnalytics/actions';

export const HeadToHeadBanner = ({
    headToHead,
    roundId,
    getHeadToHead,
    hasLoadedHeadToHead,
    largeText
}) => {
    useEffect(() => {
        if (hasLoadedHeadToHead) {
            return;
        }

        getHeadToHead(roundId);
    }, [roundId, getHeadToHead, hasLoadedHeadToHead]);

    if (!hasLoadedHeadToHead || (hasLoadedHeadToHead && !headToHead)) {
        return null;
    }

    const opponentName = headToHead.opponent.firstName.toUpperCase();
    const opponentImg = headToHead.opponent.profileImage;
    const prizeValue = headToHead.prizes.value.toUpperCase();

    return (
        <Styled.H2HBannerContainer data-test-id="head-to-head-banner">
            <Link
                to="/headtohead/results/"
                onClick={() =>
                    sendBannerEvent(
                        'head to head',
                        modules.playFlow.h2hBanner,
                        actions.clicked,
                        1,
                        'https://super6.skysports.com/headtohead/results/'
                    )
                }
            >
                <Ampersand largeText={largeText} />
                <Styled.H2HBannerBox>
                    <Styled.H2HBannerContent>
                        <Styled.TextContent>
                            <Styled.H2HHashtagLine largeText={largeText}>
                                #HEAD2HEADCHALLENGE
                            </Styled.H2HHashtagLine>
                            <Styled.Super6Logo
                                src="/content/img/logos/super6-head-to-head.png"
                                alt="Super 6 Logo"
                            />
                            <Styled.H2HChallengeLine largeText={largeText}>
                                CAN YOU BEAT {opponentName}?
                            </Styled.H2HChallengeLine>
                        </Styled.TextContent>
                        <Styled.ImageContent>
                            <img src={opponentImg} alt="H2H Opponent" />
                        </Styled.ImageContent>
                    </Styled.H2HBannerContent>
                    <Styled.H2HFooter>
                        <Styled.H2HPrizeLine>
                            BEAT {opponentName} &#38; HAVE A CHANCE TO
                        </Styled.H2HPrizeLine>
                        <Styled.H2HPrizeValue>
                            WIN {prizeValue}
                        </Styled.H2HPrizeValue>
                    </Styled.H2HFooter>
                </Styled.H2HBannerBox>
            </Link>
        </Styled.H2HBannerContainer>
    );
};

HeadToHeadBanner.propTypes = {
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string.isRequired
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string,
            profileImage: PropTypes.string.isRequired,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    }),
    roundId: PropTypes.number.isRequired,
    getHeadToHead: PropTypes.func.isRequired,
    hasLoadedHeadToHead: PropTypes.bool.isRequired,
    largeText: PropTypes.bool
};

HeadToHeadBanner.defaultProps = {
    headToHead: null,
    largeText: false
};

const mapStateToProps = state => ({
    roundId: selectActiveRoundId(state),
    hasLoadedHeadToHead: selectIsLoadedHeadToHead(
        state,
        selectActiveRoundId(state)
    ),
    headToHead: selectHeadToHead(state, selectActiveRoundId(state))
});

const mapDispatchToProps = dispatch => ({
    getHeadToHead: roundId => dispatch(requestHeadToHead(roundId))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeadToHeadBanner);
