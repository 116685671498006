import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Super6Hero = ({ info, hero, isSeasonClosed }) => (
    <Styled.Container>
        <Styled.InfoWrapper isSeasonClosed={isSeasonClosed}>
            {info}
        </Styled.InfoWrapper>
        <Styled.HeroImageContainer isSeasonClosed={isSeasonClosed}>
            {hero}
        </Styled.HeroImageContainer>
    </Styled.Container>
);

Super6Hero.propTypes = {
    info: PropTypes.node.isRequired,
    hero: PropTypes.node.isRequired,
    isSeasonClosed: PropTypes.bool
};

Super6Hero.defaultProps = {
    isSeasonClosed: false
};

export default Super6Hero;
