import axios from 'axios';
import { API_ROOT } from '../config';
import { getUserSessionId } from '../helpers/sessionStorage';

const playApi = {
    postPredictions: async (requestBody, token) => {
        const headers = {
            Authorization: token,
            'X-Session-Id': getUserSessionId()
        };
        const { data, status } = await axios.post(
            `${API_ROOT()}/user/self/prediction`,
            requestBody,
            {
                headers
            }
        );
        if (status !== 201) {
            if (data.reason && data.reason === 'Round is paused') {
                throw new Error('Round is paused');
            }
            throw new Error('Predictions could not be submitted');
        }

        return data;
    },
    postHeadToHeadEntry: async token => {
        const headers = {
            Authorization: token,
            'X-Session-Id': getUserSessionId()
        };
        const { data, status } = await axios.post(
            `${API_ROOT()}/head-to-head/enter`,
            {},
            { headers }
        );
        if (status !== 201) {
            throw new Error('Head to head could not be entered');
        }
        return data;
    }
};

export default playApi;
