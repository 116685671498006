import styled from '@emotion/styled/macro';
import Image from '../Common/Image';
import AppLinkButton from '../Common/AppLink/styles';

export const XSellContainer = styled.div`
    background: ${props => props.theme.colours.xSellBg};
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 50;
    top: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ProductList = styled.div`
    margin: auto;
    height: 36px;
    display: table;
`;

export const ProductLink = styled.div`
    display: table-cell;
    vertical-align: middle;
    padding: 0 12px;

    a,
    ${AppLinkButton} {
        display: flex;
        height: 100%;
        width: 100%;

        img {
            margin: auto;
        }
    }
`;

export const ProductImage = styled(Image)`
    height: ${props => (props.large ? '20px' : '15px')};
    border-radius: ${props => (props.border ? '1.2px' : '0')};
`;
