import { fork, all } from 'redux-saga/effects';
import { sagas as roundSagas } from './modules/round';
import { sagas as followSagas } from './modules/follow';
import { sagas as contentSagas } from './modules/content';
import { sagas as userSagas } from './modules/user';
import { sagas as offerSagas } from './modules/offer';
import { sagas as feedbackSagas } from './modules/feedback';
import { sagas as rafSagas } from './modules/raf';
import { sagas as leaderboardSagas } from './modules/leaderboard';
import { sagas as leagueSagas } from './modules/league';
import { sagas as playSagas } from './modules/play';
import { sagas as resultsSagas } from './modules/results';
import { sagas as appBridgeSagas } from './modules/appBridge';
import { sagas as authSagas } from './modules/auth';
import { sagas as super6ExtraSagas } from './modules/super6Extra';
import { sagas as podcastSagas } from './modules/podcast';
import { sagas as signalSagas } from './modules/signal';
import { sagas as metricsSagas } from './modules/metrics';
import { sagas as bannerSagas } from './modules/banner';
import { sagas as statsSagas } from './modules/stats';

const allSagas = [
    ...roundSagas,
    ...followSagas,
    ...contentSagas,
    ...userSagas,
    ...offerSagas,
    ...feedbackSagas,
    ...rafSagas,
    ...leaderboardSagas,
    ...playSagas,
    ...resultsSagas,
    ...leagueSagas,
    ...appBridgeSagas,
    ...authSagas,
    ...super6ExtraSagas,
    ...podcastSagas,
    ...signalSagas,
    ...metricsSagas,
    ...bannerSagas,
    ...statsSagas
];

export default function* rootSaga() {
    yield all(allSagas.map(saga => fork(saga)));
}
