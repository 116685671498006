import styled from '@emotion/styled/macro';

export const Profile = styled.div`
    display: flex;
`;

export const AvatarContainer = styled.div`
    display: flex;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${props => props.theme.colours.black2};
    padding-left: ${props => props.theme.spacing.medium};
`;

export const Name = styled.span`
    font-family: ${props => props.theme.fonts?.skySportsD};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: 700;
    color: ${props => props.theme.colours.bluePrimary};
    line-height: 1.22;

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;

export const Description = styled.span`
    font-family: ${props => props.theme.fonts.Bold};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: 700;
    color: ${props => props.theme.colours.bluePrimary};
    line-height: 1.22;

    @media (max-width: ${props => props.theme.widths.smallMobile}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;
