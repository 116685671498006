/* eslint-disable import/prefer-default-export */
export const selectStatsForSelf = state => state.stats.self || {};
export const selectLoadingStatsForSelf = state => !!state.stats.isLoadingSelf;
export const selectCurrentStreakForSelf = state => {
    if (!state.stats.self) {
        return null;
    }

    return state.stats.self.currentStreak;
};
export const selectSeasonStatsForSelf = state => state.stats.season || {};
export const selectLoadingSeasonStatsForSelf = state =>
    !!state.stats.isLoadingSeasonStats;

export const selectHeadToHeadStats = state =>
    state.stats.seasonHeadToHead || {};
