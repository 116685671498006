import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updatePrediction } from '../../../../store/modules/play/actions';
import * as Styled from './styles';

export const MatchPredictionController = ({
    matchId,
    team,
    score,
    updatePredictionValue,
    disabledPlaceholder,
    teamIdentifier
}) => {
    const onUpdate = value => {
        if (value < 0 || value > 30) {
            return;
        }
        updatePredictionValue(matchId, team, Number(value));
    };

    const disabled = !!disabledPlaceholder;

    return (
        <Styled.MatchPredictionController>
            <div>
                <Styled.Plus
                    onClick={() => onUpdate(score + 1)}
                    disabled={disabled}
                    tabIndex={-1}
                    id={`increase-score-${teamIdentifier}`}
                    data-test-id={`match-team-prediction-${team}-increase`}
                />
            </div>
            <div>
                <Styled.MatchScore
                    disabled={disabled}
                    id={`score-${teamIdentifier}`}
                    data-test-id={`match-team-prediction-${team}-score`}
                >
                    {disabledPlaceholder || score}
                </Styled.MatchScore>
            </div>
            <div>
                <Styled.Minus
                    onClick={() => onUpdate(score - 1)}
                    disabled={disabled}
                    tabIndex={-1}
                    id={`decrease-score-${teamIdentifier}`}
                    data-test-id={`match-team-prediction-${team}-decrease`}
                />
            </div>
        </Styled.MatchPredictionController>
    );
};

MatchPredictionController.propTypes = {
    matchId: PropTypes.number.isRequired,
    team: PropTypes.oneOf(['home', 'away']).isRequired,
    score: PropTypes.number,
    updatePredictionValue: PropTypes.func.isRequired,
    disabledPlaceholder: PropTypes.string,
    teamIdentifier: PropTypes.string
};

MatchPredictionController.defaultProps = {
    score: 0,
    disabledPlaceholder: null,
    teamIdentifier: null
};

const mapDispatchToProps = dispatch => ({
    updatePredictionValue: (matchId, team, score) => {
        dispatch(updatePrediction(matchId, team, score));
    }
});

export default connect(null, mapDispatchToProps)(MatchPredictionController);
