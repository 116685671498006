import metricsApi from '../api/metrics';

export default (validationLink, targetLink, cookieConsentQuery) => {
    const CONSENT_KEY = 'consentGroups';
    try {
        if (!cookieConsentQuery) {
            throw Error();
        }

        if (!validationLink) {
            throw Error();
        }

        const regex = new RegExp(validationLink);

        if (!regex.test(targetLink)) {
            return targetLink;
        }

        const url = new URL(targetLink);
        url.searchParams.set(CONSENT_KEY, cookieConsentQuery);
        return url.toString();
    } catch (error) {
        // ignoring metrics errors
        metricsApi
            .countMetric(CONSENT_KEY, { error })
            .then(() => {})
            .catch(() => {});
        return targetLink;
    }
};
