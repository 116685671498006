const SHARE_LINKS = [
    {
        link: 'https://wa.me/?text=',
        image: '/img/logos/share-whatsapp.png',
        altText: 'Whatsapp',
        analytics: '[Share]-Whatsapp',
        showInBrowser: true,
        showOnMobile: false,
        testId: 'share-link-whatsapp'
    },
    {
        link: 'whatsapp://send?text=',
        image: '/img/logos/share-whatsapp.png',
        altText: 'Whatsapp',
        analytics: '[Share]-Whatsapp',
        showInBrowser: false,
        showOnMobile: true,
        testId: 'share-link-whatsapp'
    },
    {
        link:
            'https://www.facebook.com/dialog/share?app_id=388193917967007&href=http%3A%2F%2Fsuper6.skysports.com&quote=',
        image: '/img/logos/share-facebook.png',
        altText: 'Facebook',
        analytics: '[Share]-Facebook',
        showInBrowser: true,
        showOnMobile: true,
        testId: 'share-link-facebook'
    },
    {
        link:
            'https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Fsuper6.skysports.com&related=Super6&text=',
        image: '/img/logos/share-twitter.png',
        altText: 'Twitter',
        analytics: '[Share]-Twitter',
        showInBrowser: true,
        showOnMobile: true,
        testId: 'share-link-twitter'
    },
    {
        link: 'mailto:?subject=Play%20Super%206&body=',
        image: '/img/logos/share-mail.png',
        altText: 'Email',
        analytics: '[Share]-Email',
        showInBrowser: true,
        showOnMobile: true,
        testId: 'share-link-email'
    }
];

export default SHARE_LINKS;
