import { useState } from 'react';

function useHandleValue() {
    const [value, setValue] = useState('');

    const handleValueChange = e => {
        setValue(e.target.value);
    };
    return [value, handleValueChange];
}

export default useHandleValue;
