import { SSO_CONSUMER_URL, SSO_AUTH_HOST, SSO_CONSUMER } from '../config';
import { checkSsoToken, logout } from '../store/modules/auth/actions';
import { setupSsoAppBridge } from '../helpers/appBridge';

let skyBetAccount;
const SESSION_ITEM_SILENT_AUTH_ATTEMPTED = 'sso_silentAttempted';

let dispatch;
export const setDispatch = dispatchFunction => {
    dispatch = dispatchFunction;
};

export const removeTokensFromBrowser = () => {
    sessionStorage.removeItem('sso_token');
    sessionStorage.removeItem(SESSION_ITEM_SILENT_AUTH_ATTEMPTED);
    sessionStorage.removeItem('sso_logged_in');
};

export const redirectToAccount = () => {
    skyBetAccount.openMyAccount();
};

export const storeTokensInBrowser = token => {
    sessionStorage.setItem('sso_token', token);
};

export const getTokensFromBrowser = () => {
    const idToken = sessionStorage.getItem('sso_token');
    if (idToken) {
        return { idToken };
    }
    return null;
};

const setup = callback => () => {
    let hasCookies = false;
    let hasCalledBack = false;
    skyBetAccount = window.SkyBetAccount;
    skyBetAccount.bind('hasCookies', data => {
        if (hasCookies !== data) {
            hasCookies = data;
        }
        if (!hasCalledBack) {
            hasCalledBack = true;
            callback();
        }
    });

    skyBetAccount.setup({
        consumer: SSO_CONSUMER,
        consumerUrl: SSO_CONSUMER_URL,
        host: SSO_AUTH_HOST,
        appBridge: true,
        pollPeriod: 0
    });

    skyBetAccount.bind('loggedIn', data => {
        const ssoToken = data.user
            ? data.user.ssotoken || data.user.ssoToken || null
            : null;
        if (!ssoToken) {
            return;
        }
        dispatch(checkSsoToken(ssoToken, data.userInteraction));
    });

    skyBetAccount.bind('loggedOut', data => {
        dispatch(logout(data && data.userInteraction));
    });

    setupSsoAppBridge(dispatch);
};

export const init = async () =>
    new Promise((resolve, reject) => {
        const accountScript = document.createElement('script');
        accountScript.type = 'text/javascript';
        accountScript.src =
            'https://st1.skybet.com/static/identity/SkyBetAccount.js';
        accountScript.onload = setup(resolve);
        accountScript.onerror = err => reject(err);
        document.body.appendChild(accountScript);
    });

export const redirectToLogin = () => skyBetAccount.openLogin();

export const silentAuth = () => {
    const hasAttempted = sessionStorage.getItem(
        SESSION_ITEM_SILENT_AUTH_ATTEMPTED
    );
    if (hasAttempted) {
        return Promise.resolve();
    }
    sessionStorage.setItem(SESSION_ITEM_SILENT_AUTH_ATTEMPTED, '1');
    return skyBetAccount.fireLoginState();
};

export const getSsoTransferToken = callback => {
    skyBetAccount.getSsoTransferToken(callback);
};

export const shouldCheckAuth = () => true;
