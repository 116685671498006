import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const HeroImage = ({
    mobile3x,
    mobile2x,
    mobile1x,
    desktop3x,
    desktop2x,
    desktop1x
}) => (
    <Styled.HeroImage>
        <source
            media="(max-width: 720px)"
            srcSet={`
                    ${mobile3x} 3x,
                    ${mobile2x} 2x,
                    ${mobile1x} 1x
                `}
        />
        <source
            srcSet={`
                    ${desktop3x} 3x,
                    ${desktop2x} 2x,
                    ${desktop1x} 1x
                `}
        />
        <img src={desktop2x} alt="Masthead" />
    </Styled.HeroImage>
);

HeroImage.propTypes = {
    mobile3x: PropTypes.string,
    mobile2x: PropTypes.string,
    mobile1x: PropTypes.string,
    desktop3x: PropTypes.string,
    desktop2x: PropTypes.string,
    desktop1x: PropTypes.string
};

HeroImage.defaultProps = {
    mobile3x: null,
    mobile2x: null,
    mobile1x: null,
    desktop3x: null,
    desktop2x: null,
    desktop1x: null
};

export default HeroImage;
