import { takeEvery, call, takeLeading } from 'redux-saga/effects';
import { isMobile } from 'react-device-detect';
import * as types from './types';
import metricsApi from '../../../api/metrics';
import { getUserSessionId } from '../../../helpers/sessionStorage';

function* triggerCountMetric(action) {
    if (action.key === 'play_flow_submit_failed') {
        yield call(metricsApi.countMetric, action.key, {
            userSessionId: getUserSessionId()
        });
    } else {
        yield call(metricsApi.countMetric, action.key, action.labels);
    }
}

function* captureLcp(action) {
    try {
        const device = isMobile ? 'mobile' : 'desktop';
        yield call(metricsApi.lcpMetric, action.page, action.value, device);
    } catch (e) {
        // no op
    }
}

// eslint-disable-next-line import/no-unused-modules
export function* watchTriggerCountMetric() {
    yield takeEvery(types.TRIGGER_COUNT_METRIC, triggerCountMetric);
}

// eslint-disable-next-line import/no-unused-modules
export function* watchCaptureLcp() {
    yield takeLeading(types.CAPTURE_LCP, captureLcp);
}

export const sagas = [watchTriggerCountMetric, watchCaptureLcp];
