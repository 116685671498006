import { put, takeLeading, call, delay } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import contentApi from '../../../api/content';

export function* getYoutubeId() {
    try {
        const data = yield call(contentApi.getYoutubeId);
        yield put(actions.receiveYoutubeId(data));
        yield delay(5000);
    } catch (err) {
        yield put(actions.receiveYoutubeId(null));
        yield delay(5000);
    }
}

export function* getFeatureSwitches() {
    try {
        const data = yield call(contentApi.getFeatureSwitches);
        yield put(actions.receiveFeatureSwitches(data));
        yield delay(300000);
    } catch (err) {
        yield put(actions.receiveFeatureSwitches({}));
        yield delay(300000);
    }
}

export function* watchGetYoutubeId() {
    yield takeLeading(types.REQUEST_YOUTUBE_ID, getYoutubeId);
}

export function* watchRequestFeatureSwitches() {
    yield takeLeading(types.REQUEST_FEATURE_SWITCHES, getFeatureSwitches);
}

export const sagas = [watchGetYoutubeId, watchRequestFeatureSwitches];
