import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const Competition = () => (
    <>
        <li>
            The Competition
            <Styled.OrderedList>
                <p>Rounds</p>
                <li>
                    There will be 6 fixtures in each full round of the
                    Competition.
                </li>
                <li>
                    The list of fixtures and kick off times for each round can
                    be viewed at the Super 6 Website at{' '}
                    <Link to="/">https://super6.skysports.com/</Link>.
                </li>
                <li>
                    Each round closes at the kick off time of the first fixture
                    of each round.
                </li>
                <p>How points are scored</p>
                <li>
                    Points will be scored as follows:
                    <Styled.OrderedList>
                        <li>
                            Players will receive 5 points for each correct{' '}
                            <strong>score</strong> &#40;number of goals for each
                            team&#41; predicted in a Super 6 fixture.
                        </li>
                        <li>
                            Players will receive 2 points for each correct{' '}
                            <strong>result</strong> &#40;win, draw, or loss for
                            each team&#41; predicted in a Super 6 fixture.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    Players will not score points for a correct Golden Goal
                    Prediction.
                </li>
                <p>Tiebreakers</p>
                <li>
                    Golden Goal Predictions will be used to determine winners in
                    the case where multiple entrants qualify for a Prize. The
                    most accurate Golden Goal Prediction will win the Prize in
                    such a tie&#45;break situation.
                </li>
                <li>
                    The most accurate Golden Goal Prediction is the Golden Goal
                    Prediction which is closest to the actual time of the first
                    goal across any of the six games in the round.
                </li>
                <li>
                    When judging closest prediction for time of the Golden Goal
                    Prediction, times before and after the actual time of the
                    first goal will be considered equally.
                </li>
                <p>Fixture scores and goal times</p>
                <li>
                    The fixture scores and time of first goal &#40;&quot;
                    <strong>Fixture and Goal Data</strong>&quot;&#41; are
                    published by Stats Perform group &#40;&quot;
                    <strong>Opta</strong>&quot;&#41;. The Fixture and Goal Data
                    published by Opta will be used to determine the final league
                    positions and winners of all Prizes &#40;as defined
                    below&#41;.
                </li>
                <li>
                    Any goals scored in injury time will be recorded as
                    forty&#45;five minutes for the first half and ninety minutes
                    for the second half of the fixture.
                </li>
                <li>
                    The timing of goals scored will be recorded as follows:
                    <Styled.OrderedList>
                        <li>0 &#45; 59 seconds = 1st minute;</li>
                        <li>1m00s &#45; 1m59s = 2nd minute;</li>
                        <li>
                            2m00s &#45; 2m59s = 3rd minute; and so on. For
                            example, if a player selects minute 6 for the Golden
                            Goal Prediction then this will apply where the first
                            goal is scored between 5m00s &#45; 5m59s.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    If there are no goals in any of the fixtures then the
                    correct answer will be deemed to be 0 or 90.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Competition;
