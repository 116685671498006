import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import ordinalFormatter from './helper';
import MatchStatus from '../MatchStatus';

const PointsResult = ({
    correctResultsPoints,
    correctScoresPoints,
    goldenGoal
}) => (
    <Styled.PointsResultCard>
        <Styled.CardHeader>
            <MatchStatus textInRed="LIVE" textInBlue="POINTS" />
        </Styled.CardHeader>
        <Styled.PointsResultContainer>
            <Styled.FlexItem>
                <Styled.BodyText>Correct Result(s) points</Styled.BodyText>
                <Styled.Points data-test-id="results-points">
                    {correctResultsPoints}
                </Styled.Points>
            </Styled.FlexItem>
            <Styled.FlexItem>
                <Styled.BodyText>Correct Score(s) points</Styled.BodyText>
                <Styled.Points data-test-id="scores-points">
                    {correctScoresPoints}
                </Styled.Points>
            </Styled.FlexItem>
            <Styled.TotalPointsSection>
                <Styled.BodyText bold>Total Points</Styled.BodyText>
                <Styled.Points total data-test-id="total-points">
                    {correctScoresPoints + correctResultsPoints}
                </Styled.Points>
            </Styled.TotalPointsSection>
            {goldenGoal && goldenGoal.goldenGoalPredictionMinute && (
                <Styled.FlexItem>
                    <Styled.BodyText>Golden Goal Prediction</Styled.BodyText>
                    <Styled.BodyText data-test-id="golden-goal-prediction-minute">
                        {ordinalFormatter(
                            goldenGoal.goldenGoalPredictionMinute
                        )}
                        &nbsp;minute
                    </Styled.BodyText>
                </Styled.FlexItem>
            )}
            {goldenGoal && goldenGoal.isGoldenGoalScored ? (
                <Styled.FlexItem>
                    <Styled.BodyText data-test-id="golden-goal-scorer-name-team">
                        {goldenGoal.goldenGoalScorerName}&nbsp;(&nbsp;
                        {goldenGoal.goldenGoalScorerTeam}&nbsp;)
                    </Styled.BodyText>
                    <Styled.BodyText data-test-id="golden-goal-scored-minute">
                        {ordinalFormatter(goldenGoal.goldenGoalScoredMinute)}
                        &nbsp;minute
                    </Styled.BodyText>
                </Styled.FlexItem>
            ) : (
                <Styled.FlexItem>
                    <Styled.BodyText data-test-id="golden-goal-status">
                        {`The Golden Goal ${
                            goldenGoal && goldenGoal.inPlay ? 'has' : 'was'
                        } not ${
                            goldenGoal && goldenGoal.inPlay
                                ? 'been scored yet.'
                                : 'scored.'
                        }`}
                    </Styled.BodyText>
                </Styled.FlexItem>
            )}
        </Styled.PointsResultContainer>
    </Styled.PointsResultCard>
);

PointsResult.propTypes = {
    correctResultsPoints: PropTypes.number.isRequired,
    correctScoresPoints: PropTypes.number.isRequired,
    goldenGoal: PropTypes.shape({
        goldenGoalPredictionMinute: PropTypes.number,
        goldenGoalScorerName: PropTypes.string,
        goldenGoalScorerTeam: PropTypes.string,
        goldenGoalScoredMinute: PropTypes.number,
        inPlay: PropTypes.bool,
        isGoldenGoalScored: PropTypes.bool
    })
};

PointsResult.defaultProps = {
    goldenGoal: { inPlay: true, isGoldenGoalScored: false }
};

export default PointsResult;
