import React from 'react';
import Skeleton from '../../components/Skeleton';
import * as Styled from './styles';

export default function LoadingSkeleton() {
    return (
        <Styled.SkeletonContainer data-test-id="loading-skeleton-container">
            <Skeleton height="300px" width="60vw" />
            <Skeleton height="40px" width="60vw" />
            <Skeleton height="200px" width="60vw" />
        </Styled.SkeletonContainer>
    );
}
