import styled from '@emotion/styled/macro';
import { keyframes, css } from '@emotion/core';
import { IMAGE_BASE_PATH } from '../../../config';
import {
    StatBoxGroupTopRow,
    StatBoxGroupRow,
    StatBoxGroupContainer
} from '../components/StatsBoxGroup/styles';

export const TitleBox = styled.div`
    width: 100%;
    background: url(${IMAGE_BASE_PATH}/img/performance-hub/BG_Lines_M.png)
        no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        background: url(${IMAGE_BASE_PATH}/img/performance-hub/BG_Lines_D.png)
            no-repeat center;
        background-size: cover;
        height: 156px;
        max-width: 1400px;
        margin: 0 auto;
    }
`;

export const StreakBoxContainer = styled.div`
    padding-top: 4px;
    max-width: 318px;
    width: 100%;
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding-top: 14px;
    }
`;

export const StatsBoxGroupContainer = styled.div`
    padding: 25px 0;
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding-left: 38px;
        padding-top: 54px;
        padding-bottom: 0;
    }
`;

export const MyPerformanceHubTitle = styled.h1`
    height: 90px;

    img {
        height: 100%;
        width: calc(100% - 40px);
        display: block;
        margin: 0 auto;
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 380px;
    }
`;

const loadingKeyframes = keyframes`
    from {
        left: -300px;
    }
        
    to {
        left: 100%;
    }
`;

const loadingAnimation = props => css`
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: 0;
        height: 100%;
        width: 300px;
        background: ${props.theme.gradients.loading};
        animation: ${loadingKeyframes} 1.5s linear infinite;
    }
`;

export const TitleLoading = styled.div`
    height: 144px;
`;

export const StreakBoxContainerLoading = styled.div`
    max-width: 318px;
    width: 100%;
    height: 300px;
    border-radius: 14px;

    position: relative;
    margin-top: 54px;
    padding: 80px 10px 10px;
    box-sizing: border-box;
    background: ${props => props.theme.colours.bgGrey};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding-top: 14px;
    }
    overflow: hidden;
    ${loadingAnimation}
`;

export { StatBoxGroupContainer, StatBoxGroupRow, StatBoxGroupTopRow };

export const StatBoxLoading = styled.div`
    border-radius: 14px;
    width: 88px;
    height: 87px;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 139px;
        height: 138px;
    }

    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;
    ${loadingAnimation}
`;

export const StatBoxContainerLoading = styled(StatBoxLoading)`
    margin-left: 4px;

    @media (min-width: 341px) {
        margin-left: 21px;
    }

    &:first-of-type {
        margin-left: 0;
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin-left: 42px;
    }
`;

export const PerformanceHubDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const PerformanceHubComponentContainer = styled.div`
    padding: 0 0 46px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        margin-top: 30px;
    }

    > div:first-of-type {
        margin-top: 0px;
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: 34px 0 34px 0;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        > div {
            margin-top: 0px;
            margin-left: 38px;
        }

        > div:first-of-type {
            margin-left: 0px;
        }
    }
`;

export const LastRoundLoading = styled.div`
    max-width: 318px;
    width: 100%;
    height: 506px;
    border-radius: 26px;

    background: ${props => props.theme.colours.bgGrey};
    ${loadingAnimation}
`;

export const PerformanceHubContainer = styled.div`
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin: auto;
        width: 849px;
        ${props => props.componentsEnabled && 'width: 1026px;'};
    }
`;
export const HeadToHeadLoading = styled.div`
    max-width: 318px;
    width: 100%;
    height: 392px;
    border-radius: 26px;

    background: ${props => props.theme.colours.bgGrey};
    ${loadingAnimation}
`;

export const GraphLoading = styled.div`
    max-width: 318px;
    width: 100%;
    height: 380px;
    border-radius: 26px;

    background: ${props => props.theme.colours.bgGrey};
    ${loadingAnimation}
`;
