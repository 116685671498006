import styled from '@emotion/styled/macro';

const ToastContainer = styled.div`
    background-color: ${props => props.theme.colours.backgroundGrey};
    border-left: 4px solid ${props => props.theme.colours.greenPrimary};
    border-right: 4px solid ${props => props.theme.colours.greenPrimary};
    ${props =>
        props.type === 'info'
            ? `border-color: ${props.theme.colours.borderDarkGrey};`
            : ''}
    padding: 10px 15px;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: ${props => props.theme.lineHeight.normal};

    h3 {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-right: 20px;
        font-size: 20px;
        font-family: ${props => props.theme.fonts.medium};
    }
`;

export default ToastContainer;
