import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';

const Privacy = () => (
    <Styled.PrivacyPageContainer>
        <PageTitle>Privacy Policy</PageTitle>
        <Styled.Header nopad>Let&apos;s talk about Privacy</Styled.Header>
        <p>
            We like to make things easier for our customers and that includes
            being clear and open about how and why we use your personal &apos;s
            nothing more off-putting than the sight of a lot of boring small
            print, so we&apos;ve written our Privacy Policy in plain English to
            make it clear, simple and easy to read. We hope you&apos;ll find it
            helpful but if you have any concerns or questions please feel free
            to contact our{' '}
            <a href="mailto:dp@skybettingandgaming.com">Data Privacy Office.</a>
        </p>
        <p>
            The wording from this Privacy Policy was updated in March 2018. It
            was reformatted in July 2022 to separate out the Cookies Policy
            which can now be found <a href="/cookies-policy">here</a>. We will
            update it from time to time and if we make any changes that could
            affect you, we&apos;ll let you know before they happen.
        </p>
        <p>
            Our Policy explains how Sky Betting and Gaming uses your personal
            &apos;re just visiting one of our websites, placing a bet or playing
            one of our games. There is a lot of information, and we know you
            won&apos;t want to read it all at once,{' '}
            <strong>
                but where you see the &apos;
                <Styled.ImportantKey>!</Styled.ImportantKey>&apos; symbol, it
                indicates something that we particularly need you to be aware of
                as it has an impact on your privacy. This includes information
                you may not already know about your data privacy rights,
                circumstances in which we share and obtain information about
                you, and how we use it to personalise the marketing you receive.
                Please take time to read those sections carefully.
            </strong>
        </p>
        <Styled.SectionHeader>Our Privacy Promise:</Styled.SectionHeader>
        <p>
            We never lose sight of the fact that your personal information is{' '}
            <strong>your</strong> personal information. Your privacy is
            important both to you and to us and we make the following promise to
            you:
        </p>
        <p>
            Sky Betting and Gaming is committed to protecting your privacy. We
            believe in using your personal information to make things simpler
            and better for you. We will always keep your personal information
            safe. We&apos;ll be clear and open with you about why we collect
            your personal information and how we use it. Where you have choices
            or rights, we&apos;ll explain them to you and respect your wishes.
        </p>
        <Styled.Header>Our Privacy Policy</Styled.Header>
        <Styled.SubSectionHeader>
            Who&apos;s in control of your information?
        </Styled.SubSectionHeader>
        <p>
            Throughout this Privacy Policy, &apos;we&apos;, &apos;our&apos;,
            &apos;Sky Bet&apos; or &apos;Sky Betting and Gaming&apos; means
            companies within the Bonne Terre, Hestview Group, Cyan Blue
            International Limited, Cyan Blue Odds Limited & Core Gaming Limited.
            You will know us better by our trading names, which include Sky
            Betting & Gaming, Sky Bet, Sky Vegas, Sky Casino, Sky Bingo, Sky
            Poker, Sky Games, Sky Sports Super 6, Sky Sports Fantasy Football,
            Fantasy Six a Side, ITV7, and Sportinglife.
        </p>
        <p>
            Although Oddschecker (Cyan Blue Odds Limited) is part of our group
            of companies, it has a separate privacy policy as its products and
            services differ from those of our other group companies, so if you
            are also an Oddschecker customer you may wish to have a look at the
            Oddschecker privacy policy as well as this one.
        </p>
        <p>
            In the UK, to comply with local data protection law, Hestview
            Limited, Bonne Terre Limited, Cyan Blue Odds Limited & Core Gaming
            &apos;Data Controllers&apos; with the Information
            Commissioner&apos;s Office (ICO) and details are published on a
            public register on the ICO website at{' '}
            <a
                rel="noopener noreferrer"
                href="http://ico.org.uk"
                target="_blank"
            >
                http://ico.org.uk
            </a>
            .
        </p>
        <Styled.SectionHeader>Our Data Protection Officer</Styled.SectionHeader>
        <p>
            If you have any concerns about how SBG handles your personal
            information, you can contact our Data Protection Officer at:{' '}
            <a href="mailto:dp@skybettingandgaming.com">
                dp@skybettingandgaming.com
            </a>
            .
        </p>
        <p>
            If you wish to submit a subject access request or a request to
            exercise any of your rights here, please{' '}
            <Link
                smooth
                to="/privacy#right-to-access-the-data-we-hold-about-you"
            >
                click here
            </Link>{' '}
            for details of how to do so.
        </p>
        <Styled.SectionHeader>
            Collecting information about you
        </Styled.SectionHeader>
        <Styled.SubSectionHeader id="information-we-get-from-you">
            Information we get from you
        </Styled.SubSectionHeader>
        <p>
            Sky Betting and Gaming collects personal information about you
            whenever you use our services. Some of it is information you give us
            directly when, for example, you register for an account, deposit
            funds, place a bet or play one of our games. Other information is
            collected as you interact with us, such as the bets you place or
            online chats with our customer services team. We also collect
            information about the way you access, view, share, contribute to and
            communicate with and through our services, for example when you post
            comments via our social media channels or chat functionalities (such
            as via Sky Poker).
        </p>
        <Styled.SubSectionHeader>
            Information we get from your device
        </Styled.SubSectionHeader>
        <Styled.Important>
            We collect information from the devices you use to receive Sky
            Betting and Gaming content, products and services. This includes,
            but is not limited to the following: your IP address (a number that
            identifies a specific device on the internet and is required for
            your device to communicate with websites), hardware model, operating
            system and version, software, preferred language, serial numbers,
            device motion information, mobile network information and location
            data.
        </Styled.Important>
        <p>
            We also collect server logs, which include information such as dates
            and times of access, the app features or pages you view, app crashes
            and other system activity, and the third party site or service you
            were using immediately before visiting our site.
        </p>
        <p>
            Every iOS device is assigned an Identifier for Advertisers (IDFA)
            which enables app owners and advertisers to track campaigns and
            deliver personalised advertising. If you are an iOS user and have
            opted in to tracking for Sky Betting and Gaming, your IDFA
            (Identifier for Advertisers) is used to deliver you personalised
            advertising that is relevant to you (based on information we have
            about you, including browsing history, transactional information,
            demographic information and behavioural information, predictive
            information we create about you in each case in relation to our
            services and advertising and information about what other people
            with similar interests, demographics and behaviours are looking at)
            and is used for attribution and analytics purposes to tell us when
            and how you have interacted with advertisements, including those
            that have been placed on a third party site or app, and so we can
            assess the effectiveness of our campaigns. You can opt out of this
            at any time by visiting your mobile settings (by going to Settings
            {'>'} Privacy {'>'} Advertising and turning on Limit Ad Tracking).
        </p>
        <Styled.SubSectionHeader>
            Information we get from external companies
        </Styled.SubSectionHeader>
        <Styled.Important>
            To help us comply with our{' '}
            <Link smooth to="/privacy#legal-and-regulatory-requirements">
                legal and regulatory obligations
            </Link>
            , when you open your account we obtain information (in the UK and
            Germany) from a Credit Reference Agency called CallCredit and, in
            the UK, from GB Group plc. This does not involve data about your
            credit standing or &apos;credit score&apos;.
        </Styled.Important>
        <p>
            Sometimes we need to undertake additional checks to verify
            information or, once an account is active, to investigate activity
            that could indicate a risk. To do this we use organisations and
            databases (such as Iovation Ltd) which collate information and make
            it commercially available for use in crime prevention and detection,
            the promotion of responsible gambling and ensuring the fair use of
            services. We also look at publicly available information to verify
            things like occupation status or property ownership, and at social
            media.
        </p>
        <p>
            Where a UK customer self-excludes through the &apos;Gamstop&apos;
            national self-exclusion scheme, we will receive a notification of
            this, which we will use to apply their chosen restriction across our
            sites and apps. To help us in our{' '}
            <Link smooth to="/privacy#legal-and-regulatory-requirements">
                commitment to responsible gambling
            </Link>
            , we aim to understand the factors that can lead people to
            self-exclude or indicate the early stages of problem gambling. We
            look at information about customers who have self-excluded (such as
            age group, location and past betting behaviour) to identify common
            factors and this helps us to provide better and earlier support for
            customers who may be at risk of problem gambling. We may in future
            subscribe to similar national self-exclusion schemes in any of the
            countries in which we operate, and we will update this policy to let
            you know.
        </p>
        <p>
            Like many companies, from time to time we may supplement the
            information we hold about you with data from companies such as
            Experian or in the UK, the Royal Mail, that collate and update
            address and household information from a range of sources and make
            it commercially available. This helps us keep our records up to
            date, fill gaps in our data and learn more about our customers so we
            can continue to improve our products and services. We will only work
            with reputable organisations that obtain their data legally in
            accordance with data protection law.
        </p>
        <p>
            Occasionally, we obtain lists of potential customers from companies
            that collate these details and make them commercially available for
            marketing purposes. We only deal with reputable companies that take
            privacy as seriously as we do and have obtained your consent to
            share this data with us or companies in our sector for marketing,
            and you will always be able to opt out of receiving further
            marketing from us.
        </p>
        <p>
            If we acquire another business, we will usually obtain customer
            information as part of the handover process. In this case, we or the
            other company will let you know that your personal information is
            changing hands, provide you with a copy of this privacy policy after
            completion of the acquisition, and explain what to do if you do not
            wish your information to change hands.
        </p>
        <Styled.SubSectionHeader id="publicly-available-information">
            Publicly available information
        </Styled.SubSectionHeader>
        <Styled.Important>
            As a betting and gaming company we must comply with{' '}
            <Link smooth to="/privacy#legal-and-regulatory-requirements">
                legal obligations
            </Link>{' '}
            to promote responsible gambling, consider our customers&apos;
            financial ability to gamble, and prevent our products and services
            being misused or used for{' '}
            <Link smooth to="/privacy#legal-and-regulatory-requirements">
                illegal purposes
            </Link>
            . If we observe account activity that could present a risk, or
            believe you have given false information, we will look at publicly
            available information about you such as your Facebook or other
            social media pages, property ownership details, the electoral roll,
            company annual returns for places you have worked, industry bodies
            of which you may be a member, and insolvency registers. Although
            this information is public, we remain mindful of your privacy and
            use it only as and where necessary for carrying out these checks.
        </Styled.Important>
        <p>
            If you would like to know more about gambling regulation and the
            obligations it places on companies like SB&G, you can find out more
            on the{' '}
            <a
                href="https://www.gamblingcommission.gov.uk/Home.aspx"
                rel="noopener noreferrer"
                target="_blank"
            >
                Gambling Commission website
            </a>
            .
        </p>
        <Styled.SubSectionHeader>
            Information we get from social media
        </Styled.SubSectionHeader>
        <p>
            If you raise a query or a complaint through the Sky Betting and
            Gaming Facebook or Twitter pages, we will of course have a record of
            your username and will use this to talk to you to resolve the matter
            you&apos;ve raised and keep accurate records of how it was resolved.
        </p>
        <p>
            We look at information on your Facebook or other public social media
            pages when carrying out checks to comply with our{' '}
            <Link smooth to="/privacy#publicly-available-information">
                legal and regulatory obligations
            </Link>
            . This helps us to verify information you have given us and ensure
            our products are being used legally and responsibly.
        </p>
        <p>
            We use information posted publicly on social media sites to help us
            understand how our customers interact with us. For example, we might
            look at which groups of customers are more likely to contact us via
            social media or to use social media to talk about our products and
            services. We do this in a way that does not identify individual
            customers.
        </p>
        <Styled.SubSectionHeader id="how-and-why-we-use-your-personal-info">
            How and why we use your personal information
        </Styled.SubSectionHeader>
        <Styled.Important>
            We use the information we hold about you in a range of different
            ways, which fall into these broad categories:
        </Styled.Important>
        <ul>
            <li>
                Things we need to do{' '}
                <strong>
                    {' '}
                    in order to provide you with the products or services
                    you&apos;ve requested
                </strong>
            </li>
            <li>
                Things we need to do{' '}
                <strong>to meet legal or regulatory obligations</strong>{' '}
            </li>
            <li>
                Things that enable us{' '}
                <strong>
                    to run our business effectively and efficiently, and
                </strong>
            </li>
            <li>
                Things we do{' '}
                <strong>with your consent for marketing purposes</strong>{' '}
            </li>
        </ul>
        <p>
            We&apos;re telling you this because data protection law gives you
            rights over your personal data, which differ according to which of
            these categories it falls into. This section explains more about
            each category, the rights it gives you, how to exercise them and
            what that means in practice.
        </p>
        <Styled.SubSectionHeader>
            Providing our products and services
        </Styled.SubSectionHeader>
        <p>
            As you would expect, we use your personal information to provide you
            with our products and services, respond to queries, contact you
            about bets you have placed or games played and provide you with the
            best possible level of customer service. We use technical
            information about your device; which includes, but is not limited
            to, operating system, browser version and location. As well as
            ensuring we can abide by our legal and regulatory requirements, this
            helps to present you with the correct version of our website or app,
            to keep it functioning securely and correctly and to check that
            users are using our services in licensed countries.
        </p>
        <p>
            Like most organisations, to provide our products and services we
            share your information with external organisations working on our
            behalf. You can find out more about this{' '}
            <Link smooth to="/privacy#sharing-your-information">
                here
            </Link>
            .
        </p>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I opt out of having any of my data used in this
            way?
        </Styled.SubSectionHeader>
        <p>
            This category covers everything that is essential in order for us to
            provide you with the service(s) you use or sign up for. If you
            don&apos;t want your data used in this way your option is to not use
            our services and close your account.
        </p>
        <Styled.SectionHeader>Meeting our Obligations</Styled.SectionHeader>
        <Styled.SubSectionHeader id="legal-and-regulatory-requirements">
            Legal and Regulatory Requirements
        </Styled.SubSectionHeader>
        <p>
            We need to comply with a range of legal and regulatory requirements,
            some of which involve the use of personal information and/or set out
            timescales for which we need to keep that information. As a provider
            of betting and gaming services, we are regulated by the{' '}
            <a
                rel="noopener noreferrer"
                href="https://www.gamblingcommission.gov.uk/Home.aspx"
                target="_blank"
            >
                UK Gambling Commission
            </a>{' '}
            and, outside Great Britain, by the{' '}
            <a
                href="https://www.gamblingcontrol.org/"
                rel="noopener noreferrer"
                target="_blank"
            >
                Alderney Gambling Control Commission
            </a>
            . To comply with their requirements, we carry out checks during
            account opening and throughout the life of account to ensure our
            customers bet and play responsibly and legally. Free-to-play games
            on Sky Games are not regulated in this way, although we do carry out
            age verification checks to ensure players are over 18.
        </p>
        <Styled.Important>
            We take our social responsibility and our{' '}
            <Link
                smooth
                to="/privacy#legal-and-regulatory-requirements
"
            >
                commitment to responsible gambling
            </Link>{' '}
            very seriously and we actively look for behaviour that may indicate
            that someone is at risk of problem gambling so that we can intervene
            at an early stage to provide them with support and direct them to
            sources of help.
        </Styled.Important>
        <p>
            Where a UK customer self-excludes through the &apos;Gamstop&apos;
            national self-exclusion scheme, we will receive a notification of
            this, which we will use to apply their chosen restriction across our
            sites and apps.
        </p>
        <Styled.Important>
            To help us understand the factors that can indicate the early stages
            of problem gambling, we look at information about customers who have
            self-excluded (either directly or through Gamstop), such as age,
            location and past betting behaviour, to identify common factors.
            This helps us intervene earlier in cases where customers may be at
            risk of problem gambling, and provide them with better support.
        </Styled.Important>
        <p>
            We are also subject to laws and regulations relating to other
            aspects of our business, such as payment processing or complaint
            handling and some of these to involve the use of, or set timescales
            for holding, your personal information.
        </p>
        <Styled.SubSectionHeader id="fraud-money-laundering-and-other-illegal-activity">
            Fraud, Money Laundering and Other Illegal Activity
        </Styled.SubSectionHeader>
        <Styled.Important>
            When you set up an account with us, place bets or play our games
            (other than free-to-play games on Sky Games), we carry out identity
            verification, fraud prevention and anti-money laundering checks,
            validating the information you give against{' '}
            <Link smooth to="/privacy#information-we-get-from-you">
                third party crime prevention databases
            </Link>{' '}
            and, where necessary, information from{' '}
            <Link smooth to="/privacy#publicly-available-information">
                publicly-available sources
            </Link>
            . We share your personal data with organisations that verify details
            and transactions and identify potential indicators of illegal
            activity, which they make available to other organisations using
            their services. Where necessary, we share your data with Credit
            Reference Agencies or fraud prevention agencies, which keep a record
            of that information and make it available to other organisations for
            use in credit decisions, identification checks and fraud detection
            and prevention. Where appropriate, we pass information to the police
            and other law enforcement agencies, debt recovery companies and
            other similar bodies.
        </Styled.Important>
        <Styled.Important>
            It is important that Betting and Gaming operators work together to
            prevent illegal activity and promote responsible gaming. In the UK,
            our industry is working with the Gambling Anti-Money Laundering
            &apos;s Office to establish a framework for sharing information
            between providers for this purpose. Where we believe it appropriate
            and necessary to do so to prevent money laundering and fraud, we
            will share information with, and receive information from, other
            betting and gaming providers. We will always do this in legally and
            in line with any industry guidelines.
        </Styled.Important>
        <p>
            If you are a Sky Games customer, we carry out age verification
            &apos;t carry out full identity, fraud prevention and anti-money
            laundering checks unless you later upgrade your account to use our
            pay-to-play products.
        </p>
        <Styled.Important>
            As well as fraud and money laundering, we carry out ongoing
            monitoring to ensure that our products and services are not used for
            other{' '}
            <a href="https://support.skybet.com/app/answers/detail/a_id/40">
                Illegal purposes
            </a>{' '}
            such as event manipulation, &quot;match-rigging&quot; or other forms
            of cheating. We do this in a variety of ways, including the{' '}
            <a href="/cookies-policy">use of cookies</a>, and where we find
            transactions, data or behaviour that could indicate illegal activity
            we share information with third parties, including the European
            Sports Security Association (ESSA) and the police.
        </Styled.Important>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I object to having my personal information used
            in this way?
        </Styled.SubSectionHeader>
        <p>
            This category covers activities we are obliged to do in order to
            provide our products and services legally, responsibly, and in line
            with the requirements of our regulators, and ultimately to protect
            our customers and our business. We cannot provide you with our
            products and services without carrying out these activities, and if
            you don&apos;t want your data used in this way your option is to not
            use our services and close your account.
        </p>
        <Styled.SubSectionHeader id="running-our-business">
            Running our business effectively and efficiently
        </Styled.SubSectionHeader>
        <Styled.Important>
            There are some things we do to help us operate as a commercial
            organisation. We have a legitimate interest in carrying out these
            activities, and where they involve using your personal information
            we are careful to carry them out in a way that minimises any impact
            &apos;right of objection&apos; to the activities in this category if
            your right to privacy outweighs our legitimate business interest in
            doing them. You always have the option to exercise this right but,
            as we explain in more detail below, the activities involved are
            central to our business,{' '}
            <strong>
                so if you wish to do so it will usually mean you need to close
                your account
            </strong>
            .
        </Styled.Important>
        <p>
            <strong>
                Please read this section carefully and ensure you are happy with
                it before providing us with your personal information.
            </strong>
        </p>
        <Styled.SubSectionHeader>Business Insights</Styled.SubSectionHeader>
        <p>
            We carry out basic analytics to help us understand, how, when, where
            and why our customers use our services, and how our business is
            performing. This helps us monitor and plan everything from the
            effectiveness of our advertising through to ensuring we have enough
            staff available to handle queries at peak times. It also gives us a
            much clearer picture of our customers generally, the broad
            demographic groups they fit into (e.g. age group, gender, location,
            etc.) and the products and services they use, which in turn helps us
            to develop better and more relevant features, products and services.
            We do this analysis in a way that does not identify individual
            customers, so there is no impact on the privacy of any one person.
        </p>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I object to having my personal information used
            in this way?
        </Styled.SubSectionHeader>
        <p>
            Like any business, we need to keep a close eye on how our business
            is performing and whether we&apos;re meeting the needs of our
            customers. As we do this in a way that does not identify you as an
            individual, there is no impact on your privacy. If you don&apos;t
            want your data used in this way your option is to not use our
            services and close your account.
        </p>
        <Styled.SubSectionHeader id="giving-you-a-more-personalised-experience">
            Giving you a more personal experience
        </Styled.SubSectionHeader>
        <p>
            Whichever SBG products or services you use, wherever and however you
            interact with us, we want to give you the same great level of
            service and make it personal to you. We will tailor your experience,
            personalising the layout and content of our sites according to what
            we know about you, your preferences and the way you like to play or
            bet. For example, we will present you with features we know you have
            used or think you are likely to use, show you the type of score card
            or bet slip that best suits your style of betting, remind you to
            deposit funds when your account is running low and personalise
            search results to show more relevant results first.
        </p>
        <p>
            We also look at aggregated (non-identifiable) data showing how our
            customers use our products and features and which games they tend to
            enjoy. We use this information to suggest games we think you&apos;ll
            enjoy because they are popular with others who play the same games
            as you.
        </p>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I object to having my personal information used
            in this way?
        </Styled.SubSectionHeader>
        <p>
            We believe this personalised experience makes betting and gaming
            better and we want to give you the best customer experience we can.
            Using your personal data in this way enables us to do that in a way
            that we believe does not have an impact on your privacy. If you
            &apos;t want your data used in this way your option is to not use
            our services and to close your account.
        </p>
        <p>
            Please note that some aspects of your customer experience are
            provided via cookies. If you have enabled cookies in your browser,
            we will personalise certain aspects of our site, such as remembering
            your username and location, and you can control this via your
            browser settings. You can learn more about how to control this{' '}
            <a href="/cookies-policy">here</a>.
        </p>
        <Styled.SubSectionHeader>
            Protecting our commercial interests
        </Styled.SubSectionHeader>
        <Styled.Important>
            Most people use our products and services fairly, but we carry out
            monitoring of how our customers bet, play and interact with our
            products and services, to identify behaviour that is not in line
            with our <a href="/terms">Terms and Conditions</a> or that could be
            prejudicial to our commercial interests. To help us do this, we
            receive information from tools and databases which collate
            information about customer behaviour across the industry and share
            it with betting and gaming operators for this purpose. We use
            services provided by Iovation Ltd., and may also use others
            including (in the UK) CIFAS. Although we obtain information from
            these sources, we do not allow the information we provide to them to
            be used by other operators for this purpose.
        </Styled.Important>
        <Styled.Important>
            At our discretion, we have the right to use this information in
            making decisions about whether to place restrictions on
            people&apos;s accounts or, on very rare occasions, to close their
            accounts. We take this very seriously, and consider a wide variety
            of factors in making such a decision, taking into the information we
            hold about you as well as any information obtained from industry
            data bases. Please note that we do not use cookies to obtain
            information for this purpose, and the decision to restrict or close
            an account is always made with human intervention; it is never
            wholly automated.
        </Styled.Important>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I object to having my personal information used
            in this way?
        </Styled.SubSectionHeader>
        <p>
            As a business, we have a legitimate interest in protecting our
            commercial interests against deliberate misuse, and we are confident
            that we do it in a way that is proportionate to the risks we face
            &apos;t want your data used in this way your option is to not use
            our services and to close your account.
        </p>
        <Styled.SubSectionHeader>Market Research</Styled.SubSectionHeader>
        <p>
            We will occasionally invite you to give feedback on SB&G services
            you have used or to take part in customer surveys, questionnaires or
            focus groups. We will contact you online or via email, directly or
            through a third party organisation acting on our behalf. Taking part
            in research is always voluntary, and where we use a third party we
            do not pass over any details (other than your contact details so
            they can send you the initial request or invitation) unless and
            until we have your consent to do so.
        </p>
        <Styled.SubSectionHeader>
            Privacy Rights: Can I object to having my personal information used
            in this way?
        </Styled.SubSectionHeader>
        <p>
            It&apos;s really important for any business to find out what its
            customers think. As we are careful to limit the number and frequency
            of feedback/research requests we send to any one person, and you can
            always decline to take part, there is no adverse impact on your
            privacy and you cannot opt out of receiving these very occasional
            requests.
        </p>
        <Styled.SubSectionHeader id="things-we-do-with-your-consent-marketing">
            Things we do with your consent: Marketing
        </Styled.SubSectionHeader>
        <p>
            We will send you offers and information only if you have given your
            consent for us to do so, in which case we will contact you via
            email, post, SMS or online about any of our group products and
            services.
            <strong>
                {' '}
                We never share your data with companies outside our group for
                them to use for their own marketing
            </strong>
            . From time to time, we may team up with a third party to bring you
            details of a product or service we think might interest you, but
            where we do this the contact will come from us - we will never pass
            your details to the third party without your prior consent.
        </p>
        <p>
            Please be assured that we do not use any sensitive information we
            hold about you (for example, information about self-exclusion,
            health or ethnicity) for marketing-related purposes.
        </p>
        <Styled.SubSectionSubHeader id="keeping-it-relevant">
            Keeping it Relevant
        </Styled.SubSectionSubHeader>
        <Styled.Important>
            At SBG we want to make betting and gaming better for you, so we want
            to be able to tell you about products, services and features that
            you will find exciting and relevant, and we tailor the offers and
            information we send to suit you. To do this, we look at what we know
            about you - such as your age, location and gender, your browsing,
            betting and gaming history and patterns, your social media usage and
            how you interact with us - and we use it to build up a picture of
            you that helps us decide what you&apos;re most likely to want to
            hear about. (This is sometimes known as &apos;Profiling&apos;). We
            also combine this with information we&apos;ve obtained from publicly
            or commercially available sources about the things people with
            similar characteristics to you (in terms of age, gender, location
            etc.) tend to be interested in so we can fine-tune the offers we
            present to you.
        </Styled.Important>
        <Styled.SubSectionSubHeader id="putting-you-in-control">
            Putting you in Control
        </Styled.SubSectionSubHeader>
        <Styled.Important>
            We firmly believe our customers prefer offers and information that
            are relevant to them and their interests over general adverts, so we
            &apos;ve built up of you. We think this makes our marketing better
            both for you and for us. However, data protection law gives you the
            right to opt out of having your personal data used to build up this
            type of picture and predict what you might be interested in, so{' '}
            <strong>you can opt out at any time</strong>. As we explain above,
            <strong>
                all our marketing is tailored to you in this way, so to opt out
                of this type of personalisation you will need to opt out of
                receiving all direct marketing from us
            </strong>
            . You can do this when you sign up, by not ticking the box to opt
            into marketing, or at any later point via your online account. We
            won&apos;t then send you offers and information by post, email or
            SMS{' '}
            <strong>
                but we will continue to personalise your online experience based
                on the picture we&apos;ve built up of you
            </strong>
            . This means you&apos;ll continue to benefit from a more
            personalised look and feel on our websites and apps, and will still
            see the following:
        </Styled.Important>
        <ul>
            <li>
                Targeted pop-ups telling you about products, services and offers
                we think you&apos;ll like
            </li>
            <li>
                Bespoke offers relevant to you, communicated through pop-ups or
                other on-site content
            </li>
            <li>
                On-site (including in-game) recommendations for games you have
                played or might enjoy
            </li>
            <li>
                Targeted messages on social media platforms such as Facebook or
                Twitter (which you can control easily through your privacy
                settings on each individual platform) and in other places on the
                internet that support targeted advertising.
            </li>
        </ul>
        <Styled.Important>
            We think this strikes the best balance: it lets you opt out of
            receiving marketing through offline channels (post, email and SMS)
            while still enjoying the best online and in-game experience and
            without having to miss out on boosts, bonuses and other benefits.
            <strong>
                However, if you prefer not to receive any personalised messages
                or offers online you can opt out by out by editing your account
                details via your online account
            </strong>
            . If you do opt out you will experience a less personal look and
            feel on our sites and apps and won&apos;t get to hear about bespoke
            offers and bonuses, but don&apos;t worry - we will continue to{' '}
            <Link
                smooth
                to="/privacy#giving-you-a-more-personalised-experience"
            >
                personalise
            </Link>{' '}
            those aspects of your online experience that are not
            marketing-related so, for example, you will still see games you
            recently played. Please also note the following:
        </Styled.Important>
        <ul>
            <li>
                We will still make onsite recommendations for games you might
                enjoy. We base these on how{' '}
                <strong>our customers as a whole</strong> tend to use our sites
                and features, not on information about you as in individual.
            </li>
            <li>
                Some personalised banner adverts on our sites are controlled
                separately through cookies, so{' '}
                <strong>
                    you will also need to adjust your browser&apos;s{' '}
                    <a href="/cookies-policy">cookie settings</a>
                </strong>{' '}
                or you will continue to see them.
            </li>
            <li>
                You may still see our adverts on{' '}
                <Link smooth to="/privacy#online-banner-adverts">
                    other websites
                </Link>{' '}
                or social media platforms you visit, but these will not be
                specifically targeted at you.
            </li>
        </ul>
        <Styled.SubSectionHeader>
            Marketing: Keeping it relevant and putting you in control - At a
            Glance:
        </Styled.SubSectionHeader>
        <p>
            All our marketing is tailored to be more relevant and interesting to
            you, so if you&apos;re:
        </p>
        <ul>
            <li>
                <Styled.Important>
                    Happy to receive personalised offers and information by
                    email, post, SMS and online. Make sure you{' '}
                    <strong>opt in to direct marketing</strong>
                </Styled.Important>
            </li>
            <li>
                Happy to see personalised offers and information when
                you&apos;re online but don&apos;t want to receive them by email,
                post or SMS - you need to{' '}
                <Link smooth to="/privacy#can-i-opt-out-of-marketing">
                    <strong>opt out of direct marketing</strong>
                </Link>
            </li>
            <li>
                <strong>Not</strong> happy to see personalised offers and
                information online or by e-mail, post or SMS - be sure to opt{' '}
                <strong>
                    out of personalised marketing and adjust your cookie
                    settings
                </strong>{' '}
                to avoid receiving banner adverts
            </li>
        </ul>
        <p>
            Remember, you can change your mind at any time by updating your
            preferences in your account.
        </p>
        <Styled.SubSectionHeader>
            Upgrading from Free to Play: marketing preferences
        </Styled.SubSectionHeader>
        <p>
            If you are a Free to Play member and you upgrade your account, your
            existing marketing preferences will continue to apply unless you
            change them. You can do this easily at any time via your online
            account.
        </p>
        <Styled.SubSectionHeader id="online-banner-adverts">
            Online banner adverts: cookies
        </Styled.SubSectionHeader>
        <p>
            If you have enabled cookies in your browser you may receive banner
            adverts on our homepage, as these are cookie-based and not tailored
            using other information we hold about you. As they are cookie-based
            you will receive them even after you have opted out of personalised
            marketing, but you can control them easily via your{' '}
            <a href="/cookies-policy">browser settings</a>.
        </p>
        <Styled.SubSectionHeader>
            Advertising on Social Media
        </Styled.SubSectionHeader>
        <Styled.Important>
            If you have given your consent to marketing, we will work with
            social media companies such as Facebook and Twitter to provide you
            with information about our products and services via their
            platforms. If you do not wish to see these adverts, you can control
            this easily by disabling preference-based marketing in the privacy
            and ad settings on each individual platform.
        </Styled.Important>
        <p>
            Even if you have withdrawn your consent to personalised marketing by
            SBG, you might still see general adverts for SBG products and
            services on your social media feeds. These will not be specifically
            targeted to you, and again, you can control this via the privacy and
            ad settings on each platform.
        </p>
        <Styled.SubSectionHeader>
            Online Behavioural Advertising
        </Styled.SubSectionHeader>
        <Styled.Important>
            We use cookies placed by third parties to collect personal
            information about your browsing activity, which is then grouped with
            data about what other people with similar interests and
            characteristics (in terms of age, gender, location etc.) are looking
            at. The combined information is used to show you online adverts
            based on those interests, either for our own products and services
            or those of a third party (this is known as &apos;Online behavioural
            Advertising&apos;). You can find out more about controlling this in
            our <a href="/cookies-policy">cookie policy</a>.
        </Styled.Important>
        <Styled.SubSectionHeader>
            Marketing on our apps: Push Messages
        </Styled.SubSectionHeader>
        <p>
            When using our apps, you will receive push messages. These can be
            easily disabled through your phone or device&apos;s settings.
        </p>
        <Styled.SubSectionHeader id="can-i-opt-out-of-marketing">
            Privacy Rights: Can I opt out of marketing?
        </Styled.SubSectionHeader>
        <Styled.Important>
            Yes, you have the right to opt out of having your information used
            for direct marketing and the right to opt out of having it used to
            build up a picture of you so we can personalise our marketing to
            suit you.{' '}
            <strong>
                Please read the sections headed{' '}
                <Link smooth to="/privacy#keeping-it-relevant">
                    &apos;Keeping it relevant
                </Link>{' '}
                and{' '}
                <Link smooth to="/privacy#putting-you-in-control">
                    keeping you in control&apos;
                </Link>
            </strong>{' '}
            , which explain how we use your personal data in our marketing, and
            how to control the marketing you see, and remember you can change
            your mind about receiving marketing material at any time via your
            online Sky Betting and Gaming account.
        </Styled.Important>
        <p>
            If you wish to <strong>opt out of e-mail marketing only</strong>,
            you can also do this by clicking on the link at the bottom of any
            email we have sent you. To{' '}
            <strong>opt out of receiving SMS messages</strong>you can reply
            &quot;STOP&quot; to 57785, and you can disable our push
            notifications via your phone&apos;s settings.
        </p>
        <p>
            As our marketing campaigns are prepared well in advance, you are
            likely to continue to receive material for a short period of time
            after updating your preferences.
        </p>
        <Styled.SubSectionHeader>
            More sensitive matters: how we use sensitive data
        </Styled.SubSectionHeader>
        <p>
            As a responsible provider of betting and gaming services, we do our
            best to ensure that our customers bet responsibly, and encourage you
            to make use of the{' '}
            <a href="https://support.skybet.com/app/answers/detail/a_id/249/kw/exclude">
                range of tools
            </a>{' '}
            available to help you with this. This means that on occasion you may
            provide us, directly or indirectly with information about your
            physical, psychological or emotional health or situation and talk to
            us about problems with gambling. We appreciate the sensitivity of
            this information and use it only to provide you with the support you
            have asked for and to signpost you to the appropriate sources of
            help.
        </p>
        <Styled.Important>
            To help us in our commitment to responsible gambling, it&apos;s
            important that we understand the factors that can lead people to
            self-exclude or may indicate the early stages of problem gambling.
            Where a customer self-excludes, either directly or (in the UK)
            through the national Gamstop scheme, we look at demographic
            information about them (such as age group and location) and their
            past betting behaviour and transactions to help us identify common
            factors. This in turn helps us to provide earlier and better support
            to customers who may be at risk of problem gambling. As well as
            ensuring we do all we can to fulfil our regulatory obligations, it
            helps us to help our customers gamble responsibly.
        </Styled.Important>
        <p>
            On occasion, you may provide us, usually indirectly, with other
            sensitive information about yourself, such as your ethnicity or
            nationality (for example, identity verification documentation or
            checks can reveal this information). It is rare for us to ask you
            for this type of information directly, and we will only do so if we
            have a specific and valid legal reason, which we will explain
            clearly at the time. Where we do need to hold this type of sensitive
            information because it is shown in an identification document for
            example, we will do so only to comply with our legal or regulatory
            requirements and will not use or make it available for any other
            purpose.
        </p>
        <p>
            Where you provide us with details of, or a copy of, your passport,
            driving licence, national identity card, code fiscal (in Italy), or
            other national identification documentation, we use it only for
            identification and verification purposes required to fulfil our
            legal and regulatory requirements.
        </p>
        <Styled.SectionHeader id="sharing-your-information">
            Sharing Your Information
        </Styled.SectionHeader>
        <Styled.SubSectionHeader>
            Companies that provide services on our behalf
        </Styled.SubSectionHeader>
        <Styled.Important>
            We share your personal information with external organisations that
            carry out a range of services on behalf of SB&G. We carry out checks
            to ensure that the companies we work with will give your information
            the same level of care and protection as we do. Both we and they are
            obliged to handle your information in accordance with data
            protection law, and we are also required to put in place contractual
            measures reinforcing those obligations.
        </Styled.Important>
        <Styled.Important>
            The main functions that are or may be carried out, fully or in part,
            by third parties are listed below:
            <ul>
                <li>Account set-up and registration</li>
                <li>Management and execution of marketing campaigns</li>
                <li>Printing</li>
                <li>Customer services</li>
                <li>Payment processing and verification</li>
                <li>
                    <Link
                        smooth
                        to="/privacy#fraud-money-laundering-and-other-illegal-activity"
                    >
                        Anti-fraud and money laundering checks and ID
                        verification
                    </Link>
                </li>
                <li>
                    Checks to detect unfair use of our products and services
                </li>
                <li>Web hosting, online content services and data storage</li>
                <li>Management of competitions, contests and offers</li>
                <li>Data analytics and data cleansing</li>
                <li>
                    Market research and collecting or analysing customer
                    feedback
                </li>
                <li>IT services and support</li>
                <li>Audit, Legal & Compliance related services</li>
            </ul>
        </Styled.Important>
        <p>Sharing information with Credit and Fraud-Prevention Agencies</p>

        <p>
            For ID verification and the prevention of fraud, money laundering
            and other illegal use of our pay-to-play services, we use
            commercially-available tools or services provided by Credit
            Reference and Fraud Prevention Agencies and services: Iovation Ltd,
            CallCredit and GB Group. These agencies/services keep a record of
            the information we and other companies provide and make it available
            for use by other organisations carrying out identification checks
            and fraud prevention. In order to provide this essential service,
            these agencies/services are &apos;data controllers&apos; for the
            purposes of data protection law, which means they own and control
            the information we and their other subscribers provide to them. As
            &apos;data controllers&apos;, they are obliged to comply with data
            protection law and can use the information shared with them only to
            provide these checks. We cannot provide our products and services
            without carrying out these essential checks by sharing your data in
            this way, so it is important that we make you aware of it from the
            outset, and for this reason we bring it to your attention in
            &apos;Fraud, Money Laundering and Other Illegal Activity&apos;
            above.{' '}
            <strong>
                If you are not happy for us to pass your information to credit
                and fraud prevention agencies, you will not be able to open an
                account and use our pay-to-play products and services
            </strong>
            .
        </p>
        <Styled.SubSectionHeader>
            Sending Personal Information outside Europe
        </Styled.SubSectionHeader>
        <Styled.Important>
            Some of the third party providers we use are based in, or carry out
            their activities in, countries outside the European Economic Area
            (EEA), which is made up of the EU Member states plus certain
            countries considered to offer a standard of data protection
            equivalent to that of Europe. Where this means personal information
            is transferred outside the EEA, we have to put in place additional
            legal protections on top of our standard checks and measures, to
            ensure it receives the same level of protection as it would within
            Europe. We do this by using standardised contractual clauses
            (sometimes called &apos;the EU Model Clauses&apos;) approved by the
            European Commission and European privacy regulators, although there
            are alternative approved legal mechanisms which we can decide to use
            instead. Where necessary, we also put in place any additional
            contractual measures required by local law in any of the countries
            in which we operate, except where they conflict with the General
            European Data Protection Regulation.
        </Styled.Important>
        <p>
            If you would like to know more about the EU &apos;Model
            Clauses&apos; or the other measures organisations like SB&G can use
            when transferring personal data overseas, you can find information{' '}
            <a href="http://europa.eu/">here</a>.
        </p>
        <Styled.SubSectionHeader>
            Other circumstances in which we share your personal data
        </Styled.SubSectionHeader>
        <p>
            Apart from the functions set out above, we do not share your
            personal information with third parties except where we are
            compelled or permitted by law to do so. These circumstances are
            rare, but may require us to share information with the police or
            other law enforcement agencies, the courts, statutory authorities
            (e.g. in connection with tax matters) and sporting bodies (in
            connection with sporting integrity issues) in any of the countries
            in which we operate.
        </p>
        <p>
            Where necessary to protect or defend our rights and interests,
            resolve disputes or enforce our agreements, we will share personal
            data with our regulators, external legal advisors and debt recovery
            and tracing agencies, although again these circumstances are rare.
        </p>
        <p>
            As we&apos;re a group we may sometimes need to share your
            information within our group for any of the purposes outlined in
            this policy, but we only do this if it&apos;s strictly necessary for
            one of those purposes and where the law permits us to do so.
        </p>
        <p>
            If ownership of all or part of our business changes or we undergo a
            reorganisation or restructure, we will transfer your personal
            information to the new owner or successor company so we or they can
            continue to provide the services you have requested.
        </p>
        <p>
            Whenever we share personal information, and whatever the reason or
            circumstances, we will always do so legally and with due regard to
            your privacy. If we receive a request from law enforcement or other
            statutory bodies, we do not disclose personal information without a
            warrant, court order or other legally valid proof of authority.
        </p>
        <Styled.SubSectionHeader id="how-long-do-we-keep-your-information">
            How long do we keep your information?
        </Styled.SubSectionHeader>
        <p>
            We hold your personal information only as long as we have a valid
            legal reason to do so, which includes providing you with the
            services you have requested, meeting our legal and regulatory
            obligations, resolving disputes and enforcing our agreements. The
            length of time for which we keep different types of personal
            information can vary, depending on why we originally obtained them,
            the reason we process them and the legal requirements that apply to
            <Link smooth to="/privacy#how-and-why-we-use-your-personal-info">
                them
            </Link>{' '}
            . When setting our data retention and deletion timescales we take
            into account a range of factors including applicable regulations and
            standards relating to gambling and gaming, anti-money laundering,
            taxation, payment processing and complaint handling, the need to
            prevent or detect crime or other misuse of our services, and audit
            requirements. To fulfil our requirements, some of your personal data
            will need to be retained for a period of time after you cease to be
            a customer. When we no longer need it to fulfil the above
            requirements, we delete it securely. Where we wish to retain any
            information for analysis purposes, we first anonymise it to the
            standards approved by the UK Information Commissioner&apos;s Office,
            (which, as we are based in the UK, is our lead regulator on matters
            relating to Data protection) so that it can no longer be linked back
            to an individual.{' '}
            <strong>
                Please note that if you opt out of receiving marketing from us,
                we will still need to keep your contact details in order to
                suppress them from future marketing activity
            </strong>
            .
        </p>
        <Styled.SubSectionHeader>
            Privacy Rights: How do I exercise my{' '}
            <Link smooth to="/privacy#your-right-to-erasure">
                &apos;right to be forgotten?&apos;
            </Link>
        </Styled.SubSectionHeader>
        <Styled.SubSectionSubHeader>
            Staying in control of your Personal information: Your Privacy rights
        </Styled.SubSectionSubHeader>
        <p>
            We respect the fact that your personal information is your
            information so we want to be clear about what those rights mean in
            practice and how you can exercise them. Please see{' '}
            <Link smooth to="/privacy#things-we-do-with-your-consent-marketing">
                &apos;how and why we use your data&apos;
            </Link>{' '}
            for more information about the purposes for which we process your
            information and the rights this gives you.
        </p>
        <Styled.SubSectionSubHeader>
            The right to opt out of having your information used for marketing
        </Styled.SubSectionSubHeader>
        <p>
            You can{' '}
            <Link smooth to="/privacy#can-i-opt-out-of-marketing">
                opt out of receiving marketing
            </Link>{' '}
            at any time. You also have the right to opt out of having your
            information used to{' '}
            <Link smooth to="/privacy#things-we-do-with-your-consent-marketing">
                create a &apos;profile&apos; of you for marketing purposes
            </Link>
            .
        </p>
        <p>
            We firmly believe that our customers prefer to receive offers and
            information that are relevant to them so we tailor all of our
            marketing to make it more interesting to our customers. Please read
            the section{' '}
            <Link smooth to="/privacy#keeping-it-relevant">
                &apos;Keeping it relevant and putting you in control&apos;{' '}
            </Link>{' '}
            for more details.
        </p>
        <Styled.SubSectionSubHeader>
            The right to have any inaccuracies in your personal information
            corrected
        </Styled.SubSectionSubHeader>
        <p>
            You can update your personal details at any time via your online Sky
            Betting and Gaming account,{' '}
            <strong>
                but please be aware that if you have an account with Oddschecker
                you will need to update your personal details with them directly
            </strong>
            .
        </p>
        <p>
            Please help us to help you by keeping your contact details up to
            date and letting us know if you spot any errors in the information
            we hold about you. If it is something you cannot correct yourself
            online, you should contact us at{' '}
            <a href="mailto:support@skybettingandgaming.com">
                support@skybettingandgaming.com
            </a>
            . We&apos;ll update inaccuracies promptly, and within a month if you
            are requesting a more complex change. If we take the decision not to
            make a change you have requested, we will explain why and make a
            note on your account to show that you requested the change. If you
            disagree with our decision, you have the right to complain to the{' '}
            <Link smooth to="/privacy#right-to-complain">
                privacy regulator
            </Link>
            .
        </p>
        <Styled.SubSectionSubHeader>
            Your &apos;right of objection&apos; to certain activities
        </Styled.SubSectionSubHeader>
        <Styled.Important>
            Data protection law gives you the right to express an{' '}
            <a href="mailto:dp@skybettingandgaming.com">objection</a> to
            activities detailed in the section entitled{' '}
            <Link smooth to="/privacy#running-our-business">
                &apos;Running our business effectively and efficiently&apos;
            </Link>{' '}
            <strong>
                if you believe your privacy rights outweigh the legitimate
                interest we have as a business in doing those things. Please
                read that section carefully before getting in touch with us and
                note that exercising your right of objection will usually mean
                you need to close your account and stop using our services
            </strong>{' '}
            . If you disagree with our decision on this, you have the right to
            complain to the privacy{' '}
            <Link smooth to="/privacy#right-to-complain">
                regulator
            </Link>{' '}
            .
        </Styled.Important>
        <Styled.SubSectionHeader id="your-right-to-erasure">
            Your Right to Erasure
        </Styled.SubSectionHeader>
        <p>
            People sometimes refer to this as the{' '}
            <strong>&apos;right to be forgotten&apos;</strong>. Under data
            protection law, you have the right to{' '}
            <a href="mailto:dp@skybettingandgaming.com">request erasure</a> of
            your personal data in the following circumstances:
        </p>
        <ul>
            <li>
                Where the courts or our regulators have found us to be
                processing it unlawfully;
            </li>
            <li>
                Where our original purpose for collecting the data has been
                completed and we have no other valid legal grounds for
                continuing to{' '}
                <Link smooth to="/privacy#how-long-do-we-keep-your-information">
                    hold it
                </Link>
                ;
            </li>
            <li>
                Where you have withdrawn your{' '}
                <Link
                    smooth
                    to="/privacy#things-we-do-with-your-consent-marketing"
                >
                    consent to marketing
                </Link>{' '}
                and{' '}
                <Link
                    smooth
                    to="/privacy#things-we-do-with-your-consent-marketing"
                >
                    personalised marketing{' '}
                </Link>
                and asked us to delete the information we previously used for
                those purposes, in which case we will either delete it from our
                marketing systems or fully anonymise it, but note that
                <strong>
                    we will retain your contact details in order to prevent
                    further marketing
                </strong>{' '}
                ;
            </li>
            <li>
                Where you have successfully exercised your &apos;right to
                object&apos; to one of the activities in the section entitled
                <Link smooth to="/privacy#running-our-business">
                    <strong>
                        {' '}
                        &apos;running our business effectively and
                        efficiently&apos;
                    </strong>
                </Link>{' '}
                and asked us to delete the information we used for that purpose.
                As we explain in that section, exercising your right to object
                will usually mean closing your account, and note that in most
                cases we will need to retain your information for{' '}
                <Link smooth to="/privacy#how-long-do-we-keep-your-information">
                    a period of time after your account is closed
                </Link>
                .
                <strong>
                    We will not delete information as long as we still have a
                    valid legal or regulatory reason to hold it, unless the
                    courts or our regulators order us to do so
                </strong>
                .
            </li>
        </ul>
        <Styled.SubSectionSubHeader>
            How do I exercise this right?
        </Styled.SubSectionSubHeader>
        <p>
            Please read the sections entitled{' '}
            <strong>&apos;How long do we keep information&apos;</strong> and
            <strong>&apos;your right to erasure&apos;</strong> carefully before
            contacting us. If you still wish to exercise your right, you should
            contact us at{' '}
            <a href="mailto:dp@skybettingandgaming.com">
                dp@skybettingandgaming.com
            </a>
            dp@skybettingandgaming.com and we will respond to your request
            within a month. If we uphold your request and erase your data we
            will also notify any third parties to which the data has been
            passed, where we are able to do so, and tell you who they are. If we
            do not uphold your request, we will tell you why. If you disagree,
            you have the right to complain to the{' '}
            <Link smooth to="/privacy#right-to-complain">
                privacy regulator
            </Link>
            .
        </p>
        <Styled.SubSectionHeader id="right-to-access-the-data-we-hold-about-you">
            The right to access the data we hold about you
        </Styled.SubSectionHeader>
        <p>
            If you would like a copy of the personal information we hold about
            you, we have an online form available which can be completed
            electronically and emailed to the Data Protection Team at{' '}
            <a href="mailto:sar@skybettingandgaming.com">
                sar@skybettingandgaming.com
            </a>
            .
        </p>
        <p>
            The form is not mandatory however please ensure that the customer
            information required in the form is included with requests made in
            other formats, as well as a copy of ID.
        </p>
        <p>
            To download the form please click{' '}
            <a href="https://skybettingandgaming.my.salesforce.com/sfc/p/0Y0000033a88/a/3z000000R7UO/iCiystBuHXcN5uE1eAiMNHM1UStw7esl9J_1YJyuujE">
                here
            </a>
            .
        </p>
        <p>
            We will provide our response within one month. If your request is
            unusually complex and likely to take longer than a month, we will
            let you know as soon as we can and tell you how long we think it
            will take.
        </p>
        <p>
            We will fulfil requests wherever possible, but there are occasional
            situations in which local or European data protection law requires
            or permits us to withhold some information (such as where it would
            involve disclosing information about another person or information
            which is commercially sensitive), or permits us to make a small
            charge. If either of these applies, we will explain this to you.
        </p>
        <Styled.SubSectionHeader>
            Your Right to &apos;Data Portability&apos;
        </Styled.SubSectionHeader>
        <p>
            The right to &apos;data portability&apos; aims to enable consumers
            to re-use some of their personal information online by making it
            available in a commonly-used, machine-readable format that can be
            passed to and used by other organisations. This is a new initiative
            and it is not yet possible to &apos;port&apos; data directly between
            providers in the betting and gaming industry. However, if you wish
            to exercise this right, you should submit your request{' '}
            <a href="mailto:SAR@skybettingandgaming.com">here</a>, and we will
            provide you with the following information as a CSV file:
        </p>
        <ul>
            <li>
                the personal and contact details held in your online account
            </li>
            <li>your betting and gaming history</li>
            <li>A list of payments made and funds withdrawn</li>
        </ul>
        <p>
            Before responding to your request, we will ask you to provide valid
            proof of identity, and we will provide our response within one month
            of receiving it.
        </p>
        <p>
            In future, it may become possible to transfer (or &apos;port&apos;)
            data directly between organisations. In the meanwhile, if you would
            like to take your SBG data to another provider you should first
            check that your chosen provider is able to upload data from a CSV
            file before making your request as above and passing the file to the
            provider yourself. If you would like to &apos;port&apos; your data
            into SBG from another provider, you should first contact{' '}
            <a href="mailto:SAR@skybettingandgaming.com">
                SAR@skybettingandgaming.com
            </a>{' '}
            to see whether we can accommodate your request. It is unlikely that
            we will be able to do so at the present time, but we will try to
            accommodate requests where we can.
        </p>
        <Styled.SubSectionHeader id="right-to-complain">
            Your Right to Complain to the Regulator
        </Styled.SubSectionHeader>
        <Styled.Important>
            If you believe your privacy rights have been infringed, or you
            disagree with a decision we have made about your privacy rights, you
            have the right to complain to the privacy regulator. As we are based
            in the UK, our principal data protection regulator is the UK&apos;s
            ICO, but if you are in Germany, Italy or any other country in which
            we operate, you may prefer to initiate contact through the regulator
            in your country of residence:
            <ul>
                <li>
                    In the UK, ICO{' '}
                    <a href="https://ico.org.uk"> https://ico.org.uk</a>
                </li>
                <li>
                    In Ireland, Data Protection Commissioner{' '}
                    <a href="https://www.dataprotection.ie/docs/Home/4.htm">
                        https://www.dataprotection.ie/docs/Home/4.htm
                    </a>
                </li>
                <li>
                    In the Isle of Man:{' '}
                    <a href="mailto:ask@inforights.im">ask@inforights.im</a>
                </li>
                <li>
                    In Jersey and Guernsey:{' '}
                    <a href="mailto:enquiries@dataci.org">
                        enquiries@dataci.org
                    </a>
                </li>
                <li>
                    In Gibraltar: <a href="mailto:info@gra.gi">info@gra.gi</a>
                </li>
            </ul>
        </Styled.Important>
        <Styled.SubSectionHeader>
            Links to Third Party Sites
        </Styled.SubSectionHeader>
        <p>
            Sometimes our websites provide links from SBG sites to those of
            third parties. These organisations and sites will have their own
            privacy policies which will not be the same as ours so when you
            visit one of these sites, check that you&apos;re happy with their
            privacy policy before providing them with any personal information.
        </p>
        <Styled.SectionHeader>Keeping your data secure</Styled.SectionHeader>
        <Styled.SubSectionHeader>
            SB&G Security Accreditations
        </Styled.SubSectionHeader>
        <p>
            At SB&G we adhere to ISO27001 principles, we are Cyber Essentials
            Plus accredited and PCI compliant.
        </p>
        <Styled.SubSectionHeader>
            Your privacy and shared computers
        </Styled.SubSectionHeader>
        <p>
            If you log in to one of our websites from a shared computer, cookies
            may cause your e-mail address or username to display in the login
            field to anyone who uses the site on that computer after you. You
            can avoid this by clearing the Cookies stored by the web browser.
            The option to do this is normally in the &apos;Tools&apos; dropdown
            menu at the top of the browser window. If you have opted to stay
            logged in, remember that another person using the same device could
            access your account unless you log out.
        </p>
        <Styled.SubSectionHeader>Passwords and PINs</Styled.SubSectionHeader>
        <p>
            Remember to keep your passwords and PINS secret, choose complex
            passwords, change them periodically and never use the same password
            or PIN on multiple websites.
        </p>
    </Styled.PrivacyPageContainer>
);

export default Privacy;
