import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

const PersonalSeasonStats = ({ totalRoundsEntered, averagePoints }) => (
    <Styled.Container data-test-id="personal-season-stats-widget">
        <Styled.Header>Personal Season Stats</Styled.Header>
        <Styled.Row>
            <Styled.Stats>
                <Styled.Label>
                    Total <br /> Rounds Entered
                </Styled.Label>
                <Styled.Score>{totalRoundsEntered}</Styled.Score>
            </Styled.Stats>
            <Styled.Stats>
                <Styled.Label>
                    Average <br /> Points
                </Styled.Label>
                <Styled.Score>{averagePoints}</Styled.Score>
            </Styled.Stats>
        </Styled.Row>
    </Styled.Container>
);

PersonalSeasonStats.propTypes = {
    totalRoundsEntered: PropTypes.number.isRequired,
    averagePoints: PropTypes.number.isRequired
};
export default PersonalSeasonStats;
