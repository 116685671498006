import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { parse } from 'querystring';
import { connect } from 'react-redux';
import {
    PageContainer,
    PageTitle,
    PageTitleText
} from '../../../components/Common/Page/styles';
import JoinLeague from '../../../components/JoinLeague';
import CreateLeague from '../components/CreateLeague';
import { joinLeague as joinLeagueAction } from '../../../store/modules/league/actions';
import * as Styled from './styles';

const CreateJoin = ({ joinLeague }) => {
    const { search } = useLocation();
    const { code } = parse(search.replace('?', ''));

    return (
        <PageContainer>
            <PageTitle>
                <PageTitleText>Create or join a league</PageTitleText>
            </PageTitle>
            <Styled.Grid>
                <CreateLeague />
                <JoinLeague joinLeague={joinLeague} leagueCode={code} />
            </Styled.Grid>
        </PageContainer>
    );
};

const mapDispatchToProps = dispatch => ({
    joinLeague: slug => dispatch(joinLeagueAction(slug))
});

CreateJoin.propTypes = {
    joinLeague: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(CreateJoin);
