import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectHasPredicted } from '../../../../store/modules/user/selectors';
import {
    selectActiveRoundId,
    selectActiveRoundStatus,
    selectIsRoundPaused
} from '../../../../store/modules/round/selectors';
import { selectIsLoggedIn } from '../../../../store/modules/auth/selectors';
import { requestUsersActiveRoundPredictions } from '../../../../store/modules/user/actions';
import { InfoButton } from './styles';

const renderButton = (text, link, variant, disabled = false) => (
    <InfoButton
        id="play-game-btn-masthead"
        data-test-id={`masthead-game-btn-${variant}`}
        data-target-id={`s6-masthead-cta-${variant}-btn`}
        disabled={disabled}
        internal
        link={link}
    >
        {text}
    </InfoButton>
);

const PlayButton = ({
    roundId,
    isLoggedIn,
    loadUserPredictions,
    roundStatus,
    hasPredicted,
    isPaused
}) => {
    useEffect(() => {
        loadUserPredictions();
    }, [loadUserPredictions, roundId, isLoggedIn]);

    if (!['inplay', 'complete', 'open'].includes(roundStatus)) {
        return null;
    }

    if (roundStatus === 'inplay' || roundStatus === 'complete') {
        return renderButton('View Current Round', '/inplay', 'inplay');
    }

    if (isPaused) {
        return renderButton('Check Back Soon', '/play', 'play', true);
    }

    if (!hasPredicted) {
        return renderButton('Play For Free', '/play', 'play');
    }

    return renderButton('Edit Predictions', '/played', 'play-edit');
};

PlayButton.propTypes = {
    loadUserPredictions: PropTypes.func.isRequired,
    roundId: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    hasPredicted: PropTypes.bool,
    roundStatus: PropTypes.string,
    isPaused: PropTypes.bool
};

PlayButton.defaultProps = {
    roundStatus: null,
    hasPredicted: false,
    isLoggedIn: null,
    roundId: null,
    isPaused: null
};

const mapStateToProps = state => ({
    hasPredicted: selectHasPredicted(state),
    roundStatus: selectActiveRoundStatus(state),
    isLoggedIn: selectIsLoggedIn(state),
    roundId: selectActiveRoundId(state),
    isPaused: selectIsRoundPaused(state)
});

const mapDispatchToProps = dispatch => ({
    loadUserPredictions: () => dispatch(requestUsersActiveRoundPredictions())
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayButton);
