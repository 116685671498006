import { css } from '@emotion/core';

const animations = css`
    @keyframes shimmerBackground {
        0% {
            background-position: 0 -1000px;
        }
        100% {
            background-position: 0 1000px;
        }
    }

    @keyframes pulsate {
        0% {
            -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
            opacity: 0.5;
        }
        50% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
            opacity: 0.5;
        }
    }
`;

export default animations;
