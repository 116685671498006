import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    followees: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.RECEIVE_FOLLOWEES:
            return newIfChanged(state, {
                ...state,
                followees: action.payload
            });
        default:
            return state;
    }
};
