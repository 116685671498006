import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    selectLeague,
    selectLeaguePundits
} from '../../../store/modules/league/selectors';
import {
    updateLeague as updateLeagueAction,
    requestLeaguePundits,
    requestLeague
} from '../../../store/modules/league/actions';
import ConnectedLeagueForm from '../components/LeagueForm';
import NotFound from '../../Error/NotFound';

const EditLeague = ({
    match,
    league,
    getLeague,
    updateLeague,
    leaguePundits,
    getLeaguePundits
}) => {
    const { slug } = match.params;

    useEffect(() => {
        if (!league) {
            getLeague(slug);
        }
    }, [slug, league, getLeague]);

    useEffect(() => {
        if (!leaguePundits) {
            getLeaguePundits(slug);
        }
    }, [slug, leaguePundits, getLeaguePundits]);

    if (league && !league.isFound) {
        return <NotFound />;
    }

    if (!league || !leaguePundits) {
        return null;
    }

    return (
        <ConnectedLeagueForm
            pageTitle="Edit League"
            submitLabel="Save changes"
            subtitle="Edit league name"
            leagueNameText="Enter a new name for your league"
            defaultLeagueName={league ? league.name : ''}
            defaultPundits={leaguePundits.map(pundit => pundit.userId)}
            onSubmit={updateLeague(slug)}
        />
    );
};

EditLeague.propTypes = {
    updateLeague: PropTypes.func.isRequired,
    getLeague: PropTypes.func.isRequired,
    getLeaguePundits: PropTypes.func.isRequired,
    league: PropTypes.shape({
        isFound: PropTypes.bool.isRequired,
        slug: PropTypes.string.isRequired,
        name: PropTypes.string,
        memberCount: PropTypes.number,
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        isChairperson: PropTypes.bool
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            slug: PropTypes.string,
            period: PropTypes.string,
            periodId: PropTypes.string
        })
    }).isRequired,
    leaguePundits: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            userId: PropTypes.number.isRequired
        })
    )
};

EditLeague.defaultProps = {
    league: null,
    leaguePundits: null
};

const mapStateToProps = (state, props) => ({
    league: selectLeague(state, props.match.params.slug),
    leaguePundits: selectLeaguePundits(state, props.match.params.slug)
});

const mapDispatchToProps = dispatch => ({
    getLeague: slug => dispatch(requestLeague(slug)),
    updateLeague: slug => (name, pundits) =>
        dispatch(updateLeagueAction(slug, name, pundits)),
    getLeaguePundits: slug => dispatch(requestLeaguePundits(slug))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditLeague));
