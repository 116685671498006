import { call, takeEvery, select } from 'redux-saga/effects';

import { sha256 } from '../../../helpers/hash';
import * as types from './types';
import * as userSelectors from '../user/selectors';
import * as signalApi from '../../../api/signal';
import { getItem, setItem } from '../../../helpers/sessionStorage';

export function* recordBetClick(action) {
    const customerId = yield select(userSelectors.selectCustomerId);
    const idHash = yield call(sha256, customerId);

    yield call(signalApi.recordBetClick, action.betType, idHash);
}

export function* recordEnterRound(action) {
    const customerId = yield select(userSelectors.selectCustomerId);
    const idHash = yield call(sha256, customerId);

    yield call(signalApi.recordEnterRound, action.roundNumber, idHash);
}

export function* recordLogin(action) {
    if (action.isSso) {
        const token = getItem('sso_logged_in');
        if (token) {
            return;
        }
        setItem('sso_logged_in', true);
    }
    const customerId =
        action.data && action.data.customerId ? action.data.customerId : null;
    if (!customerId) {
        return;
    }

    const idHash = yield call(sha256, customerId);

    yield call(signalApi.recordLogin, idHash);
}

export function* recordPageLoad(action) {
    const customerId = yield select(userSelectors.selectCustomerId);

    if (!customerId) {
        yield call(signalApi.recordPageLoad, null, action.data);

        return;
    }

    const idHash = yield call(sha256, customerId);

    yield call(signalApi.recordPageLoad, idHash, action.data);
}

export function* watchRecordBetClick() {
    yield takeEvery(types.SIGNAL_RECORD_BET_CLICK, recordBetClick);
}

export function* watchRecordEnterRound() {
    yield takeEvery(types.SIGNAL_RECORD_ENTER_ROUND, recordEnterRound);
}

export function* watchRecordLogin() {
    yield takeEvery(types.SIGNAL_RECORD_LOGIN, recordLogin);
}

export function* watchRecordPageLoad() {
    yield takeEvery(types.SIGNAL_RECORD_PAGE_LOAD, recordPageLoad);
}

export const sagas = [
    watchRecordBetClick,
    watchRecordEnterRound,
    watchRecordLogin,
    watchRecordPageLoad
];
