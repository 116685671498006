import React from 'react';
import PropTypes from 'prop-types';
import StyledContainer from './styles';

const MessageBlock = ({ children, id, type }) => (
    <StyledContainer type={type} id={id} data-test-id="message-block">
        {children}
    </StyledContainer>
);

MessageBlock.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.string.isRequired,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.element.isRequired,
                PropTypes.string.isRequired
            ])
        )
    ]).isRequired,
    id: PropTypes.string,
    type: PropTypes.string
};

MessageBlock.defaultProps = {
    id: 'message-block',
    type: 'success'
};

export default MessageBlock;
