import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from '../../../../../components/Common/Button/styles';
import { getSelectedOutcomes } from '../../../../../helpers/accumulator';
import {
    buildAccaDcmp,
    affIdMap,
    dcmpPrefixMap,
    generateBetslipLink
} from '../../../../../helpers/skyBetLinkHelper';
import AppLink from '../../../../../components/Common/AppLink';
import { recordBetClick } from '../../../../../store/modules/signal/actions';
import buildCookieUrl from '../../../../../helpers/buildCookieUrl';
import { useCookieConsentContext } from '../../../../../hooks/useCookieConsents';
import { OAUTH_URI } from '../../../../../config';
import eventContext from '../../../../../helpers/GoogleAnalytics/event_context';

const BetButton = ({
    market,
    selections,
    marketOutcomes,
    fullyRegistered,
    recordAccaClick,
    setNavigationEvent
}) => {
    const outcomes = getSelectedOutcomes(selections, marketOutcomes);

    const dcmp = buildAccaDcmp({
        prefix: dcmpPrefixMap.betButton,
        isPartReg: !fullyRegistered,
        market,
        selections: outcomes
    });
    const link = generateBetslipLink({
        affId: affIdMap.standard,
        dcmp,
        selections: outcomes
    });

    const { cookieConsentQuery } = useCookieConsentContext();

    const buttonElements = fullyRegistered
        ? {
              link: buildCookieUrl(OAUTH_URI, link, cookieConsentQuery),
              text: 'Bet with Sky Bet'
          }
        : {
              link: `https://m.skybet.com/lp/ftp-20-completely-free-S6?sba_promo=FTP10FREE&open=upgradereg&dcmp=s6ih-accaacquioffer2-entryconf`,
              text: `Claim your £20 free bet`
          };

    return (
        <Styled.BetButtonWrapper
            id="btn-acca-bet"
            onClick={() => {
                setNavigationEvent(
                    buttonElements.text,
                    1,
                    buttonElements.link,
                    eventContext.skyBetReferral
                );
                recordAccaClick();
            }}
            data-test-id="acca-bet-btn"
        >
            <AppLink app="skybet" link={buttonElements.link}>
                {buttonElements.text}
            </AppLink>
        </Styled.BetButtonWrapper>
    );
};

BetButton.propTypes = {
    market: PropTypes.string.isRequired,
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired,
            selection: PropTypes.string.isRequired
        })
    ).isRequired,
    marketOutcomes: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired
        })
    ).isRequired,
    fullyRegistered: PropTypes.bool,
    recordAccaClick: PropTypes.func.isRequired,
    setNavigationEvent: PropTypes.any
};

BetButton.defaultProps = {
    fullyRegistered: false,
    setNavigationEvent: () => {}
};

const mapStateToProps = state => ({
    fullyRegistered: state.user.info.fullyRegistered
});

const mapDispatchToProps = dispatch => ({
    recordAccaClick: () => dispatch(recordBetClick('acca'))
});

export default connect(mapStateToProps, mapDispatchToProps)(BetButton);
