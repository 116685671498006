import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';
import { sendNavigationEvent } from '../../helpers/GoogleAnalytics/gaHelper';
import eventContext from '../../helpers/GoogleAnalytics/event_context';
import modules from '../../helpers/GoogleAnalytics/modules';

export default function index({ joinLeague, leagueCode }) {
    const leagueCodeRef = useRef(null);

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.leagues.join,
            2,
            text,
            position,
            destinationUrl,
            context
        );
    };

    return (
        <Styled.JoinLeagueContainer>
            <Styled.TextGrid>
                <Styled.PlusIconCircle>
                    <Styled.PlusIcon />
                </Styled.PlusIconCircle>
                <Styled.TextContainer>
                    <Styled.HeaderText>Join a league</Styled.HeaderText>
                    <Styled.BodyText>
                        Enter the 6 digit pin below to enter that league
                    </Styled.BodyText>
                </Styled.TextContainer>
            </Styled.TextGrid>
            <Styled.InputContainer>
                <Styled.InputField
                    ref={leagueCodeRef}
                    data-test-id="join-league-input"
                    defaultValue={leagueCode}
                />
                <Styled.InputButton
                    data-test-id="join-league-button"
                    onClick={() => {
                        setNavigationEvent(
                            'Join',
                            1,
                            `https://super6.skysports.com/league/${leagueCodeRef.current.value}/season`,
                            eventContext.leagueJoined
                        );
                        joinLeague(leagueCodeRef.current.value);
                    }}
                >
                    JOIN
                </Styled.InputButton>
            </Styled.InputContainer>
        </Styled.JoinLeagueContainer>
    );
}

index.propTypes = {
    joinLeague: PropTypes.func.isRequired,
    leagueCode: PropTypes.string
};

index.defaultValue = {
    leagueCode: undefined
};
