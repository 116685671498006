export const calculateAccaDecimal = outcomes =>
    outcomes.reduce((result, outcome) => {
        const [num, den] = outcome.fraction.split('/', 2);
        return result * (1 + parseInt(num, 10) / parseInt(den, 10));
    }, 1);

export const calculateAccaFraction = (outcomes, decimal) => {
    if (outcomes.length === 1) {
        return outcomes[0].fraction;
    }

    const numerator = Math.floor(decimal - 1);

    /**
     * NOTE: It's very rare, but in some cases the numerator can be 0 here
     * the php site just renders 0/1, and the logic to try and provide a better
     * fraction is relatively complicated. I think it's too rare to need to
     * worry about it. It would only occur if a 2 or more fold accumulator would
     * have odds of less than 1/1
     */
    return `${numerator}/1`;
};

export const calculateBetReturn = (stake, decimal) => {
    if (!parseFloat(stake, 10)) {
        return 0;
    }
    return Math.min(500000, stake * decimal);
};

export const fullTimeResult = 'full-time-result';
export const bothTeamsToScore = 'both-teams-to-score';
export const underOverGoals = 'under-over-goals';
const marketConfig = {
    [fullTimeResult]: {
        outcomes: ['Home', 'Draw', 'Away']
    },
    [underOverGoals]: {
        outcomes: ['Under', 'Over']
    },
    [bothTeamsToScore]: {
        outcomes: ['Yes', 'No']
    }
};

export const getSelectedOutcomes = (selections, marketOutcomes) =>
    selections
        .filter(selection =>
            marketOutcomes.find(
                marketOutcome => marketOutcome.match === selection.match
            )
        )
        .map(({ match, selection }) => {
            const marketOutcome = marketOutcomes.find(
                item => item.match === match
            );
            return marketOutcome[selection.toLowerCase()];
        });

export const getMarketConfig = market => marketConfig[market];

export const setDefaultSelections = (fixtures, predictions) => {
    const defaultSelections = {
        [fullTimeResult]: [],
        [bothTeamsToScore]: [],
        [underOverGoals]: []
    };

    fixtures.forEach(fixture => {
        const prediction = predictions[fixture.match.id];
        if (prediction.scoreHome === prediction.scoreAway) {
            defaultSelections[fullTimeResult].push({
                match: parseInt(fixture.match.id, 10),
                selection: 'draw'
            });
        } else {
            defaultSelections[fullTimeResult].push({
                match: parseInt(fixture.match.id, 10),
                selection:
                    prediction.scoreHome > prediction.scoreAway
                        ? 'home'
                        : 'away'
            });
        }

        defaultSelections[underOverGoals].push({
            match: parseInt(fixture.match.id, 10),
            selection:
                prediction.scoreAway + prediction.scoreHome >= 3
                    ? 'over'
                    : 'under'
        });
        defaultSelections[bothTeamsToScore].push({
            match: parseInt(fixture.match.id, 10),
            selection:
                prediction.scoreAway > 0 && prediction.scoreHome > 0
                    ? 'yes'
                    : 'no'
        });
    });

    return defaultSelections;
};

export const onChangeSelection = (
    market,
    selections,
    setSelections
) => matchId => selection => {
    const otherMatchSelections = selections[market].filter(
        item => item.match !== matchId
    );

    if (selection === null) {
        setSelections({
            ...selections,
            [market]: otherMatchSelections
        });
        return;
    }

    setSelections({
        ...selections,
        [market]: [
            ...otherMatchSelections,
            {
                match: matchId,
                selection
            }
        ]
    });
};
