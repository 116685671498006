import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { selectAsset } from '../../../../store/modules/round/selectors';
import { requestActiveRound } from '../../../../store/modules/round/actions';

const MastheadPrizeImage = ({ loadActiveRound, prize3x, prize2x, prize1x }) => {
    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    if (!prize2x) {
        return null;
    }

    return (
        <Styled.PrizePicture>
            <source
                srcSet={`
                    ${prize3x} 3x,
                    ${prize2x} 2x,
                    ${prize1x} 1x
                `}
            />
            <Styled.PrizeImage src={prize2x} alt="Prize" />
        </Styled.PrizePicture>
    );
};

const mapStateToProps = state => ({
    prize3x: selectAsset(state, 'prizeImageset', '3x'),
    prize2x: selectAsset(state, 'prizeImageset', '2x'),
    prize1x: selectAsset(state, 'prizeImageset', '1x')
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound())
});

MastheadPrizeImage.propTypes = {
    prize3x: PropTypes.string,
    prize2x: PropTypes.string,
    prize1x: PropTypes.string,
    loadActiveRound: PropTypes.func.isRequired
};

MastheadPrizeImage.defaultProps = {
    prize3x: null,
    prize2x: null,
    prize1x: null
};

export default connect(mapStateToProps, mapDispatchToProps)(MastheadPrizeImage);
