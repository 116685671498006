import React from 'react';
import PropTypes from 'prop-types';
import Team from '../../../../components/Team';
import * as Styled from './styles';
import ConnectedMatchPredictionController from './MatchPredictionController';

function getDisabledPlaceholder(status) {
    if (status === 'Postponed') {
        return 'P';
    }
    if (status === 'Abandoned') {
        return 'A';
    }
    return null;
}

const MatchPrediction = ({
    homeTeam,
    homeScore,
    awayTeam,
    awayScore,
    status,
    matchId
}) => {
    const disabledPlaceholder = getDisabledPlaceholder(status);
    const homeTeamIdentifier = `${matchId}-team-home`;
    const awayTeamIdentifier = `${matchId}-team-away`;

    return (
        <Styled.MatchPrediction>
            <Team
                team={homeTeam}
                mobWidth="25%"
                id={homeTeamIdentifier}
                uppercase
            />
            <Styled.MatchPredictionContainer>
                <ConnectedMatchPredictionController
                    matchId={matchId}
                    team="home"
                    score={homeScore}
                    disabledPlaceholder={disabledPlaceholder}
                    teamIdentifier={homeTeamIdentifier}
                />
                <Styled.MatchVs>v</Styled.MatchVs>
                <ConnectedMatchPredictionController
                    matchId={matchId}
                    team="away"
                    score={awayScore}
                    disabledPlaceholder={disabledPlaceholder}
                    teamIdentifier={awayTeamIdentifier}
                />
            </Styled.MatchPredictionContainer>
            <Team
                team={awayTeam}
                mobWidth="25%"
                id={awayTeamIdentifier}
                uppercase
            />
        </Styled.MatchPrediction>
    );
};

MatchPrediction.defaultProps = {
    homeScore: 0,
    awayScore: 0
};

MatchPrediction.propTypes = {
    homeTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    homeScore: PropTypes.number,
    awayTeam: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    awayScore: PropTypes.number,
    status: PropTypes.string.isRequired,
    matchId: PropTypes.number.isRequired
};

export default MatchPrediction;
