import * as types from './types';
import { ACTIVE_ROUND_STATE_CHANGE, ACTIVE_ROUND_CHANGE } from '../round/types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    league: null,
    active: null,
    previous: null,
    pundits: null,
    myScores: [],
    results: null,
    leaguePundits: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ACTIVE_ROUND_STATE_CHANGE:
        case ACTIVE_ROUND_CHANGE:
            return newIfChanged(state, {
                ...state,
                results: null,
                myScores: []
            });
        case types.RECEIVE_LEAGUES:
            return newIfChanged(state, {
                ...state,
                active: action.payload.activeLeagues.map(league => ({
                    name: league.name,
                    slug: league.slug,
                    memberCount: league.memberCount,
                    isManaged: league.isManaged,
                    iconUrl: league.iconUrl
                })),
                previous: action.payload.previousLeagues
                    .filter(league => league.isChairperson)
                    .map(league => ({
                        name: league.name,
                        slug: league.slug,
                        memberCount: league.memberCount,
                        isManaged: league.isManaged,
                        iconUrl: league.iconUrl
                    }))
            });
        case types.RECEIVE_LEAGUE:
            return newIfChanged(state, {
                ...state,
                league: {
                    ...action.payload,
                    isFound: true
                }
            });
        case types.LEAGUE_NOT_FOUND:
            return newIfChanged(state, {
                ...state,
                league: {
                    slug: action.slug,
                    isFound: false
                }
            });
        case types.RECEIVE_PUNDITS:
            return newIfChanged(state, {
                ...state,
                pundits: action.payload.map(pundit => ({
                    id: pundit.id,
                    userId: pundit.userId,
                    name: pundit.name,
                    imageUri: pundit.imageUri
                }))
            });
        case types.POST_LEAGUE_SUCCESS:
            /**
             * Here we push the newly created league on to the active leagues
             * list, but only if the list has already been loaded, otherwise we
             * need to keep it NULL so that it will be fully loaded when
             * required on the leagues page
             */
            return newIfChanged(state, {
                ...state,
                active: state.active
                    ? [
                          ...state.active,
                          {
                              name: action.payload.name,
                              slug: action.payload.slug,
                              memberCount: action.payload.memberCount,
                              iconUrl: action.payload.iconUrl
                          }
                      ]
                    : null
            });
        case types.POST_LEAGUE_JOIN_SUCCESS:
            /**
             * Here we reset the active and previous list, as they will need
             * refreshing
             */
            return newIfChanged(state, {
                ...state,
                active: null,
                previous: null
            });
        case types.RECEIVE_MY_SCORE:
            return newIfChanged(state, {
                ...state,
                myScores: [
                    ...state.myScores,
                    {
                        ...action.payload,
                        slug: action.slug,
                        period: action.period,
                        periodId: action.periodId
                    }
                ]
            });
        case types.RECEIVE_LEAGUE_RESULTS:
            return newIfChanged(state, {
                ...state,
                results: {
                    slug: action.slug,
                    period: action.period,
                    periodId: action.periodId,
                    page: action.page,
                    results:
                        action.page > 1
                            ? [...state.results.results, ...action.payload]
                            : action.payload
                }
            });
        case types.DELETE_LEAGUE_SUCCESS:
            /**
             * Remove the deleted league from the list, so it's not shown on
             * the league summary page
             */
            return newIfChanged(state, {
                ...state,
                active: state.active
                    ? state.active.filter(league => league.slug !== action.slug)
                    : null,
                previous: state.previous
                    ? state.previous.filter(
                          league => league.slug !== action.slug
                      )
                    : null
            });
        case types.REMOVE_MEMBER_SUCCESS:
            /**
             * Here we need to decrement the member count of the league, as we
             * may be down to a single member now.
             *
             * We set the results to null, because it's not as simple as just
             * manually removing the removed users results, because all of the
             * positions will have shifted too, so we might as well reload them
             */
            return newIfChanged(state, {
                ...state,
                league: {
                    ...state.league,
                    memberCount: state.league.memberCount - 1
                },
                results: null
            });
        case types.RECEIVE_LEAGUE_PUNDITS:
            return newIfChanged(state, {
                ...state,
                leaguePundits: {
                    slug: action.slug,
                    pundits: action.payload
                }
            });
        case types.CLEAR_LEAGUE_STATE:
            return newIfChanged(state, {
                ...defaultState
            });
        case types.RESTART_LEAGUE_SUCCESS:
            return newIfChanged(state, {
                ...state,
                active: null,
                previous: null
            });
        default:
            return state;
    }
};
