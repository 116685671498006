import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';
import { PageContainer } from '../../components/Common/Page/styles';
import { requestLeagues } from '../../store/modules/league/actions';
import {
    getActiveLeagues,
    getPreviousLeagues
} from '../../store/modules/league/selectors';
import { selectActiveRoundStatus } from '../../store/modules/round/selectors';
import leagueProp from './propTypes';
import LeagueList from './components/LeagueList';
import ConnectedGlobalBanner from '../../components/Offer/GlobalBanner';
import Button from '../../components/Common/Button';

export const League = ({
    activeLeagues,
    previousLeagues,
    getLeagues,
    activeRoundStatus
}) => {
    useEffect(() => {
        if (activeLeagues == null || previousLeagues == null) {
            getLeagues();
        }
    }, [activeLeagues, previousLeagues, getLeagues]);

    if (activeLeagues == null || previousLeagues == null) {
        return null;
    }

    if (activeLeagues.length < 1 && previousLeagues.length < 1) {
        return <Redirect to="/league/create-join" />;
    }

    const isInPlay = activeRoundStatus === 'inplay';

    return (
        <PageContainer>
            <PageTitle>My Leagues</PageTitle>
            <Styled.Page>
                <Styled.CreateJoinContainer>
                    <Styled.CreateJoinControlContainer>
                        <Button
                            internal
                            link="/league/create-join"
                            data-test-id="create-or-join-league-button"
                        >
                            Create or Join a League
                        </Button>
                    </Styled.CreateJoinControlContainer>
                </Styled.CreateJoinContainer>
                {activeLeagues && activeLeagues.length > 0 && (
                    <LeagueList
                        intro="Play against your friends in multiple Super 6 Leagues."
                        list={activeLeagues}
                        isInPlay={isInPlay}
                    />
                )}
                {previousLeagues && previousLeagues.length > 0 && (
                    <LeagueList
                        intro="Past Leagues"
                        list={previousLeagues}
                        isInPlay={isInPlay}
                        isPrevious
                    />
                )}
                <ConnectedGlobalBanner />
            </Styled.Page>
        </PageContainer>
    );
};

League.propTypes = {
    activeRoundStatus: PropTypes.string.isRequired,
    activeLeagues: PropTypes.arrayOf(leagueProp),
    previousLeagues: PropTypes.arrayOf(leagueProp),
    getLeagues: PropTypes.func.isRequired
};

League.defaultProps = {
    activeLeagues: null,
    previousLeagues: null
};

const mapStateToProps = state => ({
    activeLeagues: getActiveLeagues(state),
    previousLeagues: getPreviousLeagues(state),
    activeRoundStatus: selectActiveRoundStatus(state)
});

const mapDispatchToProps = dispatch => ({
    getLeagues: () => dispatch(requestLeagues())
});

export default connect(mapStateToProps, mapDispatchToProps)(League);
