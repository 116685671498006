import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Page = styled.div`
    padding: 0 ${props => props.theme.spacing.medium};

    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        padding: 0;
    }
`;

export const PageContainer = styled.div`
    padding: 0 ${props => props.theme.spacing.large};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: 0;
    }
`;

export const CreateJoinButton = styled(Link)`
    float: right;
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large};
    margin-top: 13px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    color: ${props =>
        props.theme.colours?.colorOnPrimary ?? props.theme.colours.white};
    background-color: ${props => props.theme.colours.greenPrimary};
    font-family: ${props => props.theme.fonts.medium};
    text-align: center;
    border-radius: ${props =>
        props.theme?.button?.primary?.borderRadius ?? '5px'};
    text-decoration: none;
    text-transform: ${props => props.theme?.button?.textTransform};
    letter-spacing: ${props => props.theme?.button?.letterSpacing};
    font-weight: ${props => props.theme?.button?.fontWeight};

    :after {
        font-family: ${props => props.theme.fonts.medium};
        margin-left: ${props => props.theme.spacing.normal};
        content: '+';
    }

    :hover {
        background-color: ${props =>
            props.theme?.button?.primary?.onHoverBgColour ??
            props.theme.colours.greenDark};
        cursor: pointer;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        font-size: ${props => props.theme.fontSize.normal};
        padding: ${props => props.theme.spacing.medium};
        margin: ${props => props.theme.spacing.normal};
    }
`;

export const Clearfix = styled.div`
    clear: both;
`;

export const Input = styled.input`
    width: 288px;
    flex: 1 1 auto;
    height: 48px;
    display: inline-block;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    border-radius: 5px;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 48px;
    font-family: ${props => props.theme.fonts.medium};
    padding: ${props => props.theme.spacing.normal};
    box-sizing: border-box;
    margin-bottom: ${props => props.theme.spacing.large};
`;

export const PaddedContainer = styled.div`
    padding: ${props => `0 ${props.theme.spacing.large}
        ${props.theme.spacing.large}`};
`;

export const SubHeader = styled.h2`
    background-color: ${props =>
        props.theme?.subHeader?.backgroundColour ??
        props.theme.colours.backgroundGrey};
    padding: ${props => props.theme.spacing.large};
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colours.black};
    line-height: ${props => props.theme.lineHeight.normal};
    margin-bottom: ${props => props.theme.spacing.normal};
`;

export const CreateJoinContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const CreateJoinControlContainer = styled.div`
    margin-left: ${props => props.theme.spacing.normal};
    margin-top: ${props => props.theme.spacing.normal};
    max-width: 280px;
    width: 100%;

    a {
        padding: ${props => props.theme.spacing.large};
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        max-width: 200px;

        a {
            font-size: ${props => props.theme.fontSize.small};
        }
    }
`;
