import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const TextInBox = ({ text, variant }) => (
    <Styled.TextContainer variant={variant}>
        <Styled.Text>{text}</Styled.Text>
    </Styled.TextContainer>
);

TextInBox.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['red', 'blue', 'green'])
};

TextInBox.defaultProps = {
    variant: 'blue'
};

export default TextInBox;
