import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Match from '../Match/index';
import { selectActiveRoundScoreChallenges } from '../../../../store/modules/round/selectors';

const RoundFixtures = ({
    fixtures,
    predictions,
    isDesktop,
    setNavigationEvent,
    setInterfaceEvent
}) => {
    const defaultShowing = {
        showStats: false,
        showPundits: false,
        showPopular: false,
        showBets: false
    };

    const [bottomPanels, setPanelsShowing] = useState(
        fixtures.reduce((stateObj, fixture, index) => {
            const partnerFixture =
                index % 2 === 0 ? fixtures[index + 1] : fixtures[index - 1];
            return {
                ...stateObj,
                [fixture.match.id]: {
                    ...defaultShowing,
                    partner: partnerFixture && partnerFixture.match.id
                }
            };
        }, {})
    );

    const togglePanelShowing = (matchId, panel) => () => {
        const { partner } = bottomPanels[matchId];

        const togglePanel = originalObject => ({
            ...originalObject,
            ...defaultShowing,
            [panel]: !bottomPanels[matchId][panel]
        });

        if (!partner || !isDesktop) {
            return setPanelsShowing({
                ...bottomPanels,
                [matchId]: togglePanel(bottomPanels[matchId])
            });
        }
        return setPanelsShowing({
            ...bottomPanels,
            [matchId]: togglePanel(bottomPanels[matchId]),
            [partner]: togglePanel(bottomPanels[partner])
        });
    };
    return fixtures.map((fixture, index) => (
        <Match
            key={fixture.id}
            competitionName={fixture.match.competitionName}
            kickOffDateTime={fixture.match.kickOffDateTime}
            homeTeam={fixture.match.homeTeam}
            homeScore={predictions[fixture.match.id].scoreHome}
            awayTeam={fixture.match.awayTeam}
            awayScore={predictions[fixture.match.id].scoreAway}
            status={fixture.match.status}
            matchId={fixture.match.id}
            eventId={fixture.match.eventId}
            showStats={bottomPanels[fixture.match.id].showStats}
            toggleStats={togglePanelShowing(fixture.match.id, 'showStats')}
            showPundits={bottomPanels[fixture.match.id].showPundits}
            togglePundits={togglePanelShowing(fixture.match.id, 'showPundits')}
            showPopular={bottomPanels[fixture.match.id].showPopular}
            togglePopular={togglePanelShowing(fixture.match.id, 'showPopular')}
            showBets={bottomPanels[fixture.match.id].showBets}
            toggleBets={togglePanelShowing(fixture.match.id, 'showBets')}
            isMobile={!isDesktop}
            index={index}
            setNavigationEvent={setNavigationEvent}
            setInterfaceEvent={setInterfaceEvent}
        />
    ));
};

RoundFixtures.propTypes = {
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                competitionName: PropTypes.string,
                kickOffDateTime: PropTypes.string,
                homeTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                })
            })
        })
    ).isRequired,
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired,
    isDesktop: PropTypes.bool.isRequired,
    setNavigationEvent: PropTypes.any,
    setInterfaceEvent: PropTypes.any
};

RoundFixtures.defaultProps = {
    setNavigationEvent: () => {},
    setInterfaceEvent: () => {}
};

const mapStateToProps = state => ({
    isDesktop: state.user.isOnDesktop,
    predictions: state.play.predictions,
    fixtures: selectActiveRoundScoreChallenges(state)
});

export default connect(mapStateToProps)(RoundFixtures);
