/* eslint-disable import/no-unused-modules */
const activeRoundStates = ['open', 'future', 'inplay', 'complete'];

const isActiveRound = roundStatus => {
    if (typeof roundStatus !== 'string') {
        throw new Error('Invalid type for round status');
    }

    return activeRoundStates.includes(roundStatus);
};

export { isActiveRound as default };
