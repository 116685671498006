import React from 'react';
import PropTypes from 'prop-types';
import FullHeroTileComponent from '../../../../components/HeroTile';
import CondensedHeroTileComponent from './components/CondensedHeroTile';
import ValidateMatchIndex from '../../../../components/Teams/validation/validateMatchIndex';
import ValidatePosition from '../../../../components/Teams/validation/validatePosition';
import { useIsMobile } from './helpers';
import * as Styled from './styles';

const RoundHeroTile = ({
    round: {
        roundType,
        heroImageDesktop,
        heroImageMobile,
        prizeImageDesktop,
        prizeImageMobile,
        roundInfoText,
        link,
        linkText,
        logoPath,
        id
    },
    multi,
    isMobile,
    fixtures
}) => {
    const Component =
        multi || isMobile ? CondensedHeroTileComponent : FullHeroTileComponent;
    return (
        <Component
            variant={roundType === 'super6extra' ? 'extra' : 'default'}
            heroImagePaths={{
                desktop:
                    multi || isMobile
                        ? heroImageDesktop
                        : heroImageDesktop['2x'],
                mobile:
                    multi || isMobile ? heroImageMobile : heroImageMobile['2x']
            }}
            prizeImagePaths={{
                desktop:
                    multi || isMobile
                        ? prizeImageDesktop
                        : prizeImageDesktop['2x'],
                mobile:
                    multi || isMobile
                        ? prizeImageMobile
                        : prizeImageMobile['2x']
            }}
            predictionClosedDate={roundInfoText}
            link={link}
            buttonText={linkText}
            logoPath={logoPath}
            roundId={id}
            fixtures={fixtures}
        />
    );
};

// Generate key for react to be happy with the pages since it'll be an array of elements
const carouselPageKey = rounds =>
    rounds.reduce((key, round) => `${key}-${round.id}`, '');

// Used to space out non-full carousel pages
const dummyHeroes = (pageNumber, amount) => {
    const heroes = [];
    for (let i = 0; i < amount; i++) {
        heroes.push(<Styled.DummyHeroTile key={`dummy-${pageNumber}-${i}`} />);
    }
    return heroes;
};

// Chunk the rounds into pages of given size
const carouselPages = (rounds, perPage = 3) =>
    Array.from({ length: Math.ceil(rounds.length / perPage) }, (_, i) =>
        rounds.slice(i * perPage, i * perPage + perPage)
    ).map((r, pageNum) => ({
        rounds: r,
        dummies: dummyHeroes(pageNum, perPage - r.length),
        key: carouselPageKey(r)
    }));

// Render the nav pips and arrows
const renderNavPip = (numPages, showArrows = true) => (
    onClick,
    highlighted,
    index
) => {
    const pips = [];
    // If it's the first pip, prepend the prev arrow if enabled
    if (index === 0 && showArrows) {
        pips.push(
            <Styled.Arrow key="prevArrow">
                <Styled.ArrowBody onClick={onClick} hasNext={!highlighted}>
                    {'\uf024' /* left chevron in the skycons font */}
                </Styled.ArrowBody>
            </Styled.Arrow>
        );
    }

    pips.push(
        <Styled.Pip
            onClick={onClick}
            highlighted={highlighted}
            key={`pip-${index}`}
        />
    );

    // If it's the first pip, append the next arrow if enabled
    if (index === numPages - 1 && showArrows) {
        pips.push(
            <Styled.Arrow key="nextArrow">
                <Styled.ArrowBody onClick={onClick} hasNext={!highlighted}>
                    {'\uf025' /* right chevron in the skycons font */}
                </Styled.ArrowBody>
            </Styled.Arrow>
        );
    }
    return <>{pips}</>;
};

const Loading = () => {
    const isMobile = useIsMobile();
    const numPlaceholders = isMobile ? 1 : 2;

    return (
        <Styled.LoadingContainer>
            {Array.from({ length: numPlaceholders }).map((_, i) => (
                <Styled.LoadingPlaceholderContainer
                    key={`loadingPlaceholder-${i}`} // eslint-disable-line react/no-array-index-key
                >
                    <Styled.LoadingPlaceholderCard isMobile={isMobile} />
                    <Styled.LoadingPlaceholderButton />
                    <Styled.LoadingPlaceholderText />
                </Styled.LoadingPlaceholderContainer>
            ))}
        </Styled.LoadingContainer>
    );
};

const RoundCarousel = ({ rounds, selectedPage, isLoading, fixtures }) => {
    if (isLoading) {
        return <Loading />;
    }

    if (!rounds) {
        return null;
    }

    const isMobile = useIsMobile();

    let perPage = 3;

    if (isMobile || rounds.length === 1) {
        perPage = 1;
    } else if (rounds.length === 2) {
        perPage = 2;
    }

    const pages = carouselPages(rounds, perPage);

    // 1 round desktop: Show full hero tile
    // 2 rounds desktop: Show condensed hero tiles
    // 3 rounds desktop: Show condensed hero tiles
    // 4+ rounds desktop: Show carousel with 3 condensed hero tiles per page, no peek of next page, with arrows
    // 1 round mobile: Show condensed hero tile
    // 2+ rounds mobile: Show carousel with 1 condensed hero tile per page, with peek of next/prev (center mode), no arrows
    return (
        <Styled.RoundsCarousel
            selectedItem={selectedPage - 1}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={pages.length > 1}
            renderIndicator={renderNavPip(pages.length, !isMobile)}
            emulateTouch
            swipeable
            autoPlay={false}
            interval={9999999} // Workaround for issue https://github.com/leandrowd/react-responsive-carousel/issues/621
            isMobile={isMobile}
            centerMode={isMobile}
            centerSlidePercentage={90}
        >
            {pages.map(page => (
                <Styled.CarouselPage
                    key={page.key}
                    multi={pages.length > 1}
                    isMobile={isMobile}
                >
                    {page.rounds.map((round, index) => (
                        <RoundHeroTile
                            round={round}
                            multi={rounds.length > 1}
                            key={`${round.roundType}-${round.id}`}
                            isMobile={isMobile}
                            index={index}
                            fixtures={fixtures}
                        />
                    ))}
                    {page.dummies}
                </Styled.CarouselPage>
            ))}
        </Styled.RoundsCarousel>
    );
};

const RoundPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    roundType: PropTypes.string.isRequired,
    heroImageDesktop: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    heroImageMobile: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    prizeImageDesktop: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    prizeImageMobile: PropTypes.shape({
        '1x': PropTypes.string,
        '2x': PropTypes.string,
        '3x': PropTypes.string
    }).isRequired,
    roundInfoText: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkText: PropTypes.string.isRequired,
    logoPath: PropTypes.string.isRequired
});

RoundHeroTile.propTypes = {
    round: RoundPropType.isRequired,
    multi: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            matchIndex: ValidateMatchIndex,
            borderRadius: PropTypes.shape({
                topLeft: PropTypes.string,
                topRight: PropTypes.string,
                bottomRight: PropTypes.string,
                bottomLeft: PropTypes.string
            }),
            position: ValidatePosition,
            homeTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired,
            awayTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired
};

RoundCarousel.propTypes = {
    rounds: PropTypes.arrayOf(RoundPropType),
    selectedPage: PropTypes.number,
    isLoading: PropTypes.bool,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            matchIndex: ValidateMatchIndex,
            borderRadius: PropTypes.shape({
                topLeft: PropTypes.string,
                topRight: PropTypes.string,
                bottomRight: PropTypes.string,
                bottomLeft: PropTypes.string
            }),
            position: ValidatePosition,
            homeTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired,
            awayTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired
};

RoundCarousel.defaultProps = {
    rounds: [],
    selectedPage: 1,
    isLoading: false
};

export default RoundCarousel;
