import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResultMastheadContent from './ResultMastheadContent';
import * as Styled from '../mastheadStyles';
import { selectRoundStatusById } from '../../../../../store/modules/round/selectors';
import { requestRounds } from '../../../../../store/modules/round/actions';
import isActiveRound from '../../../../../helpers/isActiveRound';

const ResultMasthead = ({
    loadRounds,
    roundStatus,
    punditPredictions,
    userPredictions,
    opponent
}) => {
    useEffect(() => {
        loadRounds();
    }, [loadRounds]);

    if (!userPredictions || !punditPredictions) {
        return null;
    }

    const isWinning =
        userPredictions.totalPoints.points >
        punditPredictions.totalPoints.points;

    const isDrawing =
        userPredictions.totalPoints.points ===
        punditPredictions.totalPoints.points;

    const roundIsActive = isActiveRound(roundStatus);

    return (
        <Styled.Container data-test-id="results-masthead">
            <Styled.ContentContainer>
                <ResultMastheadContent
                    isComplete={roundStatus === 'complete'}
                    isInplay={roundStatus === 'inplay'}
                    isWinning={isWinning}
                    isDrawing={isDrawing}
                    hasPredicted={userPredictions.hasPredicted}
                    roundIsActive={roundIsActive}
                    userPredictions={userPredictions}
                    punditPredictions={punditPredictions}
                    opponentName={opponent.firstName}
                />
                <Styled.Image opponentImage={opponent.winnerImage} />
            </Styled.ContentContainer>
        </Styled.Container>
    );
};

ResultMasthead.propTypes = {
    loadRounds: PropTypes.func.isRequired,
    roundStatus: PropTypes.string,
    userPredictions: PropTypes.shape({
        hasPredicted: PropTypes.bool,
        hasEnteredHeadToHead: PropTypes.bool,
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }),
    opponent: PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileImage: PropTypes.string,
        winnerImage: PropTypes.string,
        loserImage: PropTypes.string
    }).isRequired
};

ResultMasthead.defaultProps = {
    userPredictions: null,
    punditPredictions: null,
    roundStatus: null
};

const mapStateToProps = (state, props) => ({
    roundStatus: selectRoundStatusById(state, props.roundId)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultMasthead);
