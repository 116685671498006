import { isOauthEnabled } from '../../../auth/auth';

export const selectIsInitialised = state => state.auth.initialised;
export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectHasCheckedAuth = state => state.auth.hasCheckedAuth;
export const selectToken = state => {
    if (!state.auth.idToken) {
        return null;
    }
    if (isOauthEnabled()) {
        return `Bearer ${state.auth.idToken}`;
    }
    return `sso ${state.auth.idToken}`;
};
export const selectJwt = state => state.auth.jwt || null;
