import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageContainer } from '../../../components/Common/Page/styles';
import leagueProp from '../propTypes';
import {
    requestLeagueLeaderboard,
    requestMyScore,
    requestLeagues
} from '../../../store/modules/league/actions';
import { requestRounds } from '../../../store/modules/round/actions';
import {
    selectActiveRound,
    selectLatestNonOpenOrFutureMonthId,
    selectLatestNonOpenOrFutureRoundId
} from '../../../store/modules/round/selectors';
import {
    selectMyScore,
    getActiveLeagues
} from '../../../store/modules/league/selectors';
import * as Styled from './styles';
import ConnectedLeaderboardTabs from '../../../components/LeaderboardTabs';
import ConnectedPeriodIdNavigation from '../../../containers/PeriodIdNavigation';
import MyPositionRow from '../../../components/LeaderboardTable/MyPositionRow';
import ConnectedResultTable from '../components/ResultTable';
import InfoParagraph from '../../Leaderboard/components/InfoParagraph';
import SelectHeaderContainer from '../../../components/SelectHeaderContainer';
import { showModal as showModalAction } from '../../../store/modules/modal/actions';
import { USE_LEADERBOARD_API } from '../../../config';
import modules from '../../../helpers/GoogleAnalytics/modules';
import { sendInterfaceEvent } from '../../../helpers/GoogleAnalytics/gaHelper';

const Leaderboard = ({
    league,
    period,
    periodId,
    myScore,
    getMyScore,
    history,
    deleteLeague,
    leaveLeague,
    getLeaderboard,
    lastCompleteRoundId,
    lastCompleteMonthId,
    activeLeagues,
    getLeagues,
    round
}) => {
    useEffect(() => {
        if (!period) {
            return;
        }
        if (period !== 'season' && !periodId) {
            return;
        }
        if (!myScore) {
            if (period === 'season' && USE_LEADERBOARD_API) {
                getLeaderboard(league.slug);

                return;
            }

            getMyScore(league.slug, period, periodId);
        }
    }, [myScore, league.slug, period, periodId, getMyScore, getLeaderboard]);

    useEffect(() => {
        if (activeLeagues == null) {
            getLeagues();
        }
    }, [activeLeagues]);

    const periodIdInt = periodId ? parseInt(periodId, 10) : null;

    const { chairman, memberCount, slug, isChairperson } = league;

    const onLeagueChange = contest => {
        if (round.status === 'inplay') {
            history.push(`/league/${contest.slug}/round/${round.id}`);
        } else {
            history.push(`/league/${contest.slug}/season`);
        }
    };

    const setInterfaceEvent = (text, action) => {
        sendInterfaceEvent(text, action, modules.leagues.results);
    };

    return (
        <PageContainer>
            <SelectHeaderContainer
                activeOption={league}
                optionsList={activeLeagues}
                backLink="/league"
                onChange={onLeagueChange}
            />
            <Styled.LeaderboardContainer>
                <Styled.LeaderboardContainerWrapper>
                    <ConnectedLeaderboardTabs
                        lastCompleteRoundId={lastCompleteRoundId}
                        period={period}
                        roundTemplate={`/league/${slug}/round/${lastCompleteRoundId}`}
                        monthTemplate={`/league/${slug}/month/${lastCompleteMonthId}`}
                        seasonTemplate={`/league/${slug}/season`}
                        infoTemplate={`/league/${slug}/info`}
                    />
                    {period === 'info' && (
                        <InfoParagraph
                            type="league-desktop"
                            data={{
                                chairman,
                                pin: slug,
                                memberCount,
                                isChairperson,
                                slug,
                                deleteLeague,
                                leaveLeague
                            }}
                            setInterfaceEvent={setInterfaceEvent}
                        />
                    )}
                </Styled.LeaderboardContainerWrapper>
                {period !== 'info' && (
                    <>
                        <ConnectedPeriodIdNavigation
                            urlTemplate={`/league/${slug}/${period}/:id`}
                            period={period}
                            periodId={periodIdInt}
                            history={history}
                        />
                        <MyPositionRow
                            myScore={myScore}
                            justMe={league.memberCount === 1}
                            isChairperson={isChairperson}
                            isManaged={league.isManaged}
                            testId="league-page-content"
                        />
                        {memberCount > 1 && (
                            <ConnectedResultTable
                                league={league}
                                period={period}
                                periodId={periodIdInt}
                                slug={slug}
                            />
                        )}
                    </>
                )}
            </Styled.LeaderboardContainer>
        </PageContainer>
    );
};

const mapStateToProps = (state, props) => ({
    myScore: selectMyScore(
        state,
        props.league.slug,
        props.period,
        props.periodId
    ),
    lastCompleteMonthId: selectLatestNonOpenOrFutureMonthId(state),
    lastCompleteRoundId: selectLatestNonOpenOrFutureRoundId(state),
    activeLeagues: getActiveLeagues(state),
    round: selectActiveRound(state)
});

const mapDispatchToProps = dispatch => ({
    getMyScore: (slug, period, periodId) =>
        dispatch(requestMyScore(slug, period, periodId)),
    getLeaderboard: slug => dispatch(requestLeagueLeaderboard(slug)),
    deleteLeague: slug =>
        dispatch(showModalAction('LEAGUE_DELETE', { slug }, true)),
    leaveLeague: slug =>
        dispatch(showModalAction('LEAGUE_LEAVE', { slug }, true)),
    loadRounds: () => dispatch(requestRounds()),
    getLeagues: () => dispatch(requestLeagues())
});

Leaderboard.propTypes = {
    activeLeagues: PropTypes.arrayOf(leagueProp),
    getLeagues: PropTypes.func.isRequired,
    leaveLeague: PropTypes.func.isRequired,
    deleteLeague: PropTypes.func.isRequired,
    getLeaderboard: PropTypes.func.isRequired,
    league: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        isFound: PropTypes.bool.isRequired,
        name: PropTypes.string,
        memberCount: PropTypes.number,
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        isChairperson: PropTypes.bool,
        pin: PropTypes.number,
        isManaged: PropTypes.bool,
        iconUrl: PropTypes.string
    }).isRequired,
    period: PropTypes.string.isRequired,
    periodId: PropTypes.string,
    getMyScore: PropTypes.func.isRequired,
    myScore: PropTypes.shape({
        userId: PropTypes.number,
        position: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isWinner: PropTypes.bool,
        goldenGoal: PropTypes.number,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        points: PropTypes.number
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    lastCompleteRoundId: PropTypes.number,
    lastCompleteMonthId: PropTypes.number,
    round: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
};

Leaderboard.defaultProps = {
    activeLeagues: null,
    myScore: null,
    periodId: null,
    lastCompleteRoundId: 0,
    lastCompleteMonthId: 0
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
