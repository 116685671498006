import styled from '@emotion/styled/macro';

export const OuterContainer = styled.div`
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 139px;
        height: 138px;
        border-radius: 14px;
        border: solid 1px rgba(214, 214, 214, 0.4);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        overflow: hidden;
        margin: -1px;
    }
`;

export const InnerContainer = styled.div`
    width: 88px;
    height: 87px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: ${props => props.theme.gradients.greyToWhite};
    border-bottom: 5px solid ${props => props.theme.colours.blueZodiac};
    padding: 8px 2px 10px;
    box-sizing: border-box;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 140px;
        height: 139px;
        border-radius: 4px;
        border-bottom: 8px solid ${props => props.theme.colours.blueZodiac};
        padding: 20px 15px;
    }
`;

export const Title = styled.div`
    font-size: ${props => props.theme.fontSize.normal};
    text-align: center;
    color: ${props => props.theme.colours.blueZodiac};
    font-family: ${props => props.theme.fonts.regular};
`;

export const Value = styled.div`
    font-size: ${props => props.theme.fontSize.xlarge2};
    text-align: center;
    color: ${props => props.theme.colours.blueZodiac};
    font-family: ${props => props.theme.fonts.medium};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        line-height: 55px;
        font-size: ${props => props.theme.fontSize.vhuge};
    }
`;
