import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const { Provider, Consumer } = createContext({});

export const Tab = ({ id, testId, isDisabled, to, onClick, children }) => (
    <Consumer>
        {active => (
            <Styled.Tab active={active === id} data-test-id={testId}>
                {isDisabled && (
                    <Styled.Disabled disabled={isDisabled}>
                        {children}
                    </Styled.Disabled>
                )}
                {!isDisabled && to && (
                    <Styled.Link
                        to={to.replace(':id', id)}
                        onClick={onClick || (() => {})}
                    >
                        {typeof children === 'string' && children}
                        {typeof children !== 'string' &&
                            React.cloneElement(children, {
                                active: active === id
                            })}
                    </Styled.Link>
                )}
                {!isDisabled && !to && onClick && (
                    <Styled.Button type="button" onClick={onClick}>
                        {children}
                    </Styled.Button>
                )}
            </Styled.Tab>
        )}
    </Consumer>
);

Tab.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
        PropTypes.string
    ]).isRequired,
    id: PropTypes.string.isRequired,
    testId: PropTypes.string,
    isDisabled: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func
};

Tab.defaultProps = {
    testId: null,
    isDisabled: false,
    to: null,
    onClick: null
};

const Tabs = ({ active, children, bgGrey }) => (
    <Provider value={active}>
        <Styled.Container bgGrey={bgGrey}>{children}</Styled.Container>
    </Provider>
);

Tabs.propTypes = {
    active: PropTypes.string,
    bgGrey: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element
    ]).isRequired
};

Tabs.defaultProps = {
    active: null,
    bgGrey: false
};

export default Tabs;
