import React from 'react';
import { PageContainer } from '../../components/Common/Page/styles';
import Container from '../../components/Common/Container';
import * as Styled from './styles';
import Skeleton from '../../components/Skeleton';

const SkeletonResults = () => (
    <PageContainer>
        <Container
            title={
                <Styled.RoundHeaderBar>
                    <Styled.SkeletonHeader>Loading...</Styled.SkeletonHeader>
                </Styled.RoundHeaderBar>
            }
            size="l"
        >
            <Styled.PaddedContainer>
                <Styled.PanelsContainer>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="223px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="190px" />
                    </Styled.Panel>
                    <Styled.Panel>
                        <Skeleton height="214px" />
                    </Styled.Panel>
                </Styled.PanelsContainer>
            </Styled.PaddedContainer>
        </Container>
    </PageContainer>
);

export default SkeletonResults;
