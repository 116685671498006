import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const HeroImage = ({ isLoading, imagePaths, imgAlt, variant }) => {
    if (isLoading) {
        return (
            <Styled.ImageContainer>
                <Styled.LoadingImage />
            </Styled.ImageContainer>
        );
    }

    const { desktop, mobile } = imagePaths;

    return (
        <Styled.ImageContainer variant={variant}>
            <Styled.HeroImage>
                <source media="(max-width: 720px)" srcSet={mobile} />
                <source srcSet={desktop} />
                <img src={desktop} alt={imgAlt} />
            </Styled.HeroImage>
        </Styled.ImageContainer>
    );
};

HeroImage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    imagePaths: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired
    }),
    imgAlt: PropTypes.string,
    variant: PropTypes.string
};

HeroImage.defaultProps = {
    imgAlt: '',
    imagePaths: {
        desktop: null,
        mobile: null
    },
    variant: 'default'
};

export default HeroImage;
