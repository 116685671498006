import React from 'react';
import * as Styled from '../styles';

const Limitation = () => (
    <>
        <li>
            Limitation of Liability
            <Styled.OrderedList>
                <li>
                    Except in the case of death or personal injury arising from
                    its negligence or in respect of fraud, and so far as is
                    permitted by law, the Promoter and each of its associated
                    companies and agents exclude responsibility and all
                    liabilities arising from:
                    <Styled.OrderedList>
                        <li>
                            any postponement, cancellation, delay or changes to
                            the Prizes, Competition, other associated promotions
                            or relevant football fixtures beyond the
                            Promoter&apos;s reasonable control;
                        </li>
                        <li>
                            any technical failures or unavailability of the
                            Super 6 Website, Mobile Apps or other related
                            platforms;
                        </li>
                        <li>
                            obvious errors or omissions relating to the awarding
                            of Prizes; or
                        </li>
                        <li>
                            any act or default of any third party supplier
                            including without limitation errors relating to
                            fixtures and scoring data.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    The terms and conditions of any third party supplier will
                    apply to the Prize where applicable.
                </li>
                <li>
                    If any provision or part&#45;provision of these Terms and
                    Conditions are or becomes invalid, illegal or unenforceable,
                    it shall be deemed modified to the minimum extent necessary
                    to make it valid, legal and enforceable. If such
                    modification is not possible, the relevant provision or
                    part&#45;provision shall be deemed deleted. Any modification
                    to or deletion of a provision or part&#45;provision under
                    this clause shall not affect the validity and enforceability
                    of the rest of these terms and conditions.
                </li>
                <li>
                    These Terms and Conditions will be interpreted in accordance
                    with English Law and you agree that all claims arising from
                    your participation in this Competition that you may bring
                    against the Promoter will be under the exclusive
                    jurisdiction of the English Courts.
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Limitation;
