import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import FollowButton from '../FollowButton';
import Image from '../../Common/Image';
import getFormattedPositionChange from '../../../helpers/getFormattedPositionChange';

const TableRow = ({ row, index, isSeason }) => {
    const history = useHistory();
    const {
        isMe,
        isWinner,
        isMasked,
        link,
        position,
        name,
        positionChange,
        correctResults,
        correctScores,
        points,
        canRemove,
        isChairperson,
        userId,
        isFollowing,
        onFollow,
        onUnfollow,
        removeUser
    } = row;

    const renderPosition = pos => {
        if (pos === 1) {
            return <Styled.WinnerIconImage src="/img/leaderboard/crown.png" />;
        }

        return pos;
    };

    const renderRemoveUser = () => (
        <Styled.RemoveUserButton
            title="Remove user"
            onClick={removeUser}
            data-test-id="remove-player-button"
        >
            <Image
                src="/img/leaderboard/delete-cross.png"
                width="25px"
                alt="red delete cross"
            />
        </Styled.RemoveUserButton>
    );

    const handleClick = () => {
        history.push(link);
    };

    const formattedPositionChange = getFormattedPositionChange(positionChange);

    return (
        <Styled.TableRow
            className={`table-row ${isMe ? 'current-player' : ''}`}
            data-test-id="player-row"
            isWinner={isWinner || isMasked}
            isMe={isMe}
            isMasked={isMasked}
        >
            <Styled.PositionData
                center
                data-test-id="row-position"
                onClick={handleClick}
                highlight
                isMe={isMe}
                isWinner={isWinner}
                index={index}
                isMasked={isMasked}
            >
                {renderPosition(position || 0)}
            </Styled.PositionData>
            {isSeason && (
                <Styled.TableData
                    data-test-id="row-position-changed"
                    onClick={handleClick}
                    isMasked={isMasked}
                    highlight
                    isMe={isMe}
                    isWinner={isWinner}
                    index={index}
                    row={row}
                >
                    {formattedPositionChange}
                </Styled.TableData>
            )}
            <Styled.TableData
                data-test-id={`row-${isMasked ? 'masked-' : ''}player-name`}
                onClick={handleClick}
                isMasked={isMasked}
                highlight
                isMe={isMe}
                isWinner={isWinner}
                index={index}
            >
                {name || ''}
            </Styled.TableData>
            <Styled.TableData
                center
                hideOnSmall
                data-test-id="row-results"
                onClick={handleClick}
                highlight
                isMe={isMe}
                isWinner={isWinner}
                index={index}
                isMasked={isMasked}
            >
                {correctResults || '0'}
            </Styled.TableData>
            <Styled.TableData
                center
                hideOnSmall
                data-test-id="row-scores"
                onClick={handleClick}
                highlight
                isMe={isMe}
                isWinner={isWinner}
                index={index}
                isMasked={isMasked}
            >
                {correctScores || '0'}
            </Styled.TableData>
            <Styled.PointsData
                center
                highlight
                hideOnSmall
                isMe={isMe}
                isWinner={isWinner}
                data-test-id="row-points"
                onClick={handleClick}
                index={index}
                isMasked={isMasked}
            >
                {points || '0'}
            </Styled.PointsData>
            <Styled.PointsData
                center
                showOnSmall
                isMe={isMe}
                isWinner={isWinner}
                onClick={handleClick}
                highlight
                index={index}
                isMasked={isMasked}
            >
                {points || '0'}
            </Styled.PointsData>
            {onFollow && (
                <Styled.TableData isMasked={isMasked} center>
                    {!isMe && (
                        <FollowButton
                            userId={userId}
                            isFollowing={isFollowing}
                            onUnfollow={onUnfollow}
                            onFollow={onFollow}
                            isMasked={isMasked}
                        />
                    )}
                </Styled.TableData>
            )}
            {canRemove && (
                <Styled.TableData center>
                    {!isChairperson && renderRemoveUser()}
                </Styled.TableData>
            )}
        </Styled.TableRow>
    );
};

TableRow.propTypes = {
    row: PropTypes.shape({
        canRemove: PropTypes.bool,
        change: PropTypes.number,
        formattedChange: PropTypes.string,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        isChairperson: PropTypes.bool,
        isFollowing: PropTypes.bool,
        isMasked: PropTypes.bool,
        isMe: PropTypes.bool,
        isWinner: PropTypes.bool.isRequired,
        link: PropTypes.string,
        name: PropTypes.string,
        onFollow: PropTypes.func,
        onUnfollow: PropTypes.func,
        points: PropTypes.number,
        position: PropTypes.number,
        positionChange: PropTypes.string,
        removeUser: PropTypes.func,
        userId: PropTypes.number,
        positionLastRound: PropTypes.number
    }).isRequired,
    index: PropTypes.number.isRequired,
    isSeason: PropTypes.bool
};

TableRow.defaultProps = {
    isSeason: false
};

export default TableRow;
