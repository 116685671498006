import * as types from './types';

export const requestRoundResults = roundId => ({
    type: types.REQUEST_ROUND_RESULTS,
    roundId
});

export const receiveRoundResults = (roundId, payload) => ({
    type: types.RECEIVE_ROUND_RESULTS,
    roundId,
    payload
});

export const requestRoundPredictions = (roundId, force = false) => ({
    type: types.REQUEST_ROUND_PREDICTIONS,
    roundId,
    force
});

export const receiveRoundPredictions = (roundId, payload) => ({
    type: types.RECEIVE_ROUND_PREDICTIONS,
    roundId,
    payload
});

export const requestRoundPredictionsByUser = (roundId, userId) => ({
    type: types.REQUEST_ROUND_PREDICTIONS_BY_USER,
    roundId,
    userId
});

export const receiveRoundPredictionsByUser = (roundId, userId, payload) => ({
    type: types.RECEIVE_ROUND_PREDICTIONS_BY_USER,
    roundId,
    userId,
    payload
});
