import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../../styles';

const HowToPlay = ({ steps }) => (
    <Styled.StepsContainer data-test-id="how-to-play-steps">
        {steps.map(({ title, text }, index) => (
            <Styled.StepCard
                data-test-id={`step-card-${index}`}
                key={`step-card-${title}`}
            >
                <Styled.StepTitle data-test-id={`step-card-title-${index}`}>
                    {title}
                </Styled.StepTitle>
                <Styled.StepLine />
                <Styled.StepBody>
                    <Styled.BodyText data-test-id={`step-card-text-${index}`}>
                        {text}
                    </Styled.BodyText>
                </Styled.StepBody>
            </Styled.StepCard>
        ))}
    </Styled.StepsContainer>
);

HowToPlay.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired
};

export default HowToPlay;
