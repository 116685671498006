import axios from 'axios';
import { API_ROOT } from '../config';

const landingPageApi = {
    getLandingPage: async slug => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/landing-page/${slug}`
        );
        if (status !== 200) {
            throw new Error('Unable to get landing page');
        }

        return data;
    }
};

export default landingPageApi;
