import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { Icon } from '../Common/Page/styles';
import { Cross } from '../../styles/icons';

const Message = ({ type, message, onDismiss }) => {
    if (type === 'success') {
        return (
            <Styled.SuccessMessage
                className="successful-action"
                data-test-id="toast-success-message"
            >
                <Styled.DismissButton primary onClick={onDismiss}>
                    <Icon data-char={Cross} />
                </Styled.DismissButton>
                {message}
            </Styled.SuccessMessage>
        );
    }

    if (type === 'error') {
        return (
            <Styled.ErrorMessage
                className="unsuccessful-action"
                data-test-id="toast-error-message"
            >
                <Styled.DismissButton onClick={onDismiss}>
                    <Icon data-char={Cross} />
                </Styled.DismissButton>
                {message}
            </Styled.ErrorMessage>
        );
    }

    return null;
};

Message.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onDismiss: PropTypes.func.isRequired
};
export default Message;
