import styled from '@emotion/styled/macro';

import Image from '../../../../components/Common/Image';

export const MatchContainer = styled.div`
    position: relative;
    background: ${props => props.theme.colours.white};
    padding: ${props =>
        `${props.theme.spacing.vlarge} 0 ${props.theme.spacing.normal} 0`};
    border-radius: ${props => props.theme.radius.normal};
    margin: ${props => `0 0 ${props.theme.spacing.large} 0`};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 40%;
        flex-grow: 1;
        :nth-of-type(even) {
            margin-left: ${props => props.theme.spacing.large};
        }
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props => props.theme.spacing.medium};
    }
`;

// PREDICTION

export const ScrollAnchor = styled.div`
    position: absolute;
    top: -90px;
`;

export const MatchPrediction = styled.div`
    display: flex;

    margin-top: ${props => props.theme.spacing.large};
    margin-bottom: ${props => props.theme.spacing.normal};
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        margin: ${props => props.theme.spacing.normal} 0;
    }
`;

export const MatchPredictionContainer = styled.div`
    flex: 40% 1 1;
    display: flex;
    flex-direction: row;
    div {
        flex: 33% 1 1;
        text-align: center;
    }
`;

export const MatchPredictionController = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const plusminus = styled.button`
    height: 20px;
    width: 20px;
    line-height: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`;

export const Plus = styled(plusminus)`
    background: url('/content/img/play/plus.svg');
    background-repeat: no-repeat;
`;

export const Minus = styled(plusminus)`
    background: url('/content/img/play/minus.svg');
    background-repeat: no-repeat;
    background-position: center;
`;

export const MatchScore = styled.p`
    width: 100%;
    border: 0px solid ${props => props.theme.colours.borderGrey};
    background: transparent;
    color: ${props => props.theme.colours.bluePrimary};
    font-size: 50px;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
    text-align: center;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 6.5px 0;
    cursor: default;
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: 50px;
    }
`;

export const MatchVs = styled.p`
    display: flex;
    align-items: center;
    align-content: center;
    font-size: ${props => props.theme.fontSize.xlarge2};
    font-family: ${props => props.theme.fonts.skySportsD};
    color: ${props => props.theme.colours.bluePrimary};
    font-weight: 700;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.xlarge};
    }
`;

// FOOTER

export const MatchFooter = styled.div`
    display: flex;
    flex-direction: column;

    background: ${props => props.theme.colours.backgroundGrey};
    border-radius: 20px;

    padding: ${props => props.theme.spacing.large};
    margin: 0 ${props => props.theme.spacing.normal};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props => props.theme.spacing.medium};
        margin: 0;
    }
`;

export const TabHeader = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    margin: 0 ${props => props.theme.spacing.large};
`;

export const TabHeading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${props =>
        props.isSelected
            ? props.theme.colours.bluePrimary
            : props.theme.colours.textGrey};
    font-size: ${props => props.theme.fontSize.large};
    cursor: pointer;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.normal};
    }

    ::after {
        content: '';
        display: ${props => (props.isSelected ? 'block' : 'none')};
        position: absolute;
        bottom: -28px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-top: 12px solid ${props => props.theme.colours.bluePrimary};
    }
`;

export const TabHeadingIcon = styled(Image)`
    max-width: ${props => props.iconmaxwidth};
    margin-bottom: ${props => props.theme.spacing.small};
`;

export const TabTitle = styled.p``;

export const TabBody = styled.div`
    min-height: 100px;
    padding: ${props => `${props.theme.spacing.xlarge} ${props.theme.spacing.medium}
        ${props.theme.spacing.medium}`};
    margin: ${props => props.theme.spacing.large} 0 0;
    border-radius: 20px;

    background: ${props => props.theme.colours.white};
`;
