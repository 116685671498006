import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import * as Styled from './styles';
import PageTitle from '../../components/Common/PageTitle';

const Cookie = () => (
    <Styled.CookiePageContainer>
        <PageTitle>Cookie Policy</PageTitle>
        <p>
            You should be aware that when you access or use our content,
            products and services, we may collect information from your devices
            through the use of &apos;cookies&apos; and similar technologies.
            Cookies perform various different functions, and often to improve
            your user experience. The purpose of this policy is to give you a
            clearer understanding of the cookies we use, why we use them and how
            you can control your cookies.
        </p>
        <Styled.SectionHeader>
            Information we get via Cookies
        </Styled.SectionHeader>
        <p>
            &apos;Cookies&apos; are small text files stored in your web browser
            that enable us to recognise your computer when you visit one of our
            websites. Cookies are essential to keep certain parts of our
            websites functioning correctly and securely. We also use them to
            make things quicker, easier and more personal to you, and to help us
            understand how our websites are used. They can also be used to
            present you with more tailored advertising content.
        </p>
        <p>
            To do all of these things, cookies collect some personal information
            about you whenever you use our websites. You can choose whether to
            accept or reject some or all types of cookies and control this
            &apos;s browser settings. We will make you aware of this by showing
            you our cookie banner when you visit our site.{' '}
            <strong>
                If you then continue to use our websites without adjusting your
                browser settings, we will use cookies as set out in the sections
                below
            </strong>
            , so to help you make an informed choice it&apos;s important to know
            why we use the different types of cookie and what that means for
            your online experience. This section provides you with a summary of
            the main points and tells you how switching off the different types
            of cookie will affect your experience on our websites.
        </p>
        <Styled.SubSectionHeader>
            The cookies we use fall into the following categories:
        </Styled.SubSectionHeader>
        <Styled.SubSectionSubHeader>
            Strictly Necessary cookies: Making our websites work:
        </Styled.SubSectionSubHeader>
        <p>
            These Cookies are essential so that you can move around the sites
            and log in to the secure areas. They also enable us to deliver
            content in a way that works for your device.
        </p>
        <p>
            <strong>Without these cookies</strong> you would be unable to place
            bets, play games or access your account so if you opt out of
            receiving these cookies, parts of our site will not work correctly
            and may not work at all.
        </p>
        <p>
            A few examples of the ways in which we use strictly necessary
            cookies:
        </p>
        <ul>
            <li>Allowing you to navigate smoothly through our websites;</li>
            <li>
                Showing content in the best format for your device or browser
                and positioning it for your screen;
            </li>
            <li>
                Keeping you logged in during your visit so that you don&apos;t
                have to log in on every one of our websites each time you visit;
            </li>
            <li>
                Enabling you to stream content without having to repeatedly
                adjust volume and viewing settings.
            </li>
        </ul>
        <Styled.SubSectionSubHeader>
            Cookies for Fraud and money laundering prevention and identifying
            unfair use of our services:
        </Styled.SubSectionSubHeader>
        <p>
            As a betting and gaming company we have certain{' '}
            <Link smooth to="/privacy#legal-and-regulatory-requirements">
                legal obligations
            </Link>{' '}
            we need to abide by. One of those rules is that we must do
            everything we can to prevent fraud and money laundering. These
            Cookies help us to protect our customers and our business by
            ensuring that customers are genuine, haven&apos;t registered more
            than once, and are not fraudulently trying to access accounts that
            don&apos;t belong to them. They also help us to recognise behaviour
            that may indicate fraud, money laundering, other illegal activities
            or breaches of our &apos; cookie for these purposes.
        </p>
        <p>
            <strong>Without these cookies</strong>you will be unable to use our
            websites. If you do not wish to accept this type of cookie, your
            option will be to close your account and not use our services.
        </p>
        <Styled.SubSectionSubHeader>
            Functionality cookies: Enabling the features of the website:{' '}
        </Styled.SubSectionSubHeader>
        <p>
            These Cookies allow our websites to remember choices you have made,
            such as your username, your region or the selections you have placed
            on your bet slip. They help us keep you logged into your account as
            you navigate around our websites, and enable you to use certain
            features such as resetting your PIN or user ID.
        </p>
        <p>
            <strong>Without these cookies</strong> you will still be able to use
            the site but may experience difficulty using certain features, and
            will need to keep logging in as you navigate around our sites. You
            will not see content (including splash offers) that is relevant to
            you.
        </p>
        <Styled.SubSectionSubHeader>
            Analytical cookies - Monitoring the performance of our websites:
        </Styled.SubSectionSubHeader>

        <p>
            These Cookies collect information about how people use our websites
            and how the sites are performing, e.g. how many people visit, which
            pages are most popular and whether and where people see error
            messages. They also ensure we can pay our marketing partners
            correctly for users they refer to us. A few examples of the ways in
            which we use Analytical cookies:
        </p>

        <ul>
            <li>to identify trends about how people use our website;</li>
            <li>to help us keep our content relevant and up to date;</li>
            <li>
                to count the number of times a page or email has been viewed and
                allow us to measure the effectiveness of our content and
                communication; and
            </li>
            <li>
                to improve the functioning of our applications and websites.
            </li>
        </ul>
        <p>
            <strong>Without these cookies </strong>you will still be able to use
            and enjoy all the online features of our websites.
        </p>
        <Styled.SubSectionSubHeader>
            Targeting Cookies - Marketing & advertising:
        </Styled.SubSectionSubHeader>
        <p>
            These cookies are used to deliver adverts and content that are more
            relevant to you as well as to limit the number of times you see a
            particular advertisement and to measure the effectiveness of
            advertising campaigns. The Cookies remember that you have visited a
            website, and we share this information with other organisations for
            advertising purposes. We also use them to test and improve our
            products and services by, for example, testing out different product
            experiences with different groups of customers to see which is most
            popular.
        </p>
        <p>
            <strong>Without these cookies</strong> you will be able to use and
            enjoy all the features of our websites but the adverts you see will
            not be tailored to your interests.
        </p>
        <Styled.SubSectionSubHeader>
            Tracking Cookies: Online Behavioural Advertising{' '}
        </Styled.SubSectionSubHeader>
        <p>
            We use cookies placed by third parties to collect personal
            information about your browsing activity, which they then group with
            data about what other people with similar interests in similar
            demographic groups are looking at. The resulting information is used
            to show you online adverts based on those interests (this is known
            as &apos;online behavioural advertising&apos;).
        </p>
        <p>
            We use a cookie placed by a company called Lotame to collect
            non-personal data that is used for online behavioural advertising.
            To opt out of the collection and use of data by Lotame in your
            browser (including a mobile browser if you have enabled third-party
            cookies), you can{' '}
            <a
                href="https://www.lotame.com/about-lotame/privacy/opt-out/"
                rel="noopener noreferrer"
                target="_blank"
            >
                click here
            </a>{' '}
            and follow the instructions provided.{' '}
            <strong>
                Your opt-out choice is applied only to the browser from which
                you make the choice
            </strong>
            , so if you use different browsers you will need to opt out in each
            one. This opt-out is cookie based, so if you delete your cookies,
            you will no longer be opted out and will need to opt out again.
        </p>
        <p>
            <strong>Without these cookies</strong> you will still be able to use
            and enjoy the features of our websites and you will still be
            presented with advertising but it is unlikely to be relevant to you.
        </p>
        <p>
            If you would like to find out more information about Online
            Behavioural Advertising and the choices you have, the following
            websites are helpful:
        </p>
        <ul>
            <li>
                <a
                    href="http://www.youronlinechoices.com/uk/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Your Online Choices
                </a>
                - Provides information and allows you to choose not to receive
                behavioural advertising from the majority of the advertising
                networks.
            </li>
            <li>
                <a
                    target="_blank"
                    href="https://www.networkadvertising.org/"
                    rel="noopener noreferrer"
                >
                    Network Advertising Initiative (NAI)
                </a>
                - Please see the &quot;opt-out&quot; page. This allows you to
                opt-out of behavioural advertising from each of the ad networks
                represented by the NAI.
            </li>
            <li>
                <a
                    href="http://www.edaa.eu/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    European Digital Advertising Alliance
                </a>
                - Please see the Online Behavioural Advertising Choices page.
            </li>
        </ul>
        <Styled.SubSectionHeader>
            Controlling my cookies
        </Styled.SubSectionHeader>
        <p>
            All modern browsers allow you to see what cookies you have, and to
            clear them individually or in their entirety by changing your cookie
            &apos; or &apos;preferences&apos; menu of your browser, otherwise
            you should use the &apos;Help&apos; option in your browser for more
            details. You may also find the links below helpful:
        </p>
        <ul>
            <li>
                <a
                    href="http://www.aboutcookies.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    aboutcookies.org
                </a>{' '}
                (contains information about cookies on a wide variety of desktop
                browsers.)
            </li>
            <li>
                <a
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie settings in Internet Explorer
                </a>
            </li>
            <li>
                <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie settings in Firefox
                </a>
            </li>
            <li>
                <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie settings in Chrome
                </a>
            </li>
            <li>
                <a
                    href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie settings in Safari
                </a>
            </li>
            <li>
                <a
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie settings in Edge
                </a>
            </li>
        </ul>
    </Styled.CookiePageContainer>
);

export default Cookie;
