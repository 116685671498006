import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../../view/styles';

const PerformanceHubLoading = ({ componentsEnabled, h2hEnabled }) => (
    <>
        <Styled.TitleLoading />
        <Styled.PerformanceHubContainer componentsEnabled={componentsEnabled}>
            <Styled.PerformanceHubDataContainer>
                <Styled.StreakBoxContainerLoading />
                <Styled.StatsBoxGroupContainer>
                    <Styled.StatBoxGroupTopRow>
                        <Styled.StatBoxContainerLoading />
                        <Styled.StatBoxContainerLoading />
                        <Styled.StatBoxContainerLoading />
                    </Styled.StatBoxGroupTopRow>
                    <Styled.StatBoxGroupRow>
                        <Styled.StatBoxContainerLoading />
                        <Styled.StatBoxContainerLoading />
                        <Styled.StatBoxContainerLoading />
                    </Styled.StatBoxGroupRow>
                </Styled.StatsBoxGroupContainer>
            </Styled.PerformanceHubDataContainer>
            {componentsEnabled && (
                <Styled.PerformanceHubComponentContainer>
                    <Styled.LastRoundLoading />
                    {h2hEnabled && <Styled.HeadToHeadLoading />}
                    <Styled.GraphLoading />
                </Styled.PerformanceHubComponentContainer>
            )}
        </Styled.PerformanceHubContainer>
    </>
);

PerformanceHubLoading.propTypes = {
    componentsEnabled: PropTypes.bool,
    h2hEnabled: PropTypes.bool
};

PerformanceHubLoading.defaultProps = {
    componentsEnabled: false,
    h2hEnabled: true
};

export default PerformanceHubLoading;
