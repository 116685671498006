import styled from '@emotion/styled/macro';

export const StatBoxGroupContainer = styled.div`
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const StatBox = styled.div`
    margin-left: 4px;

    @media (min-width: 341px) {
        margin-left: 21px;
    }

    &:first-of-type {
        margin-left: 0px;
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin-left: 38px;
    }
`;

export const StatBoxGroupRow = styled.div`
    @media (max-width: 340px) {
        justify-content: space-between;
    }
    display: flex;
`;

export const StatBoxGroupTopRow = styled(StatBoxGroupRow)`
    margin-bottom: 15px;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin-bottom: 25px;
    }
`;
