import { createSelector } from 'reselect';

export const getRoundResults = (state, roundId) =>
    state.results.rounds[roundId];
export const getRoundPredictions = (state, roundId) =>
    state.results.predictions[roundId];
export const getLoadingRoundPredictions = (state, roundId) =>
    !!state.results.loadedPredictions[roundId];
export const getRoundPredictionsByUser = (state, roundId, userId) =>
    state.results.predictionsOtherUser[userId] &&
    state.results.predictionsOtherUser[userId][roundId];

export const getRoundPredictionsByUserWithName = (state, roundId, userId) => {
    if (state.results.predictionsOtherUser[userId]) {
        const result = {
            ...state.results.predictionsOtherUser[userId][roundId],
            firstName: state.results.predictionsOtherUser[userId].firstName,
            lastName: state.results.predictionsOtherUser[userId].lastName
        };
        return result;
    }
    return null;
};

const getRoundId = (state, roundId) => roundId;
export const selectLastRoundData = createSelector(
    [getRoundId, getRoundResults, getRoundPredictions],
    (roundId, results, predictions) => {
        if (!results || !predictions) {
            return null;
        }

        const fixtures = results.scoreChallenges.map(({ match }) => {
            const { points, scoreHome, scoreAway } =
                'matches' in predictions && match.id in predictions.matches
                    ? predictions.matches[match.id]
                    : {};
            return {
                id: match.id,
                points: points !== undefined ? points : null,
                homeTeam: { badgeUri: match.homeTeam.badgeUri },
                awayTeam: { badgeUri: match.awayTeam.badgeUri },
                predictedHomeScore: scoreHome !== undefined ? scoreHome : null,
                homeScore: match.homeTeam.score,
                predictedAwayScore: scoreAway !== undefined ? scoreAway : null,
                awayScore: match.awayTeam.score,
                isAbandoned: match.shortStatus !== 'MC'
            };
        });

        return {
            totalPoints: predictions.totalPoints.points || 0,
            hasEnteredHeadToHead: predictions.hasEnteredHeadToHead,
            fixtures
        };
    }
);
