import React from 'react';
import * as CommonStyled from './common';

export default function FailureMessage() {
    return (
        <CommonStyled.HeaderText>
            Sorry, information not available at the moment.
        </CommonStyled.HeaderText>
    );
}
