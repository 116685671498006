/* eslint-disable import/no-unused-modules */
import { getApp } from '../appBridge';

const emitNativeShareEvent = ({
    e,
    shareEvent,
    sendSocialShare,
    customMessage,
    origin
}) => {
    if (shareEvent) {
        shareEvent();
    }

    if (!getApp()) {
        if (!sendSocialShare) {
            throw new Error('No social share function provided');
        }

        sendSocialShare({
            text: customMessage.shareText || '',
            URL: '',
            imageURL: '',
            origin: origin || 'homepage'
        });
    }

    if (navigator.share) {
        navigator.share({
            text: customMessage.shareText || '',
            URL: '',
            imageURL: '',
            origin: origin || 'homepage'
        });
    }

    e.preventDefault();
};

export { emitNativeShareEvent as default };
