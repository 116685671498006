import Events from './events';

export default class NavigationEvent extends Events {
    constructor(
        module = 'null',
        moduleDisplayOrder = 'null',
        elementText = 'null',
        position = 'null',
        destinationUrl = 'null',
        eventContext = 'null'
    ) {
        super(elementText, module, 'navigated to');
        this.module_display_order = moduleDisplayOrder;
        this.position = position.toString();
        this.destination_url = destinationUrl.substring(0, 90); // Firebase has a limit of 100 characters per event parameter value
        this.event_context = eventContext;
        this.type = 'null';
    }
}
