import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pundit from './Pundit';
import * as Styled from './styles';
import { requestMatchPundits } from '../../../../../../store/modules/round/actions';
import Spinner from '../../../../../../components/Common/Spinner';
import FailureMessage from '../FailureMessage';

export const Pundits = ({ pundits, getPundits, matchId, isLoading }) => {
    useEffect(() => {
        getPundits(matchId);
    }, [matchId, getPundits]);

    if (!pundits?.length && !isLoading) {
        return (
            <FailureMessage data-test-id="match-pundits-prediction-failure" />
        );
    }
    if (isLoading) {
        return <Spinner colour="textTitle" />;
    }

    return (
        <Styled.Pundits>
            <Styled.HeaderText>Pundits</Styled.HeaderText>
            <Styled.PunditsContainer data-test-id="match-pundits-pundit-prediction">
                {(!pundits || pundits.length === 0) && (
                    <Styled.NoPundits>
                        Our pundits haven&apos;t made their predictions yet,
                        please check back later
                    </Styled.NoPundits>
                )}
                {pundits.length > 0 &&
                    pundits.map(
                        (
                            { imageUri, name, scoreHome, scoreAway },
                            index,
                            items
                        ) => (
                            <Styled.PunditContainer
                                index={index}
                                totalPundits={items.length}
                                key={name}
                                numOfPundits={pundits.length}
                            >
                                <Pundit
                                    data-test-id="pundit-container"
                                    avatarUri={imageUri}
                                    name={name}
                                    homePrediction={scoreHome}
                                    awayPrediction={scoreAway}
                                />
                            </Styled.PunditContainer>
                        )
                    )}
            </Styled.PunditsContainer>
        </Styled.Pundits>
    );
};

Pundits.propTypes = {
    pundits: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            imageUri: PropTypes.string,
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    ).isRequired,
    matchId: PropTypes.number.isRequired,
    getPundits: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

Pundits.defaultProps = {
    isLoading: true
};

const mapStateToProps = (state, props) => ({
    pundits:
        state.round.pundits[props.matchId] &&
        state.round.pundits[props.matchId].data,
    isLoading:
        state.round.pundits[props.matchId] &&
        state.round.pundits[props.matchId].loading
});

const mapDispatchToProps = dispatch => ({
    getPundits: matchId => dispatch(requestMatchPundits(matchId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pundits);
