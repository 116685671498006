export const REQUEST_LEADERBOARD_FOR_REFERREES =
    'leaderboard/REQUEST_LEADERBOARD_FOR_REFERREES';
export const RECEIVE_LEADERBOARD_FOR_REFERREES =
    'leaderboard/RECEIVE_LEADERBOARD_FOR_REFERREES';
export const REQUEST_LEADERBOARD_FOR_SELF =
    'leaderboard/REQUEST_LEADERBOARD_FOR_SELF';
export const RECEIVE_LEADERBOARD_FOR_SELF =
    'leaderboard/RECEIVE_LEADERBOARD_FOR_SELF';
export const REQUEST_GLOBAL_LEADERBOARD =
    'leaderboard/REQUEST_GLOBAL_LEADERBOARD';
export const REQUEST_FOLLOWEE_LEADERBOARD =
    'leaderboard/REQUEST_FOLLOWEE_LEADERBOARD';
export const REQUEST_PUNDIT_LEADERBOARD =
    'leaderboard/REQUEST_PUNDIT_LEADERBOARD';
export const RECEIVE_GLOBAL_LEADERBOARD =
    'leaderboard/RECEIVE_GLOBAL_LEADERBOARD';
export const RECEIVE_FOLLOWEE_LEADERBOARD =
    'leaderboard/RECEIVE_FOLLOWEE_LEADERBOARD';
export const RECEIVE_PUNDIT_LEADERBOARD =
    'leaderboard/RECEIVE_PUNDIT_LEADERBOARD';
export const REQUEST_SEARCH_LEADERBOARD =
    'leaderboard/REQUEST_SEARCH_LEADERBOARD';
export const RECEIVE_SEARCH_LEADERBOARD =
    'leaderboard/RECEIVE_SEARCH_LEADERBOARD';
