import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Results from './Results';
import Prizes from './Prizes';
import HowToPlay from './HowToPlay';
import Spinner from '../../components/Common/Spinner';
import NoHeadToHead from './components/NoHeadToHead';
import {
    selectHeadToHead,
    selectIsLoadedHeadToHead
} from '../../store/modules/round/selectors';
import { requestHeadToHead } from '../../store/modules/round/actions';

const HeadToHead = ({
    section,
    roundId,
    headToHeadLoaded,
    getHeadToHead,
    headToHead
}) => {
    useEffect(() => {
        getHeadToHead(roundId);
    }, [getHeadToHead, roundId]);

    if (!headToHeadLoaded) {
        return <Spinner colour="black" />;
    }

    if (!headToHead) {
        return <NoHeadToHead />;
    }

    if (section === 'results') {
        return <Results roundId={roundId} headToHead={headToHead} />;
    }

    if (section === 'prizes') {
        return <Prizes roundId={roundId} headToHead={headToHead} />;
    }

    if (section === 'how-to-play') {
        return <HowToPlay headToHead={headToHead} />;
    }

    return null;
};

HeadToHead.propTypes = {
    section: PropTypes.string.isRequired,
    roundId: PropTypes.number.isRequired,
    headToHeadLoaded: PropTypes.bool.isRequired,
    getHeadToHead: PropTypes.func.isRequired,
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    })
};

HeadToHead.defaultProps = {
    headToHead: null
};

const mapStateToProps = (state, props) => ({
    headToHeadLoaded: selectIsLoadedHeadToHead(state, props.roundId),
    headToHead: selectHeadToHead(state, props.roundId),
    userId: state.user.info.userId
});

const mapDispatchToProps = dispatch => ({
    getHeadToHead: roundId => dispatch(requestHeadToHead(roundId))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeadToHead);
