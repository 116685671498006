import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';
import { Link } from 'react-router-dom';

export const StreaksOuterContainer = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    position: absolute;
    top: 50px;
    right: 10px;

    animation: 1.5s ${keyframes`
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    `};
`;

export const StreaksPointer = styled.div`
    height: 42px;
    width: 100%;
    background: ${props => props.theme.colours.white};
    position: relative;
    border: 1px solid #c3cce3;
    border-radius: 5px;

    :before {
        content: '';
        position: absolute;
        bottom: -webkit-calc(
            100% - 4.7px
        ); /*may require prefix for old browser support*/
        bottom: calc(100% - 4.7px); /*i.e. half the height*/
        right: 16px;
        height: 10px;
        width: 10px;
        background: ${props => props.theme.colours.white};
        transform: rotate(45deg);
        border-top: inherit;
        border-left: inherit;
        box-shadow: inherit;
    }
`;

export const StreaksInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CloseButton = styled.button`
    background: 0;
    margin: 8px;
    border: none;
    cursor: pointer;

    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.3);
    }

    &:focus {
        outline: none;
    }
`;

export const Icon = styled.i`
    font-family: 'skycons';
    font-size: 10px;
    color: ${props => props.theme.colours.textGrey};

    &:before {
        content: attr(data-char);
    }
`;

export const StreaksTextContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    margin-right: 12px;
`;

export const TextLeftBold = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3c3c;
`;

export const TextRight = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3c3c;
`;
