import React from 'react';
import PropTypes from 'prop-types';

import PunditPredictions from '.';
import * as Styled from './styles';

const PunditPredictionsList = ({ pundits, scoreChallenges }) => (
    <Styled.PunditsList>
        {pundits.map(pundit => (
            <PunditPredictions
                key={pundit.id}
                pundit={pundit}
                scoreChallenges={scoreChallenges}
            />
        ))}
    </Styled.PunditsList>
);

PunditPredictionsList.propTypes = {
    pundits: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            imageUri: PropTypes.string.isRequired,
            comment: PropTypes.string,
            predictions: PropTypes.shape({
                score: PropTypes.arrayOf(
                    PropTypes.shape({
                        challengeId: PropTypes.number.isRequired,
                        scoreHome: PropTypes.number.isRequired,
                        scoreAway: PropTypes.number.isRequired
                    })
                )
            }),
            type: PropTypes.string,
            typeImageUri: PropTypes.string,
            typeLink: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired,
    scoreChallenges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    badgeUri: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    )
};

PunditPredictionsList.defaultProps = {
    scoreChallenges: []
};

export default PunditPredictionsList;
