import axios from 'axios';
import { API_ROOT } from '../config';

const contentApi = {
    getYoutubeId: async () => {
        const { data, status } = await axios.get(`${API_ROOT()}/content/video`);
        if (status !== 200) {
            throw new Error('Unable to get youtube id');
        }
        return data.youtubeId;
    },
    getFeatureSwitches: async () => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/content/feature`
        );
        if (status !== 200) {
            throw new Error('Unable to get feature switches');
        }
        return data;
    }
};

export default contentApi;
