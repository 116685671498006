/* eslint-disable import/no-unused-modules */
import standardiseToLowerCase from '../standardiseToLowerCase';

const liveMatchStatuses = [
    'kick_off',
    'half_time',
    'half_time_started',
    'second_half_started'
];

const isLiveMatch = matchStatus => {
    if (typeof matchStatus !== 'string') {
        throw new Error('Invalid type for match status');
    }

    return liveMatchStatuses.includes(standardiseToLowerCase(matchStatus));
};

export default isLiveMatch;
