import { combineReducers } from 'redux';

import message from './modules/message';
import round from './modules/round';
import follow from './modules/follow';
import content from './modules/content';
import user from './modules/user';
import modal from './modules/modal';
import offer from './modules/offer';
import raf from './modules/raf';
import leaderboard from './modules/leaderboard';
import league from './modules/league';
import play from './modules/play';
import results from './modules/results';
import appBridge from './modules/appBridge';
import auth from './modules/auth';
import super6Extra from './modules/super6Extra';
import podcast from './modules/podcast';
import banner from './modules/banner';
import stats from './modules/stats';

export default combineReducers({
    message,
    round,
    content,
    user,
    follow,
    modal,
    offer,
    raf,
    leaderboard,
    play,
    results,
    league,
    appBridge,
    auth,
    super6Extra,
    podcast,
    banner,
    stats
});
