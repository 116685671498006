import styled from '@emotion/styled/macro';

// eslint-disable-next-line import/prefer-default-export
export const Grid = styled.div`
    display: grid;
    gap: ${props => props.theme.spacing.large};
    grid-template-columns: 1fr;
    margin: 0 ${props => props.theme.spacing.large};

    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        grid-template-columns: 1fr 1fr;
        margin: 0;
    }
`;
