import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    requestRoundResults,
    requestRoundPredictions,
    requestRoundPredictionsByUser
} from '../../store/modules/results/actions';
import {
    requestRounds,
    requestHeadToHead
} from '../../store/modules/round/actions';
import { predictions as predictionsProp, roundResults } from './propTypes';
import { PageContainer } from '../../components/Common/Page/styles';
import * as Styled from './styles';
import RoundFixtures from './components/RoundFixtures';
import RoundResult from '../../components/RoundResult';
import setTitle from '../../helpers/page';
import SkeletonResults from './SkeletonResults';
import { selectDoesBackButtonLinkExist } from '../../store/modules/content/selectors';
import ConnectedPeriodIdNavigation from '../../containers/PeriodIdNavigation';
import * as PeriodIdNavigationStyled from '../../containers/PeriodIdNavigation/styles';
import { selectHeadToHead } from '../../store/modules/round/selectors';
import PageTitle from '../../components/Common/PageTitle';
import MessageBlock from '../../components/MessageBlock';

export const ResultsPage = ({
    getRoundResults,
    getRoundPredictions,
    getRoundPredictionsForOtherUser,
    match,
    results,
    predictions,
    predictionsOtherUser,
    getRounds,
    history,
    backButtonLink,
    getHeadToHead,
    headToHead
}) => {
    const { periodId: roundId, period, userId } = match.params;
    if (!parseInt(roundId, 10)) {
        return (
            <PageContainer>
                <PageTitle>Results</PageTitle>
                <MessageBlock type="info">
                    <h3>No results are available yet</h3>
                    <p>
                        Results will be available after the 1st round of the
                        season is complete. Please check back soon.
                    </p>
                </MessageBlock>
            </PageContainer>
        );
    }
    useEffect(() => {
        setTitle(`Previous Results | Round ${roundId}`);
    }, [roundId]);

    useEffect(() => {
        getRoundResults(roundId);
    }, [roundId, getRoundResults]);

    useEffect(() => {
        getRounds();
    }, [getRounds]);

    useEffect(() => {
        if (userId) {
            getRoundPredictionsForOtherUser(roundId, userId);
        } else {
            getRoundPredictions(roundId);
        }
    }, [roundId, getRoundPredictionsForOtherUser, getRoundPredictions, userId]);

    useEffect(() => {
        getHeadToHead(roundId);
    }, [roundId]);

    const round = results[roundId];
    if (!round) {
        return <SkeletonResults />;
    }
    const {
        goldenGoalChallenge: { isGoldenGoalScored, scorerName, teamName, time },
        status,
        scoreChallenges
    } = round;

    const getPredictions = () => {
        if (userId) {
            return (
                predictionsOtherUser[userId] &&
                predictionsOtherUser[userId][roundId]
            );
        }
        return predictions[roundId];
    };

    const roundPredictions = getPredictions() ?? { matches: {} };

    const navTemplate = userId
        ? `/results/round/:id/user/${userId}`
        : `/results/round/:id`;

    const goldenGoal = {
        isGoldenGoalScored,
        goldenGoalScoredMinute: time,
        goldenGoalPredictionMinute: roundPredictions.goldenGoal,
        goldenGoalScorerName: scorerName,
        goldenGoalScorerTeam: teamName,
        inplay: status === 'inplay'
    };

    return (
        <PageContainer>
            <ConnectedPeriodIdNavigation
                period={period}
                periodId={Number(roundId)}
                urlTemplate={navTemplate}
                history={history}
                PeriodIdSelectorContainer={
                    PeriodIdNavigationStyled.PeriodIdSelectorContainer
                }
                backButtonLink={backButtonLink}
                bgGrey
            />
            <Styled.PaddedContainer>
                <Styled.PanelsContainer padVertical>
                    <RoundResult
                        roundNumber={Number(roundId)}
                        correctResultsPoints={
                            roundPredictions.resultPoints &&
                            roundPredictions.resultPoints.points
                        }
                        correctScoresPoints={
                            roundPredictions.scorePoints &&
                            roundPredictions.scorePoints.points
                        }
                        punditName={headToHead && headToHead.opponent.firstName}
                        goldenGoal={goldenGoal}
                        link={`/headtohead/results/${roundId}`}
                        roundStatus={status}
                        hasPredicted={roundPredictions.hasPredicted}
                        userId={userId}
                    />
                </Styled.PanelsContainer>

                <Styled.PanelsContainer padVertical>
                    <Styled.FixturesContainer>
                        <RoundFixtures
                            fixtures={scoreChallenges}
                            predictions={roundPredictions.matches}
                            userId={userId}
                        />
                    </Styled.FixturesContainer>
                </Styled.PanelsContainer>
            </Styled.PaddedContainer>
        </PageContainer>
    );
};

ResultsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            periodId: PropTypes.string,
            userId: PropTypes.string,
            period: PropTypes.string
        })
    }).isRequired,
    results: PropTypes.shape({ roundId: roundResults }).isRequired,
    predictions: PropTypes.shape({ roundId: predictionsProp }).isRequired,
    predictionsOtherUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        roundId: predictionsProp
    }).isRequired,
    getRoundResults: PropTypes.func.isRequired,
    getRoundPredictions: PropTypes.func.isRequired,
    getRoundPredictionsForOtherUser: PropTypes.func.isRequired,
    getRounds: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    backButtonLink: PropTypes.string,
    getHeadToHead: PropTypes.func.isRequired,
    headToHead: PropTypes.shape({
        beatPunditPercentage: PropTypes.number,
        isMasked: PropTypes.bool,
        winners: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                points: PropTypes.number
            })
        ),
        prizes: PropTypes.shape({
            quantity: PropTypes.number,
            value: PropTypes.string
        }),
        opponent: PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profileImage: PropTypes.string,
            winnerImage: PropTypes.string,
            loserImage: PropTypes.string
        })
    })
};

ResultsPage.defaultProps = {
    backButtonLink: null,
    headToHead: null
};

const mapStateToProps = (state, props) => ({
    results: state.results.rounds,
    roundId: state.results.rounds,
    predictions: state.results.predictions,
    predictionsOtherUser: state.results.predictionsOtherUser,
    backButtonLink: selectDoesBackButtonLinkExist(state),
    headToHead: selectHeadToHead(state, props.match.params.periodId)
});

const mapDispatchToProps = dispatch => ({
    getRoundResults: roundId => {
        dispatch(requestRoundResults(roundId));
    },
    getRoundPredictions: (roundId, userId) => {
        dispatch(requestRoundPredictions(roundId, userId));
    },
    getRoundPredictionsForOtherUser: (roundId, userId) => {
        dispatch(requestRoundPredictionsByUser(roundId, userId));
    },
    getRounds: () => dispatch(requestRounds()),
    getHeadToHead: roundId => dispatch(requestHeadToHead(roundId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);
