import * as types from './types';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    loading: false,
    banner: null,
    storageBannerId: null,
    isBannerClosed: null,
    checkedStorageBannerId: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REQUEST_BANNER:
            return newIfChanged(state, {
                ...state,
                loading: true
            });
        case types.RECEIVE_BANNER:
            return newIfChanged(state, {
                ...state,
                loading: false,
                banner: action.data,
                checkedStorageBannerId: false
            });
        case types.REQUEST_BANNER_ID:
            return newIfChanged(state, {
                ...state,
                checkedStorageBannerId: false
            });
        case types.RECEIVE_BANNER_ID:
            return newIfChanged(state, {
                ...state,
                storageBannerId: action.data,
                checkedStorageBannerId: true
            });
        case types.SET_BANNER_CLOSE:
            return newIfChanged(state, {
                ...state,
                isBannerClosed: true
            });
        default:
            return state;
    }
};
