import styled from '@emotion/styled/macro';

export const Container = styled.div`
    display: inline-flex;
`;

export const TextInRed = styled.div`
    background-color: ${props => props.theme.colours.red};
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.bold};
    height: fit-content;
`;

export const TextInBlue = styled.div`
    font-family: ${props => props.theme.fonts.bold};
    color: ${props => props.theme.colours.colorOnPrimary};
`;

export const Text = styled.div`
    padding-left: ${props => props.theme.spacing.small};
    padding-right: ${props => props.theme.spacing.small};
    padding-top: ${props => props.theme.spacing.xsmall};
    padding-bottom: ${props => props.theme.spacing.xsmall};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 2vw, ${props.theme.fontSize.small})`};
`;
