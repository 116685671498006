import React from 'react';
import * as Modal from '../styles';
import Link from '../../Common/Link';
import Button from '../../Common/Button';

const ContactUsForm = () => (
    <div>
        <Modal.TitleContainer>
            <Modal.Title>Contact Us</Modal.Title>
            <Modal.TitleParagraph>
                If you need customer support, then please contact us using one
                of the following methods.
            </Modal.TitleParagraph>
            <Modal.TitleParagraph>
                {`Before you contact us for help, please take a look around the site, including the `}
                <Link href="/faq">FAQs section</Link>.
            </Modal.TitleParagraph>
            <Button
                icon="/content/img/logos/X.png"
                textColour="black"
                link="https://x.com/super6"
                isSocialButton
            >
                X (Formerly Twitter)
            </Button>
            <Button
                icon="/content/img/logos/facebook-02.png"
                textColour="black"
                link="https://www.facebook.com/SUPER6"
                isSocialButton
            >
                Facebook
            </Button>
            <Button
                icon="/content/img/logos/Instagram.png"
                textColour="black"
                link="https://www.instagram.com/super6"
                isSocialButton
            >
                Instagram
            </Button>
            <Button
                icon="/content/img/logos/Mail.png"
                textColour="black"
                link="mailto:super6@skybet.com?subject=Super%206%20customer%20support"
                isSocialButton
            >
                Super6@skybet.com
            </Button>
        </Modal.TitleContainer>
    </div>
);

export default ContactUsForm;
