import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    requestGlobalLeaderboard,
    requestLeaderboardForSelf
} from '../../../store/modules/leaderboard/actions';
import {
    selectLeaderboard,
    selectSelfLeaderboard
} from '../../../store/modules/leaderboard/selectors';
import Alert from '../../../components/Common/Alert';
import ConnectedLeaderboardTypeNavigation from '../components/LeaderboardTypeNavigation';
import ConnectedScoreTable from '../containers/ScoreTable';
import MyPositionRow from '../../../components/LeaderboardTable/MyPositionRow';

export const Global = ({
    period,
    periodId,
    userId,
    selfLeaderboard,
    getLeaderboardForSelf,
    leaderboard,
    getGlobalLeaderboard
}) => {
    useEffect(() => {
        if (leaderboard) {
            return;
        }

        getGlobalLeaderboard(period, periodId);
    }, [period, periodId, leaderboard, getGlobalLeaderboard]);

    useEffect(() => {
        if (!userId || selfLeaderboard) {
            return;
        }

        getLeaderboardForSelf(period, periodId);
    }, [userId, getLeaderboardForSelf, period, periodId, selfLeaderboard]);

    const loadedWithResults = leaderboard && leaderboard.results.length > 0;
    if (!loadedWithResults) {
        return (
            <>
                <ConnectedLeaderboardTypeNavigation
                    title="Global Top 15"
                    prev="pundits"
                    next="following"
                />
                <Alert type="Warning">
                    This table is being calculated, please check back later
                </Alert>
            </>
        );
    }

    return (
        <>
            {selfLeaderboard && (
                <MyPositionRow
                    myScore={selfLeaderboard}
                    testId="current-player-row"
                />
            )}
            <ConnectedLeaderboardTypeNavigation
                title="Global Top 15"
                prev="pundits"
                next="following"
            />
            <ConnectedScoreTable
                rows={leaderboard.results}
                testId="leaderboard-table-global-top"
            />
        </>
    );
};

const mapStateToProps = (state, props) => ({
    userId: state.user.info.userId,
    selfLeaderboard: selectSelfLeaderboard(state, props.period, props.periodId),
    leaderboard: selectLeaderboard(
        state,
        props.period,
        props.periodId,
        'global'
    )
});

const mapDispatchToProps = dispatch => ({
    getLeaderboardForSelf: (period, id) => {
        dispatch(requestLeaderboardForSelf(period, id));
    },
    getGlobalLeaderboard: (period, id) => {
        dispatch(requestGlobalLeaderboard(period, id));
    }
});

Global.propTypes = {
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    userId: PropTypes.number,
    selfLeaderboard: PropTypes.shape({
        userId: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.number,
        isWinner: PropTypes.bool.isRequired,
        correctResults: PropTypes.number,
        correctScores: PropTypes.number,
        points: PropTypes.number
    }),
    getLeaderboardForSelf: PropTypes.func.isRequired,
    leaderboard: PropTypes.shape({
        results: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                name: PropTypes.string,
                position: PropTypes.number,
                isWinner: PropTypes.bool.isRequired,
                correctResults: PropTypes.number,
                correctScores: PropTypes.number,
                points: PropTypes.number
            })
        ).isRequired
    }),
    getGlobalLeaderboard: PropTypes.func.isRequired
};

Global.defaultProps = {
    periodId: null,
    userId: null,
    selfLeaderboard: null,
    leaderboard: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Global);
