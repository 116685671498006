import styled from '@emotion/styled/macro';

export const SummaryList = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
`;

export const SummaryListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid
        ${props => props.theme?.card?.border ?? props.theme.colours.borderGrey};
    border-radius: ${props => props.theme?.card?.borderRadius ?? '4px'};
    background-color: ${props => props.theme?.card?.bgColour ?? 'transparent'};
    height: auto;
    margin-bottom: ${props => props.theme.spacing.normal};
    box-sizing: border-box;

    width: 100%;
    @media (min-width: ${props => props.theme.widths.tabletMax}) {
        width: 32%;
        margin-left: 1%;
    }
`;
