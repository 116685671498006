import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import ValidateMatchIndex from './validation/validateMatchIndex';
import ValidatePosition from './validation/validatePosition';

const Teams = ({
    matchIndex,
    borderRadius,
    position,
    homeTeam,
    awayTeam,
    padding
}) => (
    <>
        <Styled.TeamsContainer
            matchIndex={matchIndex}
            borderRadius={borderRadius}
            position={position}
            padding={padding}
        >
            <Styled.HomeBadgeContainer>
                <Styled.BadgeContainer>
                    <Styled.Badge src={homeTeam.img} alt={homeTeam.name} />
                </Styled.BadgeContainer>
            </Styled.HomeBadgeContainer>
            <Styled.AwayBadgeContainer>
                <Styled.BadgeContainer>
                    <Styled.Badge src={awayTeam.img} alt={awayTeam.name} />
                </Styled.BadgeContainer>
            </Styled.AwayBadgeContainer>
        </Styled.TeamsContainer>
    </>
);

Teams.propTypes = {
    matchIndex: ValidateMatchIndex,
    borderRadius: PropTypes.shape({
        topLeft: PropTypes.string,
        topRight: PropTypes.string,
        bottomRight: PropTypes.string,
        bottomLeft: PropTypes.string
    }),
    position: ValidatePosition,
    homeTeam: PropTypes.shape({
        name: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired
    }).isRequired,
    awayTeam: PropTypes.shape({
        name: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired
    }).isRequired,
    padding: PropTypes.oneOf(['s', 'm'])
};

Teams.defaultProps = {
    matchIndex: 0,
    borderRadius: {
        topLeft: '0',
        topRight: '0',
        bottomRight: '0',
        bottomLeft: '0'
    },
    position: 'center',
    padding: 'm'
};

export default Teams;
