/* eslint-disable import/no-unused-modules */
import styled from '@emotion/styled/macro';

export const InfoIconParent = styled.div`
    display: flex;
    align-items: center;

    > div {
        color: ${props =>
            props.active
                ? props.theme.colours.white
                : props.theme.colours.mineShaft};
        border: 1px solid
            ${props =>
                props.active
                    ? props.theme.tabs.color
                    : props.theme.colours.mineShaft};
        background: ${props =>
            props.active
                ? props.theme.tabs.color
                : props.theme.colours.mineShaft};
    }
`;
export const InformationIcon = styled.div`
    width: 20px;
    min-width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: lowercase;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.large};
`;
