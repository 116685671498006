import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeMember as removeMemberAction } from '../../../../store/modules/league/actions';
import Confirmation from '../../../../components/Modals/Confirmation';

const RemoveMemberConfirmation = ({
    slug,
    userId,
    name,
    removeMember,
    onDismiss
}) => (
    <Confirmation
        title="Remove User"
        message="Are you sure you want to"
        messageSecondLine="remove this user?"
        confirmLabel="Remove"
        onConfirm={() => removeMember(slug, userId, name)}
        onDismiss={onDismiss}
    />
);

const mapDispatchToProps = dispatch => ({
    removeMember: (slug, userId, name) =>
        dispatch(removeMemberAction(slug, userId, name))
});

RemoveMemberConfirmation.propTypes = {
    onDismiss: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    removeMember: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(RemoveMemberConfirmation);
