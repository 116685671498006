import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';
import TextInBox from '../Common/TextInBox';

export default function index({ textInRed, textInBlue }) {
    return (
        <Styled.Container>
            <TextInBox text={textInRed} variant="red" />
            {textInBlue && <TextInBox text={textInBlue} colour="blue" />}
        </Styled.Container>
    );
}

index.propTypes = {
    textInRed: PropTypes.string.isRequired,
    textInBlue: PropTypes.string
};

index.defaultProps = {
    textInBlue: null
};
