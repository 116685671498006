import styled from '@emotion/styled/macro';

export const TeamsContainer = styled.div`
    background: ${props =>
        props.theme.match?.colourIndex?.[props.matchIndex]?.background ??
        props.theme.colours.white};
    border-top-left-radius: ${props => props.borderRadius?.topLeft};
    border-top-right-radius: ${props => props.borderRadius?.topRight};
    border-bottom-left-radius: ${props => props.borderRadius?.bottomLeft};
    border-bottom-right-radius: ${props => props.borderRadius?.bottomRight};
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    ${props =>
        props.padding === 'm' &&
        `
            padding: 1.5em;

            @media (max-width: ${props.theme.hero.widths.mobileMax}) {
                padding: 0.8em;
            }
        `}

    ${props =>
        props.padding === 's' &&
        `
            padding: 0.5em;

            @media (max-width: ${props.theme.hero.widths.mobileMax}) {
                padding: 0.3em;
            }
        `}
`;

export const HomeBadgeContainer = styled.div`
    padding-right: ${props => props.theme.spacing.small};
`;

export const AwayBadgeContainer = styled.div`
    padding-left: ${props => props.theme.spacing.small};
`;

export const BadgeContainer = styled.div`
    background: #fff;
    border-radius: 50%;
    width: ${props => props.width ?? '75px'};
    height: ${props => props.width ?? '75px'};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: ${props => props.width ?? '35px'};
        height: ${props => props.width ?? '35px'};
    }

    @media (max-width: ${props => props.theme.hero.widths.smallMobile}) {
        width: ${props => props.width ?? '30px'};
        height: ${props => props.width ?? '30px'};
    }

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Badge = styled.img`
    width: ${props => props.width ?? '50px !important'};
    height: ${props => props.width ?? '50px'};

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        width: ${props => props.width ?? '20px !important'};
        height: ${props => props.width ?? '20px'};
    }

    @media (max-width: ${props => props.theme.hero.widths.smallMobile}) {
        width: ${props => props.width ?? '16px !important'};
        height: ${props => props.width ?? '16px'};
    }
`;
