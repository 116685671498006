// eslint-disable-next-line import/prefer-default-export
export const getOrdinalIndicator = number => {
    const numberString = `${number}`;
    const lastNumber = numberString.substr(-1, 1);

    if (numberString.length >= 2) {
        const secondLastNumber = numberString.substr(-2, 1);
        if (secondLastNumber === '1') {
            return 'th';
        }
    }

    switch (lastNumber) {
        case '1':
            return 'st';
        case '2':
            return 'nd';
        case '3':
            return 'rd';
        default:
            return 'th';
    }
};
