import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const LastRoundScore = ({ homeScore, awayScore }) => {
    const homeScoreString = homeScore !== null ? homeScore : '-';
    const awayScoreString = awayScore !== null ? awayScore : '-';

    return (
        <Styled.ScoreContainer>
            <Styled.Score>
                <span>{homeScoreString}</span>
            </Styled.Score>
            <Styled.Vs>v</Styled.Vs>

            <Styled.Score>
                <span>{awayScoreString}</span>
            </Styled.Score>
        </Styled.ScoreContainer>
    );
};

LastRoundScore.defaultProps = {
    homeScore: null,
    awayScore: null
};

LastRoundScore.propTypes = {
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LastRoundScore;
