import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    selectActiveRoundStatus,
    selectIsRoundPaused
} from '../../store/modules/round/selectors';
import ConnectedPredictedStatusWrapper from './PredictedStatusWrapper';
import { requestActiveRound } from '../../store/modules/round/actions';
import {
    areFeaturesLoaded,
    isFeatureOn
} from '../../store/modules/content/selectors';
import { SEASON_CLOSED } from '../../features';
import Spinner from '../../components/Common/Spinner';

export const RoundStatusWrapper = ({
    loadActiveRound,
    activeRoundStatus,
    isSeasonClosed,
    featuresLoaded,
    isPaused
}) => {
    useEffect(() => {
        if (featuresLoaded && !isSeasonClosed) {
            loadActiveRound();
        }
    }, [loadActiveRound, featuresLoaded, isSeasonClosed]);

    if (featuresLoaded && isSeasonClosed) {
        return <Redirect to="/schedule" />;
    }

    if (!featuresLoaded || !activeRoundStatus) {
        return <Spinner />;
    }

    if (activeRoundStatus !== 'open') {
        return <Redirect to="/inplay" />;
    }

    if (isPaused) {
        return <Redirect to="/" />;
    }

    return <ConnectedPredictedStatusWrapper />;
};

RoundStatusWrapper.propTypes = {
    loadActiveRound: PropTypes.func.isRequired,
    activeRoundStatus: PropTypes.string,
    isSeasonClosed: PropTypes.bool.isRequired,
    featuresLoaded: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool
};

RoundStatusWrapper.defaultProps = {
    activeRoundStatus: null,
    isPaused: null
};

const mapStateToProps = state => ({
    activeRoundStatus: selectActiveRoundStatus(state),
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED, false),
    featuresLoaded: areFeaturesLoaded(state),
    isPaused: selectIsRoundPaused(state)
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundStatusWrapper);
