import styled from '@emotion/styled/macro';

export const H2HBannerContainer = styled.div`
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
    }
`;

export const H2HBannerBox = styled.div`
    background: ${props => props.theme.colours.white};
    border-radius: ${props => props.theme.radius.normal};
    padding: 20px 0 0 0;
`;

export const H2HBannerContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${props => props.theme.spacing.medium};
    padding: 0 12px;
`;

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: ${props => props.theme.spacing.normal};
`;
export const ImageContent = styled.div`
    display: flex;
    align-items: end;
    justify-content: center;
    & img {
        width: 50%;
        min-width: 105px;
    }
`;

export const H2HHashtagLine = styled.h2`
    color: ${props => props.theme.colours.bluePrimary};
    font-weight: 500;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.xsmall};
    padding-bottom: ${props => props.theme.spacing.small};

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.largeText && props.theme.fontSize.medium};
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props => props.largeText && props.theme.fontSize.xlarge};
    }
`;

export const Super6Logo = styled.img`
    padding-top: ${props => props.theme.spacing.xsmall};
    padding-bottom: ${props => props.theme.spacing.small};
    width: 80%;

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        width: 90%;
        padding-top: ${props => props.theme.spacing.small};
        padding-bottom: ${props => props.theme.spacing.normal};
    }
`;

export const H2HChallengeLine = styled.h3`
    color: ${props => props.theme.colours.bluePrimary};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.xsmall};
    font-weight: 700;

    padding-bottom: ${props => props.theme.spacing.small};

    @media (min-width: ${props => props.theme.widths.largeMobile}) {
        font-size: ${props => props.theme.fontSize.normal};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.medium};
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props => props.largeText && props.theme.fontSize.xxlarge};
    }
`;

export const H2HFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => `0 0 ${props.theme.radius.normal}
        ${props.theme.radius.normal}`};
    background: ${props => props.theme.colours.bluePrimary};
    padding: ${props =>
        `${props.theme.spacing.normal} ${props.theme.spacing.medium} ${props.theme.spacing.medium} ${props.theme.spacing.medium}`};
`;

export const H2HPrizeLine = styled.h2`
    text-align: center;
    padding-right: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.xsmall};
    font-weight: 700;

    @media (min-width: ${props => props.theme.widths.largeMobile}) {
        font-size: ${props => props.theme.fontSize.normal};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large};
    }
`;

export const H2HPrizeValue = styled.h4`
    background: ${props => props.theme.colours.greenPrimary};
    color: ${props => props.theme.colours.bluePrimary};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-size: ${props => props.theme.fontSize.xsmall};
    font-weight: 700;
    padding: ${props => props.theme.spacing.small};
    text-align: center;
    border-radius: ${props => props.theme.radius.xsmall};

    @media (min-width: ${props => props.theme.widths.largeMobile}) {
        font-size: ${props => props.theme.fontSize.normal};
    }

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.large};
    }
`;
