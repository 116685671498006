import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { updateGoldenGoal } from '../../../../store/modules/play/actions';
import { roundResults } from '../../../Results/propTypes';
import { requestRoundResults } from '../../../../store/modules/results/actions';
import { requestRounds } from '../../../../store/modules/round/actions';
import { getOrdinalIndicator } from '../../../../helpers/numberFormatters';
import LoadingGoldenGoalSkeleton from './LoadingGoldenGoalSkeleton';
import { selectLatestCompletedRoundId } from '../../../../store/modules/round/selectors';

const GoldenGoalPrediction = ({
    underlined,
    lastRoundId,
    getRoundResults,
    rounds,
    getRounds,
    goldenGoalTime,
    updateGoldenGoalTime,
    errorState
}) => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        getRounds();
    }, [getRounds]);

    useEffect(() => {
        if (lastRoundId) {
            getRoundResults(lastRoundId);
            setIsOpen(false);
        }
    }, [lastRoundId, getRoundResults]);

    const goldenGoal = {};

    const updateInput = inputVal => {
        const inputValue = Number(inputVal);
        if (inputValue < 0 || inputValue > 90 || Number.isNaN(inputValue)) {
            return;
        }
        updateGoldenGoalTime(inputValue);
    };

    const toggle = () => {
        if (lastRoundId) {
            setIsOpen(!isOpen);
        }
    };

    if (lastRoundId) {
        const round = rounds[lastRoundId];
        if (!round) {
            return <LoadingGoldenGoalSkeleton />;
        }
        ({
            scorerName: goldenGoal.scorerName,
            teamName: goldenGoal.teamName,
            time: goldenGoal.scoredMinute
        } = round.goldenGoalChallenge);
    }

    return (
        <Styled.GoldenGoalContainer underlined={underlined}>
            <Styled.GoldenGoalTitleContainer>
                <Styled.GoldenGoalTitleSection>
                    <Styled.GoldenGoalTitleIcon />
                    <Styled.GoldenGoalTitleText>
                        Golden Goal
                    </Styled.GoldenGoalTitleText>
                </Styled.GoldenGoalTitleSection>
                {isOpen || !lastRoundId ? (
                    <Styled.GoldenGoalToggleSectionOpen
                        onClick={toggle}
                        data-test-id="toggle-text-open"
                    >
                        <Styled.GoldenGoalToggleIconOpen />
                        <Styled.GoldenGoalToggleTextOpen>
                            What&apos;s this
                        </Styled.GoldenGoalToggleTextOpen>
                    </Styled.GoldenGoalToggleSectionOpen>
                ) : (
                    <Styled.GoldenGoalToggleSection
                        onClick={toggle}
                        data-test-id="toggle-text"
                    >
                        <Styled.GoldenGoalToggleIcon />
                        <Styled.GoldenGoalToggleText>
                            What&apos;s this
                        </Styled.GoldenGoalToggleText>
                    </Styled.GoldenGoalToggleSection>
                )}
            </Styled.GoldenGoalTitleContainer>

            <Styled.GoldenGoalTextContainer
                isOpen={isOpen}
                data-test-id="golden-goal-tiebreaker-container"
            >
                <Styled.GoldenGoalText>
                    <span>Tiebreaker:</span> Please predict the minute of the
                    first goal in any of the 6 games. Your golden goal
                    prediction will be used if a tie break is needed to
                    determine jackpot winners.
                </Styled.GoldenGoalText>
            </Styled.GoldenGoalTextContainer>

            {lastRoundId && (
                <Styled.GoldenGoalText data-test-id="golden-goal-scorer-data">
                    Last rounds golden goal was scored by{' '}
                    {goldenGoal.scorerName} ({goldenGoal.teamName}) in the{' '}
                    <span>
                        {goldenGoal.scoredMinute}
                        {getOrdinalIndicator(goldenGoal.scoredMinute)}
                        &nbsp;minute
                    </span>
                </Styled.GoldenGoalText>
            )}

            <Styled.GoldenGoalInput className={errorState ? 'inputError' : ''}>
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder="Predict the minute"
                    onChange={e => updateInput(e.target.value)}
                    value={goldenGoalTime || ''}
                    data-test-id={
                        errorState
                            ? 'play-golden-goal-input-error'
                            : 'play-golden-goal-input'
                    }
                />
            </Styled.GoldenGoalInput>
            {errorState && (
                <Styled.GoldenGoalInputErrorSection data-test-id="play-golden-goal-error-section">
                    <Styled.GoldenGoalInputErrorIcon />
                    <Styled.GoldenGoalInputErrorText>
                        {' '}
                        Select your golden goal
                    </Styled.GoldenGoalInputErrorText>
                </Styled.GoldenGoalInputErrorSection>
            )}
        </Styled.GoldenGoalContainer>
    );
};

GoldenGoalPrediction.defaultProps = {
    underlined: false,
    goldenGoalTime: null,
    lastRoundId: null
};

GoldenGoalPrediction.propTypes = {
    errorState: PropTypes.bool.isRequired,
    updateGoldenGoalTime: PropTypes.func.isRequired,
    goldenGoalTime: PropTypes.number,
    underlined: PropTypes.bool,
    lastRoundId: PropTypes.number,
    getRounds: PropTypes.func.isRequired,
    getRoundResults: PropTypes.func.isRequired,
    rounds: PropTypes.shape({ roundId: roundResults }).isRequired
};

const mapStateToProps = state => ({
    goldenGoalTime: state.play.goldenGoal.time,
    rounds: state.results.rounds,
    lastRoundId: selectLatestCompletedRoundId(state)
});

const mapDispatchToProps = dispatch => ({
    updateGoldenGoalTime: goldenGoalTime =>
        dispatch(updateGoldenGoal(goldenGoalTime)),
    getRoundResults: lastRoundId => {
        dispatch(requestRoundResults(lastRoundId));
    },
    getRounds: () => dispatch(requestRounds())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoldenGoalPrediction);
