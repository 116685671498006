import * as types from './types';
import * as userTypes from '../user/types';
import getPredictionsFromPayload from '../../../helpers/predictionHelper';
import { newIfChanged } from '../../../helpers/stateHelper';

const defaultState = {
    roundId: null,
    isSubmitting: false,
    predictions: null,
    fullPredictions: null,
    goldenGoal: {
        id: null,
        time: null
    },
    postingPredictions: false,
    results: {},
    scores: {},
    totals: {}
};

function receiveDefaultRound(workingState, action) {
    if (workingState.predictions) {
        return workingState;
    }
    return {
        ...workingState,
        roundId: action.payload.id,
        predictions: action.payload.scoreChallenges.reduce(
            (predictionObj, challenge) => ({
                ...predictionObj,
                [challenge.match.id]: {
                    scoreHome: 0,
                    scoreAway: 0,
                    challengeId: challenge.id
                }
            }),
            {}
        ),
        goldenGoal: {
            challengeId: action.payload.goldenGoalChallenge.id,
            time: 0
        }
    };
}

function receiveActiveRoundPredictions(workingState, action) {
    if (!action.payload.hasPredicted) {
        return { ...workingState };
    }
    return {
        ...workingState,
        roundId: action.payload.roundId,
        fullPredictions: getPredictionsFromPayload(action.payload),
        predictions: action.payload.predictions.scores.reduce(
            (predictionObj, match) => ({
                ...predictionObj,
                [match.matchId]: {
                    scoreHome: match.scoreHome,
                    scoreAway: match.scoreAway,
                    challengeId: match.challengeId
                }
            }),
            {}
        ),
        goldenGoal: action.payload.predictions.goldenGoal,
        results: action.payload.results,
        scores: action.payload.scores,
        totals: action.payload.total
    };
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.RECEIVE_DEFAULT_ROUND:
            return newIfChanged(
                state,
                receiveDefaultRound(
                    state.roundId === action.payload.id ? state : defaultState,
                    action
                )
            );

        case userTypes.RECEIVE_ACTIVE_ROUND_PREDICTIONS:
            return newIfChanged(
                state,
                receiveActiveRoundPredictions(
                    state.roundId === action.payload.roundId
                        ? state
                        : defaultState,
                    action
                )
            );

        case types.UPDATE_PREDICTION:
            return newIfChanged(state, {
                ...state,
                predictions: {
                    ...state.predictions,
                    [action.matchId]: {
                        ...state.predictions[action.matchId],
                        [action.scoreToUpdate]: action.score
                    }
                }
            });

        case types.UPDATE_GOLDEN_GOAL:
            return newIfChanged(state, {
                ...state,
                goldenGoal: {
                    ...state.goldenGoal,
                    time: action.value
                }
            });

        case types.POST_PREDICTIONS:
            return newIfChanged(state, {
                ...state,
                postingPredictions: true
            });

        case types.POST_PREDICTIONS_SUCCESS:
            return newIfChanged(state, {
                ...state,
                postingPredictions: false,
                goldenGoal: {
                    ...state.goldenGoal,
                    time: state.goldenGoal.time || 1
                }
            });
        case types.SUBMISSION_STATUS:
            return newIfChanged(state, {
                ...state,
                isSubmitting: action.inFlight
            });
        default:
            return state;
    }
};
