import styled from '@emotion/styled/macro';
import { TeamContainer } from '../../../../components/Team/styles';

export const MatchContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colours.white};
    box-sizing: border-box;
    border-radius: ${props => props.theme.radius.normal};
`;

export const MatchRow = styled.div`
    padding-left: ${props => props.theme.spacing.xlarge};
    padding-right: ${props => props.theme.spacing.xlarge};
    display: ${props => props.top && 'flex'};
    justify-content: ${props => props.top && 'space-between'};
    padding-top: ${props => props.top && props.theme.spacing.large};
    padding-bottom: ${props => props.bottom && props.theme.spacing.large};
    flex: ${props => props.top && '1'};
`;

export const GridItem = styled.div`
    display: flex;
    justify-content: center;
    width: 33%;
    max-width: ${props => props.width || '30%'};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 90%;
        max-width: unset;

        ${TeamContainer} {
            width: 90%;
        }
    }
`;

export const MatchScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 150px;
    min-width: 100px;
    height: 100%;
`;

export const MatchStatusContainer = styled.div`
    align-self: center;
    height: 17px;
`;

export const MatchLine = styled.div`
    border-top: 1px solid ${props => props.theme.colours.brightGray};
`;

export const MatchFooter = styled.div`
    border-bottom-left-radius: ${props => props.theme.spacing.vlarge};
    border-bottom-right-radius: ${props => props.theme.spacing.vlarge};
    background-color: ${props => props.theme.scores?.secondaryBgColour};
`;
