import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import LastRoundFixtures from '../LastRoundFixtures';
import LastRoundPointsLabel from '../LastRoundPointsLabel';
import LastRoundPredictionAndScore from '../LastRoundPredictionAndScore';

const LastRoundFixtureGroup = ({
    points,
    homeTeam,
    awayTeam,
    predictedHomeScore,
    homeScore,
    predictedAwayScore,
    awayScore,
    isAbandoned,
    index
}) => (
    <Styled.OuterContainer data-test-id={`match-container-${index}`}>
        <Styled.InnerContainerBorder />
        <LastRoundPointsLabel points={points} />
        <LastRoundFixtures homeTeam={homeTeam} awayTeam={awayTeam} />
        <LastRoundPredictionAndScore
            predictedHomeScore={predictedHomeScore}
            homeScore={homeScore}
            predictedAwayScore={predictedAwayScore}
            awayScore={awayScore}
            isAbandoned={isAbandoned}
        />
    </Styled.OuterContainer>
);

LastRoundFixtureGroup.defaultProps = {
    points: null,
    predictedHomeScore: null,
    predictedAwayScore: null,
    homeScore: null,
    awayScore: null,
    isAbandoned: false
};

LastRoundFixtureGroup.propTypes = {
    points: PropTypes.number,
    homeTeam: PropTypes.shape({
        badgeUri: PropTypes.string
    }).isRequired,
    awayTeam: PropTypes.shape({
        badgeUri: PropTypes.string
    }).isRequired,
    predictedHomeScore: PropTypes.number,
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    predictedAwayScore: PropTypes.number,
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAbandoned: PropTypes.bool,
    index: PropTypes.number.isRequired
};

export default LastRoundFixtureGroup;
