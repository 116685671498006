import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Score from '../Score';

const PredictionAndScore = ({
    isCorrectScore,
    isCorrectResult,
    predictedHomeScore,
    predictedAwayScore,
    homeScore,
    awayScore,
    isLive,
    isPreLive,
    isAbandoned,
    userId
}) => {
    const getScore = score => {
        if (isAbandoned) {
            return 'P';
        }
        if (isPreLive) {
            return null;
        }

        return score || 0;
    };

    let matchStatusText;

    if (isPreLive) {
        matchStatusText = 'NOT STARTED';
    } else if (isLive) {
        matchStatusText = 'LIVE SCORE';
    } else if (isAbandoned) {
        matchStatusText = 'MATCH POSTPONED';
    } else {
        matchStatusText = 'FINAL SCORE';
    }

    return (
        <Styled.PredictionAndScoreContainer>
            <Styled.ScoreTitle>
                {userId ? 'THEY' : 'YOU'} PREDICTED
            </Styled.ScoreTitle>
            <Score
                homeScore={isAbandoned ? 'P' : predictedHomeScore}
                awayScore={isAbandoned ? 'P' : predictedAwayScore}
                correctResult={isCorrectResult}
                correctScore={isCorrectScore}
                className="prediction-row"
                testId="prediction-row"
            />
            <Styled.ScoreTitle data-test-id="match-status-text">
                {matchStatusText}
            </Styled.ScoreTitle>
            <Score
                homeScore={getScore(homeScore)}
                awayScore={getScore(awayScore)}
                className="score-row"
                isScore
                testId={`score-row${isLive ? '-live' : ''}`}
            />
        </Styled.PredictionAndScoreContainer>
    );
};

PredictionAndScore.defaultProps = {
    predictedHomeScore: null,
    predictedAwayScore: null,
    homeScore: null,
    awayScore: null,
    isLive: false,
    isPreLive: false,
    isAbandoned: false,
    userId: null
};

PredictionAndScore.propTypes = {
    isCorrectScore: PropTypes.bool.isRequired,
    isCorrectResult: PropTypes.bool.isRequired,
    predictedHomeScore: PropTypes.number,
    predictedAwayScore: PropTypes.number,
    homeScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    awayScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLive: PropTypes.bool,
    isPreLive: PropTypes.bool,
    isAbandoned: PropTypes.bool,
    userId: PropTypes.string
};

export default PredictionAndScore;
