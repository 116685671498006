export const MAXIMUM_FOLLOWEE_LIMIT_ERROR =
    'You’ve reached the limit, it looks like you are already following 50 users.';

export const MAXIMUM_LEAGUE_LIMIT_ERROR =
    'You’ve reached the limit, it looks like you are already a member of 50 leagues.';

export const ALREADY_LEAGUE_MEMBER_ERROR =
    'Oops, it looks like you are already a member of this league.';

export const INVALID_LEAGUE_PIN_ERROR =
    'The league pin you provided is invalid. Please enter a correct pin and try again.';
