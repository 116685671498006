/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import theme from '../../../../styles/themes/standard';
import {
    getFormattedLongDateTime,
    getFormattedShortDate
} from '../../../../helpers/dateFormatters';

const getIsMobile = () => {
    const { innerWidth: width } = window;
    const mobileWidth = parseInt(theme.widths.mobileMax, 10);
    return width <= mobileWidth;
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
        function handleResize() {
            setIsMobile(getIsMobile());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const getPlayButtonLinkText = (
    paused,
    status,
    openDate,
    hasPredictedS6,
    roundType
) => {
    if (paused) {
        return 'Check Back Soon';
    }

    if (status === 'inplay' && roundType === 'super6') {
        return 'View Current Round';
    }

    if (status === 'inplay' && roundType === 'super6extra') {
        return 'View Live Round';
    }

    if (status === 'complete') {
        return 'View Results';
    }

    if (status === 'open' && roundType === 'super6extra') {
        return 'Play For Free';
    }
    if (status === 'open' && !hasPredictedS6) {
        return 'Play For Free';
    }
    if (status === 'open' && hasPredictedS6) {
        return 'View Predictions';
    }
    if (status === 'future') {
        return `Round Opens ${getFormattedShortDate(openDate)}`;
    }
    return 'Check Back Soon';
};

const getPlayButtonLink = (roundType, paused, status, hasPredicted) => {
    if (paused || status === 'future') {
        return null;
    }
    if (roundType === 'super6') {
        if (status === 'inplay' || status === 'complete') {
            return '/inplay';
        }
        if (hasPredicted) {
            return '/played';
        }
        if (status === 'open' && !hasPredicted) {
            return '/play';
        }
        return null;
    }
    if (roundType === 'super6extra') {
        return 'https://auth.skybetservices.com/s6extra?dcmp=s6_hero';
    }

    return null;
};

const getRoundInfoText = (roundType, status, endDateTime, roundName) => {
    if (status === 'open' || status === 'future') {
        return `Entries by ${getFormattedLongDateTime(
            endDateTime
        )}. T&Cs apply`;
    }
    return roundType === 'super6'
        ? `Round ${roundName} has kicked off.`
        : `${roundName} has kicked off.`;
};

const logos = {
    super6: '/content/img/logos/super6-logo.png',
    super6extra: '/content/img/logos/super6-extra.png'
};

export const formatRounds = (rounds, hasPredicted) =>
    rounds && rounds.length > 0
        ? rounds.map(round => ({
              id: round.id,
              roundType: round.roundType,
              heroImageDesktop:
                  round.assets && round.assets.mastheadDesktopImageset
                      ? round.assets.mastheadDesktopImageset
                      : {},
              heroImageMobile:
                  round.assets && round.assets.mastheadMobileImageset
                      ? round.assets.mastheadMobileImageset
                      : {},
              prizeImageDesktop:
                  round.assets && round.assets.prizeDesktopImageset
                      ? round.assets.prizeDesktopImageset
                      : {},
              prizeImageMobile:
                  round.assets && round.assets.prizeMobileImageset
                      ? round.assets.prizeMobileImageset
                      : {},
              roundInfoText: getRoundInfoText(
                  round.roundType,
                  round.status,
                  round.endDateTime,
                  round.roundName
              ),
              link: getPlayButtonLink(
                  round.roundType,
                  round.isPaused,
                  round.status,
                  hasPredicted
              ),
              linkText: getPlayButtonLinkText(
                  round.isPaused,
                  round.status,
                  round.startDateTime,
                  hasPredicted,
                  round.roundType
              ),
              logoPath: logos[round.roundType]
          }))
        : null;

export const borderRadiusMap = new Map([
    [
        0,
        {
            topLeft: '16px',
            topRight: '0',
            bottomRight: '0',
            bottomLeft: '0'
        }
    ],
    [
        1,
        {
            topLeft: '0',
            topRight: '0',
            bottomRight: '0',
            bottomLeft: '0'
        }
    ],
    [
        2,
        {
            topLeft: '0',
            topRight: '16px',
            bottomRight: '0',
            bottomLeft: '0'
        }
    ],
    [
        3,
        {
            topLeft: '0',
            topRight: '0',
            bottomRight: '0',
            bottomLeft: '16px'
        }
    ],
    [
        4,
        {
            topLeft: '0',
            topRight: '0',
            bottomRight: '0',
            bottomLeft: '0'
        }
    ],
    [
        5,
        {
            topLeft: '0',
            topRight: '0',
            bottomRight: '16px',
            bottomLeft: '0'
        }
    ]
]);
