import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import Teams from '../Teams';
import ValidateMatchIndex from '../Teams/validation/validateMatchIndex';
import ValidatePosition from '../Teams/validation/validatePosition';
import HeroImage from './components/HeroImage';
import InfoContainer from './components/InfoContainer';

const HeroTile = ({
    isLoading,
    heroImagePaths,
    prizeImagePaths,
    logoPath,
    link,
    variant,
    predictionClosedDate,
    buttonText,
    roundId,
    fixtures
}) => (
    <Styled.Container
        data-test-id={`s6-${variant}-hero-container-${roundId}`}
        variant={variant}
    >
        <Styled.ContainerExpander variant={variant}>
            {variant === 'default' && !isLoading && (
                <Styled.TeamsRow>
                    {fixtures.slice(0, 3).map(fixture => (
                        <Teams
                            key={fixture.matchIndex}
                            matchIndex={fixture.matchIndex}
                            borderRadius={fixture.borderRadius}
                            position={fixture.position}
                            homeTeam={fixture.homeTeam}
                            awayTeam={fixture.awayTeam}
                        />
                    ))}
                </Styled.TeamsRow>
            )}
            <Styled.ContainerInner variant={variant}>
                <InfoContainer
                    isLoading={isLoading}
                    logoPath={logoPath}
                    prizeImagePaths={prizeImagePaths}
                    link={link}
                    variant={variant}
                    predictionClosedDate={predictionClosedDate}
                    buttonText={buttonText}
                />
                <HeroImage isLoading={isLoading} imagePaths={heroImagePaths} />
            </Styled.ContainerInner>
            {variant === 'default' && !isLoading && (
                <Styled.TeamsRow>
                    {fixtures.slice(3, 6).map(fixture => (
                        <Teams
                            key={fixture.matchIndex}
                            matchIndex={fixture.matchIndex}
                            borderRadius={fixture.borderRadius}
                            position={fixture.position}
                            homeTeam={fixture.homeTeam}
                            awayTeam={fixture.awayTeam}
                        />
                    ))}
                </Styled.TeamsRow>
            )}
        </Styled.ContainerExpander>
    </Styled.Container>
);

HeroTile.propTypes = {
    isLoading: PropTypes.bool,
    logoPath: PropTypes.string.isRequired,
    link: PropTypes.string,
    heroImagePaths: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired
    }),
    prizeImagePaths: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired
    }),
    variant: PropTypes.string,
    predictionClosedDate: PropTypes.string,
    buttonText: PropTypes.string,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            matchIndex: ValidateMatchIndex,
            borderRadius: PropTypes.shape({
                topLeft: PropTypes.string,
                topRight: PropTypes.string,
                bottomRight: PropTypes.string,
                bottomLeft: PropTypes.string
            }),
            position: ValidatePosition,
            homeTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired,
            awayTeam: PropTypes.shape({
                name: PropTypes.string.isRequired,
                img: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired
};

HeroTile.defaultProps = {
    isLoading: false,
    variant: 'default',
    predictionClosedDate: '',
    heroImagePaths: {
        desktop: null,
        mobile: null
    },
    prizeImagePaths: {
        desktop: null,
        mobile: null
    },
    buttonText: 'Play For Free',
    link: null,
    roundId: 'loading'
};

export default HeroTile;
