import styled from '@emotion/styled/macro';

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    padding-top: 10px;
    position: relative;
`;

export const InnerContainerBorder = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;
