export const setBannerHash = async banner => {
    try {
        localStorage.setItem('bannerClosed', banner);
        return true;
    } catch (err) {
        return null;
    }
};

export const removeBannerHash = () => {
    try {
        return localStorage.removeItem('bannerClosed');
    } catch (err) {
        return null;
    }
};

export const loadBannerHash = () => {
    try {
        return localStorage.getItem('bannerClosed');
    } catch (err) {
        return null;
    }
};
