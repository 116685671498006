import styled from '@emotion/styled/macro';

// eslint-disable-next-line import/prefer-default-export
export const HeroContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;

    &:first-of-type {
        margin-top: 10px;
    }

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin: 0 10px;
        width: auto;
    }
`;
