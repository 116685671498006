import { parse } from 'querystring';
import { isMobile } from 'react-device-detect';
import {
    sendConsumerReady,
    setVersion
} from '../store/modules/appBridge/actions';
import { isGreaterThanOrEqual } from './versionHelper';
import { submitFeedback } from '../store/modules/feedback/actions';

export const getApp = () => window.localStorage.getItem('app');
export const shouldOpenAppLinksNatively = () => getApp() === 'ios';
export const getAppVersion = () => window.localStorage.getItem('version');
export const isUsingIOSV3OrHigher = () => {
    if (getApp() !== 'ios') {
        return false;
    }

    const version = getAppVersion();
    return version && isGreaterThanOrEqual(version, '3');
};

export const getIdToken = () => {
    if (window.location.search) {
        const { idToken } = parse(window.location.search.replace('?', ''));
        return idToken;
    }
    return false;
};

export const isAppWrapper = () =>
    !isUsingIOSV3OrHigher() || getApp() === 'android';

const isIosAppBridge = () =>
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.appBridge &&
    window.webkit.messageHandlers.appBridge.postMessage;

const isAndroidAppBridge = () =>
    window.AndroidAppBridge && window.AndroidAppBridge.postMessage;

const isAppBridgeAvailable = () => !!(isIosAppBridge() || isAndroidAppBridge());

export const detectDevice = store => {
    if (window.location.search) {
        const { app, version } = parse(window.location.search.replace('?', ''));

        if (app && version) {
            store.dispatch(
                setVersion(
                    Array.isArray(app) ? app[0] : app,
                    Array.isArray(version) ? version[0] : version
                )
            );
            return;
        }
    }

    const storedApp = getApp();
    const storedVersion = getAppVersion();

    if (storedApp && storedVersion) {
        store.dispatch(setVersion(storedApp, storedVersion));
    }
};

const onWake = () => {
    window.location.reload();
};

const onSetDeviceIDFA = data => {
    if (window.Taggert) {
        window.Taggert.report('deviceIDFA', {
            deviceIDFA: data.deviceIDFA
        });
    }
};

const onFeedback = dispatch => data => {
    dispatch(
        submitFeedback({
            comments: data.message,
            appWrapper: true
        })
    );
};

export const setupSsoAppBridge = dispatch => {
    const { SkyBetAccount } = window;
    const appBridgeConfig = {
        app: getApp()
    };
    if (!isGreaterThanOrEqual(getAppVersion(), '1.7')) {
        appBridgeConfig.iframeEventsEnabled = false;
    }

    window.appBridge = SkyBetAccount.getAppBridge(appBridgeConfig);

    window.appBridge.bind('feedback', onFeedback(dispatch));
    window.appBridge.bind('wake', onWake);
    window.appBridge.bind('setDeviceIDFA', onSetDeviceIDFA);

    dispatch(sendConsumerReady());
};

export const setupOauthAppBridge = dispatch => {
    if (!isAppBridgeAvailable()) {
        return;
    }

    window.appBridge = {
        send: (name, data) => {
            const message = JSON.stringify({
                name,
                data: data || {}
            });

            if (isIosAppBridge()) {
                window.webkit.messageHandlers.appBridge.postMessage(message);
                return;
            }

            if (isAndroidAppBridge()) {
                window.AndroidAppBridge.postMessage(message);
            }
        }
    };

    dispatch(sendConsumerReady());
};

export const postMessage = (name, data) => {
    if (window.appBridge) {
        window.appBridge.send(name, data);
    }
};

export const canShareNatively = () => isMobile && !!navigator.canShare; // isMobile includes tablets
