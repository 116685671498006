/* eslint-disable import/prefer-default-export */
export const getH2hText = (
    componentsEnabled,
    punditPredictions,
    lastRoundId,
    lastRoundData
) => {
    if (
        componentsEnabled &&
        punditPredictions &&
        lastRoundId &&
        lastRoundData &&
        lastRoundData.hasEnteredHeadToHead
    ) {
        if (lastRoundData.totalPoints > punditPredictions.totalPoints.points) {
            return `Beat ${
                punditPredictions.firstName
                    ? punditPredictions.firstName
                    : 'Opponent'
            }`;
        }
    }
    return null;
};

export const getLastFiveRounds = (componentsEnabled, entries, lastRoundId) => {
    if (!componentsEnabled || !lastRoundId) {
        return null;
    }
    const index = entries.findIndex(entry => lastRoundId === entry.roundId);
    return entries.slice(index - 4, index + 1);
};
