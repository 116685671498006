import PropTypes from 'prop-types';

const points = PropTypes.shape({
    number: PropTypes.number,
    points: PropTypes.number
});

const matchPrediction = PropTypes.shape({
    challengeId: PropTypes.number,
    matchId: PropTypes.number,
    scoreHome: PropTypes.number,
    scoreAway: PropTypes.number,
    isCorrectScore: PropTypes.bool,
    isCorrectResult: PropTypes.bool,
    points: PropTypes.number
});

export const predictions = PropTypes.shape({
    goldenGoal: PropTypes.number,
    hasPredicted: PropTypes.bool,
    resultPoints: points,
    scorePoints: points,
    totalPoints: points,
    matches: matchPrediction
});

export const scoreChallenge = PropTypes.shape({
    id: PropTypes.number,
    match: PropTypes.shape({
        status: PropTypes.string.isRequired,
        homeTeam: PropTypes.shape({
            badgeUri: PropTypes.string,
            name: PropTypes.string
        }),
        awayTeam: PropTypes.shape({
            badgeUri: PropTypes.string,
            name: PropTypes.string
        })
    })
});

export const roundResults = PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    endDateTime: PropTypes.string.isRequired,
    scoreChallenges: PropTypes.arrayOf(scoreChallenge).isRequired,
    goldenGoalChallenge: PropTypes.shape({
        isGoldenGoalScored: PropTypes.bool.isRequired,
        time: PropTypes.number
    }).isRequired
});
