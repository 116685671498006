import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageContainer } from '../../components/Common/Page/styles';
import PageTitle from '../../components/Common/PageTitle';
import * as Styled from './styles';
import setTitle from '../../helpers/page';

const NotFound = () => {
    const history = useHistory();
    setTitle('Error 404 | Page Not Found');

    return (
        <PageContainer>
            <PageTitle>We don&apos;t seem to have that page</PageTitle>
            <Styled.Container>
                <Styled.Image src="/img/errors/404.png" alt="404 not found" />
                <p>Please check and try again</p>
                <Styled.PrimaryLink to="/">
                    Back to home page
                </Styled.PrimaryLink>
                {history.length > 1 && (
                    <Styled.Button onClick={history.goBack}>
                        Previous page
                    </Styled.Button>
                )}
            </Styled.Container>
        </PageContainer>
    );
};

export default NotFound;
