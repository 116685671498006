import styled from '@emotion/styled/macro';

export const HeaderContainer = styled.div`
    background: linear-gradient(
        to bottom,
        ${props => props.theme.colours.header.gradient.from},
        ${props => props.theme.colours.header.gradient.to}
    );

    margin-top: 36px;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        background: linear-gradient(
            180deg,
            ${props => props.theme.colours.header.gradient.from} 0,
            ${props => props.theme.colours.header.gradient.to}
        );
        position: fixed;
        top: 36px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 97; // App Bridge modal is z-index: 98, so make this 1 less... S6-2970

        margin-top: 0;
    }
`;

export const Header = styled.div`
    padding: 9px 0 10px;
    margin: 0 auto;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        max-width: ${props => props.theme.widths.containerMaxWidth};
        padding: 15px 0 17px;
    }
`;
