import React from 'react';
import PropTypes from 'prop-types';
import * as Modal from './styles';

const Confirmation = ({
    title,
    message,
    messageSecondLine,
    confirmLabel,
    onDismiss,
    onConfirm
}) => (
    <div>
        <Modal.TitleContainer>
            <Modal.LeagueTitle>{title}</Modal.LeagueTitle>
            <Modal.LeagueTitleParagraph>
                {message}
                {messageSecondLine
                    ? [<br key={1} />, <span key={2}>{messageSecondLine}</span>]
                    : ''}
            </Modal.LeagueTitleParagraph>
        </Modal.TitleContainer>
        <Modal.ActionBar>
            <Modal.DangerAction onClick={onDismiss}>Cancel</Modal.DangerAction>
            <Modal.ConfirmAction
                data-test-id="modal-confirm-action"
                onClick={onConfirm}
            >
                {confirmLabel}
            </Modal.ConfirmAction>
        </Modal.ActionBar>
    </div>
);

Confirmation.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    messageSecondLine: PropTypes.string,
    confirmLabel: PropTypes.string,
    onDismiss: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

Confirmation.defaultProps = {
    confirmLabel: 'Confirm',
    messageSecondLine: ''
};

export default Confirmation;
