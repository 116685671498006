import React from 'react';
import { Redirect } from 'react-router-dom';
import { removeTokensFromBrowser } from '../../auth/oauth';

const Logout = () => {
    removeTokensFromBrowser();
    if (window.Android) {
        window.location.href = '/';
        window.Android.softLogout();
    }
    return <Redirect to="/" />;
};

export default Logout;
