import styled from '@emotion/styled/macro';

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    width: ${props => props.variant === 'default' ?? '60%'};

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    pointer-events: none;

    @media (max-width: ${props => props.theme.hero.widths.mobileMax}) {
        order: 1;

        overflow: visible;
        width: 100%;

        border-bottom-right-radius: 0;
        border-top-left-radius: 5px;
    }
`;

export const HeroImage = styled.picture`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const LoadingImage = styled.div`
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.hero.loading.colours.imageBackground};
    height: 100%;
    width: 100%;

    &:before {
        font-size: 100px;
        font-family: ${props => props.theme.fonts.skycons};
        content: '\f0f2';
        color: ${props => props.theme.hero.loading.colours.imageIcon};
    }
`;
