import axios from 'axios';
import { API_ROOT } from '../config';
import { getUserSessionId } from '../helpers/sessionStorage';

const commonHeaders = {
    Accept: 'application/json',
    'X-Session-Id': getUserSessionId()
};

const userApi = {
    getUserInfo: async idToken => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/user/self/info`,
            {
                headers: {
                    Authorization: idToken,
                    ...commonHeaders
                }
            }
        );
        if (status >= 400) {
            throw new Error('Unable to get user info');
        }
        return data;
    },
    getUsersActiveRoundPredictions: async idToken => {
        let data = null;
        let status = null;

        ({ data, status } = await axios.get(
            `${API_ROOT()}/round/active/user/self`,
            {
                headers: {
                    Authorization: idToken,
                    ...commonHeaders
                }
            }
        ));

        if (status >= 400) {
            throw new Error('Unable to get predictions');
        }
        return data;
    },
    getJwt: async idToken => {
        const { data, status } = await axios.get(`${API_ROOT()}/auth/jwt`, {
            headers: {
                Authorization: idToken,
                ...commonHeaders
            }
        });
        if (status >= 400) {
            throw new Error('Unable to get jwt');
        }
        return data;
    }
};

export default userApi;
