import { logEvent } from './event_bridge';

export default class Events {
    constructor(elementText = 'null', module = 'null', action = 'null') {
        this.element_text = elementText.toLowerCase();
        this.action = action;
        this.module = module;
    }

    get() {
        return this;
    }

    send(eventType) {
        if (window.Android || window.webkit) {
            logEvent(eventType, this);
        } else {
            window.dataLayer.push({ event: eventType, ...this });
        }
    }
}
