import * as types from './types';

export const getPodcast = () => ({
    type: types.REQUEST_PODCAST
});

export const setPodcast = data => ({
    type: types.RECEIVE_PODCAST,
    data
});

export const getPodcastFailed = () => ({
    type: types.REQUEST_PODCAST_FAILED
});
