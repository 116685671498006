/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled/macro';

export const MatchPoints = styled.div`
    background-color: ${props =>
        props.points
            ? props.theme.scores?.points.bgColour
            : props.theme.scores?.noPoints.bgColour};
    color: ${props =>
        props.points
            ? props.theme.scores?.points.text
            : props.theme.scores?.noPoints.text};
    padding: ${props =>
        `${props.theme.spacing.medium} ${props.theme.spacing.large}`};
    margin: auto 0;
    transform: rotate(-4.36deg);
    flex-shrink: 0;
    font-size: ${props => props.theme.fontSize.xsmall};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        padding: ${props =>
            `${props.theme.spacing.normal} ${props.theme.spacing.medium}`};
    }
`;
