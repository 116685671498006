import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const LeagueIcon = ({ iconUrl, alt }) => (
    <Styled.ImageContainer>
        <Styled.IconImage src={iconUrl} alt={alt} />
    </Styled.ImageContainer>
);

LeagueIcon.propTypes = {
    iconUrl: PropTypes.string.isRequired,
    alt: PropTypes.string
};

LeagueIcon.defaultProps = {
    alt: ''
};

export default LeagueIcon;
