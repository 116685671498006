import styled from '@emotion/styled/macro';

const backButtonHeight = '28px';

export const TopLinkContainer = styled.div`
    margin-bottom: 10px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: ${props => props.theme.spacing.normal} 0;
    background-color: #f6f6f6;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => props.theme.spacing.normal} 0;
    }
`;

export const BackButtonContainer = styled.div`
    display: flex;
    width: 65px;
    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        margin-left: ${props => props.theme.spacing.medium};
    }
    line-height: ${backButtonHeight};
    min-height: ${backButtonHeight};
`;

export const TopLinkContainerHeading = styled.div`
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.xxlarge};
    width: calc(100% - 140px);
    text-align: center;

    @media (max-width: ${props => props.theme.widths.tabletMax}) {
        font-size: ${props => props.theme.fontSize.xlarge};
    }
`;

export const SelectWrapper = styled.div`
    min-width: 120px;
    position: relative;
    grid-column-start: 2;
    justify-content: center;
    display: flex;
    color: ${props => props.theme.colours.header.textColor};
    outline: none;
    border: none;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.small};
    line-height: ${backButtonHeight};

    &::after {
        font-family: ${props => props.theme.fonts.skycons};
        font-size: ${props => props.theme.fontSize.medium};
        content: '\\F022';
        color: ${props => props.theme.colours.colorOnPrimary};
        pointer-events: none;
        align-self: center;
        margin-left: ${props => props.theme.spacing.medium};
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        font-size: ${props => props.theme.fontSize.medium};
    }
`;

export const LongText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Select = styled.ul`
    position: absolute;
    margin-top: 40px;
    z-index: 1;
    background: ${props => props.theme.colours.lightGrey};
    border-radius: 10px;
    width: 260px;
    max-height: 67vh;
    overflow: hidden;
    overflow-y: scroll;

    @media (min-width: ${props => props.theme.widths.mobileMax}) {
    }
    
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        margin-top: 30px;
        width: auto;
        max-height: 250px;
    }
}
`;

export const SelectOptionIcon = styled.span`
    display: block;
    width: 20px;
    margin: 0 ${props => props.theme.spacing.small};

    &::before {
        display: none;
        font-family: ${props => props.theme.fonts.skycons};
        font-size: ${props => props.theme.fontSize.normal};
        content: '\\F114';
        color: ${props => props.theme.colours.black};
        pointer-events: none;
        align-self: center;
        text-align: center;
    }

    &.selected {
        &::before {
            display: block;
        }
    }

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        width: 10px;
        padding-top: ${props => props.theme.spacing.xsmall};

        &::before {
            font-size: ${props => props.theme.fontSize.xxsmall};
        }
    }
`;

export const SelectOption = styled.li`
    position: relative;
    display: flex;
    font-size: ${props => props.theme.fontSize.medium};
    white-space: nowrap;
    font-family: sans-serif;
    font-weight: 400;
    padding: ${props => props.theme.spacing.small} 0;
    margin: 0 ${props => props.theme.spacing.small};
    color: #000000;
    border-radius: ${props => props.theme.spacing.small};
    cursor: pointer;
    justify-content: center;
    height: 50px;
    align-items: center;

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => props.theme.spacing.small}
            ${props => props.theme.spacing.vlarge}
            ${props => props.theme.spacing.small} 0;
        justify-content: normal;
        height: auto;
        align-items: normal;

        &:hover {
            background-color: ${props => props.theme.colours.linkBlueHover};
            color: white;

            ${SelectOptionIcon} {
                &::before {
                    color: ${props => props.theme.colours.white};
                }
            }
        }
    }

    &::after {
        content: '';
        height: 1px;
        position: absolute;
        background-color: ${props =>
            props.theme.hero.loading.colours.imageIcon};
        width: 100%;
        bottom: 0;
        left: 0;

        @media (min-width: ${props => props.theme.widths.desktopMin}) {
            display: none;
        }
    }

    &:last-of-type {
        &::after {
            display: none;
        }
    }
`;
