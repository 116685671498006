export const REQUEST_ACTIVE_ROUND = 'round/REQUEST_ACTIVE';
export const RECEIVE_ACTIVE_ROUND = 'round/RECEIVE_ACTIVE';
export const ACTIVE_ROUND_STATE_CHANGE = 'round/ACTIVE_ROUND_STATE_CHANGE';
export const ACTIVE_ROUND_CHANGE = 'round/ACTIVE_ROUND_CHANGE';
export const REQUEST_ROUNDS = 'round/REQUEST_ROUNDS';
export const LOADING_ROUNDS = 'round/LOADING_ROUNDS';
export const FAILED_LOADING_ROUNDS = 'round/FAILED_LOADING_ROUNDS';
export const RECEIVE_ROUNDS = 'round/RECEIVE_ROUNDS';

export const UPDATE_PREDICTION = 'round/UPDATE_PREDICTION';
export const UPDATE_GOLDEN_GOAL = 'round/UPDATE_GOLDEN_GOAL';

export const REQUEST_MATCH_STATS = 'round/REQUEST_MATCH_STATS';
export const MATCH_STATS_LOADING = 'round/MATCH_STATS_LOADING';
export const REQUEST_MATCH_STATS_FAILED = 'round/REQUEST_MATCH_STATS_FAILED';
export const RECEIVE_MATCH_STATS = 'round/RECEIVE_MATCH_STATS';

export const REQUEST_MATCH_PUNDITS = 'round/REQUEST_MATCH_PUNDITS';
export const MATCH_PUNDITS_LOADING = 'round/MATCH_PUNDITS_LOADING';
export const REQUEST_MATCH_PUNDITS_FAILED =
    'round/REQUEST_MATCH_PUNDITS_FAILED';
export const RECEIVE_MATCH_PUNDITS = 'round/RECEIVE_MATCH_PUNDITS';

export const REQUEST_MATCH_BETS = 'round/REQUEST_MATCH_BETS';
export const MATCH_BETS_LOADING = 'round/MATCH_BETS_LOADING';
export const REQUEST_MATCH_BETS_FAILED = 'round/REQUEST_MATCH_BETS_FAILED';
export const RECEIVE_MATCH_BETS = 'round/RECEIVE_MATCH_BETS';
export const REQUEST_PUNDIT_PREDICTIONS = 'round/REQUEST_PUNDIT_PREDICTIONS';
export const RECEIVE_PUNDIT_PREDICTIONS = 'round/RECEIVE_PUNDIT_PREDICTIONS';
export const LOADING_PUNDIT_PREDICTIONS = 'round/LOADING_PUNDIT_PREDICTIONS';
export const FAILED_LOAD_PUNDIT_PREDICTIONS =
    'round/FAILED_LOAD_PUNDIT_PREDICTIONS';
export const REQUEST_OUTCOMES_FOR_MARKET = 'round/REQUEST_OUTCOMES_FOR_MARKET';
export const RECEIVE_OUTCOMES_FOR_MARKET = 'round/RECEIVE_OUTCOMES_FOR_MARKET';
export const START_LOADING_MARKET = 'round/START_LOADING_MARKET';
export const HAS_LOADED_MARKET = 'round/HAS_LOADED_MARKET';

export const REQUEST_HEAD_TO_HEAD = 'round/REQUEST_HEAD_TO_HEAD';
export const RECEIVE_HEAD_TO_HEAD = 'round/RECEIVE_HEAD_TO_HEAD';
export const LOADING_HEAD_TO_HEAD = 'round/LOADING_HEAD_TO_HEAD';
export const FAILED_LOADING_HEAD_TO_HEAD = 'round/FAILED_LOADING_HEAD_TO_HEAD';
export const RECEIVE_LIVE_ODDS = 'round/RECEIVE_LIVE_ODDS';
export const REQUEST_LIVE_ODDS = 'round/REQUEST_LIVE_ODDS';

export const REQUEST_UPCOMING_ROUNDS = 'round/REQUEST_UPCOMING_ROUNDS';
export const RECEIVE_UPCOMING_ROUNDS = 'round/RECEIVE_UPCOMING_ROUNDS';
export const FAILED_LOADING_UPCOMING_ROUNDS =
    'round/FAILED_LOADING_UPCOMING_ROUNDS';
export const LOADING_UPCOMING_ROUNDS = 'round/LOADING_UPCOMING_ROUNDS';
