import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import ConnectedAccount from '../Account';
import ConnectedNotificationsContext, {
    NotificationsPanel,
    BellButton
} from '../Notifications';
import ConnectedStreaksBadge from '../../../../containers/StreaksBadge';
import elementText from '../../../../helpers/GoogleAnalytics/element_text';

export const HeaderMain = ({
    isOnDesktop,
    toggleMobileNav,
    mobileNavOpen,
    setInterfaceEvent,
    setNavigationEvent
}) => (
    <ConnectedNotificationsContext>
        <Styled.HeaderRowContainer>
            <Styled.LeftIcons key="header-left-icons">
                {!isOnDesktop && (
                    <div>
                        {mobileNavOpen ? (
                            <Styled.CloseNavIcon
                                aria-label="close menu"
                                onClick={toggleMobileNav}
                            />
                        ) : (
                            <Styled.BurgerNavIcon
                                aria-label="open menu"
                                id="burger-menu-btn"
                                data-test-id="nav-bar-burger-menu"
                                data-target-id="s6-nav-bar-burger-menu-btn"
                                onClick={toggleMobileNav}
                            />
                        )}
                    </div>
                )}
                <BellButton setInterfaceEvent={setInterfaceEvent} />
            </Styled.LeftIcons>
            <Styled.HeaderLogoContainer to="/" key="header-logo-container">
                <Styled.HeaderLogo
                    key="header-logo"
                    id="home_btn"
                    data-test-id="home_logo"
                    src="/img/logos/super6-main-logo.png"
                    alt="Super 6"
                    onClick={() =>
                        setNavigationEvent(
                            elementText.navigation.logo,
                            2,
                            'https://super6.skysports.com/'
                        )
                    }
                />
            </Styled.HeaderLogoContainer>

            <Styled.RightIcons key="header-right-icons">
                <ConnectedStreaksBadge
                    setNavigationEvent={setNavigationEvent}
                />
                <ConnectedAccount setNavigationEvent={setNavigationEvent} />
            </Styled.RightIcons>
        </Styled.HeaderRowContainer>
        <NotificationsPanel />
    </ConnectedNotificationsContext>
);

const mapStateToProps = state => ({
    isOnDesktop: state.user.isOnDesktop
});

HeaderMain.propTypes = {
    isOnDesktop: PropTypes.bool.isRequired,
    toggleMobileNav: PropTypes.func.isRequired,
    mobileNavOpen: PropTypes.bool.isRequired,
    setInterfaceEvent: PropTypes.any,
    setNavigationEvent: PropTypes.any
};

HeaderMain.defaultProps = {
    setInterfaceEvent: () => {},
    setNavigationEvent: () => {}
};

export default connect(mapStateToProps)(HeaderMain);
