import styled from '@emotion/styled/macro';

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colours.gallery};
    border-radius: 50%;
    width: 70px;
    height: 70px;
    align-self: center;
`;

export const IconImage = styled.img`
    width: 35px;
`;
