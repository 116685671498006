import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FlexRow } from '../../../components/Common/Page/styles';
import ConnectedSearchField from '../components/SearchField';
import * as Styled from '../styles';
import { requestSearchLeaderboard } from '../../../store/modules/leaderboard/actions';
import { selectLeaderboard } from '../../../store/modules/leaderboard/selectors';
import Alert from '../../../components/Common/Alert';
import ConnectedScoreTable from '../containers/ScoreTable';

export const Search = ({
    search,
    period,
    periodId,
    leaderboard,
    getSearchLeaderboard
}) => {
    useEffect(() => {
        if (leaderboard) {
            return;
        }

        getSearchLeaderboard(search, period, periodId);
    }, [search, period, periodId, leaderboard, getSearchLeaderboard]);

    let tableComponent;
    if (!leaderboard || leaderboard.results.length < 1) {
        tableComponent = (
            <Alert type="Warning">No results found for search</Alert>
        );
    } else {
        tableComponent = (
            <ConnectedScoreTable
                showHeader
                rows={leaderboard.results}
                testId="leaderboard-table-search-results"
            />
        );
    }

    return (
        <>
            <Styled.LeaderboardTypeNavigationContainer>
                <FlexRow>
                    <Styled.LeaderboardTypeHeader>
                        Global Search
                    </Styled.LeaderboardTypeHeader>
                </FlexRow>
            </Styled.LeaderboardTypeNavigationContainer>
            <ConnectedSearchField
                period={period}
                periodId={periodId}
                search={search}
            />
            {tableComponent}
        </>
    );
};

const mapStateToProps = (state, props) => ({
    leaderboard: selectLeaderboard(
        state,
        props.period,
        props.periodId,
        'search',
        props.search
    )
});

const mapDispatchToProps = dispatch => ({
    getSearchLeaderboard: (search, period, id) => {
        dispatch(requestSearchLeaderboard(search, period, id));
    }
});

Search.propTypes = {
    search: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    leaderboard: PropTypes.shape({
        results: PropTypes.arrayOf(
            PropTypes.shape({
                userId: PropTypes.number,
                name: PropTypes.string,
                position: PropTypes.number,
                isWinner: PropTypes.bool.isRequired,
                correctResults: PropTypes.number,
                correctScores: PropTypes.number,
                points: PropTypes.number
            })
        ).isRequired
    }),
    getSearchLeaderboard: PropTypes.func.isRequired
};

Search.defaultProps = {
    periodId: null,
    leaderboard: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
