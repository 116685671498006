import React from 'react';
import MessageBlock from '../../../components/MessageBlock';

const NoRounds = () => (
    <MessageBlock type="info">
        <h3>No rounds</h3>
        <p>There aren&apos;t any Head to Head rounds.</p>
    </MessageBlock>
);

export default NoRounds;
