import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { Icon } from '../../../../components/Common/Page/styles';
import * as icons from '../../../../styles/icons';
import LeagueIcon from '../LeagueIcon';

const LeagueItemInfo = ({ slug, name, memberCount, icon }) => {
    const renderIcon = iconUrl => {
        if (iconUrl) {
            return <LeagueIcon iconUrl={iconUrl} alt="League icon" />;
        }

        return (
            <Styled.Trophy data-test-id="default-league-icon">
                <Icon data-char={icons.Trophy} />
            </Styled.Trophy>
        );
    };

    return (
        <Styled.SummaryInfoContainer>
            {renderIcon(icon)}
            <Styled.SummaryInfo>
                <h3>{name}</h3>
                <p>
                    PIN <strong>{slug}</strong>
                </p>
                <p>
                    Members <strong>{memberCount}</strong>
                </p>
            </Styled.SummaryInfo>
        </Styled.SummaryInfoContainer>
    );
};

LeagueItemInfo.propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired
};

export default LeagueItemInfo;
