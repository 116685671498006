import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import Spinner from '../../components/Common/Spinner';
import {
    selectNonOpenOrFutureRoundIds,
    selectLatestCompletedRoundId,
    selectNonFutureRoundIds,
    selectActiveRound
} from '../../store/modules/round/selectors';
import { selectHasPredicted } from '../../store/modules/user/selectors';
import {
    requestActiveRound,
    requestRounds
} from '../../store/modules/round/actions';
import HeadToHead from './index';
import { PageContainer } from '../../components/Common/Page/styles';
import PageTitle from '../../components/Common/PageTitle';
import NoRounds from './components/NoRounds';
import Tabs, { Tab } from '../../components/Common/Tabs/Tabs';
import { requestUsersActiveRoundPredictions } from '../../store/modules/user/actions';

import ConnectedPeriodIdNavigation from '../../containers/PeriodIdNavigation';
import { selectIsLoggedIn } from '../../store/modules/auth/selectors';
import { getValidH2HRedirect } from '../../helpers/periodHelper';

const HeadToHeadWrapper = ({
    roundsLoading,
    loadRounds,
    loadActiveRound,
    lastCompleteRoundId,
    roundList,
    loadUserPredictions,
    isLoggedIn,
    loadingPredictions,
    activeRound,
    history,
    getRounds,
    activeRounds
}) => {
    const { section, roundId } = useRouteMatch().params;
    const match = useRouteMatch();
    const { replace } = history;

    useEffect(() => {
        loadRounds();
        loadActiveRound();
    }, [loadRounds, loadActiveRound]);

    useEffect(() => {
        loadUserPredictions();
    }, [loadUserPredictions, isLoggedIn, activeRound]);

    useEffect(() => {
        if (!lastCompleteRoundId) {
            getRounds();
        }
    }, [lastCompleteRoundId, getRounds]);

    useEffect(() => {
        const validRedirect = getValidH2HRedirect(
            activeRound?.id,
            lastCompleteRoundId,
            activeRounds
        );
        if (
            validRedirect &&
            window.location.href.split('/')[
                window.location.href.split('/').length - 1
            ] !== validRedirect &&
            !roundId
        ) {
            replace(`/headtohead/${section}/${validRedirect}` || '');
        }
    }, [replace, activeRound?.id, lastCompleteRoundId, activeRounds]);

    if (!roundList) {
        return null;
    }

    const { id: activeRoundId = null } = activeRound || {};
    const roundIdInt = parseInt(roundId, 10)
        ? parseInt(roundId, 10) || lastCompleteRoundId
        : null;

    if (!roundIdInt || !roundList.includes(roundIdInt)) {
        if (roundsLoading || loadingPredictions || activeRound === null) {
            return (
                <PageContainer>
                    <Spinner colour="black" />
                </PageContainer>
            );
        }

        if (lastCompleteRoundId || !roundId) {
            return (
                <Redirect
                    to={`/headtohead/${section}/${lastCompleteRoundId}`}
                />
            );
        }

        if (!lastCompleteRoundId && (activeRoundId || roundId)) {
            return <Redirect to={`/headtohead/${section}/${activeRoundId}`} />;
        }

        return (
            <PageContainer>
                <PageTitle>Head to Head</PageTitle>
                <NoRounds />
            </PageContainer>
        );
    }

    const sortedRoundList = roundList.slice().sort((a, b) => a - b);

    return (
        <PageContainer>
            <Tabs active={section} bgGrey>
                <Tab
                    id="results"
                    testId="tab-results"
                    to={`/headtohead/results/${roundIdInt}`}
                >
                    Results
                </Tab>
                <Tab
                    id="prizes"
                    testId="tab-prizes"
                    to={`/headtohead/prizes/${roundIdInt}`}
                >
                    Prizes
                </Tab>
                <Tab
                    id="how-to-play"
                    testId="tab-how-to-play"
                    to={`/headtohead/how-to-play/${roundIdInt}`}
                >
                    How to Play
                </Tab>
            </Tabs>
            <ConnectedPeriodIdNavigation
                activeRounds={sortedRoundList}
                activeMonths={[]}
                period="round"
                periodId={roundIdInt}
                urlTemplate={`/headtohead/${section}/:id`}
                match={match}
                history={history}
            />
            <HeadToHead section={section} roundId={roundIdInt} />
        </PageContainer>
    );
};

HeadToHeadWrapper.propTypes = {
    roundsLoading: PropTypes.bool.isRequired,
    loadRounds: PropTypes.func.isRequired,
    loadActiveRound: PropTypes.func.isRequired,
    loadUserPredictions: PropTypes.func.isRequired,
    roundList: PropTypes.arrayOf(PropTypes.number),
    lastCompleteRoundId: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    loadingPredictions: PropTypes.bool,
    activeRound: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
    }).isRequired,
    getRounds: PropTypes.func.isRequired,
    activeRounds: PropTypes.arrayOf(PropTypes.number)
};

HeadToHeadWrapper.defaultProps = {
    lastCompleteRoundId: null,
    roundList: null,
    isLoggedIn: null,
    loadingPredictions: null,
    activeRound: null,
    activeRounds: []
};

const mapStateToProps = state => ({
    lastCompleteRoundId: selectLatestCompletedRoundId(state),
    roundsLoading: state.round.loading || false,
    roundList: selectNonFutureRoundIds(state),
    hasPredicted: selectHasPredicted(state),
    isLoggedIn: selectIsLoggedIn(state),
    loadingPredictions: state.user.loadingActiveRoundPredictions || false,
    activeRound: selectActiveRound(state),
    activeRounds: selectNonOpenOrFutureRoundIds(state)
});

const mapDispatchToProps = dispatch => ({
    loadRounds: () => dispatch(requestRounds()),
    loadActiveRound: () => dispatch(requestActiveRound()),
    loadUserPredictions: () => dispatch(requestUsersActiveRoundPredictions()),
    getRounds: () => dispatch(requestRounds())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeadToHeadWrapper);
