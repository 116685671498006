import { put, select, takeLeading, call } from 'redux-saga/effects';
import { parse } from 'querystring';
import * as types from './types';
import * as actions from './actions';
import { receiveUserInfo, requestUserInfo } from '../user/actions';
import * as selectors from './selectors';
import { isOauthEnabled, getAuthService } from '../../../auth/auth';
import { addErrorMessage } from '../message/actions';
import oauthApi from '../../../api/oauth';
import userApi from '../../../api/user';
import metricsApi from '../../../api/metrics';
import history from '../../../routing/history';
import { isUsingIOSV3OrHigher } from '../../../helpers/appBridge';
import { getUserSessionId } from '../../../helpers/sessionStorage';
import * as signalActions from '../signal/actions';
import { removeBannerHash } from '../banner/helpers/localStorage';
import { processAPICallbacks } from '../../../api/axios';
import { sendMetadataEvent } from '../../../helpers/GoogleAnalytics/gaHelper';
import Metadata from '../../../helpers/GoogleAnalytics/metadata';

export function* initAuth() {
    try {
        const isInitialised = yield select(selectors.selectIsInitialised);
        if (isInitialised) {
            return;
        }
        const authService = yield getAuthService();
        yield call(authService.init);
        yield put(actions.authInitialised());

        if (!window.Android) {
            yield put(actions.checkAuth());
        } else {
            yield put(actions.checkedAuth());
        }
    } catch (err) {
        //
    }
}

export function* checkAuth(action) {
    try {
        const { forceLoggedIn } = action;
        const hasCheckedAuth = yield select(selectors.selectHasCheckedAuth);
        const authService = yield getAuthService();
        const shouldCheckAuth = yield call(authService.shouldCheckAuth);

        if (!shouldCheckAuth) {
            yield put(actions.checkedAuth());
            return;
        }

        if (hasCheckedAuth && forceLoggedIn) {
            const isLoggedIn = yield select(selectors.selectIsLoggedIn);

            if (!isLoggedIn) {
                yield put(actions.requestLogin());
            }
            return;
        }

        const tokens = yield call(authService.getTokensFromBrowser);

        if (tokens) {
            if (isOauthEnabled()) {
                yield put(
                    actions.checkOauthTokens(tokens.token, tokens.idToken)
                );
            } else {
                yield put(actions.checkSsoToken(tokens.idToken));
            }
            return;
        }

        const metadata = new Metadata(null, false, false);
        sendMetadataEvent(metadata);

        if (forceLoggedIn) {
            yield put(actions.checkedAuth());
            yield put(actions.requestLogin());
            return;
        }

        yield call(authService.silentAuth);
        yield put(actions.checkedAuth());
    } catch (err) {
        //
    }
}

function* redirectToLogin() {
    try {
        const authService = yield call(getAuthService);
        yield call(authService.redirectToLogin);
    } catch (err) {
        yield put(
            addErrorMessage('There was a problem loading the login form')
        );
    }
}

function* getAndroidOauthTokens() {
    const authService = yield call(getAuthService);
    const tokens = window.Android.getAuth();
    const {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken
    } = JSON.parse(tokens);
    yield call(
        authService.storeTokensInBrowser,
        accessToken,
        idToken,
        refreshToken
    );
    yield put(actions.receiveOauthTokens(idToken));
    yield put(requestUserInfo(true));
    processAPICallbacks();
}

function* getOauthTokens(action) {
    try {
        const authService = yield call(getAuthService);
        const storedState = yield call(authService.getStateFromBrowser);
        if (String(storedState) !== String(action.state)) {
            yield put(addErrorMessage('There was a problem logging in'));
            yield put(actions.logout());
            return;
        }
        const tokens = yield call(oauthApi.getTokens, action.code);
        const {
            id_token: idToken,
            access_token: accessToken,
            refresh_token: refreshToken
        } = tokens;
        yield call(
            authService.storeTokensInBrowser,
            accessToken,
            idToken,
            refreshToken
        );
        yield put(actions.receiveOauthTokens(idToken));
        yield put(requestUserInfo(true));
    } catch (err) {
        yield put(addErrorMessage('There was a problem logging in'));
        yield put(actions.logout());
    }
}

function* logOutLocally(action) {
    const { shouldRedirect } = action;
    const authService = yield call(getAuthService);
    yield call(authService.removeTokensFromBrowser);
    removeBannerHash();
    if (shouldRedirect) {
        window.location.href = '/';
    }
}

function* checkTokens(action) {
    try {
        const userTokenValid = yield call(
            oauthApi.getUserLoggedIn,
            action.token
        );

        if (!userTokenValid) {
            yield put(addErrorMessage('There was a problem logging in'));
            yield put(actions.logout());
            return;
        }
        const authService = yield call(getAuthService);
        const { idToken } = yield call(authService.getTokensFromBrowser);

        yield put(actions.receiveOauthTokens(idToken));
        metricsApi.countMetric('oauth_token_success');
        yield put(requestUserInfo());
        yield put(actions.checkedAuth());
    } catch (err) {
        yield put(addErrorMessage('There was a problem logging in'));
        metricsApi.countMetric('oauth_token_failure', {
            userSessionId: getUserSessionId()
        });
        yield put(actions.logout());
        yield put(actions.checkedAuth());
    }
}

function* checkSsoTokenAction(action) {
    try {
        const authService = yield call(getAuthService);
        yield call(authService.storeTokensInBrowser, action.token);
        const data = yield call(userApi.getUserInfo, `sso ${action.token}`);
        if (data) {
            yield put(actions.receiveSsoToken(action.token));
            metricsApi.countMetric('sso_token_success', {
                userSessionId: getUserSessionId()
            });
            yield put(receiveUserInfo(data));
            yield put(actions.checkedAuth());
            const { shouldRedirect } = action;
            yield put(signalActions.recordLogin(data.user, true));
            if (shouldRedirect) {
                const { redirectTo } = parse(
                    window.location.search.replace('?', '')
                );
                if (redirectTo && redirectTo.match('/[^/]+')) {
                    history.push(redirectTo);
                }
            }
            return;
        }
        yield put(actions.logout());
        yield put(actions.checkedAuth());
    } catch (err) {
        yield put(addErrorMessage('There was a problem logging in'));
        metricsApi.countMetric('sso_token_failure', {
            userSessionId: getUserSessionId()
        });
        yield put(actions.logout());
        yield put(actions.checkedAuth());
    }
}

function* getJwtFromIdToken() {
    try {
        if (!isOauthEnabled()) {
            return;
        }

        const existingJwt = yield select(selectors.selectJwt);
        const idToken = yield select(selectors.selectToken);

        if (existingJwt || !idToken) {
            if (window.Android && existingJwt === null && idToken === null) {
                const metadata = new Metadata(null, false, false);
                sendMetadataEvent(metadata);
            }

            return;
        }

        const { jwt } = yield call(userApi.getJwt, idToken);

        yield put(actions.receiveJwt(jwt));

        if (idToken && userApi && jwt) {
            const data = yield call(userApi.getUserInfo, idToken);

            const metadata = new Metadata(
                data.user.customerId,
                true,
                data.user.fullyRegistered
            );
            sendMetadataEvent(metadata);
        }
    } catch (err) {
        yield put(addErrorMessage('There was a problem accessing token'));
    }
}

function* updateIdTokenForIOS(action) {
    try {
        const { idToken } = action;

        const enabled = yield call(isUsingIOSV3OrHigher);

        if (!enabled) {
            return;
        }

        const authService = yield call(getAuthService);
        yield call(authService.storeTokensInBrowser, 'null', idToken, 'null');
        yield put(requestUserInfo());
        yield call(authService.stopCheckAuth);
    } catch (err) {
        //
    }
}

export function* watchInitAuth() {
    yield takeLeading(types.INITIALISE_AUTH, initAuth);
}

export function* watchCheckAuth() {
    yield takeLeading(types.CHECK_AUTH, checkAuth);
}

export function* watchLogin() {
    yield takeLeading(types.REQUEST_LOGIN, redirectToLogin);
}

export function* watchLogout() {
    yield takeLeading(types.LOGOUT, logOutLocally);
}

export function* watchOauthTokenRequest() {
    yield takeLeading(types.REQUEST_OAUTH_TOKENS, getOauthTokens);
}

export function* watchAndroidOauthTokenRequest() {
    yield takeLeading(
        types.REQUEST_ANDROID_OAUTH_TOKENS,
        getAndroidOauthTokens
    );
}

export function* watchCheckOauthTokens() {
    yield takeLeading(types.CHECK_OAUTH_TOKENS, checkTokens);
}

export function* watchRequestJwt() {
    yield takeLeading(types.REQUEST_JWT, getJwtFromIdToken);
}

export function* watchCheckSsoToken() {
    yield takeLeading(types.CHECK_SSO_TOKEN, checkSsoTokenAction);
}

export function* watchUpdateIdTokenIOS() {
    yield takeLeading(types.UPDATE_ID_TOKEN, updateIdTokenForIOS);
}

export const sagas = [
    watchInitAuth,
    watchCheckAuth,
    watchLogin,
    watchLogout,
    watchOauthTokenRequest,
    watchCheckOauthTokens,
    watchRequestJwt,
    watchCheckSsoToken,
    watchUpdateIdTokenIOS,
    watchAndroidOauthTokenRequest
];
