import { createSelector } from 'reselect';
import { selectActiveRound } from '../round/selectors';
import { selectIsLoggedIn } from '../auth/selectors';

export const selectUserId = state => state.user.info.userId || false;
export const selectCustomerId = state => state.user.info.customerId || null;
export const selectHasCustomerId = state => !!state.user.info.customerId;
export const selectUserHasPredictedRounds = state =>
    state.user.hasPredictedRounds;

export const selectHasPredicted = createSelector(
    [selectActiveRound, selectIsLoggedIn, selectUserHasPredictedRounds],
    (activeRound, isLoggedIn, userHasPredictedRounds) => {
        if (!isLoggedIn || !activeRound) {
            return false;
        }

        return userHasPredictedRounds.includes(activeRound.id);
    }
);

export const selectLoadingActiveRoundPredictions = state =>
    state.user.loadingActiveRoundPredictions;
