import styled from '@emotion/styled/macro';

export const Container = styled.div`
    background: ${props => props.theme.colours.white};
    text-align: center;
    border: 1px solid ${props => props.theme.colours.header.gradient.to};
    border-radius: ${props => props.theme?.card?.borderRadius ?? '4px'};
    width: 100%;
    max-width: 345px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        margin-top: ${props => props.theme.spacing.large};
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Header = styled.div`
    padding: 12px;
    background: ${props => props.theme.colours.blueDark};
    justify-content: center;
    font-family: ${props =>
        props.theme.fonts.skySportsD ?? props.theme.fonts.medium};};
    font-weight: ${props => props.theme.fonts.skySportsD && '700'};};
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colours.white};
    border-radius: ${props =>
        props.theme?.card?.borderRadius
            ? `${props.theme?.card?.borderRadius} ${props.theme?.card?.borderRadius} 0 0`
            : '4px 4px 0 0'};
`;

export const Row = styled.div`
    display: flex;
    padding: ${props => props.theme.spacing.xlarge};
`;

export const Stats = styled.div`
    width: 112px;
    font-size: ${props => props.theme.fontSize.normal};
    font-family: ${props => props.theme.fonts.medium};
    margin: 0 auto;
`;

export const Label = styled.p`
    height: 40px;
`;

export const Score = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colours.header.gradient.to};
    border-bottom: 4px solid ${props => props.theme.colours.blueDark};
    border-radius: 4px;
    width: 76px;
    height: 76px;
    margin: 0 auto;
    font-size: ${props => props.theme.fontSize.xxlarge};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;
