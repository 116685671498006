import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import { FlexRow, Icon } from '../../../components/Common/Page/styles';
import * as icons from '../../../styles/icons';

const LeaderboardTypeNavigation = ({ title, prev, next }) => (
    <Styled.LeaderboardTypeNavigationContainer>
        <FlexRow>
            <Styled.NavigationDirection>
                <Styled.LeaderboardTypeNavigationButton
                    data-test-id="left-navigation-link"
                    to={`?filter=${prev}`}
                >
                    <Icon data-char={icons.LeftChevron} />
                </Styled.LeaderboardTypeNavigationButton>
            </Styled.NavigationDirection>
            <Styled.LeaderboardTypeHeader data-test-id="leaderboard-title">
                {title}
            </Styled.LeaderboardTypeHeader>
            <Styled.NavigationDirection align="right">
                <Styled.LeaderboardTypeNavigationButton
                    data-test-id="right-navigation-link"
                    to={`?filter=${next}`}
                >
                    <Icon data-char={icons.RightChevron} />
                </Styled.LeaderboardTypeNavigationButton>
            </Styled.NavigationDirection>
        </FlexRow>
    </Styled.LeaderboardTypeNavigationContainer>
);

LeaderboardTypeNavigation.propTypes = {
    title: PropTypes.string.isRequired,
    prev: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired
};

export default LeaderboardTypeNavigation;
