import React from 'react';
import PropTypes from 'prop-types';
import {
    getShortDay,
    getShortMonth,
    getFormattedTime
} from '../../../../helpers/dateFormatters';
import * as Styled from './styles';

const NextRound = ({ closeDate, roundName, didEnter }) => {
    const day = getShortDay(closeDate.getDay());
    const date = closeDate.getDate();
    const month = getShortMonth(closeDate.getMonth());

    const buttonText = didEnter ? 'Edit Predictions' : 'Enter Now';

    return (
        <Styled.Container data-test-id="next-round-widget">
            <Styled.Header>Next Round</Styled.Header>
            <Styled.Info>
                <Styled.InfoTop>
                    <Styled.Date>
                        {day} <span>{date}</span> {month}
                    </Styled.Date>
                    <Styled.Text>
                        {roundName}
                        <br />
                        <span>Entries by {getFormattedTime(closeDate)}</span>
                    </Styled.Text>
                </Styled.InfoTop>
                <Styled.Button link="/play" internal>
                    {buttonText}
                </Styled.Button>
            </Styled.Info>
        </Styled.Container>
    );
};

NextRound.propTypes = {
    closeDate: PropTypes.instanceOf(Date).isRequired,
    roundName: PropTypes.string.isRequired,
    didEnter: PropTypes.bool.isRequired
};

export default NextRound;
