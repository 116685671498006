import React from 'react';
import PropTypes from 'prop-types';
import ConnectedMatchWithScores from '../../../components/MatchWithScores';
import isResultedMatch from '../../../helpers/isResultedMatch';
import isLiveMatch from '../../../helpers/isLiveMatch';
import {
    getFormattedDate,
    getFormattedTime
} from '../../../helpers/dateFormatters';
import * as Styled from '../../LiveScoring/components/style';

const RoundFixtures = ({ fixtures, predictions, userId }) => {
    if (!fixtures) {
        return null;
    }

    return (
        <>
            {fixtures.map((fixture, index) => {
                const {
                    match: {
                        id,
                        status,
                        homeTeam,
                        awayTeam,
                        competitionImageUrl,
                        competitionName,
                        kickOffDateTime
                    }
                } = fixture;

                const fixturePrediction = predictions[id] ?? {};

                const isCancelled = ['Abandoned', 'Postponed'].includes(status);
                const isPreLive = status === 'Pre Live';
                const panelTitleForStatus = {
                    'Kick Off': 'LATEST',
                    'Half Time': 'HALF TIME',
                    'Half Time Started': 'LATEST',
                    'Second Half Started': 'LATEST'
                };
                const formattedDate = getFormattedDate(kickOffDateTime);
                const formattedTime = getFormattedTime(kickOffDateTime)
                    .split(':00')
                    .join('');

                return (
                    <Styled.MatchResultCard key={fixture.id}>
                        <ConnectedMatchWithScores
                            key={fixture.id}
                            homeTeam={homeTeam}
                            predictedHomeScore={fixturePrediction.scoreHome}
                            homeScore={homeTeam.score}
                            awayTeam={awayTeam}
                            predictedAwayScore={fixturePrediction.scoreAway}
                            awayScore={awayTeam.score}
                            isCorrectResult={fixturePrediction.isCorrectResult}
                            isCorrectScore={fixturePrediction.isCorrectScore}
                            points={fixturePrediction.points}
                            index={index}
                            competitionUri={competitionImageUrl}
                            competitionName={competitionName}
                            isPreLive={isPreLive}
                            isLive={isLiveMatch(status)}
                            isResulted={isResultedMatch(status)}
                            isAbandoned={isCancelled}
                            matchStatusText={{
                                red: isLiveMatch(status) ? 'LIVE' : 'FULL TIME',
                                blue: panelTitleForStatus[status]
                            }}
                            kickOffDate={formattedDate}
                            kickOffTime={formattedTime}
                            isResultsPage
                            userId={userId}
                        />
                    </Styled.MatchResultCard>
                );
            })}
        </>
    );
};

RoundFixtures.defaultProps = {
    predictions: null,
    liveOdds: null,
    fixtures: null,
    userId: null
};

RoundFixtures.propTypes = {
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                competitionName: PropTypes.string,
                competitionImageUrl: PropTypes.string,
                kickOffDateTime: PropTypes.string,
                homeTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                })
            })
        })
    ),
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.string,
            scoreAway: PropTypes.string
        })
    }),
    liveOdds: PropTypes.shape({
        home: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        away: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        }),
        draw: PropTypes.shape({
            id: PropTypes.number,
            desc: PropTypes.string,
            fraction: PropTypes.string
        })
    }),
    userId: PropTypes.string
};

export default RoundFixtures;
