import styled from '@emotion/styled/macro';

const Button = styled.button`
    display: inline-block;
    border: 0;
    color: inherit;
    background: transparent;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    padding: 0;
    margin: 0;
`;

export default Button;
