import styled from '@emotion/styled/macro';
import { keyframes, css } from '@emotion/core';

export const TopSection = styled.div`
    display: flex;
    margin-bottom: ${props => props.theme.spacing.large};
    justify-content: flex-start;

    & > :nth-of-type(1) {
        margin-right: ${props => props.theme.spacing.large};
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        flex-direction: column;
        & > :nth-of-type(1) {
            margin: auto;
        }
    }
`;

const loadingKeyframes = keyframes`
    from {
        left: -300px;
    }
        
    to {
        left: 100%;
    }
`;

const loadingAnimation = () => css`
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -100px;
        top: 0;
        height: 100%;
        width: 300px;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 33%,
            rgba(255, 255, 255, 0.4) 67%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: ${loadingKeyframes} 1.5s linear infinite;
    }
`;

export const NextRoundLoading = styled.div`
    width: 100%;
    max-width: 210px;
    height: 230px;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        max-width: 345px;
        margin: 0 auto;
    }

    border-radius: 4px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;

    ${loadingAnimation}
`;

export const PersonalSeasonStatsLoading = styled.div`
    width: 100%;
    height: 230px;
    max-width: 345px;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        margin-top: ${props => props.theme.spacing.large};
        margin-left: auto;
        margin-right: auto;
    }

    border-radius: 4px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;

    ${loadingAnimation}
`;

export const RoundsListLoading = styled.div`
    width: 100%;
    height: 400px;

    border-radius: 4px;
    background: ${props => props.theme.colours.bgGrey};
    overflow: hidden;
    position: relative;

    ${loadingAnimation}
`;
