import styled from '@emotion/styled/macro';
import AppLinkButton from '../../Common/AppLink/styles';

export const Picture = styled.picture`
    display: block;
    width: 100%;
    margin: 0 auto;

    source,
    img {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
`;

export const ImageLink = styled.div`
    a,
    ${AppLinkButton} {
        display: block;
        width: 100%;
        text-decoration: none;
    }
`;

export const TermsLink = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colours.textGrey};
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    margin-top: ${props => props.theme.spacing.normal};
    padding: ${props => props.theme.spacing.normal} 0;
`;

export const ButtonContainer = styled.div`
    display: block;
    margin-bottom: ${props => props.theme.spacing.normal};
`;
