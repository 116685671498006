import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import { Icon } from '../../../../../components/Common/Page/styles';
import { CircleTick, CircleCross } from '../../../../../styles/icons';

const Outcome = ({
    matchId,
    selections,
    onChangeSelection,
    outcomeName,
    outcome
}) => {
    const lowerOutcome = outcomeName.toLowerCase();
    const selection = selections.find(item => item.match === matchId);
    const isSelected = selection && selection.selection === lowerOutcome;

    if (isSelected) {
        return (
            <Styled.OutcomeColumn>
                <Styled.SelectedOutcomeButton
                    onClick={() => onChangeSelection(null)}
                >
                    <Icon data-char={CircleTick} />
                    <Icon data-char={CircleCross} />
                </Styled.SelectedOutcomeButton>
            </Styled.OutcomeColumn>
        );
    }

    return (
        <Styled.OutcomeColumn>
            <Styled.OutcomeButton
                onClick={() => onChangeSelection(lowerOutcome)}
            >
                <span>{outcome.fraction}</span>
                <Icon data-char={CircleTick} />
            </Styled.OutcomeButton>
        </Styled.OutcomeColumn>
    );
};

Outcome.propTypes = {
    outcome: PropTypes.shape({
        fraction: PropTypes.string.isRequired
    }).isRequired,
    outcomeName: PropTypes.string.isRequired,
    matchId: PropTypes.number.isRequired,
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.number.isRequired,
            selection: PropTypes.string.isRequired
        })
    ).isRequired,
    onChangeSelection: PropTypes.func.isRequired
};

export default Outcome;
