import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Styled from '../styles';
import MessageBlock from '../../../components/MessageBlock';
import BasicShare from '../../../components/BasicShare';
import createPredictionsString from '../../../helpers/createPredictionsString';
import { selectActiveRoundScoreChallenges } from '../../../store/modules/round/selectors';
import { requestActiveRound } from '../../../store/modules/round/actions';

export const SharePredictions = ({
    loadActiveRound,
    predictions,
    fixtures
}) => {
    useEffect(() => {
        loadActiveRound();
    }, [loadActiveRound]);

    const {
        predictionsStringShort,
        predictionsStringFull
    } = createPredictionsString({ predictions, fixtures });

    return (
        <MessageBlock id="prediction-submitted">
            Predictions submitted!
            <Styled.ShareBar>
                <div>Share my Predictions</div>
                <div>
                    <BasicShare
                        customMessage={{
                            shareText: `I've made my Super 6 predictions for the week. ${predictionsStringFull}. Enter yours now for the chance to win thousands for FREE! https://super6.skysports.com`,
                            shortShareText: `My @Super6: ${predictionsStringShort}`,
                            url: `https://super6.skysports.com`
                        }}
                        small
                    />
                </div>
            </Styled.ShareBar>
        </MessageBlock>
    );
};

SharePredictions.propTypes = {
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ).isRequired,
    loadActiveRound: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    fixtures: selectActiveRoundScoreChallenges(state),
    predictions: state.play.predictions
});

const mapDispatchToProps = dispatch => ({
    loadActiveRound: () => dispatch(requestActiveRound())
});

export default connect(mapStateToProps, mapDispatchToProps)(SharePredictions);
