/* eslint-disable no-console */
export const logPageView = name => {
    if (!name) {
        return;
    }
    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.logPageView(name);
    } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.firebase
    ) {
        // Call iOS interface
        const message = {
            command: 'logPageView',
            name
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log('No native APIs found.');
    }
};

export const logEvent = (name, params) => {
    if (!name) {
        return;
    }
    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
    } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.firebase
    ) {
        // Call iOS interface
        const message = {
            command: 'logEvent',
            name,
            parameters: params
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log('No native APIs found.');
    }
};

export const setUserProperty = (name, value) => {
    if (!name || !value) {
        return;
    }
    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.setUserProperty(name, value);
    } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.firebase
    ) {
        // Call iOS interface
        const message = {
            command: 'setUserProperty',
            name,
            value
        };

        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log('No native APIs found.');
    }
};
