import { put, takeLeading, call, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as offerActions from '../offer/actions';
import { addErrorMessage, addSuccessMessage } from '../message/actions';
import { requestRoundPredictions } from '../results/actions';
import * as types from './types';
import playApi from '../../../api/play';
import * as playSelectors from './selectors';
import { selectToken } from '../auth/selectors';
import { selectActiveRoundId } from '../round/selectors';
import { sendIncrementFeedbackCount, sendMessage } from '../appBridge/actions';
import { selectCustomerId } from '../user/selectors';
import history from '../../../routing/history';
import * as signalActions from '../signal/actions';
import * as metricsActions from '../metrics/actions';
import * as statsActions from '../stats/actions';
import { requestStatsForSelf } from '../stats/actions';
import { isFeatureOn } from '../content/selectors';
import { ENABLE_MY_PERFORMANCE_PAGE } from '../../../features';

export function* postPredictions() {
    try {
        const customerId = yield select(selectCustomerId);
        yield put(actions.submissionInFlight());

        yield put(
            sendMessage('AppsFlyerTrackEvent', {
                name: 'af_S6_entry_submitted',
                data: {
                    custId: customerId
                }
            })
        );

        yield put(metricsActions.triggerCountMetric('play_flow_submit'));

        const predictions = yield select(playSelectors.getPredictions);
        const goldenGoal = yield select(playSelectors.getGoldenGoal);

        const data = {
            scores: Object.keys(predictions).map(matchId => ({
                challengeId: predictions[matchId].challengeId,
                scoreHome: predictions[matchId].scoreHome,
                scoreAway: predictions[matchId].scoreAway
            })),
            goldenGoal
        };

        const token = yield select(selectToken);
        yield call(playApi.postPredictions, data, token);
        yield put(sendIncrementFeedbackCount());
        const roundId = yield select(selectActiveRoundId);
        yield put(actions.predictionsPostSuccess(roundId));
        yield put(requestRoundPredictions(roundId, true));
        yield put(offerActions.requestPostPredictionBanner());
        yield put(actions.submissionFinished());
        yield call(history.push, '/played?submitted=1');
        yield put(signalActions.recordEnterRound(roundId));
        yield put(statsActions.updateStatsEnteredRound(roundId));
        yield put(
            metricsActions.triggerCountMetric('play_flow_submit_success')
        );
        yield put(addSuccessMessage('Predictions submitted successfully'));

        const shouldRequestStats = yield select(
            isFeatureOn,
            ENABLE_MY_PERFORMANCE_PAGE
        );
        if (shouldRequestStats) {
            yield put(requestStatsForSelf(true));
        }
    } catch (err) {
        yield put(actions.submissionFinished());
        yield put(metricsActions.triggerCountMetric('play_flow_submit_failed'));
        if (err.message === 'Round is paused') {
            yield call(history.push, '/');
            yield put(
                addErrorMessage(
                    'Submission unsuccessful, please try again soon.'
                )
            );
        } else {
            yield put(
                addErrorMessage('There was a problem posting your predictions')
            );
        }
    }
}

export function* updateHeadToHeadMaybe(action) {
    try {
        if (!action.postValue || !action.value) {
            return;
        }

        const token = yield select(selectToken);
        yield call(playApi.postHeadToHeadEntry, token);
        const roundId = yield select(selectActiveRoundId);
        yield put(requestRoundPredictions(roundId, true));
    } catch (err) {
        yield put(
            addErrorMessage(
                'There was a problem posting your head to head entry'
            )
        );
    }
}

export function* watchPostPredictions() {
    yield takeLeading(types.POST_PREDICTIONS, postPredictions);
}

export const sagas = [watchPostPredictions];
