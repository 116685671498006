const setRegStatus = (isLoggedIn, fullyRegistered) => {
    if (isLoggedIn) {
        return fullyRegistered ? 'full reg' : 'part reg';
    }
    return 'null';
};

const setOrientation = navigator => {
    const isOnAndroid = navigator.userAgent.includes('Android');
    const isOnIosWeb = navigator.userAgent.includes('iPhone');
    if (isOnAndroid || isOnIosWeb) {
        return window.outerWidth / window.outerHeight > 1
            ? 'landscape'
            : 'portrait';
    }
    return 'desktop';
};

class Metadata {
    constructor(customerId, isLoggedIn, fullyRegistered) {
        this.account_balance = 'null';
        this.account_id = isLoggedIn && customerId ? customerId : '123456';
        this.aff_id = 'null';
        this.bid = 'null';
        this.brand = 'sbg';
        this.btag = 'null';
        this.city = 'null';
        this.clkid = 'null';
        this.connection_type = 'null';
        this.country = 'null';
        this.currency = 'null';
        this.efid = 'null';
        this.jurisdiction = 'uki';
        this.language = 'en_en';
        this.locale = 'en_gb';
        this.login_status = isLoggedIn ? 'logged in' : 'logged out';
        this.mi_ign = 'null';
        this.mi_u = 'null';
        this.orientation = setOrientation(navigator);
        this.pi = 'null';
        this.pid = 'null';
        this.product = 'web';
        this.product_theme = 'null';
        this.promo_code = 'null';
        this.reg_status = setRegStatus(isLoggedIn, fullyRegistered);
        this.rfr = 'null';
        this.sid = 'null';
        this.sub_id = 'null';
        this.context = 'super6';
        this.ttp = 'null';
        this.user_id = isLoggedIn ? customerId : null;
        this.vertical = 'sports';
    }
}

export default Metadata;
