/* eslint-disable import/no-unused-modules */
import standardiseToLowerCase from '../standardiseToLowerCase';

const resultedMatchStatuses = ['full_time', 'result', 'match_complete'];

const isResultedMatch = matchStatus => {
    if (typeof matchStatus !== 'string') {
        throw new Error('Invalid type for match status');
    }

    return resultedMatchStatuses.includes(standardiseToLowerCase(matchStatus));
};

export { isResultedMatch as default };
