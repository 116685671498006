/* eslint-disable import/prefer-default-export */
export const reorderWinners = (winnersArr, userId) => {
    if (!Array.isArray(winnersArr)) {
        return [];
    }

    if (winnersArr.length) {
        const userWinnerIndex = winnersArr.findIndex(e => e.userId === userId);

        if (userWinnerIndex > 0) {
            const winningUser = winnersArr[userWinnerIndex];
            winnersArr.splice(userWinnerIndex, 1);
            winnersArr.unshift(winningUser);
        }
    }

    return winnersArr;
};
