import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { showModal } from '../../store/modules/modal/actions';
import Defer from '../Defer';
import { useCookieConsentContext } from '../../hooks/useCookieConsents';
import buildCookieUrl from '../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../config';
import {
    sendNavigationEvent,
    sendInterfaceEvent
} from '../../helpers/GoogleAnalytics/gaHelper';
import modules from '../../helpers/GoogleAnalytics/modules';
import elementText from '../../helpers/GoogleAnalytics/element_text';
import actions from '../../helpers/GoogleAnalytics/actions';

const Footer = ({ openFeedbackModal, openContactUsModal }) => {
    const { cookieConsentQuery } = useCookieConsentContext();

    const setNavigationEvent = (text, position, destinationUrl, context) => {
        sendNavigationEvent(
            modules.homepage.footer,
            'null',
            text,
            position,
            destinationUrl,
            context
        );
    };

    const setInterfaceEvent = (text, action) => {
        sendInterfaceEvent(text, action, modules.homepage.footer);
    };

    return (
        <Styled.FooterContainer>
            <Defer>
                <Styled.Footer>
                    <Styled.FooterRow>
                        <Styled.FooterItem>
                            <Styled.FooterInternalLink
                                onClick={() => {
                                    setNavigationEvent(
                                        elementText.navigation.TnCs,
                                        1,
                                        'https://super6.skysports.com/terms'
                                    );
                                }}
                                to="/terms"
                                data-target-id="s6-footer-tandc-link"
                            >
                                Terms &amp; Conditions
                            </Styled.FooterInternalLink>
                        </Styled.FooterItem>

                        <Styled.FooterItem>
                            <Styled.FooterInternalLink
                                onClick={() => {
                                    setNavigationEvent(
                                        elementText.navigation.privacyPolicy,
                                        2,
                                        'https://super6.skysports.com/privacy'
                                    );
                                }}
                                to="/privacy"
                                data-target-id="s6-footer-privacy-link"
                            >
                                Privacy Policy
                            </Styled.FooterInternalLink>
                        </Styled.FooterItem>

                        <Styled.FooterItem>
                            <Styled.FooterButton
                                onClick={() => {
                                    setInterfaceEvent(
                                        elementText.interface.cookieManagement,
                                        actions.clicked
                                    );

                                    if (window.Android?.showCookieManagement) {
                                        window.Android.showCookieManagement();
                                        return;
                                    }

                                    if (window.OneTrust) {
                                        window.OneTrust.ToggleInfoDisplay();
                                    }
                                }}
                            >
                                Cookie Management
                            </Styled.FooterButton>
                        </Styled.FooterItem>

                        <Styled.FooterItem>
                            <Styled.FooterLink
                                onClick={() => {
                                    setNavigationEvent(
                                        elementText.navigation.accessibility,
                                        4,
                                        buildCookieUrl(
                                            OAUTH_URI,
                                            'https://support.skybet.com/s/article/Accessibility',
                                            cookieConsentQuery
                                        )
                                    );
                                }}
                                href={buildCookieUrl(
                                    OAUTH_URI,
                                    'https://support.skybet.com/s/article/Accessibility',
                                    cookieConsentQuery
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-target-id="s6-footer-accessibility-link"
                            >
                                Accessibility Information
                            </Styled.FooterLink>
                        </Styled.FooterItem>

                        <Styled.FooterItem>
                            <Styled.FooterButton
                                data-target-id="s6-footer-contact-us-link"
                                onClick={() => {
                                    openContactUsModal();
                                    setInterfaceEvent(
                                        elementText.interface.contactUs,
                                        actions.clicked
                                    );
                                }}
                            >
                                Contact Us
                            </Styled.FooterButton>
                        </Styled.FooterItem>

                        <Styled.FooterItem>
                            <Styled.FooterButton
                                data-target-id="s6-footer-feedback-link"
                                onClick={() => {
                                    openFeedbackModal();
                                    setInterfaceEvent(
                                        elementText.interface.feedback,
                                        actions.clicked
                                    );
                                }}
                            >
                                Feedback
                            </Styled.FooterButton>
                        </Styled.FooterItem>
                    </Styled.FooterRow>
                    <Styled.FooterRow>
                        <Styled.FooterItem>
                            <Styled.SkyImage
                                src="/img/products/sky-sports.png?updated=2020-10-08"
                                alt="Sky Sports logo"
                            />
                            ©{new Date().getFullYear()} Sky
                        </Styled.FooterItem>
                    </Styled.FooterRow>
                </Styled.Footer>
            </Defer>
        </Styled.FooterContainer>
    );
};

const mapDispatchToProps = dispatch => ({
    openFeedbackModal: () => {
        dispatch(showModal('FEEDBACK_FORM', {}, true));
    },
    openContactUsModal: () => {
        dispatch(showModal('CONTACT_US_FORM', {}, true));
    }
});

Footer.propTypes = {
    openFeedbackModal: PropTypes.func.isRequired,
    openContactUsModal: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Footer);
