import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../mastheadStyles';

const ResultMastheadSummary = ({
    punditPredictions,
    userPredictions,
    opponentName,
    isPrelive
}) => {
    const noPoints = isPrelive ? '-' : 0;

    return (
        <Styled.SummaryContainer
            id="head-to-head-summary"
            data-test-id="h2h-results-points-summary"
        >
            <Styled.BannerContainer>
                <Styled.SummaryRow>
                    <Styled.SummaryPointsLabel>You</Styled.SummaryPointsLabel>
                    <Styled.SummaryPointsLabel>
                        {opponentName}
                    </Styled.SummaryPointsLabel>
                </Styled.SummaryRow>
                <Styled.SummaryRowDivider />
                <Styled.SummaryRow>
                    <Styled.SummaryPointsBox
                        success={
                            userPredictions.resultPoints.points >
                            punditPredictions.resultPoints.points
                        }
                    >
                        {userPredictions.resultPoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                    <Styled.SummaryTitle>
                        Correct results(s)
                    </Styled.SummaryTitle>
                    <Styled.SummaryPointsBox
                        success={
                            punditPredictions.resultPoints.points >
                            userPredictions.resultPoints.points
                        }
                    >
                        {punditPredictions.resultPoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                </Styled.SummaryRow>

                <Styled.SummaryRow>
                    <Styled.SummaryPointsBox
                        success={
                            userPredictions.scorePoints.points >
                            punditPredictions.scorePoints.points
                        }
                    >
                        {userPredictions.scorePoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                    <Styled.SummaryTitle>Correct scores(s)</Styled.SummaryTitle>
                    <Styled.SummaryPointsBox
                        success={
                            punditPredictions.scorePoints.points >
                            userPredictions.scorePoints.points
                        }
                    >
                        {punditPredictions.scorePoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                </Styled.SummaryRow>
                <Styled.SummaryRowDivider />
                <Styled.SummaryRow bold>
                    <Styled.SummaryPointsBox
                        success={
                            userPredictions.totalPoints.points >
                            punditPredictions.totalPoints.points
                        }
                    >
                        {userPredictions.totalPoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                    <Styled.SummaryTitle>Total Points</Styled.SummaryTitle>
                    <Styled.SummaryPointsBox
                        success={
                            punditPredictions.totalPoints.points >
                            userPredictions.totalPoints.points
                        }
                    >
                        {punditPredictions.totalPoints.points || noPoints}
                    </Styled.SummaryPointsBox>
                </Styled.SummaryRow>
            </Styled.BannerContainer>
        </Styled.SummaryContainer>
    );
};

ResultMastheadSummary.defaultProps = {
    isPrelive: false
};

ResultMastheadSummary.propTypes = {
    userPredictions: PropTypes.shape({
        hasPredicted: PropTypes.bool,
        hasEnteredHeadToHead: PropTypes.bool,
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }).isRequired,
    punditPredictions: PropTypes.shape({
        totalPoints: PropTypes.shape({
            points: PropTypes.number
        }).isRequired,
        resultPoints: PropTypes.shape({
            points: PropTypes.number
        }),
        scorePoints: PropTypes.shape({
            points: PropTypes.number
        })
    }).isRequired,
    opponentName: PropTypes.string.isRequired,
    isPrelive: PropTypes.bool
};

export default ResultMastheadSummary;
