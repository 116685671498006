import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const LastRoundPointsLabel = ({ points }) => {
    const pointsValue = points || '0';
    return (
        <Styled.PointsLabel points={pointsValue}>
            <Styled.InnerContainer>
                <Styled.Points>{pointsValue} POINTS</Styled.Points>
            </Styled.InnerContainer>
        </Styled.PointsLabel>
    );
};

LastRoundPointsLabel.defaultProps = {
    points: null
};

LastRoundPointsLabel.propTypes = {
    points: PropTypes.number
};

export default LastRoundPointsLabel;
