import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Image from '../../Common/Image';

export const RemoveUserButton = styled.button`
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    i {
        color: ${props => props.theme.colours.red};
        font-size: 24px;
    }

    :hover {
        i {
            color: ${props => props.theme.colours.redDark};
        }
    }

    @media (max-width: 370px) {
        i {
            font-size: 18px;
        }
    }
`;

export const WinnerIconImage = styled(Image)`
    width: 19px;
    height: 19px;
    padding: 3px;
    background-color: ${props => props.theme.colours.blueLeaderboard};
    border-radius: 20px;
`;

export const TableRow = styled.tr`
    border: inherit;
    font-weight: normal;
    font-size: ${props => props.theme.fontSize.medium};
    height: 48px;
    font-family: ${props =>
        (props.isMe || props.isWinner) && props.theme.fonts.bold};
    background-color: ${props => props.theme.colours.white};
    border-bottom: 1px solid ${props => props.theme.colours.brightGray};

    &:hover {
        cursor: pointer;
    }

    color: inherit;

    ${props =>
        props.isMasked &&
        `
        pointer-events:none;
    `};
`;

export const TableData = styled.td`
    vertical-align: middle;
    text-align: ${props => (props.center ? 'center' : 'left')};
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${props =>
        !props.onClick &&
        `
        &:hover {
            cursor: auto;
        }
    `}

    ${props =>
        props.showOnSmall &&
        `
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: none;
        }
    `}

    ${props =>
        props.hideOnSmall &&
        `
        display: none;
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: table-cell;
        }
    `}
    ${props =>
        (props.isMe || props.isWinner) &&
        `
        font-weight: 900;
    `}

    ${props =>
        props.row &&
        `
        text-align: center;
    `}

    color: ${props => {
        if (props.row) {
            if (props.row.change < 0) {
                return props.theme.skyBet.colours.greenPrimary;
            }
            if (props.row.change > 0) {
                return props.theme.colours.redMedium;
            }
        }
        if (props.isMe) {
            return props.theme.colours.blueLeaderboard;
        }
        return props.theme.colours.black;
    }};
`;

export const PointsData = styled(TableData)`
    border-top-right-radius: ${props => props.theme.card.borderRadius};
    border-bottom-right-radius: ${props => props.theme.card.borderRadius};
`;

export const PositionData = styled(TableData)`
    max-width: 100%;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    border-top-left-radius: ${props => props.theme.card.borderRadius};
    border-bottom-left-radius: ${props => props.theme.card.borderRadius};
`;

export const TableSubtleLink = styled(Link)`
    font-family: ${props => props.theme.fonts.regular};
    color: inherit;
    text-decoration: none;
`;

export const Placeholder = styled.div`
    width: 100%;
    background-color: red;
`;
