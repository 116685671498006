import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Icon } from '../Common/Page/styles';

export const ToastContainer = styled.div`
    margin-top: ${props => props.theme.spacing.normal};
    text-align: center;
`;

export const NavigationDirection = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: ${props => props.align || 'left'};

    ${Icon} {
        text-align: ${props => props.align || 'left'};
    }
`;

export const PeriodIdSelector = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const LeaderboardTable = styled.table`
    width: 90%;
    align-self: center;
`;

export const TableHeader = styled.thead`
    height: 32px;
    background-color: ${props => props.theme.colours.white};
    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.colours.textTitle};

    @media (max-width: 370px) {
        font-size: ${props => props.theme.fontSize.normal};
    }
`;

export const TableHeaderColumn = styled.th`
    box-sizing: border-box;
    vertical-align: middle;
    text-transform: uppercase;

    width: ${props => {
        if (props.width === 'x-small') {
            return '52px';
        }

        if (props.width === 'small') {
            return '52px';
        }

        if (props.width === 'small-reduce') {
            return '52px';
        }

        if (props.width === 'medium') {
            return '75px';
        }

        if (props.width === 'large') {
            return '100px';
        }

        if (props.width === 'large-reduce') {
            return '100px';
        }

        if (props.width === 'position') {
            return '70px';
        }

        return 'auto';
    }};

    padding: ${props => props.theme.spacing.normal};
    font-family: ${props => props.theme.fonts.bold};

    text-align: ${props => (props.center ? 'center' : 'left')};

    background-color: ${props =>
        props.highlight ? props.theme.colours.borderGrey : 'none'};

    @media (max-width: 680px) {
        width: ${props => {
            if (props.width === 'x-small') {
                return '52px';
            }

            if (props.width === 'small') {
                return '52px';
            }

            if (props.width === 'small-reduce') {
                return '52px';
            }

            if (props.width === 'medium') {
                return '75px';
            }

            if (props.width === 'large') {
                return '65px';
            }

            if (props.width === 'large-reduce') {
                return '56px';
            }

            if (props.width === 'position') {
                return '70px';
            }

            return 'auto';
        }};
    }

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        width: ${props => {
            if (props.width === 'x-small') {
                return '25px';
            }

            if (props.width === 'small') {
                return '45px';
            }

            if (props.width === 'small-reduce') {
                if (props.canRemove) {
                    return '38px';
                }
                return '45px';
            }

            if (props.width === 'medium') {
                return '50px';
            }

            if (props.width === 'large') {
                return '65px';
            }

            if (props.width === 'large-reduce') {
                return '65px';
            }

            if (props.width === 'position') {
                return '64px';
            }

            return 'auto';
        }};
    }

    @media (max-width: 370px) {
        width: ${props => {
            if (props.width === 'x-small') {
                return '25px';
            }

            if (props.width === 'small') {
                return '45px';
            }

            if (props.width === 'small-reduce') {
                if (props.canRemove) {
                    return '28px';
                }
                return '45px';
            }

            if (props.width === 'medium') {
                return '50px';
            }

            if (props.width === 'large') {
                return '55px';
            }

            if (props.width === 'large-reduce') {
                return '65px';
            }

            if (props.width === 'position') {
                return '60px';
            }

            return 'auto';
        }};

        padding: ${props => props.theme.spacing.small};
    }

    ${props =>
        props.showOnSmall &&
        `
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: none;
        }    
    `}

    ${props =>
        props.hideOnSmall &&
        `
        display: none;
        @media (min-width: ${props.theme.widths.mobileMax}) {
            display: table-cell;
        }
    `}
`;

export const SharePositionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 300px;
    float: right;
    padding-right: ${props => props.theme.spacing.medium};

    > span {
        line-height: ${props => props.theme.lineHeight.normal};
        flex: 50% 0 0;
    }
    > div {
        flex: 50% 0 0;

        > div {
            padding: 0;
        }
    }
`;

export const Clear = styled.div`
    clear: both;
`;

export const LeaderboardTypeHeader = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
`;

export const LeaderboardTypeNavigationButton = styled(Link)`
    display: block;
    text-decoration: none;
    color: ${props => props.theme.colours.linkBlue};
    width: 100%;

    &:hover {
        color: ${props => props.theme.colours.linkBlueHover};
    }
`;

export const SearchContainer = styled.div`
    position: relative;
    margin-bottom: ${props => props.theme.spacing.normal};
`;

export const SearchField = styled.input`
    display: block;
    height: 46px;
    padding: ${props => `
        ${props.theme.spacing.normal} 
        56px
        ${props.theme.spacing.normal} 
        ${props.theme.spacing.medium}`};
    box-sizing: border-box;
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
    color: ${props => props.theme.colours.textGrey};
    background-color: ${props => props.theme.colours.white};
    background-image: none;
    border: ${props =>
        props.hasActiveSearch
            ? `2px solid ${props.theme.colours.greenPrimary}`
            : `1px solid ${props.theme.colours.borderGrey}`};
    border-radius: 0;
    width: 100%;
    box-shadow: none;
`;

export const SearchButton = styled.button`
    padding: ${props => props.theme.spacing.medium};
    border: 0;
    background: ${props => props.theme.colours.white};
    position: absolute;
    top: 3px;
    right: 3px;

    :hover {
        cursor: pointer;
    }
`;

export const ClearButton = styled(SearchButton)`
    padding: ${props => props.theme.spacing.medium};
    border: 0;
    background: ${props => props.theme.colours.white};
    position: absolute;
    top: 3px;
    right: 41px;

    :hover {
        cursor: pointer;
    }
`;

export const MaskedContent = styled.span`
    font-family: ${props => props.theme.fonts.italic};
    line-height: 39px;
`;
