import axios from 'axios';
import { API_ROOT } from '../config';
import userFriendlyError from '../errors/UserFriendlyError';
import { getUserSessionId } from '../helpers/sessionStorage';

const roundApi = {
    getRounds: async () => {
        const { data, status } = await axios.get(`${API_ROOT()}/round`);
        if (status !== 200) {
            throw new Error('Unable to get rounds');
        }
        return data;
    },
    getActiveRound: async () => {
        const { data, status } = await axios.get(`${API_ROOT()}/round/active`);
        if (status !== 200) {
            throw new Error('Unable to get active round');
        }
        return data;
    },
    getMatchStats: async id => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/match/${id}/stats`
        );
        if (status !== 200) {
            throw new Error('Unable to get match stats');
        }
        return data;
    },
    getMatchPundits: async id => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/match/${id}/pundit/prediction`
        );
        if (status !== 200) {
            throw new Error('Unable to get match pundits');
        }
        return data;
    },
    getPunditPredictions: async roundId => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/${roundId}/pundit`
        );
        if (status !== 200) {
            throw new Error('Unable to fetch pundits');
        }
        return data;
    },
    getMatchBets: async id => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/match/${id}/market`
        );
        if (status !== 200) {
            throw new Error('Unable to get match bets');
        }
        return data;
    },
    getResultsForRound: async roundId => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/${roundId}`
        );

        if (status === 404) {
            throw userFriendlyError('Round does not exist');
        }
        if (status !== 200) {
            throw new Error('Unable to get round results');
        }
        return data;
    },
    getUserPredictionsForRound: async (roundId, token) => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/${roundId}/user/self`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId()
                }
            }
        );
        if (status === 404) {
            throw userFriendlyError('Round does not exist');
        }
        if (status !== 200) {
            throw new Error('Unable to get user predictions for round');
        }
        return data;
    },
    getPredictionsForRoundByUser: async (roundId, userId) => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/${roundId}/user/${userId}`
        );
        if (status !== 200) {
            throw new Error('Unable to get predictions for player');
        }
        return data;
    },
    getOutcomesForMarket: async marketType => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/acca/${marketType}/outcome`
        );
        if (status !== 200) {
            throw new Error('Unable to get outcomes for market');
        }
        return data;
    },
    getHeadToHead: async roundId => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/head-to-head/${roundId}/prize`
        );
        if (status !== 200) {
            throw new Error('Unable to get head to head');
        }
        return data;
    },
    getLiveOdds: async () => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/active/live-outcome`
        );
        if (status !== 200) {
            throw new Error('Unable to get live odds');
        }
        return data;
    },
    getUpcomingRounds: async () => {
        const { data, status } = await axios.get(
            `${API_ROOT()}/round/upcoming`
        );
        if (status !== 200) {
            throw new Error('Unable to get upcoming rounds');
        }
        return data;
    }
};

export default roundApi;
