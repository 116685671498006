import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import StreaksBadge from '../../../../components/StreaksBadge';

import * as Styled from './styles';

const getStreakMessage = (streak, hasEntered, isLive, brokenStreak) => {
    const streakNotEnteredMessage = (
        <>
            <Styled.BoldText>Congratulations</Styled.BoldText> on playing{' '}
            {streak} {streak === 1 ? 'round' : 'rounds in a row'}, can you make
            it {streak + 1} out of {streak + 1}?
        </>
    );

    const streakEnteredMessage = (
        <>
            <Styled.BoldText>Congratulations</Styled.BoldText> on entering the
            latest round. Keep it up!
        </>
    );

    const newUserMessage = (
        <>Enter the latest round to start your Super 6 streak!</>
    );

    const newUserInplayMessage = (
        <>Come back next round to start your Super 6 streak!</>
    );

    const brokenStreakMessage = (
        <>
            You broke your latest streak! Enter the latest round to start your
            Super 6 streak!
        </>
    );

    const brokenStreakInplayMessage = (
        <>
            You broke your latest streak! Come back next round to start your
            Super 6 streak!
        </>
    );

    if (brokenStreak) {
        return isLive ? brokenStreakInplayMessage : brokenStreakMessage;
    }

    if (streak > 0) {
        return hasEntered ? streakEnteredMessage : streakNotEnteredMessage;
    }

    return isLive ? newUserInplayMessage : newUserMessage;
};

const StreakBox = ({
    streak,
    subText,
    buttonText,
    buttonLink,
    hasEntered,
    isLive,
    brokenStreak,
    buttonOnClick
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClick = event => {
            if (!isOpen) {
                return;
            }

            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    return (
        <Styled.Container ref={containerRef}>
            <Styled.OverlayContainer
                aria-hidden={!isOpen}
                isOpen={isOpen}
                data-test-id="streakbox-overlay"
            >
                <Styled.InfoText>
                    A streak is the number of Super 6 rounds you have entered
                    consecutively. <br />
                    <br />
                    For example; if you entered rounds 1, 2 and 3 your Super 6
                    streak would be 3. <br />
                    <br /> You break your streak by not entering a round and
                    your streak score will go back to 0.
                </Styled.InfoText>
            </Styled.OverlayContainer>
            <Styled.Header>
                <Styled.StreakBadgeContainer>
                    <StreaksBadge fontSize={45} currentStreak={streak} />
                </Styled.StreakBadgeContainer>
            </Styled.Header>
            <Styled.OverlayButton
                onClick={() => setIsOpen(prevState => !prevState)}
                data-test-id="streakbox-overlay-btn"
            >
                {isOpen ? (
                    <Styled.OverlayImage
                        aria-label="close"
                        src="/content/img/icons/close.svg"
                        height="20"
                        width="20"
                    />
                ) : (
                    <Styled.OverlayImage
                        aria-label="open"
                        src="/content/img/icons/info.svg"
                        height="20"
                        width="20"
                    />
                )}
            </Styled.OverlayButton>
            <Styled.Title>Rounds Streak</Styled.Title>
            <Styled.Message data-test-id="streakbox-message">
                {getStreakMessage(streak, hasEntered, isLive, brokenStreak)}
            </Styled.Message>
            <Styled.EntryContainer>
                <Styled.Button
                    link={buttonOnClick ? undefined : buttonLink}
                    onClick={buttonOnClick || undefined}
                    internal={!buttonOnClick}
                    data-test-id="streakbox-entry-btn"
                >
                    {buttonText}
                </Styled.Button>
                <Styled.SubText>{subText}</Styled.SubText>
            </Styled.EntryContainer>
        </Styled.Container>
    );
};

StreakBox.propTypes = {
    streak: PropTypes.number.isRequired,
    subText: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func,
    hasEntered: PropTypes.bool,
    isLive: PropTypes.bool,
    brokenStreak: PropTypes.bool
};

StreakBox.defaultProps = {
    subText: null,
    hasEntered: false,
    isLive: false,
    brokenStreak: false,
    buttonOnClick: undefined
};

export default StreakBox;
