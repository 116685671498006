import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Team = ({ team, size, mobWidth, id, hideName, uppercase }) => (
    <Styled.TeamContainer
        mobWidth={mobWidth}
        id={id}
        className="team-container"
    >
        <Styled.TeamImage
            src={team.badgeUri}
            size={size}
            {...(hideName ? { alt: team.name } : {})} // eslint-disable-line react/jsx-props-no-spreading
        />
        {!hideName && (
            <Styled.TeamName size={size} uppercase={uppercase}>
                {team.name}
            </Styled.TeamName>
        )}
    </Styled.TeamContainer>
);

Team.propTypes = {
    team: PropTypes.shape({
        badgeUri: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    size: PropTypes.oneOf(['m', 'l']),
    mobWidth: PropTypes.string,
    id: PropTypes.string,
    hideName: PropTypes.bool,
    uppercase: PropTypes.bool
};

Team.defaultProps = {
    size: 'm',
    mobWidth: null,
    id: '',
    hideName: false,
    uppercase: false
};

export default Team;
