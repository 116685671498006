import axios from 'axios';
import { API_ROOT } from '../config';
import { getUserSessionId } from '../helpers/sessionStorage';

const rafApi = {
    setReferrer: async (userId, token) => {
        const headers = {
            Authorization: token,
            'X-Session-Id': getUserSessionId()
        };

        const { data, status } = await axios.post(
            `${API_ROOT()}/referral/${userId}`,
            {},
            {
                headers
            }
        );

        if (status >= 500) {
            throw new Error('Unable to set referrer');
        }
        return data;
    }
};

export default rafApi;
