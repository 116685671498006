import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import LeagueShare from '../../League/components/LeagueShare';
import * as Styled from '../styles';
import { canShareNatively, getApp } from '../../../helpers/appBridge';
import Button from '../../../components/Common/Button';

const InfoParagraph = ({ type, data, setInterfaceEvent }) => {
    let link = null;
    let shareText = null;
    let chairman = null;

    const isApp = !!getApp();
    const isWebViewOrNative = canShareNatively() || isApp;

    if (data) {
        link = `https://${window.location.hostname}/league/create-join?code=${data.pin}`;
        shareText = `I'm in a Super 6 League, think you can beat me? Head to ${link} and join my league for free!`;
        chairman = data.chairman.firstName.concat(' ', data.chairman.lastName);
    }

    return (
        <Styled.LeagueInfoParagraphContainer>
            {type === 'league-desktop' && (
                <Styled.Container style={{ 'margin-bottom': '0' }}>
                    <Styled.Title>League Information</Styled.Title>
                    <Styled.Body>
                        <Styled.BodyItemBig>
                            <Styled.BodyItemLeftSide>
                                League Owner:
                            </Styled.BodyItemLeftSide>
                            <Styled.BodyItemRightSide>
                                {chairman}
                            </Styled.BodyItemRightSide>
                        </Styled.BodyItemBig>
                        <Styled.BodyItemSmall>
                            <Styled.BodyItemLeftSide>
                                PIN:
                            </Styled.BodyItemLeftSide>
                            <Styled.BodyItemRightSide>
                                {data.pin}
                            </Styled.BodyItemRightSide>
                        </Styled.BodyItemSmall>
                        <Styled.BodyItemBig>
                            <Styled.BodyItemLeftSide>
                                Members:
                            </Styled.BodyItemLeftSide>
                            <Styled.BodyItemRightSide>
                                {data.memberCount}
                            </Styled.BodyItemRightSide>
                        </Styled.BodyItemBig>
                    </Styled.Body>
                    {!isWebViewOrNative && (
                        <Styled.Footer style={{ 'padding-bottom': '16px' }}>
                            <Styled.Invite>Invite friends via:</Styled.Invite>
                            <Styled.Share isApp={isWebViewOrNative}>
                                <LeagueShare
                                    customMessage={{
                                        shareText,
                                        buttonText: 'Share League'
                                    }}
                                    isApp={isApp}
                                />
                            </Styled.Share>
                        </Styled.Footer>
                    )}
                    {isWebViewOrNative && (
                        <Styled.Footer>
                            <Styled.Share isApp={isWebViewOrNative}>
                                <LeagueShare
                                    customMessage={{
                                        shareText,
                                        buttonText: 'Share League'
                                    }}
                                    isApp={isApp}
                                    setInterfaceEvent={setInterfaceEvent}
                                />
                            </Styled.Share>
                        </Styled.Footer>
                    )}
                    {data.isChairperson ? (
                        <Styled.ButtonWrapper>
                            <Styled.LeagueButtonWrapper>
                                <Button
                                    data-test-id="league-edit-button"
                                    link={`/league/${data.slug}/edit`}
                                    hoverColour="bgGrey"
                                    textColour="textDarkGrey"
                                    style={{
                                        padding: '10px',
                                        'line-height': 'normal',
                                        backgroundColor: '#EAEAEA'
                                    }}
                                >
                                    Edit
                                </Button>
                            </Styled.LeagueButtonWrapper>
                            <Styled.LeagueButtonWrapper>
                                <Button
                                    data-test-id="league-delete-button"
                                    onClick={() => data.deleteLeague(data.slug)}
                                    textColour="white"
                                    style={{
                                        backgroundColor: '#D92231',
                                        padding: '10px'
                                    }}
                                >
                                    Delete
                                </Button>
                            </Styled.LeagueButtonWrapper>
                        </Styled.ButtonWrapper>
                    ) : (
                        <Styled.ButtonWrapper>
                            <Styled.LeagueButtonWrapper>
                                <Button
                                    data-test-id="league-leave-button"
                                    onClick={() => data.leaveLeague(data.slug)}
                                    style={{
                                        backgroundColor: '#D92231',
                                        padding: '10px'
                                    }}
                                    textColour="white"
                                >
                                    Leave
                                </Button>
                            </Styled.LeagueButtonWrapper>
                        </Styled.ButtonWrapper>
                    )}
                </Styled.Container>
            )}
            {type === 'leaderboard' && (
                <Styled.Paragraph>
                    Leaderboard standings are now calculated as per the updated
                    game rules which use Golden Goal time as ranking criteria.
                    See <Link to="/terms">T&Cs</Link> for full details.
                </Styled.Paragraph>
            )}
        </Styled.LeagueInfoParagraphContainer>
    );
};

InfoParagraph.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
        chairman: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string
        }),
        pin: PropTypes.string,
        memberCount: PropTypes.number,
        isChairperson: PropTypes.bool,
        slug: PropTypes.string.isRequired,
        leaveLeague: PropTypes.func.isRequired,
        deleteLeague: PropTypes.func.isRequired
    }),
    setInterfaceEvent: PropTypes.any
};

InfoParagraph.defaultProps = {
    data: null,
    setInterfaceEvent: () => {}
};

export default InfoParagraph;
