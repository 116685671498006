import { put, takeLeading, call, select } from 'redux-saga/effects';
import * as actions from './actions';
import { addErrorMessage } from '../message/actions';
import * as types from './types';
import roundApi from '../../../api/round';
import * as resultSelectors from './selectors';
import { selectToken } from '../auth/selectors';

export function* getRoundResults(action) {
    try {
        const existingData = yield select(
            resultSelectors.getRoundResults,
            action.roundId
        );
        if (existingData) {
            return;
        }
        const data = yield call(roundApi.getResultsForRound, action.roundId);

        yield put(actions.receiveRoundResults(action.roundId, data));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(addErrorMessage(err.message));
        } else {
            yield put(
                addErrorMessage('There was a problem retrieving round results')
            );
        }
    }
}

export function* getRoundPredictions(action) {
    const { roundId, force } = action;

    if (!force) {
        const existingData = yield select(
            resultSelectors.getRoundPredictions,
            roundId
        );
        if (existingData) {
            return;
        }
    }

    const token = yield select(selectToken);
    try {
        const data = yield call(
            roundApi.getUserPredictionsForRound,
            roundId,
            token
        );

        yield put(actions.receiveRoundPredictions(roundId, data));
    } catch (err) {
        if (err.name === 'UserFriendlyError') {
            yield put(addErrorMessage(err.message));
        } else {
            yield put(
                addErrorMessage('There was a problem retrieving round results')
            );
        }
    }
}

export function* getRoundPredictionsByUser(action) {
    const { roundId, userId } = action;
    const existingData = yield select(
        resultSelectors.getRoundPredictionsByUser,
        roundId,
        userId
    );
    if (existingData) {
        return;
    }

    try {
        const data = yield call(
            roundApi.getPredictionsForRoundByUser,
            roundId,
            userId
        );

        yield put(actions.receiveRoundPredictionsByUser(roundId, userId, data));
    } catch (err) {
        // We can ignore this error, and then to the user it will be represented as if the other user hasn't predicted
    }
}

export function* watchGetRoundResults() {
    yield takeLeading(types.REQUEST_ROUND_RESULTS, getRoundResults);
}

export function* watchGetRoundPredictions() {
    yield takeLeading(types.REQUEST_ROUND_PREDICTIONS, getRoundPredictions);
}

export function* watchGetRoundPredictionsByUser() {
    yield takeLeading(
        types.REQUEST_ROUND_PREDICTIONS_BY_USER,
        getRoundPredictionsByUser
    );
}

export const sagas = [
    watchGetRoundResults,
    watchGetRoundPredictions,
    watchGetRoundPredictionsByUser
];
