export const REQUEST_PUNDITS = 'league/REQUEST_PUNDITS';
export const RECEIVE_PUNDITS = 'league/RECEIVE_PUNDITS';
export const REQUEST_LEAGUES = 'league/REQUEST_LEAGUES';
export const REQUEST_LEAGUE = 'league/REQUEST_LEAGUE';
export const RECEIVE_LEAGUES = 'league/RECEIVE_LEAGUES';
export const RECEIVE_LEAGUE = 'league/RECEIVE_LEAGUE';
export const LEAGUE_NOT_FOUND = 'league/LEAGUE_NOT_FOUND';
export const REQUEST_CREATE_LEAGUE = 'league/REQUEST_CREATE_LEAGUE';
export const REQUEST_JOIN_LEAGUE = 'league/REQUEST_JOIN_LEAGUE';
export const POST_LEAGUE_SUCCESS = 'league/POST_LEAGUE_SUCCESS';
export const POST_LEAGUE_JOIN_SUCCESS = 'league/POST_LEAGUE_JOIN_SUCCESS';
export const REQUEST_MY_SCORE = 'league/REQUEST_MY_SCORE';
export const RECEIVE_MY_SCORE = 'league/RECEIVE_MY_SCORE';
export const REQUEST_LEAGUE_RESULTS = 'league/REQUEST_LEAGUE_RESULTS';
export const RECEIVE_LEAGUE_RESULTS = 'league/RECEIVE_LEAGUE_RESULTS';
export const REQUEST_DELETE_LEAGUE = 'league/REQUEST_DELETE_LEAGUE';
export const REQUEST_LEAVE_LEAGUE = 'league/REQUEST_LEAVE_LEAGUE';
export const DELETE_LEAGUE_SUCCESS = 'league/DELETE_LEAGUE_SUCCESS';
export const REQUEST_REMOVE_MEMBER = 'league/REQUEST_REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'league/REMOVE_MEMBER_SUCCESS';
export const REQUEST_UPDATE_LEAGUE = 'league/REQUEST_UPDATE_LEAGUE';
export const REQUEST_RESTART_LEAGUE = 'league/REQUEST_RESTART_LEAGUE';
export const CLEAR_LEAGUE_STATE = 'league/CLEAR_LEAGUE_STATE';
export const RESTART_LEAGUE_SUCCESS = 'league/RESTART_LEAGUE_SUCCESS';
export const REQUEST_LEAGUE_PUNDITS = 'league/REQUEST_LEAGUE_PUNDITS';
export const RECEIVE_LEAGUE_PUNDITS = 'league/RECEIVE_LEAGUE_PUNDITS';
export const REQUEST_LEAGUE_LEADERBOARD = 'league/REQUEST_LEAGUE_LEADERBOARD';
