const eventContext = {
    crossSell: 'cross sell',
    skyBetReferral: 'cross sell - skybet referral',
    entryFirst: 'entry - first submission',
    entryAdditional: 'entry - additional submission',
    leagueCreated: 'league - created',
    leagueJoined: 'league - joined'
};

export default eventContext;
