import styled from '@emotion/styled/macro';

export const ScoreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 18px 0;
`;

export const Score = styled.div`
    background: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.blueDark};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 28px;
    width: 28px;
    line-height: 43px;
    box-sizing: border-box;
    border-radius: 50%;
`;

export const Vs = styled.p`
    display: flex;
    align-items: center;
    align-content: center;
    color: ${props => props.theme.colours.blueDark};
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.xsmall};
    font-weight: bold;
`;
