function isPeriodIdValid(validList, periodId) {
    const id = parseInt(periodId, 10);
    return validList.includes(id);
}

export const getValidRedirect = (
    uriPeriod,
    periodId,
    activeMonthId,
    activeRoundId,
    activeMonths,
    activeRounds
) => {
    if (
        uriPeriod === 'season' ||
        uriPeriod === 'info' ||
        !activeMonths ||
        !activeRounds ||
        !activeRoundId ||
        !activeMonthId
    ) {
        return null;
    }
    const period = uriPeriod || 'round';

    if (period === 'month' && isPeriodIdValid(activeMonths, periodId)) {
        return null;
    }

    if (period === 'round' && isPeriodIdValid(activeRounds, periodId)) {
        return null;
    }

    const activeId = period === 'month' ? activeMonthId : activeRoundId;

    return `${period}/${activeId}`;
};

export const getValidH2HRedirect = (periodId, activeRoundId, activeRounds) => {
    if (!activeRounds || !activeRoundId) {
        return null;
    }

    if (isPeriodIdValid(activeRounds, periodId)) {
        return null;
    }

    const activeId = activeRoundId;

    return `${activeId}`;
};

const MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

export const getPeriodTitle = (period, id) => {
    const string = {
        round: `Round ${id}`,
        month: MONTHS[id],
        season: id
    };

    return string[period];
};

export const getPeriodTitleForSharing = (period, id) => {
    const string = {
        round: `Round ${id}`,
        month: MONTHS[id],
        season: 'the Season'
    };

    return string[period];
};
