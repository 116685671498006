import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    background: ${props =>
        props.theme.componentColours.results.roundsListBackground};
    border: 1px solid
        ${props => props.theme.componentColours.results.roundsListBorder};
    box-sizing: border-box;
`;

export const Headers = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid
        ${props => props.theme.componentColours.results.roundsListBorder};
    color: ${props =>
        props.theme.componentColours.results.roundsListHeaderText};

    /* Round Name */
    & > :first-of-type {
        padding-left: 19px;
        width: 200px;
        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            padding-left: 9px;
            width: 160px;
        }
    }

    /* Entered */
    & > :nth-of-type(2) {
        width: 64px;
        align-content: center;
        text-align: center;
        margin-right: auto;
    }

    /* Score */
    & > :nth-of-type(3) {
        width: 64px;
        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            display: none;
        }
    }

    /* View Round */
    & > :last-of-type {
        width: 123px;
        align-content: center;
        text-align: center;

        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            width: 103px;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.medium};
`;

export const Rows = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled(Link)`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: ${props => props.theme.componentColours.results.roundsListText};
    width: 100%;
    height: 48px;
    font-family: ${props => props.theme.fonts.medium};
    font-size: ${props => props.theme.fontSize.normal};
    border-bottom: 1px solid
        ${props => props.theme.componentColours.results.roundsListBorder};

    box-sizing: border-box;

    /* Round Name */
    & > :first-of-type {
        padding-left: 19px;
        width: 200px;
        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            padding-left: 9px;
            width: 160px;
        }
    }

    /* Entered */
    & > :nth-of-type(2) {
        width: 64px;
        justify-content: center;
        margin-right: auto;
    }

    /* Score */
    & > :nth-of-type(3) {
        width: 64px;
        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            display: none;
        }
    }

    /* View Round */
    & > :last-of-type {
        width: 123px;
        justify-content: center;

        @media (max-width: ${props => props.theme.widths.mobileMax}) {
            width: 103px;
        }
    }

    /* Row colours */
    :nth-of-type(even) {
        background: ${props =>
            props.theme.componentColours.results.roundsListRowEvenBackground};
    }
    :nth-of-type(odd) {
        background: ${props =>
            props.theme.componentColours.results.roundsListRowOddBackground};
    }

    /* View Round backgrounds */
    :nth-of-type(even) > :last-of-type {
        background: ${props =>
            props.theme.componentColours.results
                .roundsListRowViewRoundEvenBackground};
    }

    :nth-of-type(odd) > :last-of-type {
        background: ${props =>
            props.theme.componentColours.results
                .roundsListRowViewRoundOddBackground};
    }
`;

export const Col = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;
