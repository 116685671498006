import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../../../../components/Common/Button';
import emitNativeShareEvent from '../../../../../helpers/emitNativeShareEvent';
import createPredictionsString from '../../../../../helpers/createPredictionsString';
import ConnectedSubmittedPredictions from './SubmittedPredictions';
import * as ParentStyled from '../../../styles';
import { sendSocialShare as sendSocialShareAction } from '../../../../../store/modules/appBridge/actions';
import elementText from '../../../../../helpers/GoogleAnalytics/element_text';
import modules from '../../../../../helpers/GoogleAnalytics/modules';
import { sendNavigationEvent } from '../../../../../helpers/GoogleAnalytics/gaHelper';

const setNavigationEvent = (text, position, destinationUrl) => {
    sendNavigationEvent(
        modules.playedFlow.viewPredictions,
        1,
        text,
        position,
        destinationUrl
    );
};

const PredictionsSummary = ({
    onEditPredictions,
    sendSocialShare,
    predictions,
    fixtures,
    isNativeShareAvailable
}) => {
    const {
        predictionsStringShort,
        predictionsStringFull
    } = createPredictionsString({ predictions, fixtures });

    return (
        <>
            <ParentStyled.RowTop>
                <ParentStyled.MiddleColumns>
                    <ConnectedSubmittedPredictions />
                </ParentStyled.MiddleColumns>
            </ParentStyled.RowTop>
            <ParentStyled.Row>
                <ParentStyled.MiddleColumns>
                    <ParentStyled.BtnRow>
                        <ParentStyled.FlexColumn>
                            <Button
                                fontSize="large"
                                onClick={() => {
                                    setNavigationEvent(
                                        elementText.navigation.editPredictions,
                                        1,
                                        'https://super6.skysports.com/played'
                                    );
                                    onEditPredictions();
                                }}
                                id="js-fixtures-edit-entry"
                                data-test-id="show-predictions-edit-button"
                            >
                                {isNativeShareAvailable
                                    ? 'Edit'
                                    : 'Edit Predictions'}
                            </Button>
                        </ParentStyled.FlexColumn>
                        {isNativeShareAvailable && (
                            <ParentStyled.FlexColumn>
                                <Button
                                    fontSize="large"
                                    onClick={e =>
                                        emitNativeShareEvent({
                                            e,
                                            sendSocialShare,
                                            customMessage: {
                                                shareText: `I've made my Super 6 predictions for the week. ${predictionsStringFull}. Enter yours now for the chance to win thousands for FREE! https://super6.skysports.com`,
                                                shortShareText: `My @Super6: ${predictionsStringShort}`,
                                                url: `https://super6.skysports.com`
                                            }
                                        })
                                    }
                                    data-analytics="[Share]-Native"
                                    id="share-btn"
                                    data-test-id="predictions-share-button"
                                    colour="colorOnPrimary"
                                    hoverColour="colorOnPrimary"
                                    textColour="white"
                                >
                                    Share
                                </Button>
                            </ParentStyled.FlexColumn>
                        )}
                    </ParentStyled.BtnRow>
                </ParentStyled.MiddleColumns>
            </ParentStyled.Row>
        </>
    );
};

PredictionsSummary.propTypes = {
    onEditPredictions: PropTypes.func.isRequired,
    sendSocialShare: PropTypes.func.isRequired,
    predictions: PropTypes.shape({
        id: PropTypes.shape({
            scoreHome: PropTypes.number,
            scoreAway: PropTypes.number
        })
    }).isRequired,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    shortname: PropTypes.string,
                    name: PropTypes.string
                })
            })
        })
    ).isRequired,
    isNativeShareAvailable: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => ({
    sendSocialShare: data => dispatch(sendSocialShareAction(data))
});

export default connect(null, mapDispatchToProps)(PredictionsSummary);
