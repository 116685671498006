import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Image from '../../components/Common/Image';

export const PaddedContainer = styled.div`
    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        padding: ${props => props.theme.spacing.medium};
    }

    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        padding-top: ${props => props.theme.spacing.medium};
        padding-bottom: ${props => props.theme.spacing.medium};
    }
`;

export const PanelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: ${props =>
            props.pad || props.padVertical ? props.theme.spacing.medium : 0}
        ${props => (props.pad ? props.theme.spacing.medium : 0)};

    @media (min-width: ${props => props.theme.widths.desktopMin}) {
        flex-direction: row;
    }
    row-gap: ${props => props.theme.spacing.medium};
`;

export const Panel = styled.div`
    flex: 40% 1 1;
    margin-bottom: ${props => props.theme.spacing.normal};
    box-sizing: border-box;
`;

export const MatchContainer = styled(Panel)`
    border: 1px solid ${props => props.theme.colours.borderLightGrey};
    position: relative;

    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        border: none;
    }

    ${props =>
        props.live &&
        `
        ::before {
            content: 'LIVE';
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            color: ${props.theme.colours.white};
            background-color: ${props.theme.colours.red};
            width: 47px;
            font-family: ${props.theme.fonts.medium};
            line-height: 26px
        }
       
    `}
`;

export const PointsContainer = styled.div`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    text-align: left;
    padding: ${props => props.theme.spacing.xlarge} 0 0 0;
    font-size: ${props => props.theme.fontSize.medium};
`;

export const ScoreHeader = styled.div`
    font-size: ${props => props.theme.fontSize.xlarge};
    padding: 0 ${props => props.theme.spacing.medium}
        ${props => props.theme.spacing.large}
        ${props => props.theme.spacing.medium};
`;

export const ScoreRow = styled.div`
    border-top: 1px solid ${props => props.theme.colours.borderGrey};
    padding: 0 ${props => props.theme.spacing.medium};
    line-height: 36px;
    display: flex;

    div {
        flex: 33% 1 1;
        box-sizing: border-box;
        text-align: center;
        padding-top: ${props => props.theme.spacing.medium};
        padding-bottom: 3px;

        :first-of-type {
            padding: 0;
            text-align: left;
            display: flex;
            align-items: center;
        }

        span {
            box-sizing: border-box;
        }
    }
    :last-of-type {
        color: white;
        background-color: ${props => props.theme.colours.backgroundRafBlue};
    }
`;

export const GoldenGoalContainer = styled.div`
    text-align: left;
    border: none;
    background-color: ${props => props.theme.colours.backgroundGrey};
    padding: ${props => props.theme.spacing.xlarge}
        ${props => props.theme.spacing.medium};
    > div {
    }
`;

export const ContainerHeader = styled(ScoreHeader)`
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
    padding-left: 0px;
    margin-bottom: ${props => props.theme.fontSize.xlarge};
`;

export const GoldenGoalInformation = styled(ScoreHeader)`
    display: flex;
    font-size: ${props => props.theme.fontSize.large};
    padding-bottom: 0;
    div {
        :first-of-type {
            text-align: center;
            width: 40%;
            padding-right: ${props => props.theme.spacing.large};
        }
    }
`;

export const MatchTitleBar = styled.div`
    background-color: ${props => props.theme.colours.backgroundGrey};
    padding 0 10px;
    line-height: 25px;
    text-align: center;
    
    ${props =>
        props.highlight &&
        `
        background-color: ${props.theme.colours.backgroundRafBlue};
        color: ${props.theme.colours.white};
        ::before {
            font-family: skycons;
            content: '\f092';
            padding-right: 5px
        }
    `}
    
`;

export const PredictionAndScoreContainer = styled.div`
    flex: 33% 1 1;

    font-size: ${props => props.theme.fontSize.small};
    div {
        margin: 4px 0 8px 0;
    }
`;

export const ScoreTitle = styled.div`
    font-family: ${props => props.theme.fonts.medium};
    text-align: center;
`;

export const ScoreContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Score = styled.div`
    width: 100%;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    font-size: ${props => props.theme.fontSize.xlarge};
    text-align: center;
    height: 42px;
    width: 41px;
    line-height: 42px;

    background-color: ${props =>
        props.highlight ? props.theme.colours.backgroundRafBlue : 'none'};
    color: ${props => (props.highlight ? props.theme.colours.white : 'none')};
`;

export const Vs = styled.p`
    display: flex;
    align-items: center;
    align-content: center;
    font-size: ${props => props.theme.fontSize.small};
    font-family: ${props => props.theme.fonts.regular};
`;

export const TeamImage = styled(Image)`
    margin: 0 auto;
    max-width: ${props => (props.size === 'l' ? '70px' : '56px')};
    margin-bottom: ${props => props.theme.spacing.normal};
    @media (max-width: ${props => props.theme.widths.desktopMin}) {
        max-width: 56px;
    }
`;

export const LargeNumber = styled.span`
    font-size: ${props => props.theme.fontSize.huge};
`;

export const HeadToHeadInPlay = styled(Link)`
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    border: solid 2px ${props => props.theme.colours.blueDark};
    margin: ${props => props.theme.spacing.large} 0;
    padding: ${props => props.theme.spacing.large};
    font-family: ${props => props.theme.fonts.regular};
    font-size: ${props => props.theme.fontSize.large};
    line-height: ${props => props.theme.lineHeight.normal};
    color: ${props => props.theme.colours.bluePrimary};
    text-decoration: none;
    div {
        margin-left: ${props => props.theme.spacing.large};
    }

    img {
        height: 40px;
        width: auto;
        border-radius: 50%;
    }
`;

export const UnderlinedText = styled.span`
    font-family: ${props => props.theme.fonts.medium};
    text-decoration: underline;
`;

export const Share = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ShareText = styled.div`
    flex: 1 1 auto;
    display: block;
    text-align: right;
`;

export const ShareControls = styled.div`
    display: block;
    width: 130px;
    flex: 0 0 auto;
    margin-left: ${props => props.theme.spacing.large};
`;
