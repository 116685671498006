export const SEASON_CLOSED = 'SEASON_CLOSED';
export const ENABLE_CLIENT_METRICS = 'ENABLE_CLIENT_METRICS';
export const ENABLE_LCP_METRICS = 'ENABLE_LCP_METRICS';
export const ENABLE_NAV_PART_REG_AD = 'ENABLE_NAV_PART_REG_AD';
export const ENABLE_NAV_EXTRA = 'ENABLE_NAV_EXTRA';
export const ENABLE_PLAY_EXTRA_HERO_TILE = 'ENABLE_PLAY_EXTRA_HERO_TILE';
export const ENABLE_HOME_BANNER = 'ENABLE_HOME_BANNER';
export const ENABLE_HOME_ROUND_CAROUSEL = 'ENABLE_HOME_ROUND_CAROUSEL';
export const ENABLE_SHOW_CURRENT_ROUNDS_ONLY =
    'ENABLE_SHOW_CURRENT_ROUNDS_ONLY';
export const ENABLE_WELCOME_BANNER = 'ENABLE_WELCOME_BANNER';
export const ENABLE_STREAKS_BANNER = 'ENABLE_STREAKS_BANNER';
export const ENABLE_MY_PERFORMANCE_PAGE = 'ENABLE_MY_PERFORMANCE_PAGE';
export const ENABLE_STREAKS_ALERT = 'ENABLE_STREAKS_ALERT';
export const ENABLE_MY_PERFORMANCE_PAGE_COMPONENTS =
    'ENABLE_MY_PERFORMANCE_PAGE_COMPONENTS';
export const ENABLE_HEAD_TO_HEAD = 'ENABLE_HEAD_TO_HEAD';
