import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import ordinalFormatter from './helper';
import noPointsText from '../../helpers/noPointsText';

const RoundResult = ({
    roundNumber,
    punditName,
    correctResultsPoints,
    correctScoresPoints,
    goldenGoal,
    link,
    roundStatus,
    hasPredicted,
    userId
}) => (
    <Styled.RoundResultCard>
        <Styled.CardHeader>
            <Styled.HeaderText data-test-id="results-round">
                Round {roundNumber}
            </Styled.HeaderText>
        </Styled.CardHeader>
        <Styled.RoundResultContainer>
            <Styled.FlexItem>
                <Styled.BodyText bold>
                    Here is how {userId ? 'they' : 'you'} did:
                </Styled.BodyText>
            </Styled.FlexItem>
            <Styled.FlexItem>
                <Styled.BodyText>Correct Result(s) points</Styled.BodyText>
                <Styled.Points data-test-id="results-points">
                    {correctResultsPoints ||
                        noPointsText({ hasPredicted, roundStatus })}
                </Styled.Points>
            </Styled.FlexItem>
            <Styled.FlexItem>
                <Styled.BodyText>Correct Score(s) points</Styled.BodyText>
                <Styled.Points data-test-id="scores-points">
                    {correctScoresPoints ||
                        noPointsText({ hasPredicted, roundStatus })}
                </Styled.Points>
            </Styled.FlexItem>
            <Styled.TotalPointsSection>
                <Styled.BodyText semibold>Total Points</Styled.BodyText>
                <Styled.Points total data-test-id="total-points">
                    {correctScoresPoints + correctResultsPoints ||
                        noPointsText({ hasPredicted, roundStatus })}
                </Styled.Points>
            </Styled.TotalPointsSection>
            {goldenGoal && goldenGoal.goldenGoalPredictionMinute && (
                <Styled.FlexItem>
                    <Styled.BodyText bold>
                        Golden Goal Prediction
                    </Styled.BodyText>
                    <Styled.BodyText data-test-id="golden-goal-prediction-minute">
                        {ordinalFormatter(
                            goldenGoal.goldenGoalPredictionMinute
                        )}
                        &nbsp;minute
                    </Styled.BodyText>
                </Styled.FlexItem>
            )}
            {goldenGoal && goldenGoal.isGoldenGoalScored ? (
                <Styled.FlexItem>
                    <Styled.BodyText data-test-id="golden-goal-scorer-name-team">
                        {goldenGoal.goldenGoalScorerName}&nbsp;(&nbsp;
                        {goldenGoal.goldenGoalScorerTeam}&nbsp;)
                    </Styled.BodyText>
                    <Styled.BodyText data-test-id="golden-goal-scored-minute">
                        {ordinalFormatter(goldenGoal.goldenGoalScoredMinute)}
                        &nbsp;minute
                    </Styled.BodyText>
                </Styled.FlexItem>
            ) : (
                <Styled.FlexItem>
                    <Styled.BodyText data-test-id="golden-goal-status">
                        {`The Golden Goal ${
                            goldenGoal && goldenGoal.inPlay ? 'has' : 'was'
                        } not ${
                            goldenGoal && goldenGoal.inPlay
                                ? 'been scored yet.'
                                : 'scored.'
                        }`}
                    </Styled.BodyText>
                </Styled.FlexItem>
            )}
            {!userId && (
                <Styled.FlexItem>
                    <Styled.BodyText bold data-test-id="pundit-name">
                        Have you Beaten {punditName}?
                    </Styled.BodyText>
                    <Styled.ButtonWrapper to={link} data-test-id="link">
                        <Styled.Button>
                            <Styled.ButtonText>VIEW RESULTS</Styled.ButtonText>
                        </Styled.Button>
                    </Styled.ButtonWrapper>
                </Styled.FlexItem>
            )}
        </Styled.RoundResultContainer>
    </Styled.RoundResultCard>
);

RoundResult.propTypes = {
    roundNumber: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    punditName: PropTypes.string.isRequired,
    correctResultsPoints: PropTypes.number.isRequired,
    correctScoresPoints: PropTypes.number.isRequired,
    goldenGoal: PropTypes.shape({
        goldenGoalPredictionMinute: PropTypes.number,
        goldenGoalScorerName: PropTypes.string,
        goldenGoalScorerTeam: PropTypes.string,
        goldenGoalScoredMinute: PropTypes.number,
        inPlay: PropTypes.bool,
        isGoldenGoalScored: PropTypes.bool
    }),
    roundStatus: PropTypes.string.isRequired,
    hasPredicted: PropTypes.bool.isRequired,
    userId: PropTypes.string
};

RoundResult.defaultProps = {
    userId: null,
    goldenGoal: { inPlay: true, isGoldenGoalScored: false }
};

export default RoundResult;
