import React from 'react';
import PropTypes from 'prop-types';
import ConnectedPeriodIdNavigation from '../../../containers/PeriodIdNavigation';
import ConnectedLeaderboardTabs from '../../../components/LeaderboardTabs';

export const LeaderboardNavigation = ({
    match,
    history,
    activeNavigation,
    location,
    lastCompleteRoundId,
    lastCompleteMonthId
}) => {
    const { search } = location;
    const { period, periodId } = match.params;

    const periodIdInt = periodId ? parseInt(periodId, 10) : null;

    return (
        <>
            <ConnectedLeaderboardTabs
                period={period}
                roundTemplate={`/leaderboard/:id/${lastCompleteRoundId}${search}`}
                monthTemplate={`/leaderboard/:id/${lastCompleteMonthId}${search}`}
                seasonTemplate="/leaderboard/:id"
                infoTemplate="/leaderboard/info"
                lastCompleteRoundId={lastCompleteRoundId}
            />
            {activeNavigation && (
                <ConnectedPeriodIdNavigation
                    urlTemplate={`/leaderboard/${period}/:id`}
                    period={period}
                    periodId={periodIdInt}
                    history={history}
                />
            )}
        </>
    );
};

LeaderboardNavigation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            period: PropTypes.string,
            periodId: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    activeNavigation: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        key: PropTypes.string
    }).isRequired,
    lastCompleteMonthId: PropTypes.number.isRequired,
    lastCompleteRoundId: PropTypes.number.isRequired
};

LeaderboardNavigation.defaultProps = {
    activeNavigation: true
};

export default LeaderboardNavigation;
