import axios from 'axios';
import { API_ROOT } from '../config';
import userFriendlyError from '../errors/UserFriendlyError';
import { getUserSessionId } from '../helpers/sessionStorage';

const commonHeaders = {
    Accept: 'application/json'
};

const leaderboardApi = {
    getLeaderboardForReferrees: async (period, id, token) => {
        const idText = id ? `&id=${id}` : '';
        const { data, status } = await axios.get(
            `${API_ROOT()}/score/referral?period=${period}${idText}`,
            {
                headers: {
                    Authorization: token,
                    'X-Session-Id': getUserSessionId(),
                    ...commonHeaders
                }
            }
        );

        if (status !== 200) {
            throw new Error('Unable to get leaderboard for referrees');
        }
        return data;
    },
    getLeaderboardForSelf: async (period, id, token) => {
        const idText = id ? `&id=${id}` : '';

        let path = `${API_ROOT()}/score/leaderboard/user/self?period=${period}${idText}`;

        if (period === 'season') {
            path = `${API_ROOT()}/score/leaderboard/season/user/self?period=${period}${idText}`;
        }
        const { data, status } = await axios.get(path, {
            headers: {
                Authorization: token,
                'X-Session-Id': getUserSessionId(),
                ...commonHeaders
            }
        });

        if (status !== 200) {
            throw new Error('Unable to get leaderboard for self');
        }
        return data;
    },
    getFolloweeLeaderboard: async (period, id, token) => {
        const idText = id ? `&id=${id}` : '';

        let path = `${API_ROOT()}/score/leaderboard/following?period=${period}${idText}`;

        if (period === 'season') {
            path = `${API_ROOT()}/score/global/leaderboard/following?period=${period}${idText}`;
        }

        const { data, status } = await axios.get(path, {
            headers: {
                Authorization: token,
                'X-Session-Id': getUserSessionId(),
                ...commonHeaders
            }
        });

        if (status !== 200) {
            throw new Error('Unable to get following leaderboard');
        }
        return data;
    },
    getSearchLeaderboard: async (search, period, id) => {
        const idText = id ? `&id=${id}` : '';
        const { data, status } = await axios.get(
            `${API_ROOT()}/score/leaderboard/search?query=${search}&period=${period}${idText}`,
            {
                headers: commonHeaders
            }
        );

        if (status === 404) {
            throw userFriendlyError('No users found in the leaderboard');
        }
        if (status !== 200) {
            throw new Error('Unable to get search leaderboard');
        }
        return data;
    },
    getGlobalLeaderboard: async (period, id) => {
        const idText = id ? `&id=${id}` : '';

        let path = `${API_ROOT()}/score/leaderboard?period=${period}${idText}`;

        if (period === 'season') {
            path = `${API_ROOT()}/score/global/leaderboard?period=${period}${idText}`;
        }

        const { data, status } = await axios.get(path, {
            headers: commonHeaders
        });
        if (status === 404) {
            return [];
        }
        if (status !== 200) {
            throw new Error('Unable to get global leaderboard');
        }

        return data;
    },
    getPunditLeaderboard: async (period, id) => {
        const idText = id ? `&id=${id}` : '';

        let path = `${API_ROOT()}/score/leaderboard/pundit?period=${period}${idText}`;

        if (period === 'season') {
            path = `${API_ROOT()}/score/global/leaderboard/pundit?period=${period}${idText}`;
        }
        const { data, status } = await axios.get(path, {
            headers: commonHeaders
        });

        if (status !== 200) {
            throw new Error('Unable to get global leaderboard');
        }
        return data;
    }
};

export default leaderboardApi;
