import styled from '@emotion/styled/macro';

export const PredictionRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.75fr 1fr;
    color: ${props => props.theme.colours.colorOnPrimary};
    font-size: ${props =>
        `clamp(${props.theme.fontSize.xsmall}, 3vw, ${props.theme.fontSize.normal})`};
    padding: ${props => `${props.theme.spacing.normal} 0`};
`;

export const PredictionLabel = styled.div`
    text-align: left;
    align-self: center;
`;

export const PredictionCenter = styled.div`
    width: 100%;
`;

export const PredictionScoreContainer = styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 100px;
    margin: 0 auto;
`;

export const PredictionScore = styled.div`
    background-color: ${props => props.theme.scores?.score.bgColour};
    color: ${props => props.theme.scores?.score.text};
    font-size: ${props => props.theme.fontSize.normal};
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20px;
    width: 20px;
    line-height: 20px;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;

    ${props =>
        props.notPredicted &&
        `
            background-color: ${props.theme.scores?.noPoints.bgColour};
            color: ${props.theme.scores?.noPoints.text};
        `}
`;

export const PredictionVs = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    color: ${props => props.theme.scores?.text};
    font-size: ${props => props.theme.fontSize.normal};
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;

    ${props =>
        props.notPredicted &&
        `
            color: ${props.theme.scores?.noPoints.bgColour};
        `}
`;

export const PredictionPointsContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    align-items: center;
`;

export const PredictionPoints = styled.span`
    background-color: ${props =>
        props.points
            ? props.theme.colours.colorOnPrimary
            : props.theme.colours.black};
    color: ${props => props.theme.colours.white};
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-left: ${props => props.theme.spacing.normal};
`;
