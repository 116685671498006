import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { useCookieConsentContext } from '../../../../hooks/useCookieConsents';
import buildCookieUrl from '../../../../helpers/buildCookieUrl';
import { OAUTH_URI } from '../../../../config';
import actions from '../../../../helpers/GoogleAnalytics/actions';

const NavSectionList = ({
    navItems,
    closeMobileNav,
    dispatch,
    setNavigationEvent,
    setInterfaceEvent,
    startingIndex
}) => {
    let section = '';
    const location = useLocation();

    const getTargetId = alt =>
        `s6-xsell-${alt.toLowerCase().replace(/\s/g, '-')}-link`;
    const { cookieConsentQuery } = useCookieConsentContext();
    return (
        <>
            {navItems.map((item, index) => {
                const sectionChange = item.section !== section;
                ({ section } = item);
                return (
                    <Fragment key={`${item.section}-${item.label}`}>
                        {sectionChange && (
                            <Styled.NavLinkSection new={item.new}>
                                {item.section}
                            </Styled.NavLinkSection>
                        )}
                        <Styled.NavLinkWrapper>
                            {item.dispatchActionMethod && (
                                <Styled.NavButton
                                    data-analytics={item.analytics}
                                    onClick={() => {
                                        setInterfaceEvent(
                                            item.label,
                                            actions.clicked
                                        );
                                        closeMobileNav();
                                        if (item.dispatchActionMethod) {
                                            dispatch(item.dispatchActionMethod);
                                        }
                                    }}
                                    id={item.id}
                                    data-test-id={item.testId}
                                    data-target-id={item.targetId}
                                    activeTab={
                                        item.path &&
                                        item.path.includes(
                                            `/${
                                                location.pathname.split('/')[1]
                                            }`
                                        )
                                    }
                                >
                                    {item.label}
                                </Styled.NavButton>
                            )}
                            {!item.dispatchActionMethod && !item.external && (
                                <Styled.NavLink
                                    data-analytics={item.analytics}
                                    to={item.link}
                                    onClick={() => {
                                        setNavigationEvent(
                                            item.label,
                                            startingIndex + index,
                                            item.path instanceof Array
                                                ? `https://super6.skysports.com${item.path[0]}`
                                                : `https://super6.skysports.com${item.link}`
                                        );
                                        closeMobileNav();
                                    }}
                                    id={item.id}
                                    data-test-id={item.testId}
                                    data-target-id={item.targetId}
                                    activeTab={
                                        item.path &&
                                        item.path.includes(
                                            `/${
                                                location.pathname.split('/')[1]
                                            }`
                                        )
                                    }
                                >
                                    {item.label}
                                </Styled.NavLink>
                            )}
                            {item.external && item.appLink && (
                                <Styled.NavExternalAppLink
                                    app={item.appName}
                                    link={buildCookieUrl(
                                        OAUTH_URI,
                                        item.link,
                                        cookieConsentQuery
                                    )}
                                    data-test-id={item.testId}
                                    targetId={getTargetId(item.alt)}
                                    id={item.id}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    new={item.new}
                                >
                                    {item.label}
                                </Styled.NavExternalAppLink>
                            )}
                            {item.external && !item.appLink && (
                                <Styled.NavExternalLink
                                    href={buildCookieUrl(
                                        OAUTH_URI,
                                        item.link,
                                        cookieConsentQuery
                                    )}
                                    onClick={() => {
                                        setNavigationEvent(
                                            item.label,
                                            startingIndex + index,
                                            item.link
                                        );
                                    }}
                                    id={item.id}
                                    data-test-id={item.testId}
                                    data-target-id={item.targetId}
                                    new={item.new}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {item.label}
                                </Styled.NavExternalLink>
                            )}
                        </Styled.NavLinkWrapper>
                    </Fragment>
                );
            })}
        </>
    );
};

NavSectionList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    closeMobileNav: PropTypes.func.isRequired,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            show: PropTypes.bool.isRequired,
            section: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            dispatchActionMethod: PropTypes.shape({
                type: PropTypes.string
            }),
            link: PropTypes.string.isRequired,
            path: PropTypes.arrayOf(PropTypes.string),
            analytics: PropTypes.string,
            id: PropTypes.string,
            testId: PropTypes.string.isRequired,
            targetId: PropTypes.string.isRequired
        })
    ).isRequired,
    setNavigationEvent: PropTypes.any,
    setInterfaceEvent: PropTypes.any,
    startingIndex: PropTypes.number.isRequired
};

NavSectionList.defaultProps = {
    setNavigationEvent: () => {},
    setInterfaceEvent: () => {}
};

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(null, mapDispatchToProps)(NavSectionList);
