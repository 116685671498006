import styled from '@emotion/styled/macro';
import CommonButton from '../../../../components/Common/Button';

export const Container = styled.div`
    background-color: ${props => props.theme.colours.white};
    text-align: center;
    border: 1px solid ${props => props.theme.colours.header.gradient.to};
    border-radius: ${props => props.theme?.card?.borderRadius ?? '4px'};
    width: 100%;
    max-width: 210px;

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        max-width: 345px;
        margin: 0 auto;
    }
`;

export const Header = styled.div`
    padding: 12px;
    background-color: ${props =>
        props.theme?.card?.bgColour ?? props.theme.colours.lightGrey};
    justify-content: center;
    font-family: ${props =>
        props.theme.fonts.skySportsD ?? props.theme.fonts.medium};};
    font-weight: ${props => props.theme.fonts.skySportsD && '700'};};
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colours.header.textColor};
    border-radius: ${props =>
        props.theme?.card?.borderRadius
            ? `${props.theme?.card?.borderRadius} ${props.theme?.card?.borderRadius} 0 0`
            : '3px 3px 0 0'};
`;

export const Info = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: ${props => props.theme.spacing.large};
    border-radius: ${props => props.theme?.card?.borderRadius ?? '4px'};
`;

export const Text = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.medium};
    box-sizing: border-box;
    width: auto;
    span {
        font-weight: 600;
    }
`;

export const Date = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colours.header.gradient.to};
    border-bottom: 4px solid ${props => props.theme.colours.greenPrimary};
    border-radius: 4px;
    width: 76px;
    height: 76px;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colours.lightTextGrey};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    flex-shrink: 0;
    span {
        color: ${props => props.theme.colours.black};
        font-size: ${props => props.theme.fontSize.xxlarge};
        font-weight: 600;
    }
`;

export const InfoTop = styled.div`
    display: flex;
    flex-direction: row;
    & > :first-of-type {
        margin-right: ${props => props.theme.spacing.large};
    }
`;

export const Button = styled(CommonButton)`
    margin-top: ${props => props.theme.spacing.large};
    padding-left: 0;
    padding-right: 0;
`;
