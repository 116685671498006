/* eslint-disable import/no-unused-modules */

function createPredictionStringForFixture({
    strings,
    predictions,
    fixture,
    index
}) {
    const { id } = fixture.match;
    const { homeTeam, awayTeam } = fixture.match;

    if (
        !id ||
        !homeTeam ||
        !awayTeam ||
        !homeTeam.name ||
        !homeTeam.shortname ||
        !awayTeam.name ||
        !awayTeam.shortname
    ) {
        throw new Error('Incorrect fixtures data provided');
    }

    if (
        predictions[id].scoreHome == null ||
        predictions[id].scoreAway == null
    ) {
        throw new Error('Incorrect predictions data provided');
    }

    const { scoreHome, scoreAway } = predictions[id];
    const initialCharacter = index === 0 ? '' : ', ';
    const resultShort = `${homeTeam.shortname} ${scoreHome}-${scoreAway} ${awayTeam.shortname}`;
    const resultFull = `${homeTeam.name} ${scoreHome}-${scoreAway} ${awayTeam.name}`;

    return {
        predictionsStringShort: `${strings.predictionsStringShort}${initialCharacter}${resultShort}`,
        predictionsStringFull: `${strings.predictionsStringFull}${initialCharacter}${resultFull}`
    };
}

export { createPredictionStringForFixture as default };
