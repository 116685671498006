import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RoundCarousel from './RoundCarousel';

import { isFeatureOn } from '../../../../store/modules/content/selectors';
import {
    SEASON_CLOSED,
    ENABLE_HOME_ROUND_CAROUSEL,
    ENABLE_SHOW_CURRENT_ROUNDS_ONLY
} from '../../../../features';
import {
    selectUpcomingS6AndExtraRounds,
    selectIsLoadingUpcomingRounds,
    selectCurrentS6AndExtraRoundsOnly,
    selectOpenSuper6RoundId,
    selectActiveRoundScoreChallenges
} from '../../../../store/modules/round/selectors';
import { selectHasPredicted } from '../../../../store/modules/user/selectors';
import { selectIsLoggedIn } from '../../../../store/modules/auth/selectors';
import { requestUpcomingRounds } from '../../../../store/modules/round/actions';
import { requestUsersActiveRoundPredictions } from '../../../../store/modules/user/actions';
import ConnectedHeroes from '../Heroes';
import { formatRounds, borderRadiusMap } from './helpers';

export const RoundCarouselWrapper = ({
    loadUpcomingRounds,
    upcomingRounds,
    currentRounds,
    isLoading,
    isSeasonClosed,
    isRoundCarouselEnabled,
    displayCurrentRoundsOnly,
    loadUserPredictions,
    roundId,
    isLoggedIn,
    hasPredicted,
    fixtures
}) => {
    useEffect(() => {
        loadUpcomingRounds();
    }, [loadUpcomingRounds]);

    useEffect(() => {
        if (upcomingRounds && upcomingRounds.length > 0 && roundId) {
            loadUserPredictions();
        }
    }, [loadUserPredictions, roundId, isLoggedIn, upcomingRounds]);
    const RoundCarouselComponent = () => {
        if (!fixtures) {
            return null;
        }

        const rounds = displayCurrentRoundsOnly
            ? currentRounds
            : upcomingRounds;
        const roundFixtures = fixtures.map((roundMatch, index) => {
            const { homeTeam, awayTeam } = roundMatch.match;
            return {
                matchIndex: index,
                borderRadius: borderRadiusMap.get(index),
                position: 'center',
                homeTeam: {
                    name: homeTeam.name,
                    img: homeTeam.badgeUri
                },
                awayTeam: {
                    name: awayTeam.name,
                    img: awayTeam.badgeUri
                }
            };
        });

        return (
            <RoundCarousel
                rounds={formatRounds(rounds, hasPredicted)}
                isLoading={isLoading}
                fixtures={roundFixtures}
            />
        );
    };

    return (
        <>
            {isSeasonClosed || !isRoundCarouselEnabled ? (
                <ConnectedHeroes />
            ) : (
                <RoundCarouselComponent />
            )}
        </>
    );
};

RoundCarouselWrapper.propTypes = {
    loadUpcomingRounds: PropTypes.func.isRequired,
    upcomingRounds: PropTypes.arrayOf(PropTypes.shape({})),
    currentRounds: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    isSeasonClosed: PropTypes.bool,
    isRoundCarouselEnabled: PropTypes.bool,
    displayCurrentRoundsOnly: PropTypes.bool,
    loadUserPredictions: PropTypes.func.isRequired,
    roundId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    hasPredicted: PropTypes.bool,
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            match: PropTypes.shape({
                homeTeam: PropTypes.shape({
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                }),
                awayTeam: PropTypes.shape({
                    name: PropTypes.string,
                    badgeUri: PropTypes.string
                })
            })
        })
    ).isRequired
};

RoundCarouselWrapper.defaultProps = {
    upcomingRounds: [],
    currentRounds: [],
    isLoading: true,
    isSeasonClosed: false,
    isRoundCarouselEnabled: true,
    displayCurrentRoundsOnly: false,
    hasPredicted: false,
    isLoggedIn: null,
    roundId: null
};

const mapStateToProps = state => ({
    upcomingRounds: selectUpcomingS6AndExtraRounds(state),
    currentRounds: selectCurrentS6AndExtraRoundsOnly(state),
    isLoading: selectIsLoadingUpcomingRounds(state),
    isSeasonClosed: isFeatureOn(state, SEASON_CLOSED),
    isRoundCarouselEnabled: isFeatureOn(state, ENABLE_HOME_ROUND_CAROUSEL),
    displayCurrentRoundsOnly: isFeatureOn(
        state,
        ENABLE_SHOW_CURRENT_ROUNDS_ONLY
    ),
    roundId: selectOpenSuper6RoundId(state),
    isLoggedIn: selectIsLoggedIn(state),
    hasPredicted: selectHasPredicted(state),
    fixtures: selectActiveRoundScoreChallenges(state)
});

const mapDispatchToProps = dispatch => ({
    loadUpcomingRounds: () => dispatch(requestUpcomingRounds()),
    loadUserPredictions: () => dispatch(requestUsersActiveRoundPredictions())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundCarouselWrapper);
