const elementText = {
    banner: {
        betHere: 'bet here',
        playForFree: 'play for free'
    },
    navigation: {
        skyBet: 'sky bet',
        itv7: 'itv7',
        skyVegas: 'sky vegas',
        skySports: 'sky sports',
        skyCasino: 'sky casino',
        skyPoker: 'sky poker',
        skyBingo: 'sky bingo',
        sportingLife: 'sporting life',
        logo: 'home logo',
        leaderboard: 'leaderboard',
        results: 'results',
        headToHead: 'head to head',
        leagues: 'leagues',
        pundits: 'pundits',
        officialClubLeagues: 'official club leagues',
        myPerformanceHub: 'my performance hub',
        faqs: 'frequently asked questions',
        TnCs: 'terms and conditions',
        privacyPolicy: 'privacy policy',
        accessibility: 'accessibility information',
        playForFree: 'play for free',
        'View Predictions': 'view predictions',
        editPredictions: 'edit predictions',
        editFlow: 'edit flow',
        submitPredictions: 'submit predictions',
        submitMyEntry: 'submit my entry',
        liveBets: 'live bets selection',
        skyBetCTA: 'sky bet CTA',
        createLeague: 'create league',
        myAccount: 'my account',
        inPlayOdds: 'sky bet - in-play odds',
        moreMarkets: 'for more markets visit sky bet'
    },
    interface: {
        pundits: 'pundits',
        stats: 'stats',
        popular: 'popular',
        bets: 'bets',
        notificationBell: 'notifications',
        Whatsapp: 'whatsapp',
        Facebook: 'facebook',
        Twitter: 'twitter',
        Email: 'email',
        cookieManagement: 'cookie management',
        contactUs: 'contact us',
        feedback: 'feedback'
    }
};

export default elementText;
