import styled from '@emotion/styled/macro';

// Prediction and Score
export const PredictionAndScoreContainer = styled.div`
    flex: 34% 0 1;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.scores?.text};

    div {
        margin: 4px 0 8px 0;
    }
`;

export const GridItem = styled.div`
    display: flex;
    justify-content: ${props => props.position};
`;

export const ScoreTitle = styled.div`
    font-family: ${props => props.theme.fonts.skySportsD};
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
`;

// Match with scores
export const MatchContainer = styled.div`
    background-color: ${props => props.theme.scores?.mainBgColour};
    border-radius: ${props => props.theme.radius.normal};
`;

export const MatchHeader = styled.div`
    display: grid;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    grid-template-columns: minmax(75px, 1fr) 1fr 1fr;
    height: ${props => props.theme.spacing.medium};
`;

export const NonLiveMatchHeader = styled.div`
    padding-left: ${props => props.theme.spacing.medium};
    padding-right: ${props => props.theme.spacing.medium};
    padding-top: ${props => props.theme.spacing.large};
`;

export const MatchCompetitionName = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    font-family: ${props => props.theme.fonts.skySportsPL};
    font-weight: 700;
    ${props =>
        props.competitionName !== 'Premier League' &&
        `
            font-family: ${props.theme.fonts.skySportsF};
            font-weight: 500
        `};
    align-self: center;
    margin-left: ${props => props.theme.spacing.large};
    text-align: start;
    color: ${props => props.theme.colours.colorOnPrimary};

    @media (max-width: ${props => props.theme.widths.mobileMax}) {
        font-size: ${props => props.theme.fontSize.small};
        margin-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: ${props => props.theme.spacing.large};
    }
`;

export const Match = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const MatchFooter = styled.div`
    border-bottom-left-radius: ${props => props.theme.spacing.xlarge2};
    border-bottom-right-radius: ${props => props.theme.spacing.xlarge2};
    background-color: ${props => props.theme.scores.secondaryBgColour};
`;
