import { parse } from 'querystring';
import { createSelector } from 'reselect';

export const referees = (state, period, periodId) =>
    state.leaderboard.referreesLeaderboard[period][periodId || 'default'] ||
    null;

export const user = (state, period) =>
    state.leaderboard.usersLeaderboard[period] || null;

const getQueryString = location => location.search;
const getPeriod = (state, period) => period;
const getPeriodId = (state, period, id) => id;
const getSelfLeaderboards = state => state.leaderboard.selfLeaderboard;
const getLeaderboards = (state, period, periodId, type, search) =>
    type === 'search'
        ? state.leaderboard.search[search]
        : state.leaderboard.leaderboard[type];

export const selectSelfLeaderboard = createSelector(
    [getPeriod, getPeriodId, getSelfLeaderboards],
    (period, periodId, leaderboards) =>
        leaderboards.find(leaderboard => {
            if (leaderboard.period !== period) {
                return false;
            }

            return period === 'season' || leaderboard.periodId === periodId;
        })
);

export const selectLeaderboard = createSelector(
    [getPeriod, getPeriodId, getLeaderboards],
    (period, periodId, leaderboards) => {
        if (typeof leaderboards === 'undefined') {
            return null;
        }

        return leaderboards.find(leaderboard => {
            if (leaderboard.period !== period) {
                return false;
            }

            return period === 'season' || leaderboard.periodId === periodId;
        });
    }
);

export const selectType = createSelector([getQueryString], queryString => {
    const query = parse(queryString.replace('?', ''));
    const search = query.search || null;
    if (search) {
        return 'search';
    }

    const type = query.filter || 'global';
    if (!['global', 'following', 'pundits'].includes(type)) {
        return 'global';
    }

    return type;
});
