import React from 'react';
import PropTypes from 'prop-types';
import LastRoundFixtureGroup from './LastRoundFixtureGroup';
import * as Styled from './styles';

const LastRound = ({ fixtures, totalPoints, h2hText }) => (
    <Styled.LastRoundGroupContainer>
        <Styled.Title>Last Round</Styled.Title>
        <Styled.LastRoundGroupRow>
            {fixtures.slice(0, 3).map((fixture, index) => (
                <LastRoundFixtureGroup
                    key={fixture.id}
                    points={fixture.points}
                    homeTeam={fixture.homeTeam}
                    awayTeam={fixture.awayTeam}
                    predictedHomeScore={fixture.predictedHomeScore}
                    homeScore={fixture.homeScore}
                    predictedAwayScore={fixture.predictedAwayScore}
                    awayScore={fixture.awayScore}
                    isAbandoned={fixture.isAbandoned}
                    index={index}
                />
            ))}
        </Styled.LastRoundGroupRow>
        <Styled.LastRoundGroupBottomRow>
            {fixtures.slice(3, 6).map((fixture, index) => (
                <LastRoundFixtureGroup
                    key={fixture.id}
                    points={fixture.points}
                    homeTeam={fixture.homeTeam}
                    awayTeam={fixture.awayTeam}
                    predictedHomeScore={fixture.predictedHomeScore}
                    homeScore={fixture.homeScore}
                    predictedAwayScore={fixture.predictedAwayScore}
                    awayScore={fixture.awayScore}
                    isAbandoned={fixture.isAbandoned}
                    index={index + 3}
                />
            ))}
        </Styled.LastRoundGroupBottomRow>
        <Styled.LastRoundGroupFooterRow>
            <Styled.Points>{totalPoints} Points</Styled.Points>
            {!!h2hText && <Styled.H2H>{h2hText}</Styled.H2H>}
        </Styled.LastRoundGroupFooterRow>
    </Styled.LastRoundGroupContainer>
);

LastRound.defaultProps = {
    h2hText: null
};

LastRound.propTypes = {
    fixtures: PropTypes.arrayOf(
        PropTypes.shape({
            points: PropTypes.number,
            homeTeam: PropTypes.shape({
                badgeUri: PropTypes.string
            }).isRequired,
            awayTeam: PropTypes.shape({
                badgeUri: PropTypes.string
            }).isRequired,
            predictedHomeScore: PropTypes.number,
            homeScore: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            predictedAwayScore: PropTypes.number,
            awayScore: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            isAbandoned: PropTypes.bool
        })
    ).isRequired,
    totalPoints: PropTypes.number.isRequired,
    h2hText: PropTypes.string
};

export default LastRound;
