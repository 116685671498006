import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import { Icon } from '../../../components/Common/Page/styles';
import * as icons from '../../../styles/icons';

export const SearchField = ({ period, periodId, search, history }) => {
    const [localSearch, setSearch] = useState(search || '');
    const hasActiveSearch = search && search !== '';

    const baseUrl = `/leaderboard/${period}${periodId ? `/${periodId}` : ''}`;

    const onSubmit = e => {
        e.preventDefault();
        if (localSearch !== '') {
            history.push(
                `${baseUrl}?search=${encodeURIComponent(localSearch)}`
            );
        }
    };

    const clearSearch = e => {
        e.preventDefault();
        setSearch('');
        history.push(baseUrl);
    };

    return (
        <Styled.SearchContainer>
            <form onSubmit={onSubmit}>
                <Styled.SearchField
                    type="text"
                    autoComplete="off"
                    defaultValue={localSearch}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Search for a player..."
                    hasActiveSearch={hasActiveSearch}
                />

                {hasActiveSearch && (
                    <Styled.ClearButton type="button" onClick={clearSearch}>
                        <Icon data-char={icons.Cross} />
                    </Styled.ClearButton>
                )}
                <Styled.SearchButton type="submit">
                    <Icon data-char={icons.Search} />
                </Styled.SearchButton>
            </form>
        </Styled.SearchContainer>
    );
};

SearchField.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    period: PropTypes.string.isRequired,
    periodId: PropTypes.number,
    search: PropTypes.string
};

SearchField.defaultProps = {
    periodId: null,
    search: ''
};

export default withRouter(SearchField);
